// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Input_ReactInput__3tngp > label {\n  font-size: 0.9375rem;\n  font-family: Montserrat, sans-serif;\n  transform: translate(0.875rem, 1.25rem) scale(1);\n}\n.Input_ReactInput__3tngp legend {\n  font-family: Montserrat, sans-serif;\n}\n\n.Input_ReactInput__3tngp input {\n  font-size: 0.9375rem;\n  font-family: Montserrat, sans-serif;\n  font-weight: 500;\n  padding: 1.15625rem 0.875rem;\n}\n\n.Input_ReactInput__3tngp textarea {\n  font-size: 15px;\n  font-family: Montserrat, sans-serif;\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://src/components/Form/Input/Input.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mCAAmC;EACnC,gDAAgD;AAClD;AACA;EACE,mCAAmC;AACrC;;AAEA;EACE,oBAAoB;EACpB,mCAAmC;EACnC,gBAAgB;EAChB,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,mCAAmC;EACnC,gBAAgB;AAClB","sourcesContent":[".ReactInput > label {\n  font-size: 0.9375rem;\n  font-family: Montserrat, sans-serif;\n  transform: translate(0.875rem, 1.25rem) scale(1);\n}\n.ReactInput legend {\n  font-family: Montserrat, sans-serif;\n}\n\n.ReactInput input {\n  font-size: 0.9375rem;\n  font-family: Montserrat, sans-serif;\n  font-weight: 500;\n  padding: 1.15625rem 0.875rem;\n}\n\n.ReactInput textarea {\n  font-size: 15px;\n  font-family: Montserrat, sans-serif;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ReactInput": "Input_ReactInput__3tngp"
};
export default ___CSS_LOADER_EXPORT___;
