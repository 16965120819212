import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  IconButton,
  Tooltip,
  useMediaQuery
} from "@material-ui/core";

import {
  AllMealsOfADayGrid,
  Auth,
  ShowAllMealGoalsForADay,
  DarkBlueButton,
  WhiteIconButton,
  DuplicateMealDialogContent,
  Input,
  Table,
  LightGrayButton,
  SnackBar,
  SelectDropdown
} from "../../components";
import { useTheme } from "@material-ui/core/styles";
import "./Exchange.module.css";
import {
  providerForGet,
  providerForPost,
  providerForDownload,
  providerForPut
} from "../../api";
import auth from "../../components/Auth";
import {
  downloadActualFoodForAllMealsBreakdownPdf,
  downloadSpecificMealsForTodayPdf,
  foodCategory,
  getAllMealInfoApi,
  getSingleDayBreakdownPdf,
  getMenuForTodaysMeals,
  getMenuForWeekMeal,
  saveAllMealsGoals,
  getAllDaysBreakdownPdf,
  replicateMeal,
  generateShoppingList,
  getExchangesAndMenuTemplate,
  loadFavoritesForMeals,
  saveFavoritesForMeal,
  getClientScheduleData
} from "../../constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CREATEMEALS } from "../../paths";
import GetAppIcon from "@material-ui/icons/GetApp";
import DialogBox from "../../components/DialogBox/DialogBox";
import { Prompt } from "react-router-dom";
import { isEmptyString } from "../../utils";
import { ERROR_MESSAGE, SAVE_MESSAGE } from "../../constants/genericConstants";
import PrintIcon from "@material-ui/icons/Print";

const Exchange = props => {
  const tableRef = React.createRef();
  const columns = [{ title: "Name", field: "name" }];
  const [filter, setFilter] = useState({
    _sort: "template_name:asc"
  });

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    summaryButtonBox: {
      display: "flex",
      marginBottom: "3%"
    },
    loadTemplate: {
      marginLeft: "auto"
    },
    duplicateReplicateStyle: {
      justifyContent: "flex-end",
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      marginRight: 30,
      [theme.breakpoints.up("md")]: {
        marginRight: 0
      },
      [theme.breakpoints.up("sm")]: {
        marginRight: 0
      },
      [theme.breakpoints.up("xs")]: {
        marginRight: 0
      }
    },
    margin: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    formControl: {
      margin: "10px 0px",
      minWidth: 270,
      flexDirection: "row"
    },
    mealForDayGrid: {
      display: "flex",
      flexWrap: "wrap"
    },
    saveButton: {
      display: "flex",
      justifyContent: "flex-end"
    },
    selectBox: {
      height: 30,
      weight: 10,
      borderRadius: 10,
      marginTop: theme.spacing(1)
    },
    selectBoxLabel: {
      marginTop: 11,
      marginRight: theme.spacing(1),
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.15px",
      color: "#000000"
    },
    favouriteClass: {
      [theme.breakpoints.down("xs")]: {
        "& button": {
          marginLeft: "0px !important",
          display: "flex !important",
          "&:first-child": {
            marginBottom: "10px !important"
          }
        }
      }
    }
  }));

  const reportList = [
    "Exchange Breakdown of Today's Meal",
    "Exchange Breakdown For All Meals Week",
    "Specific Food Break Down for Todays meals",
    "Actual food Breakdown for All Meals Week",
    "Menu for today's meal",
    "Menu for all meals for a week",
    "Shopping List"
  ];
  const reportListObj = [
    { name: "Exchange Breakdown of Today's Meal" },
    { name: "Exchange Breakdown For All Meals Week" },
    { name: "Specific Food Break Down for Todays meals" },
    { name: "Actual food Breakdown for All Meals Week" },
    { name: "Menu for today's meal" },
    { name: "Menu for all meals for a week" },
    { name: "Shopping List" }
  ];

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  const [statusVariable, setStatusVariable] = useState({
    isError: false,
    isOpenSnackBar: false,
    successErrorMessage: ""
  });

  /**
   * * Create an array with 10 elements [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
   */
  const [defaultMeals, setDefaultMeals] = useState(
    Array.from(
      { length: parseInt(props.scheduleData.no_of_meals_per_day) },
      (_, i) => i + 1
    )
  );
  const [exchangeDayList] = useState(
    Array.from({ length: 7 }, (_, i) => i + 1)
  );
  const [foodItem] = useState(["bread", "meat", "veg", "fruit", "milk", "fat"]);
  const [daysMap, setDaysMap] = useState({});
  const [dupError, setDupError] = useState(false);
  const [openReportDownload, setOpenReportDownload] = useState(false);
  const [mealForm, setMealForm] = useState({
    exchangeDay: props["location"]["state"]
      ? props["location"]["state"].day
        ? props["location"]["state"].day
        : 1
      : 1,
    mealValues: {},
    singleMealTotal: {},
    singleFoodTotal: {},
    total: 0,
    isValuesCalculated: false,
    scheduleId: null,
    clientId: null,
    isActive: props ? props.isActive : null,
    mealPerDay: props.scheduleData.no_of_meals_per_day,
    report: ""
  });

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    children: "",
    isDuplicate: false,
    isReplicate: false,
    isDuplicateOk: false
  });

  const [avgFoodCat, setAvgFoodCat] = useState({
    bread: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    meat: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    veg: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    fruit: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    milk: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    fat: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    }
  });

  const [goalValues, setGoalValues] = useState({
    values: {
      bread: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      meat: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      veg: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      fruit: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      milk: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      fat: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      }
    },
    total: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    avgPerMeal: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    calContributed: {
      proContNum: 0,
      proContPercent: 0,
      carbContNum: 0,
      carbContPercent: 0,
      fatCalNum: 0,
      fatCalNumPercent: 0
    }
  });

  const urlParams = new URLSearchParams(window.location.search);

  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(true);
  const [favorites, setFavorites] = useState({
    templateName: "",
    isTemplateError: false,
    errorText: "",
    openDialogBox: false,
    openMealsTemplateDialogBox: false,
    isEdit: props.scheduleData.meal_template ? true : false,
    templateId: props.scheduleData.meal_template
      ? props.scheduleData.meal_template
      : null
  });

  const history = useHistory();

  const localClasses = useStyles();

  const updateAllGoals = (
    singleFoodTotal = mealForm.singleFoodTotal,
    avgFood = avgFoodCat,
    meal_per_day = mealForm.mealPerDay
  ) => {
    setLoader1(true);
    let finalValues = {};
    let totalValues = {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    };

    Object.keys(goalValues.values).map(fv => {
      let totalPortion = parseFloat(singleFoodTotal[fv]);
      let avgcal = totalPortion * parseFloat(avgFood[fv].avgcal);
      let avgpro = totalPortion * parseFloat(avgFood[fv].avgpro);
      let avgfat = totalPortion * parseFloat(avgFood[fv].avgfat);
      let avgcarb = totalPortion * parseFloat(avgFood[fv].avgcarb);
      totalValues.portion = totalValues.portion + totalPortion;
      totalValues.avgcal = totalValues.avgcal + avgcal;
      totalValues.avgpro = totalValues.avgpro + avgpro;
      totalValues.avgcarb = totalValues.avgcarb + avgcarb;
      totalValues.avgfat = totalValues.avgfat + avgfat;
      finalValues[fv] = {
        portion: totalPortion ? totalPortion.toFixed(2) : "0",
        avgcal: avgcal ? avgcal.toFixed(1) : "0",
        avgpro: avgpro ? avgpro.toFixed(2) : "0",
        avgcarb: avgcarb ? avgcarb.toFixed(2) : "0",
        avgfat: avgfat ? avgfat.toFixed(2) : "0"
      };
      return null;
    });

    let calContByPro = totalValues.avgpro * 4;
    let calContByCarb = totalValues.avgcarb * 4;
    let calContByFat = totalValues.avgfat * 9;
    let calContByProPercent = (calContByPro / totalValues.avgcal) * 100;
    let calContByCarbPercent = (calContByCarb / totalValues.avgcal) * 100;
    let calContByFatPercent = (calContByFat / totalValues.avgcal) * 100;
    if (isNaN(calContByProPercent)) {
      calContByProPercent = 0;
    }
    if (isNaN(calContByCarbPercent)) {
      calContByCarbPercent = 0;
    }
    if (isNaN(calContByFatPercent)) {
      calContByFatPercent = 0;
    }

    setGoalValues(goalValues => ({
      ...goalValues,
      values: finalValues,
      total: {
        portion: totalValues.portion ? totalValues.portion.toFixed(2) : "0",
        avgcal: totalValues.avgcal ? totalValues.avgcal.toFixed(1) : "0",
        avgpro: totalValues.avgpro ? totalValues.avgpro.toFixed(2) : "0",
        avgcarb: totalValues.avgcarb ? totalValues.avgcarb.toFixed(2) : "0",
        avgfat: totalValues.avgfat ? totalValues.avgfat.toFixed(2) : "0"
      },
      avgPerMeal: {
        portion:
          totalValues.portion / meal_per_day
            ? (totalValues.portion / meal_per_day).toFixed(2)
            : "0",
        avgcal:
          totalValues.avgcal / meal_per_day
            ? (totalValues.avgcal / meal_per_day).toFixed(2)
            : "0",
        avgpro:
          totalValues.avgpro / meal_per_day
            ? (totalValues.avgpro / meal_per_day).toFixed(2)
            : "0",
        avgcarb:
          totalValues.avgcarb / meal_per_day
            ? (totalValues.avgcarb / meal_per_day).toFixed(2)
            : "0",
        avgfat:
          totalValues.avgfat / meal_per_day
            ? (totalValues.avgfat / meal_per_day).toFixed(2)
            : "0"
      },
      calContributed: {
        proContNum: calContByPro ? calContByPro.toFixed(2) : "0",
        proContPercent: calContByProPercent
          ? calContByProPercent.toFixed(2)
          : "0",
        carbContNum: calContByCarb ? calContByCarb.toFixed(2) : "0",
        carbContPercent: calContByCarbPercent
          ? calContByCarbPercent.toFixed(2)
          : "0",
        fatCalNum: calContByFat ? calContByFat.toFixed(2) : "0",
        fatCalNumPercent: calContByFatPercent
          ? calContByFatPercent.toFixed(2)
          : "0"
      }
    }));
    setLoader1(false);
  };

  /*
   * * Updates food values
   */
  const updateTotal = (food, mealNo, goal, target) => {
    let mealTotalKey = `meal_${mealNo}`;
    let mealTotal = 0;
    /** Calculate single meal total */
    foodItem.map(f => {
      let dataToAdd = parseFloat(mealForm.mealValues[`meal_${mealNo}`][f]);
      /** This check the value to add is the current added value or not this is done because it takes a while for the current value to update */
      if (f === food) {
        dataToAdd = goal;
      }
      mealTotal = mealTotal + dataToAdd;
      return null;
    });
    mealTotal = mealTotal ? mealTotal.toFixed(2) : "0";

    /** Calculate single food total */
    let foodTotal = 0;
    defaultMeals.map(mealNumber => {
      let key = `meal_${mealNumber}`;
      let dataToAdd = parseFloat(mealForm.mealValues[key][food]);
      /** This check the value to add is the current added value or not this is done because it takes a while for the current value to update */
      if (mealNumber === mealNo) {
        dataToAdd = goal;
      }
      foodTotal = foodTotal + dataToAdd;
      return null;
    });
    foodTotal = foodTotal ? foodTotal.toFixed(2) : "0";
    let total = 0;
    Object.keys(mealForm.singleFoodTotal).map(f => {
      let value = 0;
      if (f === food) {
        value = parseFloat(foodTotal);
      } else {
        value = mealForm.singleFoodTotal[f];
      }
      total = total + value;
      return null;
    });

    let singleFoodTotal = {
      ...mealForm.singleFoodTotal,
      [food]: parseFloat(foodTotal)
    };

    setMealForm(mealForm => ({
      ...mealForm,
      singleMealTotal: {
        ...mealForm.singleMealTotal,
        [mealTotalKey]: parseFloat(mealTotal)
      },
      singleFoodTotal: singleFoodTotal,
      total: parseFloat(total ? total.toFixed(2) : "0")
    }));

    updateAllGoals(singleFoodTotal);
  };

  /** Add Default values to meals */
  const addDefaultValuesToMeals = (
    avgFood = avgFoodCat,
    no_of_meals = defaultMeals
  ) => {
    setLoader(true);
    let data = {};
    let singleMealTotal = {};
    let defaultFoodValues = {
      bread: 0,
      meat: 0,
      veg: 0,
      fruit: 0,
      milk: 0,
      fat: 0
    };
    /*
     * * Add default values
     */
    no_of_meals.map(mealNumber => {
      let key = `meal_${mealNumber}`;
      data[key] = defaultFoodValues;
      singleMealTotal[key] = 0;
      return null;
    });

    /**
     * This will make data as mealForm data as
     * mealForm = {
     *  mealValues : {
     *                meal_1 : {
     *                            bread : 0
     *                            .. all other food values
     *                         } ,
     *                 .... all other meal values
     *                }
     *
     * }
     *
     */

    setGoalValues(goalValues => ({
      ...goalValues,
      values: {
        bread: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        },
        meat: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        },
        veg: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        },
        fruit: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        },
        milk: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        },
        fat: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        }
      },
      total: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      avgPerMeal: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      calContributed: {
        proContNum: 0,
        proContPercent: 0,
        carbContNum: 0,
        carbContPercent: 0,
        fatCalNum: 0,
        fatCalNumPercent: 0
      }
    }));

    setMealForm(mealForm => ({
      ...mealForm,
      mealValues: data,
      singleMealTotal: singleMealTotal,
      singleFoodTotal: defaultFoodValues,
      total: 0,
      isValuesCalculated: true
    }));
    updateAllGoals(defaultFoodValues, avgFood, no_of_meals.length);
    setLoader1(false);
    setLoader(false);
  };

  /** Get initial data */
  const getAllMealInfo = async (
    ex_day = mealForm.exchangeDay,
    avgFood = avgFoodCat,
    no_of_meals = defaultMeals
  ) => {
    setLoader(true);
    let scheduleId = urlParams.get("s");
    await providerForGet(
      getAllMealInfoApi,
      { scheduleId: scheduleId, ex_day: ex_day },
      Auth.getToken()
    )
      .then(async res => {
        if (res.data.exchange_day) {
          setMealForm(mealForm => ({
            ...mealForm,
            mealValues: res.data.mealValues,
            singleMealTotal: res.data.singleMealTotal,
            singleFoodTotal: res.data.singleFoodTotal,
            total: res.data.total,
            isValuesCalculated: true
          }));
          updateAllGoals(res.data.singleFoodTotal, avgFood, no_of_meals.length);
        } else {
          addDefaultValuesToMeals(avgFood, no_of_meals);
        }
        setLoader(false);
      })
      .catch(error => {
        addDefaultValuesToMeals(avgFood, no_of_meals);
        setLoader(false);
      });
  };

  const getNoOfMealsPerDay = async (
    ex_day = mealForm.exchangeDay,
    finalAvgFood = avgFoodCat
  ) => {
    setLoader1(true);
    await providerForGet(
      getClientScheduleData + "/" + urlParams.get("s"),
      {},
      auth.getToken()
    )
      .then(res => {
        if (res.data) {
          let no_of_meals = res.data[`no_of_meals_per_day_${ex_day}`];
          let no_of_meals_arr = Array.from(
            { length: parseInt(no_of_meals) },
            (_, i) => i + 1
          );
          setMealForm(mealForm => ({
            ...mealForm,
            mealPerDay: no_of_meals
          }));
          setDefaultMeals(no_of_meals_arr);
          getAllMealInfo(ex_day, finalAvgFood, no_of_meals_arr);
        }
      })
      .catch(error => {
        setLoader1(false);
      });
  };

  const getAvgInfo = async (ex_day = mealForm.exchangeDay) => {
    setLoader(true);
    setMealForm(mealForm => ({
      ...mealForm,
      isValuesCalculated: false
    }));
    await providerForGet(foodCategory, { pageSize: -1 }, Auth.getToken())
      .then(async res => {
        let finalAvgFood = {};
        res.data.data.map(fc => {
          let name = "";
          let dataToAdd = {
            avgcal: 0,
            avgpro: 0,
            avgcarb: 0,
            avgfat: 0
          };
          if (fc.name === "Milk" || fc.name === "milk") {
            name = "milk";
          } else if (
            fc.name === "Vegetable" ||
            fc.name === "veg" ||
            fc.name === "vegetable" ||
            fc.name === "Veg"
          ) {
            name = "veg";
          } else if (fc.name === "Fruit" || fc.name === "fruit") {
            name = "fruit";
          } else if (fc.name === "Meat" || fc.name === "meat") {
            name = "meat";
          } else if (fc.name === "Fat" || fc.name === "fat") {
            name = "fat";
          } else if (fc.name === "Bread" || fc.name === "bread") {
            name = "bread";
          }
          if (name !== "") {
            dataToAdd.avgcal = fc.avgcal;
            dataToAdd.avgpro = fc.avgpro;
            dataToAdd.avgcarb = fc.avgcarb;
            dataToAdd.avgfat = fc.avgfat;
            finalAvgFood[name] = dataToAdd;
          }
          return null;
        });
        setAvgFoodCat(finalAvgFood);
        getNoOfMealsPerDay(ex_day, finalAvgFood);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    setMealForm(mealForm => ({
      ...mealForm,
      scheduleId: urlParams.get("s"),
      clientId: urlParams.get("c")
    }));
    getAvgInfo(mealForm.exchangeDay);
  }, []);

  const closeReortDwonloadDialogBox = () => {
    setOpenReportDownload(false);
  };

  const closeDialogBox = () => {
    setFavorites(favorites => ({
      ...favorites,
      isEdit: false,
      templateName: "",
      isTemplateError: false,
      errorText: "",
      openDialogBox: false,
      openMealsTemplateDialogBox: false
    }));
    setFavorites(favorites => ({
      ...favorites,
      isEdit: favorites.templateId ? true : false
    }));
  };

  const handleSaveFavorites = () => {
    setFavorites(favorites => ({
      ...favorites,
      openMealsTemplateDialogBox: true
    }));
  };

  const handleSave = async (flag = false) => {
    setLoader(true);
    await providerForPost(
      saveAllMealsGoals,
      {
        exchangeDay: mealForm.exchangeDay,
        scheduleId: mealForm.scheduleId,
        mealValues: mealForm.mealValues,
        c: mealForm.clientId
      },
      Auth.getToken(),
      {
        desc: "Saved the meals of day " + mealForm.exchangeDay,
        audit_log_clientid: mealForm.clientId
      }
    )
      .then(res => {
        setLoader(false);
        if (flag) {
          setStatusVariable(statusVariable => ({
            ...statusVariable,

            isOpenSnackBar: true,
            isError: "success",
            successErrorMessage: "Exchange meal data " + SAVE_MESSAGE
          }));
        }
      })
      .catch(err => {
        setLoader(false);
        if (flag) {
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isOpenSnackBar: true,
            isError: "error",
            successErrorMessage: ERROR_MESSAGE + err
          }));
        }
      });
  };

  /**
   * Set exchange day
   * @param {*} event
   */

  const handleChange = event => {
    setLoader(true);
    handleSave();
    setMealForm(mealForm => ({
      ...mealForm,
      exchangeDay: event.target.value
    }));
    getNoOfMealsPerDay(event.target.value);
  };

  const handleChangeReport = event => {
    setMealForm(mealForm => ({
      ...mealForm,
      report: event.target.value
    }));
  };

  const handleMealChange = (event, food, mealNo) => {
    const floatRegExp = new RegExp(
      process.env.REACT_APP_REG_EX_FOR_EMPTY_STRING
    );
    let value = event.target.value;

    if (value !== "" && floatRegExp.test(value)) {
      setMealForm(mealForm => ({
        ...mealForm,
        mealValues: {
          ...mealForm.mealValues,
          ["meal_" + mealNo]: {
            ...mealForm.mealValues["meal_" + mealNo],
            [food]: value
          }
        }
      }));
      updateTotal(food, mealNo, parseFloat(value), event.target.id);
    } else if (event.target.value === "") {
      setMealForm(mealForm => ({
        ...mealForm,
        mealValues: {
          ...mealForm.mealValues,
          ["meal_" + mealNo]: {
            ...mealForm.mealValues["meal_" + mealNo],
            [food]: 0
          }
        }
      }));
      updateTotal(food, mealNo, 0, event.target.id);
    }
  };

  const handleCreateMeals = async () => {
    await handleSave();
    history.push({
      pathname: CREATEMEALS,
      search: `?c=${mealForm.clientId}&s=${mealForm.scheduleId}&e_day=${mealForm.exchangeDay}`,
      state: { isActive: mealForm.isActive }
    });
  };

  const handleActualFoodForAllMeals = async (isPrint = false) => {
    setLoader(true);
    let finalData = {
      schedule_id: mealForm.scheduleId,
      c: mealForm.clientId
    };
    await providerForDownload(
      downloadActualFoodForAllMealsBreakdownPdf,
      finalData,
      Auth.getToken(),
      {
        desc: "Download Actual Food Breakdown For All Meals Week",
        audit_log_clientid: mealForm.clientId
      }
    )
      .then(res => {
        setLoader(false);
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          const pdfNewWindow = window.open();
          pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "actual_food_for_all_meals_breakdown.pdf"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const handleSpecificMeals = async (isPrint = false) => {
    setLoader(true);
    let finalData = {
      exchange_day: mealForm.exchangeDay,
      schedule_id: mealForm.scheduleId,
      c: mealForm.clientId
    };
    await providerForDownload(
      downloadSpecificMealsForTodayPdf,
      finalData,
      Auth.getToken(),
      {
        desc: "Download report for specific food breakdown for today's meal",
        audit_log_clientid: mealForm.clientId
      }
    )
      .then(res => {
        setLoader(false);
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          const pdfNewWindow = window.open();
          pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "specific_food_for_todays_meal.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const getPdfForTodaysMeal = async (isPrint = false) => {
    setLoader(true);
    await providerForDownload(
      getMenuForTodaysMeals,
      {
        exchange_day: mealForm.exchangeDay,
        schedule_id: mealForm.scheduleId,
        c: mealForm.clientId
      },
      Auth.getToken(),
      {
        desc: "Download Menu for todays meal pdf",
        audit_log_clientid: mealForm.clientId
      }
    )
      .then(res => {
        setLoader(false);
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          const pdfNewWindow = window.open();
          pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "menu_for_todays_meal.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const getPdfForWeeksMeal = async (isPrint = false) => {
    setLoader(true);
    await providerForDownload(
      getMenuForWeekMeal,
      {
        schedule_id: mealForm.scheduleId,
        c: mealForm.clientId
      },
      Auth.getToken(),
      {
        desc: "Download Menu for week's meal pdf",
        audit_log_clientid: mealForm.clientId
      }
    )
      .then(res => {
        setLoader(false);
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          const pdfNewWindow = window.open();
          pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "menu_for_entire_weeks_meal.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const downloadSingleDayBreakdownPdf = async (isPrint = false) => {
    setLoader(true);
    let ex_day = mealForm.exchangeDay;
    const urlParams = new URLSearchParams(window.location.search);
    let scheduleId = urlParams.get("s");
    let c = urlParams.get("c");
    let data = {
      scheduleId: scheduleId,
      ex_day: ex_day,
      c: c
    };
    await providerForDownload(getSingleDayBreakdownPdf, data, auth.getToken(), {
      desc: "Download Exchange Breakdown of Today's Meal",
      audit_log_clientid: c
    })
      .then(res => {
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          const pdfNewWindow = window.open();
          pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "exchange_breakdown_for_todays_meal.pdf"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const downloadAllDaysBreakdownPdf = async (isPrint = false) => {
    setLoader(true);
    const urlParams = new URLSearchParams(window.location.search);
    let scheduleId = urlParams.get("s");
    let c = urlParams.get("c");
    let data = {
      scheduleId: scheduleId,
      c: c
    };
    await providerForDownload(getAllDaysBreakdownPdf, data, auth.getToken(), {
      desc: "Download Exchange Breakdown For All Meals Week",
      audit_log_clientid: c
    })
      .then(res => {
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          const pdfNewWindow = window.open();
          pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "exchange_breakdown_for_all_meals_week.pdf"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const handleGenerateShoppingList = async (isPrint = false) => {
    setLoader(true);
    let data = {
      schedule_id: mealForm.scheduleId,
      c: mealForm.clientId
    };
    await providerForDownload(generateShoppingList, data, auth.getToken(), {
      desc: "Download report for shopping list",
      audit_log_clientid: mealForm.clientId
    })
      .then(res => {
        setLoader(false);
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          const pdfNewWindow = window.open();
          pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "shopping_list.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  /**
   *  "Exchange Breakdown of Today's Meal",
      "Exchange Breakdown For All Meals Week",
      "Specific Food Break Down for Todays meals",
      "Actual food Breakdown for All Meals Week",
      "Menu for today's meal",
      "Menu for all meals for a week",
      "Shopping List"
   */

  const downloadReport = () => {
    if (isEmptyString(mealForm.report)) {
      setStatusVariable(statusVariable => ({
        ...statusVariable,
        isOpenSnackBar: true,
        isError: "error",
        successErrorMessage: "Please select a report"
      }));
    }
    if (mealForm.report === "Menu for today's meal") {
      getPdfForTodaysMeal();
    } else if (mealForm.report === "Menu for all meals for a week") {
      getPdfForWeeksMeal();
    } else if (mealForm.report === "Exchange Breakdown of Today's Meal") {
      downloadSingleDayBreakdownPdf();
    } else if (mealForm.report === "Exchange Breakdown For All Meals Week") {
      downloadAllDaysBreakdownPdf();
    } else if (
      mealForm.report === "Specific Food Break Down for Todays meals"
    ) {
      handleSpecificMeals();
    } else if (mealForm.report === "Actual food Breakdown for All Meals Week") {
      handleActualFoodForAllMeals();
    } else if (mealForm.report === "Shopping List") {
      handleGenerateShoppingList();
    }
  };

  const handlePrintReport = rowData => {
    if (rowData.name === "Menu for today's meal") {
      getPdfForTodaysMeal(true);
    } else if (rowData.name === "Menu for all meals for a week") {
      getPdfForWeeksMeal(true);
    } else if (rowData.name === "Exchange Breakdown of Today's Meal") {
      downloadSingleDayBreakdownPdf(true);
    } else if (rowData.name === "Exchange Breakdown For All Meals Week") {
      downloadAllDaysBreakdownPdf(true);
    } else if (rowData.name === "Specific Food Break Down for Todays meals") {
      handleSpecificMeals(true);
    } else if (rowData.name === "Actual food Breakdown for All Meals Week") {
      handleActualFoodForAllMeals(true);
    } else if (rowData.name === "Shopping List") {
      handleGenerateShoppingList(true);
    }

    closeReortDwonloadDialogBox();
  };

  const openDownloadReportPopUp = () => {
    setOpenReportDownload(true);
  };

  const handleContinue = async () => {
    let counter = 0;
    let days = "";
    let daysArr = [];
    let num = 0;

    Object.keys(daysMap).map(d => {
      if (!daysMap[d]) {
        counter += 1;
      } else {
        if (!num) {
          days = d;
        } else {
          days = days + ", " + d;
        }
        num = num + 1;
        daysArr.push(d);
      }
      return null;
    });
    if (counter === Object.keys(daysMap).length) {
      setDupError(true);
    } else {
      setDialog(dialog => ({
        ...dialog,
        open: true,
        children:
          "This will duplicate exchanges and meals from day " +
          mealForm.exchangeDay +
          " into days " +
          days +
          " overriding previous data, are you sure want to continue?",
        title: "Duplicate warning",
        isDuplicate: false,
        isReplicate: false,
        isDuplicateOk: true
      }));
      if (dialog.isDuplicateOk) {
        /** saving previous data  */

        setLoader(true);
        await providerForPost(
          saveAllMealsGoals,
          {
            exchangeDay: mealForm.exchangeDay,
            scheduleId: mealForm.scheduleId,
            mealValues: mealForm.mealValues,
            c: mealForm.clientId
          },
          Auth.getToken(),
          {
            desc: "Saved the meals of day " + mealForm.exchangeDay,
            audit_log_clientid: mealForm.clientId
          }
        )
          .then(async res => {
            setDialog(dialog => ({
              ...dialog,
              open: false,
              children: "",
              title: "",
              isDuplicate: false,
              isReplicate: false,
              isDuplicateOk: false
            }));
            setLoader(true);

            await providerForPost(
              replicateMeal,
              {
                fromDay: mealForm.exchangeDay,
                toDay: daysArr,
                schedule_id: mealForm.scheduleId,
                c: mealForm.clientId
              },
              Auth.getToken(),
              {
                desc:
                  "Duplicate the meals and menus from day " +
                  mealForm.exchangeDay +
                  " into days " +
                  days,
                audit_log_clientid: mealForm.clientId
              }
            )
              .then(res => {
                setLoader(false);
                getAllMealInfo();
              })
              .catch(error => {
                setLoader(false);
              });
          })
          .catch(err => {
            setLoader(false);
          });
      }
    }
  };

  const handleDialogOk = async () => {
    /** saving previous data  */
    setLoader(true);
    await providerForPost(
      saveAllMealsGoals,
      {
        exchangeDay: mealForm.exchangeDay,
        scheduleId: mealForm.scheduleId,
        mealValues: mealForm.mealValues,
        c: mealForm.clientId
      },
      Auth.getToken(),
      {
        desc: "Saved the meals of day " + mealForm.exchangeDay,
        audit_log_clientid: mealForm.clientId
      }
    )
      .then(async res => {
        let isReplicate = dialog.isReplicate;
        if (isReplicate) {
          setDialog(dialog => ({
            ...dialog,
            open: false,
            children: "",
            title: "",
            isDuplicate: false,
            isReplicate: false,
            isDuplicateOk: false
          }));

          setLoader(true);
          await providerForPost(
            replicateMeal,
            {
              fromDay: 1,
              toDay: [2, 3, 4, 5, 6, 7],
              schedule_id: mealForm.scheduleId,
              c: mealForm.clientId
            },
            Auth.getToken(),
            {
              desc: "Replicate the meals and menus from day 1 into days 2-7",
              audit_log_clientid: mealForm.clientId
            }
          )
            .then(res => {
              getAllMealInfo();
              setLoader(false);
            })
            .catch(error => {
              setLoader(false);
            });
        }
      })
      .catch(err => {
        setLoader(false);
      });
  };

  const handleDialogCancel = () => {
    setDialog(dialog => ({
      ...dialog,
      open: false,
      children: "",
      title: "",
      isReplicate: false,
      isDuplicate: false,
      isDuplicateOk: false
    }));
  };

  const handleOpenReplicate = () => {
    setDialog(dialog => ({
      ...dialog,
      isReplicate: true,
      open: true,
      children:
        "This will replicate the meals and menus from day 1 into days 2-7, overwriting any previous meals and menus. Are you sure you want to continue?",
      title: "Replicate"
    }));
  };

  const handleChangeDuplicateMeals = (event, s) => {
    if (event.checked) {
      setDupError(false);
    }
    setDaysMap(daysMap => ({
      ...daysMap,
      [s]: event.checked
    }));
  };

  const handleOpenDuplicate = () => {
    let days = [1, 2, 3, 4, 5, 6, 7];
    var index = days.indexOf(mealForm.exchangeDay);
    if (index !== -1) {
      days.splice(index, 1);
    }
    let d_Map = {};
    days.map(d => {
      d_Map = {
        ...d_Map,
        [d]: false
      };
      return null;
    });
    setDaysMap(d_Map);
    setDialog(dialog => ({
      ...dialog,
      isDuplicate: true,
      open: true,
      title: "Duplicate"
    }));
  };

  const handleLoadFavorites = () => {
    setFavorites(favorites => ({
      ...favorites,
      openDialogBox: true
    }));
  };

  const handleChangeFavouriteName = e => {
    setFavorites(favorites => ({
      ...favorites,
      templateName: e.target.value,
      errorText: "",
      isTemplateError: false
    }));
  };

  const convertTableData = res => {
    let arr = [];
    if (res && res.length > 0) {
      for (let i in res) {
        let tempArr = {};
        tempArr["id"] = res[i].id;
        tempArr["name"] = res[i].template_name;
        arr.push(tempArr);
      }
      return arr;
    } else {
      return [];
    }
  };

  const getExchangeMealTemplateData = async (page, pageSize) => {
    /**
     ** It sets the page and pageSize in filter variable
     ** so as to keep the record of what page and pageSize was set
     */
    let apiUrl = "";
    apiUrl = getExchangesAndMenuTemplate;

    setFilter(filter => ({
      ...filter,
      page: page,
      pageSize: pageSize
    }));
    let params = { page: page, pageSize: pageSize };
    Object.keys(filter).map(res => {
      if (!params.hasOwnProperty(res)) {
        params[res] = filter[res];
      }
      return null;
    });
    params.desc = "Get existing Exchange Meal templates";
    return new Promise((resolve, reject) => {
      fetch(apiUrl + "?" + new URLSearchParams(params), {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth.getToken()
        }
      })
        .then(response => response.json())
        .then(result => {
          resolve({
            data: convertTableData(result.data),
            page: result.page - 1,
            totalCount: result.totalCount
          });
        });
    });
  };

  const handleLoadTemplate = async id => {
    setLoader(true);
    setFavorites(favorites => ({
      ...favorites,
      openDialogBox: false,
      openMealsTemplateDialogBox: false
    }));
    await providerForPost(
      loadFavoritesForMeals,
      {
        templateId: id,
        scheduleId: mealForm.scheduleId,
        c: mealForm.clientId
      },
      auth.getToken(),
      { desc: "Load favorites for meal" }
    )
      .then(res => {
        setFavorites(favorites => ({
          ...favorites,
          isEdit: true,
          templateId: id,
          openDialogBox: false,
          openMealsTemplateDialogBox: false,
          errorText: "",
          isTemplateError: false,
          templateName: ""
        }));
        setLoader(false);
        window.location.reload();
      })
      .catch(err => {
        setFavorites(favorites => ({
          ...favorites,
          openDialogBox: false,
          openMealsTemplateDialogBox: false,
          errorText: "",
          isTemplateError: false,
          templateName: ""
        }));
        setLoader(false);
      });
  };

  const handleCreateNewTemplate = async () => {
    setFavorites(favorites => ({
      ...favorites,
      isEdit: false,
      templateName: "",
      errorText: "",
      isTemplateError: false,
      openDialogBox: false,
      openMealsTemplateDialogBox: true
    }));
  };

  const saveFavorite = async (
    template_id = null,
    isUpdate = false,
    templateName = ""
  ) => {
    setLoader(true);
    await providerForPost(
      saveFavoritesForMeal,
      {
        templateId: template_id,
        scheduleId: mealForm.scheduleId,
        isUpdate: isUpdate,
        templateName: templateName,
        c: mealForm.clientId
      },
      auth.getToken(),
      {
        desc: "Save Favorites for a meal"
      }
    )
      .then(res => {
        setFavorites(favorites => ({
          ...favorites,
          isEdit: true,
          templateId: res.data,
          openDialogBox: false,
          openMealsTemplateDialogBox: false,
          errorText: "",
          isTemplateError: false,
          templateName: ""
        }));
        setLoader(false);
      })
      .catch(error => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isSaving: false,
            isOpenSnackBar: true,
            isError: "error",
            successErrorMessage: ERROR_MESSAGE + error.response.data.message
          }));
        } else {
          setFavorites(favorites => ({
            ...favorites,
            openDialogBox: false,
            openMealsTemplateDialogBox: false,
            errorText: "",
            isTemplateError: false,
            templateName: ""
          }));
        }
        setLoader(false);
      });
  };

  const handleOk = async () => {
    if (isEmptyString(favorites.templateName)) {
      setFavorites(favorites => ({
        ...favorites,
        errorText: "Favorite Name cannot be empty",
        isTemplateError: true
      }));
    } else {
      await saveFavorite(null, false, favorites.templateName);
    }
  };

  const updateExistingTemplate = async () => {
    await saveFavorite(favorites.templateId, true, favorites.templateName);
  };

  const handleSnackbarClose = () => {
    setStatusVariable(statusVariable => ({
      ...statusVariable,
      isOpenSnackBar: false,
      isError: "",
      successErrorMessage: ""
    }));
  };

  const handleAddNewMeal = async () => {
    setLoader(true);
    setLoader1(true);
    await handleSave();
    let ex_day = mealForm.exchangeDay;
    await providerForPut(
      getClientScheduleData,
      urlParams.get("s"),
      {
        [`no_of_meals_per_day_${ex_day}`]: defaultMeals.length + 1
      },
      auth.getToken()
    )
      .then(res => {
        let no_of_meals = res.data[`no_of_meals_per_day_${ex_day}`];
        let no_of_meals_arr = Array.from(
          { length: parseInt(no_of_meals) },
          (_, i) => i + 1
        );
        setMealForm(mealForm => ({
          ...mealForm,
          mealPerDay: no_of_meals
        }));
        setDefaultMeals(no_of_meals_arr);
        getAllMealInfo(ex_day, avgFoodCat, no_of_meals_arr);
      })
      .catch(error => {
        setLoader(false);
        setLoader1(false);
      });
  };

  return (
    <>
      <Prompt
        message={(location, action) =>
          props && props.isButtonClicked ? null : handleSave()
        }
      />
      <Grid container className={localClasses.root} spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            className={localClasses.favouriteClass}
          >
            <WhiteIconButton
              variant="contained"
              size="small"
              style={{
                marginRight: theme.spacing(1)
              }}
              onClick={() => handleLoadFavorites()}
              disabled={mealForm.isActive === false}
            >
              LOAD FAVORITES
            </WhiteIconButton>
            <WhiteIconButton
              variant="contained"
              size="small"
              style={{
                marginRight: theme.spacing(1),
                marginLeft: theme.spacing(1)
              }}
              disabled={mealForm.isActive === false}
              onClick={() => handleSaveFavorites()}
            >
              SAVE FAVORITES
            </WhiteIconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid
            xs={12}
            md={12}
            lg={12}
            container
            item
            justify="flex-end"
            alignItems="flex-start"
          >
            <DarkBlueButton
              variant="contained"
              size="small"
              style={{
                marginRight: theme.spacing(1),
                marginLeft: theme.spacing(1)
              }}
              onClick={handleCreateMeals}
            >
              CREATE MEALS
            </DarkBlueButton>
            <DarkBlueButton
              variant="contained"
              size="small"
              style={{
                marginRight: 0,
                marginLeft: theme.spacing(1)
              }}
              onClick={() => handleSave(true)}
              disabled={mealForm.isActive === false}
            >
              Save
            </DarkBlueButton>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            className={localClasses.mealForDayGrid}
          >
            <SelectDropdown
              title=" Meal for day"
              value={mealForm.exchangeDay}
              onChange={handleChange}
            >
              {exchangeDayList.map(day => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              ))}
            </SelectDropdown>

            <div className={localClasses.duplicateReplicateStyle}>
              <DarkBlueButton
                variant="contained"
                size="small"
                style={{
                  marginRight: 0,
                  marginLeft: theme.spacing(1)
                }}
                onClick={handleOpenDuplicate}
                disabled={mealForm.isActive === false}
              >
                Duplicate
              </DarkBlueButton>
              <DarkBlueButton
                variant="contained"
                size="small"
                style={{
                  marginRight: 0,
                  marginLeft: theme.spacing(1)
                }}
                disabled={mealForm.isActive === false}
                onClick={handleOpenReplicate}
              >
                Replicate
              </DarkBlueButton>
            </div>
          </Grid>
          {mealForm.isValuesCalculated && !loader1 ? (
            <AllMealsOfADayGrid
              isDesktop={isDesktop}
              defaultMeals={defaultMeals}
              mealForm={mealForm}
              isActive={mealForm.isActive}
              handleMealChange={handleMealChange}
              loader1={loader1}
              handleAddNewMeal={handleAddNewMeal}
              totalValues={Object.keys(mealForm.mealValues).length}
            />
          ) : null}
          &nbsp;
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FormControl
              variant="outlined"
              className={localClasses.formControl}
            >
              <Typography className={localClasses.selectBoxLabel}>
                Report
              </Typography>
              <Select
                labelId="report-download-label-id"
                id="report-download-id"
                value={mealForm.report}
                onChange={handleChangeReport}
                className={localClasses.selectBox}
                style={{ width: 270 }}
                displayEmpty
                renderValue={selected => {
                  if (selected.length === 0) {
                    return <em>Select a report</em>;
                  }
                  return selected;
                }}
              >
                <MenuItem value="">
                  <em>Select a report</em>
                </MenuItem>
                {reportList.map(day => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <IconButton
              variant="contained"
              size="small"
              style={{
                marginTop: theme.spacing(1),
                background: "none"
              }}
              onClick={downloadReport}
            >
              <Tooltip title="Download Report">
                <GetAppIcon style={{ color: "#1C4979", fontSize: 30 }} />
              </Tooltip>
            </IconButton>

            <IconButton
              variant="contained"
              size="small"
              style={{
                marginTop: theme.spacing(1),
                background: "none"
              }}
              onClick={openDownloadReportPopUp}
            >
              <Tooltip title="Reports to print">
                <PrintIcon style={{ color: "#1C4979", fontSize: 30 }} />
              </Tooltip>
            </IconButton>
          </div>

          <DialogBox
            open={openReportDownload}
            title="Select report to print"
            buttonCancel="Cancel"
            handleCancel={closeReortDwonloadDialogBox}
            skipOk={true}
          >
            <Table
              columns={columns}
              data={reportListObj}
              actions={[
                {
                  icon: () => <PrintIcon />,
                  tooltip: "Print",
                  onClick: (event, rowData) => {
                    handlePrintReport(rowData);
                  }
                }
              ]}
              options={{
                actionsColumnIndex: -1,
                search: false,
                sorting: false,
                paging: false,
                thirdSortClick: false,
                headerStyle: {
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  color: "#8A8A97",
                  borderBottom: "solid #E0E0E0 2px",
                  fontSize: "1rem"
                },
                cellStyle: {
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  color: "#000000",
                  fontSize: "1rem"
                }
              }}
            />
          </DialogBox>

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{
              textAlign: "center",
              width: "100%",
              marginTop: "0px",
              overflow: "auto"
            }}
          >
            {loader1 ? null : (
              <ShowAllMealGoalsForADay goalValues={goalValues} />
            )}
          </Grid>
        </Grid>
        <DialogBox
          skipCancel={false}
          skipOk={false}
          open={dialog.open}
          title={dialog.title}
          children={
            dialog.isReplicate ? (
              dialog.children
            ) : dialog.isDuplicateOk ? (
              dialog.children
            ) : dialog.isDuplicate ? (
              <DuplicateMealDialogContent
                dupError={dupError}
                daysMap={daysMap}
                handleChange={handleChangeDuplicateMeals}
                exchangeDay={mealForm.exchangeDay}
              />
            ) : null
          }
          handleOk={dialog.isReplicate ? handleDialogOk : handleContinue}
          handleCancel={handleDialogCancel}
          buttonCancel={"Cancel"}
          buttonOk={
            dialog.isReplicate ? "Ok" : dialog.isDuplicateOk ? "Ok" : "Continue"
          }
        />

        <Backdrop className={localClasses.backdrop} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <DialogBox
          open={favorites.openMealsTemplateDialogBox}
          title="Save Exchanges/Meals Favorite"
          buttonCancel="Cancel"
          handleCancel={closeDialogBox}
          skipOk={favorites.isEdit === true ? true : false}
          buttonOk={favorites.isEdit === true ? null : "Ok"}
          handleOk={favorites.isEdit === true ? null : handleOk}
        >
          {favorites.isEdit === true ? (
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Typography gutterBottom>
                  Update existing Exchanges/Meals favorite?
                </Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <DarkBlueButton
                  variant={"contained"}
                  onClick={updateExistingTemplate}
                >
                  Ok
                </DarkBlueButton>
              </Grid>
              <Grid item md={6} xs={12}>
                <DarkBlueButton
                  variant={"contained"}
                  onClick={handleCreateNewTemplate}
                >
                  Create new
                </DarkBlueButton>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Input
                  label={"Favorite Name"}
                  name={"meal_favorite"}
                  value={favorites.templateName}
                  error={favorites.isTemplateError}
                  placeholder={"Favorite Name"}
                  variant="outlined"
                  required
                  fullWidth
                  onChange={handleChangeFavouriteName}
                  helperText={favorites.errorText}
                />
              </Grid>
            </Grid>
          )}
        </DialogBox>
        <DialogBox
          open={favorites.openDialogBox}
          title="Select Exchanges/Meals Favorite"
          buttonCancel="Cancel"
          handleCancel={closeDialogBox}
          skipOk={true}
        >
          <Table
            tableRef={tableRef}
            columns={columns}
            data={async query => {
              return await getExchangeMealTemplateData(
                query.page + 1,
                query.pageSize
              );
            }}
            actions={[
              {
                icon: () => <LightGrayButton>Use</LightGrayButton>,
                tooltip: "Use this favorite",
                onClick: (event, rowData) => {
                  handleLoadTemplate(rowData.id);
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              search: false,
              sorting: false,
              pageSize: 5,
              thirdSortClick: false,
              headerStyle: {
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "#8A8A97",
                borderBottom: "solid #E0E0E0 2px",
                fontSize: "1rem"
              },
              cellStyle: {
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "#000000",
                fontSize: "1rem"
              },
              paginationStyle: {
                justifyContent: "center"
              }
            }}
          />
        </DialogBox>
        <Grid>
          <SnackBar
            open={statusVariable.isOpenSnackBar}
            severity={statusVariable.isError}
            onClose={handleSnackbarClose}
          >
            {statusVariable.successErrorMessage}
          </SnackBar>
        </Grid>
      </Grid>
    </>
  );
};

export default Exchange;
