import React from "react";
import styles from "./DateRange.module.css";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Popover,
  Typography
} from "@material-ui/core";
import InlineDatePicker from "../InlineDatePicker";
import DarkBlueButton from "../DarkBlueButton";
import { useStyles } from "../../utils";
import { Input } from "../Form/Input";

const DateRange = props => {
  const classes = useStyles();

  return (
    <div className={styles.DateRange}>
      <Typography className={classes.typography}>
        {"Date Range :  "}
        <DarkBlueButton
          aria-describedby={props.id}
          variant="contained"
          onClick={props.onClick}
        >
          {props.filterButtonName}
        </DarkBlueButton>
      </Typography>
      <Popover
        id={props.id}
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Grid xs={12} md={12} className={classes.checkBox}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.recentVisits}
                onChange={props.handlePopUpCheckBox}
                name="recentVisits"
              />
            }
            label="Recent 6 Visits"
          />
        </Grid>
        <Grid xs={12} md={12} className={classes.checkBox}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.selectDateRange}
                onChange={props.handlePopUpCheckBox}
                name="selectDateRange"
              />
            }
            label="Select Date Range"
          />

          {props.selectDateRange ? (
            <div>
              <Grid container spacing={1}>
                <Grid item className={classes.paddingDate}>
                  <InlineDatePicker
                    id="startDate"
                    label="From Date"
                    placeholder="From Date"
                    value={props.valueFromDate}
                    name={"From Date"}
                    onChange={props.handleStartDateChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item className={classes.paddingDate}>
                  <InlineDatePicker
                    id="endDate"
                    label="To Date"
                    placeholder="To Date"
                    value={props.valueToDate}
                    name={"To Date"}
                    onChange={props.handleEndDateChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>

              {props.dateFromError ? (
                <Grid item>
                  <FormHelperText className={classes.dateErrorText}>
                    {props.dateFromErrorText}
                  </FormHelperText>
                </Grid>
              ) : null}
              {props.dateToError ? (
                <Grid item>
                  <FormHelperText className={classes.dateErrorText}>
                    {props.dateToErrorText}
                  </FormHelperText>
                </Grid>
              ) : null}
            </div>
          ) : null}
        </Grid>
        <Grid xs={12} md={12} className={classes.checkBox}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.noOfVisits}
                onChange={props.handlePopUpCheckBox}
                name="noOfVisits"
              />
            }
            label="No. of Visits"
          />
          {props.noOfVisits ? (
            <div>
              <Input
                name="noOfVisits"
                type="number"
                placeholder="No. of Visits"
                value={props.valueNoOfVisitis}
                onChange={props.handleInputChange}
                variant="outlined"
                className={classes.inputClass}
              />
              {props.dateNoOfVisitsError ? (
                <Grid item>
                  <FormHelperText className={classes.dateErrorText}>
                    {props.dateNoOfVisitsErrorText}
                  </FormHelperText>
                </Grid>
              ) : null}
            </div>
          ) : null}
        </Grid>

        <Grid item className={classes.filterByButton}>
          <DarkBlueButton
            variant="contained"
            color="primary"
            onClick={props.handleClickFilterDoneButton}
            style={{
              marginRight: "1%"
            }}
          >
            Done
          </DarkBlueButton>
          <DarkBlueButton
            variant="contained"
            color="primary"
            onClick={props.handleClickFilterResetButton}
          >
            Reset
          </DarkBlueButton>
        </Grid>
      </Popover>
    </div>
  );
};

DateRange.propTypes = {};

DateRange.defaultProps = {};

export default DateRange;
