import React, { useState, useEffect } from "react";
import styles from "./AddEditNotes.module.css";
import { Backdrop, Grid, Tooltip } from "@material-ui/core";
import {
  HeadingOne,
  Auth,
  DarkBlueButton,
  SnackBar,
  InlineDatePicker,
  NotFoundPage,
  Input
} from "../../components";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { providerForGet, providerForPost, providerForPut } from "../../api";
import { CLIENTMYNOTES } from "../../paths";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CircularProgress,
  IconButton,
  LinearProgress
} from "material-ui";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PublishIcon from "@material-ui/icons/Publish";
import { notes } from "../../constants";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import "./ReactQuill.css";
import { formatDate, getFormattedDate } from "../../utils";
import moment from "moment";

const localStyles = makeStyles(theme => ({
  titleText: {
    color: "#110F48",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1.25rem",
    marginBottom: "1.5625rem",
    "& svg": {
      verticalAlign: "middle"
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: "#fff"
  },
  scheduleDate: {
    marginBottom: 25
  },
  textDesign: {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.5",
    letterSpacing: "-0.015em",
    color: "#000000"
  },
  textBox: {
    border: "1px solid lightgray"
  },
  iconStyle: {
    paddingTop: "4px",
    paddingBottom: "4px"
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  dialogBoxDesign: {
    "& MuiPaper-root": {
      width: 900
    }
  },
  notesSection: {
    marginTop: "1.25rem",
    width: "500px"
  },
  imageDiv: {
    marginTop: "10px"
  },
  DefaultNoImage: {
    width: "750px",
    height: "200px",
    objectFit: "contain",
    backgroundColor: "#FFFFFF"
  },
  UploadImage: {
    width: "100%",
    height: "200px",
    objectFit: "contain",
    backgroundColor: "#FFFFFF"
  },
  centerImage: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "30%"
  }
}));

const AddEditNotes = props => {
  const classes = localStyles();
  let propData = props["location"]["state"];
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  const [formState, setFormState] = useState({
    isClient:
      Auth.getUserInfo().role.name === process.env.REACT_APP_CLIENT_ROLE_NAME,
    isNutritionist:
      Auth.getUserInfo().role.name === process.env.REACT_APP_NUTRITIONIST_ROLE,
    isBackAvailable: propData ? propData.isBackAvailable : false,
    backPath: propData
      ? propData.isBackAvailable
        ? propData.backPath
        : null
      : null,
    backName: propData
      ? propData.isBackAvailable
        ? propData.backName
        : ""
      : "",
    customHeader: propData ? propData.customHeader : "NOTES",
    scheduleId: propData
      ? propData["scheduleId"]
        ? propData["scheduleId"]
        : null
      : null,
    scheduleObj: propData
      ? propData["scheduleObj"]
        ? propData["scheduleObj"]
        : null
      : null,
    isError: false
  });

  const [noteData, setNoteData] = useState({
    isEdit: propData
      ? propData["isEdit"]
        ? propData["isEdit"]
        : false
      : false,
    note: "",
    noteId: propData ? (propData["noteId"] ? propData["noteId"] : null) : null,
    note_type: formState.isClient ? "client" : "nutritionist",
    notes_meta_data: "",
    date: new Date(),
    user: formState.isClient
      ? Auth.getUserInfo().id
      : propData
      ? propData["userId"]
        ? propData["userId"]
        : null
      : null,
    is_read: false,
    franchise: Auth.getUserInfo().franchise.id,
    private: propData
      ? propData["private"]
        ? propData["private"]
        : false
      : false
  });

  const [statusVariable, setStatusVariable] = useState({
    severity: "",
    isOpenSnackBar: false,
    successErrorMessage: ""
  });

  //regex for validsating richtext field
  const regexForRichtext = /(<([^>]+)>)/gi;

  const getData = async () => {
    let params = {
      desc: `${Auth.getUserInfo().full_name} viewed their note with id ${
        noteData.noteId
      }`
    };
    if (formState.isNutritionist) {
      params = {
        ...params,
        audit_log_clientid: noteData.user
      };
    }
    await providerForGet(notes + "/" + noteData.noteId, params, Auth.getToken())
      .then(res => {
        if (Object.keys(res.data).length) {
          setNoteData(noteData => ({
            ...noteData,
            isEdit: true,
            note: res.data.notes,
            noteId: res.data.id,
            note_type: res.data.note_type,
            notes_meta_data: res.data.notes_meta_data,
            date: new Date(res.data.date),
            user: res.data.user.id,
            is_read: res.data.is_read,
            franchise: res.data.franchise.id,
            private: res.data.private
          }));
        } else {
          setFormState(formState => ({
            ...formState,
            isError: true
          }));
        }
        setLoader(false);
      })
      .catch(error => {
        setFormState(formState => ({
          ...formState,
          isError: true
        }));
        setLoader(false);
      });
  };

  useEffect(() => {
    if (noteData.isEdit && noteData.noteId && noteData.user) {
      setLoader(true);
      getData();
    } else {
      if (formState.isNutritionist && !noteData.user) {
        setFormState(formState => ({
          ...formState,
          isError: true
        }));
      } else {
        setNoteData(noteData => ({
          ...noteData,
          isEdit: false,
          note: "",
          noteId: null,
          note_type: formState.isClient ? "client" : "nutritionist",
          notes_meta_data: "",
          date: new Date(noteData.date),
          user: formState.isClient ? Auth.getUserInfo().id : noteData.user,
          is_read: false,
          franchise: noteData.franchise,
          private: noteData.private
        }));
      }
    }
  }, []);

  const imageUploadCallBack = file =>
    new Promise((resolve, reject) => {
      setStatusVariable(statusVariable => ({
        ...statusVariable,
        severity: "",
        isOpenSnackBar: false,
        successErrorMessage: ""
      }));
      var reader = new FileReader();
      reader.readAsDataURL(file);
      let img = new Image();
      reader.onload = function (e) {
        img.src = this.result;
      };
      img.onload = function () {
        // Zoom the canvas needed for the image (you can also define the canvas tag directly in the DOM
        // so that the compressed image can be directly displayed without going to base64)
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");

        // image original size
        var originWidth = this.width;
        var originHeight = this.height;

        // Maximum size limit, you can achieve image compression by setting the width and height
        var maxWidth = 400,
          maxHeight = 500;
        // target size
        var targetWidth = originWidth,
          targetHeight = originHeight;
        // Image size exceeds 300x300 limit
        if (originWidth > maxWidth || originHeight > maxHeight) {
          if (originWidth / originHeight > maxWidth / maxHeight) {
            // wider, size limited by width
            targetWidth = maxWidth;
            targetHeight = Math.round(maxWidth * (originHeight / originWidth));
          } else {
            targetHeight = maxHeight;
            targetWidth = Math.round(maxHeight * (originWidth / originHeight));
          }
        }
        // canvas scales the image
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        // clear the canvas
        context.clearRect(0, 0, targetWidth, targetHeight);
        // Image Compression
        context.drawImage(img, 0, 0, targetWidth, targetHeight);
        // The first parameter is the created img object; the second three parameters are the upper left corner coordinates
        // and the second two are the canvas area width and height

        // compressed image to base64 url
        /*canvas.toDataURL(mimeType, qualityArgument), mimeType The default value is 'image/png';
         * qualityArgument indicates the quality of the exported image. This parameter is valid only when exported to jpeg and webp formats. The default value is 0.92*/
        var newUrl = canvas.toDataURL("image/jpeg", 0.3); //base64 format

        resolve({
          data: {
            link: newUrl
          }
        });
      };
    });

  const handleSnackbarClose = () => {
    setStatusVariable(statusVariable => ({
      ...statusVariable,
      severity: "",
      isOpenSnackBar: false,
      successErrorMessage: ""
    }));
  };

  const handleUploadImage = async event => {
    if (
      event.target.name === "notesLogo" &&
      event.target.files &&
      event.target.files.length &&
      event.target.files[0].type
    ) {
      if (
        !(
          event.target.files[0].type === "image/jpg" ||
          event.target.files[0].type === "image/jpeg" ||
          event.target.files[0].type === "image/png"
        )
      ) {
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          severity: "error",
          isOpenSnackBar: true,
          successErrorMessage: "Image format not supported"
        }));
      } else if (event.target.files[0].size > 5000000) {
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          severity: "error",
          isOpenSnackBar: true,
          successErrorMessage: "File size cannot be more than 5 Mb"
        }));
      } else {
        let image = await imageUploadCallBack(event.target.files[0]);
        let data = `<div> <img src=${image.data.link}></div>`;
        setNoteData(noteData => ({
          ...noteData,
          note: noteData.note + data
        }));
      }
    }
  };

  function extractContent(s) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }
  const richTextFieldValidation = () => {
    let hasText = !!noteData.note.replace(regexForRichtext, "").length;
    if (hasText) {
      return true;
    } else {
      return false;
    }
  };

  const handleSaveAndExit = async () => {
    //calling save function first then back function
    setLoader(true);
    let data = {
      ...propData.backProps
    };
    let meta_data = extractContent(noteData.note).slice(0, 10) + "...";
    let validateRichText = richTextFieldValidation();
    if (validateRichText) {
      if (noteData.isEdit) {
        await providerForPut(
          notes,
          noteData.noteId,
          {
            notes: noteData.note,
            note_type: noteData.note_type,
            notes_meta_data: meta_data,
            date: formatDate(noteData.date),
            user: noteData.user,
            is_read: false,
            franchise: Auth.getUserInfo().franchise.id,
            private: noteData.private
          },
          Auth.getToken(),
          {
            desc: `${
              Auth.getUserInfo().full_name
            } updated notes of date ${getFormattedDate(noteData.date)}`
          }
        )
          .then(res => {
            setLoader(false);
            data["successErrorMessage"] = "Notes updated successfully";
            history.push(CLIENTMYNOTES, data);
          })
          .catch(error => {
            setLoader(false);
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              severity: "error",
              isOpenSnackBar: true,
              successErrorMessage: "An error occured! " + error
            }));
          });
      } else {
        await providerForPost(
          notes,
          {
            notes: noteData.note,
            note_type: noteData.note_type,
            notes_meta_data: meta_data,
            date: formatDate(noteData.date),
            user: noteData.user,
            is_read: false,
            franchise: Auth.getUserInfo().franchise.id,
            private: noteData.private
          },
          Auth.getToken(),
          {
            desc: `${
              Auth.getUserInfo().full_name
            } added notes of date ${getFormattedDate(noteData.date)}`
          }
        )
          .then(res => {
            setLoader(false);
            setNoteData(noteData => ({
              ...noteData,
              isEdit: true,
              noteId: res.data.id
            }));
            data["successErrorMessage"] = "Notes saved successfully";
            history.push(CLIENTMYNOTES, data);
          })
          .catch(error => {
            setLoader(false);
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              severity: "error",
              isOpenSnackBar: true,
              successErrorMessage: "An error occured! " + error
            }));
          });
      }
    } else {
      setLoader(false);
      setStatusVariable(statusVariable => ({
        ...statusVariable,
        severity: "error",
        isOpenSnackBar: true,
        successErrorMessage: "Cannot save empty note"
      }));
    }
  };

  const handleBackArrow = async () => {
    //calling save function first then back function
    setLoader(true);
    let data = {
      ...propData.backProps
    };
    let meta_data = extractContent(noteData.note).slice(0, 10) + "...";
    let validateRichText = richTextFieldValidation();
    if (validateRichText) {
      if (noteData.isEdit) {
        await providerForPut(
          notes,
          noteData.noteId,
          {
            notes: noteData.note,
            note_type: noteData.note_type,
            notes_meta_data: meta_data,
            date: formatDate(noteData.date),
            user: noteData.user,
            is_read: false,
            franchise: Auth.getUserInfo().franchise.id,
            private: noteData.private
          },
          Auth.getToken(),
          {
            desc: `${
              Auth.getUserInfo().full_name
            } updated notes of date ${getFormattedDate(noteData.date)}`
          }
        )
          .then(res => {
            setLoader(false);
            history.push(CLIENTMYNOTES, data);
          })
          .catch(error => {
            setLoader(false);
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              severity: "error",
              isOpenSnackBar: true,
              successErrorMessage: "An error occured! " + error
            }));
          });
      } else {
        await providerForPost(
          notes,
          {
            notes: noteData.note,
            note_type: noteData.note_type,
            notes_meta_data: meta_data,
            date: formatDate(noteData.date),
            user: noteData.user,
            is_read: false,
            franchise: Auth.getUserInfo().franchise.id,
            private: noteData.private
          },
          Auth.getToken(),
          {
            desc: `${
              Auth.getUserInfo().full_name
            } added notes of date ${getFormattedDate(noteData.date)}`
          }
        )
          .then(res => {
            setLoader(false);
            setNoteData(noteData => ({
              ...noteData,
              isEdit: true,
              noteId: res.data.id
            }));
            history.push(CLIENTMYNOTES, data);
          })
          .catch(error => {
            setLoader(false);
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              severity: "error",
              isOpenSnackBar: true,
              successErrorMessage: "An error occured! " + error
            }));
          });
      }
    } else {
      history.push(CLIENTMYNOTES, data);
    }
  };

  const saveData = async () => {
    setLoader(true);
    let meta_data = extractContent(noteData.note).slice(0, 10) + "...";
    let validateRichText = richTextFieldValidation();
    if (validateRichText) {
      if (noteData.isEdit) {
        await providerForPut(
          notes,
          noteData.noteId,
          {
            notes: noteData.note,
            note_type: noteData.note_type,
            notes_meta_data: meta_data,
            date: formatDate(noteData.date),
            user: noteData.user,
            is_read: false,
            franchise: Auth.getUserInfo().franchise.id,
            private: noteData.private
          },
          Auth.getToken(),
          {
            desc: `${
              Auth.getUserInfo().full_name
            } updated notes of date ${getFormattedDate(noteData.date)}`
          }
        )
          .then(res => {
            setLoader(false);
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              severity: "success",
              isOpenSnackBar: true,
              successErrorMessage: "Notes updated successfully"
            }));
          })
          .catch(error => {
            setLoader(false);
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              severity: "error",
              isOpenSnackBar: true,
              successErrorMessage: "An error occured! " + error
            }));
          });
      } else {
        await providerForPost(
          notes,
          {
            notes: noteData.note,
            note_type: noteData.note_type,
            notes_meta_data: meta_data,
            date: formatDate(noteData.date),
            user: noteData.user,
            is_read: false,
            franchise: Auth.getUserInfo().franchise.id,
            private: noteData.private
          },
          Auth.getToken(),
          {
            desc: `${
              Auth.getUserInfo().full_name
            } added notes of date ${getFormattedDate(noteData.date)}`
          }
        )
          .then(res => {
            setLoader(false);
            setNoteData(noteData => ({
              ...noteData,
              isEdit: true,
              noteId: res.data.id
            }));
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              severity: "success",
              isOpenSnackBar: true,
              successErrorMessage: "Notes saved successfully"
            }));
          })
          .catch(error => {
            setLoader(false);
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              severity: "error",
              isOpenSnackBar: true,
              successErrorMessage: "An error occured! " + error
            }));
          });
      }
    } else {
      setLoader(false);
      setStatusVariable(statusVariable => ({
        ...statusVariable,
        severity: "error",
        isOpenSnackBar: true,
        successErrorMessage: "Cannot save empty note"
      }));
    }
  };

  return (
    <div className={styles.AddEditNotes}>
      {formState.isError ? (
        <NotFoundPage />
      ) : (
        <>
          <SnackBar
            open={statusVariable.isOpenSnackBar}
            severity={statusVariable.severity}
            onClose={handleSnackbarClose}
          >
            {statusVariable.successErrorMessage}
          </SnackBar>
          <Tooltip
            title={
              formState.isBackAvailable ? formState.backName : "Back To Notes"
            }
          >
            <IconButton
              className={styles.backIcon}
              onClick={() => handleBackArrow()}
            >
              <ChevronLeftIcon
                style={{
                  padding: "5px",
                  border: "solid #DADADA 1px",
                  borderRadius: "25px",
                  margin: "0px 1.875rem 0px 10px",
                  background: "#fff",
                  marginTop: 0
                }}
              />
            </IconButton>
          </Tooltip>
          <HeadingOne>
            <FileCopyIcon />
            <span style={{ position: "absolute", marginLeft: "10px" }}>
              {formState.customHeader ? formState.customHeader : "NOTES"}
            </span>
          </HeadingOne>

          <>
            <Grid
              container
              spacing={1}
              style={{
                marginBottom: "1%"
              }}
            >
              <Grid item md={6} xs={12}>
                <InlineDatePicker
                  id="date"
                  label="Date"
                  maxDate={new Date()}
                  placeholder="MM/DD/YYYY"
                  value={noteData.date || ""}
                  name={"Date"}
                  onChange={event => {
                    let convertedDate = moment(event).format(
                      "YYYY-MM-DDT00:00:00.000Z"
                    );
                    let date = null;
                    if (convertedDate !== "Invalid date") {
                      date = new Date(convertedDate).toISOString();
                    }
                    setNoteData(noteData => ({
                      ...noteData,
                      isEdit: false,
                      note: "",
                      noteId: null,
                      note_type: formState.isClient ? "client" : "nutritionist",
                      notes_meta_data: "",
                      date: date,
                      user: formState.isClient
                        ? Auth.getUserInfo().id
                        : noteData.user,
                      is_read: false,
                      franchise: noteData.franchise,
                      private: noteData.private
                    }));
                  }}
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                style={{ justifyContent: "flex-end", textAlign: "end" }}
              >
                <Grid>
                  <Input
                    fullWidth
                    id="upload-logo"
                    name="notesLogo"
                    onClick={event => {
                      event.target.value = null;
                    }}
                    onChange={handleUploadImage}
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    variant="outlined"
                    className={classes.inputFile}
                    style={{
                      display: "none"
                    }}
                  />
                  <label htmlFor={"upload-logo"}>
                    <IconButton
                      variant="contained"
                      size="small"
                      component="span"
                    >
                      <Tooltip title="Upload file">
                        <PublishIcon
                          style={{ color: "#1C4979", fontSize: 40 }}
                        />
                      </Tooltip>
                    </IconButton>
                  </label>
                  <DarkBlueButton
                    variant="contained"
                    size="small"
                    style={{
                      margin: "8px 0 8px 5px"
                    }}
                    onClick={() => saveData()}
                  >
                    SAVE
                  </DarkBlueButton>

                  <DarkBlueButton
                    variant="contained"
                    size="small"
                    style={{
                      margin: "8px 0 8px 5px"
                    }}
                    onClick={() => handleSaveAndExit()}
                  >
                    SAVE & EXIT
                  </DarkBlueButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item style={{ width: "calc(100%)" }}>
                <Card
                  style={{
                    width: "100%",
                    height: "600px"
                  }}
                >
                  {loader ? <LinearProgress /> : null}
                  <div
                    style={{
                      padding: "1%",
                      maxHeight: "560px",
                      overflow: "auto"
                    }}
                    className={styles.editor}
                  >
                    <>
                      <ReactQuill
                        onChange={html => {
                          setNoteData(noteData => ({
                            ...noteData,
                            note: html
                          }));
                        }}
                        theme={null}
                        value={noteData.note}
                        placeholder={"Your Notes Here"}
                      />
                    </>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </>
          <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </div>
  );
};

AddEditNotes.propTypes = {};

AddEditNotes.defaultProps = {};

export default AddEditNotes;
