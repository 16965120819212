import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Card,
  CardContent,
  Grid,
  useMediaQuery,
  useTheme,
  CardHeader,
  Box,
  CircularProgress
} from "@material-ui/core";
import {
  Auth,
  SnackBar,
  DarkBlueButton,
  BorderLessButton,
  HeadingOne
} from "../../components";
import { ColorPicker } from "material-ui-color";
import {
  providerForDelete,
  providerForGet,
  providerForPut,
  providerForPost
} from "../../api";
import { getCustomizationUrl } from "../../constants/urlConstants";
import BarChartIcon from "@material-ui/icons/BarChart";
import TimelineIcon from "@material-ui/icons/Timeline";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const CustomColors = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    table: {
      minWidth: 650
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    responsiveContainerWrap: {
      overflow: "auto",
      height: "550px",
      "& > div": {
        margin: "0 auto"
      }
    }
  }));
  const themes = useTheme();
  const isDesktop = useMediaQuery(themes.breakpoints.up("lg"), {
    defaultMatches: true
  });
  const classes = useStyles();
  const [enabledColor, setEnabledColor] = useState(false);
  const [graphColorData, setGraphColorData] = useState([]);

  const [yAxis, setYAxis] = useState([
    {
      id: 0,
      name: "COLOR_1",
      strokeColor: "",
      areaColor: "",
      show: true
    },
    {
      id: 1,
      name: "COLOR_2",
      strokeColor: "",
      areaColor: "",
      show: true
    },
    {
      id: 2,
      name: "COLOR_3",
      strokeColor: "",
      areaColor: "",
      show: true
    },
    {
      id: 3,
      name: "COLOR_4",
      strokeColor: "",
      areaColor: "",
      show: true
    },
    {
      id: 4,
      name: "COLOR_5",
      strokeColor: "",
      areaColor: "",
      show: true
    },

    {
      id: 5,
      name: "COLOR_6",
      strokeColor: "",
      areaColor: "",
      show: true
    },
    {
      id: 6,
      name: "COLOR_7",
      strokeColor: "",
      areaColor: "",
      show: true
    },
    {
      id: 7,
      name: "COLOR_8",
      strokeColor: "",
      areaColor: "",
      show: true
    },
    {
      id: 8,
      name: "COLOR_9",
      strokeColor: "",
      areaColor: "",
      show: true
    },
    {
      id: 9,
      name: "COLOR_10",
      strokeColor: "",
      areaColor: "",
      show: true
    },
    {
      id: 10,
      name: "COLOR_11",
      strokeColor: "",
      areaColor: "",
      show: true
    },
    {
      id: 11,
      name: "COLOR_12",
      strokeColor: "",
      areaColor: "",
      show: true
    }
  ]);
  //Below data variable is used for line graph
  const data = [
    {
      COLOR_1: 150,
      COLOR_2: 500,
      COLOR_3: 100,
      COLOR_4: 145,
      COLOR_5: 220,
      COLOR_6: 110,
      COLOR_7: 140,
      id: 222,
      COLOR_8: 100,
      COLOR_9: 250,
      start_date: "04/06/2021",
      COLOR_10: 150,
      COLOR_11: 540,
      COLOR_12: 100
    },
    {
      COLOR_1: 100,
      COLOR_2: 300,
      COLOR_3: 250,
      COLOR_4: 155,
      COLOR_5: 270,
      COLOR_6: 410,
      COLOR_7: 360,
      id: 270,
      COLOR_8: 450,
      COLOR_9: 340,
      start_date: "05/06/2021",
      COLOR_10: 164,
      COLOR_11: 450,
      COLOR_12: 250
    },
    {
      COLOR_1: 150,
      COLOR_2: 400,
      COLOR_3: 500,
      COLOR_4: 255,
      COLOR_5: 300,
      COLOR_6: 210,
      COLOR_7: 360,
      id: 201,
      COLOR_8: 150,
      COLOR_9: 150,
      start_date: "05/06/2021",
      COLOR_10: 164,
      COLOR_11: 210,
      COLOR_12: 350
    },
    {
      COLOR_1: 200,
      COLOR_2: 100,
      COLOR_3: 300,
      COLOR_4: 125,
      COLOR_5: 400,
      COLOR_6: 510,
      COLOR_7: 275,
      id: 290,
      COLOR_8: 490,
      COLOR_9: 190,
      start_date: "05/06/2021",
      COLOR_10: 90,
      COLOR_11: 330,
      COLOR_12: 375
    }
  ];
  //Below BarData variable is used for Bar graph
  const BarData = [
    {
      COLOR_1: 150,
      COLOR_2: 500,
      COLOR_3: 175,
      COLOR_4: 145,
      COLOR_5: 200,
      COLOR_6: 110,
      COLOR_7: 400,
      id: 22,
      COLOR_8: 100,
      COLOR_9: 250,
      start_date: "04/06/2021",
      COLOR_10: 150,
      COLOR_11: 500,
      COLOR_12: 100
    }
  ];
  //radioValue state variable is use to toggle between bar graph and line graph
  const [radioValue, setRadioValue] = useState("Line Graph");

  const [imageSize] = useState({
    width: 800,
    height: 400
  });

  const auth = Auth.getToken();
  const [formState, setFormState] = useState({
    areaValues: {},
    strokeValues: {},
    updateId: "",
    alert: false,
    errorMessage: "",
    severity: "success",
    touched: [],
    showGraph: false
  });

  const setDate = () => {
    var today = new Date(),
      newDate =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();

    for (var i = 0; i < data.length; i++) {
      data[i].start_date = newDate;
    }
  };

  const getObjectKeys = ColorData => {
    let temparray = [];
    Object.keys(ColorData.areaColor).map(number => {
      let tempJson = {};
      let id = parseInt(number) + 1;
      tempJson = {
        id: id,
        areaColor: ColorData.areaColor[number],
        strokeColor: ColorData.fillColor[number],
        name: "COLOR_" + id
      };
      temparray.push(tempJson);
      return null;
    });

    return temparray;
  };

  const convertDataAreaColor = data => {
    for (var i = 0; i < data.length; i++) {
      yAxis[i].strokeColor = data[i];
      setFormState(formState => ({
        ...formState,
        strokeValues: {
          ...formState.strokeValues,
          [i]: data[i]
        }
      }));
    }
  };
  const convertDataStrokeColor = data => {
    for (var i = 0; i < data.length; i++) {
      yAxis[i].areaColor = data[i];
      setFormState(formState => ({
        ...formState,
        areaValues: {
          ...formState.areaValues,
          [i]: data[i]
        }
      }));
    }
  };

  const getColors = async () => {
    let body = {
      franchise_master: Auth.getUserInfo().franchise.id,
      is_graph_color_custom: true
    };
    await providerForGet(getCustomizationUrl, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          updateId: res.data[0].id ? res.data[0].id : ""
        }));

        setDate();
        setGraphColorData(getObjectKeys(res.data[0].customization_json_field));
        convertDataAreaColor(res.data[0].customization_json_field.areaColor);
        convertDataStrokeColor(res.data[0].customization_json_field.fillColor);
        setFormState(formState => ({
          ...formState,
          showGraph: true
        }));
      })
      .catch(err => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getColors();
  }, []);

  const handleDynamicChange = (eventName, typeColor, value) => {
    if (typeColor === "areaColor") {
      yAxis[eventName].areaColor =
        value.css && value.css.backgroundColor
          ? value.css.backgroundColor
          : value;

      setFormState(formState => ({
        ...formState,
        areaValues: {
          ...formState.areaValues,
          [eventName]: value
        },
        touched: [eventName]
      }));
    } else {
      yAxis[eventName].strokeColor =
        value.css && value.css.backgroundColor
          ? value.css.backgroundColor
          : value;
      setFormState(formState => ({
        ...formState,
        strokeValues: {
          ...formState.strokeValues,
          [eventName]: value
        },
        touched: [eventName]
      }));
    }
  };

  const updateColors = (postAreaColor, postStrokeColor) => {
    let body = {
      areaColor: postStrokeColor,
      fillColor: postAreaColor
    };
    let postData = {
      customization_json_field: JSON.stringify(body),
      franchise_master: Auth.getUserInfo().franchise.id,
      is_graph_color_custom: true,
      users_permissions_role: Auth.getUserInfo().role.id
    };

    if (formState.updateId) {
      providerForPut(getCustomizationUrl, formState.updateId, postData, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            alert: true,
            errorMessage: "Colors have been updated",
            severity: "success"
          }));
          getColors();
        })
        .catch(err => {
          console.log("updateError", err);
        });
    } else {
      providerForPost(getCustomizationUrl, postData, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            alert: true,
            errorMessage: "Colors have been added",
            severity: "success"
          }));
          getColors();
        })
        .catch(err => {});
    }
  };

  const handleSubmit = () => {
    let postAreaColor = [];
    let postStrokeColor = [];
    Object.keys(formState.areaValues).map(number => {
      if (
        formState.areaValues[number] &&
        formState.areaValues[number].css &&
        formState.areaValues[number].css.backgroundColor
      ) {
        postAreaColor.push(formState.areaValues[number].css.backgroundColor);
      } else {
        postAreaColor.push(formState.areaValues[number]);
      }
      return null;
    });
    Object.keys(formState.strokeValues).map(number => {
      if (
        formState.strokeValues[number] &&
        formState.strokeValues[number].css &&
        formState.strokeValues[number].css.backgroundColor
      ) {
        postStrokeColor.push(
          formState.strokeValues[number].css.backgroundColor
        );
      } else {
        postStrokeColor.push(formState.strokeValues[number]);
      }
      return null;
    });

    updateColors(postAreaColor, postStrokeColor);
  };

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "success"
    }));
  };

  const handleDefaultClick = () => {
    providerForDelete(getCustomizationUrl, formState.updateId, auth)
      .then(res => {
        getColors();
      })
      .catch(err => {
        console.log("error", err);
      });
  };

  const handleChangeRadio = event => {
    setRadioValue(event);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      return (
        <div
          className="subsCOLOR_5ibers-by-channel-tooltip"
          style={{
            backgroundColor: "white"
          }}
        >
          <p
            className="subsCOLOR_5ibers-by-channel-tooltip-label"
            style={{
              paddingTop: "5px",
              paddingLeft: "10px"
            }}
          >
            {"Schedule Data : "}
            {label}
          </p>
          {payload.map(data => (
            <p
              className="subsCOLOR_5ibers-by-channel-tooltip-value"
              style={{
                color: `${data.stroke}`,
                paddingLeft: "10px",
                marginBottom: "-10px"
              }}
            >
              {` ${data.name}`} : {` ${data.value}`}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  const renderColorfulLegendText = (value, entry) => {
    return (
      <span
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: "1rem",
          color: "#8A8A97",
          paddingTop: "50px"
        }}
      >
        {value}
      </span>
    );
  };

  const handleClick = event => {};

  const handleClickVisible = (event, row) => {
    if (yAxis[row].show === true) {
      let dataCopy = [...yAxis];
      dataCopy[row].show = false;
      setYAxis(dataCopy);
    } else if (yAxis[row].show === false) {
      let dataCopy = [...yAxis];
      dataCopy[row].show = true;
      setYAxis(dataCopy);
    }
  };
  const handleClickAll = enableAll => {
    let dataCopy = [...yAxis];
    if (enableAll) {
      dataCopy.map((i, j) => {
        dataCopy[j].show = true;
        return null;
      });
      setEnabledColor(true);
      setYAxis(dataCopy);
    } else {
      dataCopy.map((i, j) => {
        dataCopy[j].show = false;
        return null;
      });
      setYAxis(dataCopy);
      setEnabledColor(false);
    }
  };

  return (
    <>
      <HeadingOne>Customization</HeadingOne>
      {formState.updateId && (
        <DarkBlueButton
          style={{ float: "right" }}
          onClick={() => {
            handleDefaultClick();
          }}
        >
          Set Default Color
        </DarkBlueButton>
      )}
      <Grid>
        <SnackBar
          open={formState.alert}
          severity={formState.severity}
          onClose={handleSnackbarClose}
        >
          {formState.errorMessage}
        </SnackBar>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={7}>
          <CardHeader
            title={
              <div
                style={{
                  marginLeft: "15%"
                }}
              ></div>
            }
            action={
              <>
                {formState.touched.length > 0 && (
                  <BorderLessButton
                    onClick={() => {
                      getColors();
                    }}
                  >
                    RESET
                  </BorderLessButton>
                )}

                <DarkBlueButton
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  SAVE
                </DarkBlueButton>
              </>
            }
          />
          <Card
            variant="elevation"
            elevation={2}
            className={isDesktop ? classes.paddingStyle : null}
          >
            <CardContent>
              <Box className={classes.responsiveContainerWrap}>
                {formState.showGraph !== true ? (
                  <div style={{ marginTop: "150px", marginLeft: "400px" }}>
                    <CircularProgress color="secondary" />
                  </div>
                ) : radioValue === "Line Graph" ? (
                  <ResponsiveContainer
                    id="responsiveContainer"
                    width="100%"
                    height={400}
                  >
                    <LineChart
                      width={imageSize.width}
                      height={imageSize.height}
                      data={data}
                      margin={{
                        top: 5,
                        bottom: 5
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />

                      <XAxis
                        dataKey="start_date"
                        style={{
                          fontSize: "0.875rem",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                          color: "#8A8A97"
                        }}
                      />

                      <YAxis
                        style={{
                          fontSize: "0.875rem",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                          color: "#8A8A97"
                        }}
                      />
                      <Tooltip
                        content={<CustomTooltip />}
                        cursor={false}
                        position="top"
                      />
                      <Legend
                        iconType="square"
                        onClick={handleClick}
                        iconSize={18}
                        formatter={renderColorfulLegendText}
                        wrapperStyle={{ bottom: "-40px" }}
                      />
                      {yAxis.map(
                        (number, index) =>
                          number.show === true && (
                            <Line
                              connectNulls
                              id={index}
                              type="monotone"
                              dataKey={number.name}
                              stroke={number.strokeColor}
                              strokeWidth={2.5}
                            />
                          )
                      )}
                    </LineChart>
                  </ResponsiveContainer>
                ) : (
                  <ResponsiveContainer
                    id="responsiveContainer"
                    width="100%"
                    height={400}
                  >
                    <BarChart
                      width={imageSize.width}
                      height={imageSize.height}
                      data={BarData}
                      margin={{
                        top: 5,
                        bottom: 5
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="start_date"
                        style={{
                          fontSize: "0.875rem",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                          color: "#8A8A97"
                        }}
                      />
                      <YAxis
                        style={{
                          fontSize: "0.875rem",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                          color: "#8A8A97"
                        }}
                      />
                      <Tooltip
                        content={<CustomTooltip />}
                        cursor={false}
                        position="top"
                      />
                      <Legend
                        iconType="square"
                        onClick={handleClick}
                        iconSize={18}
                        formatter={renderColorfulLegendText}
                        wrapperStyle={{ bottom: "-40px" }}
                      />

                      {yAxis.map(
                        (number, index) =>
                          number.show === true && (
                            <Bar
                              connectNulls
                              id={index}
                              type="monotone"
                              dataKey={number.name}
                              stroke={number.strokeColor}
                              fill={number.areaColor}
                              strokeWidth={2.5}
                            />
                          )
                      )}
                    </BarChart>
                  </ResponsiveContainer>
                )}
                <Grid container>
                  <Grid item>
                    <div
                      style={{
                        textAlign: "right",
                        padding: "1.875rem",
                        marginLeft: "350px",
                        marginTop: "45px"
                      }}
                    >
                      <BarChartIcon
                        variant="outlined"
                        style={{
                          height: "35px",
                          width: "35px",
                          marginRight: "15px",
                          color:
                            radioValue === "Bar Graph" ? "#1C4979" : "#808080"
                        }}
                        onClick={() => handleChangeRadio("Bar Graph")}
                      />

                      <TimelineIcon
                        variant="contained"
                        style={{
                          height: "35px",
                          width: "35px",
                          marginRight: "15px",
                          color:
                            radioValue === "Line Graph" ? "#1C4979" : "#808080"
                        }}
                        onClick={() => handleChangeRadio("Line Graph")}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={5}>
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {" "}
                    {enabledColor ? (
                      <VisibilityIcon
                        title="Click this to add the color"
                        style={{ overflow: "visible" }}
                        variant="contained"
                        onClick={event => {
                          handleClickAll(false);
                        }}
                        style={{ color: "#1C4979" }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        style={{ overflow: "visible" }}
                        // fontSize="large"
                        onClick={event => {
                          handleClickAll(true);
                        }}
                        style={{ color: "grey" }}
                      />
                    )}
                  </TableCell>
                  <TableCell>Color no. </TableCell>
                  {radioValue !== "Line Graph" && (
                    <TableCell>Area Color</TableCell>
                  )}
                  <TableCell>
                    {radioValue === "Line Graph"
                      ? "Graph color"
                      : "Stroke color"}{" "}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {graphColorData.map((row, i) => (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {yAxis[i].show === true ? (
                        <VisibilityIcon
                          tooltip="Click this to add the color"
                          onClick={event => {
                            handleClickVisible(event, i);
                          }}
                          style={{ color: "#1C4979" }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          tooltip="Click this to remove the color"
                          onClick={event => {
                            handleClickVisible(event, i);
                          }}
                          style={{ color: "grey" }}
                        />
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {i + 1}
                    </TableCell>
                    {radioValue !== "Line Graph" && (
                      <TableCell component="th" scope="row">
                        <ColorPicker
                          hideTextfield={true}
                          value={formState.areaValues[i]}
                          onChange={value => {
                            handleDynamicChange(i, "areaColor", value);
                          }}
                        />
                      </TableCell>
                    )}
                    <TableCell component="th" scope="row">
                      <ColorPicker
                        className={classes.root}
                        hideTextfield={true}
                        value={formState.strokeValues[i]}
                        onChange={value => {
                          handleDynamicChange(i, "strokeColor", value);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <div
        style={{
          float: "right",
          marginTop: "15px"
        }}
      ></div>
    </>
  );
};

CustomColors.propTypes = {};

CustomColors.defaultProps = {};

export default CustomColors;
