import {
  IconButton,
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography
} from "@material-ui/core";
import { Input } from "../../../components";
import React from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { converToFraction, roundTo1Digit, roundTo2Digit } from "../../../utils";

const AddItemsGrid = props => {
  const useStyles = makeStyles(theme => ({
    inputClass: {
      width: "80px"
    },
    label: {
      fontSize: "small",
      paddingLeft: 20
    },
    cardTitleStyle: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "1rem",
      color: "#8A8A97",
      padding: "1rem !important"
    },
    tableRowStyle: {
      // background: "#f4f8ff"
    },
    tableCellStyle: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "0.9375rem",
      color: "#000000",
      // border: "none",
      padding: props.isViewOnly ? null : 0
    },
    borderNone: {
      border: "none",
      padding: 0
    },
    trRoot: {
      overflow: "auto",
      "& .MuiTable-root": {
        // borderSpacing: " 0 1.0625rem !important",
        borderCollapse: "separate  !important"
      },
      "& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover": {
        background: "#F8F8FA"
      },
      "& .MuiTableRow-root": {
        "&:hover": {
          background: "#F8F8FA !important"
        }
      }
    },
    inputRoot: {
      "& .MuiOutlinedInput-input": {
        textAlign: "center",
        padding: "0.875rem !important"
      },
      "& .MuiOutlinedInput-root": {
        margin: "8px 0px"
      },
      "& .MuiInputBase-root": {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "1rem",
        color: "#110F48"
      }
    },
    checkBoxStyle: {
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#1C4979"
      }
    },
    cardHeaderStyle: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "1rem",
      color: "#110F48",
      textAlign: "center",
      paddingLeft: "44%"
    },
    paddingStyle: {
      padding: 30,
      paddingRight: 0,
      paddingBottom: 0
    },
    radioButtonStyle: {
      "& .MuiFormControlLabel-label": {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "0.9375rem",
        color: "#000000",
        textAlign: "center"
      },
      "& .MuiRadio-colorSecondary.Mui-checked": {
        color: "#1C4979"
      },
      "& .MuiSvgIcon-root": {
        fontSize: "1.2rem"
      }
    },
    responsiveContainerWrap: {
      overflow: "auto",
      height: "270px",
      "& > div": {
        margin: "0 auto"
      }
    },
    root: {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px"
      }
    }
  }));

  const classes = useStyles();

  return (
    <>
      <div className={classes.trRoot}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography className={classes.cardTitleStyle}>
                  Category
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cardTitleStyle}>Name</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cardTitleStyle}>
                  Num. Exchanges
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cardTitleStyle}>
                  Portions
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cardTitleStyle}>
                  Units
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cardTitleStyle}>
                  Calories
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cardTitleStyle}>
                  Proteins
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cardTitleStyle}>
                  Carbs
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cardTitleStyle}>Fats</Typography>
              </TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(props.foodData.foodsAdded).map(f => (
              <TableRow className={classes.tableRowStyle} hover={true}>
                <TableCell className={classes.tableCellStyle} align="center">
                  {props.foodData.foodsAdded[f].category}
                </TableCell>
                <TableCell className={classes.tableCellStyle} align="center">
                  {props.foodData.foodsAdded[f].name}
                </TableCell>
                <TableCell className={classes.tableCellStyle} align="center">
                  {props.isViewOnly ? (
                    props.foodData.foodsAdded[f].no_of_exchanges
                  ) : (
                    <div className={classes.inputRoot}>
                      <Input
                        id={"no_of_exchange_" + f}
                        onChange={e => {
                          props.handleChangeForNoOfExchanges(
                            e.target.value,
                            f,
                            props.foodData.foodsAdded[f].no_of_exchanges,
                            props.foodData.foodsAdded[f].foodCatAbbrName,
                            false
                          );
                        }}
                        onBlur={e => {
                          props.handleChangeForNoOfExchanges(
                            e.target.value,
                            f,
                            props.foodData.foodsAdded[f].no_of_exchanges,
                            props.foodData.foodsAdded[f].foodCatAbbrName,
                            true
                          );
                        }}
                        type="number"
                        variant="outlined"
                        value={props.foodData.foodsAdded[f].no_of_exchanges}
                        className={classes.inputClass}
                        InputProps={{
                          style: {
                            textAlign: "center",
                            padding: "0.875rem !important"
                          }
                        }}
                      />
                    </div>
                  )}
                </TableCell>
                <TableCell className={classes.tableCellStyle} align="center">
                  {converToFraction(props.foodData.foodsAdded[f].portion)}
                </TableCell>
                <TableCell className={classes.tableCellStyle} align="center">
                  {props.foodData.foodsAdded[f].portion_unit}
                </TableCell>
                <TableCell className={classes.tableCellStyle} align="center">
                  {roundTo1Digit(props.foodData.foodsAdded[f].calories)}
                </TableCell>
                <TableCell className={classes.tableCellStyle} align="center">
                  {roundTo2Digit(props.foodData.foodsAdded[f].proteins)}
                </TableCell>
                <TableCell className={classes.tableCellStyle} align="center">
                  {roundTo2Digit(props.foodData.foodsAdded[f].carbs)}
                </TableCell>
                <TableCell className={classes.tableCellStyle} align="center">
                  {roundTo2Digit(props.foodData.foodsAdded[f].fats)}
                </TableCell>
                {props.isViewOnly ? null : (
                  <TableCell className={classes.tableCellStyle} align="center">
                    <IconButton
                      color="secondary"
                      aria-label="Delete supplement"
                      disabled={props.isActive === false}
                      onClick={event => {
                        props.handleDeleteFood(
                          f,
                          props.foodData.foodsAdded[f].foodCatAbbrName,
                          props.foodData.foodsAdded[f].no_of_exchanges
                        );
                      }}
                    >
                      <DeleteForeverIcon id={"meal" + f} />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
            <TableRow className={classes.tableRowStyle} hover={true}>
              <TableCell
                className={classes.tableCellStyle}
                align="center"
              ></TableCell>
              <TableCell
                className={classes.tableCellStyle}
                align="center"
              ></TableCell>
              <TableCell
                className={classes.tableCellStyle}
                align="center"
              ></TableCell>
              <TableCell
                className={classes.tableCellStyle}
                align="center"
              ></TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                <Typography className={classes.cardTitleStyle}>
                  TOTALS
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo1Digit(props.foodData.totalFoods.totalCal)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.foodData.totalFoods.totalPro)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.foodData.totalFoods.totalCarb)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.foodData.totalFoods.totalfat)}
              </TableCell>
              <TableCell
                className={classes.tableCellStyle}
                align="center"
              ></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default AddItemsGrid;
