// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BodyMass_recharts-legend-item__314Rh .BodyMass_recharts-surface__3b8t5 {\n  border-radius: 4px;\n}\n.BodyMass_boddyMassTable__32iPQ tr td:first-child {\n  border-top-left-radius: 10px;\n  border-bottom-left-radius: 10px;\n}\n.BodyMass_boddyMassTable__32iPQ tr td:last-child {\n  border-top-right-radius: 10px;\n  border-bottom-right-radius: 10px;\n}\n@media only screen and (min-width: 1280px) {\n  .BodyMass_backIcon__x-bq6 {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/containers/BodyMass/BodyMass.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,4BAA4B;EAC5B,+BAA+B;AACjC;AACA;EACE,6BAA6B;EAC7B,gCAAgC;AAClC;AACA;EACE;IACE,6BAA6B;IAC7B,6BAA6B;EAC/B;AACF","sourcesContent":[".recharts-legend-item .recharts-surface {\n  border-radius: 4px;\n}\n.boddyMassTable tr td:first-child {\n  border-top-left-radius: 10px;\n  border-bottom-left-radius: 10px;\n}\n.boddyMassTable tr td:last-child {\n  border-top-right-radius: 10px;\n  border-bottom-right-radius: 10px;\n}\n@media only screen and (min-width: 1280px) {\n  .backIcon {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recharts-legend-item": "BodyMass_recharts-legend-item__314Rh",
	"recharts-surface": "BodyMass_recharts-surface__3b8t5",
	"boddyMassTable": "BodyMass_boddyMassTable__32iPQ",
	"backIcon": "BodyMass_backIcon__x-bq6"
};
export default ___CSS_LOADER_EXPORT___;
