const UsersSchema = {
  do_not_share: {
    label: "do_not_share",
    id: "do_not_share",
    autoComplete: "do_not_share",
    required: false,
    placeholder: "do_not_share",
    autoFocus: true,
    type: "boolean",
    validations: {}
  },

  active: {
    label: "active",
    id: "active",
    autoComplete: "active",
    required: false,
    placeholder: "active",
    autoFocus: true,
    type: "boolean",
    validations: {}
  },
  is2FAEnabled: {
    label: "is2FAEnabled",
    id: "is2FAEnabled",
    autoComplete: "is2FAEnabled",
    required: false,
    placeholder: "2FA",
    autoFocus: true,
    type: "boolean",
    validations: {}
  },
  email: {
    label: "Email",
    id: "email",
    autoComplete: "email",
    required: true,
    placeholder: "email",
    autoFocus: true,
    type: "email",
    validations: {
      required: {
        value: "true",
        message: "Email is required"
      },
      validateEmailRegex: {
        value: "true",
        message: "Email not valid"
      }
    }
  },

  full_name: {
    label: "Full Name",
    id: "fullName",
    autoComplete: "fullName",
    required: true,
    placeholder: "fullName",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Name is required"
      }
    }
  },
  phone: {
    label: "Phone",
    id: "phone",
    autoComplete: "phone",
    required: false,
    placeholder: "phone",
    autoFocus: true,
    type: "phone",
    validations: {
      // required: {
      //   value: "true",
      //   message: "Phone is required"
      // }
    }
  },
  franchiseName: {
    label: "Franchise",
    id: "franchiseName",
    autoComplete: "Franchise",
    required: true,
    placeholder: "Franchise",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Franchise is required"
      }
    }
  },
  franchiseSite: {
    label: "Franchise Site",
    id: "franchiseSite",
    autoComplete: "Franchise Site",
    required: false,
    placeholder: "Franchise Site",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  role: {
    label: "Role",
    id: "role",
    autoComplete: "role",
    required: false,
    placeholder: "role",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  address: {
    label: "Address",
    id: "address",
    autoComplete: "address",
    required: false,
    placeholder: "address",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  state: {
    label: "State",
    id: "state",
    autoComplete: "state",
    required: false,
    placeholder: "state",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  city: {
    label: "City",
    id: "city",
    autoComplete: "city",
    required: false,
    placeholder: "city",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  zipcode: {
    label: "Zipcode",
    id: "zipcode",
    autoComplete: "zipcode",
    required: false,
    placeholder: "zipcode",
    autoFocus: true,
    type: "number",
    validations: {}
  },
  country: {
    label: "Country",
    id: "country",
    autoComplete: "country",
    required: false,
    placeholder: "country",
    autoFocus: true,
    type: "text",
    validations: {}
  }
};
export default UsersSchema;
