import React from "react";
import { Button as ButtonElement } from "@material-ui/core";

export const Button = props => {
  return (
    <ButtonElement
      variant={props.variant}
      color={props.color}
      onClick={props.onClick}
      disabled={props.disabled}
      to={props.to}
      type={props.type}
      onKeyDown={props.onKeyDown}
      {...props}
    >
      {props.children}
    </ButtonElement>
  );
};
