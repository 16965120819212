import {
  makeStyles,
  useTheme,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography
} from "@material-ui/core";
import { Input } from "../../../components";
import React from "react";
import { convertNumber, roundTo1Digit, roundTo2Digit } from "../../../utils";
import DarkBlueButton from "../../DarkBlueButton";

const CreateMealsGrid = props => {
  const theme = useTheme();

  const useStyles = makeStyles(theme => ({
    inputClass: {
      width: "80px"
    },
    label: {
      fontSize: "small",
      paddingLeft: 20
    },
    cardTitleStyle: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "1rem",
      color: "#8A8A97",
      padding: "1rem !important"
    },
    tableRowStyle: {
      background: "#f4f8ff"
    },
    tableCellStyle: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "0.9375rem",
      color: "#110F48",
      border: "none",
      padding: props.isViewOnly ? null : 0,
      backgroundColor: "#f4f8ff"
    },
    borderNone: {
      border: "none",
      padding: 0
    },
    trRoot: {
      overflow: "auto",
      "& .MuiTable-root": {
        borderSpacing: " 0 1.0625rem !important",
        borderCollapse: "separate  !important"
      },
      "& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover": {
        background: "#F8F8FA"
      },
      "& .MuiTableRow-root": {
        "&:hover": {
          background: "#F8F8FA !important"
        }
      }
    },
    inputRoot: {
      "& .MuiOutlinedInput-input": {
        textAlign: "center",
        padding: "0.875rem !important"
      },
      "& .MuiOutlinedInput-root": {
        margin: "8px 0px"
      },
      "& .MuiInputBase-root": {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "1rem",
        color: "#110F48"
      }
    },
    checkBoxStyle: {
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#1C4979"
      }
    },
    cardHeaderStyle: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "1rem",
      color: "#110F48",
      textAlign: "center",
      paddingLeft: "44%"
    },
    paddingStyle: {
      padding: 30,
      paddingRight: 0,
      paddingBottom: 0
    },
    radioButtonStyle: {
      "& .MuiFormControlLabel-label": {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "0.9375rem",
        color: "#000000",
        textAlign: "center"
      },
      "& .MuiRadio-colorSecondary.Mui-checked": {
        color: "#1C4979"
      },
      "& .MuiSvgIcon-root": {
        fontSize: "1.2rem"
      }
    },
    responsiveContainerWrap: {
      overflow: "auto",
      height: "270px",
      "& > div": {
        margin: "0 auto"
      }
    },
    root: {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px"
      }
    },
    createMealTable: {
      "& tbody tr td:first-child": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important"
      },
      "& tbody tr td:last-child": {
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px"
      }
    },
    tableCurveCellStyle: {
      borderTopLeftRadius: "10px !important",
      borderBottomLeftRadius: "10px !important",
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "0.9375rem",
      color: "#110F48",
      border: "none",
      padding: 5,
      textAlign: "center",
      backgroundColor: "#f4f8ff"
    }
  }));

  const classes = useStyles();
  return (
    <>
      <div className={classes.trRoot}>
        <Table className={classes.createMealTable}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.borderNone}>
                {props.isViewOnly ? null : (
                  <DarkBlueButton
                    title="Copies actual food portion values inside this table"
                    variant="contained"
                    size="small"
                    style={{
                      margin: theme.spacing(1)
                    }}
                    onClick={props.copyFromActualFood}
                    disabled={props.isActive === false}
                  >
                    COPY
                  </DarkBlueButton>
                )}
              </TableCell>
              {props.isViewOnly ? null : (
                <TableCell className={classes.borderNone}>
                  <Typography className={classes.cardTitleStyle} align="center">
                    Ref
                  </Typography>
                </TableCell>
              )}
              <TableCell className={classes.borderNone}>
                <Typography className={classes.cardTitleStyle} align="center">
                  Portion
                </Typography>
              </TableCell>
              <TableCell className={classes.borderNone} align="center">
                <Typography className={classes.cardTitleStyle}>
                  Calories
                </Typography>
              </TableCell>
              <TableCell className={classes.borderNone} align="center">
                <Typography className={classes.cardTitleStyle}>
                  Proteins
                </Typography>
              </TableCell>
              <TableCell className={classes.borderNone} align="center">
                <Typography className={classes.cardTitleStyle}>
                  Carbs
                </Typography>
              </TableCell>
              <TableCell className={classes.borderNone} align="center">
                <Typography className={classes.cardTitleStyle}>Fats</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.tableRowStyle} hover={true}>
              <TableCell
                className={classes.borderNone}
                style={{ background: "#ffffff" }}
              >
                <Typography className={classes.cardTitleStyle}>
                  BREAD
                </Typography>
              </TableCell>
              {props.isViewOnly ? null : (
                <TableCell className={classes.tableCellStyle} align="center">
                  {convertNumber(props.fixedValues.bread)}
                </TableCell>
              )}
              {props.isViewOnly ? (
                <TableCell className={classes.tableCellStyle} align="center">
                  {props.goalValues.bread.portion || "0"}
                </TableCell>
              ) : (
                <TableCell
                  className={classes.tableCurveCellStyle}
                  align="center"
                >
                  <div className={classes.inputRoot}>
                    <Input
                      id={"bread"}
                      onChange={e => {
                        props.handleChangeForGoals(e.target.value, "bread");
                      }}
                      onBlur={e => {
                        props.onLooseFocus(e.target.value, "bread");
                      }}
                      value={props.goalValues.bread.portion || ""}
                      placeholder="0"
                      type="number"
                      variant="outlined"
                      className={classes.inputClass}
                      // size="small"
                      InputProps={{ style: { textAlign: "center" } }}
                    />
                  </div>
                </TableCell>
              )}
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo1Digit(props.goalValues.bread.avgcal)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.bread.avgpro)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.bread.avgcarb)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.bread.avgfat)}
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRowStyle} hover={true}>
              <TableCell
                className={classes.borderNone}
                style={{ background: "#ffffff" }}
              >
                <Typography className={classes.cardTitleStyle}>MEAT</Typography>
              </TableCell>
              {props.isViewOnly ? null : (
                <TableCell className={classes.tableCellStyle} align="center">
                  {convertNumber(props.fixedValues.meat)}
                </TableCell>
              )}
              {props.isViewOnly ? (
                <TableCell className={classes.tableCellStyle} align="center">
                  {props.goalValues.meat.portion || "0"}
                </TableCell>
              ) : (
                <TableCell
                  className={classes.tableCurveCellStyle}
                  align="center"
                >
                  <div className={classes.inputRoot}>
                    <Input
                      id={"meat"}
                      onChange={e => {
                        props.handleChangeForGoals(e.target.value, "meat");
                      }}
                      onBlur={e => {
                        props.onLooseFocus(e.target.value, "meat");
                      }}
                      value={props.goalValues.meat.portion || ""}
                      placeholder="0"
                      type="number"
                      variant="outlined"
                      className={classes.inputClass}
                      InputProps={{ style: { textAlign: "center" } }}
                    />
                  </div>
                </TableCell>
              )}
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo1Digit(props.goalValues.meat.avgcal)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.meat.avgpro)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.meat.avgcarb)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.meat.avgfat)}
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRowStyle} hover={true}>
              <TableCell
                className={classes.borderNone}
                style={{ background: "#ffffff" }}
              >
                <Typography className={classes.cardTitleStyle}>VEG</Typography>
              </TableCell>
              {props.isViewOnly ? null : (
                <TableCell className={classes.tableCellStyle} align="center">
                  {convertNumber(props.fixedValues.veg)}
                </TableCell>
              )}
              {props.isViewOnly ? (
                <TableCell className={classes.tableCellStyle} align="center">
                  {props.goalValues.veg.portion || "0"}
                </TableCell>
              ) : (
                <TableCell
                  className={classes.tableCurveCellStyle}
                  align="center"
                >
                  <div className={classes.inputRoot}>
                    <Input
                      id={"veg"}
                      onChange={e => {
                        props.handleChangeForGoals(e.target.value, "veg");
                      }}
                      onBlur={e => {
                        props.onLooseFocus(e.target.value, "veg");
                      }}
                      value={props.goalValues.veg.portion || ""}
                      placeholder="0"
                      type="number"
                      variant="outlined"
                      className={classes.inputClass}
                      InputProps={{ style: { textAlign: "center" } }}
                    />
                  </div>
                </TableCell>
              )}
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo1Digit(props.goalValues.veg.avgcal)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.veg.avgpro)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.veg.avgcarb)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.veg.avgfat)}
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRowStyle} hover={true}>
              <TableCell
                className={classes.borderNone}
                style={{ background: "#ffffff" }}
              >
                <Typography className={classes.cardTitleStyle}>
                  FRUIT
                </Typography>
              </TableCell>
              {props.isViewOnly ? null : (
                <TableCell className={classes.tableCellStyle} align="center">
                  {convertNumber(props.fixedValues.fruit)}
                </TableCell>
              )}
              {props.isViewOnly ? (
                <TableCell className={classes.tableCellStyle} align="center">
                  {props.goalValues.fruit.portion || "0"}
                </TableCell>
              ) : (
                <TableCell
                  className={classes.tableCurveCellStyle}
                  align="center"
                >
                  <div className={classes.inputRoot}>
                    <Input
                      id={"fruit"}
                      onChange={e => {
                        props.handleChangeForGoals(e.target.value, "fruit");
                      }}
                      onBlur={e => {
                        props.onLooseFocus(e.target.value, "fruit");
                      }}
                      value={props.goalValues.fruit.portion || ""}
                      placeholder="0"
                      type="number"
                      variant="outlined"
                      className={classes.inputClass}
                      InputProps={{ style: { textAlign: "center" } }}
                    />
                  </div>
                </TableCell>
              )}
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo1Digit(props.goalValues.fruit.avgcal)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.fruit.avgpro)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.fruit.avgcarb)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.fruit.avgfat)}
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRowStyle} hover={true}>
              <TableCell
                className={classes.borderNone}
                style={{ background: "#ffffff" }}
              >
                <Typography className={classes.cardTitleStyle}>MILK</Typography>
              </TableCell>
              {props.isViewOnly ? null : (
                <TableCell className={classes.tableCellStyle} align="center">
                  {convertNumber(props.fixedValues.milk)}
                </TableCell>
              )}
              {props.isViewOnly ? (
                <TableCell className={classes.tableCellStyle} align="center">
                  {props.goalValues.milk.portion || "0"}
                </TableCell>
              ) : (
                <TableCell
                  className={classes.tableCurveCellStyle}
                  align="center"
                >
                  <div className={classes.inputRoot}>
                    <Input
                      id={"milk"}
                      onChange={e => {
                        props.handleChangeForGoals(e.target.value, "milk");
                      }}
                      onBlur={e => {
                        props.onLooseFocus(e.target.value, "milk");
                      }}
                      value={props.goalValues.milk.portion || ""}
                      placeholder="0"
                      type="number"
                      variant="outlined"
                      className={classes.inputClass}
                      InputProps={{ style: { textAlign: "center" } }}
                    />
                  </div>
                </TableCell>
              )}
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo1Digit(props.goalValues.milk.avgcal)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.milk.avgpro)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.milk.avgcarb)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.milk.avgfat)}
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRowStyle} hover={true}>
              <TableCell
                className={classes.borderNone}
                style={{ background: "#ffffff" }}
              >
                <Typography className={classes.cardTitleStyle}>FAT</Typography>
              </TableCell>
              {props.isViewOnly ? null : (
                <TableCell className={classes.tableCellStyle} align="center">
                  {convertNumber(props.fixedValues.fat)}
                </TableCell>
              )}
              {props.isViewOnly ? (
                <TableCell className={classes.tableCellStyle} align="center">
                  {props.goalValues.fat.portion || "0"}
                </TableCell>
              ) : (
                <TableCell
                  className={classes.tableCurveCellStyle}
                  align="center"
                >
                  <div className={classes.inputRoot}>
                    <Input
                      id={"fat"}
                      onChange={e => {
                        props.handleChangeForGoals(e.target.value, "fat");
                      }}
                      onBlur={e => {
                        props.onLooseFocus(e.target.value, "fat");
                      }}
                      value={props.goalValues.fat.portion || ""}
                      placeholder="0"
                      type="number"
                      variant="outlined"
                      className={classes.inputClass}
                      InputProps={{ style: { textAlign: "center" } }}
                    />
                  </div>
                </TableCell>
              )}
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo1Digit(props.goalValues.fat.avgcal)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.fat.avgpro)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.fat.avgcarb)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.fat.avgfat)}
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRowStyle} hover={true}>
              <TableCell
                className={classes.borderNone}
                style={{ background: "#ffffff" }}
              >
                <Typography className={classes.cardTitleStyle}>
                  TOTAL
                </Typography>
              </TableCell>
              {props.isViewOnly ? null : (
                <TableCell className={classes.tableCellStyle} align="center">
                  {convertNumber(
                    props.fixedValues.bread +
                      props.fixedValues.meat +
                      props.fixedValues.milk +
                      props.fixedValues.fat +
                      props.fixedValues.fruit +
                      props.fixedValues.veg
                  )}
                </TableCell>
              )}
              {props.isViewOnly ? (
                <TableCell className={classes.tableCellStyle} align="center">
                  {convertNumber(props.goalValues.total.portion)}
                </TableCell>
              ) : (
                <TableCell
                  className={classes.tableCurveCellStyle}
                  align="center"
                >
                  <div className={classes.inputRoot}>
                    <Input
                      value={convertNumber(props.goalValues.total.portion)}
                      type="number"
                      className={classes.inputClass}
                      InputProps={{
                        style: { textAlign: "center" },
                        classes: {
                          root: classes.root
                        }
                      }}
                      disabled
                    />
                  </div>
                </TableCell>
              )}
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo1Digit(props.goalValues.total.avgcal)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.total.avgpro)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.total.avgcarb)}
              </TableCell>
              <TableCell className={classes.tableCellStyle} align="center">
                {roundTo2Digit(props.goalValues.total.avgfat)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default CreateMealsGrid;
