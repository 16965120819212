// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (min-width: 1280px) {\n  .ClientExercise_backIcon__3vjEs {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/containers/ClientExercise/ClientExercise.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,6BAA6B;IAC7B,6BAA6B;EAC/B;AACF","sourcesContent":["@media only screen and (min-width: 1280px) {\n  .backIcon {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backIcon": "ClientExercise_backIcon__3vjEs"
};
export default ___CSS_LOADER_EXPORT___;
