export const bodyMassTitle = "Body Mass";
export const measurementsTitle = "Measurements";
export const exchangeMenuTitle = "Menu/Exchanges";
export const supplementTitle = "Supplements";
export const exerciseTitle = "Exercise";
export const foodDiaryTitle = "Food Diary";

export function calculateAspectRatioFit(
  srcWidth,
  srcHeight,
  maxWidth,
  maxHeight
) {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { newwidth: srcWidth * ratio, newheight: srcHeight * ratio };
}

export const defaultIconsModel = {
  bodyMass: {
    isSet: true,
    id: null,
    title: bodyMassTitle,
    data: null,
    media: null,
    content: ""
  },
  measurement: {
    isSet: false,
    id: null,
    title: measurementsTitle,
    data: null,
    media: null,
    content: ""
  },
  exchangeMenu: {
    id: null,
    isSet: false,
    isDayPresent: true,
    title: exchangeMenuTitle,
    data: null,
    media: null,
    content: "",
    day: 1
  },
  supplement: {
    isSet: false,
    id: null,
    title: supplementTitle,
    data: null,
    media: null,
    content: ""
  },
  workout: {
    id: null,
    isSet: false,
    isDayPresent: true,
    title: exerciseTitle,
    data: null,
    media: null,
    content: "",
    day: 1
  },
  foodDiary: {
    isSet: false,
    id: null,
    title: foodDiaryTitle,
    data: null,
    media: null,
    content: ""
  }
};

export const notesModal = {
  bodyMassNotes: "",
  bodyMassTitle: bodyMassTitle,
  bodyMassNotesId: null,
  isBodyMassDataPresent: false,

  measurementsNotes: "",
  measurementsTitle: bodyMassTitle,
  measurementsNotesId: null,
  isMeasurementsDataPresent: false,

  exchangeMenuNotes: "",
  exchangeMenuTitle: bodyMassTitle,
  exchangeMenuNotesId: null,
  isExchangeMenuDataPresent: false,

  supplementNotes: "",
  supplementTitle: bodyMassTitle,
  supplementNotesId: null,
  isSupplementDataPresent: false,

  exerciseNotes: "",
  exerciseTitle: bodyMassTitle,
  exerciseNotesId: null,
  isExerciseDataPresent: false
};

export const usaState = [
  { id: 0, text: "Alabama" },
  { id: 1, text: "Alaska" },
  { id: 2, text: "American Samoa" },
  { id: 3, text: "Arizona" },
  { id: 4, text: "Arkansas" },
  { id: 5, text: "California" },
  { id: 6, text: "Colorado" },
  { id: 7, text: "Connecticut" },
  { id: 8, text: "Delaware" },
  { id: 9, text: "District Of Columbia" },
  { id: 10, text: "Federated States Of Micronesia" },
  { id: 11, text: "Florida" },
  { id: 12, text: "Georgia" },
  { id: 13, text: "Guam" },
  { id: 14, text: "Hawaii" },
  { id: 15, text: "Idaho" },
  { id: 16, text: "Illinois" },
  { id: 17, text: "Indiana" },
  { id: 18, text: "Iowa" },
  { id: 19, text: "Kansas" },
  { id: 20, text: "Kentucky" },
  { id: 21, text: "Louisiana" },
  { id: 22, text: "Maine" },
  { id: 23, text: "Marshall Islands" },
  { id: 24, text: "Maryland" },
  { id: 25, text: "Massachusetts" },
  { id: 26, text: "Michigan" },
  { id: 27, text: "Minnesota" },
  { id: 28, text: "Mississippi" },
  { id: 29, text: "Missouri" },
  { id: 30, text: "Montana" },
  { id: 31, text: "Nebraska" },
  { id: 32, text: "Nevada" },
  { id: 33, text: "New Hampshire" },
  { id: 34, text: "New Jersey" },
  { id: 35, text: "New Mexico" },
  { id: 36, text: "New York" },
  { id: 37, text: "North Carolina" },
  { id: 38, text: "North Dakota" },
  { id: 39, text: "Northern Mariana Islands" },
  { id: 40, text: "Ohio" },
  { id: 41, text: "Oklahoma" },
  { id: 42, text: "Oregon" },
  { id: 43, text: "Palau" },
  { id: 44, text: "Pennsylvania" },
  { id: 45, text: "Puerto Rico" },
  { id: 46, text: "Rhode Island" },
  { id: 47, text: "South Carolina" },
  { id: 48, text: "South Dakota" },
  { id: 49, text: "Tennessee" },
  { id: 50, text: "Texas" },
  { id: 51, text: "Utah" },
  { id: 52, text: "Vermont" },
  { id: 53, text: "Virgin Islands" },
  { id: 54, text: "Virginia" },
  { id: 55, text: "Washington" },
  { id: 56, text: "West Virginia" },
  { id: 57, text: "Wisconsin" },
  { id: 58, text: "Wyoming" }
];
export const countryCode = [
  "AE",
  "US",
  "CA",
  "AF",
  "AL",
  "DZ",
  "AS",
  "AD",
  "AO",
  "AI",
  "AG",
  "AR",
  "AM",
  "AW",
  "AU",
  "AT",
  "AZ",
  "BS",
  "BH",
  "BD",
  "BB",
  "BY",
  "BE",
  "BZ",
  "BJ",
  "BM",
  "BT",
  "BO",
  "BA",
  "BW",
  "BR",
  "IO",
  "VG",
  "BN",
  "BG",
  "BF",
  "BI",
  "KH",
  "CM",
  "CA",
  "CV",
  "BQ",
  "KY",
  "CF",
  "TD",
  "CL",
  "CN",
  "CX",
  "CC",
  "CO",
  "KM",
  "CD",
  "CG",
  "CK",
  "CR",
  "CI",
  "HR",
  "CU",
  "CW",
  "CY",
  "CZ",
  "DK",
  "DJ",
  "DM",
  "DO",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "EE",
  "ET",
  "FK",
  "FO",
  "FJ",
  "FI",
  "FR",
  "GF",
  "PF",
  "GA",
  "GM",
  "GE",
  "DE",
  "GH",
  "GI",
  "GR",
  "GL",
  "GD",
  "GP",
  "GU",
  "GT",
  "GG",
  "GN",
  "GW",
  "GY",
  "HT",
  "HN",
  "HK",
  "HU",
  "IS",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IE",
  "IM",
  "IL",
  "IT",
  "JM",
  "JP",
  "JE",
  "JO",
  "KZ",
  "KE",
  "KI",
  "XK",
  "KW",
  "KG",
  "LA",
  "LV",
  "LB",
  "LS",
  "LR",
  "LY",
  "LI",
  "LT",
  "LU",
  "MO",
  "MK",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MH",
  "MQ",
  "MR",
  "MU",
  "YT",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "ME",
  "MS",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NL",
  "NC",
  "NZ",
  "NI",
  "NE",
  "NG",
  "NU",
  "NF",
  "KP",
  "MP",
  "NO",
  "OM",
  "PK",
  "PW",
  "PS",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PL",
  "PT",
  "PR",
  "QA",
  "RE",
  "RO",
  "RU",
  "RW",
  "BL",
  "SH",
  "KN",
  "LC",
  "MF",
  "PM",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "SC",
  "SL",
  "SG",
  "SX",
  "SK",
  "SI",
  "SB",
  "SO",
  "ZA",
  "KR",
  "SS",
  "ES",
  "LK",
  "SD",
  "SR",
  "SJ",
  "SZ",
  "SE",
  "CH",
  "SY",
  "TW",
  "TJ",
  "TZ",
  "TH",
  "TL",
  "TG",
  "TK",
  "TO",
  "TT",
  "TN",
  "TR",
  "TM",
  "TC",
  "TV",
  "VI",
  "UG",
  "UA",
  "AE",
  "GB",
  "US",
  "UY",
  "UZ",
  "VU",
  "VA",
  "VE",
  "VN",
  "WF",
  "EH",
  "YE",
  "ZM",
  "ZW",
  "AX"
];

export const countryCodeList = [
  {
    country: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
    code: "+971",
    countrycode: "AE"
  },
  {
    country: "United States",
    code: "+1",
    countrycode: "US"
  },
  {
    country: "Canada",
    code: "+1",
    countrycode: "CA"
  },
  {
    country: "Afghanistan (‫افغانستان‬‎)",
    code: "+93",
    countrycode: "AF"
  },
  {
    country: "Albania (Shqipëri)",
    code: "+355",
    countrycode: "AL"
  },
  {
    country: "Algeria (‫الجزائر‬‎)",
    code: "+213",
    countrycode: "DZ"
  },
  {
    country: "American Samoa",
    code: "+1684",
    countrycode: "AS"
  },
  {
    country: "Andorra",
    code: "+376",
    countrycode: "AD"
  },
  {
    country: "Angola",
    code: "+244",
    countrycode: "AO"
  },
  {
    country: "Anguilla",
    code: "+1264",
    countrycode: "AI"
  },
  {
    country: "Antigua and Barbuda",
    code: "+1268",
    countrycode: "AG"
  },
  {
    country: "Argentina",
    code: "+54",
    countrycode: "AR"
  },
  {
    country: "Armenia (Հայաստան)",
    code: "+374",
    countrycode: "AM"
  },
  {
    country: "Aruba",
    code: "+297",
    countrycode: "AW"
  },
  {
    country: "Australia",
    code: "+61",
    countrycode: "AU"
  },
  {
    country: "Austria (Österreich)",
    code: "+43",
    countrycode: "AT"
  },
  {
    country: "Azerbaijan (Azərbaycan)",
    code: "+994",
    countrycode: "AZ"
  },
  {
    country: "Bahamas",
    code: "+1242",
    countrycode: "BS"
  },
  {
    country: "Bahrain (‫البحرين‬‎)",
    code: "+973",
    countrycode: "BH"
  },
  {
    country: "Bangladesh (বাংলাদেশ)",
    code: "+880",
    countrycode: "BD"
  },
  {
    country: "Barbados",
    code: "+1246",
    countrycode: "BB"
  },
  {
    country: "Belarus (Беларусь)",
    code: "+375",
    countrycode: "BY"
  },
  {
    country: "Belgium (België)",
    code: "+32",
    countrycode: "BE"
  },
  {
    country: "Belize",
    code: "+501",
    countrycode: "BZ"
  },
  {
    country: "Benin (Bénin)",
    code: "+229",
    countrycode: "BJ"
  },
  {
    country: "Bermuda",
    code: "+1441",
    countrycode: "BM"
  },
  {
    country: "Bhutan (འབྲུག)",
    code: "+975",
    countrycode: "BT"
  },
  {
    country: "Bolivia",
    code: "+591",
    countrycode: "BO"
  },
  {
    country: "Bosnia and Herzegovina (Босна и Херцеговина)",
    code: "+387",
    countrycode: "BA"
  },
  {
    country: "Botswana",
    code: "+267",
    countrycode: "BW"
  },
  {
    country: "Brazil (Brasil)",
    code: "+55",
    countrycode: "BR"
  },
  {
    country: "British Indian Ocean Territory",
    code: "+246",
    countrycode: "IO"
  },
  {
    country: "British Virgin Islands",
    code: "+1284",
    countrycode: "VG"
  },
  {
    country: "Brunei",
    code: "+673",
    countrycode: "BN"
  },
  {
    country: "Bulgaria (България)",
    code: "+359",
    countrycode: "BG"
  },
  {
    country: "Burkina Faso",
    code: "+226",
    countrycode: "BF"
  },
  {
    country: "Burundi (Uburundi)",
    code: "+257",
    countrycode: "BI"
  },
  {
    country: "Cambodia (កម្ពុជា)",
    code: "+855",
    countrycode: "KH"
  },
  {
    country: "Cameroon (Cameroun)",
    code: "+237",
    countrycode: "CM"
  },
  {
    country: "Canada",
    code: "+1",
    countrycode: "CA"
  },
  {
    country: "Cape Verde (Kabu Verdi)",
    code: "+238",
    countrycode: "CV"
  },
  {
    country: "Caribbean Netherlands",
    code: "+599",
    countrycode: "BQ"
  },
  {
    country: "Cayman Islands",
    code: "+1345",
    countrycode: "KY"
  },
  {
    country: "Central African Republic (République centrafricaine)",
    code: "+236",
    countrycode: "CF"
  },
  {
    country: "Chad (Tchad)",
    code: "+235",
    countrycode: "TD"
  },
  {
    country: "Chile",
    code: "+56",
    countrycode: "CL"
  },
  {
    country: "China (中国)",
    code: "+86",
    countrycode: "CN"
  },
  {
    country: "Christmas Island",
    code: "+61",
    countrycode: "CX"
  },
  {
    country: "Cocos (Keeling) Islands",
    code: "+61",
    countrycode: "CC"
  },
  {
    country: "Colombia",
    code: "+57",
    countrycode: "CO"
  },
  {
    country: "Comoros (‫جزر القمر‬‎)",
    code: "+269",
    countrycode: "KM"
  },
  {
    country: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
    code: "+243",
    countrycode: "CD"
  },
  {
    country: "Congo (Republic) (Congo-Brazzaville)",
    code: "+242",
    countrycode: "CG"
  },
  {
    country: "Cook Islands",
    code: "+682",
    countrycode: "CK"
  },
  {
    country: "Costa Rica",
    code: "+506",
    countrycode: "CR"
  },
  {
    country: "Côte d’Ivoire",
    code: "+225",
    countrycode: "CI"
  },
  {
    country: "Croatia (Hrvatska)",
    code: "+385",
    countrycode: "HR"
  },
  {
    country: "Cuba",
    code: "+53",
    countrycode: "CU"
  },
  {
    country: "Curaçao",
    code: "+599",
    countrycode: "CW"
  },
  {
    country: "Cyprus (Κύπρος)",
    code: "+357",
    countrycode: "CY"
  },
  {
    country: "Czech Republic (Česká republika)",
    code: "+420",
    countrycode: "CZ"
  },
  {
    country: "Denmark (Danmark)",
    code: "+45",
    countrycode: "DK"
  },
  {
    country: "Djibouti",
    code: "+253",
    countrycode: "DJ"
  },
  {
    country: "Dominica",
    code: "+1767",
    countrycode: "DM"
  },
  {
    country: "Dominican Republic (República Dominicana)",
    code: "+1",
    countrycode: "DO"
  },
  {
    country: "Ecuador",
    code: "+593",
    countrycode: "EC"
  },
  {
    country: "Egypt (‫مصر‬‎)",
    code: "+20",
    countrycode: "EG"
  },
  {
    country: "El Salvador",
    code: "+503",
    countrycode: "SV"
  },
  {
    country: "Equatorial Guinea (Guinea Ecuatorial)",
    code: "+240",
    countrycode: "GQ"
  },
  {
    country: "Eritrea",
    code: "+291",
    countrycode: "ER"
  },
  {
    country: "Estonia (Eesti)",
    code: "+372",
    countrycode: "EE"
  },
  {
    country: "Ethiopia",
    code: "+251",
    countrycode: "ET"
  },
  {
    country: "Falkland Islands (Islas Malvinas)",
    code: "+500",
    countrycode: "FK"
  },
  {
    country: "Faroe Islands (Føroyar)",
    code: "+298",
    countrycode: "FO"
  },
  {
    country: "Fiji",
    code: "+679",
    countrycode: "FJ"
  },
  {
    country: "Finland (Suomi)",
    code: "+358",
    countrycode: "FI"
  },
  {
    country: "France",
    code: "+33",
    countrycode: "FR"
  },
  {
    country: "French Guiana (Guyane française)",
    code: "+594",
    countrycode: "GF"
  },
  {
    country: "French Polynesia (Polynésie française)",
    code: "+689",
    countrycode: "PF"
  },
  {
    country: "Gabon",
    code: "+241",
    countrycode: "GA"
  },
  {
    country: "Gambia",
    code: "+220",
    countrycode: "GM"
  },
  {
    country: "Georgia (საქართველო)",
    code: "+995",
    countrycode: "GE"
  },
  {
    country: "Germany (Deutschland)",
    code: "+49",
    countrycode: "DE"
  },
  {
    country: "Ghana (Gaana)",
    code: "+233",
    countrycode: "GH"
  },
  {
    country: "Gibraltar",
    code: "+350",
    countrycode: "GI"
  },
  {
    country: "Greece (Ελλάδα)",
    code: "+30",
    countrycode: "GR"
  },
  {
    country: "Greenland (Kalaallit Nunaat)",
    code: "+299",
    countrycode: "GL"
  },
  {
    country: "Grenada",
    code: "+1473",
    countrycode: "GD"
  },
  {
    country: "Guadeloupe",
    code: "+590",
    countrycode: "GP"
  },
  {
    country: "Guam",
    code: "+1671",
    countrycode: "GU"
  },
  {
    country: "Guatemala",
    code: "+502",
    countrycode: "GT"
  },
  {
    country: "Guernsey",
    code: "+44",
    countrycode: "GG"
  },
  {
    country: "Guinea (Guinée)",
    code: "+224",
    countrycode: "GN"
  },
  {
    country: "Guinea-Bissau (Guiné Bissau)",
    code: "+245",
    countrycode: "GW"
  },
  {
    country: "Guyana",
    code: "+592",
    countrycode: "GY"
  },
  {
    country: "Haiti",
    code: "+509",
    countrycode: "HT"
  },
  {
    country: "Honduras",
    code: "+504",
    countrycode: "HN"
  },
  {
    country: "Hong Kong (香港)",
    code: "+852",
    countrycode: "HK"
  },
  {
    country: "Hungary (Magyarország)",
    code: "+36",
    countrycode: "HU"
  },
  {
    country: "Iceland (Ísland)",
    code: "+354",
    countrycode: "IS"
  },
  {
    country: "India (भारत)",
    code: "+91",
    countrycode: "IN"
  },
  {
    country: "Indonesia",
    code: "+62",
    countrycode: "ID"
  },
  {
    country: "Iran (‫ایران‬‎)",
    code: "+98",
    countrycode: "IR"
  },
  {
    country: "Iraq (‫العراق‬‎)",
    code: "+964",
    countrycode: "IQ"
  },
  {
    country: "Ireland",
    code: "+353",
    countrycode: "IE"
  },
  {
    country: "Isle of Man",
    code: "+44",
    countrycode: "IM"
  },
  {
    country: "Israel (‫ישראל‬‎)",
    code: "+972",
    countrycode: "IL"
  },
  {
    country: "Italy (Italia)",
    code: "+39",
    countrycode: "IT"
  },
  {
    country: "Jamaica",
    code: "+1",
    countrycode: "JM"
  },
  {
    country: "Japan (日本)",
    code: "+81",
    countrycode: "JP"
  },
  {
    country: "Jersey",
    code: "+44",
    countrycode: "JE"
  },
  {
    country: "Jordan (‫الأردن‬‎)",
    code: "+962",
    countrycode: "JO"
  },
  {
    country: "Kazakhstan (Казахстан)",
    code: "+7",
    countrycode: "KZ"
  },
  {
    country: "Kenya",
    code: "+254",
    countrycode: "KE"
  },
  {
    country: "Kiribati",
    code: "+686",
    countrycode: "KI"
  },
  {
    country: "Kosovo",
    code: "+383",
    countrycode: "XK"
  },
  {
    country: "Kuwait (‫الكويت‬‎)",
    code: "+965",
    countrycode: "KW"
  },
  {
    country: "Kyrgyzstan (Кыргызстан)",
    code: "+996",
    countrycode: "KG"
  },
  {
    country: "Laos (ລາວ)",
    code: "+856",
    countrycode: "LA"
  },
  {
    country: "Latvia (Latvija)",
    code: "+371",
    countrycode: "LV"
  },
  {
    country: "Lebanon (‫لبنان‬‎)",
    code: "+961",
    countrycode: "LB"
  },
  {
    country: "Lesotho",
    code: "+266",
    countrycode: "LS"
  },
  {
    country: "Liberia",
    code: "+231",
    countrycode: "LR"
  },
  {
    country: "Libya (‫ليبيا‬‎)",
    code: "+218",
    countrycode: "LY"
  },
  {
    country: "Liechtenstein",
    code: "+423",
    countrycode: "LI"
  },
  {
    country: "Lithuania (Lietuva)",
    code: "+370",
    countrycode: "LT"
  },
  {
    country: "Luxembourg",
    code: "+352",
    countrycode: "LU"
  },
  {
    country: "Macau (澳門)",
    code: "+853",
    countrycode: "MO"
  },
  {
    country: "Macedonia (FYROM) (Македонија)",
    code: "+389",
    countrycode: "MK"
  },
  {
    country: "Madagascar (Madagasikara)",
    code: "+261",
    countrycode: "MG"
  },
  {
    country: "Malawi",
    code: "+265",
    countrycode: "MW"
  },
  {
    country: "Malaysia",
    code: "+60",
    countrycode: "MY"
  },
  {
    country: "Maldives",
    code: "+960",
    countrycode: "MV"
  },
  {
    country: "Mali",
    code: "+223",
    countrycode: "ML"
  },
  {
    country: "Malta",
    code: "+356",
    countrycode: "MT"
  },
  {
    country: "Marshall Islands",
    code: "+692",
    countrycode: "MH"
  },
  {
    country: "Martinique",
    code: "+596",
    countrycode: "MQ"
  },
  {
    country: "Mauritania (‫موريتانيا‬‎)",
    code: "+222",
    countrycode: "MR"
  },
  {
    country: "Mauritius (Moris)",
    code: "+230",
    countrycode: "MU"
  },
  {
    country: "Mayotte",
    code: "+262",
    countrycode: "YT"
  },
  {
    country: "Mexico (México)",
    code: "+52",
    countrycode: "MX"
  },
  {
    country: "Micronesia",
    code: "+691",
    countrycode: "FM"
  },
  {
    country: "Moldova (Republica Moldova)",
    code: "+373",
    countrycode: "MD"
  },
  {
    country: "Monaco",
    code: "+377",
    countrycode: "MC"
  },
  {
    country: "Mongolia (Монгол)",
    code: "+976",
    countrycode: "MN"
  },
  {
    country: "Montenegro (Crna Gora)",
    code: "+382",
    countrycode: "ME"
  },
  {
    country: "Montserrat",
    code: "+1664",
    countrycode: "MS"
  },
  {
    country: "Morocco (‫المغرب‬‎)",
    code: "+212",
    countrycode: "MA"
  },
  {
    country: "Mozambique (Moçambique)",
    code: "+258",
    countrycode: "MZ"
  },
  {
    country: "Myanmar (Burma) (မြန်မာ)",
    code: "+95",
    countrycode: "MM"
  },
  {
    country: "Namibia (Namibië)",
    code: "+264",
    countrycode: "NA"
  },
  {
    country: "Nauru",
    code: "+674",
    countrycode: "NR"
  },
  {
    country: "Nepal (नेपाल)",
    code: "+977",
    countrycode: "NP"
  },
  {
    country: "Netherlands (Nederland)",
    code: "+31",
    countrycode: "NL"
  },
  {
    country: "New Caledonia (Nouvelle-Calédonie)",
    code: "+687",
    countrycode: "NC"
  },
  {
    country: "New Zealand",
    code: "+64",
    countrycode: "NZ"
  },
  {
    country: "Nicaragua",
    code: "+505",
    countrycode: "NI"
  },
  {
    country: "Niger (Nijar)",
    code: "+227",
    countrycode: "NE"
  },
  {
    country: "Nigeria",
    code: "+234",
    countrycode: "NG"
  },
  {
    country: "Niue",
    code: "+683",
    countrycode: "NU"
  },
  {
    country: "Norfolk Island",
    code: "+672",
    countrycode: "NF"
  },
  {
    country: "North Korea (조선 민주주의 인민 공화국)",
    code: "+850",
    countrycode: "KP"
  },
  {
    country: "Northern Mariana Islands",
    code: "+1670",
    countrycode: "MP"
  },
  {
    country: "Norway (Norge)",
    code: "+47",
    countrycode: "NO"
  },
  {
    country: "Oman (‫عُمان‬‎)",
    code: "+968",
    countrycode: "OM"
  },
  {
    country: "Pakistan (‫پاکستان‬‎)",
    code: "+92",
    countrycode: "PK"
  },
  {
    country: "Palau",
    code: "+680",
    countrycode: "PW"
  },
  {
    country: "Palestine (‫فلسطين‬‎)",
    code: "+970",
    countrycode: "PS"
  },
  {
    country: "Panama (Panamá)",
    code: "+507",
    countrycode: "PA"
  },
  {
    country: "Papua New Guinea",
    code: "+675",
    countrycode: "PG"
  },
  {
    country: "Paraguay",
    code: "+595",
    countrycode: "PY"
  },
  {
    country: "Peru (Perú)",
    code: "+51",
    countrycode: "PE"
  },
  {
    country: "Philippines",
    code: "+63",
    countrycode: "PH"
  },
  {
    country: "Poland (Polska)",
    code: "+48",
    countrycode: "PL"
  },
  {
    country: "Portugal",
    code: "+351",
    countrycode: "PT"
  },
  {
    country: "Puerto Rico",
    code: "+1",
    countrycode: "PR"
  },
  {
    country: "Qatar (‫قطر‬‎)",
    code: "+974",
    countrycode: "QA"
  },
  {
    country: "Réunion (La Réunion)",
    code: "+262",
    countrycode: "RE"
  },
  {
    country: "Romania (România)",
    code: "+40",
    countrycode: "RO"
  },
  {
    country: "Russia (Россия)",
    code: "+7",
    countrycode: "RU"
  },
  {
    country: "Rwanda",
    code: "+250",
    countrycode: "RW"
  },
  {
    country: "Saint Barthélemy",
    code: "+590",
    countrycode: "BL"
  },
  {
    country: "Saint Helena",
    code: "+290",
    countrycode: "SH"
  },
  {
    country: "Saint Kitts and Nevis",
    code: "+1869",
    countrycode: "KN"
  },
  {
    country: "Saint Lucia",
    code: "+1758",
    countrycode: "LC"
  },
  {
    country: "Saint Martin (Saint-Martin (partie française))",
    code: "+590",
    countrycode: "MF"
  },
  {
    country: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
    code: "+508",
    countrycode: "PM"
  },
  {
    country: "Saint Vincent and the Grenadines",
    code: "+1784",
    countrycode: "VC"
  },
  {
    country: "Samoa",
    code: "+685",
    countrycode: "WS"
  },
  {
    country: "San Marino",
    code: "+378",
    countrycode: "SM"
  },
  {
    country: "São Tomé and Príncipe (São Tomé e Príncipe)",
    code: "+239",
    countrycode: "ST"
  },
  {
    country: "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
    code: "+966",
    countrycode: "SA"
  },
  {
    country: "Senegal (Sénégal)",
    code: "+221",
    countrycode: "SN"
  },
  {
    country: "Serbia (Србија)",
    code: "+381",
    countrycode: "RS"
  },
  {
    country: "Seychelles",
    code: "+248",
    countrycode: "SC"
  },
  {
    country: "Sierra Leone",
    code: "+232",
    countrycode: "SL"
  },
  {
    country: "Singapore",
    code: "+65",
    countrycode: "SG"
  },
  {
    country: "Sint Maarten",
    code: "+1721",
    countrycode: "SX"
  },
  {
    country: "Slovakia (Slovensko)",
    code: "+421",
    countrycode: "SK"
  },
  {
    country: "Slovenia (Slovenija)",
    code: "+386",
    countrycode: "SI"
  },
  {
    country: "Solomon Islands",
    code: "+677",
    countrycode: "SB"
  },
  {
    country: "Somalia (Soomaaliya)",
    code: "+252",
    countrycode: "SO"
  },
  {
    country: "South Africa",
    code: "+27",
    countrycode: "ZA"
  },
  {
    country: "South Korea (대한민국)",
    code: "+82",
    countrycode: "KR"
  },
  {
    country: "South Sudan (‫جنوب السودان‬‎)",
    code: "+211",
    countrycode: "SS"
  },
  {
    country: "Spain (España)",
    code: "+34",
    countrycode: "ES"
  },
  {
    country: "Sri Lanka (ශ්‍රී ලංකාව)",
    code: "+94",
    countrycode: "LK"
  },
  {
    country: "Sudan (‫السودان‬‎)",
    code: "+249",
    countrycode: "SD"
  },
  {
    country: "Suriname",
    code: "+597",
    countrycode: "SR"
  },
  {
    country: "Svalbard and Jan Mayen",
    code: "+47",
    countrycode: "SJ"
  },
  {
    country: "Swaziland",
    code: "+268",
    countrycode: "SZ"
  },
  {
    country: "Sweden (Sverige)",
    code: "+46",
    countrycode: "SE"
  },
  {
    country: "Switzerland (Schweiz)",
    code: "+41",
    countrycode: "CH"
  },
  {
    country: "Syria (‫سوريا‬‎)",
    code: "+963",
    countrycode: "SY"
  },
  {
    country: "Taiwan (台灣)",
    code: "+886",
    countrycode: "TW"
  },
  {
    country: "Tajikistan",
    code: "+992",
    countrycode: "TJ"
  },
  {
    country: "Tanzania",
    code: "+255",
    countrycode: "TZ"
  },
  {
    country: "Thailand (ไทย)",
    code: "+66",
    countrycode: "TH"
  },
  {
    country: "Timor-Leste",
    code: "+670",
    countrycode: "TL"
  },
  {
    country: "Togo",
    code: "+228",
    countrycode: "TG"
  },
  {
    country: "Tokelau",
    code: "+690",
    countrycode: "TK"
  },
  {
    country: "Tonga",
    code: "+676",
    countrycode: "TO"
  },
  {
    country: "Trinidad and Tobago",
    code: "+1868",
    countrycode: "TT"
  },
  {
    country: "Tunisia (‫تونس‬‎)",
    code: "+216",
    countrycode: "TN"
  },
  {
    country: "Turkey (Türkiye)",
    code: "+90",
    countrycode: "TR"
  },
  {
    country: "Turkmenistan",
    code: "+993",
    countrycode: "TM"
  },
  {
    country: "Turks and Caicos Islands",
    code: "+1649",
    countrycode: "TC"
  },
  {
    country: "Tuvalu",
    code: "+688",
    countrycode: "TV"
  },
  {
    country: "U.S. Virgin Islands",
    code: "+1340",
    countrycode: "VI"
  },
  {
    country: "Uganda",
    code: "+256",
    countrycode: "UG"
  },
  {
    country: "Ukraine (Україна)",
    code: "+380",
    countrycode: "UA"
  },
  {
    country: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
    code: "+971",
    countrycode: "AE"
  },
  {
    country: "United Kingdom",
    code: "+44",
    countrycode: "GB"
  },
  {
    country: "United States",
    code: "+1",
    countrycode: "US"
  },
  {
    country: "Uruguay",
    code: "+598",
    countrycode: "UY"
  },
  {
    country: "Uzbekistan (Oʻzbekiston)",
    code: "+998",
    countrycode: "UZ"
  },
  {
    country: "Vanuatu",
    code: "+678",
    countrycode: "VU"
  },
  {
    country: "Vatican City (Città del Vaticano)",
    code: "+39",
    countrycode: "VA"
  },
  {
    country: "Venezuela",
    code: "+58",
    countrycode: "VE"
  },
  {
    country: "Vietnam (Việt Nam)",
    code: "+84",
    countrycode: "VN"
  },
  {
    country: "Wallis and Futuna (Wallis-et-Futuna)",
    code: "+681",
    countrycode: "WF"
  },
  {
    country: "Western Sahara (‫الصحراء الغربية‬‎)",
    code: "+212",
    countrycode: "EH"
  },
  {
    country: "Yemen (‫اليمن‬‎)",
    code: "+967",
    countrycode: "YE"
  },
  {
    country: "Zambia",
    code: "+260",
    countrycode: "ZM"
  },
  {
    country: "Zimbabwe",
    code: "+263",
    countrycode: "ZW"
  },
  {
    country: "Åland Islands",
    code: "+358",
    countrycode: "AX"
  }
];
