// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddEditFood_titleText__2NrQg {\n  color: #110f48;\n  font-size: 1.25rem !important;\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 500 !important;\n  margin-bottom: 15px !important;\n  padding: 8px !important;\n}\n.AddEditFood_editCard__1TEIu {\n  box-shadow: none !important;\n}\n.AddEditFood_editCardContent__Yk0c_ {\n  padding: 8px !important;\n}\n.AddEditFood_groupHeader__1EtK8 {\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 500 !important;\n  border-top: 1px solid #c4c4c4;\n  padding: 18px 0px 0px 0px;\n}\n.AddEditFood_actionButtonGrid__cvxD1 {\n  padding: 8px !important;\n}\n.AddEditFood_actionButtonGrid__cvxD1 svg {\n  fill: #fff !important;\n}\n", "",{"version":3,"sources":["webpack://src/containers/Food/AddEditFood/AddEditFood.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,6BAA6B;EAC7B,8CAA8C;EAC9C,2BAA2B;EAC3B,8BAA8B;EAC9B,uBAAuB;AACzB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,uBAAuB;AACzB;AACA;EACE,8CAA8C;EAC9C,2BAA2B;EAC3B,6BAA6B;EAC7B,yBAAyB;AAC3B;AACA;EACE,uBAAuB;AACzB;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".titleText {\n  color: #110f48;\n  font-size: 1.25rem !important;\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 500 !important;\n  margin-bottom: 15px !important;\n  padding: 8px !important;\n}\n.editCard {\n  box-shadow: none !important;\n}\n.editCardContent {\n  padding: 8px !important;\n}\n.groupHeader {\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 500 !important;\n  border-top: 1px solid #c4c4c4;\n  padding: 18px 0px 0px 0px;\n}\n.actionButtonGrid {\n  padding: 8px !important;\n}\n.actionButtonGrid svg {\n  fill: #fff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleText": "AddEditFood_titleText__2NrQg",
	"editCard": "AddEditFood_editCard__1TEIu",
	"editCardContent": "AddEditFood_editCardContent__Yk0c_",
	"groupHeader": "AddEditFood_groupHeader__1EtK8",
	"actionButtonGrid": "AddEditFood_actionButtonGrid__cvxD1"
};
export default ___CSS_LOADER_EXPORT___;
