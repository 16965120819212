import React from "react";

const Nutritionist = () => (
  <div data-testid="Nutritionist">Nutritionist Component</div>
);

Nutritionist.propTypes = {};

Nutritionist.defaultProps = {};

export default Nutritionist;
