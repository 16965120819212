import { React, useState } from "react";
import clsx from "clsx";
import {
  Typography,
  Backdrop,
  CircularProgress,
  FormHelperText,
  AppBar,
  Toolbar,
  Card,
  CardContent,
  Box,
  useMediaQuery,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { commonStyles } from "../../../common";
import { Input, DarkBlueButton } from "../../../components";
import { useHistory } from "react-router-dom";
import { FORGOTPASSWORD, PASSWORDCHANGED } from "../../../paths/Paths";
import {
  checkAllKeysPresent,
  getListOfKeysNotPresent,
  setErrors,
  checkEmpty
} from "../../../utils";
import { get } from "lodash";
import resetpassword from "./resetpassword.json";
import { forgotPasswordURL } from "../../../constants";
import { providerForPublicPost } from "../../../api";
import Logo from "../../../components/Logo/Logo";

function Copyright() {
  return (
    <Typography
      align="center"
      style={{
        color: "#8A8A97",
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "0.875rem"
      }}
    >
      Copyright © {new Date().getFullYear()} Performance Fitness Concepts, All
      Rights Reserved.
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  loginAppBar: {
    background: "rgb(244, 248, 255) !important",
    "box-shadow": "0px 3px 5px #e8eef7 !important"
  },
  loginToolbar: {
    padding: "0px 25px"
  },
  loginLogo: {
    "max-width": "178px",
    margin: "0px 5px",
    "max-height": "50px"
  },
  container: {
    margin: "117px auto 50px auto"
  },
  cardContent: {
    padding: " 60px !important",
    paddingBottom: "45px !important",
    "background-color": " rgb(244 248 255) !important",
    border: "1px solid #1C4979 !important",
    "border-radius": "4px !important"
  },
  loginTextDesktop: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1.25rem",
    color: "#110F48",
    paddingTop: 40
  },
  loginTextMobile: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#110F48",
    fontSize: "1.25rem"
  },
  form: {
    width: "100%"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#0152CC",
    fontFamily: "Montserrat",
    fontWeight: 400,
    color: "#fff",
    fontSize: "0.875rem"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    position: "relative"
  },
  welcomeText: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000000",
    fontSize: "1rem"
  },
  inputText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1rem"
  },
  label: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.875rem"
  },
  Heading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000000",
    fontSize: "1rem"
  }
}));

const ResetPassword = props => {
  const newPassword = "newPassword";
  const confirmPassword = "confirmPassword";
  const classes = useStyles();
  const commonClass = commonStyles();
  const history = useHistory();
  const identifierPresent = props.location.identifier;
  const [isErrorWhilePassReset, setIsErrorWhilePassReset] = useState(false);
  const [passStatusMessage, setPassStatusMessage] = useState("");
  const themes = useTheme();

  const isDesktop = useMediaQuery(themes.breakpoints.up("lg"), {
    defaultMatches: true
  });

  /** Use state for form */
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    setNewPassword: props.location.setNewPassword
      ? props.location.setNewPassword
      : false,
    showNewPassword: false,
    showReEnterPassword: false
  });

  if (!identifierPresent) {
    history.push({
      pathname: FORGOTPASSWORD
    });
  }

  const handleChange = ({ target }) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [target.name]: target.value
      },
      touched: {
        ...formState.touched,
        [target.name]: true
      }
    }));
    delete formState.errors[target.name];
    setIsErrorWhilePassReset(false);
    setPassStatusMessage("");
  };

  const hasError = field => {
    return formState.errors[field] ? true : false;
  };

  const onResetPass = () => {
    let payload = {
      identifier: identifierPresent,
      password: formState.values.newPassword,
      type: props.type
    };
    providerForPublicPost(forgotPasswordURL, payload, {
      desc: "Password change"
    })
      .then(res => {
        history.push({
          pathname: PASSWORDCHANGED,
          identifier: identifierPresent
        });
      })
      .catch(error => {
        setIsErrorWhilePassReset(true);
        setPassStatusMessage(error.response.data.message);
        console.log("Error", error);
      });
  };

  /** onValidatePass
   *  If passwords entered in valid format, API call to reset user's password is done
   * @param myParam New password,confirm password fields
   */
  const onValidatePass = () => {
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      resetpassword
    );
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, resetpassword);
      if (checkEmpty(formState.errors)) {
        isValid = true;
        if (formState.values.newPassword !== formState.values.confirmPassword) {
          setIsErrorWhilePassReset(true);
          setPassStatusMessage("Passwords do not match");
          isValid = false;
        }
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        resetpassword
      );
      formState.errors = setErrors(formState.values, resetpassword);
    }
    if (isValid) {
      setFormState(formState => ({
        ...formState,
        isValid: true
      }));
      onResetPass();
    } else {
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
    }
  };

  const handleClickShowPassword = () => {
    setFormState({
      ...formState,
      showNewPassword: !formState.showNewPassword
    });
  };

  const handleClickShowReEnterPassword = () => {
    setFormState({
      ...formState,
      showReEnterPassword: !formState.showReEnterPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <>
      <AppBar position="fixed" className={classes.loginAppBar} elevation={0}>
        <Toolbar className={classes.loginToolbar}>
          <Logo className={classes.loginLogo} />
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" className={classes.container}>
        <Card variant="elevation" elevation={2}>
          <CardContent className={classes.cardContent}>
            <Box>
              <Typography
                className={
                  isDesktop ? classes.loginTextDesktop : classes.loginTextMobile
                }
                align="center"
              >
                {formState.setNewPassword
                  ? "Set New Password"
                  : "Reset Password"}
              </Typography>
            </Box>
            <Box>
              <Typography
                align="center"
                className={classes.welcomeText}
                style={{ marginBottom: 0 }}
              >
                OTP verified successfully. Create a new password for{" "}
              </Typography>
              <Typography align="center" className={classes.welcomeText}>
                {identifierPresent}
              </Typography>
            </Box>
            <form className={classes.form} noValidate>
              <FormControl
                fullWidth
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
                style={{ marginTop: "10px" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  fullWidth
                  error={hasError(newPassword)}
                >
                  New Password
                </InputLabel>
                <OutlinedInput
                  type={formState.showNewPassword ? "text" : "password"}
                  variant="outlined"
                  margin="normal"
                  error={hasError("newPassword")}
                  fullWidth
                  autoFocus={get(resetpassword[newPassword], "autoFocus")}
                  helperText={
                    hasError(newPassword)
                      ? formState.errors[newPassword].map(error => {
                          return error + " ";
                        })
                      : null
                  }
                  endAdornment={
                    <InputAdornment
                      position="end"
                      error={hasError(newPassword)}
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {formState.showNewPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="New Password"
                  name={newPassword}
                  value={formState.values[newPassword] || ""}
                  onChange={handleChange}
                  className={classes.inputText}
                />
              </FormControl>
              <FormControl
                fullWidth
                className={clsx(classes.margin, classes.textField)}
                variant="outlined"
                style={{ marginTop: "10px" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  fullWidth
                  error={hasError(newPassword)}
                >
                  Re-enter Password
                </InputLabel>
                <OutlinedInput
                  type={formState.showReEnterPassword ? "text" : "password"}
                  variant="outlined"
                  margin="normal"
                  error={hasError("confirmPassword")}
                  fullWidth
                  autoFocus={get(resetpassword[confirmPassword], "autoFocus")}
                  helperText={
                    hasError(confirmPassword)
                      ? formState.errors[confirmPassword].map(error => {
                          return error + " ";
                        })
                      : null
                  }
                  endAdornment={
                    <InputAdornment
                      position="end"
                      error={hasError(newPassword)}
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowReEnterPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {formState.showReEnterPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Re-enter Password"
                  name={confirmPassword}
                  value={formState.values[confirmPassword] || ""}
                  onChange={handleChange}
                  className={classes.inputText}
                />
                <FormHelperText
                  error={isErrorWhilePassReset}
                  id="my-helper-text"
                >
                  {isErrorWhilePassReset ? passStatusMessage : null}
                </FormHelperText>
              </FormControl>
              <div>
                <DarkBlueButton
                  type="button"
                  fullWidth
                  variant="contained"
                  onClick={onValidatePass}
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  Save
                </DarkBlueButton>
              </div>
            </form>

            <Backdrop className={commonClass.backdrop}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </CardContent>
        </Card>
        <Box mt={2}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
};

export default ResetPassword;
