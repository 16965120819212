// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Exchange_MuiInputBase-input__irWL2 {\n  font: inherit;\n  color: currentColor;\n  width: 100%;\n  border: 0;\n  height: 1.1876em;\n  margin: 0;\n  display: block;\n  padding: 6px 0 7px;\n  min-width: 0;\n  background: none;\n  box-sizing: content-box;\n  animation-name: Exchange_mui-auto-fill-cancel__3WcgM;\n  letter-spacing: inherit;\n  animation-duration: 10ms;\n  -webkit-tap-highlight-color: transparent;\n  text-align: center;\n}\n.Exchange_addItemGrid__3zjYp {\n  padding: 0.9375rem 0px 0.9375rem 1.875rem !important;\n}\n@media only screen and (min-width: 1280px) {\n  .Exchange_backIcon__HQnM0 {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/containers/Exchange/Exchange.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,SAAS;EACT,gBAAgB;EAChB,SAAS;EACT,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;EACvB,oDAAoC;EACpC,uBAAuB;EACvB,wBAAwB;EACxB,wCAAwC;EACxC,kBAAkB;AACpB;AACA;EACE,oDAAoD;AACtD;AACA;EACE;IACE,6BAA6B;IAC7B,6BAA6B;EAC/B;AACF","sourcesContent":[".MuiInputBase-input {\n  font: inherit;\n  color: currentColor;\n  width: 100%;\n  border: 0;\n  height: 1.1876em;\n  margin: 0;\n  display: block;\n  padding: 6px 0 7px;\n  min-width: 0;\n  background: none;\n  box-sizing: content-box;\n  animation-name: mui-auto-fill-cancel;\n  letter-spacing: inherit;\n  animation-duration: 10ms;\n  -webkit-tap-highlight-color: transparent;\n  text-align: center;\n}\n.addItemGrid {\n  padding: 0.9375rem 0px 0.9375rem 1.875rem !important;\n}\n@media only screen and (min-width: 1280px) {\n  .backIcon {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MuiInputBase-input": "Exchange_MuiInputBase-input__irWL2",
	"mui-auto-fill-cancel": "Exchange_mui-auto-fill-cancel__3WcgM",
	"addItemGrid": "Exchange_addItemGrid__3zjYp",
	"backIcon": "Exchange_backIcon__HQnM0"
};
export default ___CSS_LOADER_EXPORT___;
