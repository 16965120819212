import React, { useEffect, useState } from "react";
import {
  Auth,
  AutoCompleteDropDown,
  HeadingOne,
  SnackBar,
  Input,
  SmallCardComponent
} from "../../components";
import DateRangeIcon from "@material-ui/icons/DateRange";
import noSchedule from "../../assets/images/No_schedule.jpg";

import body_mass_image from "../../assets/schedule_icon/Body Mass.png";
import exercise_image from "../../assets/schedule_icon/Exercise.png";
import measurements_image from "../../assets/schedule_icon/Measurements.png";
import menu_image from "../../assets/schedule_icon/Menu.png";
import supplements_image from "../../assets/schedule_icon/Supplements.png";

import { Backdrop, CircularProgress, Grid } from "@material-ui/core";
import { providerForGet } from "../../api";
import { getScheduleDateForClient } from "../../constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  CLIENTEXCHANGE,
  CLIENTEXERCISE,
  CLIENTSUPPLEMENT,
  CLIENTMEASUREMENT,
  CLIENTBODYMASS
} from "../../paths";
import { makeStyles } from "@material-ui/core/styles";
import { convertDate, useStyles } from "../../utils";

const localStyles = makeStyles(theme => ({
  titleText: {
    color: "#110F48",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1.25rem",
    marginBottom: "1.5625rem",
    "& svg": {
      verticalAlign: "middle"
    }
  },

  scheduleDate: {
    marginBottom: 25
  },
  textDesign: {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.5",
    letterSpacing: "-0.015em",
    color: "#000000"
  },
  centerImage: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "30%"
  }
}));

const ClientMySchedule = props => {
  const classes = localStyles();
  const classesBackdrop = useStyles();

  const auth = Auth.getToken();
  let propData = props["location"]["state"];
  const noScheduleDataMessageFromBackend = "No schedule data";
  const history = useHistory();
  const userInfo = Auth.getUserInfo();
  const [dateList, setDateList] = useState([]);
  const [scheduleDateNotPresent, setScheduleDateNotPresent] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    scheduleId: propData ? propData["id"] : null,
    alert: false,
    errorMessage: "",
    severity: "success"
  });

  const getScheduleDates = () => {
    setLoading(true);
    providerForGet(
      getScheduleDateForClient,
      {
        c: userInfo.id
      },
      auth
    )
      .then(async res => {
        let convertDateData = await convertDate(res.data);
        setDateList(convertDateData);
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            scheduleDate: convertDateData[0].id,
            scheduleObj: convertDateData[0],
            defaultScheduleObj: convertDateData[0]
          }
        }));
        setLoading(false);
      })
      .catch(error => {
        console.log("error", error.response);
        if (
          error.response &&
          error.response.data &&
          error.response.data.statusCode === 400 &&
          error.response.data.message === noScheduleDataMessageFromBackend
        ) {
          setScheduleDateNotPresent(true);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    getScheduleDates();
  }, []);

  const handleChangeAutoComplete = (eventName, event, value) => {
    if (eventName === "scheduleDate") {
      if (value !== null) {
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [eventName]: value.id,
            scheduleObj: value
          }
        }));
      } else {
        delete formState.values[eventName];
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [eventName]: formState.values.defaultScheduleObj.id,
            scheduleObj: formState.values.defaultScheduleObj
          }
        }));
      }
    }
  };

  const handleMyBodyMassClick = () => {
    let data = {};
    if (scheduleDateNotPresent === true) {
      setFormState(formState => ({
        ...formState,
        alert: true,
        errorMessage: "There are no schedule present for this section",
        severity: "error"
      }));
    } else {
      if (formState.values["scheduleObj"]) {
        data["id"] = formState.values.scheduleObj.id;
        history.push(CLIENTBODYMASS, data);
      }
    }
  };

  const handleMyMeasurementClick = () => {
    let data = {};
    if (scheduleDateNotPresent === true) {
      setFormState(formState => ({
        ...formState,
        alert: true,
        errorMessage: "There are no schedule present for this section",
        severity: "error"
      }));
    } else {
      if (formState.values["scheduleObj"]) {
        data["id"] = formState.values.scheduleObj.id;
        history.push(CLIENTMEASUREMENT, data);
      }
    }
  };

  const handleMySupplementClick = () => {
    let data = {};
    if (scheduleDateNotPresent === true) {
      setFormState(formState => ({
        ...formState,
        alert: true,
        errorMessage: "There are no schedule present for this section",
        severity: "error"
      }));
    } else {
      if (formState.values["scheduleObj"]) {
        data["id"] = formState.values.scheduleObj.id;
        data["scheduleObj"] = formState.values.scheduleObj;
        history.push(CLIENTSUPPLEMENT, data);
      }
    }
  };

  const handleMyExerciseClick = () => {
    let data = {};
    if (scheduleDateNotPresent === true) {
      setFormState(formState => ({
        ...formState,
        alert: true,
        errorMessage: "There are no schedule present for this section",
        severity: "error"
      }));
    } else {
      if (formState.values["scheduleObj"]) {
        data["id"] = formState.values.scheduleObj.id;
        history.push(CLIENTEXERCISE, data);
      }
    }
  };

  const handleMyExchangeClick = () => {
    let data = {};
    if (scheduleDateNotPresent === true) {
      setFormState(formState => ({
        ...formState,
        alert: true,
        errorMessage: "There are no schedule present for this section",
        severity: "error"
      }));
    } else {
      if (formState.values["scheduleObj"]) {
        data["id"] = formState.values.scheduleObj.id;
        data["scheduleObj"] = formState.values.scheduleObj;
        history.push(CLIENTEXCHANGE, data);
      }
    }
  };

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "error"
    }));
  };
  return (
    <div>
      <Grid>
        <SnackBar
          open={formState.alert}
          severity={formState.severity}
          onClose={handleSnackbarClose}
        >
          {formState.errorMessage}
        </SnackBar>
      </Grid>
      <HeadingOne className={classes.titleText}>
        <DateRangeIcon style={{ paddingRight: 10, color: "#1C4979" }} />
        MY SCHEDULES
      </HeadingOne>

      {scheduleDateNotPresent ? (
        <Grid>
          <img
            src={noSchedule}
            className={classes.centerImage}
            alt="No schedule/data"
          />
        </Grid>
      ) : (
        <>
          <Grid container className={classes.scheduleDate} spacing={1}>
            <Grid item lg={4} md={6} xs={12}>
              <AutoCompleteDropDown
                options={dateList}
                getOptionLabel={option => option.schedule_start_date}
                id="schedule_date"
                value={
                  dateList[
                    dateList.findIndex(function (item, i) {
                      return item.id === formState.values["scheduleDate"];
                    })
                  ] || null
                }
                onChange={(event, value) => {
                  handleChangeAutoComplete("scheduleDate", event, value);
                }}
                renderInput={params => (
                  <Input {...params} id="schedule_date" label="Schedule Date" />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <SmallCardComponent
                imgComponent="img"
                imgAlt="PFC logo"
                imgHeight="140"
                imgWidth="140"
                imgImage={body_mass_image}
                handleCardClick={() => {
                  handleMyBodyMassClick();
                }}
                title="PFC nutrition"
                cardTitle="Body Mass"
                contentText="View your Body Mass information and their progress over time"
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <SmallCardComponent
                imgComponent="img"
                imgAlt="PFC logo"
                imgHeight="140"
                imgWidth="140"
                imgImage={measurements_image}
                handleCardClick={() => {
                  handleMyMeasurementClick();
                }}
                title="PFC nutrition"
                cardTitle="Measurements"
                contentText="View your Measurement information and their progress over time"
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <SmallCardComponent
                imgComponent="img"
                imgAlt="PFC logo"
                imgHeight="140"
                imgWidth="140"
                imgImage={menu_image}
                handleCardClick={() => {
                  handleMyExchangeClick();
                }}
                title="PFC nutrition"
                cardTitle="Menu/Exchanges"
                contentText="View your Menu, Caloric information etc."
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <SmallCardComponent
                imgComponent="img"
                imgAlt="PFC logo"
                imgHeight="140"
                imgWidth="140"
                imgImage={supplements_image}
                handleCardClick={() => {
                  handleMySupplementClick();
                }}
                title="PFC nutrition"
                cardTitle="Supplements"
                contentText="View your new Supplement schedule"
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <SmallCardComponent
                imgComponent="img"
                imgAlt="PFC logo"
                imgHeight="140"
                imgWidth="140"
                imgImage={exercise_image}
                handleCardClick={() => {
                  handleMyExerciseClick();
                }}
                title="PFC nutrition"
                cardTitle="Exercises"
                contentText="View your Exercise schedule"
              />
            </Grid>
          </Grid>
        </>
      )}
      <Backdrop className={classesBackdrop.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

ClientMySchedule.propTypes = {};

ClientMySchedule.defaultProps = {};

export default ClientMySchedule;
