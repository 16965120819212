import { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { providerForPost } from "../../../api";
import { userLogOut } from "../../../constants";
import { LOGIN } from "../../../paths";
import { Auth, Auth as auth } from "../../index";
import { AuthContext } from "../../../context";

const Logout = () => {
  const history = useHistory();
  const { setUserInfo } = useContext(AuthContext);

  const logout = async () => {
    providerForPost(userLogOut, {}, auth.getToken(), {
      desc: "User Log Out"
    })
      .then(res => {})
      .catch(error => {});
    if (Auth.getLogoUrl() || Auth.getToken() || Auth.getUserInfo()) {
      Auth.clearAppStorage();
      history.replace(LOGIN);
    } else {
      history.push(LOGIN);
    }
  };

  useEffect(() => {
    setUserInfo(userInfo => ({
      ...userInfo,
      user: {
        logo: null,
        name: null
      }
    }));
    logout();
  }, []);

  return null;
};

Logout.propTypes = {};

Logout.defaultProps = {};

export default Logout;
