import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { providerForDelete, providerForPut } from "../../api";
import { notes } from "../../constants";
import {
  Auth as auth,
  DarkBlueButton,
  HeadingOne,
  InlineDatePicker,
  SnackBar,
  useWindowDimensions,
  Table,
  NameComponent,
} from "../../components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ADDEDITNOTES, CLIENTDASHBAORD, CLIENTEXCHANGE } from "../../paths";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import moment from "moment";
import { formatDate, plainDate } from "../../utils";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import "./ReactQuill.css";
import {
  DELETE_MESSAGE,
  ERROR_MESSAGE,
} from "../../constants/genericConstants";

export default function ManageNotes(props) {
  const tableRef = React.createRef();
  const { width } = useWindowDimensions();
  const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 700,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff",
    },
    borderNone: {
      border: "none",
      padding: "1rem !important",
    },
    individualItem: {
      textAlignLast: "center",
      textAlign: "center",
      width: props.isDesktop ? width / 30 : 60,
      minWidth: props.isDesktop ? width / 30 : 60,
    },
    individualItemBig: {
      textAlignLast: "center",
      textAlign: "center",
      width: props.isDesktop ? width / 8 : 150,
      minWidth: props.isDesktop ? width / 8 : 150,
    },
    trRoot: {
      overflow: "auto",
      "& .MuiTable-root": {
        borderSpacing: " 0 1.0625rem !important",
        borderCollapse: "separate  !important",
      },
      "& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover": {
        background: "#F8F8FA",
      },
      "& .MuiTableRow-root": {
        "&:hover": {
          background: "#F8F8FA !important",
        },
      },
      marginTop: "15px",
    },

    ".MuiTableCell-root": {
      padding: "1rem !important",
    },
    inputRoot: {
      "& .MuiOutlinedInput-input": {
        textAlign: "center",
        padding: "0.875rem !important",
      },
      "& .MuiOutlinedInput-root": {
        margin: "8px 0px",
      },
      "& .MuiInputBase-root": {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "1rem",
        color: "#110F48",
      },
    },
    mainGrid: {
      overflow: props.isDesktop ? "none" : "auto",
    },
    addButton: {
      textAlign: "right",
      marginBottom: "7px !important",
    },
    nameComponent: {
      marginBottom: "10px !important",
    },
  }));

  const classes = useStyles();
  let propData = props["location"]["state"];

  const [dateFilter, setDateFilter] = useState({
    fromDate: new Date().setMonth(new Date().getMonth() - 3),
    toDate: new Date(),
    dateFromError: false,
    dateToError: false,
    dateFromErrorText: "",
    dateToErrorText: "",
    filterSelectError: false,
    filterSelectErrorText: "",
    gte_key: "",
    lte_key: "",
  });

  const [statusVariable, setStatusVariable] = useState({
    severity: propData
      ? propData["successErrorMessage"]
        ? "success"
        : ""
      : "",
    isOpenSnackBar: propData ? propData["successErrorMessage"] : false,
    successErrorMessage: propData ? propData["successErrorMessage"] : "",
  });
  const history = useHistory();

  const [formState, setFormState] = useState({
    viewOwnNotes: propData
      ? propData["viewOwnNotes"]
        ? true
        : false
      : false /** Viewing own notes */,
    viewOtherNotes: propData /** Viewing other notes */
      ? propData["viewOtherNotes"]
        ? true
        : false
      : false,

    userId: propData ? (propData["userId"] ? propData["userId"] : null) : null,
    franchiseId: auth.getUserInfo().franchise.id,
    isClient:
      auth.getUserInfo().role.name === process.env.REACT_APP_CLIENT_ROLE_NAME,
    isNutritionist:
      auth.getUserInfo().role.name === process.env.REACT_APP_NUTRITIONIST_ROLE,

    isBackAvailable: propData ? propData.isBackAvailable : false,
    backPath: propData
      ? propData.isBackAvailable
        ? propData.backPath
        : null
      : null,
    backName: propData
      ? propData.isBackAvailable
        ? propData.backName
        : ""
      : "",
    customHeader: propData ? propData.customHeader : "NOTES",
    private: propData ? (propData["private"] ? true : false) : false,
  });

  const columns = [
    { title: "Date", field: "date" },
    { title: "Details", field: "details" },
  ];

  const [filter, setFilter] = useState({
    _sort: "date:desc",
    date_gte: formatDate(dateFilter.fromDate),
    date_lte: formatDate(dateFilter.toDate),
  });

  const getParamsAndUserInfo = (params) => {
    let user = "";
    if (formState.isClient) {
      params = {
        ...params,
        note_type: "client",
        user: auth.getUserInfo().id,
        franchise: formState.franchiseId,
        private: false,
      };
      if (formState.viewOtherNotes) {
        user = "nutritionist";
        params = {
          ...params,
          user: auth.getUserInfo().id,
          note_type: "nutritionist",
          franchise: formState.franchiseId,
          private: false,
        };
      }
    } else if (formState.isNutritionist) {
      if (formState.viewOwnNotes) {
        params = {
          ...params,
          user: formState.userId,
          note_type: "nutritionist",
          franchise: formState.franchiseId,
          private: formState.private,
        };
      } else if (formState.viewOtherNotes) {
        user = "client";
        params = {
          ...params,
          note_type: "client",
          user: formState.userId,
          audit_log_clientid: formState.userId,
          private: false,
        };
      } else {
        params = {
          ...params,
          note_type: "nutritionist",
          franchise: formState.franchiseId,
          private: false,
        };
      }
    }
    return {
      params: params,
      user: user,
    };
  };

  const getNotesData = async (page, pageSize) => {
    let { params, user } = getParamsAndUserInfo({
      page: page,
      pageSize: pageSize,
    });

    Object.keys(filter).map((res) => {
      if (!params.hasOwnProperty(res)) {
        params[res] = filter[res];
      }
      return null;
    });

    params.desc = `Viewed ${user} ${
      formState.isPrivateNotes ? "private" : ""
    } notes listing page by ${auth.getUserInfo().full_name}`;

    return new Promise((resolve, reject) => {
      fetch(notes + "?" + new URLSearchParams(params), {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth.getToken(),
        },
      })
        .then((response) => response.json())
        .then((result) => {
          let finalData = [];
          if (result.data && result.data.length) {
            result.data.map((r) => {
              let json = {
                id: r.id,
                date: plainDate(r.date),
                details: r.notes_meta_data,
                notes: r.notes,
                is_read: r.is_read,
              };
              finalData.push(json);
              return null;
            });
          }
          resolve({
            data: finalData,
            page: result.page - 1,
            totalCount: result.totalCount,
          });
        });
    });
  };

  const handleSnackbarClose = async () => {
    delete propData["successErrorMessage"];
    setStatusVariable((statusVariable) => ({
      ...statusVariable,
      severity: "",
      isOpenSnackBar: false,
      successErrorMessage: "",
    }));
  };

  const handleBackArrow = () => {
    if (formState.isNutritionist) {
      history.push(formState.backPath);
    } else {
      if (formState.isBackAvailable && formState.backPath) {
        let data = {
          id: formState.scheduleIdToSendBack,
          scheduleObj: formState.scheduleObjToSendBack,
        };

        if (formState.backPath === CLIENTEXCHANGE) {
          data = {
            ...data,
            exchangeDay: formState.dayToSendBack,
          };
        }
        history.push(formState.backPath, data);
      } else {
        history.push(CLIENTDASHBAORD);
      }
    }
  };

  const handleEndDateChange = (event) => {
    let endDate = moment(event).endOf("day").format("YYYY-MM-DDT23:59:59.999Z");
    let toDate = "";
    let dateToError = false;
    let dateToErrorText = "";
    if (endDate !== "Invalid date") {
      toDate = new Date(endDate).toISOString();
      if (
        dateFilter.fromDate &&
        dateFilter.fromDate !== "" &&
        moment(endDate).isBefore(moment(dateFilter.fromDate))
      ) {
        dateToError = true;
        dateToErrorText = ["To date cannot be less than from date"];
      }
    }

    setDateFilter((dateFilter) => ({
      ...dateFilter,
      toDate: toDate,
      dateToError: dateToError,
      dateToErrorText: dateToErrorText,
    }));
  };

  /** Handle Start Date filter change */
  const handleStartDateChange = (event) => {
    let startDate = moment(event).format("YYYY-MM-DDT00:00:00.000Z");
    let fromDate = null;
    let dateFromError = false;
    let dateFromErrorText = "";
    if (startDate !== "Invalid date") {
      fromDate = new Date(startDate).toISOString();
      if (
        dateFilter.toDate &&
        dateFilter.toDate !== "" &&
        moment(fromDate).isAfter(moment(dateFilter.toDate))
      ) {
        dateFromError = true;
        dateFromErrorText = ["From date cannot be greater than to date"];
      }
    }

    setDateFilter((dateFilter) => ({
      ...dateFilter,
      fromDate: fromDate,
      dateFromError: dateFromError,
      dateFromErrorText: dateFromErrorText,
    }));
  };

  const handleSearchButton = () => {
    if (
      !dateFilter.dateFromError &&
      !dateFilter.dateToError &&
      dateFilter.toDate &&
      dateFilter.fromDate &&
      dateFilter.toDate !== "" &&
      dateFilter.fromDate !== ""
    ) {
      let gte_key = "date_gte";
      let lte_key = "date_lte";

      setDateFilter((dateFilter) => ({
        ...dateFilter,
        gte_key: gte_key,
        lte_key: lte_key,
      }));
      setFilter((filter) => ({
        ...filter,
        page: 1,
        date_gte: formatDate(dateFilter.fromDate),
        date_lte: formatDate(dateFilter.toDate),
      }));
      tableRef.current.onQueryChange();
    }
  };

  const orderFunc = (columnId, direction) => {
    let orderByColumn;
    let orderBy = "";
    if (columnId >= 0) {
      orderByColumn = columns[columnId]["field"];
    }
    orderBy = orderByColumn + ":" + direction;
    setFilter((filter) => ({
      ...filter,
      _sort: orderBy,
    }));
    tableRef.current.onQueryChange();
  };

  const handleClickOpen = (rowData) => {
    let data = {
      userId: formState.userId ? formState.userId : null,
      noteId: rowData.id,
      isEdit: true,
      isBackAvailable: true,
      backProps: propData,
      backName: "Back To Notes",
      customHeader: formState.customHeader,
    };

    history.push(ADDEDITNOTES, data);
  };

  return (
    <>
      <div>
        <Tooltip
          title={
            formState.isBackAvailable ? formState.backName : "Back To Dashboard"
          }
        >
          <IconButton
            style={{
              position: "relative",
              right: "120px",
            }}
            onClick={() => handleBackArrow()}
          >
            <ChevronLeftIcon
              style={{
                padding: "5px",
                border: "solid #DADADA 1px",
                borderRadius: "25px",
                margin: "0px 30px 0px 10px",
                background: "#fff",
                marginTop: 0,
              }}
            />
          </IconButton>
        </Tooltip>

        {propData ? (
          propData.NameComponent ? (
            <Grid md={6} xs={12} className={classes.nameComponent}>
              <NameComponent
                initials={propData.NameComponent.initials}
                full_name={propData.NameComponent.full_name}
                id={propData.NameComponent.id}
                scheduleData={propData.NameComponent.scheduleData}
              />
            </Grid>
          ) : null
        ) : null}

        <HeadingOne>
          <FileCopyIcon />
          <span style={{ position: "absolute", marginLeft: "10px" }}>
            {formState.customHeader ? formState.customHeader : "NOTES"}
          </span>
        </HeadingOne>

        <Grid
          container
          spacing={1}
          alignItems="flex-end"
          style={{ marginTop: "1.25rem" }}
        >
          <Grid item className={classes.paddingDate}>
            <InlineDatePicker
              id="startDate"
              label="From Date"
              placeholder="MM/DD/YYYY"
              maxDate={new Date()}
              value={dateFilter.fromDate || null}
              name={"From Date"}
              onChange={(event) => handleStartDateChange(event)}
              error={dateFilter.dateFromError}
              required
              fullWidth
              helperText={
                dateFilter.dateFromError ? dateFilter.dateFromErrorText : null
              }
            />
          </Grid>
          <Grid item className={classes.paddingDate}>
            <InlineDatePicker
              id="endDate"
              label="To Date"
              maxDate={new Date()}
              placeholder="MM/DD/YYYY"
              value={dateFilter.toDate || null}
              name={"To Date"}
              onChange={(event) => handleEndDateChange(event)}
              error={dateFilter.dateToError}
              required
              fullWidth
              helperText={
                dateFilter.dateToError ? dateFilter.dateToErrorText : null
              }
            />
          </Grid>

          <Grid item className={classes.paddingDate}>
            <DarkBlueButton
              variant="contained"
              size="small"
              style={{
                margin: "8px 0 8px 5px",
              }}
              onClick={handleSearchButton}
            >
              SEARCH
            </DarkBlueButton>
          </Grid>
        </Grid>
        {formState.viewOtherNotes ? null : (
          <Grid container spacing={2}>
            <Grid item sm={12} className={classes.addButton}>
              <DarkBlueButton
                onClick={() => {
                  let data = {
                    isBackAvailable: true,
                    backName: "Back To Notes",
                    userId: formState.userId,
                    private: formState.private,
                    backProps: propData,
                    customHeader: formState.customHeader,
                  };
                  history.push(ADDEDITNOTES, data);
                }}
              >
                <AddIcon /> &nbsp;
                {"Add Note"}
              </DarkBlueButton>
              &nbsp;
            </Grid>
          </Grid>
        )}
        <Grid
          item
          style={{
            marginTop: "2%",
          }}
        >
          <Table
            tableRef={tableRef}
            columns={columns}
            data={async (query) => {
              return await getNotesData(query.page + 1, query.pageSize);
            }}
            localization={{
              body: {
                editRow: {
                  deleteText: `Are you sure you want to delete this note?`,
                  saveTooltip: "Delete",
                },
              },
            }}
            detailPanel={[
              {
                tooltip: "Notes",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        padding: "1%",
                        maxHeight: "560px",
                        overflow: "auto",
                      }}
                    >
                      <>
                        <ReactQuill theme={null} value={rowData.notes} />
                      </>
                    </div>
                  );
                },
              },
            ]}
            editable={{
              isDeletable: (rowData) =>
                formState.viewOtherNotes ? false : true,
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(async () => {
                    await providerForDelete(
                      notes,
                      oldData.id,
                      auth.getToken(),
                      {
                        desc: `Delete ${
                          formState.private ? `private` : ``
                        }notes by ${auth.getUserInfo().role.name}`,
                      }
                    )
                      .then(async (res) => {
                        setFormState((formState) => ({
                          ...formState,
                          alert: true,
                          errorMessage: res.data.full_name + DELETE_MESSAGE,
                          severity: "success",
                        }));
                        await getNotesData(1, filter.pageSize);
                      })
                      .catch((err) => {
                        setFormState((formState) => ({
                          ...formState,
                          alert: true,
                          errorMessage: ERROR_MESSAGE + err,
                          severity: "error",
                        }));
                      });
                    resolve();
                  }, 1000);
                  tableRef.current.onQueryChange();
                }),
            }}
            actions={[
              (rowData) => ({
                icon: () => <EditOutlinedIcon />,
                tooltip: "Edit",
                disabled: formState.viewOtherNotes,
                onClick: (event, rowData) => {
                  handleClickOpen(rowData);
                },
              }),
            ]}
            onRowClick={(event, rowData, togglePanel) => {
              if (formState.viewOtherNotes) {
                if (rowData.is_read) {
                  togglePanel();
                } else {
                  togglePanel();
                  providerForPut(
                    notes,
                    rowData.id,
                    {
                      is_read: true,
                    },
                    auth.getToken(),
                    {
                      desc: `${auth.getUserInfo().role.name} viewed ${
                        formState.isClient ? `nutritionist` : `client`
                      } note with id ${rowData.id}`,
                    }
                  )
                    .then((res) => {})
                    .catch((err) => {});
                  rowData.is_read = true;
                  return rowData.is_read;
                }
              } else {
                togglePanel();
              }
            }}
            options={{
              pageSize: 10,
              actionsColumnIndex: -1,
              search: false,
              sorting: true,
              thirdSortClick: false,
              headerStyle: {
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "#8A8A97",
                borderBottom: "solid #E0E0E0 2px",
                fontSize: "1rem",
              },
              cellStyle: {
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "#000000",
                fontSize: "1rem",
              },
              paginationStyle: {
                justifyContent: "center",
              },
              rowStyle: (rowData) => ({
                backgroundColor:
                  !rowData.is_read && formState.viewOtherNotes
                    ? "#F3F3F3"
                    : "#FFFFFF",
              }),
            }}
            onOrderChange={(orderedColumnId, orderDirection) => {
              orderFunc(orderedColumnId, orderDirection);
            }}
          />
        </Grid>
      </div>
      <SnackBar
        open={statusVariable.isOpenSnackBar}
        severity={statusVariable.severity}
        onClose={handleSnackbarClose}
      >
        {statusVariable.successErrorMessage}
      </SnackBar>
    </>
  );
}
