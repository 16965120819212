import * as React from "react";
import { Route } from "react-router-dom";
import { Layout } from "../hoc";
import { Auth as auth, NotFoundPage } from "../components";
import { Redirect } from "react-router-dom";
import {
  CREATEMEALTEMPLATE,
  LOGIN,
  MANAGEEXCHANGEMEALTEMPLATE
} from "../paths";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { useStyles as ManagePageStyles } from "../utils";
import { providerForGet } from "../api";
import { getMealTemplate } from "../constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ExchangesMenusFavourites = props => {
  const history = useHistory();
  const {
    component: Component,
    computedMatch: ComputedMatch,
    location: Location
  } = props;

  const [loader, setLoader] = React.useState(true);
  const [no_of_meals_per_day, setNoMeals] = React.useState(0);
  const [isNotFoundPage, setIsNotFoundPage] = React.useState(false);
  const managePageStyles = ManagePageStyles();

  const getAllMealInfo = async template_id => {
    await providerForGet(
      getMealTemplate + "/" + template_id,
      {},
      auth.getToken()
    )
      .then(res => {
        setNoMeals(res.data.no_of_meals_per_day);
        setIsNotFoundPage(false);
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        setIsNotFoundPage(true);
      });
  };

  React.useEffect(() => {
    setLoader(true);
    if (!props.location.isValid) {
      history.push({
        pathname: MANAGEEXCHANGEMEALTEMPLATE
      });
    }
    const urlParams = new URLSearchParams(window.location.search);
    let exchange_day = urlParams.get("e_day");
    let template_id = urlParams.get("s");
    if (!template_id) {
      setLoader(false);
      setIsNotFoundPage(true);
    }
    if (props.path === CREATEMEALTEMPLATE) {
      if (!exchange_day) {
        setLoader(false);
        setIsNotFoundPage(true);
      }
    }
    getAllMealInfo(template_id);
  }, []);

  if (auth.getToken() !== null) {
    if (loader) {
      return (
        <>
          <Route
            render={otherProps => (
              <>
                <Layout>
                  <Backdrop className={managePageStyles.backdrop} open={loader}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Layout>
              </>
            )}
          />
        </>
      );
    } else {
      if (isNotFoundPage) {
        return (
          <>
            <Route
              render={otherProps => (
                <>
                  <Layout>
                    <NotFoundPage />
                  </Layout>
                </>
              )}
            />
          </>
        );
      } else {
        return (
          <>
            <Route
              render={otherProps => (
                <>
                  <Layout>
                    <Component
                      {...otherProps}
                      urlParams={ComputedMatch}
                      location={Location}
                      no_of_meals_per_day={no_of_meals_per_day}
                    />
                  </Layout>
                </>
              )}
            />
          </>
        );
      }
    }
  } else {
    return (
      <React.Fragment>
        {auth.clearAppStorage()}
        <Redirect
          to={{
            pathname: LOGIN
          }}
        />
      </React.Fragment>
    );
  }
};
export default ExchangesMenusFavourites;
