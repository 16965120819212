import React from "react";
import { CardComponent, HeadingOne } from "../../components";
import { Grid } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import client_schedule from "../../assets/images/Client_schedule.jpg";
import client_profile from "../../assets/images/Client_profile.jpg";
import client_notes from "../../assets/images/Client_notes.jpg";
import client_report from "../../assets/images/Client_report.jpg";
import nutritionist_notes from "../../assets/images/Nutritionist_notes.jpg";
import food_diary from "../../assets/images/Food Diary Icon.png";

import {
  CLIENTMYNOTES,
  CLIENTMYREPORTS,
  CLIENTMYSCHEDULE,
  CLIENTMYPROFILE,
  CLIENTDASHBAORD,
  CLIENTFOODDIARY
} from "../../paths";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@material-ui/core/styles";
import auth from "../../components/Auth";

const localStyles = makeStyles(theme => ({
  titleText: {
    color: "#110F48",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1.25rem",
    marginBottom: "1.5625rem",
    "& svg": {
      verticalAlign: "middle"
    }
  },
  scheduleDate: {
    marginBottom: 15
  },
  textDesign: {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.5",
    letterSpacing: "-0.015em",
    color: "#000000"
  },
  ".MuiSvgIcon-colorPrimary": {
    color: "red !important"
  }
}));

const ClientDashboard = () => {
  const classes = localStyles();

  const history = useHistory();
  const contentText = {
    myNotes:
      "Keep track of your schedules for Menu, Exercises Supplements etc. in the Notes section.",
    mySchedules:
      "View your schedules for Menu, Exercises, Supplements etc. to keep up with your health.",
    myReports:
      "Download and view your various health reports to see your Progress.",
    myProfile: "Edit/View your personal information in my profile."
  };
  const handleMyScheduleClick = () => {
    history.push(CLIENTMYSCHEDULE);
  };
  const handleMyNotesClick = () => {
    let data = {
      viewOwnNotes: true,
      viewOtherNotes: false,
      userId: auth.getUserInfo().id,
      isBackAvailable: true,
      backPath: CLIENTDASHBAORD,
      backName: "Back to Dashboard"
    };
    history.push(CLIENTMYNOTES, data);
  };

  const handleNutritionistClick = () => {
    let data = {
      viewOwnNotes: false,
      viewOtherNotes: true,
      customHeader: "Nutritionist's Notes",
      userId: auth.getUserInfo().id,
      isBackAvailable: true,
      backPath: CLIENTDASHBAORD,
      backName: "Back to Dashboard"
    };
    history.push(CLIENTMYNOTES, data);
  };

  const handleMyReportsClick = () => {
    history.push(CLIENTMYREPORTS);
  };

  const handleMyProfileClick = () => {
    history.push(CLIENTMYPROFILE);
  };

  const handleViewEditFoodDiary = () => {
    history.push(CLIENTFOODDIARY);
  };

  return (
    <div>
      <HeadingOne className={classes.titleText}>
        <DashboardIcon style={{ paddingRight: 10, color: "#1C4979" }} />
        DASHBOARD
      </HeadingOne>

      <Grid container spacing={5} display="flex">
        <Grid item lg={3} md={4} sm={6} xs={12} display="flex">
          <CardComponent
            imgComponent="img"
            imgAlt="PFC logo"
            imgHeight="133"
            imgWidth="133"
            imgImage={client_schedule}
            handleCardClick={() => {
              handleMyScheduleClick();
            }}
            title="PFC nutrition"
            cardTitle={<b>MY SCHEDULES</b>}
            contentText={contentText.mySchedules}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12} display="flex">
          <CardComponent
            imgComponent="img"
            imgAlt="PFC logo"
            imgHeight="133"
            imgWidth="133"
            imgImage={food_diary}
            handleCardClick={() => {
              handleViewEditFoodDiary();
            }}
            title="PFC nutrition"
            cardTitle={<b>FOOD DIARY</b>}
            contentText="Edit/View your food diary."
          />
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12} display="flex">
          <CardComponent
            imgComponent="img"
            imgAlt="PFC logo"
            imgHeight="133"
            imgWidth="133"
            imgImage={client_notes}
            handleCardClick={() => {
              handleMyNotesClick();
            }}
            title="PFC nutrition"
            cardTitle={<b>MY NOTES</b>}
            contentText={contentText.myNotes}
          />
        </Grid>

        <Grid item lg={3} md={4} sm={6} xs={12} display="flex">
          <CardComponent
            imgComponent="img"
            imgAlt="PFC logo"
            imgHeight="133"
            imgWidth="133"
            imgImage={nutritionist_notes}
            handleCardClick={() => {
              handleNutritionistClick();
            }}
            title="PFC nutrition"
            cardTitle={<b>NUTRITIONIST'S NOTES</b>}
            contentText="Review notes written by your nutrionists."
          />
        </Grid>

        <Grid item lg={3} md={4} sm={6} xs={12} display="flex"></Grid>

        <Grid item lg={3} md={4} sm={6} xs={12} display="flex">
          <CardComponent
            imgComponent="img"
            imgAlt="PFC logo"
            imgHeight="133"
            imgWidth="133"
            imgImage={client_report}
            handleCardClick={() => {
              handleMyReportsClick();
            }}
            title="PFC nutrition"
            cardTitle={<b>MY REPORTS</b>}
            contentText={contentText.myReports}
          />
        </Grid>

        <Grid item lg={3} md={4} sm={6} xs={12} display="flex">
          <CardComponent
            imgComponent="img"
            imgAlt="PFC logo"
            imgHeight="133"
            imgWidth="133"
            imgImage={client_profile}
            handleCardClick={() => {
              handleMyProfileClick();
            }}
            title="PFC nutrition"
            cardTitle={<b>MY PROFILE</b>}
            contentText={contentText.myProfile}
          />
        </Grid>

        <Grid item lg={3} md={4} sm={6} xs={12} display="flex"></Grid>
      </Grid>
    </div>
  );
};

ClientDashboard.propTypes = {};

ClientDashboard.defaultProps = {};

export default ClientDashboard;
