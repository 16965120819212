// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_Main__Vm9LA {\n  width: calc(100% - 56px);\n  padding-left: 35px;\n  padding-bottom: 10px;\n  min-height: calc(100vh - 104px);\n}\n.Layout_mainContainer__2UN1U {\n  width:82%;\n  padding: 50px 0px;\n  margin: 0 auto;\n}\n@media only screen and (max-width: 600px) {\n  .Layout_mainContainer__2UN1U {\n  width:100%;\n    padding: 50px 10px;\n  }\n}", "",{"version":3,"sources":["webpack://src/hoc/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,oBAAoB;EACpB,+BAA+B;AACjC;AACA;EACE,SAAS;EACT,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE;EACA,UAAU;IACR,kBAAkB;EACpB;AACF","sourcesContent":[".Main {\n  width: calc(100% - 56px);\n  padding-left: 35px;\n  padding-bottom: 10px;\n  min-height: calc(100vh - 104px);\n}\n.mainContainer {\n  width:82%;\n  padding: 50px 0px;\n  margin: 0 auto;\n}\n@media only screen and (max-width: 600px) {\n  .mainContainer {\n  width:100%;\n    padding: 50px 10px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Main": "Layout_Main__Vm9LA",
	"mainContainer": "Layout_mainContainer__2UN1U"
};
export default ___CSS_LOADER_EXPORT___;
