import React, { useState, useContext } from "react";
import {
  Hidden,
  Drawer,
  makeStyles,
  Toolbar,
  InputLabel,
  Avatar,
  AppBar,
  Card,
  CardContent,
  createMuiTheme,
  ThemeProvider,
  Backdrop,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import Logo from "../../../components/Logo/Logo";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { LogoutMenu, SideMenus } from "../SideMenus";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { useHistory } from "react-router-dom";
import {
  CHANGEPASSWORD,
  AUTHENTICATOR,
  VIEWPROFILE,
  CLIENTMYPROFILE,
  LOGOUT,
  BODYMASS,
  CLIENTMYNOTES,
  CLIENTFOODDIARY
} from "../../../paths";
import { Auth, Auth as auth, DarkBlueButton } from "../../../components";
import { getInitials } from "../../../utils";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { providerForDownload, providerForPost } from "../../../api";
import PreviousSchedule from "../../../containers/Schedules/PreviousSchedule";
import styles from "./SideDrawer.module.css";
import {
  ctAllData,
  generateGiveAllWithInserts,
  getAllReports
} from "../../../constants/urlConstants";
import AddReports from "../../../assets/small_icons/Download give all.png";
import LockedNutriNotes from "../../../assets/images/Locked Nutritionist Notes Private.png";
import NotesForClient from "../../../assets/images/Nutritionist Notes for client.png";
import ClientNotes from "../../../assets/images/Client Notes.png";
import { AuthContext } from "../../../context";
import DialogBox from "../../../components/DialogBox/DialogBox";

const drawerWidth = 228;

const useMobileDrawerStyles = makeStyles(theme => ({
  drawer: {
    backgroundColor: "#EDEDF2",
    width: drawerWidth
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: "#fff"
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    boxShadow: "none",
    marginTop: "25px"
  },
  nameLabel: {
    alignSelf: "center",
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#110F48",
    fontSize: "1rem"
  },
  menu: {
    zIndex: theme.zIndex.drawer + 1,
    left: "-50px !important",
    right: "30px !important",
    width: "200px",
    marginRight: "-7px"
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: "#110F48",
    backgroundColor: "#D8F4FF",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "1.25rem"
  },
  largeMobileView: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    color: "#ffffff",
    backgroundColor: "#110F48",
    alignSelf: "center",
    marginTop: "5%"
  },
  menuButton: {
    color: "#000000",
    flexGrow: 1
  },
  rootMobileView: {
    flexGrow: 1
  },
  Iconroot: {
    display: "flex",
    alignSelf: "center",
    marginRight: "0px",
    marginLeft: "auto"
  },
  mobIconroot: {
    display: "flex",
    alignSelf: "center",
    marginRight: "0px",
    marginLeft: "auto"
  }
}));

const useWebDrawerStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    minHeight: "72px"
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    color: "#000000"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#f4f8ff"
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "center",
    minHeight: "72px !important",
    paddingTop: "15%"
  }
}));

const SideAndTopNavBar = props => {
  const { userInfo } = useContext(AuthContext);
  const anchorRef = React.useRef(null);
  const urlParams = new URLSearchParams(window.location.search);
  const [loader, setLoader] = React.useState(false);
  const classes = useMobileDrawerStyles();
  const classesWeb = useWebDrawerStyles();
  const history = useHistory();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openMenu, setMenuOpen] = React.useState(false);
  const [openWeb, setOpenWeb] = React.useState(false);
  const [previous, setprevious] = React.useState(false);

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    children: ""
  });

  const [openGiveAll, setOpenGiveAll] = React.useState(false);
  const [openClientNotes, setOpenClientNotes] = React.useState(false);

  const anchorRefGiveAll = React.useRef(null);
  const anchorRefClientNotes = React.useRef(null);

  const handleGiveAllToggle = () => {
    setOpenGiveAll(prevOpen => !prevOpen);
  };

  const handleClientNotesToggle = () => {
    setOpenClientNotes(prevOpen => !prevOpen);
  };

  const handleCloseGiveAll = event => {
    if (
      anchorRefGiveAll.current &&
      anchorRefGiveAll.current.contains(event.target)
    ) {
      return;
    }
    setOpenClientNotes(false);
  };
  const handleCloseClientNotes = event => {
    if (
      anchorRefClientNotes.current &&
      anchorRefClientNotes.current.contains(event.target)
    ) {
      return;
    }

    setOpenGiveAll(false);
  };

  function handleListKeyDownGiveAll(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenGiveAll(false);
    }
  }

  function handleListKeyDownClientNotes(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenClientNotes(false);
    }
  }

  // return focus to the button when we transitioned from !openGiveAll -> openGiveAll
  const prevOpenGiveAll = React.useRef(openGiveAll);
  const prevOpenClientNotes = React.useRef(openClientNotes);

  React.useEffect(() => {
    if (prevOpenGiveAll.current === true && openGiveAll === false) {
      anchorRefGiveAll.current.focus();
    }
    if (prevOpenClientNotes.current === true && openClientNotes === false) {
      anchorRefClientNotes.current.focus();
    }
    prevOpenClientNotes.current = openClientNotes;
    prevOpenGiveAll.current = openGiveAll;
  }, [openGiveAll, openClientNotes]);

  const handleWebDrawerOpen = () => {
    setOpenWeb(true);
    props.handleContentShiftTrue();
  };

  const handleWebDrawerClose = () => {
    setOpenWeb(false);
    props.handleContentShiftFalse();
  };

  const getbackPath = () => {
    let backPath = "";
    backPath =
      window.location.pathname +
      "?c=" +
      urlParams.get("c") +
      "&s=" +
      urlParams.get("s");
    return backPath;
  };

  const toolBarTheme = createMuiTheme({
    overrides: {
      MuiToolbar: {
        root: {
          display: "inline"
        }
      }
    }
  });

  /**
   * * Handle menu function
   */
  const handleMenuToggle = () => {
    setMenuOpen(prevOpen => !prevOpen);
  };

  const handleMenuClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setMenuOpen(false);
    }
  }

  const prevOpen = React.useRef(openMenu);
  React.useEffect(() => {
    if (prevOpen.current === true && openMenu === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = openMenu;
  }, [openMenu]);

  /**
   * * Drawer function
   */

  const handleDrawerToggle = (name = "abcd") => {
    setOpen(!open);
  };

  const handleChangePasswordButton = () => {
    setMenuOpen(false);
    history.push({
      pathname: CHANGEPASSWORD
    });
  };

  const handlePreviousSchedule = () => {
    setprevious(true);
  };

  const handleDialogCancel = () => {
    setDialog(dialog => ({
      ...dialog,
      open: false,
      children: "",
      title: ""
    }));
  };

  const handleCTAll = async () => {
    props.handleIsButtonClicked();
    setDialog(dialog => ({
      ...dialog,
      open: false,
      children: "",
      title: ""
    }));
    setLoader(true);
    let postData = {
      scheduleId: urlParams.get("s"),
      clientId: urlParams.get("c")
    };

    await providerForPost(ctAllData, postData, Auth.getToken())
      .then(res => {
        setLoader(false);
        history.push({
          pathname: BODYMASS,
          search: `?c=${res.data.c}&s=${res.data.s}`
        });
        props.handleDone();
      })
      .catch(err => {
        props.handleDone();
        setLoader(false);
        console.log("error", err);
      });
  };

  const handleCloseDialgue = status => {
    setprevious(status);
  };

  const handleDownloadAllReports = async () => {
    setLoader(true);
    await providerForDownload(
      getAllReports,
      {
        scheduleId: urlParams.get("s"),
        clientId: urlParams.get("c")
      },
      Auth.getToken(),
      {
        desc: "Download All Reports for client",
        audit_log_clientid: urlParams.get("c")
      }
    )
      .then(res => {
        setLoader(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "all_reports.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        // PDF for print
        const pdfUrl = URL.createObjectURL(
          new Blob([res.data], { type: "application/pdf" })
        );
        const pdfNewWindow = window.open();
        pdfNewWindow.location.href = pdfUrl;
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const handleDownloadGiveAllReport = async () => {
    setLoader(true);
    await providerForDownload(
      generateGiveAllWithInserts,
      {
        scheduleId: urlParams.get("s"),
        clientId: urlParams.get("c")
      },
      Auth.getToken(),
      {
        desc: "Download Give All Reports with inserts for a client",
        audit_log_clientid: urlParams.get("c")
      }
    )
      .then(res => {
        setLoader(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "give_all_with_inserts.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        // PDF for print
        const pdfUrl = URL.createObjectURL(
          new Blob([res.data], { type: "application/pdf" })
        );
        const pdfNewWindow = window.open();
        pdfNewWindow.location.href = pdfUrl;
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  return (
    <>
      <Hidden mdDown>
        <div>
          <AppBar
            position="fixed"
            className={clsx(classesWeb.appBar, {
              [classesWeb.appBarShift]: openWeb
            })}
            style={{
              background: "#f4f8ff",
              boxShadow: "0px 3px 5px #e8eef7"
            }}
            elevation={0}
          >
            <ThemeProvider theme={toolBarTheme}>
              <Toolbar
                style={{
                  padding: "0 25px",
                  minHeight: "72px",
                  flexWrap: "wrap",
                  flexShrink: 0,
                  flex: "100%"
                }}
              >
                <div
                  className={classesWeb.root}
                  style={{
                    height: "72px",
                    flexWrap: "wrap",
                    flexShrink: 0,
                    flex: "100%"
                  }}
                >
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleWebDrawerOpen}
                    edge="start"
                    className={clsx(
                      classesWeb.menuButton,
                      openWeb && classesWeb.hide
                    )}
                    style={{ padding: "5px", marginLeft: "0px" }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <div
                    style={{
                      maxWidth: "171px",
                      display: "block",
                      margin: "auto 0px auto 25px"
                    }}
                  >
                    <Logo
                      style={{
                        maxHeight: "40px",
                        width: "auto"
                      }}
                      className={clsx(openWeb && classesWeb.hide)}
                    />
                  </div>
                  <div className={classes.Iconroot}>
                    <InputLabel className={classes.nameLabel}>
                      {"Hello, "}
                      {userInfo.user.name + "!"}
                    </InputLabel>
                    <Button
                      ref={anchorRef}
                      aria-controls={open ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleMenuToggle}
                      className={classes.avatarMenuButton}
                      style={{
                        padding: "0px",
                        marginLeft: "12px",
                        minWidth: "auto"
                      }}
                    >
                      <Avatar className={classes.large}>
                        {auth.getUserInfo()
                          ? getInitials(auth.getUserInfo().full_name)
                          : ""}
                      </Avatar>
                    </Button>

                    <Popper
                      open={openMenu}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                      className={classes.menu}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom"
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleMenuClose}>
                              <MenuList
                                autoFocusItem={openMenu}
                                id="menu-list-grow"
                                onKeyDown={handleListKeyDown}
                              >
                                <MenuItem
                                  onClick={() => {
                                    setMenuOpen(false);
                                    if (
                                      auth.getUserInfo().role.name ===
                                      process.env.REACT_APP_CLIENT_ROLE_NAME
                                    ) {
                                      history.push(CLIENTMYPROFILE);
                                    } else {
                                      history.push(VIEWPROFILE);
                                    }
                                  }}
                                >
                                  Profile
                                </MenuItem>
                                <MenuItem onClick={handleChangePasswordButton}>
                                  {" "}
                                  Change Password
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setMenuOpen(false);
                                    history.push(AUTHENTICATOR);
                                  }}
                                >
                                  2FA
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setMenuOpen(false);
                                    history.push(LOGOUT);
                                  }}
                                >
                                  Logout
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </div>
                {/* <div> */}
                {props.isTabLayout ? (
                  <Card
                    style={{
                      background: "#f4f8ff",
                      padding: 0,
                      margin: "0px"
                    }}
                    elevation={0}
                  >
                    <CardContent style={{ padding: 0 }}>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row"
                          }}
                        >
                          {props.isCreateMealsPage ? (
                            <IconButton
                              onClick={() => props.redirectToExchangesPage()}
                            >
                              <ChevronLeftIcon
                                style={{
                                  padding: "5px",
                                  border: "solid #DADADA 1px",
                                  borderRadius: "25px",
                                  margin: "0px 1.875rem 0px 10px",
                                  background: "#fff",
                                  marginTop: 0
                                }}
                              />
                            </IconButton>
                          ) : (
                            <div
                              style={{
                                padding: "5px",
                                margin: "0px 1.875rem 0px 10px",
                                marginTop: 0
                              }}
                            ></div>
                          )}

                          {props.NameComponent}
                        </div>

                        <div className={styles.headerNavWrap}>
                          <props.NavBar />
                          {urlParams.get("s") &&
                          !isNaN(parseInt(urlParams.get("s"))) ? (
                            <>
                              <Tooltip title="Personal notes about client">
                                <IconButton
                                  style={{
                                    marginBottom: "0px",
                                    padding: 0
                                  }}
                                  onClick={() => {
                                    let data = {
                                      viewOwnNotes: true,
                                      viewOtherNotes: false,
                                      private: true,
                                      userId: urlParams.get("c"),
                                      isBackAvailable: true,
                                      backPath: getbackPath(),
                                      backName: "Back To Schedule",
                                      customHeader: "Private Notes",
                                      NameComponent: props.NameComponent
                                        ? props.NameComponent.props
                                        : null
                                    };
                                    history.push(CLIENTMYNOTES, data);
                                  }}
                                >
                                  <img
                                    src={LockedNutriNotes}
                                    alt=""
                                    style={{
                                      padding:
                                        "0.3125rem 0.9375rem 0.625rem 0.3125rem",
                                      height: "2.375rem"
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Notes for client">
                                <IconButton
                                  style={{
                                    marginBottom: "0px",
                                    padding: 0
                                  }}
                                  onClick={() => {
                                    let data = {
                                      viewOwnNotes: true,
                                      viewOtherNotes: false,
                                      private: false,
                                      userId: urlParams.get("c"),
                                      franchiseId: auth.getUserInfo().franchise
                                        .id,
                                      isBackAvailable: true,
                                      backPath: getbackPath(),
                                      backName: "Back to schedule",
                                      customHeader: "Nutritionist's Notes",
                                      NameComponent: props.NameComponent
                                        ? props.NameComponent.props
                                        : null
                                    };
                                    history.push(CLIENTMYNOTES, data);
                                  }}
                                >
                                  <img
                                    src={NotesForClient}
                                    alt=""
                                    style={{
                                      padding:
                                        "0.3125rem 0.9375rem 0.625rem 0.3125rem",
                                      height: "2.375rem"
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Client's Notes">
                                <IconButton
                                  style={{
                                    marginBottom: "0px",
                                    padding: 0
                                  }}
                                  onClick={handleClientNotesToggle}
                                >
                                  <img
                                    src={ClientNotes}
                                    alt=""
                                    ref={anchorRefClientNotes}
                                    aria-controls={
                                      open ? "menu-list-grow" : undefined
                                    }
                                    aria-haspopup="true"
                                    style={{
                                      padding:
                                        "0.3125rem 0.9375rem 0.625rem 0.3125rem",
                                      height: "2.375rem"
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Popper
                                open={openClientNotes}
                                anchorEl={anchorRefClientNotes.current}
                                role={undefined}
                                transition
                                disablePortal
                              >
                                {({ TransitionProps, placement }) => (
                                  <Grow
                                    {...TransitionProps}
                                    style={{
                                      transformOrigin:
                                        placement === "bottom"
                                          ? "center top"
                                          : "center bottom"
                                    }}
                                  >
                                    <Paper>
                                      <ClickAwayListener
                                        onClickAway={handleCloseClientNotes}
                                      >
                                        <MenuList
                                          autoFocusItem={openClientNotes}
                                          id="menu-list-grow"
                                          onKeyDown={
                                            handleListKeyDownClientNotes
                                          }
                                        >
                                          <MenuItem
                                            onClick={() => {
                                              let data = {
                                                userId: urlParams.get("c"),
                                                franchiseId: auth.getUserInfo()
                                                  .franchise.id,
                                                isBackAvailable: true,
                                                backPath: getbackPath(),
                                                backName: "Back to schedule",
                                                customHeader:
                                                  "Client's Food Diary",
                                                NameComponent: props.NameComponent
                                                  ? props.NameComponent.props
                                                  : null
                                              };
                                              history.push(
                                                CLIENTFOODDIARY,
                                                data
                                              );
                                            }}
                                          >
                                            Client's Food Diary
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              let data = {
                                                viewOwnNotes: false,
                                                viewOtherNotes: true,
                                                private: false,
                                                userId: urlParams.get("c"),
                                                franchiseId: auth.getUserInfo()
                                                  .franchise.id,
                                                isBackAvailable: true,
                                                backPath: getbackPath(),
                                                backName: "Back to schedule",
                                                customHeader: "Client's Notes",
                                                NameComponent: props.NameComponent
                                                  ? props.NameComponent.props
                                                  : null
                                              };
                                              history.push(CLIENTMYNOTES, data);
                                            }}
                                          >
                                            Client Notes
                                          </MenuItem>
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Paper>
                                  </Grow>
                                )}
                              </Popper>

                              <Tooltip title="Give All Document">
                                <IconButton
                                  style={{
                                    marginBottom: "0px",
                                    padding: 0
                                  }}
                                  onClick={handleGiveAllToggle}
                                >
                                  <img
                                    src={AddReports}
                                    alt=""
                                    ref={anchorRefGiveAll}
                                    aria-controls={
                                      open ? "menu-list-grow" : undefined
                                    }
                                    aria-haspopup="true"
                                    style={{
                                      padding:
                                        "0.3125rem 0.9375rem 0.625rem 0.3125rem",
                                      height: "2.375rem"
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Popper
                                open={openGiveAll}
                                anchorEl={anchorRefGiveAll.current}
                                role={undefined}
                                transition
                                disablePortal
                              >
                                {({ TransitionProps, placement }) => (
                                  <Grow
                                    {...TransitionProps}
                                    style={{
                                      transformOrigin:
                                        placement === "bottom"
                                          ? "center top"
                                          : "center bottom"
                                    }}
                                  >
                                    <Paper>
                                      <ClickAwayListener
                                        onClickAway={handleCloseClientNotes}
                                      >
                                        <MenuList
                                          autoFocusItem={openGiveAll}
                                          id="menu-list-grow"
                                          onKeyDown={handleListKeyDownGiveAll}
                                        >
                                          <MenuItem
                                            onClick={
                                              handleDownloadGiveAllReport
                                            }
                                          >
                                            Give All Document
                                          </MenuItem>
                                          <MenuItem
                                            onClick={handleDownloadAllReports}
                                          >
                                            All Reports
                                          </MenuItem>
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Paper>
                                  </Grow>
                                )}
                              </Popper>
                            </>
                          ) : null}

                          {props.isLatest ? (
                            <DarkBlueButton
                              variant="contained"
                              title={`This will copy "Exchanges/Menu", "Supplements", "Execises" data  from previous schedule into this schedule`}
                              disabled={props.isActive === false}
                              onClick={() => {
                                setDialog(dialog => ({
                                  ...dialog,
                                  open: true,
                                  children: ` This will copy the data from the previous schedule's "Exchanges/Menu," "Supplements," and "Exercises" into this schedule, Are you sure you want to continue?`,
                                  title: "Continue all from previous schedule"
                                }));
                              }}
                            >
                              CT-ALL
                            </DarkBlueButton>
                          ) : null}

                          <DialogBox
                            skipCancel={false}
                            skipOk={false}
                            open={dialog.open}
                            title={dialog.title}
                            children={dialog.children}
                            handleOk={handleCTAll}
                            handleCancel={handleDialogCancel}
                            buttonCancel={"Cancel"}
                            buttonOk={"Ok"}
                          />

                          <DarkBlueButton
                            variant="contained"
                            style={{ marginLeft: 15 }}
                            disabled={props.isActive === false}
                            onClick={() => {
                              props.handleAddSchedule();
                            }}
                          >
                            New Schedule
                          </DarkBlueButton>
                          {urlParams.get("s") &&
                          !isNaN(parseInt(urlParams.get("s"))) ? (
                            <>
                              <DarkBlueButton
                                variant="contained"
                                style={{ marginLeft: "0.9375rem" }}
                                onClick={() => {
                                  handlePreviousSchedule();
                                }}
                              >
                                All Schedules
                              </DarkBlueButton>
                              <PreviousSchedule
                                OpenDialogueBox={previous}
                                closeDialogue={handleCloseDialgue}
                              />
                            </>
                          ) : null}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ) : null}
                {/* </div> */}
              </Toolbar>
            </ThemeProvider>
          </AppBar>
          <Drawer
            className={classesWeb.drawer}
            variant="persistent"
            anchor="left"
            open={openWeb}
            classes={{
              paper: classesWeb.drawerPaper
            }}
          >
            <div className={classesWeb.drawerHeader}>
              <IconButton
                onClick={handleWebDrawerClose}
                style={{
                  color: "#000000",
                  paddingLeft: "0px",
                  paddingRight: "3px"
                }}
              >
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
              <Logo style={{ maxWidth: "95%", maxHeight: "100px" }} />
            </div>
            <List style={{ marginTop: "90%" }}>
              <SideMenus />
            </List>
          </Drawer>
        </div>
      </Hidden>
      <Hidden lgUp>
        <div>
          <AppBar
            position="fixed"
            className={classesWeb.appBar}
            style={{ background: "#f4f8ff", boxShadow: "0px 3px 5px #e8eef7" }}
            elevation={0}
          >
            <ThemeProvider theme={toolBarTheme}>
              <Toolbar className={styles.mobToolbar}>
                <div className={classesWeb.root} style={{ height: "72px" }}>
                  <div
                    style={{
                      maxWidth: "178px",
                      display: "block",
                      margin: "auto 0px auto 0px"
                    }}
                  >
                    <Logo
                      style={{
                        maxHeight: "40px",
                        width: "auto"
                      }}
                    />
                  </div>
                  <div className={classes.mobIconroot}>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerToggle}
                      edge="start"
                      className={clsx(classes.menuButton, {
                        [classes.hide]: open
                      })}
                    >
                      <MenuIcon />
                    </IconButton>
                  </div>
                </div>
              </Toolbar>
            </ThemeProvider>
            <div>
              {props.isTabLayout ? (
                <Card
                  className={styles.tabHeaderCard}
                  style={{
                    background: "#f4f8ff",
                    padding: 0,
                    margin: "0"
                  }}
                  elevation={0}
                >
                  <CardContent style={{ padding: "0 25px" }}>
                    <div>
                      <div className={styles.tabUserInfoWrap}>
                        {props.isCreateMealsPage ? (
                          <IconButton
                            onClick={() => props.redirectToExchangesPage()}
                          >
                            <ChevronLeftIcon className={styles.tabLeftIcon} />
                          </IconButton>
                        ) : (
                          <div className={styles.tabLeftIconHidden}></div>
                        )}

                        {props.NameComponent}
                      </div>

                      <div className={styles.mobScheduleBtnWrap}>
                        {urlParams.get("s") &&
                        !isNaN(parseInt(urlParams.get("s"))) ? (
                          <>
                            <IconButton
                              style={{
                                marginBottom: "0px",
                                padding: 0
                              }}
                              onClick={() => {
                                let data = {
                                  viewOwnNotes: true,
                                  viewOtherNotes: false,
                                  private: true,
                                  userId: urlParams.get("c"),
                                  isBackAvailable: true,
                                  backPath: getbackPath(),
                                  backName: "Back To Schedule",
                                  customHeader: "Private Notes",
                                  NameComponent: props.NameComponent
                                    ? props.NameComponent.props
                                    : null
                                };
                                history.push(CLIENTMYNOTES, data);
                              }}
                            >
                              <img
                                src={LockedNutriNotes}
                                alt=""
                                style={{
                                  padding: "5px 15px 10px 5px",
                                  height: 20
                                }}
                              />
                            </IconButton>
                            <IconButton
                              style={{
                                marginBottom: "0px",
                                padding: 0
                              }}
                              onClick={() => {
                                let data = {
                                  viewOwnNotes: true,
                                  viewOtherNotes: false,
                                  private: false,
                                  userId: urlParams.get("c"),
                                  franchiseId: auth.getUserInfo().franchise.id,
                                  isBackAvailable: true,
                                  backPath: getbackPath(),
                                  backName: "Back to schedule",
                                  customHeader: "Nutritionist's Notes",
                                  NameComponent: props.NameComponent
                                    ? props.NameComponent.props
                                    : null
                                };
                                history.push(CLIENTMYNOTES, data);
                              }}
                            >
                              <img
                                src={NotesForClient}
                                alt=""
                                style={{
                                  padding: "5px 15px 10px 5px",
                                  height: 20
                                }}
                              />
                            </IconButton>

                            <IconButton
                              style={{
                                marginBottom: "0px",
                                padding: 0
                              }}
                              onClick={handleClientNotesToggle}
                            >
                              <img
                                src={ClientNotes}
                                alt=""
                                ref={anchorRefClientNotes}
                                aria-controls={
                                  open ? "menu-list-grow" : undefined
                                }
                                aria-haspopup="true"
                                style={{
                                  padding: "5px 15px 10px 5px",
                                  height: 20
                                }}
                              />
                            </IconButton>

                            <Popper
                              open={openClientNotes}
                              anchorEl={anchorRefClientNotes.current}
                              role={undefined}
                              transition
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{
                                    transformOrigin:
                                      placement === "bottom"
                                        ? "center top"
                                        : "center bottom"
                                  }}
                                >
                                  <Paper>
                                    <ClickAwayListener
                                      onClickAway={handleCloseClientNotes}
                                    >
                                      <MenuList
                                        autoFocusItem={openClientNotes}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDownClientNotes}
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            let data = {
                                              viewOwnNotes: false,
                                              viewOtherNotes: true,
                                              private: false,
                                              userId: urlParams.get("c"),
                                              franchiseId: auth.getUserInfo()
                                                .franchise.id,
                                              isBackAvailable: true,
                                              backPath: getbackPath(),
                                              backName: "Back to schedule",
                                              customHeader:
                                                "Client's Food Diary",
                                              NameComponent: props.NameComponent
                                                ? props.NameComponent.props
                                                : null
                                            };
                                            history.push(CLIENTFOODDIARY, data);
                                          }}
                                        >
                                          Client's Food Diary
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => {
                                            let data = {
                                              viewOwnNotes: false,
                                              viewOtherNotes: true,
                                              private: false,
                                              userId: urlParams.get("c"),
                                              franchiseId: auth.getUserInfo()
                                                .franchise.id,
                                              isBackAvailable: true,
                                              backPath: getbackPath(),
                                              backName: "Back to schedule",
                                              customHeader: "Client's Notes",
                                              NameComponent: props.NameComponent
                                                ? props.NameComponent.props
                                                : null
                                            };
                                            history.push(CLIENTMYNOTES, data);
                                          }}
                                        >
                                          Client's Notes
                                        </MenuItem>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>

                            <IconButton
                              style={{
                                marginBottom: "0px",
                                padding: 0
                              }}
                              onClick={handleGiveAllToggle}
                            >
                              <img
                                src={AddReports}
                                alt=""
                                ref={anchorRefGiveAll}
                                aria-controls={
                                  open ? "menu-list-grow" : undefined
                                }
                                aria-haspopup="true"
                                style={{
                                  padding: "5px 15px 10px 5px",
                                  height: 20
                                }}
                              />
                            </IconButton>
                            <IconButton
                              style={{
                                marginBottom: "0px",
                                padding: 0
                              }}
                              onClick={handleGiveAllToggle}
                            >
                              <img
                                src={AddReports}
                                alt=""
                                ref={anchorRefGiveAll}
                                aria-controls={
                                  open ? "menu-list-grow" : undefined
                                }
                                aria-haspopup="true"
                                style={{
                                  padding: "5px 15px 10px 5px",
                                  height: 20
                                }}
                              />
                            </IconButton>

                            <Popper
                              open={openGiveAll}
                              anchorEl={anchorRefGiveAll.current}
                              role={undefined}
                              transition
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{
                                    transformOrigin:
                                      placement === "bottom"
                                        ? "center top"
                                        : "center bottom"
                                  }}
                                >
                                  <Paper>
                                    <ClickAwayListener
                                      onClickAway={handleCloseGiveAll}
                                    >
                                      <MenuList
                                        autoFocusItem={openGiveAll}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDownGiveAll}
                                      >
                                        <MenuItem
                                          onClick={handleDownloadGiveAllReport}
                                        >
                                          Give All Document
                                        </MenuItem>
                                        <MenuItem
                                          onClick={handleDownloadAllReports}
                                        >
                                          All Reports
                                        </MenuItem>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          </>
                        ) : null}

                        {props.isLatest ? (
                          <DarkBlueButton
                            variant="contained"
                            title={`This will copy "Exchanges/Menu", "Supplements", "Execises" data  from previous schedule into this schedule`}
                            disabled={props.isActive === false}
                            onClick={() => {
                              setDialog(dialog => ({
                                ...dialog,
                                open: true,
                                children: ` This will copy the data from the previous schedule's "Exchanges/Menu," "Supplements," and "Exercises" into this schedule, Are you sure you want to continue?`,
                                title: "Continue all from previous schedule"
                              }));
                            }}
                          >
                            CT-ALL
                          </DarkBlueButton>
                        ) : null}
                        <DarkBlueButton
                          variant="contained"
                          disabled={props.isActive === false}
                          onClick={() => {
                            props.handleAddSchedule();
                          }}
                        >
                          New Schedule
                        </DarkBlueButton>
                        {urlParams.get("s") &&
                        !isNaN(parseInt(urlParams.get("s"))) ? (
                          <>
                            <DarkBlueButton
                              variant="contained"
                              style={{ marginLeft: 10 }}
                              onClick={() => {
                                handlePreviousSchedule();
                              }}
                            >
                              All Schedules
                            </DarkBlueButton>
                            <PreviousSchedule
                              OpenDialogueBox={previous}
                              closeDialogue={handleCloseDialgue}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ) : null}
            </div>
          </AppBar>
          <Drawer
            classes={{
              paper: classes.drawer
            }}
            anchor={theme.direction === "rtl" ? "right" : "left"}
            variant="temporary"
            open={open}
          >
            <div className={classesWeb.drawerHeader}>
              <IconButton
                onClick={handleDrawerToggle}
                style={{
                  color: "#000000",
                  paddingLeft: "0px",
                  paddingRight: "3px"
                }}
              >
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
              <Logo
                style={{
                  maxWidth: "80%",
                  maxHeight: "150px",
                  marginLeft: "5%"
                }}
              />
            </div>

            <List style={{ marginTop: "30%" }}>
              <SideMenus />
            </List>

            <List style={{ marginTop: "10%" }}>
              <LogoutMenu />
            </List>
          </Drawer>
        </div>
      </Hidden>

      <DialogBox
        skipCancel={false}
        skipOk={false}
        open={dialog.open}
        title={dialog.title}
        children={dialog.children}
        handleOk={handleCTAll}
        handleCancel={handleDialogCancel}
        buttonCancel={"Cancel"}
        buttonOk={"Continue"}
      />

      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default SideAndTopNavBar;
