import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery
} from "@material-ui/core";

import {
  SupplementGrid,
  Table,
  Input,
  SnackBar,
  WhiteIconButton,
  DarkBlueButton
} from "../../components";
import { useTheme } from "@material-ui/core/styles";
import {
  providerForGet,
  providerForPost,
  providerForDownload
} from "../../api";
import {
  addUpdateSupplementData,
  supplementForFranchise,
  getSupplementDataOfASchedule,
  getSupplementTemplateData,
  downloadSupplementsPdf,
  addUpdateSupplementTemplateData,
  getSupplementTemplateForFranchise
} from "../../constants";
import auth from "../../components/Auth";
import DialogBox from "../../components/DialogBox/DialogBox";
import addSupplementForm from "../SupplementTemplate/SupplementTemplateSchema";
import { get } from "lodash";
import {
  ERROR_MESSAGE,
  SAVE_MESSAGE,
  UPDATE_MESSAGE
} from "../../constants/genericConstants";
import {
  checkAllKeysPresent,
  checkEmpty,
  getListOfKeysNotPresent,
  setErrors
} from "../../utils";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Prompt } from "react-router-dom";
import PrintIcon from "@material-ui/icons/Print";

const SupplementSchedule = props => {
  const user = auth.getUserInfo();

  const tableRef = React.createRef();
  const columns = [
    { title: "Name", field: "name" },
    { title: "Description", field: "description" }
  ];
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    summaryButtonBox: {
      display: "flex",
      marginBottom: "3%"
    },
    loadTemplate: {
      marginLeft: "auto"
    },
    duplicateReplicateStyle: {
      display: "flex",
      marginLeft: "auto"
    },
    margin: {
      margin: theme.spacing(1)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    favouriteClass: {
      [theme.breakpoints.down("xs")]: {
        "& button": {
          marginLeft: "0px !important",
          display: "flex !important",
          "&:first-child": {
            marginBottom: "10px !important"
          }
        }
      }
    }
  }));

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  const suppDummyData = {
    id: null,
    morn: 0,
    pre: 0,
    post: 0,
    eve: 0,
    total: 0,
    PersonalNotes: ""
  };

  const [suppForm, setSuppForm] = useState({
    suppData: {
      1: suppDummyData /** This is the initial data */
    },
    scheduleId: null,
    clientId: null,
    errors: {},
    checked: {}
  });
  const supplementName = "supplementName";
  const suppTimeArr = ["morn", "pre", "post", "eve"];

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    isActive: props ? props.isActive : null,
    editData: false,
    editDataId: null,
    editDataName: null
  });

  const [statusVariable, setStatusVariable] = useState({
    isError: false,
    isOpenSnackBar: false,
    successErrorMessage: "",
    saveText: "",
    dummyTime: new Date(),
    savedTime: null,
    isSaving: false
  });
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [opensuppTemplateDialogBox, setOpensuppTemplateDialogBox] = useState(
    false
  );
  const [noOfSupplement] = useState(1);

  const [supplementData, setSupplementData] = useState([]);
  const [loader, setLoader] = useState(false);

  const getInitialSupplementData = async () => {
    setLoader(true);
    const urlParams = new URLSearchParams(window.location.search);
    setSuppForm(suppForm => ({
      ...suppForm,
      scheduleId: urlParams.get("s"),
      clientId: urlParams.get("c")
    }));
    await providerForGet(
      supplementForFranchise,
      {
        pageSize: -1,
        _sort: "name:asc"
      },
      auth.getToken()
    )
      .then(res => {
        setSupplementData(res.data);
      })
      .catch(error => {});

    await providerForPost(
      getSupplementDataOfASchedule,
      {
        s: urlParams.get("s"),
        c: urlParams.get("c")
      },
      auth.getToken(),
      {
        desc: "Access supplement data of a schedule",
        audit_log_clientid: urlParams.get("c")
      }
    )
      .then(res => {
        setLoader(false);
        let obj = {};
        let resObj = res.data;
        if (resObj.length) {
          resObj.map((data, i) => {
            obj[i + 1] = {
              id: data.supplement_id ? data.supplement_id.id : null,
              morn: data.Morn ? data.Morn : null,
              pre: data.Pre ? data.Pre : null,
              post: data.Post ? data.Post : null,
              eve: data.Eve ? data.Eve : null,
              total: data.Total ? data.Total : null,
              supp_notes: data.supplement_id
                ? data.supplement_id.description
                : null,
              PersonalNotes: data.personal_notes ? data.personal_notes : null
            };
            return null;
          });
        } else {
          obj = {
            1: suppDummyData /** This is the initial data */
          };
        }
        setSuppForm(suppForm => ({
          ...suppForm,
          suppData: obj
        }));
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getInitialSupplementData();
  }, []);

  const [filter, setFilter] = useState({
    _sort: "template_name:asc"
  });

  const localClasses = useStyles();

  const hasError = field => (formState.errors[field] ? true : false);

  const convertTableData = res => {
    let arr = [];
    if (res && res.length > 0) {
      for (let i in res) {
        let tempArr = {};
        tempArr["id"] = res[i].id;
        tempArr["name"] = res[i].template_name;
        tempArr["description"] = res[i].description;
        tempArr["franchise"] = res[i].franchise
          ? res[i].franchise.franchise_name
          : "";
        arr.push(tempArr);
      }
      return arr;
    } else {
      return [];
    }
  };

  const getSupplementData = async (page, pageSize) => {
    /**
     ** It sets the page and pageSize in filter variable
     ** so as to keep the record of what page and pageSize was set
     */
    let apiUrl = getSupplementTemplateForFranchise;

    setFilter(filter => ({
      ...filter,
      page: page,
      pageSize: pageSize
    }));
    let params = { page: page, pageSize: pageSize };
    Object.keys(filter).map(res => {
      if (!params.hasOwnProperty(res)) {
        params[res] = filter[res];
      }
      return null;
    });
    const urlParams = new URLSearchParams(window.location.search);
    params.desc = "Get supplement data";
    params.audit_log_clientid = urlParams.get("c");
    return new Promise((resolve, reject) => {
      fetch(apiUrl + "?" + new URLSearchParams(params), {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth.getToken()
        }
      })
        .then(response => response.json())
        .then(result => {
          resolve({
            data: convertTableData(result.data),
            page: result.page - 1,
            totalCount: result.totalCount
          });
        });
    });
  };

  const handleSupplementChange = (
    isAutoComplete,
    isTextBox,
    numbers,
    eventName,
    event,
    value,
    suppNumber
  ) => {
    if (isAutoComplete) {
      if (value) {
        if (suppForm.suppData[suppNumber]) {
          setSuppForm(suppForm => ({
            ...suppForm,
            suppData: {
              ...suppForm.suppData,
              [suppNumber]: {
                ...suppForm.suppData[suppNumber],
                id: value.id,
                supp_notes: value.description
              }
            }
          }));
        } else {
          setSuppForm(suppForm => ({
            ...suppForm,
            suppData: {
              ...suppForm.suppData,
              [suppNumber]: {
                ...suppForm.suppData[suppNumber],
                id: value.id,
                morn: 0,
                pre: 0,
                post: 0,
                eve: 0,
                total: 0,
                supp_notes: value.description,
                PersonalNotes: ""
              }
            }
          }));
        }
      } else {
        setSuppForm(suppForm => ({
          ...suppForm,
          suppData: {
            ...suppForm.suppData,
            [suppNumber]: {
              ...suppForm.suppData[suppNumber],
              id: null,
              morn: 0,
              pre: 0,
              post: 0,
              eve: 0,
              total: 0,
              supp_notes: "",
              PersonalNotes: ""
            }
          }
        }));
      }
    } else if (isTextBox) {
      setSuppForm(suppForm => ({
        ...suppForm,
        suppData: {
          ...suppForm.suppData,
          [suppNumber]: {
            ...suppForm.suppData[suppNumber],
            PersonalNotes: value
          }
        }
      }));
    } else if (numbers) {
      let total = 0;
      suppTimeArr.map(st => {
        if (st !== eventName) {
          total = total + suppForm.suppData[suppNumber][st];
        } else {
          if (parseInt(value)) {
            total = total + parseInt(value);
          } else {
            total = total + 0;
          }
        }
        return null;
      });
      if (parseInt(value)) {
        setSuppForm(suppForm => ({
          ...suppForm,
          suppData: {
            ...suppForm.suppData,
            [suppNumber]: {
              ...suppForm.suppData[suppNumber],
              [eventName]: parseInt(value),
              total: total
            }
          }
        }));
      } else {
        setSuppForm(suppForm => ({
          ...suppForm,
          suppData: {
            ...suppForm.suppData,
            [suppNumber]: {
              ...suppForm.suppData[suppNumber],
              [eventName]: 0
            }
          }
        }));
      }
    }
  };

  const handleChange = e => {
    e.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));
    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const checkIfDataProper = (isForDelete = false) => {
    let isValid = true;
    if (isForDelete) {
      let arr = Object.keys(suppForm.suppData);
      if (arr.length === 1 && !suppForm.suppData[arr[0]].id) {
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      let arr = Object.keys(suppForm.suppData);
      arr.map(i => {
        if (!suppForm.suppData[i].id) {
          isValid = false;
        }
        return null;
      });
    }
    return isValid;
  };

  const handleAddSupplement = () => {
    if (checkIfDataProper()) {
      let highest = Object.keys(suppForm.suppData)
        .sort(function (a, b) {
          return a - b;
        })
        .pop();
      setSuppForm(suppForm => ({
        ...suppForm,
        suppData: {
          ...suppForm.suppData,
          [parseInt(highest) + 1]: suppDummyData
        }
      }));
    }
  };

  const handleDeleteSupplement = event => {
    let arr = Object.keys(suppForm.suppData);
    if (arr.length === 1) {
      setSuppForm(suppForm => ({
        ...suppForm,
        suppData: {
          ...suppForm.suppData,
          [event]: {
            ...suppForm.suppData[event],
            id: null,
            morn: 0,
            pre: 0,
            post: 0,
            eve: 0,
            total: 0,
            supp_notes: "",
            PersonalNotes: ""
          }
        }
      }));
    } else {
      delete suppForm.suppData[event];

      setSuppForm(suppForm => ({
        ...suppForm
      }));
    }
  };

  const handleLoadFavorites = () => {
    setOpenDialogBox(true);
  };

  const savedata = async (flag = false) => {
    setStatusVariable(statusVariable => ({
      ...statusVariable,
      isSaving: true
    }));
    setLoader(true);

    await providerForPost(
      addUpdateSupplementData,
      { data: suppForm.suppData, s: suppForm.scheduleId, c: suppForm.clientId },
      auth.getToken(),
      {
        desc: "Add/Update Supplement Data for a schedule",
        audit_log_clientid: suppForm.clientId
      }
    )
      .then(res => {
        if (flag) {
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isSaving: false,
            savedTime: new Date(),
            isOpenSnackBar: true,
            isError: "success",
            successErrorMessage: "Supplement data " + SAVE_MESSAGE
          }));
        } else {
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isSaving: false,
            savedTime: new Date()
          }));
        }
        setLoader(false);
      })
      .catch(error => {
        if (flag) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              isSaving: false,
              isOpenSnackBar: true,
              isError: "error",
              successErrorMessage: ERROR_MESSAGE + error.response.data.message
            }));
          } else {
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              isSaving: false,
              isOpenSnackBar: true,
              isError: "error",
              successErrorMessage: ERROR_MESSAGE + error
            }));
          }
        } else {
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isSaving: false
          }));
        }
        setLoader(false);
      });
  };

  const downloadPdf = async isPrint => {
    setLoader(true);
    await savedata();
    const urlParams = new URLSearchParams(window.location.search);
    let finalData = {
      s: urlParams.get("s"),
      c: urlParams.get("c")
    };
    await providerForDownload(
      downloadSupplementsPdf,
      finalData,
      auth.getToken(),
      {
        desc: "Download Supplements Schedule",
        audit_log_clientid: urlParams.get("c")
      }
    )
      .then(res => {
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          const pdfNewWindow = window.open();
          pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Supplements.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };
  const handleSaveFavorites = () => {
    if (suppForm.suppData[1].id) {
      setOpensuppTemplateDialogBox(true);
    } else {
      setStatusVariable(statusVariable => ({
        ...statusVariable,
        isOpenSnackBar: true,
        isError: "error",
        successErrorMessage: "No Supplement Selected "
      }));
    }
  };

  const handleLoadTemplate = rowData => {
    let tempId = {
      id: rowData.id,
      schedule_id: suppForm.scheduleId,
      desc: "Get supplement favorite data for editing",
      audit_log_clientid: suppForm.clientId
    };
    setLoader(true);
    providerForGet(getSupplementTemplateData, tempId, auth.getToken())
      .then(res => {
        let obj = {};
        let resObj = res.data;
        resObj.map((data, i) => {
          obj[i + 1] = {
            id: data.supplement_id ? data.supplement_id.id : null,
            morn: data.Morn ? data.Morn : 0,
            pre: data.Pre ? data.Pre : 0,
            post: data.Post ? data.Post : 0,
            eve: data.Eve ? data.Eve : 0,
            total: data.Total ? data.Total : 0,
            supp_notes: data.supplement_id
              ? data.supplement_id.description
              : null,
            PersonalNotes: data.personal_notes ? data.personal_notes : null
          };
          return null;
        });
        setSuppForm(suppForm => ({
          ...suppForm,
          suppData: obj
        }));
        setLoader(false);
        setOpenDialogBox(false);
        setFormState(formState => ({
          ...formState,
          editData: res.data
            ? res.data[0].supplement_template_id
              ? true
              : false
            : false,
          editDataId: res.data
            ? res.data[0].supplement_template_id
              ? res.data[0].supplement_template_id.id
              : null
            : null,
          editDataName: res.data
            ? res.data[0].supplement_template_id
              ? res.data[0].supplement_template_id.template_name
              : null
            : null
        }));
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });
  };

  const closeDialogBox = () => {
    setOpenDialogBox(false);
    setOpensuppTemplateDialogBox(false);
    delete formState.values["supplementName"];
    delete formState.values["supplementDescription"];
  };

  const saveSupplementTemplate = async id => {
    setStatusVariable(statusVariable => ({
      ...statusVariable,
      isSaving: true
    }));
    setLoader(true);
    await providerForPost(
      addUpdateSupplementTemplateData,
      { data: suppForm.suppData, st: id },
      auth.getToken(),
      {
        desc: "Add/Update Supplement Data for a schedule",
        audit_log_clientid: suppForm.clientId
      }
    )
      .then(res => {
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          isSaving: false,
          savedTime: new Date(),
          isOpenSnackBar: true,
          isError: "success",
          successErrorMessage:
            "Supplement Favorite " + res.data.template_name + UPDATE_MESSAGE
        }));

        closeDialogBox();
        setLoader(false);
      })
      .catch(error => {
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          isSaving: false,
          isOpenSnackBar: true,
          isError: "error",
          successErrorMessage: ERROR_MESSAGE + error
        }));
        setLoader(false);
      });
  };

  const handleUpdateTemplate = () => {
    saveSupplementTemplate(formState.editDataId);
    closeDialogBox();
  };

  const handleCreateNewTemplate = () => {
    setFormState(formState => ({
      ...formState,
      editData: false,
      editDataId: null,
      editDataName: null
    }));
  };

  const handleOk = async () => {
    setLoader(true);
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      addSupplementForm
    );
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, addSupplementForm);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        addSupplementForm
      );
      formState.errors = setErrors(formState.values, addSupplementForm);
    }
    if (isValid) {
      /**api call from here */

      if (formState.editData === true) {
        /**Put api call (Update method)*/
        setLoader(true);
        await providerForPost(
          addUpdateSupplementTemplateData,
          { data: suppForm.suppData, st: formState.editDataId },
          auth.getToken(),
          {
            desc: "Add/Update Supplement template Data ",
            audit_log_clientid: suppForm.clientId
          }
        )
          .then(res => {
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              isOpenSnackBar: true,
              isError: "success",
              successErrorMessage:
                "Supplement Favorite " + res.data.template_name + UPDATE_MESSAGE
            }));

            setLoader(false);
            closeDialogBox();
          })
          .catch(error => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              setStatusVariable(statusVariable => ({
                ...statusVariable,
                isSaving: false,
                isOpenSnackBar: true,
                isError: "error",
                successErrorMessage: ERROR_MESSAGE + error.response.data.message
              }));
            } else {
              setStatusVariable(statusVariable => ({
                ...statusVariable,
                isSaving: false,
                isOpenSnackBar: true,
                isError: "error",
                successErrorMessage: ERROR_MESSAGE + error
              }));
            }
            setLoader(false);
          });
      } else {
        /**Post api call (Save method)*/

        setLoader(true);
        await providerForPost(
          addUpdateSupplementTemplateData,
          {
            data: suppForm.suppData,
            st: null,
            templateName: formState.values.supplementName,
            franchiseId: user.franchise ? user.franchise.id : null
          },
          auth.getToken(),
          {
            desc: "Add/Update Supplement template Data ",
            audit_log_clientid: suppForm.clientId
          }
        )
          .then(res => {
            setStatusVariable(statusVariable => ({
              ...statusVariable,

              isOpenSnackBar: true,
              isError: "success",
              successErrorMessage:
                "Supplement Favorite " + res.data.template_name + SAVE_MESSAGE
            }));

            setLoader(false);
            setFormState(formState => ({
              ...formState,
              editData: true,
              editDataId: res.data.id,
              editDataName: res.data.template_name
            }));
            closeDialogBox();
          })
          .catch(error => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              setStatusVariable(statusVariable => ({
                ...statusVariable,
                isSaving: false,
                isOpenSnackBar: true,
                isError: "error",
                successErrorMessage: ERROR_MESSAGE + error.response.data.message
              }));
            } else {
              setStatusVariable(statusVariable => ({
                ...statusVariable,
                isSaving: false,
                isOpenSnackBar: true,
                isError: "error",
                successErrorMessage: ERROR_MESSAGE + error
              }));
            }
            setLoader(false);
          });
      }
    }
  };

  const handleSnackbarClose = () => {
    setStatusVariable(statusVariable => ({
      ...statusVariable,
      isOpenSnackBar: false,
      isError: "",
      successErrorMessage: ""
    }));
  };

  return (
    <>
      <Prompt
        message={(location, action) =>
          props && props.isButtonClicked ? null : savedata()
        }
      />
      <Grid container className={localClasses.root} spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid
              item
              xs={12}
              md={7}
              lg={7}
              className={localClasses.favouriteClass}
            >
              <WhiteIconButton
                style={{
                  //marginTop: theme.spacing(3),
                  marginRight: theme.spacing(1)
                  //marginLeft: theme.spacing(1)
                }}
                disabled={formState.isActive === false}
                onClick={() => handleLoadFavorites()}
              >
                LOAD FAVORITES
              </WhiteIconButton>
              <WhiteIconButton
                style={{
                  marginRight: theme.spacing(1),
                  marginLeft: theme.spacing(1)
                }}
                disabled={formState.isActive === false}
                onClick={() => handleSaveFavorites()}
              >
                SAVE FAVORITES
              </WhiteIconButton>
            </Grid>
            <Grid
              className={localClasses.duplicateReplicateStyle}
              xs={12}
              md={5}
              lg={5}
              container
              item
              justify="flex-end"
              alignItems="center"
              // style={{ paddingBottom: "15px" }}
            >
              <IconButton
                onClick={() => downloadPdf(true)}
                style={{ marginRight: 15, padding: 0, background: "none" }}
              >
                <Tooltip title="Print Report">
                  <PrintIcon style={{ color: "#1C4979", fontSize: 30 }} />
                </Tooltip>
              </IconButton>

              <IconButton
                variant="contained"
                size="small"
                onClick={() => downloadPdf(false)}
              >
                <Tooltip title="Download Report">
                  <GetAppIcon style={{ color: "#1C4979", fontSize: 30 }} />
                </Tooltip>
              </IconButton>
              <DarkBlueButton
                variant="contained"
                size="small"
                style={{
                  margin: "8px 0 8px 5px"
                }}
                disabled={formState.isActive === false}
                onClick={() => savedata(true)}
              >
                SAVE
              </DarkBlueButton>
            </Grid>

            <DialogBox
              open={openDialogBox}
              title="Select Supplement Favorite"
              buttonCancel="Cancel"
              handleCancel={closeDialogBox}
              skipOk={true}
            >
              <Table
                tableRef={tableRef}
                columns={columns}
                data={async query => {
                  return await getSupplementData(
                    query.page + 1,
                    query.pageSize
                  );
                }}
                actions={[
                  {
                    icon: () => <DarkBlueButton>Use</DarkBlueButton>,
                    tooltip: "Use this Favorite",
                    onClick: (event, rowData) => {
                      handleLoadTemplate(rowData);
                    }
                  }
                ]}
                options={{
                  actionsColumnIndex: -1,
                  search: false,
                  sorting: false,
                  pageSize: 5,
                  thirdSortClick: false,
                  headerStyle: {
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    color: "#8A8A97",
                    borderBottom: "solid #E0E0E0 2px",
                    fontSize: "1rem"
                  },
                  cellStyle: {
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    color: "#000000",
                    fontSize: "1rem"
                  },
                  paginationStyle: {
                    justifyContent: "center"
                  }
                }}
              />
            </DialogBox>
            <DialogBox
              open={opensuppTemplateDialogBox}
              title="Save Supplement Favorite"
              buttonCancel="Cancel"
              handleCancel={closeDialogBox}
              skipOk={formState.editData ? true : false}
              buttonOk={formState.editData ? null : "Ok"}
              handleOk={formState.editData ? null : handleOk}
            >
              {formState.editData ? (
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Typography gutterBottom>
                      Update
                      {" " + formState.editDataName + " "}supplement Favorite?
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <DarkBlueButton
                      variant={"contained"}
                      onClick={handleUpdateTemplate}
                    >
                      Update
                    </DarkBlueButton>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DarkBlueButton
                      variant={"contained"}
                      onClick={handleCreateNewTemplate}
                    >
                      Create new
                    </DarkBlueButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Input
                      label={get(addSupplementForm[supplementName], "label")}
                      name={supplementName}
                      value={formState.values[supplementName] || ""}
                      error={hasError(supplementName)}
                      placeholder={get(
                        addSupplementForm[supplementName],
                        "placeholder"
                      )}
                      variant="outlined"
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(supplementName)
                          ? formState.errors[supplementName].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                    />
                  </Grid>
                </Grid>
              )}
            </DialogBox>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ marginTop: "0px" }}>
          <Grid container spacing={2}>
            <Grid item xs="12">
              <SupplementGrid
                isDesktop={isDesktop}
                noOfSupplement={noOfSupplement}
                supplementData={supplementData}
                handleSupplementChange={handleSupplementChange}
                handleAddSupplement={handleAddSupplement}
                checkIfDataProper={checkIfDataProper}
                handleDeleteSupplement={handleDeleteSupplement}
                suppData={suppForm.suppData}
                isActive={formState.isActive}
              />
              <Backdrop className={localClasses.backdrop} open={loader}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <SnackBar
            open={statusVariable.isOpenSnackBar}
            severity={statusVariable.isError}
            onClose={handleSnackbarClose}
          >
            {statusVariable.successErrorMessage}
          </SnackBar>
        </Grid>
      </Grid>
    </>
  );
};

export default SupplementSchedule;
