import React from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

function CameraPhoto(props) {
  return (
    <Camera
      onTakePhoto={dataUri => {
        props.handleTakePhoto(dataUri);
      }}
      onCameraError={error => {
        props.handleCameraError(error);
      }}
      idealFacingMode={FACING_MODES.ENVIRONMENT}
      idealResolution={{ width: 640, height: 480 }}
      imageType={IMAGE_TYPES.PNG}
      imageCompression={0.6}
      isMaxResolution={true}
      isImageMirror={false}
      isSilentMode={false}
      isDisplayStartCameraError={true}
      sizeFactor={1}
      onCameraStart={stream => {
        props.handleCameraStart(stream);
      }}
      onCameraStop={() => {
        props.handleCameraStop();
      }}
    />
  );
}

export default CameraPhoto;
