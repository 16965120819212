import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Grid,
  makeStyles,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import {
  useWindowDimensions,
  AutoCompleteDropDown,
  ExerciseGrid,
  Input,
  SnackBar,
  DarkBlueButton,
  BorderLessButton,
  HeadingOne,
  SelectDropdown,
} from "../../components";
import { useTheme } from "@material-ui/core/styles";
import { providerForGet, providerForPost } from "../../api";
import {
  muscleGroup,
  exerciseForFranchise,
  addUpdateExerciseTemplate,
  getTemplateDataForWorkout,
  getWorkoutTemplateForFranchise,
} from "../../constants";
import auth from "../../components/Auth";
import addExerciseForm from "./ExerciseTemplateSchema";
import {
  checkAllKeysPresent,
  checkEmpty,
  getListOfKeysNotPresent,
  setErrors,
} from "../../utils";
import { get } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  ERROR_MESSAGE,
  SAVE_MESSAGE,
  UPDATE_MESSAGE,
} from "../../constants/genericConstants";
import { EXERCISETEMPLATE } from "../../paths";
import { Prompt } from "react-router-dom";

const AddEditExerciseTemplate = (props) => {
  let propData = props["location"]["state"];
  const history = useHistory();
  const user = auth.getUserInfo();
  const { width } = useWindowDimensions();

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    summaryButtonBox: {
      display: "flex",
      marginBottom: "3%",
    },
    loadTemplate: {
      marginLeft: "auto",
    },
    favoritesOption: {
      display: "flex",
      marginRight: "auto",
    },
    margin: {
      margin: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff",
    },
    formControl: {
      width: "100% !important",
      marginRight: theme.spacing(1),
      minWidth: 180,
      "& label": {
        fontSize: "0.9375rem",
        fontFamily: "Montserrat !important",
        fontWeight: "500",
      },
      "& input": {
        fontSize: "0.9375rem",
        fontFamily: "Montserrat !important",
        fontWeight: "500",
      },
      "& > div": {
        fontSize: "0.9375rem",
        fontFamily: "Montserrat !important",
        fontWeight: "500",
      },
    },
    workoutFormControl: {
      width: "100% !important",
      marginRight: theme.spacing(1),
      minWidth: 180,
      "& label": {
        fontSize: "0.9375rem",
        fontFamily: "Montserrat !important",
        fontWeight: "500",
      },
      "& input": {
        fontSize: "0.9375rem",
        fontFamily: "Montserrat !important",
        fontWeight: "500",
      },
      "& > div": {
        fontSize: "0.9375rem",
        fontFamily: "Montserrat !important",
        fontWeight: "500",
      },
      "& legend": {
        minWidth: "100px",
      },
    },
    cancelSaveButton: {
      marginLeft: "auto",
    },
    duplicateReplicateStyle: {
      display: "flex",
    },
  }));
  const localClasses = useStyles();
  const theme = useTheme();
  const [offId, setOffId] = useState([]);

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    franchiseList: [],
    alert: false,
    severity: "success",
    errorMessage: "",
    /** This is when we return from edit page */
    rowData: propData ? propData : [],
    editData: propData ? (propData["id"] ? true : false) : false,
    editDataId: propData ? propData["id"] : null,
  });
  const exerciseName = "exerciseName";

  const [muscleGroups, setMuscleGroups] = useState([]);
  const [exerciseData, setExerciseData] = useState([]);

  const exerciseDummyData = {
    id: null,
    exerciseId: null,
    setReps: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
    },
  };

  const [exerciseForm, setExerciseForm] = useState({
    workoutDay: 1,
    muscleGroupId: null,
    exerciseList: {
      1: exerciseDummyData /** This is the initial data */,
    },
  });

  const [workoutDayList] = useState(Array.from({ length: 7 }, (_, i) => i + 1));
  const [loader, setLoader] = useState(false);
  const hasError = (field) => (formState.errors[field] ? true : false);

  const addDefaultData = () => {
    setExerciseForm((exerciseForm) => ({
      ...exerciseForm,
      exerciseList: {
        1: exerciseDummyData /** This is the initial data */,
      },
    }));
  };

  const getExerciseInfo = async (workoutDay = exerciseForm.workoutDay) => {
    setLoader(true);
    if (formState.editData === true) {
      await providerForGet(
        getTemplateDataForWorkout,
        {
          workoutTemplateId: formState.editDataId,
          workoutDay: exerciseForm.workoutDay,
        },
        auth.getToken()
      )
        .then((res) => {
          if (res.data.isPresent) {
            setExerciseForm((exerciseForm) => ({
              ...exerciseForm,
              muscleGroupId: res.data.muscleGroupId,
              exerciseList: res.data.exerciseList,
            }));
          } else {
            if (res.data.muscleGroupId) {
              setExerciseForm((exerciseForm) => ({
                ...exerciseForm,
                muscleGroupId: res.data.muscleGroupId,
              }));
            } else {
              setExerciseForm((exerciseForm) => ({
                ...exerciseForm,
                muscleGroupId: offId,
              }));
            }
            addDefaultData();
          }
          setLoader(false);
        })
        .catch((err) => {
          addDefaultData();
          setLoader(false);
        });
    } else {
      addDefaultData();
      setLoader(false);
    }
  };

  useEffect(() => {
    getExerciseInfo(exerciseForm.workoutDay);
  }, [exerciseForm.workoutDay]);

  const getInitialExerciseData = async () => {
    if (formState.editData === true) {
      let editId = {
        id: formState.editDataId,
        desc: "Get exercise favorite name",
      };
      await providerForGet(
        getWorkoutTemplateForFranchise,
        editId,
        auth.getToken()
      )
        .then((res) => {
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              exerciseName: res.data.data
                ? res.data.data[0].template_name
                : null,
            },
          }));
          setLoader(false);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
    setLoader(true);

    await providerForGet(muscleGroup, { pageSize: -1 }, auth.getToken())
      .then((res) => {
        let muscleId = null;
        res.data.data.map((r) => {
          if (r.name === "Off") {
            setOffId(r.id);
            muscleId = r.id;
          }
          return null;
        });
        setMuscleGroups(res.data.data);
        if (res.data.data.length) {
          setExerciseForm((exerciseForm) => ({
            ...exerciseForm,
            muscleGroupId: muscleId,
          }));
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    await providerForGet(
      exerciseForFranchise,
      { pageSize: -1 },
      auth.getToken()
    )
      .then((res) => {
        setExerciseData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
    getExerciseInfo();
  };

  useEffect(() => {
    getInitialExerciseData();
  }, []);

  const handleExerciseChange = (
    isAutoComplete,
    isTextBox,
    numbers,
    eventName,
    event,
    value,
    exerciseNo
  ) => {
    if (isAutoComplete) {
      if (value) {
        if (exerciseForm.exerciseList[exerciseNo]) {
          setExerciseForm((exerciseForm) => ({
            ...exerciseForm,
            exerciseList: {
              ...exerciseForm.exerciseList,
              [exerciseNo]: {
                ...exerciseForm.exerciseList[exerciseNo],
                exerciseId: value.id,
              },
            },
          }));
        } else {
          setExerciseForm((exerciseForm) => ({
            ...exerciseForm,
            exerciseList: {
              ...exerciseForm.exerciseList,
              [exerciseNo]: {
                ...exerciseForm.exerciseList[exerciseNo],
                id: null,
                exerciseId: value.id,
                setReps: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                },
              },
            },
          }));
        }
      } else {
        setExerciseForm((exerciseForm) => ({
          ...exerciseForm,
          exerciseList: {
            ...exerciseForm.exerciseList,
            [exerciseNo]: {
              ...exerciseForm.exerciseList[exerciseNo],
              id: null,
              exerciseId: null,
              setReps: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
              },
            },
          },
        }));
      }
    } else if (isTextBox) {
      setExerciseForm((exerciseForm) => ({
        ...exerciseForm,
        exerciseList: {
          ...exerciseForm.exerciseList,
          [exerciseNo]: {
            ...exerciseForm.exerciseList[exerciseNo],
          },
        },
      }));
    } else if (numbers) {
      if (parseInt(value)) {
        setExerciseForm((exerciseForm) => ({
          ...exerciseForm,
          exerciseList: {
            ...exerciseForm.exerciseList,
            [exerciseNo]: {
              ...exerciseForm.exerciseList[exerciseNo],
              setReps: {
                ...exerciseForm.exerciseList[exerciseNo].setReps,
                [eventName]: parseInt(value),
              },
            },
          },
        }));
      } else {
        setExerciseForm((exerciseForm) => ({
          ...exerciseForm,
          exerciseList: {
            ...exerciseForm.exerciseList,
            [exerciseNo]: {
              ...exerciseForm.exerciseList[exerciseNo],
              setReps: {
                ...exerciseForm.exerciseList[exerciseNo].setReps,
                [eventName]: 0,
              },
            },
          },
        }));
      }
    }
  };

  const checkIfDataProper = (isForDelete = false) => {
    let isValid = true;
    if (isForDelete) {
      let arr = Object.keys(exerciseForm.exerciseList);
      if (arr.length === 1 && !exerciseForm.exerciseList[arr[0]].exerciseId) {
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      let arr = Object.keys(exerciseForm.exerciseList);
      arr.map((i) => {
        if (!exerciseForm.exerciseList[i].exerciseId) {
          isValid = false;
        }
        return null;
      });
    }
    return isValid;
  };

  const handleAddExercise = () => {
    if (checkIfDataProper()) {
      let highest = Object.keys(exerciseForm.exerciseList)
        .sort(function (a, b) {
          return a - b;
        })
        .pop();
      setExerciseForm((exerciseForm) => ({
        ...exerciseForm,
        exerciseList: {
          ...exerciseForm.exerciseList,
          [parseInt(highest) + 1]: exerciseDummyData,
        },
      }));
    }
  };

  const handelDeleteExercise = (event) => {
    let arr = Object.keys(exerciseForm.exerciseList);
    if (arr.length === 1) {
      setExerciseForm((exerciseForm) => ({
        ...exerciseForm,
        exerciseList: {
          ...exerciseForm.exerciseList,
          [event]: {
            ...exerciseForm.exerciseList[event],
            id: null,
            exerciseId: null,
            setReps: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
              9: 0,
            },
          },
        },
      }));
    } else {
      delete exerciseForm.exerciseList[event];
      setExerciseForm((exerciseForm) => ({
        ...exerciseForm,
      }));
    }
  };

  const handleSave = async (flag = false) => {
    setLoader(true);
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      addExerciseForm
    );
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, addExerciseForm);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        addExerciseForm
      );
      formState.errors = setErrors(formState.values, addExerciseForm);
    }
    if (isValid) {
      /**api call from here */

      if (formState.editData === true) {
        /**Put api call (Update method)*/
        await providerForPost(
          addUpdateExerciseTemplate,
          {
            workoutTemplateId: formState.editDataId,
            workoutDay: exerciseForm.workoutDay,
            exerciseData: exerciseForm.exerciseList,
            muscle_group: exerciseForm.muscleGroupId,
            template_name: formState.values.exerciseName,
            franchiseId: user.franchise ? user.franchise.id : null,
          },
          auth.getToken(),
          { desc: "Update Exercise Template" }
        )
          .then((res) => {
            if (flag) {
              setFormState((formState) => ({
                ...formState,
                alert: true,
                severity: "success",
                errorMessage:
                  "Exercise Favorite " +
                  res.data.template_name +
                  UPDATE_MESSAGE,
              }));
            }
            setLoader(false);
          })
          .catch((error) => {
            console.log("EDITERROR", error);
            setLoader(false);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              setFormState((formState) => ({
                ...formState,
                alert: true,
                errorMessage: ERROR_MESSAGE + error.response.data.message,
                severity: "error",
              }));
            }
          });
      } else {
        /**Post api call (Save method)*/

        await providerForPost(
          addUpdateExerciseTemplate,
          {
            workoutTemplateId: null,
            workoutDay: exerciseForm.workoutDay,
            exerciseData: exerciseForm.exerciseList,
            muscle_group: exerciseForm.muscleGroupId,
            template_name: formState.values.exerciseName,
            franchiseId: user.franchise ? user.franchise.id : null,
          },
          auth.getToken(),
          { desc: "Add Exercise Template" }
        )
          .then((res) => {
            if (flag) {
              setFormState((formState) => ({
                ...formState,
                alert: true,
                severity: "success",
                errorMessage:
                  "Exercise Favorite " + res.data.template_name + SAVE_MESSAGE,
              }));
            }
            setLoader(false);
            setFormState((formState) => ({
              ...formState,
              editData: true,
              editDataId: res.data.id,
            }));
          })
          .catch((error) => {
            console.log("error***", error);
            setLoader(false);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              setFormState((formState) => ({
                ...formState,
                alert: true,
                errorMessage: ERROR_MESSAGE + error.response.data.message,
                severity: "error",
              }));
            }
          });
      }
    } else {
      setFormState((formState) => ({
        ...formState,
        isValid: false,
      }));
      setLoader(false);
    }
  };

  const handleChangeInput = (e) => {
    e.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value,
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const handleChange = (event) => {
    setLoader(true);
    handleSave();

    setExerciseForm((exerciseForm) => ({
      ...exerciseForm,
      workoutDay: event.target.value,
    }));
  };

  const handleChangeMuscleGroup = (event, value) => {
    setExerciseForm((exerciseForm) => ({
      ...exerciseForm,
      muscleGroupId: value ? value.id : offId,
    }));
  };

  const handleCancel = () => {
    history.push(EXERCISETEMPLATE);
  };

  const handleSnackbarClose = () => {
    setFormState((formState) => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "success",
    }));
  };

  return (
    <>
      <Prompt
        message={(location, action) => {
          handleSave();
        }}
      />

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {formState.editData ? (
            <HeadingOne>Edit Exercise Favorite</HeadingOne>
          ) : (
            <HeadingOne>Add Exercise Favorite</HeadingOne>
          )}
        </Grid>
      </Grid>
      <Grid container className={localClasses.root} spacing={2}>
        <Grid
          item
          className={localClasses.favoritesOption}
          xs={12}
          md={12}
          lg={12}
        >
          <Grid>
            <SnackBar
              open={formState.alert}
              severity={formState.severity}
              onClose={handleSnackbarClose}
            >
              {formState.errorMessage}
            </SnackBar>
          </Grid>

          <div className={localClasses.cancelSaveButton}>
            <BorderLessButton
              variant="contained"
              style={{
                marginRight: 15,
              }}
              onClick={handleCancel}
              cgut
            >
              Cancel
            </BorderLessButton>
            <DarkBlueButton
              variant="contained"
              onClick={() => handleSave(true)}
            >
              Save
            </DarkBlueButton>
          </div>
        </Grid>
        <Grid item sm={12}>
          <Grid
            container
            className={localClasses.duplicateReplicateStyle}
            spacing={3}
          >
            <Grid item sm={4}>
              <Input
                label={get(addExerciseForm[exerciseName], "label")}
                name={exerciseName}
                value={formState.values[exerciseName] || ""}
                error={hasError(exerciseName)}
                placeholder={get(addExerciseForm[exerciseName], "placeholder")}
                variant="outlined"
                required
                onChange={handleChangeInput}
                helperText={
                  hasError(exerciseName)
                    ? formState.errors[exerciseName].map((error) => {
                        return error + " ";
                      })
                    : null
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            className={localClasses.duplicateReplicateStyle}
            spacing={3}
          >
            <Grid item sm={3}>
              <AutoCompleteDropDown
                className={localClasses.formControl}
                id="Muscle Group"
                options={muscleGroups ? muscleGroups : []}
                getOptionLabel={(option) => option.name}
                style={{ width: width / 7 }}
                value={
                  muscleGroups[
                    muscleGroups.findIndex(function (item, i) {
                      return item.id === exerciseForm.muscleGroupId;
                    })
                  ] || null
                }
                onChange={(event, value) => {
                  handleChangeMuscleGroup(event, value);
                }}
                renderInput={(params) => (
                  <Input {...params} label="Muscle Group" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item sm={3}>
              <SelectDropdown
                title="Workout for day"
                value={exerciseForm.workoutDay}
                onChange={handleChange}
              >
                {workoutDayList.map((day) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </SelectDropdown>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              className={localClasses.workoutDayGrid}
            >
              <ExerciseGrid
              //for favorites section isActive tag is needed to edit exercise section
                isActive={true}
                isDesktop={isDesktop}
                exerciseData={exerciseData}
                exerciseList={exerciseForm.exerciseList}
                handleExerciseChange={handleExerciseChange}
                handleAddExercise={handleAddExercise}
                handelDeleteExercise={handelDeleteExercise}
                checkIfDataProper={checkIfDataProper}
              />
              <Backdrop className={localClasses.backdrop} open={loader}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddEditExerciseTemplate;
