// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Measurements_measurementTable__-Zp_t tr td:first-child {\n  border-top-left-radius: 10px;\n  border-bottom-left-radius: 10px;\n}\n.Measurements_measurementTable__-Zp_t tr td:last-child {\n  border-top-right-radius: 10px;\n  border-bottom-right-radius: 10px;\n}\n@media only screen and (min-width: 1280px) {\n  .Measurements_backIcon__FI6JT {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/containers/Measurements/Measurements.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,+BAA+B;AACjC;AACA;EACE,6BAA6B;EAC7B,gCAAgC;AAClC;AACA;EACE;IACE,6BAA6B;IAC7B,6BAA6B;EAC/B;AACF","sourcesContent":[".measurementTable tr td:first-child {\n  border-top-left-radius: 10px;\n  border-bottom-left-radius: 10px;\n}\n.measurementTable tr td:last-child {\n  border-top-right-radius: 10px;\n  border-bottom-right-radius: 10px;\n}\n@media only screen and (min-width: 1280px) {\n  .backIcon {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"measurementTable": "Measurements_measurementTable__-Zp_t",
	"backIcon": "Measurements_backIcon__FI6JT"
};
export default ___CSS_LOADER_EXPORT___;
