import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const localStyles = makeStyles((theme) => ({
  titleText: {
    color: "#110F48",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "1.25rem",
    marginBottom: "0.9375rem",
    marginTop: 11,
  },
}));

export default function HeadingTwo(props) {
  const classes = localStyles();

  return (
    <Typography className={classes.titleText} gutterBottom {...props}>
      {props.children}
    </Typography>
  );
}
