import React, { useState } from "react";
import styles from "./FranchiseSites.module.css";
import { CardContent, Grid, Typography } from "@material-ui/core";
import {
  SnackBar,
  Table,
  DarkBlueButton,
  BorderLessButton,
  AutoCompleteDropDown
} from "../../components";
import {
  checkIfFranchiseSiteIsUsed,
  franchise,
  franchiseSites,
  getFranchiseSitesForAdmin
} from "../../constants";
import { Auth } from "../../components";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddIcon from "@material-ui/icons/Add";
import { Input } from "../../components";
import { useHistory } from "react-router-dom";
import { EDITFRANCHISESITES, ADDFRANCHISESITES } from "../../paths";
import useStyles from "../../utils/Styles/ManagePageStyles";
import { providerForDelete, providerForGet } from "../../api";
import {
  DELETE_MESSAGE,
  ERROR_MESSAGE
} from "../../constants/genericConstants";

const FranchiseSites = props => {
  const propData = props["location"]["state"];
  const history = useHistory();
  const classes = useStyles();
  const tableRef = React.createRef();
  const auth = Auth.getToken();
  const userInfo = Auth.getUserInfo();
  const columns = [
    { title: "Name", field: "name" },
    { title: "Franchise", field: "franchise.franchise_name" }
  ];
  const [filter, setFilter] = useState({
    _sort: "name:asc"
  });

  const [formState, setFormState] = useState({
    dataTable: {
      data: [],
      page: 1,
      totalCount: 0
    },
    alert: propData ? propData["successMessage"] : false,
    errorMessage: propData ? propData["successMessage"] : false,
    severity: "success",
    isLoad: false,
    adminData: {},
    franchiseList: []
  });
  const [isAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_ADMIN_ROLE_NAME ? true : false
  );
  const [isSuperAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
      ? true
      : false
  );

  /**
   ** Use key names exactly same as API response,they'll be used for filtering & sorting purpose
   * */
  const convertTableData = res => {
    let arr = [];
    if (res && res.length > 0) {
      for (let i in res) {
        let tempArr = {};
        tempArr["id"] = res[i].id;
        tempArr["name"] = res[i].name;
        tempArr["franchise.franchise_name"] = res[i].franchise
          ? res[i].franchise.franchise_name
          : "";
        arr.push(tempArr);
      }
      return arr;
    } else {
      return [];
    }
  };

  const getFranchiseData = async (page, pageSize) => {
    /**
     ** It sets the page and pageSize in filter variable
     ** so as to keep the record of what page and pageSize was set
     */

    let api = franchiseSites;
    if (isAdmin) {
      api = getFranchiseSitesForAdmin;
    }
    if (isSuperAdmin) {
      api = franchiseSites;
    }

    setFilter(filter => ({
      ...filter,
      page: page,
      pageSize: pageSize
    }));

    /**getting franchise list for franchise filter */
    let body = {};
    await providerForGet(franchise, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          franchiseList: res.data.data
        }));
      })
      .catch(err => {
        console.log("err", err);
      });

    let params = { page: page, pageSize: pageSize };
    Object.keys(filter).map(res => {
      if (!params.hasOwnProperty(res)) {
        params[res] = filter[res];
      }
      return null;
    });
    params.desc = `${userInfo.full_name} viewed the franchise sites listing page`;
    return new Promise((resolve, reject) => {
      fetch(api + "?" + new URLSearchParams(params), {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth
        }
      })
        .then(response => response.json())
        .then(result => {
          resolve({
            data: convertTableData(result.data),
            page: result.page - 1,
            totalCount: result.totalCount
          });
        });
    });
  };

  const handleClickOpen = rowData => {
    let data = {};
    data["id"] = rowData.id;

    history.push(EDITFRANCHISESITES, data);
  };

  const handleFilterChange = event => {
    if (event.target.value !== "") {
      setFilter(filter => ({
        ...filter,
        [event.target.name]: event.target.value
      }));
    } else {
      delete filter[event.target.name];
      setFilter(filter => ({
        ...filter
      }));
    }
  };

  const handleFilterAutoCompleteChange = (eventName, event, value) => {
    if (value !== null) {
      setFilter(filter => ({
        ...filter,
        [eventName]: value.id
      }));
    } else {
      delete filter[eventName];
      setFilter(filter => ({
        ...filter
      }));
    }
  };

  const handleSnackbarClose = () => {
    if (propData) {
      propData["successMessage"] = false;
    }
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "success"
    }));
  };

  const orderFunc = (columnId, direction) => {
    let orderByColumn;
    let orderBy = "";
    if (columnId >= 0) {
      orderByColumn = columns[columnId]["field"];
    }
    orderBy = orderByColumn + ":" + direction;
    setFilter(filter => ({
      ...filter,
      _sort: orderBy
    }));
    tableRef.current.onQueryChange();
  };

  const handleReset = event => {
    delete filter["name_contains"];
    delete filter["franchise"];
    setFilter(filter => ({
      ...filter
    }));
    getFranchiseData(1, filter.pageSize);
    tableRef.current.onQueryChange();
  };

  return (
    <div className={styles.FranchiseSite} data-testid="Franchise Sites">
      <div>
        <Typography variant="h4" className={styles.titleText}>
          Manage Franchise Sites
        </Typography>
        <Grid>
          <SnackBar
            open={formState.alert}
            severity={formState.severity}
            onClose={handleSnackbarClose}
          >
            {formState.errorMessage}
          </SnackBar>
        </Grid>

        <Grid container spacing={1}>
          <Grid item sm={12} className={classes.addButton}>
            <DarkBlueButton
              onClick={() => {
                history.push(ADDFRANCHISESITES);
              }}
            >
              <AddIcon /> &nbsp; Add Franchise Site
            </DarkBlueButton>
          </Grid>
        </Grid>
        <CardContent className={classes.Cardtheming}>
          <Grid className={classes.filterOptions} container spacing={1}>
            <Grid item>
              <Input
                label={"Franchise Site Name"}
                placeholder="Name"
                name="name_contains"
                value={filter.name_contains ? filter.name_contains : ""}
                variant="outlined"
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid item>
              {isSuperAdmin && (
                <AutoCompleteDropDown
                  options={formState.franchiseList}
                  getOptionLabel={option => option.franchise_name}
                  id={"franchiseName"}
                  value={
                    formState.franchiseList[
                      formState.franchiseList.findIndex(function (item, i) {
                        return item.id === filter.franchise;
                      })
                    ] || null
                  }
                  onChange={(event, value) => {
                    handleFilterAutoCompleteChange("franchise", event, value);
                  }}
                  renderInput={params => (
                    <Input
                      variant="outlined"
                      {...params}
                      style={{ "min-width": "150px" }}
                      name="franchise"
                      id={"franchiseName"}
                      label={"Franchise"}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item>
              <DarkBlueButton
                disableElevation
                onClick={() => {
                  setFormState(formState => ({
                    ...formState,
                    isLoad: true
                  }));
                  tableRef.current.onQueryChange();
                }}
              >
                search
              </DarkBlueButton>
            </Grid>
            <Grid item>
              <BorderLessButton onClick={handleReset} disableElevation>
                reset{" "}
              </BorderLessButton>
            </Grid>
          </Grid>
        </CardContent>

        <Table
          tableRef={tableRef}
          columns={columns}
          data={async query => {
            return await getFranchiseData(query.page + 1, query.pageSize);
          }}
          localization={{
            body: {
              editRow: {
                deleteText: `Are you sure you want to delete this Franchise Site?`,
                saveTooltip: "Delete"
              }
            }
          }}
          actions={[
            {
              icon: () => <EditOutlinedIcon />,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                handleClickOpen(rowData);
              }
            }
          ]}
          editable={{
            onRowDelete: oldData =>
              new Promise(async resolve => {
                let query = { franchise_site: oldData.id };
                await providerForGet(checkIfFranchiseSiteIsUsed, query, auth)
                  .then(res => {
                    setTimeout(async () => {
                      await providerForDelete(
                        franchiseSites,
                        oldData.id,
                        auth,
                        {
                          desc: `${userInfo.full_name} deleted a franchise site`
                        }
                      )
                        .then(res => {
                          setFormState(formState => ({
                            ...formState,
                            alert: true,
                            errorMessage: res.data.name + DELETE_MESSAGE,
                            severity: "success"
                          }));
                        })
                        .catch(err => {
                          console.log("error", err);
                          if (
                            err.response &&
                            err.response.data &&
                            err.response.data.message
                          ) {
                            setFormState(formState => ({
                              ...formState,
                              alert: true,
                              severity: "error",
                              errorMessage: err.response.data.message
                            }));
                          } else {
                            setFormState(formState => ({
                              ...formState,
                              alert: true,
                              errorMessage: ERROR_MESSAGE + err,
                              severity: "error"
                            }));
                          }
                        });
                      resolve();
                    }, 1000);
                  })
                  .catch(err => {
                    console.log("error", err);
                  });
              })
          }}
          options={{
            pageSize: 10,
            actionsColumnIndex: -1,
            search: false,
            sorting: true,
            thirdSortClick: false
          }}
          onOrderChange={(orderedColumnId, orderDirection) => {
            orderFunc(orderedColumnId, orderDirection);
          }}
        />
      </div>
    </div>
  );
};

FranchiseSites.propTypes = {};

FranchiseSites.defaultProps = {};

export default FranchiseSites;
