import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
  Auth,
  Input,
  SnackBar,
  DarkBlueButton,
  BorderLessButton,
  Table
} from "../../../components";
import styles from "./Schooling.module.css";
import {
  findIfDataAvailableInContacts,
  schoolingForAdmin
} from "../../../constants";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import useStyles from "../../../utils/Styles/ManagePageStyles";
import { providerForDelete, providerForGet } from "../../../api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ADDSCHOOLING, EDITSCHOOLING } from "../../../paths";
import {
  DELETE_MESSAGE,
  ERROR_MESSAGE
} from "../../../constants/genericConstants";

const Schooling = props => {
  const propData = props["location"]["state"];
  const history = useHistory();
  const classes = useStyles();
  const [filter, setFilter] = useState({
    _sort: "name:asc"
  });
  const tableRef = React.createRef();
  const auth = Auth.getToken();
  const userInfo = Auth.getUserInfo();
  let apiUrl = "";
  const columns = [{ title: "Name", field: "name" }];

  const [formState, setFormState] = useState({
    dataTable: {
      data: [],
      page: 1,
      totalCount: 0
    },
    alert: propData ? propData["successMessage"] : false,
    errorMessage: propData ? propData["successMessage"] : false,
    severity: "success",
    isLoad: false,
    isAdmin: false,
    franchiseList: []
  });

  /**
   ** Use key names exactly same as API response,they'll be used for filtering & sorting purpose
   * */
  const convertTableData = res => {
    let arr = [];
    if (res && res.length > 0) {
      for (let i in res) {
        let tempArr = {};
        tempArr["id"] = res[i].id;
        tempArr["name"] = res[i].name;
        arr.push(tempArr);
      }
      return arr;
    } else {
      return [];
    }
  };

  const getSchoolingData = async (page, pageSize) => {
    apiUrl = schoolingForAdmin;
    /**
     ** It sets the page and pageSize in filter variable
     ** so as to keep the record of what page and pageSize was set
     */
    setFilter(filter => ({
      ...filter,
      page: page,
      pageSize: pageSize
    }));

    let params = { page: page, pageSize: pageSize };
    Object.keys(filter).map(res => {
      if (!params.hasOwnProperty(res)) {
        params[res] = filter[res];
      }
      return null;
    });
    params.desc = `${userInfo.full_name} viewed the schooling listing page`;

    return new Promise((resolve, reject) => {
      fetch(apiUrl + "?" + new URLSearchParams(params), {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth
        }
      })
        .then(response => response.json())
        .then(result => {
          resolve({
            data: convertTableData(result.data),
            page: result.page - 1,
            totalCount: result.totalCount
          });
        });
    });
  };

  const handleClickOpen = rowData => {
    let data = {};
    data["id"] = rowData.id;

    history.push(EDITSCHOOLING, data);
  };

  const handleFilterChange = event => {
    if (event.target.value !== "") {
      setFilter(filter => ({
        ...filter,
        [event.target.name]: event.target.value
      }));
    } else {
      delete filter[event.target.name];
      setFilter(filter => ({
        ...filter
      }));
    }
  };

  const orderFunc = (columnId, direction) => {
    let orderByColumn;
    let orderBy = "";
    if (columnId >= 0) {
      orderByColumn = columns[columnId]["field"];
    }
    orderBy = orderByColumn + ":" + direction;
    setFilter(filter => ({
      ...filter,
      _sort: orderBy
    }));
    tableRef.current.onQueryChange();
  };

  const handleReset = event => {
    delete filter["name_contains"];
    setFilter(filter => ({
      ...filter
    }));
    getSchoolingData(1, filter.pageSize);
    tableRef.current.onQueryChange();
  };

  const handleSnackbarClose = () => {
    if (propData) {
      propData["successMessage"] = false;
    }
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "success"
    }));
  };

  return (
    <div className={styles.User}>
      <>
        <Typography variant="h4" className={styles.titleText}>
          Manage Schooling
        </Typography>

        <Grid>
          <SnackBar
            open={formState.alert}
            severity={formState.severity}
            onClose={handleSnackbarClose}
          >
            {formState.errorMessage}
          </SnackBar>
        </Grid>

        <Grid container spacing={1}>
          <Grid item sm={12} className={classes.addButton}>
            <DarkBlueButton
              onClick={() => {
                history.push(ADDSCHOOLING);
              }}
            >
              <AddIcon /> &nbsp; Add Schooling{" "}
            </DarkBlueButton>
          </Grid>
        </Grid>

        <Card className={classes.paperFilter}>
          <CardContent className={classes.Cardtheming}>
            <Grid className={classes.filterOptions} container spacing={1}>
              <Grid item>
                <Input
                  label={"Name"}
                  placeholder="Name"
                  name="name_contains"
                  value={filter.name_contains ? filter.name_contains : ""}
                  variant="outlined"
                  onChange={handleFilterChange}
                />
              </Grid>
              <Grid item>
                <DarkBlueButton
                  disableElevation
                  onClick={() => {
                    setFormState(formState => ({
                      ...formState,
                      isLoad: true
                    }));
                    tableRef.current.onQueryChange();
                  }}
                >
                  search
                </DarkBlueButton>
              </Grid>
              <Grid item>
                <BorderLessButton onClick={handleReset} disableElevation>
                  reset{" "}
                </BorderLessButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Table
          tableRef={tableRef}
          columns={columns}
          data={async query => {
            return await getSchoolingData(query.page + 1, query.pageSize);
          }}
          localization={{
            body: {
              editRow: {
                deleteText: `Are you sure you want to delete this Schooling?`,
                saveTooltip: "Delete"
              }
            }
          }}
          actions={[
            rowData => ({
              icon: () => <EditOutlinedIcon />,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                handleClickOpen(rowData);
              }
            })
          ]}
          editable={{
            onRowDelete: oldData =>
              new Promise(async resolve => {
                let query = { schooling: oldData.id };
                await providerForGet(findIfDataAvailableInContacts, query, auth)
                  .then(res => {
                    if (res.data.data > 0) {
                      let userOrUsers = res.data.data > 1 ? " Users" : " User";
                      let names = [];
                      let allNames = res.data.names;
                      if (res.data.data > 2) {
                        let finalLength = res.data.data - 2;
                        names = [allNames[0], allNames[1], " + " + finalLength];
                      } else {
                        names = allNames;
                      }
                      setFormState(formState => ({
                        ...formState,
                        alert: true,
                        errorMessage:
                          "cannot delete ! as schooling data is used by " +
                          names.join(" ,") +
                          userOrUsers,
                        severity: "error"
                      }));
                      resolve();
                    } else {
                      setTimeout(async () => {
                        await providerForDelete(
                          schoolingForAdmin,
                          oldData.id,
                          auth,
                          {
                            desc: `${userInfo.full_name} deleted the schooling data`
                          }
                        )
                          .then(res => {
                            setFormState(formState => ({
                              ...formState,
                              alert: true,
                              errorMessage: res.data.name + DELETE_MESSAGE,
                              severity: "success"
                            }));
                          })
                          .catch(err => {
                            console.log("error", err);
                            setFormState(formState => ({
                              ...formState,
                              alert: true,
                              errorMessage: ERROR_MESSAGE + err,
                              severity: "error"
                            }));
                          });
                        resolve();
                      }, 1000);
                    }
                  })
                  .catch(err => {
                    console.log("error", err);
                  });
              })
          }}
          options={{
            pageSize: 10,
            actionsColumnIndex: -1,
            search: false,
            sorting: true,
            thirdSortClick: false
          }}
          onOrderChange={(orderedColumnId, orderDirection) => {
            orderFunc(orderedColumnId, orderDirection);
          }}
        />
      </>
    </div>
  );
};

Schooling.propTypes = {};

Schooling.defaultProps = {};

export default Schooling;
