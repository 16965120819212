import React, { useEffect, useState, useContext } from "react";
import { Page, Document, pdfjs } from "react-pdf";

import {
  Grid,
  makeStyles,
  Card,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import {
  AutoCompleteDropDown,
  DarkBlueButton,
  Auth,
  Input,
  BorderLessButton,
  CircularProgressWithLabel,
  HeadingOne
} from "../../components";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {
  providerForGet,
  providerForDownload,
  providerForDownloadWithGraph
} from "../../api";
import {
  createExerciseReport,
  downloadActualFoodForAllMealsBreakdownPdf,
  downloadSupplementsPdf,
  foodCategory,
  generateGiveAllWithInserts,
  generateShoppingList,
  getAllDaysBreakdownPdf,
  getAllReports,
  getGenericDocument,
  getMenuForWeekMeal,
  getScheduleDateForClient,
  printPdf,
  viewPdf,
  getCustomizationUrl
} from "../../constants";
import { InitContext } from "../../context";
import { convertDate } from "../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CLIENTMYREPORTS } from "../../paths";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewDocument = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    }
  }));
  const classes = useStyles();
  const { isInitial, setInitial } = useContext(InitContext);
  let propData = props["location"]["state"];
  const [loader, setLoader] = useState(false);
  const userInfo = Auth.getUserInfo();
  const auth = Auth.getToken();
  const [dateList, setDateList] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [percentCompleted, setPercentCompleted] = useState(0);

  const [reportData, setReportData] = useState({
    viewReportData: "",
    downLoadReportData: []
  });
  const [showFile, setShowFile] = useState({ status: false, data: {} });
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show first page
  const [formState, setFormState] = useState({
    isValid: false,
    scheduleId: propData && propData.scheduleId ? propData.scheduleId : null,
    values: {
      scheduleObj: propData ? propData.scheduleObj : null,
      scheduleDate:
        propData && propData.scheduleObj ? propData.scheduleObj.id : null,
      reportObj: propData ? propData.reportData : null,
      reportName:
        propData && propData.reportData ? propData.reportData.name : null
    },
    viewPermission: propData && propData.showFile ? propData.showFile : null,
    fromReport: false,
    alert: false,
    errorMessage: "",
    severity: "error"
  });
  const history = useHistory();
  const [avgFoodCat, setAvgFoodCat] = useState({
    bread: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    meat: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    veg: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    fruit: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    milk: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    fat: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    }
  });

  const [reportKeys, setReportKeys] = useState([
    {
      name: "Give All",
      url: getGenericDocument,
      download: "report",
      sendBody: false,
      scheduleId: false,
      clientId: false
    },
    {
      name: "Give All With Insert",
      url: generateGiveAllWithInserts,
      download: "report",
      sendBody: true,
      scheduleId: true,
      clientId: true
    },
    {
      name: "All Reports",
      url: getAllReports,
      download: "report",
      sendBody: true,
      scheduleId: true,
      clientId: true
    },
    {
      name: "Exchange Break Down For All Meals Week",
      url: getAllDaysBreakdownPdf,
      download: "report",
      sendBody: true,
      scheduleId: true,
      clientId: false
    },
    {
      name: "Shopping List",
      url: generateShoppingList,
      download: "report",
      sendBody: true,
      scheduleId: true,
      clientId: false
    },
    {
      name: "Menu For Week List",
      url: getMenuForWeekMeal,
      download: "report",
      sendBody: true,
      scheduleId: true,
      clientId: false
    },
    {
      name: "Actual Food For All Meal",
      url: downloadActualFoodForAllMealsBreakdownPdf,
      download: "report",
      sendBody: true,
      scheduleId: true,
      clientId: false
    },
    {
      name: "Supplement Reports",
      url: downloadSupplementsPdf,
      download: "report",
      sendBody: true,
      scheduleId: false,
      clientId: false
    },
    {
      name: "Exercise Reports",
      url: createExerciseReport,
      download: "report",
      sendBody: true,
      scheduleId: true,
      clientId: false
    },
    {
      name: "Body Mass Reports",
      url: viewPdf,
      download: "graph",
      sendBody: true,
      scheduleId: false,
      clientId: false
    },
    {
      name: "Measurements Reports",
      url: printPdf,
      download: "graph",
      sendBody: true,
      scheduleId: false,
      clientId: false
    }
  ]);

  const setPermission = data => {
    let temp = {};
    Object.keys(data).map((number, index) => {
      temp[number] = {
        view: data[number].view === true ? true : false,
        download: data[number].download === true ? true : false
      };
      return null;
    });
    setShowFile(showFile => ({
      ...showFile,
      data: temp,
      status: true
    }));
  };

  const getPermissionDetails = () => {
    let body = {
      is_document_permission_custom: true
    };
    providerForGet(getCustomizationUrl, body, auth)
      .then(res => {
        if (
          res.data &&
          res.data[0] &&
          res.data[0].customization_json_field &&
          res.data[0].customization_json_field.allDocuments
        ) {
          setPermission(res.data[0].customization_json_field.allDocuments);
        }
      })
      .catch(err => {
        console.log("errpermission", err);
      });
  };

  const getScheduleDates = () => {
    providerForGet(
      getScheduleDateForClient,
      {
        c: userInfo.id
      },
      auth
    )
      .then(async res => {
        let convertDateData = await convertDate(res.data);
        setDateList(convertDateData);
        let sid = null;
        if (!formState.scheduleId) {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              scheduleDate: convertDateData[0].id,
              scheduleObj: convertDateData[0],
              defaultScheduleObj: convertDateData[0]
            }
          }));
        } else {
          sid = formState.scheduleId;
          convertDateData.map(sd => {
            if (sd.id === sid) {
              setFormState(formState => ({
                ...formState,
                values: {
                  ...formState.values,
                  scheduleDate: sid,
                  scheduleObj: sd,
                  defaultScheduleObj: sd
                },
                fromReport: propData && propData.fromReport ? true : false
              }));
            }
            return null;
          });
        }
      })
      .catch(error => {
        console.log("error", error);
      });
  };

  const getAvgInfo = async () => {
    setLoader(true);
    await providerForGet(foodCategory, { pageSize: -1 }, Auth.getToken())
      .then(async res => {
        let finalAvgFood = {};
        res.data.data.map(fc => {
          let name = "";
          let dataToAdd = {
            avgcal: 0,
            avgpro: 0,
            avgcarb: 0,
            avgfat: 0
          };
          if (fc.name === "Milk" || fc.name === "milk") {
            name = "milk";
          } else if (
            fc.name === "Vegetable" ||
            fc.name === "veg" ||
            fc.name === "vegetable" ||
            fc.name === "Veg"
          ) {
            name = "veg";
          } else if (fc.name === "Fruit" || fc.name === "fruit") {
            name = "fruit";
          } else if (fc.name === "Meat" || fc.name === "meat") {
            name = "meat";
          } else if (fc.name === "Fat" || fc.name === "fat") {
            name = "fat";
          } else if (fc.name === "Bread" || fc.name === "bread") {
            name = "bread";
          }
          if (name !== "") {
            dataToAdd.avgcal = fc.avgcal;
            dataToAdd.avgpro = fc.avgpro;
            dataToAdd.avgcarb = fc.avgcarb;
            dataToAdd.avgfat = fc.avgfat;
            finalAvgFood[name] = dataToAdd;
          }
          return null;
        });
        setAvgFoodCat(finalAvgFood);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getPermissionDetails();
    getScheduleDates();
    getAvgInfo();
  }, []);

  const checkPermission = data => {
    let temp = [];
    Object.keys(data).map((number, index) => {
      for (var i = 0; i < reportKeys.length; i++) {
        if (number === reportKeys[i].name) {
          if (showFile.data[number].view === true) {
            temp.push(reportKeys[i]);
          }
        }
      }
      return null;
    });
    setReportKeys(temp);
  };

  const createFormData = body => {
    const data = new FormData();
    data.append("data", JSON.stringify(body));

    return data;
  };

  const ViewReport = async data => {
    setLoader(true);
    let finalData = {};
    if (
      data.sendBody === true &&
      data.scheduleId === true &&
      data.clientId === true
    ) {
      finalData = {
        scheduleId: formState.values.scheduleObj.id,
        clientId: userInfo.id
      };
    } else if (
      data.sendBody === true &&
      data.scheduleId === true &&
      data.clientId === false
    ) {
      if (data.url === getAllDaysBreakdownPdf) {
        finalData = {
          scheduleId: formState.values.scheduleObj.id,
          c: userInfo.id,
          avgMealValues: avgFoodCat
        };
      } else {
        finalData = {
          schedule_id: formState.values.scheduleObj.id,
          c: userInfo.id,
          avgMealValues: avgFoodCat
        };
      }
    } else if (data.sendBody === true) {
      finalData = {
        s: formState.values.scheduleObj.id,
        c: userInfo.id
      };
    }
    if (data.download === "report") {
      await providerForDownload(
        data.url,
        finalData,
        auth,
        {
          desc: `Client ${userInfo.full_name} viewed ${data.name}`
        },
        progressEvent => {
          setPercentCompleted(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        }
      )
        .then(res => {
          setPercentCompleted(0);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          var string64 =
            "data:application/pdf;base64," + _arrayBufferToBase64(res.data);

          setReportData(reportData => ({
            ...reportData,
            viewReportData: string64,
            downLoadReportData: res.data
          }));
          if (isInitial) {
            setInitial(false);
          }
          setLoader(false);
        })
        .catch(error => {
          setLoader(false);
          console.log(error);
        });
    } else if (data.download === "graph") {
      let finalData = createFormData({
        s: formState.values.scheduleObj.id,
        c: userInfo.id
      });
      providerForDownloadWithGraph(
        data.url,
        finalData,
        Auth.getToken(),
        {
          desc: `Client ${userInfo.full_name} viewed ${data.name}`
        },
        progressEvent => {
          setPercentCompleted(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        }
      )
        .then(res => {
          setPercentCompleted(0);
          setLoader(false);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          var string64 =
            "data:application/pdf;base64," + _arrayBufferToBase64(res.data);

          setReportData(reportData => ({
            ...reportData,
            viewReportData: string64,
            downLoadReportData: res.data
          }));
          if (isInitial) {
            setInitial(false);
          }
        })
        .catch(error => {
          setLoader(false);
          console.log("PrintError", error);
        });
    }
  };

  useEffect(() => {
    if (formState.fromReport) {
      if (
        formState.values.reportObj !== {} &&
        formState.values.reportName &&
        formState.values.scheduleObj &&
        formState.values.scheduleObj.id
      ) {
        ViewReport(formState.values.reportObj);
      }
    }
  }, [formState.fromReport]);

  useEffect(() => {
    if (showFile.status) {
      checkPermission(showFile.data);
    }
  }, [showFile]);

  useEffect(() => {
    setReportData(reportData => ({
      ...reportData,
      viewReportData: "",
      downLoadReportData: []
    }));
  }, [formState.values["reportName"]]);

  const handleChangeAutoComplete = (eventName, event, value) => {
    if (eventName === "scheduleDate") {
      if (value !== null) {
        setFormState(formState => ({
          ...formState,

          values: {
            ...formState.values,
            [eventName]: value.id,
            scheduleObj: value
          }
        }));
      } else {
        delete formState.values[eventName];
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [eventName]: formState.values.defaultScheduleObj.id,
            scheduleObj: formState.values.defaultScheduleObj
          }
        }));
      }
    } else if (eventName === "reportName") {
      if (value !== null) {
        setFormState(formState => ({
          ...formState,

          values: {
            ...formState.values,
            [eventName]: value.name,
            reportObj: value
          }
        }));
      } else {
        delete formState.values[eventName];
        delete formState.values.reportObj;
      }
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const handleLoading = () => {
    return (
      <div>
        {" "}
        <CircularProgress color="inherit" />
      </div>
    );
  };

  const handleDownload = isPrint => {
    if (isPrint) {
      const url = URL.createObjectURL(
        new Blob([reportData.downLoadReportData], { type: "application/pdf" })
      );
      window.open(url,'_blank');
      // const pdfNewWindow = window.open();
      // pdfNewWindow.location.href = url;
    } else {
      const url = window.URL.createObjectURL(
        new Blob([reportData.downLoadReportData])
      );
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", formState.values.reportName + ".pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleView = () => {
    if (formState.values.reportObj !== {} && formState.values.reportName) {
      ViewReport(formState.values.reportObj);
    }
  };

  /**
   * Below function is use to convert buffer into base64 to display pdf.
   */

  function _arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const handleBackArrow = () => {
    history.push(CLIENTMYREPORTS);
  };

  return (
    <>
      {isInitial ? (
        <Backdrop className={classes.backdrop} open={loader}>
          {!percentCompleted ? (
            <CircularProgress color="inherit" />
          ) : (
            <CircularProgressWithLabel
              color="inherit"
              value={percentCompleted}
            />
          )}
        </Backdrop>
      ) : (
        <div className={classes.root}>
          <HeadingOne>
            {formState.values["reportName"]
              ? formState.values["reportName"]
              : ""}
          </HeadingOne>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <AutoCompleteDropDown
                options={dateList}
                getOptionLabel={option => option.schedule_start_date}
                id="schedule_date"
                value={
                  dateList[
                    dateList.findIndex(function (item, i) {
                      return item.id === formState.values["scheduleDate"];
                    })
                  ] || null
                }
                onChange={(event, value) => {
                  handleChangeAutoComplete("scheduleDate", event, value);
                }}
                renderInput={params => (
                  <Input {...params} id="schedule_date" label="Schedule Date" />
                )}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <AutoCompleteDropDown
                options={reportKeys}
                getOptionLabel={option => option.name}
                id="report_name"
                value={
                  reportKeys[
                    reportKeys.findIndex(function (item, i) {
                      return item.name === formState.values["reportName"];
                    })
                  ] || null
                }
                onChange={(event, value) => {
                  handleChangeAutoComplete("reportName", event, value);
                }}
                renderInput={params => (
                  <Input {...params} id="report_name" label="report name" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ marginTop: "10px" }}>
              <DarkBlueButton type="button" onClick={handleView}>
                View
              </DarkBlueButton>
              {showFile.data[formState.values["reportName"]] &&
                showFile.data[formState.values["reportName"]]["download"] ===
                  true &&
                reportData.viewReportData !== "" && (
                  <>
                    <DarkBlueButton
                      type="button"
                      style={{ marginLeft: "10px" }}
                      onClick={() => handleDownload(false)}
                    >
                      download
                    </DarkBlueButton>
                    <DarkBlueButton
                      type="button"
                      style={{ marginLeft: "10px" }}
                      onClick={() => handleDownload(true)}
                    >
                      print
                    </DarkBlueButton>
                  </>
                )}
              <BorderLessButton
                type="button"
                style={{ marginLeft: "10px" }}
                onClick={() => handleBackArrow()}
              >
                Back to Reports
              </BorderLessButton>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ paddingTop: "15px" }}>
            <Grid item style={{ width: "calc(100% - 48px)" }}>
              {reportData.viewReportData !== "" && (
                <Card variant="elevation" elevation={2}>
                  <Document
                    loading={handleLoading}
                    file={reportData.viewReportData}
                    options={{ workerSrc: "/pdf.js" }}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page width={1100} pageNumber={pageNumber} />
                  </Document>

                  <div style={{ marginBottom: "10px" }}>
                    <p style={{ marginLeft: "500px" }}>
                      Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                      {numPages || "--"}
                    </p>
                    <DarkBlueButton
                      type="button"
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                      style={{ marginLeft: "450px" }}
                    >
                      Previous
                    </DarkBlueButton>
                    <DarkBlueButton
                      type="button"
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                      style={{ marginLeft: "5px" }}
                    >
                      Next
                    </DarkBlueButton>
                  </div>
                </Card>
              )}
            </Grid>
            <Backdrop className={classes.backdrop} open={loader}>
              {!percentCompleted ? (
                <CircularProgress color="inherit" />
              ) : (
                <CircularProgressWithLabel
                  color="inherit"
                  value={percentCompleted}
                />
              )}
            </Backdrop>
          </Grid>
        </div>
      )}
    </>
  );
};

export default ViewDocument;
