export {
  LOGIN,
  // DASHBOARD,
  CLIENT,
  USERS,
  ADDUSERS,
  EDITUSERS,
  SCHOOLING,
  ADDSCHOOLING,
  EDITSCHOOLING,
  GENDER,
  ADDGENDER,
  EDITGENDER,
  MARITALSTATUS,
  ADDMARITALSTATUS,
  EDITMARITALSTATUS,
  EMERGENCYCONTACTS,
  ADDEMERGENCYCONTACTS,
  EDITEMERGENCYCONTACTS,
  COUNTRIES,
  ADDCOUNTRIES,
  EDITCOUNTRIES,
  USERTAG,
  ADDUSERTAG,
  EDITUSERTAG,
  NUTRITIONIST,
  CHANGEPASSWORD,
  LOGOUT,
  ADDCLIENT,
  EDITCLIENT,
  AUDITLOGS,
  DOCUMENTLIST,
  FRANCHISE,
  ADDFRANCHISE,
  EDITFRANCHISE,
  FRANCHISESITES,
  ADDFRANCHISESITES,
  EDITFRANCHISESITES,
  MANAGECLIENTS,
  FORGOTPASSWORD,
  NEWACCOUNT,
  VERIFYOTP,
  RESETPASSWORD,
  PASSWORDCHANGED,
  TWOFACTORAUTH,
  VERIFYCODE,
  SUPPLEMENT,
  ADDSUPPLEMENT,
  EDITSUPPLEMENT,
  EXERCISE,
  ADDEXERCISE,
  EDITEXERCISE,
  FOOD,
  ADDFOOD,
  EDITFOOD,
  FOODCATEGORY,
  ADDFOODCATEGORY,
  EDITFOODCATEGORY,
  FOODSUBCATEGORY,
  ADDFOODSUBCATEGORY,
  EDITFOODSUBCATEGORY,
  SIZEWEIGHT,
  ADDSIZEWEIGHT,
  EDITSIZEWEIGHT,
  MUSCLEGROUP,
  SCHEDULE,
  ADDMUSCLEGROUP,
  EDITMUSCLEGROUP,
  AUTHENTICATOR,
  CUSTOMCOLOR,
  VIEWPROFILE,
  VIEWFILE,
  BODYMASS,
  MEASUREMENTS,
  EXCHANGEMENU,
  SUPPLEMENTS,
  WORKOUT,
  CREATEMEALS,
  SUPPLEMENTTEMPLATE,
  ADDSUPPLEMENTTEMPLATE,
  EDITSUPPLEMENTTEMPLATE,
  GIVEALL,
  EXERCISETEMPLATE,
  ADDEXERCISETEMPLATE,
  EDITEXERCISETEMPLATE,
  EX_TEMPLATE,
  CREATEMEALTEMPLATE,
  MANAGEEXCHANGEMEALTEMPLATE,
  CLIENTDASHBAORD,
  CLIENTMYNOTES,
  CLIENTMYPROFILE,
  CLIENTMYREPORTS,
  CLIENTMYSCHEDULE,
  CLIENTSUPPLEMENT,
  CLIENTEXERCISE,
  CLIENTEXCHANGE,
  CLIENTMEASUREMENT,
  CLIENTBODYMASS,
  CLIENTSHOPPINGLIST,
  CLIENTBODYMASSNOTES,
  CLIENTMEASUREMENTSNOTES,
  CLIENTMEALS,
  CLIENTEXCHANGENOTES,
  ADDEDITNOTES,
  CLIENTFOODDIARY
} from "./Paths";
