import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
  IconButton,
  Tooltip,
  useMediaQuery
} from "@material-ui/core";
import { get } from "lodash";
import GetAppIcon from "@material-ui/icons/GetApp";

import {
  useWindowDimensions,
  AutoCompleteDropDown,
  ExerciseGrid,
  Table,
  LightGrayButton,
  Input,
  WhiteIconButton,
  DarkBlueButton,
  SnackBar,
  SelectDropdown
} from "../../components";
import { useTheme } from "@material-ui/core/styles";
import {
  providerForDownload,
  providerForGet,
  providerForPost
} from "../../api";
import {
  muscleGroup,
  exerciseForFranchise,
  addUpdateExerciseData,
  getDataForWorkout,
  createExerciseReport,
  getWorkoutTemplateForFranchise,
  useTemplate,
  saveFavoriteExercise,
  addUpdateExerciseTemplate
} from "../../constants";
import {
  checkAllKeysPresent,
  checkEmpty,
  getListOfKeysNotPresent,
  setErrors
} from "../../utils";
import addExerciseForm from "../ExerciseTemplate/ExerciseTemplateSchema";
import auth from "../../components/Auth";
import DialogBox from "../../components/DialogBox/DialogBox";
import { Prompt } from "react-router-dom";
import {
  ERROR_MESSAGE,
  SAVE_MESSAGE,
  UPDATE_MESSAGE
} from "../../constants/genericConstants";
import PrintIcon from "@material-ui/icons/Print";

const WorkoutSchedule = props => {
  const { width } = useWindowDimensions();
  const tableRef = React.createRef();
  const columns = [{ title: "Name", field: "name" }];
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    summaryButtonBox: {
      display: "flex",
      marginBottom: "3%"
    },
    loadTemplate: {
      marginLeft: "auto"
    },
    favoritesOption: {
      display: "flex",
      marginRight: "auto"
    },
    margin: {
      margin: theme.spacing(1)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },

    formControl: {
      marginRight: theme.spacing(1),
      minWidth: 180,
      "& label": {
        fontSize: "0.9375rem",
        fontFamily: "Montserrat !important",
        fontWeight: "500"
      },
      "& input": {
        fontSize: "0.9375rem",
        fontFamily: "Montserrat !important",
        fontWeight: "500"
      },
      "& > div": {
        fontSize: "0.9375rem",
        fontFamily: "Montserrat !important",
        fontWeight: "500"
      }
    },
    workoutFormControl: {
      width: "100% !important",
      marginRight: theme.spacing(1),
      minWidth: 180,
      "& label": {
        fontSize: "0.9375rem",
        fontFamily: "Montserrat !important",
        fontWeight: "500"
      },
      "& input": {
        fontSize: "0.9375rem",
        fontFamily: "Montserrat !important",
        fontWeight: "500"
      },
      "& > div": {
        fontSize: "0.9375rem",
        fontFamily: "Montserrat !important",
        fontWeight: "500"
      },
      "& legend": {
        minWidth: "100px"
      }
    },
    cancelSaveButton: {
      marginLeft: "auto"
    },
    duplicateReplicateStyle: {
      display: "flex"
    },
    workoutDayGrid: {
      paddingTop: "0px !Important"
    },
    favouriteClass: {
      [theme.breakpoints.down("xs")]: {
        "& button": {
          marginLeft: "0px !important",
          display: "flex !important",
          "&:first-child": {
            marginBottom: "10px !important"
          }
        }
      }
    }
  }));
  const urlParams = new URLSearchParams(window.location.search);
  const localClasses = useStyles();
  const theme = useTheme();
  const user = auth.getUserInfo();

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  const [statusVariable, setStatusVariable] = useState({
    isError: false,
    isOpenSnackBar: false,
    successErrorMessage: "",
    saveText: "",
    dummyTime: new Date(),
    savedTime: null,
    isSaving: false
  });
  const exerciseName = "exerciseName";
  const [muscleGroups, setMuscleGroups] = useState([]);
  const [exerciseData, setExerciseData] = useState([]);
  const [offId, setOffId] = useState([]);
  const [loader, setLoader] = useState(false);

  const exerciseDummyData = {
    id: null,
    exerciseId: null,
    setReps: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0
    }
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    isActive: props ? props.isActive : null,
    alert: false,
    severity: "success",
    errorMessage: "",
    editData: null,
    editDataId: null,
    editDataName: null
  });

  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [opensuppTemplateDialogBox, setOpensuppTemplateDialogBox] = useState(
    false
  );

  const [exerciseForm, setExerciseForm] = useState({
    scheduleId: urlParams.get("s"),
    clientId: urlParams.get("c"),
    workoutDay: props["location"]["state"]
      ? props["location"]["state"].day
        ? props["location"]["state"].day
        : 1
      : 1,
    muscleGroupId: null,
    exerciseList: {
      1: exerciseDummyData /** This is the initial data */
    }
  });

  const [workoutDayList] = useState(Array.from({ length: 7 }, (_, i) => i + 1));
  const [filter, setFilter] = useState({
    _sort: "template_name:asc"
  });
  const hasError = field => (formState.errors[field] ? true : false);

  const convertTableData = res => {
    let arr = [];
    if (res && res.length > 0) {
      for (let i in res) {
        let tempArr = {};
        tempArr["id"] = res[i].id;
        tempArr["name"] = res[i].template_name;

        arr.push(tempArr);
      }
      return arr;
    } else {
      return [];
    }
  };

  const getExerciseData = async (page, pageSize) => {
    /**
     ** It sets the page and pageSize in filter variable
     ** so as to keep the record of what page and pageSize was set
     */
    let apiUrl = getWorkoutTemplateForFranchise;

    setFilter(filter => ({
      ...filter,
      page: page,
      pageSize: pageSize
    }));
    let params = { page: page, pageSize: pageSize };
    Object.keys(filter).map(res => {
      if (!params.hasOwnProperty(res)) {
        params[res] = filter[res];
      }
      return null;
    });
    return new Promise((resolve, reject) => {
      fetch(apiUrl + "?" + new URLSearchParams(params), {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth.getToken()
        }
      })
        .then(response => response.json())
        .then(result => {
          resolve({
            data: convertTableData(result.data),
            page: result.page - 1,
            totalCount: result.totalCount
          });
        });
    });
  };

  const addDefaultData = () => {
    setExerciseForm(exerciseForm => ({
      ...exerciseForm,
      exerciseList: {
        1: exerciseDummyData /** This is the initial data */
      }
    }));
  };

  const getExerciseInfo = async (workoutDay = exerciseForm.workoutDay) => {
    setLoader(true);
    await providerForGet(
      getDataForWorkout,
      { scheduleId: exerciseForm.scheduleId, workoutDay: workoutDay },
      auth.getToken()
    )
      .then(res => {
        if (res.data.isPresent) {
          setExerciseForm(exerciseForm => ({
            ...exerciseForm,
            muscleGroupId: res.data.muscleGroupId,
            exerciseList: res.data.exerciseList
          }));
        } else {
          if (res.data.muscleGroupId) {
            setExerciseForm(exerciseForm => ({
              ...exerciseForm,
              muscleGroupId: res.data.muscleGroupId
            }));
          } else {
            setExerciseForm(exerciseForm => ({
              ...exerciseForm,
              muscleGroupId: offId
            }));
          }
          addDefaultData();
        }
        setLoader(false);
      })
      .catch(err => {
        addDefaultData();
        setLoader(false);
      });
  };

  const getInitialExerciseData = async () => {
    setLoader(true);

    await providerForGet(muscleGroup, { pageSize: -1 }, auth.getToken())
      .then(res => {
        let muscleId = null;

        res.data.data.map(r => {
          if (r.name === "Off") {
            setOffId(r.id);
            muscleId = r.id;
          }
          return null;
        });
        setMuscleGroups(res.data.data);
        if (res.data.data.length) {
          setExerciseForm(exerciseForm => ({
            ...exerciseForm,
            muscleGroupId: muscleId
          }));
        }
      })
      .catch(err => {
        console.log("err", err);
      });

    await providerForGet(
      exerciseForFranchise,
      { pageSize: -1 },
      auth.getToken()
    )
      .then(res => {
        setExerciseData(res.data);
        setLoader(false);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });
    getExerciseInfo();
  };



  useEffect(() => {
    getInitialExerciseData();
  }, []);

  const closeDialogBox = () => {
    setOpenDialogBox(false);
    setOpensuppTemplateDialogBox(false);
    delete formState.values["exerciseName"];
  };

  const handleLoadTemplate = async id => {
    setLoader(true);
    await providerForPost(
      useTemplate,
      {
        workoutTemplateId: id,
        scheduleId: exerciseForm.scheduleId,
        c: exerciseForm.clientId
      },
      auth.getToken(),
      {
        desc: "Use exercise template",
        audit_log_clientid: exerciseForm.clientId
      }
    )
      .then(res => {
        closeDialogBox();
        setFormState(formState => ({
          ...formState,
          editData: true,
          editDataId: id,
          editDataName: null
        }));
        getInitialExerciseData();
        setLoader(false);
      })
      .catch(err => {
        addDefaultData();
        setLoader(false);
      });
  };

  const handleSaveFavorites = () => {
    setOpensuppTemplateDialogBox(true);
  };

  const handleLoadFavorites = () => {
    setOpenDialogBox(true);
  };

  const handleExerciseChange = (
    isAutoComplete,
    isTextBox,
    numbers,
    eventName,
    event,
    value,
    exerciseNo
  ) => {
    if (isAutoComplete) {
      if (value) {
        if (exerciseForm.exerciseList[exerciseNo]) {
          setExerciseForm(exerciseForm => ({
            ...exerciseForm,
            exerciseList: {
              ...exerciseForm.exerciseList,
              [exerciseNo]: {
                ...exerciseForm.exerciseList[exerciseNo],
                exerciseId: value.id
              }
            }
          }));
        } else {
          setExerciseForm(exerciseForm => ({
            ...exerciseForm,
            exerciseList: {
              ...exerciseForm.exerciseList,
              [exerciseNo]: {
                ...exerciseForm.exerciseList[exerciseNo],
                id: null,
                exerciseId: value.id,
                setReps: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0
                }
              }
            }
          }));
        }
      } else {
        setExerciseForm(exerciseForm => ({
          ...exerciseForm,
          exerciseList: {
            ...exerciseForm.exerciseList,
            [exerciseNo]: {
              ...exerciseForm.exerciseList[exerciseNo],
              id: null,
              exerciseId: null,
              setReps: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0
              }
            }
          }
        }));
      }
    } else if (isTextBox) {
      setExerciseForm(exerciseForm => ({
        ...exerciseForm,
        exerciseList: {
          ...exerciseForm.exerciseList,
          [exerciseNo]: {
            ...exerciseForm.exerciseList[exerciseNo]
          }
        }
      }));
    } else if (numbers) {
      if (parseInt(value)) {
        setExerciseForm(exerciseForm => ({
          ...exerciseForm,
          exerciseList: {
            ...exerciseForm.exerciseList,
            [exerciseNo]: {
              ...exerciseForm.exerciseList[exerciseNo],
              setReps: {
                ...exerciseForm.exerciseList[exerciseNo].setReps,
                [eventName]: parseInt(value)
              }
            }
          }
        }));
      } else {
        setExerciseForm(exerciseForm => ({
          ...exerciseForm,
          exerciseList: {
            ...exerciseForm.exerciseList,
            [exerciseNo]: {
              ...exerciseForm.exerciseList[exerciseNo],
              setReps: {
                ...exerciseForm.exerciseList[exerciseNo].setReps,
                [eventName]: 0
              }
            }
          }
        }));
      }
    }
  };

  const checkIfDataProper = (isForDelete = false) => {
    let isValid = true;
    if (isForDelete) {
      let arr = Object.keys(exerciseForm.exerciseList);
      if (arr.length === 1 && !exerciseForm.exerciseList[arr[0]].exerciseId) {
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      let arr = Object.keys(exerciseForm.exerciseList);
      arr.map(i => {
        if (!exerciseForm.exerciseList[i].exerciseId) {
          isValid = false;
        }
        return null;
      });
    }
    return isValid;
  };

  const handleAddExercise = () => {
    if (checkIfDataProper()) {
      let highest = Object.keys(exerciseForm.exerciseList)
        .sort(function (a, b) {
          return a - b;
        })
        .pop();
      setExerciseForm(exerciseForm => ({
        ...exerciseForm,
        exerciseList: {
          ...exerciseForm.exerciseList,
          [parseInt(highest) + 1]: exerciseDummyData
        }
      }));
    }
  };

  const handelDeleteExercise = event => {
    let arr = Object.keys(exerciseForm.exerciseList);
    if (arr.length === 1) {
      setExerciseForm(exerciseForm => ({
        ...exerciseForm,
        exerciseList: {
          ...exerciseForm.exerciseList,
          [event]: {
            ...exerciseForm.exerciseList[event],
            id: null,
            exerciseId: null,
            setReps: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
              9: 0
            }
          }
        }
      }));
    } else {
      delete exerciseForm.exerciseList[event];
      setExerciseForm(exerciseForm => ({
        ...exerciseForm
      }));
    }
  };

  const handleSave = async (isSave = false) => {
    if (isSave) {
      setLoader(true);
    }
    await providerForPost(
      addUpdateExerciseData,
      {
        scheduleId: exerciseForm.scheduleId,
        workoutDay: exerciseForm.workoutDay,
        exerciseData: exerciseForm.exerciseList,
        muscle_group: exerciseForm.muscleGroupId,
        clientId: exerciseForm.clientId
      },
      auth.getToken(),
      {
        desc: "Add/Update exercise for a schedule",
        audit_log_clientid: exerciseForm.clientId
      }
    )
      .then(res => {
        if (isSave) {
          setLoader(false);
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isOpenSnackBar: true,
            isError: "success",
            successErrorMessage: "Exercise data " + SAVE_MESSAGE
          }));
        }
      })
      .catch(error => {
        if (isSave) {
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isSaving: false,
            savedTime: null,
            isOpenSnackBar: true,
            isError: "error",
            successErrorMessage: ERROR_MESSAGE + error
          }));
          setLoader(false);
        }
      });
  };

  const handleChange = event => {
    setLoader(true);
    handleSave();
    setExerciseForm(exerciseForm => ({
      ...exerciseForm,
      workoutDay: event.target.value
    }));
    getExerciseInfo(event.target.value);
  };

  const handleChangeMuscleGroup = (event, value) => {
    setExerciseForm(exerciseForm => ({
      ...exerciseForm,
      muscleGroupId: value ? value.id : offId
    }));
    if (!value) {
      setExerciseForm(exerciseForm => ({
        ...exerciseForm,
        exerciseList: {
          1: exerciseDummyData /** This is the initial data */
        }
      }));
    }
  };

  const generateReport = async isPrint => {
    setLoader(true);
    await handleSave();
    await providerForDownload(
      createExerciseReport,
      {
        schedule_id: exerciseForm.scheduleId,
        c: exerciseForm.clientId
      },
      auth.getToken(),
      {
        desc: "Generate report for exercise",
        audit_log_clientid: exerciseForm.clientId
      }
    )
      .then(res => {
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          window.open(url,'_blank');
          // const pdfNewWindow = window.open();
          // pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Workout_program.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const handleChangeInput = e => {
    e.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));
    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const handleUpdateTemplate = async () => {
    setFormState(formState => ({
      ...formState,
      editData: true
    }));
    setLoader(true);
    await providerForPost(
      saveFavoriteExercise,
      {
        workoutTemplateId: formState.editDataId,
        scheduleId: exerciseForm.scheduleId,
        c: exerciseForm.clientId
      },
      auth.getToken(),
      {
        desc: "Add/Update exercise template data",
        audit_log_clientid: exerciseForm.clientId
      }
    )
      .then(res => {
        closeDialogBox();
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          isSaving: false,
          savedTime: new Date(),
          isOpenSnackBar: true,
          isError: "success",
          successErrorMessage: "Exercise Favorite " + UPDATE_MESSAGE
        }));
        setFormState(formState => ({
          ...formState,
          editData: null,
          editDataId: null,
          editDataName: null
        }));
        setLoader(false);
      })
      .catch(err => {
        addDefaultData();
        setLoader(false);
      });
  };

  const handleCreateNewTemplate = () => {
    setFormState(formState => ({
      ...formState,
      editData: false,
      editDataId: null,
      editDataName: null
    }));
  };

  const handleOk = async () => {
    setLoader(true);
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      addExerciseForm
    );

    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, addExerciseForm);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        addExerciseForm
      );
      formState.errors = setErrors(formState.values, addExerciseForm);
    }
    if (isValid) {
      await providerForPost(
        saveFavoriteExercise,
        {
          workoutTemplateId: null,
          scheduleId: exerciseForm.scheduleId,
          template_name: formState.values.exerciseName,
          franchiseId: user.franchise ? user.franchise.id : null,
          c: exerciseForm.clientId
        },
        auth.getToken(),
        {
          desc: "Add/Update exercise template data",
          audit_log_clientid: exerciseForm.clientId
        }
      )
        .then(res => {
          closeDialogBox();
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isSaving: false,
            savedTime: new Date(),
            isOpenSnackBar: true,
            isError: "success",
            successErrorMessage: "Exercise Favorite " + SAVE_MESSAGE
          }));

          setFormState(formState => ({
            ...formState,
            editData: null,
            editDataId: null,
            editDataName: null
          }));
          setLoader(false);
        })
        .catch(error => {
          //add error
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              isSaving: false,
              isOpenSnackBar: true,
              isError: "error",
              successErrorMessage: ERROR_MESSAGE + error.response.data.message
            }));
          }
          addDefaultData();
          setLoader(false);
        });
    }
  };

  const handleSnackbarClose = () => {
    setStatusVariable(statusVariable => ({
      ...statusVariable,
      isOpenSnackBar: false,
      isError: "",
      successErrorMessage: ""
    }));
  };

  return (
    <>
      <Prompt
        message={(location, action) =>
          props && props.isButtonClicked ? null : handleSave()
        }
      />
      <Grid container className={localClasses.root} spacing={2}>
        <Grid item xs={12} md={7} lg={7}>
          <Grid
            item
            // className={localClasses.duplicateReplicateStyle}
            xs={12}
            md={12}
            lg={12}
            className={localClasses.favouriteClass}
          >
            <WhiteIconButton
              variant="contained"
              size="small"
              style={{
                //marginTop: theme.spacing(3),
                marginRight: theme.spacing(1)
                //marginLeft: theme.spacing(1)
              }}
              disabled={formState.isActive === false}
              onClick={() => handleLoadFavorites()}
            >
              LOAD FAVORITES
            </WhiteIconButton>
            <WhiteIconButton
              variant="contained"
              size="small"
              style={{
                //marginTop: theme.spacing(3),
                marginRight: theme.spacing(1),
                marginLeft: theme.spacing(1)
              }}
              disabled={formState.isActive === false}
              onClick={() => handleSaveFavorites()}
            >
              SAVE FAVORITES
            </WhiteIconButton>
          </Grid>
        </Grid>
        <Grid
          item
          className={localClasses.favoritesOption}
          xs={12}
          md={5}
          lg={5}
        >
          <div className={localClasses.cancelSaveButton}>
            <IconButton
              onClick={() => generateReport(true)}
              style={{ marginRight: 15, padding: 0, background: "none" }}
            >
              <Tooltip title="Print Report">
                <PrintIcon style={{ color: "#1C4979", fontSize: 30 }} />
              </Tooltip>
            </IconButton>
            <IconButton
              variant="contained"
              size="small"
              onClick={() => generateReport(false)}
            >
              <Tooltip title="Download Report">
                <GetAppIcon style={{ color: "#1C4979", fontSize: 30 }} />
              </Tooltip>
            </IconButton>
            <DarkBlueButton
              variant="contained"
              color="primary"
              size="small"
              style={{
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1)
              }}
              disabled={formState.isActive === false}
              onClick={() => handleSave(true)}
            >
              Save
            </DarkBlueButton>
          </div>
        </Grid>
        <DialogBox
          open={openDialogBox}
          title="Select Exercise Favorite"
          buttonCancel="Cancel"
          handleCancel={closeDialogBox}
          skipOk={true}
        >
          <Table
            tableRef={tableRef}
            columns={columns}
            data={async query => {
              return await getExerciseData(query.page + 1, query.pageSize);
            }}
            actions={[
              {
                icon: () => <LightGrayButton>Use</LightGrayButton>,
                tooltip: "Use this favorite",
                onClick: (event, rowData) => {
                  handleLoadTemplate(rowData.id);
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              search: false,
              sorting: false,
              pageSize: 5,
              thirdSortClick: false,
              headerStyle: {
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "#8A8A97",
                borderBottom: "solid #E0E0E0 2px",
                fontSize: "1rem"
              },
              cellStyle: {
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "#000000",
                fontSize: "1rem"
              },
              paginationStyle: {
                justifyContent: "center"
              }
            }}
          />
        </DialogBox>
        <DialogBox
          open={opensuppTemplateDialogBox}
          title="Save Exercise Favorite"
          buttonCancel="Cancel"
          handleCancel={closeDialogBox}
          skipOk={formState.editData === true ? true : false}
          buttonOk={formState.editData === true ? null : "Ok"}
          handleOk={formState.editData === true ? null : handleOk}
        >
          {formState.editData === true ? (
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Typography gutterBottom>
                  Update existing exercise favorite?
                </Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <DarkBlueButton variant={"contained"} onClick={handleUpdateTemplate}>
                  Ok
                </DarkBlueButton>
              </Grid>
              <Grid item md={6} xs={12}>
                <DarkBlueButton
                  variant={"contained"}
                  onClick={handleCreateNewTemplate}
                >
                  Create new
                </DarkBlueButton>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Input
                  label={get(addExerciseForm[exerciseName], "label")}
                  name={exerciseName}
                  value={formState.values[exerciseName] || ""}
                  error={hasError(exerciseName)}
                  placeholder={get(
                    addExerciseForm[exerciseName],
                    "placeholder"
                  )}
                  variant="outlined"
                  required
                  fullWidth
                  onChange={handleChangeInput}
                  helperText={
                    hasError(exerciseName)
                      ? formState.errors[exerciseName].map(error => {
                          return error + " ";
                        })
                      : null
                  }
                />
              </Grid>
            </Grid>
          )}
        </DialogBox>

        <Grid
          item
          className={localClasses.duplicateReplicateStyle}
          xs={12}
          md={12}
          lg={12}
        >
          <AutoCompleteDropDown
            className={localClasses.formControl}
            id="Muscle Group"
            fullWidth={false}
            options={muscleGroups ? muscleGroups : []}
            getOptionLabel={option => option.name}
            style={{ width: width / 7 }}
            disabled={formState.isActive === false}
            value={
              muscleGroups[
                muscleGroups.findIndex(function (item, i) {
                  return item.id === exerciseForm.muscleGroupId;
                })
              ] || null
            }
            onChange={(event, value) => {
              handleChangeMuscleGroup(event, value);
            }}
            renderInput={params => (
              <Input {...params} label="Muscle Group" variant="outlined" />
            )}
          />
          <SelectDropdown
            title="Workout for day"
            value={exerciseForm.workoutDay}
            onChange={handleChange}
          >
            {workoutDayList.map(day => (
              <MenuItem key={day} value={day}>
                {day}
              </MenuItem>
            ))}
          </SelectDropdown>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          className={localClasses.workoutDayGrid}
        >
          <ExerciseGrid
            isDesktop={isDesktop}
            exerciseData={exerciseData}
            exerciseList={exerciseForm.exerciseList}
            handleExerciseChange={handleExerciseChange}
            handleAddExercise={handleAddExercise}
            handelDeleteExercise={handelDeleteExercise}
            checkIfDataProper={checkIfDataProper}
            isActive={formState.isActive}
            muscleGroupData={
              muscleGroups[
                muscleGroups.findIndex(function (item, i) {
                  return item.id === exerciseForm.muscleGroupId;
                })
              ] || null
            }
          />
          <Backdrop className={localClasses.backdrop} open={loader}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
        <Grid>
          <SnackBar
            open={statusVariable.isOpenSnackBar}
            severity={statusVariable.isError}
            onClose={handleSnackbarClose}
          >
            {statusVariable.successErrorMessage}
          </SnackBar>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkoutSchedule;
