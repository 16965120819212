import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Auth,
  AutoCompleteDropDown,
  Input,
  SnackBar,
  Table,
  DarkBlueButton,
  BorderLessButton
} from "../../components";
import styles from "./Food.module.css";
import { food, franchise, foodForFranchise } from "../../constants";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { providerForDelete, providerForGet } from "../../api";
import useStyles from "../../utils/Styles/ManagePageStyles";
import { ADDFOOD, EDITFOOD } from "../../paths";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import {
  DELETE_MESSAGE,
  ERROR_MESSAGE
} from "../../constants/genericConstants";
import {
  checkIfDataAailableForFood,
  foodCategory,
  foodSubCategory,
  franchiseListForAdmin
} from "../../constants/urlConstants";

const Food = props => {
  const propData = props["location"]["state"];
  const history = useHistory();
  const classes = useStyles();
  const [filter, setFilter] = useState({
    _sort: "name:asc"
  });
  const tableRef = React.createRef();
  const auth = Auth.getToken();
  const userInfo = Auth.getUserInfo();
  const [isAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_ADMIN_ROLE_NAME ? true : false
  );
  const [isSuperAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
      ? true
      : false
  );
  let apiUrl = "";
  const columns = [
    { title: "Name", field: "name" },
    { title: "Unit", field: "unit" },
    { title: "Calories", field: "calories" },
    { title: "Proteins", field: "proteins" },
    { title: "Fats", field: "fats" },
    { title: "Carbs", field: "carbs" },
    { title: "Food Category", field: "food_category", sorting: false },
    { title: "Food Sub Category ", field: "food_sub_category", sorting: false },
    { title: "Portion/Unit", field: "portion_unit", sorting: false },
    {
      title: "Franchise",
      field: "franchise",
      sorting: false
    }
  ];
  const [formState, setFormState] = useState({
    dataTable: {
      data: [],
      page: 1,
      totalCount: 0
    },
    alert: propData ? propData["successMessage"] : false,
    errorMessage: propData ? propData["successMessage"] : false,
    severity: "success",
    isLoad: false,
    isAdmin: false,
    franchiseList: [],
    foodCategoryList: [],
    foodSubCategoryList: []
  });

  const getListData = async () => {
    let body = {};
    if (isAdmin) {
      await providerForGet(franchiseListForAdmin, body, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            franchiseList: res.data
          }));
        })
        .catch(err => {
          console.log("err", err);
        });
    } else {
      await providerForGet(franchise, body, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            franchiseList: res.data.data
          }));
        })
        .catch(err => {
          console.log("err", err);
        });
    }
    let params = { pageSize: -1 };

    //food Category list data
    await providerForGet(
      foodCategory,
      { pageSize: -1, _sort: "name:asc" },
      auth
    )
      .then(res => {
        setFormState(formState => ({
          ...formState,
          foodCategoryList: res.data.data
        }));
      })
      .catch(err => {
        console.log("err", err);
      });

    //food Sub Category list data
    await providerForGet(foodSubCategory, params, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          foodSubCategoryList: res.data.data
        }));
      })
      .catch(err => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getListData();
  }, []);

  /**
   ** Use key names exactly same as API response,they'll be used for filtering & sorting purpose
   * */
  const convertTableData = res => {
    let arr = [];
    if (res && res.length > 0) {
      for (let i in res) {
        let tempArr = {};
        tempArr["id"] = res[i].id;
        tempArr["name"] = res[i].name;
        tempArr["unit"] = res[i].unit;
        tempArr["calories"] = res[i].calories;
        tempArr["proteins"] = res[i].proteins;
        tempArr["fats"] = res[i].fats;
        tempArr["carbs"] = res[i].carbs;
        tempArr["franchise"] = res[i].franchise
          ? res[i].franchise.franchise_name
          : "";
        tempArr["isGlobal"] = res[i].isGlobal ? res[i].isGlobal : false;
        tempArr["food_category_id"] = res[i].food_category
          ? res[i].food_category.id
          : "";
        tempArr["food_category"] = res[i].food_category
          ? res[i].food_category.name
          : "";
        tempArr["food_sub_category"] = res[i].food_sub_category
          ? res[i].food_sub_category.name
          : "";
        tempArr["portion_unit"] = res[i].portion_unit
          ? res[i].portion_unit.name
          : "";
        arr.push(tempArr);
      }
      return arr;
    } else {
      return [];
    }
  };

  const getFoodData = async (page, pageSize) => {
    /**
     ** It sets the page and pageSize in filter variable
     ** so as to keep the record of what page and pageSize was set
     */
    if (isSuperAdmin) {
      apiUrl = food;
    } else {
      apiUrl = foodForFranchise;
    }
    setFilter(filter => ({
      ...filter,
      page: page,
      pageSize: pageSize
    }));

    let params = {
      page: page,
      pageSize: pageSize
    };
    Object.keys(filter).map(res => {
      if (!params.hasOwnProperty(res)) {
        params[res] = filter[res];
      }
      return null;
    });
    params.desc = `${userInfo.full_name} viewed the food listing page`;
    return new Promise((resolve, reject) => {
      fetch(apiUrl + "?" + new URLSearchParams(params), {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth
        }
      })
        .then(response => response.json())
        .then(result => {
          resolve({
            data: convertTableData(result.data),
            page: result.page - 1,
            totalCount: result.totalCount
          });
        });
    });
  };

  const handleClickOpen = rowData => {
    let data = {};
    data["id"] = rowData.id;

    history.push(EDITFOOD, data);
  };

  const handleFilterChange = event => {
    if (event.target.value !== "") {
      setFilter(filter => ({
        ...filter,
        [event.target.name]: event.target.value
      }));
    } else {
      delete filter[event.target.name];
      setFilter(filter => ({
        ...filter
      }));
    }
  };

  const handleFilterAutoCompleteChange = (eventName, event, value) => {
    if (value !== null) {
      setFilter(filter => ({
        ...filter,
        [eventName]: value.id
      }));
    } else {
      delete filter[eventName];
      setFilter(filter => ({
        ...filter
      }));
    }
  };

  const orderFunc = (columnId, direction) => {
    let orderByColumn;
    let orderBy = "";
    if (columnId >= 0) {
      orderByColumn = columns[columnId]["field"];
    }
    orderBy = orderByColumn + ":" + direction;
    setFilter(filter => ({
      ...filter,
      _sort: orderBy
    }));
    tableRef.current.onQueryChange();
  };

  const handleReset = event => {
    delete filter["name_contains"];
    delete filter["franchise"];
    delete filter["food_sub_category"];
    delete filter["food_category"];
    setFilter(filter => ({
      ...filter
    }));
    getFoodData(1, filter.pageSize);
    tableRef.current.onQueryChange();
  };

  const handleSnackbarClose = () => {
    if (propData) {
      propData["successMessage"] = false;
    }
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "success"
    }));
  };

  return (
    <div>
      <Typography variant="h4" className={styles.titleText}>
        Manage Food
      </Typography>
      <Grid>
        <SnackBar
          open={formState.alert}
          severity={formState.severity}
          onClose={handleSnackbarClose}
        >
          {formState.errorMessage}
        </SnackBar>
      </Grid>

      <Grid container spacing={2}>
        <Grid item sm={12} className={classes.addButton}>
          <DarkBlueButton
            onClick={() => {
              history.push(ADDFOOD);
            }}
          >
            <AddIcon /> &nbsp; Add Food{" "}
          </DarkBlueButton>
        </Grid>
      </Grid>

      <Card className={classes.paperFilter}>
        <CardContent className={classes.Cardtheming}>
          <Grid className={classes.filterOptions} container spacing={2}>
            <Grid item>
              <Input
                label={"Name"}
                placeholder="Name"
                name="name_contains"
                value={filter.name_contains ? filter.name_contains : ""}
                variant="outlined"
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid item>
              <AutoCompleteDropDown
                options={formState.foodCategoryList}
                getOptionLabel={option => option.name}
                id={"foodCategoryName"}
                value={
                  formState.foodCategoryList[
                    formState.foodCategoryList.findIndex(function (item, i) {
                      return item.id === filter.food_category;
                    })
                  ] || null
                }
                onChange={(event, value) => {
                  handleFilterAutoCompleteChange("food_category", event, value);
                }}
                renderInput={params => (
                  <Input
                    {...params}
                    name="food_category"
                    style={{ "min-width": "200px" }}
                    id={"foodCategoryName"}
                    label={"Food Category"}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <AutoCompleteDropDown
                options={formState.foodSubCategoryList}
                getOptionLabel={option => option.name}
                id={"foodSubCategoryName"}
                value={
                  formState.foodSubCategoryList[
                    formState.foodSubCategoryList.findIndex(function (item, i) {
                      return item.id === filter.food_sub_category;
                    })
                  ] || null
                }
                onChange={(event, value) => {
                  handleFilterAutoCompleteChange(
                    "food_sub_category",
                    event,
                    value
                  );
                }}
                renderInput={params => (
                  <Input
                    {...params}
                    name="food_sub_category"
                    style={{ "min-width": "200px" }}
                    id={"foodSubCategoryName"}
                    label={"Food Sub Category"}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <AutoCompleteDropDown
                options={formState.franchiseList}
                getOptionLabel={option => option.franchise_name}
                id={"franchiseName"}
                value={
                  formState.franchiseList[
                    formState.franchiseList.findIndex(function (item, i) {
                      return item.id === filter.franchise;
                    })
                  ] || null
                }
                onChange={(event, value) => {
                  handleFilterAutoCompleteChange("franchise", event, value);
                }}
                renderInput={params => (
                  <Input
                    {...params}
                    name="franchise"
                    style={{ "min-width": "150px" }}
                    id={"franchiseName"}
                    label={"Franchise"}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <DarkBlueButton
                disableElevation
                onClick={() => {
                  setFormState(formState => ({
                    ...formState,
                    isLoad: true
                  }));
                  tableRef.current.onQueryChange();
                }}
              >
                search
              </DarkBlueButton>
            </Grid>
            <Grid item>
              <BorderLessButton onClick={handleReset} disableElevation>
                reset{" "}
              </BorderLessButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Table
        tableRef={tableRef}
        columns={columns}
        data={async query => {
          return await getFoodData(query.page + 1, query.pageSize);
        }}
        localization={{
          body: {
            editRow: {
              deleteText: `Are you sure you want to delete this Food?`,
              saveTooltip: "Delete"
            }
          }
        }}
        actions={[
          rowData => ({
            icon: () => <EditOutlinedIcon />,
            tooltip:
              isAdmin && rowData.isGlobal
                ? "Created by superadmin. Cannot edit"
                : "Edit",
            onClick: (event, rowData) => {
              handleClickOpen(rowData);
            },
            disabled: isAdmin && rowData.isGlobal
          })
        ]}
        editable={{
          isDeletable: rowData =>
            (!rowData.isGlobal && isAdmin) || isSuperAdmin,
          onRowDelete: oldData =>
            new Promise(async resolve => {
              let query = { food: oldData.id };
              await providerForGet(checkIfDataAailableForFood, query, auth)
                .then(res => {
                  if (res.data.data) {
                    setFormState(formState => ({
                      ...formState,
                      alert: true,
                      errorMessage: "cannot delete ! as this food data is used",
                      severity: "error"
                    }));
                    resolve();
                  } else {
                    setTimeout(async () => {
                      await providerForDelete(food, oldData.id, auth, {
                        desc: `${userInfo.full_name} deleted a food item`,
                        food_category: oldData.food_category_id
                      })
                        .then(res => {
                          setFormState(formState => ({
                            ...formState,
                            alert: true,
                            errorMessage: res.data.name + DELETE_MESSAGE,
                            severity: "success"
                          }));
                        })
                        .catch(err => {
                          console.log("error", err);
                          setFormState(formState => ({
                            ...formState,
                            alert: true,
                            errorMessage: ERROR_MESSAGE + err,
                            severity: "error"
                          }));
                        });
                      resolve();
                    }, 1000);
                  }
                })
                .catch(err => {
                  console.log("error", err);
                });
            })
        }}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          sorting: true,
          thirdSortClick: false
        }}
        onOrderChange={(orderedColumnId, orderDirection) => {
          orderFunc(orderedColumnId, orderDirection);
        }}
      />
    </div>
  );
};

Food.propTypes = {};

Food.defaultProps = {};

export default Food;
