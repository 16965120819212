// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddEditMaritalStatus_titleText__3-RQx {\n  color: #110f48;\n  font-size: 1.25rem !important;\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 500 !important;\n  margin-bottom: 15px !important;\n  padding: 8px !important;\n}\n\n.AddEditMaritalStatus_editCard__1vVJP {\n  box-shadow: none !important;\n}\n.AddEditMaritalStatus_editCardContent__3X4TW {\n  padding: 8px !important;\n}\n", "",{"version":3,"sources":["webpack://src/containers/Users/MaritalStatus/AddEditMaritalStatus/AddEditMaritalStatus.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,6BAA6B;EAC7B,8CAA8C;EAC9C,2BAA2B;EAC3B,8BAA8B;EAC9B,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;AAC7B;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".titleText {\n  color: #110f48;\n  font-size: 1.25rem !important;\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 500 !important;\n  margin-bottom: 15px !important;\n  padding: 8px !important;\n}\n\n.editCard {\n  box-shadow: none !important;\n}\n.editCardContent {\n  padding: 8px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleText": "AddEditMaritalStatus_titleText__3-RQx",
	"editCard": "AddEditMaritalStatus_editCard__1vVJP",
	"editCardContent": "AddEditMaritalStatus_editCardContent__3X4TW"
};
export default ___CSS_LOADER_EXPORT___;
