import { React, useState, useContext } from "react";
import {
  Typography,
  CssBaseline,
  Container,
  Link,
  FormHelperText,
  CircularProgress,
  AppBar,
  Toolbar,
  Card,
  CardContent
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OtpInput from "react-otp-input";
import { DarkBlueButton, Auth as auth } from "../../components";
import Styles from "./VerifyCode.module.css";
import { useHistory } from "react-router-dom";
import {
  MANAGECLIENTS,
  FRANCHISE,
  USERS,
  CLIENTDASHBAORD,
  LOGOUT
} from "../../paths/Paths";
import {
  checkAllKeysPresent,
  getListOfKeysNotPresent,
  setErrors,
  checkEmpty
} from "../../utils";
import verifycodeschema from "./verifycodeschema.json";
import { providerForPost } from "../../api";
import {
  verifyOtpForTwoFAURL,
  requestOtpTwoFAURL,
  apiBackendUrl
} from "../../constants";
import Logo from "../../components/Logo/Logo";
import { AuthContext } from "../../context";

const useStyles = makeStyles(theme => ({
  container: {
    margin: "117px auto 50px auto",
    maxWidth: "600px",
    height: "100%",
    width: "100%"
  },

  otpinput: {
    margin: theme.spacing(3, 0, 2),
    width: "40px !important;",
    height: "50px"
  },
  otpError: {
    "font-size": "larger",
    "text-align": "center"
  },
  paper: {
    // marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  Heading: {
    marginBottom: theme.spacing(2)
  },
  txtClass: {
    textAlign: "center",
    fontWeight: "500",
    fontFamily: "Montserrat"
  },

  dont_resend: {
    fontSize: "0.9375rem",
    fontFamily: "Montserrat",
    padding: "5px"
  },

  resend: {
    fontSize: "0.875rem",
    fontFamily: "Montserrat"
  },

  txtClassotpvalid: {
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "13px",
    padding: "3px"
  },

  txtClass2: {
    textAlign: "center",
    fontWeight: "600",
    fontFamily: "Montserrat"
  },
  avatar: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    position: "relative"
  }
}));

const VerifyCode = props => {
  const [loading, setLoading] = useState(false);
  const otp = "otp";
  const classes = useStyles();
  const history = useHistory();
  const [isErrorWhileVerifyingOTP, setIsErrorWhileVerifyingOTP] = useState(
    false
  );
  const [isErrorWhileResendingOTP, setIsErrorWhileResendingOTP] = useState(
    false
  );
  const [otpStatusMessage, setOtpStatusMessage] = useState("");
  /** Use state for form */
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    errors: {},
    identifierPresent: props.location.identifier,
    authMethodPresent: props.location.authMethod,
    token: props.location.token,
    userData: props.location.userData,
    resendOTP: false,
    status: props.location.status
  });
  const { setUserInfo } = useContext(AuthContext);

  if (!formState.authMethodPresent || formState.authMethodPresent === null) {
    history.push({
      pathname: LOGOUT
    });
  }

  const handleChange = otpValue => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [otp]: otpValue
      }
    }));
    delete formState.errors[otp];
    setIsErrorWhileVerifyingOTP(false);
  };

  const hasError = field => {
    return formState.errors[field] ? true : false;
  };

  const reSendOtpToIdentifier = async () => {
    setIsErrorWhileVerifyingOTP(false);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [otp]: ""
      }
    }));
    let payload = {
      reason: "2FA",
      status: formState.status
    };
    await providerForPost(requestOtpTwoFAURL, payload, formState.token, {
      desc: "Request OTP for 2FA"
    })
      .then(res => {
        setFormState(formState => ({
          ...formState,
          identifierPresent: res.data,
          resendOTP: true
        }));
      })
      .catch(error => {
        console.log("Error", error);
        setIsErrorWhileResendingOTP(true);
        setOtpStatusMessage(
          error.response && error.response.data && error.response.data.message
        );
      });
  };

  const verifyCode = () => {
    let payload = {
      otpEntered: formState.values.otp,
      status: formState.status
    };
    providerForPost(verifyOtpForTwoFAURL, payload, formState.token, {
      desc: "Verify OTP for 2FA"
    })
      .then(res => {
        setLoading(false);
        setUserInfo(userInfo => ({
          ...userInfo,
          user: {
            ...userInfo.user,
            name: props.location.userData.full_name,
            logo: props.location.logo
              ? apiBackendUrl + props.location.logo
              : null
          }
        }));
        console.log("verify code ", formState.userData);

        auth.setToken(formState.token, true);
        auth.setUserInfo(formState.userData, true);

        if (
          formState.userData.role.name ===
          process.env.REACT_APP_NUTRITIONIST_ROLE
        ) {
          history.push({
            pathname: MANAGECLIENTS
          });
        } else if (
          formState.userData.role.name ===
          process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
        ) {
          history.push({
            pathname: FRANCHISE
          });
        } else if (
          formState.userData.role.name === process.env.REACT_APP_ADMIN_ROLE_NAME
        ) {
          history.push({
            pathname: USERS
          });
        } else if (
          formState.userData.role.name ===
          process.env.REACT_APP_CLIENT_ROLE_NAME
        ) {
          history.push({
            pathname: CLIENTDASHBAORD
          });
        } else {
          history.push({
            pathname: LOGOUT
          });
        }
      })
      .catch(error => {
        setLoading(false);
        setFormState(formState => ({
          ...formState,
          resendOTP: false
        }));
        console.log("Error--->>>", error.response);
        setIsErrorWhileVerifyingOTP(true);
        setOtpStatusMessage(
          error.response && error.response.data && error.response.data.message
        );
      });
  };

  /** validateCode
   *  After valid OTP is entered, API call to verify OTP is done
   * @param myParam OTP
   */
  const validateCode = () => {
    setLoading(true);
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      verifycodeschema
    );
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, verifycodeschema);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        verifycodeschema
      );
      formState.errors = setErrors(formState.values, verifycodeschema);
    }
    if (isValid) {
      setFormState(formState => ({
        ...formState,
        isValid: true
      }));
      verifyCode();
    } else {
      setLoading(false);
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
    }
  };

  return (
    <>
      <AppBar position="fixed" className={Styles.loginAppBar} elevation={0}>
        <Toolbar className={Styles.loginToolbar}>
          <Logo className={Styles.loginLogo} />
        </Toolbar>
      </AppBar>
      <div>
        <Container component="main" maxWidth="xs" className={classes.container}>
          <Card variant="elevation" elevation={2}>
            <CardContent className={Styles.loginCardContent}>
              <CssBaseline />
              <div className={classes.paper}>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.titleText}
                >
                  Verification
                </Typography>
                {!formState.resendOTP ? (
                  <Typography
                    component="h6"
                    variant="subtitle1"
                    className={classes.txtClass}
                  >
                    An OTP is sent to{" "}
                    <span className={classes.txtClass2}>
                      {formState.identifierPresent}
                    </span>
                  </Typography>
                ) : (
                  <Typography
                    component="h6"
                    variant="subtitle1"
                    className={classes.txtClass}
                  >
                    {" "}
                    OTP successfully resend to{" "}
                    <span className={classes.txtClass2}>
                      {formState.identifierPresent}
                    </span>
                  </Typography>
                )}
                <form noValidate>
                  <div>
                    <OtpInput
                      value={formState.values[otp] ? formState.values[otp] : ""}
                      onChange={handleChange}
                      numInputs={6}
                      separator={<span> &nbsp; &nbsp;</span>}
                      inputStyle={classes.otpinput}
                      isInputNum
                    />
                    <Typography
                      component="h1"
                      // variant="h5"
                      // style={{ fontSize: "inherit", textAlign: "end" }}
                      className={classes.txtClassotpvalid}
                    >
                      OTP is valid for 5 minutes
                    </Typography>
                    <FormHelperText
                      className={classes.otpError}
                      error={
                        hasError(otp) ||
                        isErrorWhileVerifyingOTP ||
                        isErrorWhileResendingOTP
                      }
                      id="my-helper-text"
                    >
                      {hasError(otp)
                        ? formState.errors[otp].map(error => {
                            return error + " ";
                          })
                        : isErrorWhileVerifyingOTP
                        ? otpStatusMessage
                        : null}
                    </FormHelperText>
                  </div>
                  <div className={classes.wrapper}>
                    <DarkBlueButton
                      type="button"
                      fullWidth
                      // variant="contained"
                      // color="primary"
                      // className={classes.submit}
                      disabled={loading}
                      onClick={validateCode}
                    >
                      Verify
                    </DarkBlueButton>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </form>
                <Typography
                  component="h6"
                  // variant="subtitle1"
                  className={classes.dont_resend}
                >
                  Didn't recieve an OTP?{" "}
                  <span className={classes.resend}>
                    <Link variant="body2" onClick={reSendOtpToIdentifier}>
                      Resend an OTP
                    </Link>
                  </span>
                </Typography>{" "}
              </div>
            </CardContent>
          </Card>
        </Container>
        //
      </div>
    </>
  );
};

export default VerifyCode;
