import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Collapse
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import { sideDrawerAuthMenus } from "./menu";
import styles from "./SideMenus.module.css";
import { CHANGEPASSWORD } from "../../../paths";

const AuthMenu = props => {
  const [open, setOpen] = useState(false);
  const [openSub, setOpenSub] = useState(false);

  useEffect(() => {
    if (window.location.pathname === CHANGEPASSWORD) {
      setOpen(true);
    }
  }, []);

  const authMenuList = sideDrawerAuthMenus.map((menu, i) => {
    let currUrl = window.location.pathname;
    let pathName = currUrl.split("/")[1];
    let isSelected = menu.pathList.includes("/".concat(pathName));
    return (
      <List disablePadding>
        {menu.type ? (
          <ListItem
            button
            disableRipple={true}
            selected={open || isSelected}
            key={menu.icon}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText
              disableTypography
              className={clsx(styles.Text, {
                [styles.SelectedText]: isSelected,
                [styles.UnselectedText]: !isSelected
              })}
              primary={menu.title}
            />
            {menu.children ? open ? <ExpandLess /> : <ExpandMore /> : null}
          </ListItem>
        ) : (
          <ListItem
            button
            // disableRipple={true}
            selected={isSelected}
            className={clsx({
              [styles.OpenItem]: props.opened,
              [styles.ClosedItem]: !props.opened
            })}
            classes={{
              root: styles.IconItem,
              selected: styles.SelectedItem
            }}
            key={menu.icon}
            component={NavLink}
            to={menu.path}
            onClick={() => {
              setOpen(false);
              setOpenSub(false);
            }}
          >
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText
              disableTypography
              className={clsx(styles.Text, {
                [styles.SelectedText]: isSelected,
                [styles.UnselectedText]: !isSelected
              })}
              primary={menu.title}
            />
            {menu.children ? open ? <ExpandLess /> : <ExpandMore /> : null}
          </ListItem>
        )}
        {menu.type ? (
          <Collapse in={open} timeout="auto" unmountOnExit>
            {menu.children
              ? menu.children.map(subMenu => {
                  let currUrlSubList = window.location.pathname;
                  let pathNameSubList = currUrlSubList.split("/")[1];
                  let isSelectedSubList = subMenu.pathList.includes(
                    "/".concat(pathNameSubList)
                  );
                  return (
                    <List disablePadding>
                      {subMenu.type ? (
                        <div className={styles.SubChildren}>
                          <ListItem
                            button
                            disableRipple={true}
                            selected={openSub || isSelectedSubList}
                            className={clsx({
                              [styles.OpenItem]: props.opened,
                              [styles.ClosedItem]: !props.opened
                            })}
                            classes={{
                              root: styles.IconItem,
                              selected: styles.SelectedItem
                            }}
                            component={NavLink}
                            to={subMenu.path}
                            key={subMenu.icon}
                            onClick={() => {
                              setOpenSub(!openSub);
                            }}
                          >
                            <ListItemIcon>{subMenu.icon}</ListItemIcon>
                            <ListItemText
                              disableTypography
                              className={clsx(styles.Text, {
                                [styles.SelectedText]: isSelectedSubList,
                                [styles.UnselectedText]: !isSelectedSubList
                              })}
                              primary={subMenu.title}
                            />
                            {subMenu.children ? (
                              openSub ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )
                            ) : null}
                          </ListItem>
                        </div>
                      ) : (
                        <div className={styles.SubChildren}>
                          <ListItem
                            button
                            disableRipple={true}
                            selected={isSelectedSubList}
                            className={clsx({
                              [styles.OpenItem]: props.opened,
                              [styles.ClosedItem]: !props.opened
                            })}
                            classes={{
                              root: styles.IconItem,
                              selected: styles.SelectedItem
                            }}
                            key={subMenu.icon}
                            onClick={() => {
                              setOpenSub(false);
                            }}
                          >
                            <ListItemIcon>{subMenu.icon}</ListItemIcon>
                            <ListItemText
                              disableTypography
                              className={clsx(styles.Text, {
                                [styles.SelectedText]: isSelectedSubList,
                                [styles.UnselectedText]: !isSelectedSubList
                              })}
                              primary={subMenu.title}
                            />
                          </ListItem>
                        </div>
                      )}
                    </List>
                  );
                })
              : null}
          </Collapse>
        ) : null}
      </List>
    );
  });
  return <List className={styles.List}>{authMenuList}</List>;
};

AuthMenu.propTypes = {};

AuthMenu.defaultProps = {};

export default AuthMenu;
