import React, { useEffect, useState } from "react";
import styles from "../Client.module.css";
import moment from "moment";

import {
  Chip,
  Grid,
  Typography,
  Popover,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Backdrop,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import {
  Auth,
  SnackBar,
  Table,
  SearchBar,
  HeadingOne,
  DarkBlueButton,
  InlineDatePicker,
  Input
} from "../../../components";
import {
  generateGiveAllWithInserts,
  getClient,
  getCurrentScheduleOfClient,
  user,
  userTagForAdmin,
  verifyPassForLogs
} from "../../../constants";
import { useHistory } from "react-router-dom";
import { useStyles, getFormattedDate, onlyUnique } from "../../../utils";
import AddReports from "../../../assets/small_icons/Download give all.png";

import { ADDCLIENT, BODYMASS, EDITCLIENT } from "../../../paths";
import {
  providerForDownload,
  providerForGet,
  providerForPost,
  providerForPut
} from "../../../api";
import { ERROR_MESSAGE } from "../../../constants/genericConstants";
import "react-dropdown/style.css";
import PropTypes from "prop-types";

import Styles from "./../Client.module.css";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import DateRangeIcon from "@material-ui/icons/DateRange";
import TuneIcon from "@material-ui/icons/Tune";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import DialogBox from "../../../components/DialogBox/DialogBox";

const Franchise = props => {
  const oneTimeClientKey = "One Time Client";
  const joinedKey = "Filter by Joined";
  const lastVisitKey = "Filter by Last Visit";
  const password = "password";
  const classes = useStyles();
  const propData = props["location"]["state"];
  const userTagsFilter = "user_tags.id_in";
  const history = useHistory();
  const tableRef = React.createRef();
  const [enabledId, setEnabledId] = useState(null);
  const [openVerifyDialogBox, setOpenVerifyDialogBox] = useState(false);
  const [passNotPresent, setPassNotPresent] = React.useState(false);

  const auth = Auth.getToken();
  const [loader, setLoader] = React.useState(false);
  const [passLoader, setpassLoader] = React.useState(false);
  const [filter, setFilter] = useState({
    _sort: "last_visited:desc",
    page: 1,
    pageSize: 10
  });

  const [formState, setFormState] = useState({
    values: {},
    dataTable: {
      data: [],
      page: 1,
      totalCount: 0
    },
    isReportStatus: false,
    alert: propData ? propData["successMessage"] : false,
    errorMessage: propData ? propData["successMessage"] : false,
    severity: "success",
    isLoad: false,
    inValidPass: false,
    viewUserPhone: false,
    viewUserEmail: false,
    enableUserEmail: false,
    enableUserPhone: false,
    adminData: {},
    errors: {},
    filterList: [],
    dataToShowForMultiSelect: [],
    editPersonalNotesId: null
  });

  const onClickUserEmail = async (rowData, viewingUserEmail) => {
    setFormState(formState => ({
      ...formState,
      viewUserPhone: false,
      viewUserEmail: false,
      enableUserEmail: false,
      enableUserPhone: false,
      showPassword: false
    }));
    setEnabledId(rowData.id);
    setOpenVerifyDialogBox(true);
    if (viewingUserEmail) {
      setFormState(formState => ({ ...formState, viewUserEmail: true }));
    } else {
      setFormState(formState => ({ ...formState, viewUserPhone: true }));
    }
  };

  const onDisableUserEmail = async (rowData, disablingAccessorEmail) => {
    setEnabledId(null);
  };

  const columns = [
    { title: "Name", field: "full_name" },
    {
      title: "Email",
      field: "email",
      render: rowData => {
        return (
          <>
            {(rowData.userTagList && rowData.userTagList.includes("VVIP")) ||
            (rowData.userTagList && rowData.userTagList.includes("VIP")) ||
            rowData.maskAllUsers ? (
              enabledId &&
              enabledId === rowData.id &&
              formState.enableUserEmail ? (
                <>
                  {rowData.email}
                  <VisibilityOffIcon
                    style={{ textAlign: "center", color: "gray" }}
                    iconProps={classes.icon}
                    onClick={event => {
                      onDisableUserEmail(rowData, true);
                    }}
                  />
                </>
              ) : (
                <VisibilityIcon
                  style={{ textAlign: "center", color: "#1C4979" }}
                  onClick={event => {
                    onClickUserEmail(rowData, true);
                  }}
                />
              )
            ) : (
              <>{rowData.email}</>
            )}
          </>
        );
      },
      width: "100%"
    },
    {
      title: "Phone",
      field: "phone",
      render: rowData => {
        return (
          <>
            {(rowData.userTagList && rowData.userTagList.includes("VVIP")) ||
            (rowData.userTagList && rowData.userTagList.includes("VIP")) ||
            rowData.maskAllUsers ? (
              enabledId &&
              enabledId === rowData.id &&
              formState.enableUserPhone ? (
                <>
                  {rowData.phone
                    ? rowData.phone.includes("+")
                      ? rowData.phone
                      : `+${rowData.phone}`
                    : ""}
                  <VisibilityOffIcon
                    style={{ textAlign: "center", color: "gray" }}
                    iconProps={classes.icon}
                    onClick={event => {
                      onDisableUserEmail(rowData, true);
                    }}
                  />
                </>
              ) : (
                <VisibilityIcon
                  style={{ textAlign: "center", color: "#1C4979" }}
                  onClick={event => {
                    onClickUserEmail(rowData, false);
                  }}
                />
              )
            ) : (
              <>
                {rowData.phone
                  ? rowData.phone.includes("+")
                    ? rowData.phone
                    : `+${rowData.phone}`
                  : ""}
              </>
            )}
          </>
        );
      },
      width: "100%"
    },
    {
      title: oneTimeClientKey,
      field: "isOneTimeClient",
      hidden: true
    },
    {
      title: "Tags",
      field: "userTagList",
      sorting: false,
      render: rowData => {
        return (
          <>
            {rowData.userTagList.map(r => {
              return <Chip label={r} className={classes.chip} />;
            })}
          </>
        );
      }
    },
    { title: "Joined", field: "created_at" },
    { title: "Last Visit", field: "last_visited" },
    {
      title: "Active",
      field: "active"
    }
  ];

  const [dateFilter, setDateFilter] = useState({
    filterByArray: [joinedKey, lastVisitKey],
    filterBy: null,
    fromDate: null,
    toDate: "",
    dateFromError: false,
    dateToError: false,
    dateFromErrorText: "",
    dateToErrorText: "",
    filterSelectError: false,
    filterSelectErrorText: "",
    gte_key: "",
    lte_key: ""
  });

  const [filterData, setFilterData] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorForDateFilter, setAnchorForDateFilter] = React.useState(null);
  const [showChip, setShowChip] = useState(false);
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const open = Boolean(anchorEl);
  const openDateFilter = Boolean(anchorForDateFilter);

  const [selectedValue, setSelectedValue] = React.useState([]);

  const [openGiveAll] = React.useState(false);
  const [anchorRefGiveAll] = React.useState(null);

  const getUserTags = async () => {
    setShowChip(false);
    providerForGet(userTagForAdmin, {}, Auth.getToken())
      .then(res => {
        let data = res.data.data;
        let filters = {
          1: oneTimeClientKey
        };
        Object.keys(filters).map(num => {
          data.push({
            id: data[data.length - 1].id + num,
            name: filters[num]
          });
          return null;
        });

        data = data.filter(onlyUnique);
        setFormState(formState => ({
          ...formState,
          filterList: data
        }));
      })
      .catch(error => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getUserTags();
  }, []);

  const handleGiveAllToggle = async clientId => {
    setLoader(true);
    await providerForDownload(
      generateGiveAllWithInserts,
      {
        scheduleId: null,
        clientId: clientId
      },
      Auth.getToken(),
      {
        desc: "Download Give All Reports with inserts for a client",
        audit_log_clientid: clientId
      }
    )
      .then(res => {
        setLoader(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "give_all_with_inserts.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        // PDF for print
        const pdfUrl = URL.createObjectURL(
          new Blob([res.data], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");

        // const pdfNewWindow = window.open();
        // pdfNewWindow.location.href = pdfUrl;

        setFormState(formState => ({
          ...formState,
          alert: true,
          isReportStatus: true,
          errorMessage:
            "Give All With Inserts document downloaded successfully",
          severity: "success"
        }));
      })
      .catch(error => {
        setFormState(formState => ({
          ...formState,
          alert: true,
          isReportStatus: true,
          errorMessage: "Could not download Give All With Inserts document",
          severity: "error"
        }));
        setLoader(false);
        console.log(error);
      });
  };

  // return focus to the button when we transitioned from !openGiveAll -> openGiveAll
  const prevOpenGiveAll = React.useRef(openGiveAll);
  React.useEffect(() => {
    if (prevOpenGiveAll.current === true && openGiveAll === false) {
      anchorRefGiveAll.current.focus();
    }

    prevOpenGiveAll.current = openGiveAll;
  }, [openGiveAll]);

  const convertTableData = res => {
    let arr = [];
    if (res && res.length > 0) {
      for (let i in res) {
        let tempArr = {};
        tempArr["id"] = res[i].id;
        tempArr["created_at"] = res[i].created_at
          ? getFormattedDate(res[i].created_at)
          : "";
        tempArr["last_visited"] = res[i].last_visited
          ? getFormattedDate(res[i].last_visited)
          : "";
        tempArr["active"] = res[i].active ? "Yes" : "No";
        tempArr["full_name"] = res[i].full_name;
        tempArr["email"] = res[i].email;
        tempArr["phone"] = res[i].phone;
        tempArr["userTagList"] = res[i].userTagList;
        tempArr["isOneTimeClient"] =
          res[i].isOneTimeClient === true ? true : false;
        tempArr["maskAllUsers"] = res[i].franchise_site
          ? res[i].franchise_site.mask_all_users
          : false;
        arr.push(tempArr);
      }
      return arr;
    } else {
      return [];
    }
  };

  /**
   * * get's user data
   * @param {*} page
   * @param {*} pageSize
   */
  const getUserData = async (page, pageSize) => {
    let params = { page: page, pageSize: pageSize };
    Object.keys(filter).map(res => {
      if (!params.hasOwnProperty(res)) {
        params[res] = filter[res];
      }
      return null;
    });

    params.desc = "Get clients data";
    return new Promise((resolve, reject) => {
      fetch(getClient + "?" + new URLSearchParams(params), {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth
        }
      })
        .then(response => response.json())
        .then(result => {
          resolve({
            data: convertTableData(result.data),
            page: result.page - 1,
            totalCount: result.totalCount
          });
        });
    });
  };

  const handleClickShowPassword = () => {
    setFormState({
      ...formState,
      showPassword: !formState.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleClickOpen = rowData => {
    let data = {};
    data["id"] = rowData.id;
    history.push(EDITCLIENT, data);
  };

  const handleDuplicate = rowData => {
    let data = {};
    data["duplicateId"] = rowData.id;
    data["isDuplicate"] = true;
    history.push(ADDCLIENT, data);
  };

  const closeDialogBox = () => {
    setOpenDialogBox(false);
    setFormState(formState => ({
      ...formState,
      editPersonalNotesId: null
    }));
  };

  const handleNotesSave = () => {
    let dataToSend = {
      personal_notes: formState.values.personal_notes
        ? formState.values.personal_notes
        : null
    };

    if (formState.editPersonalNotesId) {
      setLoader(true);

      providerForPut(user, formState.editPersonalNotesId, dataToSend, auth)
        .then(res => {
          setLoader(false);
          closeDialogBox();
        })
        .catch(err => {
          console.log("err", err);
          setFormState(formState => ({
            ...formState,
            alert: true,
            severity: "error",
            errorMessage: ERROR_MESSAGE + err
          }));
        });
    }
  };
  const handleChange = e => {
    setPassNotPresent(false);
    setFormState(formState => ({
      ...formState,
      inValidPass: false,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value
      }
    }));
    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const onClickVerify = async () => {
    let isValid = false;
    setFormState(formState => ({
      ...formState,
      inValidPass: false
    }));

    if (formState.values.password && formState.values.password.length > 0) {
      isValid = true;
    } else {
      setPassNotPresent(true);
    }
    if (isValid) {
      setpassLoader(true);
      let payLoad = {
        password: formState.values.password
      };
      providerForPost(verifyPassForLogs, payLoad, Auth.getToken(), {
        desc: "Verify password for viewing user's email"
      })
        .then(res => {
          setFormState(formState => ({
            ...formState,
            enableUserEmail: formState.viewUserEmail ? true : false,
            enableUserPhone: formState.viewUserPhone ? true : false
          }));
          delete formState.values[password];
          setOpenVerifyDialogBox(false);
          setpassLoader(false);
        })
        .catch(err => {
          setEnabledId(null);
          setFormState(formState => ({
            ...formState,
            inValidPass: true
          }));
          setpassLoader(false);
        });
    }
  };

  const hasError = field => (formState.errors[field] ? true : false);

  const closePasswordDialogBox = () => {
    setOpenVerifyDialogBox(false);
    setPassNotPresent(false);
    delete formState.values[password];
    setFormState(formState => ({
      ...formState,
      inValidPass: false
    }));
  };

  const handleClickSchedule = async rowData => {
    rowData["viewData"] = true;
    providerForGet(
      getCurrentScheduleOfClient,
      {
        clientid: rowData.id
      },
      Auth.getToken()
    )
      .then(res => {
        history.push({
          pathname: BODYMASS,
          search: `?c=${rowData.id}&s=${res.data.id}`,
          state: { isActive: rowData.active }
        });
      })
      .catch(error => {
        setFormState(formState => ({
          ...formState,
          alert: true,
          errorMessage: "Error occured",
          severity: "error"
        }));
      });
  };

  const handleSearch = event => {
    setFormState(formState => ({
      ...formState,
      isLoad: true
    }));
    tableRef.current.onQueryChange();
  };

  const handleFilterChange = event => {
    if (event.target.value !== "") {
      setFilterData(filterData => ({
        ...filterData,
        [event.target.name]: event.target.value
      }));
      setFilter(filter => ({
        ...filter,
        "_where[_or][0][email_contains]": event.target.value,
        "_where[_or][1][full_name_contains]": event.target.value,
        "_where[_or][2][phone_contains]": event.target.value
      }));
    } else {
      delete filterData[event.target.name];
      Object.keys(filter).map(r => {
        if (r.startsWith("_where", 0)) {
          delete filter[r];
        }
        return null;
      });
      setFilter(filter => ({
        ...filter
      }));
      setFilterData(filterData => ({
        ...filterData
      }));
      handleSearch();
    }
  };

  const orderFunc = (columnId, direction) => {
    let orderByColumn;
    let orderBy = "";
    if (columnId >= 0) {
      orderByColumn = columns[columnId]["field"];
    }
    orderBy = orderByColumn + ":" + direction;
    setFilter(filter => ({
      ...filter,
      _sort: orderBy
    }));
    tableRef.current.onQueryChange();
  };

  const handleSnackbarClose = () => {
    if (propData) {
      propData["successMessage"] = false;
    }
    setFormState(formState => ({
      ...formState,
      isReportStatus: false,
      alert: false,
      errorMessage: ""
    }));
  };

  const handleReset = event => {
    delete filterData["full_name_contains"];
    Object.keys(filter).map(r => {
      if (r.startsWith("_where", 0)) {
        delete filter[r];
      }
      return null;
    });
    setFilter(filter => ({
      ...filter
    }));
    setFilterData(filterData => ({
      ...filterData
    }));
    handleSearch();
  };

  const addClientPage = () => {
    history.push(ADDCLIENT);
  };

  function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
      onClose();
    };

    const handleListItemClick = value => {
      let enterData = false;
      for (var i = 0; i < selectedValue.length; i++) {
        if (value.id === selectedValue[i].id) {
          enterData = true;
        }
      }
      if (enterData) {
        onClose();
      } else {
        onClose(value);
      }
    };

    return (
      <>
        <Popover
          id={"simple-popover"}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={Styles.popoverFilter}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          {formState.filterList.map(item => (
            <Typography
              className={classes.typography}
              onClick={() => handleListItemClick(item)}
              key={item.id}
            >
              {item.name}
            </Typography>
          ))}
        </Popover>
      </>
    );
  }

  SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired
  };

  function SimpleDialogForGiveAll(props) {
    const classes = useStyles();
    const { open } = props;

    const handleClose = () => {};

    return (
      <>
        <Popover
          id={"simple-popover"}
          open={open}
          anchorEl={anchorRefGiveAll}
          onClose={handleClose}
          className={Styles.popoverFilter}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Typography className={classes.typography} onClick={() => {}}>
            Give All Document
          </Typography>
          <Typography className={classes.typography} onClick={() => {}}>
            All Reports
          </Typography>
        </Popover>
      </>
    );
  }

  SimpleDialogForGiveAll.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  };

  /** Handle End Date filter change */
  const handleEndDateChange = event => {
    let endDate = moment(event).endOf("day").format("YYYY-MM-DDT23:59:59.999Z");
    let toDate = "";
    let dateToError = false;
    let dateToErrorText = "";
    if (endDate !== "Invalid date") {
      toDate = new Date(endDate).toISOString();
      if (
        dateFilter.fromDate &&
        dateFilter.fromDate !== "" &&
        moment(endDate).isBefore(moment(dateFilter.fromDate))
      ) {
        dateToError = true;
        dateToErrorText = ["To date cannot be less than from date"];
      }
    }

    setDateFilter(dateFilter => ({
      ...dateFilter,
      toDate: toDate,
      dateToError: dateToError,
      dateToErrorText: dateToErrorText
    }));
  };

  /** Handle Start Date filter change */
  const handleStartDateChange = event => {
    let startDate = moment(event).format("YYYY-MM-DDT00:00:00.000Z");
    let fromDate = null;
    let dateFromError = false;
    let dateFromErrorText = "";
    if (startDate !== "Invalid date") {
      fromDate = new Date(startDate).toISOString();
      if (
        dateFilter.toDate &&
        dateFilter.toDate !== "" &&
        moment(fromDate).isAfter(moment(dateFilter.toDate))
      ) {
        dateFromError = true;
        dateFromErrorText = ["From date cannot be greater than to date"];
      }
    }

    setDateFilter(dateFilter => ({
      ...dateFilter,
      fromDate: fromDate,
      dateFromError: dateFromError,
      dateFromErrorText: dateFromErrorText
    }));
  };

  const handleChangeFilterBy = e => {
    setDateFilter(dateFilter => ({
      ...dateFilter,
      filterBy: e.target.value,
      filterSelectError: false,
      filterSelectErrorText: ""
    }));
  };

  const handleClickFilterResetButton = () => {
    setDateFilter(dateFilter => ({
      ...dateFilter,
      filterBy: null,
      fromDate: null,
      toDate: "",
      dateFromError: false,
      dateToError: false,
      dateFromErrorText: "",
      dateToErrorText: "",
      filterSelectError: false,
      filterSelectErrorText: "",
      gte_key: "",
      lte_key: ""
    }));
    if (filter.hasOwnProperty(dateFilter.gte_key)) {
      delete filter[dateFilter.gte_key];
    }
    if (filter.hasOwnProperty("last_visited_gte")) {
      delete filter["last_visited_gte"];
    }
    if (filter.hasOwnProperty("last_visited_lte")) {
      delete filter["last_visited_lte"];
    }
    if (filter.hasOwnProperty("created_at_gte")) {
      delete filter["created_at_gte"];
    }
    if (filter.hasOwnProperty("created_at_lte")) {
      delete filter["created_at_lte"];
    }
    setFilter(filter => ({
      ...filter
    }));
    setAnchorForDateFilter(null);
    tableRef.current.onQueryChange();
  };

  const handleClickFilterDoneButton = () => {
    if (dateFilter.filterBy === "" || !dateFilter.filterBy) {
      setDateFilter(dateFilter => ({
        ...dateFilter,
        filterSelectError: true,
        filterSelectErrorText: "Please select a filter"
      }));
    } else {
      if (
        !dateFilter.dateFromError &&
        !dateFilter.dateToError &&
        dateFilter.toDate &&
        dateFilter.fromDate &&
        dateFilter.toDate !== "" &&
        dateFilter.fromDate !== ""
      ) {
        let gte_key = "";
        let lte_key = "";
        if (dateFilter.filterBy === joinedKey) {
          delete filter["last_visited_gte"];
          delete filter["last_visited_lte"];
          gte_key = "created_at_gte";
          lte_key = "created_at_lte";
        } else {
          delete filter["created_at_gte"];
          delete filter["created_at_lte"];
          gte_key = "last_visited_gte";
          lte_key = "last_visited_lte";
        }
        setDateFilter(dateFilter => ({
          ...dateFilter,
          gte_key: gte_key,
          lte_key: lte_key
        }));
        setFilter(filter => ({
          ...filter,
          [gte_key]: dateFilter.fromDate,
          [lte_key]: dateFilter.toDate
        }));
        setAnchorForDateFilter(null);
        tableRef.current.onQueryChange();
      }
    }
  };

  function DateFilterComponent(props) {
    const { onClose, open } = props;

    const handleClose = () => {
      onClose();
    };

    return (
      <>
        <Popover
          id={"simple-popover"}
          open={open}
          anchorEl={anchorForDateFilter}
          onClose={handleClose}
          className={Styles.popoverFilter}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <FormControl
            className={classes.formControlFilterBy}
            error={dateFilter.filterSelectError}
          >
            <InputLabel id="filter_by_select">Filter by</InputLabel>
            <Select
              labelId="filter_by_select"
              id="demo-simple-select-error"
              value={dateFilter.filterBy}
              onChange={handleChangeFilterBy}
            >
              {dateFilter.filterByArray.map(r => {
                return <MenuItem value={r}>{r}</MenuItem>;
              })}
            </Select>
            {dateFilter.filterSelectError ? (
              <FormHelperText>
                {dateFilter.filterSelectErrorText}
              </FormHelperText>
            ) : null}
          </FormControl>
          <Grid container spacing={1}>
            <Grid item className={classes.paddingDate}>
              <InlineDatePicker
                id="startDate"
                label="From Date"
                placeholder="MM/DD/YYYY"
                value={dateFilter.fromDate || null}
                name={"From Date"}
                onChange={event => handleStartDateChange(event)}
              />
            </Grid>
            <Grid item className={classes.paddingDate}>
              <InlineDatePicker
                id="endDate"
                label="To Date"
                placeholder="MM/DD/YYYY"
                value={dateFilter.toDate || null}
                name={"To Date"}
                onChange={event => handleEndDateChange(event)}
              />
            </Grid>
          </Grid>

          {dateFilter.dateFromError ? (
            <Grid item>
              <FormHelperText className={classes.dateErrorText}>
                {dateFilter.dateFromErrorText}
              </FormHelperText>
            </Grid>
          ) : null}
          {dateFilter.dateToError ? (
            <Grid item>
              <FormHelperText className={classes.dateErrorText}>
                {dateFilter.dateToErrorText}
              </FormHelperText>
            </Grid>
          ) : null}

          <Grid item className={classes.filterByButton}>
            <DarkBlueButton
              variant="contained"
              color="primary"
              onClick={handleClickFilterDoneButton}
              style={{
                marginRight: "1%"
              }}
            >
              Done
            </DarkBlueButton>
            <DarkBlueButton
              variant="contained"
              color="primary"
              onClick={handleClickFilterResetButton}
            >
              Reset
            </DarkBlueButton>
          </Grid>
        </Popover>
      </>
    );
  }

  /** Set for filters */
  const handleClickOpenDialog = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickDialogForDateFilter = event => {
    setAnchorForDateFilter(event.currentTarget);
  };

  const handleCloseDateFilter = async () => {
    setAnchorForDateFilter(null);
  };

  const selectedChipFilter = async () => {
    let array = [];
    for (var i = 0; i < selectedValue.length; i++) {
      if (
        selectedValue[i].name !== oneTimeClientKey &&
        selectedValue[i].name !== joinedKey &&
        selectedValue[i].name !== lastVisitKey
      ) {
        array.push(parseInt(selectedValue[i].id));
      } else if (selectedValue[i].name === oneTimeClientKey) {
        setFilter(filter => ({
          ...filter,
          isOneTimeClient: true
        }));
      }
    }
    if (array.length > 0) {
      setFilter(filter => ({
        ...filter,
        [userTagsFilter]: array
      }));
    }
    tableRef.current.onQueryChange();
  };

  const handleClose = async value => {
    if (value) {
      setAnchorEl(null);
      selectedValue.push(value);
      await selectedChipFilter();
      setShowChip(true);
    } else {
      setAnchorEl(null);
    }
  };

  const handleFilterChangeData = () => {
    let array = [];
    if (selectedValue.length > 0) {
      for (var i = 0; i < selectedValue.length; i++) {
        if (
          selectedValue[i].name !== oneTimeClientKey &&
          selectedValue[i].name !== joinedKey &&
          selectedValue[i].name !== lastVisitKey
        ) {
          array.push(parseInt(selectedValue[i].id));
        } else if (selectedValue[i].name === oneTimeClientKey) {
          setFilter(filter => ({
            ...filter,
            isOneTimeClient: true
          }));
        }
      }
      if (array.length > 0) {
        setFilter(filter => ({
          ...filter,
          [userTagsFilter]: array
        }));
      } else {
        delete filter[userTagsFilter];
      }
    } else {
      delete filter[userTagsFilter];
      delete filter["isOneTimeClient"];
    }

    tableRef.current.onQueryChange();
  };

  useEffect(() => {
    handleFilterChangeData();
  }, [selectedValue]);

  const handleDelete = data => {
    let array = [];
    if (data.name === oneTimeClientKey) {
      delete filter["isOneTimeClient"];
    }
    if (data.name === joinedKey) {
      delete filter["created_at_gte"];
      delete filter["created_at_gte"];
    }

    if (data.name === lastVisitKey) {
      delete filter["last_visited_gte"];
      delete filter["last_visited_lt"];
    }

    for (var i = 0; i < selectedValue.length; i++) {
      if (data.id !== selectedValue[i].id) {
        array.push(selectedValue[i]);
      }
    }

    setSelectedValue(array);
  };

  return (
    <div className={styles.Franchise} data-testid="clients">
      <div>
        <Grid
          container
          spacing={3}
          style={{ justifyContent: "flex-end", marginBottom: "15px" }}
        >
          <Grid item>
            <Tooltip title="Add New Client">
              <PersonAddIcon
                style={{ color: "#1C4979" }}
                variant="contained"
                onClick={() => addClientPage()}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Filter By Date">
              <DateRangeIcon
                variant="contained"
                style={{ color: "#1C4979" }}
                onClick={handleClickDialogForDateFilter}
              />
            </Tooltip>

            {openDateFilter && (
              <DateFilterComponent
                open={openDateFilter}
                onClose={handleCloseDateFilter}
              />
            )}
          </Grid>
          <Grid item>
            <Tooltip title="Filter By Tags">
              <TuneIcon
                variant="contained"
                style={{ color: "#1C4979" }}
                onClick={handleClickOpenDialog}
              />
            </Tooltip>

            {open && (
              <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
              />
            )}
          </Grid>
        </Grid>
        <Grid container style={{ justifyContent: "flex-end" }}>
          <Grid item>
            {showChip &&
              selectedValue.map(data => (
                <Chip
                  className={Styles.filterChip}
                  label={data && data.name}
                  onDelete={() => handleDelete(data)}
                />
              ))}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <HeadingOne>My Clients</HeadingOne>
          </Grid>
        </Grid>
        <Grid>
          <SnackBar
            autoHideDuration={formState.isReportStatus ? null : 10000}
            open={formState.alert}
            severity={formState.severity}
            onClose={handleSnackbarClose}
          >
            {formState.errorMessage}
          </SnackBar>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <SearchBar
              handleFilterChange={handleFilterChange}
              filter={
                filterData.full_name_contains
                  ? filterData.full_name_contains
                  : ""
              }
              handleSearch={handleSearch}
              handleReset={() => handleReset(false)}
              placeholder={"Search for client by Name, Email, Phone"}
            />
          </Grid>
        </Grid>

        <DialogBox
          open={openDialogBox}
          title="Private Notes "
          buttonCancel="Cancel"
          handleCancel={closeDialogBox}
          skipOk={false}
          buttonOk={"SAVE"}
          handleOk={handleNotesSave}
        >
          <Typography
            style={{
              color: "#ACACB8",
              fontSize: "14px",
              fontFamily: "Montserrat",
              fontWeight: "600",
              marginBottom: "1.875rem"
            }}
          >
            Client cannot see this section.
          </Typography>
          <Input
            label="private notes"
            name="personal_notes"
            value={formState.values["personal_notes"] || ""}
            multiline
            rows={8}
            fullWidth
            onChange={handleChange}
            style={{
              width: "100%"
            }}
          />
        </DialogBox>

        <Table
          tableRef={tableRef}
          columns={columns}
          data={async query => {
            return await getUserData(query.page + 1, query.pageSize);
          }}
          localization={{
            body: {
              editRow: {
                deleteText: `Are you sure you want to delete this client?`,
                saveTooltip: "Delete"
              }
            }
          }}
          actions={[
            {
              icon: () => <DarkBlueButton>Schedule</DarkBlueButton>,
              tooltip: "Schedule",
              onClick: (event, rowData) => {
                handleClickSchedule(rowData);
              }
            },
            {
              icon: () => (
                <>
                  <img
                    src={AddReports}
                    alt=""
                    aria-haspopup="true"
                    style={{
                      padding: 2,
                      height: 25
                    }}
                  />
                </>
              ),
              tooltip: "Give All Document",

              onClick: (event, rowData) => {
                handleGiveAllToggle(rowData.id);
              }
            },
            {
              icon: () => <PersonOutlineIcon />,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                handleClickOpen(rowData);
              }
            },
            {
              icon: () => <FileCopyIcon />,
              tooltip: "Duplicate",
              onClick: (event, rowData) => {
                handleDuplicate(rowData);
              }
            }
          ]}
          icons={{}}
          editable={{}}
          options={{
            pageSize: 10,
            actionsColumnIndex: -1,
            search: false,
            sorting: true,
            thirdSortClick: false,
            headerStyle: {
              fontFamily: "Montserrat",
              fontWeight: 500,
              color: "#8A8A97",
              borderBottom: "solid #E0E0E0 2px",
              fontSize: "1rem"
            },
            cellStyle: {
              fontFamily: "Montserrat",
              fontWeight: 500,
              color: "#000000",
              fontSize: "1rem"
            },
            paginationStyle: {
              justifyContent: "center"
            }
          }}
          onOrderChange={(orderedColumnId, orderDirection) => {
            orderFunc(orderedColumnId, orderDirection);
          }}
        />
      </div>
      <DialogBox
        open={openVerifyDialogBox}
        title="Enter password"
        buttonCancel="Cancel"
        handleCancel={closePasswordDialogBox}
        buttonOk="Enter"
        handleOk={onClickVerify}
      >
        <Grid container spacing={2}>
          <Backdrop className={classes.backdrop} open={passLoader}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid item md={12} xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginTop: "10px" }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                fullWidth
                error={
                  hasError(password) || formState.inValidPass || passNotPresent
                }
              >
                Password
              </InputLabel>
              <OutlinedInput
                label="Password"
                name={password}
                type={formState.showPassword ? "text" : "password"}
                value={formState.values[password] || ""}
                onChange={handleChange}
                fullWidth
                error={
                  hasError(password) || formState.inValidPass || passNotPresent
                }
                endAdornment={
                  <InputAdornment
                    position="end"
                    error={
                      hasError(password) ||
                      formState.inValidPass ||
                      passNotPresent
                    }
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {formState.showPassword ? (
                        <Visibility style={{ color: "#1C4979" }} />
                      ) : (
                        <VisibilityOff style={{ color: "gray" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              ></OutlinedInput>
              <FormHelperText
                error={
                  hasError(password) || formState.inValidPass || passNotPresent
                }
                style={{ fontSize: "larger" }}
              >
                {hasError(password)
                  ? formState.errors[password].map(error => {
                      return error + " ";
                    })
                  : formState.inValidPass
                  ? "Invalid Password"
                  : passNotPresent
                  ? "Please enter the password"
                  : null}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogBox>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

Franchise.propTypes = {};

Franchise.defaultProps = {};

export default Franchise;
