import React from "react";
import styles from "./Client.module.css";

const Client = () => {
  return (
    <div className={styles.Client} data-testid="Client">
      Client Component
    </div>
  );
};

Client.propTypes = {};

Client.defaultProps = {};

export default Client;
