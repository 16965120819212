import React, { useEffect, useState } from "react";
import styles from "./AddEditFranchise.module.css";
import addFranchiseForm from "../FranchiseSchema";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  LinearProgress,
  FormHelperText,
} from "@material-ui/core";
import {
  Auth,
  Input,
  SnackBar,
  DarkBlueButton,
  BorderLessButton,
  Spinner,
} from "../../../components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { get } from "lodash";
import {
  checkAllKeysPresent,
  checkEmpty,
  getListOfKeysNotPresent,
  setErrors,
} from "../../../utils";
import SaveIcon from "@material-ui/icons/Save";
import { FRANCHISE } from "../../../paths";
import { providerForGet, providerForPost, providerForPut } from "../../../api";
import { franchise, apiBackendUrl } from "../../../constants";
import {
  ERROR_MESSAGE,
  SAVE_MESSAGE,
  UPDATE_MESSAGE,
} from "../../../constants/genericConstants";
import useStyles from "../../../utils/Styles/ManagePageStyles";

import upload_primary_logo from "../../../assets/images/upload_primary_logo.png";
import cover_page_logo from "../../../assets/images/cover_page_logo.png";

import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import PhoneInput from "react-phone-input-2";
import Styles from "../../Client/AddEditClient/AddEditClient.module.css";
import { AuthContext } from "../../../context";
import { useContext } from "react";
const AddEditFranchise = (props) => {
  const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
  const history = useHistory();
  const auth = Auth.getToken();
  const [loader, setLoader] = useState(false);
  const classes = useStyles();
  let propData = props["location"]["state"];
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    files: {},
    giveAllLogo: {},
    viewFile: "",
    deleteImage: false,
    previewFile: {},
    showPreviewImage: false,
    showPreviewEditImage: false,
    showPreviewNoImage: false,
    showAddPreviewNoImage: true,
    viewGiveAllFile: "",
    deleteGiveAllImage: false,
    previewGiveAllFile: {},
    showGiveAllPreviewImage: false,
    showGiveAllPreviewEditImage: false,
    showGiveAllPreviewNoImage: false,
    showAddGiveAllPreviewNoImage: true,
    franchiseList: [],
    alert: false,
    severity: "success",
    errorMessage: "",
    /** This is when we return from edit page */
    rowData: propData ? propData : [],
    editData: propData ? (propData["id"] ? true : false) : false,
    editDataId: propData ? propData["id"] : null,
  });
  const franchiseName = "franchiseName";
  const franchiseDescription = "franchiseDescription";
  const address_line_1 = "address_line_1";
  const address_line_2 = "address_line_2";
  const files = "files";
  const giveAllLogo = "giveAllLogo";
  const phone = "phone";
  const franchise_website = "franchise_website";
  const email = "email";
  const no_of_franchise_sites = "no_of_franchise_sites";
  const active_users = "active_users";
  const active_nutritionists = "active_nutritionists";

  useEffect(() => {
    getListData();
  }, []);

  /**Api call for getting autocomplete list itmes */
  const getListData = async () => {
    if (formState.editData === true) {
      setLoader(true);
      let editId = {
        id: formState.editDataId,
        desc: `${
          Auth.getUserInfo().full_name
        } accessed a franchise for editing`,
      };
      await providerForGet(franchise, editId, auth)
        .then((res) => {
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              franchiseName: res.data.data[0].franchise_name,
              franchiseDescription: res.data.data[0].description,
              address_line_1: res.data.data[0].address_line_1,
              address_line_2: res.data.data[0].address_line_2,
              phone: res.data.data[0].phone ? res.data.data[0].phone : null,
              franchise_website: res.data.data[0].franchise_website,
              email: res.data.data[0].email,
              no_of_franchise_sites: res.data.data[0].no_of_franchise_sites
                ? res.data.data[0].no_of_franchise_sites
                : null,
              active_users: res.data.data[0].active_users
                ? res.data.data[0].active_users
                : null,
              active_nutritionists: res.data.data[0].active_nutritionists
                ? res.data.data[0].active_nutritionists
                : null,
            },
            viewFile:
              res.data.data[0] &&
              res.data.data[0].franchise_logo &&
              res.data.data[0].franchise_logo.url &&
              res.data.data[0].franchise_logo.url,
            showPreviewEditImage:
              res.data.data[0] &&
              res.data.data[0].franchise_logo &&
              res.data.data[0].franchise_logo.url
                ? true
                : false,
            showAddPreviewNoImage:
              res.data.data[0] &&
              res.data.data[0].franchise_logo &&
              res.data.data[0].franchise_logo.url
                ? false
                : true,
            viewGiveAllFile:
              res.data.data[0] &&
              res.data.data[0].give_all_franchise_logo &&
              res.data.data[0].give_all_franchise_logo.url &&
              res.data.data[0].give_all_franchise_logo.url,
            showGiveAllPreviewEditImage:
              res.data.data[0] &&
              res.data.data[0].give_all_franchise_logo &&
              res.data.data[0].give_all_franchise_logo.url
                ? true
                : false,
            showAddGiveAllPreviewNoImage:
              res.data.data[0] &&
              res.data.data[0].give_all_franchise_logo &&
              res.data.data[0].give_all_franchise_logo.url
                ? false
                : true,
          }));

          setLoader(false);
        })
        .catch((err) => {
          console.log("err", err);
          setLoader(false);
        });
    }
  };

  const handleSnackbarClose = () => {
    setFormState((formState) => ({
      ...formState,
      alert: false,
      errorMessage: "",
    }));
  };

  const handleChange = (e) => {
    e.persist();
    let isTrue = false;
    if (
      e.target.name === active_users ||
      e.target.name === no_of_franchise_sites ||
      e.target.name === active_nutritionists
    ) {
      isTrue = true;
    }
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]: isTrue ? parseInt(e.target.value) : e.target.value,
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const handlePhoneNumberChange = (value, name) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value,
      },
      touched: {
        ...formState.touched,
        [name]: true,
      },
    }));
    delete formState.errors[name];
  };

  async function validate(val, name) {
    let newVal = "+1" + val;
    try {
      const number = phoneUtil.parseAndKeepRawInput(newVal, "+1");
      let isValid = phoneUtil.isValidNumber(number);
      if (isValid) {
        if (val.startsWith("+")) {
          val = val.substring(1);
        }
        formState.values[name] = val;
      } else {
        formState.errors[name] = ["Invalid mobile number"];
      }
    } catch {}
  }
  const hasError = (field) => (formState.errors[field] ? true : false);

  const createFormData = (body) => {
    const data = new FormData();
    data.append("files.franchise_logo", formState.files);
    data.append("files.give_all_franchise_logo", formState.giveAllLogo);
    data.append("data", JSON.stringify(body));
    return data;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      addFranchiseForm
    );

    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, addFranchiseForm);
      await validate(formState.values[phone], phone);
      if (!formState.showAddGiveAllPreviewNoImage) {
        delete formState.errors[giveAllLogo];
      }
      if (!formState.showAddPreviewNoImage) {
        delete formState.errors[files];
      }
      setFormState((formState) => ({
        ...formState,
      }));
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        addFranchiseForm
      );
      formState.errors = setErrors(formState.values, addFranchiseForm);
    }
    let isGiveAllPresent = false;
    let isLogoPresent = false;
    if (formState.editData) {
      if (!formState.showPreviewEditImage) {
        if (
          formState.files &&
          formState.files.name !== null &&
          formState.files.name
        ) {
          isLogoPresent = true;
          delete formState.errors[files];
          setFormState((formState) => ({
            ...formState,
          }));
        } else {
          setFormState((formState) => ({
            ...formState,
            errors: {
              ...formState.errors,
              [files]: ["Primary logo is required"],
            },
          }));
        }
      } else {
        isLogoPresent = true;
        delete formState.errors[files];
        setFormState((formState) => ({
          ...formState,
        }));
      }

      if (!formState.showGiveAllPreviewEditImage) {
        if (
          formState.giveAllLogo &&
          formState.giveAllLogo.name !== null &&
          formState.giveAllLogo.name
        ) {
          isGiveAllPresent = true;
          delete formState.errors[giveAllLogo];
          setFormState((formState) => ({
            ...formState,
          }));
        } else {
          setFormState((formState) => ({
            ...formState,
            errors: {
              ...formState.errors,
              [giveAllLogo]: ["Cover logo is required"],
            },
          }));
        }
      } else {
        isGiveAllPresent = true;
        delete formState.errors[giveAllLogo];
        setFormState((formState) => ({
          ...formState,
        }));
      }
    } else {
      if (
        formState.giveAllLogo &&
        formState.giveAllLogo.name !== null &&
        formState.giveAllLogo.name
      ) {
        isGiveAllPresent = true;
        delete formState.errors[giveAllLogo];
        setFormState((formState) => ({
          ...formState,
        }));
      } else {
        setFormState((formState) => ({
          ...formState,
          errors: {
            ...formState.errors,
            [giveAllLogo]: ["Cover logo is required"],
          },
        }));
      }

      if (
        formState.files &&
        formState.files.name !== null &&
        formState.files.name
      ) {
        isLogoPresent = true;
        delete formState.errors[files];
        setFormState((formState) => ({
          ...formState,
        }));
      } else {
        setFormState((formState) => ({
          ...formState,
          errors: {
            ...formState.errors,
            [files]: ["Primary logo is required"],
          },
        }));
      }
    }

    if (isValid && isGiveAllPresent && isLogoPresent) {
      /**api call from here */
      let postData = {
        franchise_name: formState.values.franchiseName,
        description: formState.values.franchiseDescription,
        address_line_1: formState.values.address_line_1
          ? formState.values.address_line_1
          : "",
        address_line_2: formState.values.address_line_2
          ? formState.values.address_line_2
          : "",
        phone: formState.values.phone ? formState.values.phone : null,
        franchise_website: formState.values.franchise_website,
        email: formState.values.email,
        no_of_franchise_sites: parseInt(formState.values.no_of_franchise_sites),
        active_users: parseInt(formState.values.active_users),
        active_nutritionists: parseInt(formState.values.active_nutritionists),
      };
      if (formState.editData === true) {
        /**Put api call (Update method)*/
        let finalDataEdit = postData;
        if (
          (formState.files &&
            formState.files.name !== null &&
            formState.files.name) ||
          (formState.giveAllLogo &&
            formState.giveAllLogo.name !== null &&
            formState.giveAllLogo.name)
        ) {
          finalDataEdit = createFormData(postData);
        }
        /**
         * Below if is used when user removes the  logo and clicks on save
         */
        if (formState.showAddPreviewNoImage) {
          finalDataEdit["showAddPreviewNoImage"] = true;
        }
        if (formState.showAddGiveAllPreviewNoImage) {
          finalDataEdit["showAddGiveAllPreviewNoImage"] = true;
        }
        providerForPut(franchise, formState.editDataId, finalDataEdit, auth, {
          desc: `${Auth.getUserInfo().full_name} updated the franchise ${
            formState.values.franchiseName
          }`,
        })
          .then((res) => {
            Auth.setLogo(res.data.logo, true);
            let successMessage = {
              successMessage:
                "Franchise  " + res.data.data.franchise_name + UPDATE_MESSAGE,
            };

            history.push(FRANCHISE, successMessage);
            setLoader(false);
          })
          .catch((err) => {
            console.log("err", err);
            if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              setFormState((formState) => ({
                ...formState,
                alert: true,
                severity: "error",
                errorMessage: err.response.data.message,
              }));
            } else {
              setFormState((formState) => ({
                ...formState,
                alert: true,
                severity: "error",
                errorMessage: ERROR_MESSAGE + err,
              }));
            }
            setLoader(false);
          });
      } else {
        /**Post api call (Save method)*/

        let finalData = postData;
        if (
          (formState.files && formState.files.name) ||
          formState.giveAllLogo.name
        ) {
          finalData = createFormData(postData);
        }

        providerForPost(franchise, finalData, auth, {
          desc: `${Auth.getUserInfo().full_name} added a new franchise ${
            formState.values.franchiseName
          } with ${formState.values.no_of_franchise_sites} franchise sites ,${
            formState.values.active_users
          } active clients and ${
            formState.values.active_nutritionists
          } active nutritionists`,
        })
          .then((res) => {
            let successMessage = {
              successMessage:
                "Franchise " + res.data.franchise_name + SAVE_MESSAGE,
            };

            setLoader(false);
            history.push(FRANCHISE, successMessage);
          })
          .catch((err) => {
            console.log("error", err);
            if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              setFormState((formState) => ({
                ...formState,
                alert: true,
                severity: "error",
                errorMessage: err.response.data.message,
              }));
            } else {
              setFormState((formState) => ({
                ...formState,
                alert: true,
                severity: "error",
                errorMessage: ERROR_MESSAGE + err,
              }));
            }
            setLoader(false);
          });
        setLoader(false);
      }
    } else {
      setFormState((formState) => ({
        ...formState,
        isValid: false,
      }));
      setLoader(false);
    }
  };

  const handleCancel = () => {
    history.push(FRANCHISE);
  };

  const handleFileChange = (event) => {
    event.persist();
    if (event.target.files[0].size <= 1100000) {
      if (
        event.target.files[0].type === "image/png" ||
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/jpg"
      ) {
        if (event.target.name === giveAllLogo) {
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              [event.target.name]: event.target.value,
            },
            touched: {
              ...formState.touched,
              [event.target.name]: true,
            },
            giveAllLogo: event.target.files[0],
            previewGiveAllFile: URL.createObjectURL(event.target.files[0]),
            showGiveAllPreviewEditImage: false,
            showGiveAllPreviewNoImage: false,
            showGiveAllPreviewImage: true,
            showAddGiveAllPreviewNoImage: false,
          }));
        } else {
          setFormState((formState) => ({
            ...formState,
            values: {
              ...formState.values,
              [event.target.name]: event.target.value,
            },
            touched: {
              ...formState.touched,
              [event.target.name]: true,
            },
            files: event.target.files[0],
            previewFile: URL.createObjectURL(event.target.files[0]),
            showPreviewEditImage: false,
            showPreviewNoImage: false,
            showPreviewImage: true,
            showAddPreviewNoImage: false,
          }));
        }
      } else {
        setFormState((formState) => ({
          ...formState,
          alert: true,
          severity: "error",
          errorMessage: "Image should be in PNG,JPG,JPEG format",
        }));
      }
    } else {
      setFormState((formState) => ({
        ...formState,
        alert: true,
        severity: "error",
        errorMessage: "File size must be less than or equal to 1mb",
      }));
    }

    /** This is used to remove any existing errors if present in text field */
    if (formState.errors.hasOwnProperty(event.target.name)) {
      delete formState.errors[event.target.name];
    }
  };

  const handleRemoveFile = () => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [files]: null,
      },
      touched: {
        ...formState.touched,
        [files]: false,
      },
      files: null,
      previewFile: null,
      showPreviewEditImage: false,
      showPreviewNoImage: false,
      showPreviewImage: false,
      showAddPreviewNoImage: true,
    }));
  };
  const handleRemoveGiveAllLogo = () => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [giveAllLogo]: null,
      },
      touched: {
        ...formState.touched,
        [giveAllLogo]: false,
      },
      giveAllLogo: null,
      previewGiveAllFile: null,
      showGiveAllPreviewEditImage: false,
      showGiveAllPreviewNoImage: false,
      showGiveAllPreviewImage: false,
      showAddGiveAllPreviewNoImage: true,
    }));
  };
  return (
    <div>
      {loader ? (
        <LinearProgress />
      ) : (
        <>
          <Grid item xs={12} className={styles.title}>
            {formState.editData === true ? (
              <Typography variant="h4" className={styles.titleText}>
                Edit Franchise
              </Typography>
            ) : (
              <Typography variant="h4" className={styles.titleText}>
                Add Franchise
              </Typography>
            )}
          </Grid>
          <Grid>
            <SnackBar
              open={formState.alert}
              severity={formState.severity}
              onClose={handleSnackbarClose}
            >
              {formState.errorMessage}
            </SnackBar>
          </Grid>
          <Grid item xs={12} className={styles.formgrid}>
            <Card className={classes.editCard}>
              <CardContent className={classes.editCardContent}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFranchiseForm[franchiseName], "label")}
                      name={franchiseName}
                      value={formState.values[franchiseName] || ""}
                      error={hasError(franchiseName)}
                      placeholder={get(
                        addFranchiseForm[franchiseName],
                        "placeholder"
                      )}
                      variant="outlined"
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(franchiseName)
                          ? formState.errors[franchiseName].map((error) => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(
                        addFranchiseForm[franchiseDescription],
                        "label"
                      )}
                      name={franchiseDescription}
                      value={formState.values[franchiseDescription] || ""}
                      placeholder={get(
                        addFranchiseForm[franchiseDescription],
                        "placeholder"
                      )}
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      className={styles.elementroot}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFranchiseForm[email], "label")}
                      name={email}
                      value={formState.values[email] || ""}
                      error={hasError(email)}
                      required
                      placeholder={get(addFranchiseForm[email], "placeholder")}
                      onChange={handleChange}
                      helperText={
                        hasError(email)
                          ? formState.errors[email].map((error) => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <PhoneInput
                      country={"us"}
                      onlyCountries={["us"]}
                      disableDropdown={true}
                      disableCountryGuess={true}
                      // disableCountryCode={true}
                      value={formState.values[phone] || ""}
                      onChange={(phone) =>
                        handlePhoneNumberChange(phone, "phone")
                      }
                      required
                      specialLabel="Phone"
                      placeholder={get(addFranchiseForm[phone], "placeholder")}
                      inputProps={{
                        name: "phone",
                        style: {
                          width: "100%",
                          border: hasError(phone) ? "1px solid red" : null,
                        },
                      }}
                    />
                    <FormHelperText
                      type="error"
                      visible={hasError(phone)}
                      style={{
                        "background-color": "White",
                        color: "red",
                      }}
                    >
                      {formState.errors[phone]}
                    </FormHelperText>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFranchiseForm[address_line_1], "label")}
                      name={address_line_1}
                      value={formState.values[address_line_1] || ""}
                      error={hasError(address_line_1)}
                      placeholder={get(
                        addFranchiseForm[address_line_1],
                        "placeholder"
                      )}
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(address_line_1)
                          ? formState.errors[address_line_1].map((error) => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFranchiseForm[address_line_2], "label")}
                      name={address_line_2}
                      value={formState.values[address_line_2] || ""}
                      error={hasError(address_line_2)}
                      placeholder={get(
                        addFranchiseForm[address_line_2],
                        "placeholder"
                      )}
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(address_line_2)
                          ? formState.errors[address_line_2].map((error) => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFranchiseForm[franchise_website], "label")}
                      name={franchise_website}
                      value={formState.values[franchise_website] || ""}
                      error={hasError(franchise_website)}
                      placeholder={get(
                        addFranchiseForm[franchise_website],
                        "placeholder"
                      )}
                      onChange={handleChange}
                      helperText={
                        hasError(franchise_website)
                          ? formState.errors[franchise_website].map((error) => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(
                        addFranchiseForm[no_of_franchise_sites],
                        "label"
                      )}
                      name={no_of_franchise_sites}
                      value={formState.values[no_of_franchise_sites] || ""}
                      error={hasError(no_of_franchise_sites)}
                      placeholder={get(
                        addFranchiseForm[no_of_franchise_sites],
                        "placeholder"
                      )}
                      variant="outlined"
                      type={get(
                        addFranchiseForm[no_of_franchise_sites],
                        "type"
                      )}
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(no_of_franchise_sites)
                          ? formState.errors[no_of_franchise_sites].map(
                              (error) => {
                                return error + " ";
                              }
                            )
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFranchiseForm[active_users], "label")}
                      name={active_users}
                      value={formState.values[active_users] || ""}
                      error={hasError(active_users)}
                      placeholder={get(
                        addFranchiseForm[active_users],
                        "placeholder"
                      )}
                      variant="outlined"
                      type={get(addFranchiseForm[active_users], "type")}
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(active_users)
                          ? formState.errors[active_users].map((error) => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(
                        addFranchiseForm[active_nutritionists],
                        "label"
                      )}
                      name={active_nutritionists}
                      value={formState.values[active_nutritionists] || ""}
                      error={hasError(active_nutritionists)}
                      placeholder={get(
                        addFranchiseForm[active_nutritionists],
                        "placeholder"
                      )}
                      variant="outlined"
                      type={get(addFranchiseForm[active_nutritionists], "type")}
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(active_nutritionists)
                          ? formState.errors[active_nutritionists].map(
                              (error) => {
                                return error + " ";
                              }
                            )
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  {" "}
                  <Grid item md={6} xs={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <div className={classes.imageDiv}>
                        <Grid item md={6} xs={12}>
                          {" "}
                          {formState.showPreviewImage ? (
                            <img
                              src={formState.previewFile}
                              alt="abc"
                              style={{
                                height: "200px",
                                width: "530px",
                              }}
                              loader={<Spinner />}
                              className={classes.UploadImage}
                            />
                          ) : null}
                          {formState.showPreviewEditImage &&
                          formState.viewFile !== "" ? (
                            <>
                              <img
                                src={apiBackendUrl + formState.viewFile}
                                alt="abc"
                                loader={<Spinner />}
                                style={{
                                  height: "200px",
                                  width: "530px",
                                }}
                                className={classes.UploadImage}
                              />
                            </>
                          ) : null}
                          {formState.showAddPreviewNoImage ? (
                            <img
                              src={upload_primary_logo}
                              alt="abc"
                              style={{
                                height: "200px",
                                width: "530px",
                              }}
                              loader={<Spinner />}
                              className={classes.DefaultNoImage}
                            />
                          ) : null}
                        </Grid>
                      </div>
                      <FormHelperText
                        type="error"
                        visible={
                          hasError(files) && formState.showAddPreviewNoImage
                        }
                        style={{
                          "background-color": "White",
                          color: "red",
                          fontSize: "small",
                        }}
                      >
                        {formState.showAddPreviewNoImage
                          ? formState.errors[files]
                          : null}
                      </FormHelperText>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Input
                        fullWidth
                        id={get(addFranchiseForm[files], "id")}
                        name={files}
                        onChange={handleFileChange}
                        required
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        type={get(addFranchiseForm[files], "type")}
                        error={hasError(files)}
                        inputProps={{ accept: "image/*" }}
                        helperText={
                          hasError(files) ? formState.errors[files] : null
                        }
                        variant="outlined"
                        className={classes.inputFile}
                      />

                      <Grid item md={6} xs={6}>
                        <label htmlFor={get(addFranchiseForm[files], "id")}>
                          <DarkBlueButton
                            variant="contained"
                            color="primary"
                            component="span"
                            fullWidth
                            startIcon={<AddOutlinedIcon />}
                          >
                            ADD PRIMARY LOGO{" "}
                          </DarkBlueButton>
                        </label>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <BorderLessButton
                          variant="contained"
                          color="primary"
                          component="span"
                          fullWidth
                          onClick={handleRemoveFile}
                        >
                          Remove Logo
                        </BorderLessButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <div className={classes.imageDiv}>
                        <Grid item md={6} xs={12}>
                          {" "}
                          {formState.showGiveAllPreviewImage ? (
                            <img
                              src={formState.previewGiveAllFile}
                              alt="abc"
                              style={{
                                height: "200px",
                                width: "530px",
                              }}
                              loader={<Spinner />}
                              className={classes.UploadImage}
                            />
                          ) : null}
                          {formState.showGiveAllPreviewEditImage &&
                          formState.viewGiveAllFile !== "" ? (
                            <>
                              <img
                                src={apiBackendUrl + formState.viewGiveAllFile}
                                alt="abc"
                                loader={<Spinner />}
                                style={{
                                  height: "200px",
                                  width: "530px",
                                }}
                                className={classes.UploadImage}
                              />
                            </>
                          ) : null}
                          {formState.showAddGiveAllPreviewNoImage ? (
                            <img
                              src={cover_page_logo}
                              alt="abc"
                              style={{
                                height: "200px",
                                width: "530px",
                              }}
                              loader={<Spinner />}
                              className={classes.DefaultNoImage}
                            />
                          ) : null}
                        </Grid>
                      </div>
                      <FormHelperText
                        type="error"
                        visible={
                          hasError(giveAllLogo) &&
                          formState.showAddGiveAllPreviewNoImage
                        }
                        style={{
                          "background-color": "White",
                          color: "red",
                          fontSize: "small",
                        }}
                      >
                        {formState.showAddGiveAllPreviewNoImage
                          ? formState.errors[giveAllLogo]
                          : null}
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      style={{
                        display: "flex",
                      }}
                    >
                      <Input
                        fullWidth
                        id={get(addFranchiseForm[giveAllLogo], "id")}
                        name={giveAllLogo}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        onChange={handleFileChange}
                        required
                        type={get(addFranchiseForm[giveAllLogo], "type")}
                        error={hasError(giveAllLogo)}
                        inputProps={{ accept: "image/*" }}
                        helperText={
                          hasError(giveAllLogo)
                            ? formState.errors[giveAllLogo].map((error) => {
                                return error + " ";
                              })
                            : null
                        }
                        variant="outlined"
                        className={classes.inputFile}
                      />
                      <Grid item md={6} xs={6}>
                        <label
                          htmlFor={get(addFranchiseForm[giveAllLogo], "id")}
                        >
                          <DarkBlueButton
                            variant="contained"
                            color="primary"
                            component="span"
                            fullWidth
                            startIcon={<AddOutlinedIcon />}
                          >
                            ADD COVER PAGE IMAGE{" "}
                          </DarkBlueButton>
                        </label>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <BorderLessButton
                          variant="contained"
                          color="primary"
                          component="span"
                          fullWidth
                          onClick={handleRemoveGiveAllLogo}
                        >
                          Remove Logo
                        </BorderLessButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} className={classes.actionButtonGrid}>
            <DarkBlueButton
              type="submit"
              data-testid="ok-button"
              onClick={handleSubmit}
            >
              <SaveIcon />
              &nbsp; Save
            </DarkBlueButton>

            <BorderLessButton onClick={handleCancel}>cancel</BorderLessButton>
          </Grid>
        </>
      )}
    </div>
  );
};

AddEditFranchise.propTypes = {};

AddEditFranchise.defaultProps = {};

export default AddEditFranchise;
