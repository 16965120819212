import React, { useState } from "react";
import styles from "./Franchise.module.css";
import { CardContent, Grid, Typography } from "@material-ui/core";
import {
  SnackBar,
  Table,
  DarkBlueButton,
  BorderLessButton,
  Auth,
  Input
} from "../../components";
import { franchise, franchiseAdminWithPaging } from "../../constants";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { ADDFRANCHISE, EDITFRANCHISE } from "../../paths";
import useStyles from "../../utils/Styles/ManagePageStyles";
import { providerForDelete } from "../../api";
import {
  DELETE_MESSAGE,
  ERROR_MESSAGE
} from "../../constants/genericConstants";

const Franchise = props => {
  const propData = props["location"]["state"];
  const history = useHistory();
  const classes = useStyles();
  const tableRef = React.createRef();
  const auth = Auth.getToken();
  const userInfo = Auth.getUserInfo();
  const columns = [
    { title: "Franchise Name", field: "franchise_name" },
    { title: "Description", field: "description" },
    { title: "Franchise Sites", field: "no_of_franchise_sites" },
    { title: "Active Clients", field: "active_users" },
    { title: "Active Nutritionists", field: "active_nutritionists" }
  ];
  const [filter, setFilter] = useState({
    _sort: "franchise_name:asc"
  });
  const [isAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_ADMIN_ROLE_NAME ? true : false
  );
  const [isSuperAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
      ? true
      : false
  );

  const [formState, setFormState] = useState({
    dataTable: {
      data: [],
      page: 1,
      totalCount: 0
    },
    alert: propData ? propData["successMessage"] : false,
    errorMessage: propData ? propData["successMessage"] : false,
    severity: "success",
    isLoad: false,
    adminData: {}
  });

  /**
   ** Use key names exactly same as API response,they'll be used for filtering & sorting purpose
   * */
  const convertTableData = res => {
    let arr = [];
    if (res && res.length > 0) {
      for (let i in res) {
        let tempArr = {};
        tempArr["id"] = res[i].id;
        tempArr["franchise_name"] = res[i].franchise_name;
        tempArr["description"] = res[i].description;
        tempArr["no_of_franchise_sites"] = res[i].no_of_franchise_sites;
        tempArr["active_users"] = res[i].active_users;
        tempArr["active_nutritionists"] = res[i].active_nutritionists;
        arr.push(tempArr);
      }
      return arr;
    } else {
      return [];
    }
  };

  const getFranchiseData = async (page, pageSize) => {
    /**
     ** It sets the page and pageSize in filter variable
     ** so as to keep the record of what page and pageSize was set
     */

    let api = franchise;
    if (isAdmin) {
      api = franchiseAdminWithPaging;
    }
    if (isSuperAdmin) {
      api = franchise;
    }
    setFilter(filter => ({
      ...filter,
      page: page,
      pageSize: pageSize
    }));

    let params = { page: page, pageSize: pageSize };
    Object.keys(filter).map(res => {
      if (!params.hasOwnProperty(res)) {
        params[res] = filter[res];
      }
      return null;
    });
    params.desc = `${userInfo.full_name} viewed the franchise listing page`;
    return new Promise((resolve, reject) => {
      fetch(api + "?" + new URLSearchParams(params), {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth
        }
      })
        .then(response => response.json())
        .then(result => {
          resolve({
            data: convertTableData(result.data),
            page: result.page - 1,
            totalCount: result.totalCount
          });
        });
    });
  };

  const handleClickOpen = rowData => {
    let data = {};
    data["id"] = rowData.id;

    history.push(EDITFRANCHISE, data);
  };

  const handleFilterChange = event => {
    if (event.target.value !== "") {
      setFilter(filter => ({
        ...filter,
        [event.target.name]: event.target.value
      }));
    } else {
      delete filter[event.target.name];
      setFilter(filter => ({
        ...filter
      }));
    }
  };

  const handleSnackbarClose = () => {
    if (propData) {
      propData["successMessage"] = false;
    }
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "success"
    }));
  };

  const orderFunc = (columnId, direction) => {
    let orderByColumn;
    let orderBy = "";
    if (columnId >= 0) {
      orderByColumn = columns[columnId]["field"];
    }
    orderBy = orderByColumn + ":" + direction;
    setFilter(filter => ({
      ...filter,
      _sort: orderBy
    }));
    tableRef.current.onQueryChange();
  };

  const handleReset = event => {
    delete filter["franchise_name_contains"];
    setFilter(filter => ({
      ...filter
    }));
    getFranchiseData(1, filter.pageSize);
    tableRef.current.onQueryChange();
  };

  return (
    <div className={styles.Franchise} data-testid="Franchise">
      <div>
        <Typography variant="h4" className={styles.titleText}>
          Manage Franchise
        </Typography>
        <Grid>
          <SnackBar
            open={formState.alert}
            severity={formState.severity}
            onClose={handleSnackbarClose}
          >
            {formState.errorMessage}
          </SnackBar>
        </Grid>
        {isSuperAdmin ? (
          <Grid container spacing={1}>
            <Grid item sm={12} className={classes.addButton}>
              <DarkBlueButton
                onClick={() => {
                  history.push(ADDFRANCHISE);
                }}
              >
                <AddIcon /> &nbsp; Add Franchise
              </DarkBlueButton>
            </Grid>
          </Grid>
        ) : null}
        <CardContent className={classes.Cardtheming}>
          <Grid className={classes.filterOptions} container spacing={1}>
            <Grid item>
              <Input
                label={"Franchise Name"}
                placeholder="Name"
                name="franchise_name_contains"
                value={
                  filter.franchise_name_contains
                    ? filter.franchise_name_contains
                    : ""
                }
                variant="outlined"
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid item>
              <DarkBlueButton
                disableElevation
                onClick={() => {
                  setFormState(formState => ({
                    ...formState,
                    isLoad: true
                  }));
                  tableRef.current.onQueryChange();
                }}
              >
                search
              </DarkBlueButton>
            </Grid>
            <Grid item>
              <BorderLessButton onClick={handleReset} disableElevation>
                reset{" "}
              </BorderLessButton>
            </Grid>
          </Grid>
        </CardContent>

        <Table
          tableRef={tableRef}
          columns={columns}
          data={async query => {
            return await getFranchiseData(query.page + 1, query.pageSize);
          }}
          localization={{
            body: {
              editRow: {
                deleteText: `Are you sure you want to delete this Franchise?`,
                saveTooltip: "Delete"
              }
            }
          }}
          actions={[
            {
              icon: () => <EditOutlinedIcon />,
              tooltip: "Edit",
              onClick: (event, rowData) => {
                handleClickOpen(rowData);
              }
            }
          ]}
          editable={{
            isDeletable: rowData => (isAdmin ? false : true),
            onRowDelete: oldData =>
              new Promise(resolve => {
                setTimeout(async () => {
                  await providerForDelete(franchise, oldData.id, auth, {
                    desc: `${userInfo.full_name} deleted a franchise`
                  })
                    .then(res => {
                      setFormState(formState => ({
                        ...formState,
                        alert: true,
                        errorMessage: res.data.franchise_name + DELETE_MESSAGE,
                        severity: "success"
                      }));
                    })
                    .catch(err => {
                      console.log("error", err);
                      if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                      ) {
                        setFormState(formState => ({
                          ...formState,
                          alert: true,
                          severity: "error",
                          errorMessage: err.response.data.message
                        }));
                      } else {
                        setFormState(formState => ({
                          ...formState,
                          alert: true,
                          errorMessage: ERROR_MESSAGE + err,
                          severity: "error"
                        }));
                      }
                    });
                  resolve();
                }, 1000);
              })
          }}
          options={{
            pageSize: 10,
            actionsColumnIndex: -1,
            search: false,
            sorting: true,
            thirdSortClick: false
          }}
          onOrderChange={(orderedColumnId, orderDirection) => {
            orderFunc(orderedColumnId, orderDirection);
          }}
        />
      </div>
    </div>
  );
};

Franchise.propTypes = {};

Franchise.defaultProps = {};

export default Franchise;
