import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Checkbox,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Box,
  TableHead,
  useMediaQuery,
  useTheme,
  IconButton,
  Tooltip as TooltipForButton
} from "@material-ui/core";
import {
  Auth,
  SnackBar,
  DarkBlueButton,
  Input,
  AutoCompleteDropDown,
  HeadingOne,
  DateRange
} from "../../components";
import DialogBox from "../../components/DialogBox/DialogBox";
import {
  providerForGet,
  providerForPost,
  providerForPut,
  providerForDownloadWithGraph
} from "../../api";
import {
  cholesterols_url,
  getCholestrolOfSchedule,
  printPdf,
  getCholestrolGraphData,
  getPreviousMeasurementDataUrl,
  getAge,
  getClientGender,
  getScheduleDateForClient,
  getClientScheduleData
} from "../../constants";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import moment from "moment";
import { convertDate, convertNumber } from "../../utils";
import GetAppIcon from "@material-ui/icons/GetApp";
import Styles from "./Measurements.module.css";
import { Prompt, Redirect } from "react-router-dom";
import {
  ERROR_MESSAGE,
  SAVE_MESSAGE,
  UPDATE_MESSAGE
} from "../../constants/genericConstants";
import MeasurementImg from "../../assets/small_icons/Measurement.png";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CLIENTMYSCHEDULE, LOGIN } from "../../paths";

import BarChartIcon from "@material-ui/icons/BarChart";
import TimelineIcon from "@material-ui/icons/Timeline";
import PrintIcon from "@material-ui/icons/Print";
import noSchedule from "../../assets/images/No_schedule.jpg";

const useStyles = makeStyles(theme => ({
  cardTitleStyle: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "1rem",
    color: "#8A8A97",
    padding: "1rem !important"
  },
  tableRowStyle: {
    background: "#f4f8ff"
  },
  tableCellStyle: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.9375rem",
    color: "#110F48",
    border: "none",
    padding: 0,
    backgroundColor: "#f4f8ff"
  },
  borderNone: {
    border: "none",
    padding: 0
  },
  trRoot: {
    overflow: "auto",
    "& .MuiTable-root": {
      borderSpacing: " 0 1.0625rem !important",
      borderCollapse: "separate  !important"
    },
    "& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover": {
      background: "#F8F8FA"
    },
    "& .MuiTableRow-root": {
      "&:hover": {
        background: "#F8F8FA !important"
      }
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: "#fff"
  },
  inputRoot: {
    "& .MuiOutlinedInput-input": {
      textAlign: "center",
      padding: "0.875rem !important"
    },
    "& .MuiOutlinedInput-root": {
      margin: "8px 0px",
      width: "5.125rem"
    }
  },
  checkBoxStyle: {
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#1C4979"
    },
    "& > label > span": {
      paddingLeft: "0px",
      paddingRight: "0px"
    }
  },
  cardHeaderStyle: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1rem",
    color: "#110F48",
    textAlign: "center",
    paddingLeft: "44%"
  },
  paddingStyle: {
    padding: 30,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: 60
  },
  radioButtonStyle: {
    "& .MuiFormControlLabel-label": {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "0.9375rem",
      color: "#000000",
      textAlign: "center"
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#1C4979"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.2rem"
    }
  },
  label: {
    fontSize: "small",
    paddingLeft: 20
  },
  responsiveContainerWrap: {
    overflow: "auto",
    height: "420px",
    "& > div": {
      margin: "0 auto"
    }
  },
  notesSection: {
    marginTop: "1.25rem",
    width: "500px"
  },
  centerImage: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "30%"
  },
  ClientMeasurementContainer: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important"
  }
}));

const cholesterol = "cholesterol";
const hdl = "hdl";
const ldl = "ldl";
const ratio = "ratio";
const trigs = "trigs";
const vldl = "vldl";
const cr = "cr";
const glucose = "glucose";
const HB1AC = "HB1AC";
const cardiac_risk_years = "cardiac_risk_years";
const Hematocrit = "Hematocrit";

const Measurements = props => {
  const themes = useTheme();
  let propData = props["location"]["state"];

  const noScheduleDataMessageFromBackend = "No schedule data";
  const isDesktop = useMediaQuery(themes.breakpoints.up("lg"), {
    defaultMatches: true
  });
  const [dateList, setDateList] = useState([]);
  const [clientView] = useState(
    Auth.getUserInfo().role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
      ? true
      : false
  );
  const [scheduleDateNotPresent, setScheduleDateNotPresent] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [imageSize] = useState({
    width: 800,
    height: 400
  });
  const [loader, setLoader] = useState(false);

  const [graphColor, setGraphColor] = useState({
    graphAreaColor: [],
    graphFillColor: []
  });
  const [isPreviousPresent, setIsPreviousPresent] = useState(true);

  const [formState, setFormState] = useState({
    values: {
      cholesterol: 0,
      hdl: 0,
      ldl: 0,
      ratio: 0,
      trigs: 0,
      vldl: 0,
      cr: 0,
      glucose: 0,
      HB1AC: 0,
      Hematocrit: 0,
      cardiac_risk_years: 0
    },
    isActive: props ? props.isActive : null,
    clientFilter: {},
    scheduleDate: propData ? propData : null,
    fromData: propData ? propData.from : null,
    previousValues: {
      cholesterol: 0,
      hdl: 0,
      ldl: 0,
      ratio: 0,
      trigs: 0,
      vldl: 0,
      cr: 0,
      glucose: 0,
      HB1AC: 0,
      Hematocrit: 0,
      cardiac_risk_years: 0
    },
    percentValues: {
      cholesterol: 0,
      hdl: 0,
      ldl: 0,
      ratio: 0,
      trigs: 0,
      vldl: 0,
      glucose: 0
    },
    limit: {
      cholesterol: 200,
      hdl: 35,
      ldl: 130,
      ratio: 3.5,
      trigs: 175,
      glucose: 120
    },
    measurementsId: null,
    scheduleId: null,

    clientId: null,
    errors: {},
    checked: {}
  });

  const [graphData, setGraphData] = useState({
    printGraphData: "",
    showGraph: false,
    graphDataArray: [],
    graphDataXaxis: ["start_date"],
    graphDataYaxis: [
      {
        paramData: "cholesterol",
        paramColor: "#E63956 ",
        areaColor: "#FFEBEE "
      },
      {
        paramData: "hdl",
        paramColor: "#ccff99",
        areaColor: "#ccff99"
      }
    ]
  });
  const [radioValue, setRadioValue] = useState("Bar Graph");

  const renderColorfulLegendText = (value, entry) => {
    return (
      <span
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: "1rem",
          color: "#8A8A97"
        }}
      >
        {value}
      </span>
    );
  };

  const [xAxis] = useState(["start_date"]);
  const [yAxis, setYAxis] = useState([]);

  const [statusVariable, setStatusVariable] = useState({
    isAdd: false,
    isError: false,
    isOpenSnackBar: false,
    successErrorMessage: "",
    saveText: "",
    dummyTime: new Date(),
    savedTime: null,
    isSaving: false
  });

  /**const for popover */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    recentVisits: true,
    selectDateRange: false,
    noOfVisits: false,
    filterButtonName: "Recent 6 Visits"
  });
  const [dateFilter, setDateFilter] = useState({
    filterBy: null,
    fromDate: null,
    toDate: "",
    dateFromError: false,
    dateToError: false,
    dateNoOfVisitsError: false,
    dateFromErrorText: "",
    dateToErrorText: "",
    dateNoOfVisitsErrorText: "",
    filterSelectError: false,
    filterSelectErrorText: "",
    gte_key: "",
    lte_key: "",
    noOfVisits: null
  });

  const getScheduleDates = () => {
    providerForGet(
      getScheduleDateForClient,
      {
        c: Auth.getUserInfo().id
      },
      Auth.getToken()
    )
      .then(async res => {
        let convertDateData = await convertDate(res.data);
        setDateList(convertDateData);
        if (formState.scheduleDate === null) {
          setFormState(formState => ({
            ...formState,
            clientFilter: {
              ...formState.clientFilter,
              scheduleDate: convertDateData[0].id,
              scheduleObj: convertDateData[0],
              defaultScheduleObj: convertDateData[0]
            }
          }));
        }
        let resObj = convertDateData;
        resObj.map(r => {
          if (formState.scheduleDate !== null) {
            if (r.id === formState.scheduleDate.id) {
              setFormState(formState => ({
                ...formState,
                clientFilter: {
                  ...formState.clientFilter,
                  scheduleDate: r.id,
                  scheduleObj: r
                }
              }));
            }
          }
          return null;
        });
        setLoader(false);
      })
      .catch(error => {
        console.log("error-->>", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.statusCode === 400 &&
          error.response.data.message === noScheduleDataMessageFromBackend
        ) {
          setScheduleDateNotPresent(true);
        }
        setLoader(false);
      });
  };

  const getInitialData = async clientScheduleDate => {
    setLoader(true);
    const urlParams = new URLSearchParams(window.location.search);
    setFormState(formState => ({
      ...formState,
      scheduleId: clientScheduleDate
        ? clientScheduleDate.id
        : urlParams.get("s"),
      clientId: clientScheduleDate ? Auth.getUserInfo().id : urlParams.get("c")
    }));
    let age = 0;
    await providerForGet(
      getAge,
      {
        c: clientScheduleDate ? Auth.getUserInfo().id : urlParams.get("c")
      },
      Auth.getToken()
    )
      .then(res => {
        age = res.data;
      })
      .catch(error => {});

    // Setting ratio limit
    let clientIdToSend = clientScheduleDate
      ? Auth.getUserInfo().id
      : urlParams.get("c");
    let userGender;
    await providerForGet(
      getClientGender,
      { id: clientIdToSend },
      Auth.getToken()
    )
      .then(res => {
        userGender = res.data ? res.data : "Other";
      })
      .catch(err => {
        console.log("err", err);
      });

    await providerForGet(
      getCholestrolOfSchedule,
      {
        s: clientScheduleDate ? clientScheduleDate.id : urlParams.get("s"),
        c: clientScheduleDate ? Auth.getUserInfo().id : urlParams.get("c"),
        desc: clientScheduleDate
          ? "View  Cholestrol details"
          : "Get Cholestrol details of a client",
        audit_log_clientid: clientScheduleDate
          ? Auth.getUserInfo().id
          : urlParams.get("c")
      },
      Auth.getToken()
    )
      .then(res => {
        if (Object.keys(res.data).length !== 0) {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              cholesterol: res.data["cholesterol"]
                ? res.data["cholesterol"]
                : 0,
              hdl: res.data["hdl"] ? res.data["hdl"] : 0,
              ldl: res.data["ldl"] ? res.data["ldl"] : 0,
              ratio: res.data["ratio"] ? res.data["ratio"] : 0,
              trigs: res.data["trigs"] ? res.data["trigs"] : 0,
              vldl: res.data["vldl"] ? res.data["vldl"] : 0,
              cr: res.data["cr"] ? res.data["cr"] : 0,
              glucose: res.data["glucose"] ? res.data["glucose"] : 0,
              HB1AC: res.data["HB1AC"] ? res.data["HB1AC"] : 0,
              Hematocrit: res.data["Hematocrit"] ? res.data["Hematocrit"] : 0,
              cardiac_risk_years: res.data["cardiac_risk_years"]
                ? res.data["cardiac_risk_years"]
                : 0
            },
            percentValues: {
              ...formState.percentValues,
              cholesterol: res.data["cholesterol"]
                ? (
                    (res.data["cholesterol"] /
                      formState["limit"]["cholesterol"]) *
                    100
                  ).toFixed(1)
                : 0,
              hdl: res.data["hdl"]
                ? ((res.data["hdl"] / formState["limit"]["hdl"]) * 100).toFixed(
                    1
                  )
                : 0,
              ldl: res.data["ldl"]
                ? ((res.data["ldl"] / formState["limit"]["ldl"]) * 100).toFixed(
                    1
                  )
                : 0,
              ratio: res.data["ratio"]
                ? (
                    (res.data["ratio"] / formState["limit"]["ratio"]) *
                    100
                  ).toFixed(1)
                : 0,
              trigs: res.data["trigs"]
                ? (
                    (res.data["trigs"] / formState["limit"]["trigs"]) *
                    100
                  ).toFixed(1)
                : 0,
              // vldl: res.data["vldl"]
              //   ? (
              //       (res.data["vldl"] / formState["limit"]["vldl"]) *
              //       100
              //     ).toFixed(1)
              //   : 0,
              glucose: res.data["glucose"]
                ? (
                    (res.data["glucose"] / formState["limit"]["glucose"]) *
                    100
                  ).toFixed(1)
                : 0
            },
            measurementsId: res.data.id
          }));
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isAdd: false
          }));
        } else {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              cardiac_risk_years: res.data["cardiac_risk_years"]
                ? res.data["cardiac_risk_years"]
                : 5
            }
          }));
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isAdd: true
          }));
        }
      })
      .catch(error => {
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          isAdd: true,
          isError: true,
          successErrorMessage: "Error getting cholestrol data"
        }));
        setLoader(false);
      });
    setFormState(formState => ({
      ...formState,
      limit: {
        ...formState.limit,
        cholesterol: age !== undefined && age !== null ? 100 + age : "< 200",
        ratio: userGender === "Male" ? 3.5 : 4.5
      }
    }));
  };
  const getPreviousData = async clientScheduleDate => {
    //api for previous data

    const urlParams = new URLSearchParams(window.location.search);
    await providerForGet(
      getPreviousMeasurementDataUrl,
      {
        s: clientScheduleDate ? clientScheduleDate.id : urlParams.get("s"),
        c: clientScheduleDate ? Auth.getUserInfo().id : urlParams.get("c")
      },
      Auth.getToken()
    )
      .then(res => {
        if (
          res.data.Previous_cholestroldata &&
          Object.keys(res.data.Previous_cholestroldata).length
        ) {
          setIsPreviousPresent(true);
          setFormState(formState => ({
            ...formState,
            previousValues: {
              ...formState.previousValues,
              cholesterol: res.data.Previous_cholestroldata["cholesterol"]
                ? res.data.Previous_cholestroldata["cholesterol"]
                : 0,
              hdl: res.data.Previous_cholestroldata["hdl"]
                ? res.data.Previous_cholestroldata["hdl"]
                : 0,
              ldl: res.data.Previous_cholestroldata["ldl"]
                ? res.data.Previous_cholestroldata["ldl"]
                : 0,
              ratio: res.data.Previous_cholestroldata["ratio"]
                ? res.data.Previous_cholestroldata["ratio"]
                : 0,
              trigs: res.data.Previous_cholestroldata["trigs"]
                ? res.data.Previous_cholestroldata["trigs"]
                : 0,
              vldl: res.data.Previous_cholestroldata["vldl"]
                ? res.data.Previous_cholestroldata["vldl"]
                : 0,
              cr: res.data.Previous_cholestroldata["cr"]
                ? res.data.Previous_cholestroldata["cr"]
                : 0,
              glucose: res.data.Previous_cholestroldata["glucose"]
                ? res.data.Previous_cholestroldata["glucose"]
                : 0,
              HB1AC: res.data.Previous_cholestroldata["HB1AC"]
                ? res.data.Previous_cholestroldata["HB1AC"]
                : 0,
              Hematocrit: res.data.Previous_cholestroldata["Hematocrit"]
                ? res.data.Previous_cholestroldata["Hematocrit"]
                : 0,
              cardiac_risk_years: res.data.Previous_cholestroldata[
                "cardiac_risk_years"
              ]
                ? res.data.Previous_cholestroldata["cardiac_risk_years"]
                : 0
            }
          }));
        } else if (!Object.keys(res.data.Previous_cholestroldata).length) {
          setIsPreviousPresent(false);
        }
      })
      .catch(error => {
        console.log("error", error);
      });
  };

  const getGraphData = async clientScheduleDate => {
    if (!Auth.getUserInfo()) {
      return (
        <React.Fragment>
          <Redirect
            to={{
              pathname: LOGIN
            }}
          />
        </React.Fragment>
      );
    }

    const urlParams = new URLSearchParams(window.location.search);
    setGraphData(graphData => ({
      ...graphData,
      showGraph: true
    }));
    let scheduleDateTemp;
    let clientDateTemp;
    if (
      Auth.getUserInfo().role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
    ) {
      scheduleDateTemp = clientScheduleDate ? clientScheduleDate.id : null;
      clientDateTemp = Auth.getUserInfo().id;
    } else if (
      Auth.getUserInfo().role.name === process.env.REACT_APP_NUTRITIONIST_ROLE
    ) {
      scheduleDateTemp = urlParams.get("s");
      clientDateTemp = urlParams.get("c");
    }
    let array = [];
    await providerForGet(
      getCholestrolGraphData,
      {
        s: scheduleDateTemp,
        c: clientDateTemp,
        dateFilter: dateFilter.fromDate ? true : false,
        noOfVisits: dateFilter.noOfVisits
          ? parseInt(dateFilter.noOfVisits)
          : null,
        fromDate: dateFilter.fromDate ? dateFilter.fromDate : null,
        toDate: dateFilter.toDate ? dateFilter.toDate : null,
        desc: clientScheduleDate
          ? `Client ${Auth.getUserInfo().full_name} viewed Cholestrol Graph`
          : `Nutritionist ${
              Auth.getUserInfo().full_name
            } viewed Cholestrol Graph of a client`,
        audit_log_clientid: clientScheduleDate
          ? Auth.getUserInfo().id
          : urlParams.get("c")
      },
      Auth.getToken()
    )
      .then(res => {
        if (res.data.cholestrolGraphData) {
          array.push(res.data.cholestrolGraphData);
        }

        setGraphColor(graphColor => ({
          ...graphColor,
          graphAreaColor: res.data.color.areaColor,
          graphFillColor: res.data.color.fillColor
        }));
        setFormState(formState => ({
          ...formState,
          checked: {
            ...formState.checked,
            [cholesterol]: true,
            [hdl]: true,
            [ldl]: true,
            [trigs]: true,
            [HB1AC]: true
          }
        }));
        setGraphData(graphData => ({
          ...graphData,
          graphDataArray: array,
          showGraph: false
        }));
        setLoader(false);
      })
      .catch(error => {
        console.log("error", error);
        setGraphData(graphData => ({
          ...graphData,
          showGraph: false
        }));
      });
  };

  useEffect(() => {
    setLoader(true);
    if (clientView === true) {
      getScheduleDates();
    } else {
      getInitialData();
      getPreviousData();
      getGraphData();
    }
  }, []);

  const createFormData = body => {
    const data = new FormData();
    data.append("data", JSON.stringify(body));

    return data;
  };

  /**functions for popover */
  const open = Boolean(anchorEl);
  const popOverId = open ? "simple-popover" : undefined;

  const handleClickPopUpOpen = event => {
    /**
     * for opening the popover button
     */
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    /**
     * for closing popover button
     */
    setAnchorEl(null);
  };

  const handlePopUpCheckBox = event => {
    if (event.target.name === "recentVisits" && event.target.checked === true) {
      setState({
        ...state,
        recentVisits: true,
        selectDateRange: false,
        noOfVisits: false
      });
      setDateFilter(dateFilter => ({
        ...dateFilter,
        filterBy: null,
        fromDate: null,
        toDate: "",
        dateFromError: false,
        dateToError: false,
        dateNoOfVisitsError: false,
        dateFromErrorText: "",
        dateToErrorText: "",
        dateNoOfVisitsErrorText: "",
        filterSelectError: false,
        filterSelectErrorText: "",
        gte_key: "",
        lte_key: "",
        noOfVisits: null
      }));
    }
    if (
      event.target.name === "selectDateRange" &&
      event.target.checked === true
    ) {
      setState({
        ...state,
        recentVisits: false,
        selectDateRange: true,
        noOfVisits: false
      });
      setDateFilter(dateFilter => ({
        ...dateFilter,
        filterBy: null,
        fromDate: null,
        toDate: "",
        dateFromError: false,
        dateToError: false,
        dateNoOfVisitsError: false,
        dateFromErrorText: "",
        dateToErrorText: "",
        dateNoOfVisitsErrorText: "",
        filterSelectError: false,
        filterSelectErrorText: "",
        gte_key: "",
        lte_key: "",
        noOfVisits: null
      }));
    }
    if (event.target.name === "noOfVisits" && event.target.checked === true) {
      setState({
        ...state,
        recentVisits: false,
        selectDateRange: false,
        noOfVisits: true
      });
      setDateFilter(dateFilter => ({
        ...dateFilter,
        filterBy: null,
        fromDate: null,
        toDate: "",
        dateFromError: false,
        dateToError: false,
        dateNoOfVisitsError: false,
        dateFromErrorText: "",
        dateToErrorText: "",
        dateNoOfVisitsErrorText: "",
        filterSelectError: false,
        filterSelectErrorText: "",
        gte_key: "",
        lte_key: "",
        noOfVisits: null
      }));
    }
    if (!event.target.checked) {
      setState({
        ...state,
        recentVisits: true,
        selectDateRange: false,
        noOfVisits: false
      });
      setDateFilter(dateFilter => ({
        ...dateFilter,
        filterBy: null,
        fromDate: null,
        toDate: "",
        dateFromError: false,
        dateToError: false,
        dateNoOfVisitsError: false,
        dateFromErrorText: "",
        dateToErrorText: "",
        dateNoOfVisitsErrorText: "",
        filterSelectError: false,
        filterSelectErrorText: "",
        gte_key: "",
        lte_key: "",
        noOfVisits: null
      }));
    }
  };

  const handleInputChange = event => {
    let error = false;
    let errorText = "";

    setDateFilter(dateFilter => ({
      ...dateFilter,
      noOfVisits: event.target.value,
      dateNoOfVisitsError: error,
      dateNoOfVisitsErrorText: errorText
    }));
  };

  /** Handle Start Date filter change */
  const handleStartDateChange = event => {
    let startDate = moment(event).format("YYYY-MM-DDT00:00:00.000Z");
    let fromDate = null;
    let dateFromError = false;
    let dateFromErrorText = "";
    if (startDate !== "Invalid date") {
      fromDate = new Date(startDate).toISOString();
      if (
        dateFilter.toDate &&
        dateFilter.toDate !== "" &&
        moment(fromDate).isAfter(moment(dateFilter.toDate))
      ) {
        dateFromError = true;
        dateFromErrorText = ["From date cannot be greater than to date"];
      }
    }

    setDateFilter(dateFilter => ({
      ...dateFilter,
      fromDate: fromDate,
      dateFromError: dateFromError,
      dateFromErrorText: dateFromErrorText
    }));
  };

  /** Handle End Date filter change */
  const handleEndDateChange = event => {
    let endDate = moment(event).endOf("day").format("YYYY-MM-DDT23:59:59.999Z");
    let toDate = "";
    let dateToError = false;
    let dateToErrorText = "";
    if (endDate !== "Invalid date") {
      toDate = new Date(endDate).toISOString();
      if (
        dateFilter.fromDate &&
        dateFilter.fromDate !== "" &&
        moment(endDate).isBefore(moment(dateFilter.fromDate))
      ) {
        dateToError = true;
        dateToErrorText = ["To date cannot be less than from date"];
      }
    }

    setDateFilter(dateFilter => ({
      ...dateFilter,
      toDate: toDate,
      dateToError: dateToError,
      dateToErrorText: dateToErrorText
    }));
  };

  const handleClickFilterDoneButton = () => {
    /**
     * validation for no of visits
     */
    if (state.noOfVisits) {
      if (!dateFilter.noOfVisits) {
        setDateFilter(dateFilter => ({
          ...dateFilter,
          dateNoOfVisitsError: true,
          dateNoOfVisitsErrorText: ["Please enter no. of visits"]
        }));
      }
      if (dateFilter.noOfVisits === 0) {
        setDateFilter(dateFilter => ({
          ...dateFilter,
          dateNoOfVisitsError: true,
          dateNoOfVisitsErrorText: ["No. of visits must be greater than 0"]
        }));
      }
      if (dateFilter.noOfVisits < 0) {
        setDateFilter(dateFilter => ({
          ...dateFilter,
          dateNoOfVisitsError: true,
          dateNoOfVisitsErrorText: ["No. of visits must be positive value"]
        }));
      }
      if (
        dateFilter.noOfVisits &&
        dateFilter.noOfVisits !== 0 &&
        dateFilter.noOfVisits > 0
      ) {
        setState({
          ...state,

          filterButtonName: "No. Of Visits"
        });
        handleClose();
        getGraphData();
      }
    }
    /**
     * for daterange and recentvisits
     */
    if (state.recentVisits) {
      setState({
        ...state,

        filterButtonName: "Recent 6 Visits"
      });
      handleClose();
      getGraphData();
    }
    if (state.selectDateRange) {
      setState({
        ...state,

        filterButtonName: "Selected Date Range"
      });
      handleClose();
      getGraphData();
    }
  };

  const handleClickFilterResetButton = () => {
    handleClose();
    setDateFilter(dateFilter => ({
      ...dateFilter,
      filterBy: null,
      fromDate: null,
      toDate: "",
      dateFromError: false,
      dateToError: false,
      dateNoOfVisitsError: false,
      dateFromErrorText: "",
      dateToErrorText: "",
      dateNoOfVisitsErrorText: "",
      filterSelectError: false,
      filterSelectErrorText: "",
      gte_key: "",
      lte_key: "",
      noOfVisits: null
    }));

    setState({
      ...state,

      recentVisits: true,
      selectDateRange: false,
      noOfVisits: false,
      filterButtonName: "Recent 6 Visits"
    });
    getGraphData();
  };

  const handleChangeInput = async event => {
    const floatRegExp = new RegExp(
      process.env.REACT_APP_REG_EX_FOR_EMPTY_STRING
    );
    let value = event.target.value;
    let percentValue = 0;
    if (value !== "" && floatRegExp.test(value)) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: value
        }
      }));
      if (formState.percentValues.hasOwnProperty(event.target.name)) {
        percentValue =
          (parseFloat(value) / formState.limit[event.target.name]) * 100;
        setFormState(formState => ({
          ...formState,
          percentValues: {
            ...formState.percentValues,
            [event.target.name]: percentValue.toFixed(1)
          }
        }));
      }
    } else if (event.target.value === "") {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: 0
        }
      }));

      if (formState.percentValues.hasOwnProperty(event.target.name)) {
        percentValue =
          (parseFloat(0) / formState.limit[event.target.name]) * 100;
        setFormState(formState => ({
          ...formState,
          percentValues: {
            ...formState.percentValues,
            [event.target.name]: percentValue
          }
        }));
      }
    }
  };

  const saveChanges = async (doNotShowMessage = false) => {
    const urlParams = new URLSearchParams(window.location.search);
    let editId = urlParams.get("c");
    let dataToPost = {
      cholesterol: parseFloat(formState.values.cholesterol),
      hdl: parseFloat(formState.values.hdl),
      ldl: parseFloat(formState.values.ldl),
      ratio: parseFloat(formState.values.ratio),
      trigs: parseFloat(formState.values.trigs),
      vldl: parseFloat(formState.values.vldl),
      cr: parseFloat(formState.values.cr),
      glucose: parseFloat(formState.values.glucose),
      HB1AC: parseFloat(formState.values.HB1AC),
      cardiac_risk_years: parseFloat(formState.values.cardiac_risk_years),
      Hematocrit: parseFloat(formState.values.Hematocrit),
      schedule_id: formState.scheduleId,
      clientId: editId
    };
    if (statusVariable.isAdd === true) {
      await providerForPost(cholesterols_url, dataToPost, Auth.getToken(), {
        desc: "Add Cholestrol Info",
        audit_log_clientid: editId
      })
        .then(res => {
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isAdd: false,
            isSaving: false,
            savedTime: new Date()
          }));
          if (doNotShowMessage !== true) {
            setStatusVariable(statusVariable => ({
              ...statusVariable,

              isOpenSnackBar: true,
              isError: "success",
              successErrorMessage: "Measurement data " + SAVE_MESSAGE
            }));
          }
          setFormState(formState => ({
            ...formState,
            measurementsId: res.data.id
          }));
          setLoader(false);
        })
        .catch(error => {
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isAdd: true,
            isSaving: false,
            savedTime: null,
            isOpenSnackBar: true,
            isError: "error",
            successErrorMessage: ERROR_MESSAGE + error
          }));
          setLoader(false);
        });
    } else if (statusVariable.isAdd === false) {
      let desc = {};
      /** This is done so that audit log doesn't get overfilled
       * initially the saved time is null so at that time when the data updates the audit log entry will be made
       * later the entry will not be made so as to prevent the audit log table to get overfilled by update entries
       */
      if (!statusVariable.savedTime) {
        desc = {
          desc: "Update Cholestrol Info",
          audit_log_clientid: editId
        };
      }
      await providerForPut(
        cholesterols_url,
        formState.measurementsId,
        dataToPost,
        Auth.getToken(),
        desc
      )
        .then(res => {
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isSaving: false,
            savedTime: new Date()
          }));
          if (doNotShowMessage !== true) {
            setStatusVariable(statusVariable => ({
              ...statusVariable,

              isOpenSnackBar: true,
              isError: "success",
              successErrorMessage: "Measurement data " + UPDATE_MESSAGE
            }));
          }
          setLoader(false);
        })
        .catch(error => {
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isSaving: false,
            savedTime: null,
            isOpenSnackBar: true,
            isError: "error",
            successErrorMessage: ERROR_MESSAGE + error
          }));
          setLoader(false);
        });
    }
  };

  const handleSaveOnBlur = async () => {
    let doNotShowMessage = true;
    await saveChanges(doNotShowMessage);
    await getGraphData();
  };

  const handleSnackbarClose = () => {
    setStatusVariable(statusVariable => ({
      ...statusVariable,
      isOpenSnackBar: false,
      isError: "",
      successErrorMessage: ""
    }));
  };

  const handleChangeRadio = event => {
    setRadioValue(event);
  };

  const getKeyName = key => {
    let name = "";
    switch (key) {
      case "cholesterol":
        name = "Cholesterol";
        break;
      case "hdl":
        name = "HDL";
        break;
      case "ldl":
        name = "LDL";
        break;
      case "ratio":
        name = "Ratio";
        break;
      case "trigs":
        name = "Trigs";
        break;
      case "VLDL":
        name = "vldl";
        break;
      case "cr":
        name = "C/R";
        break;
      case "glucose":
        name = "Glucose";
        break;
      case "HB1AC":
        name = "HB1AC";
        break;
      case "cardiac_risk_years":
        name = "Cardiac Risk Years";
        break;
    }
    return name;
  };

  useEffect(() => {
    let array = [];

    Object.keys(formState.checked).map((key, i) => {
      if (formState.checked[key] === true) {
        let name = getKeyName(key);
        let gData = {};
        gData = {
          name: key,
          keyName: name,
          paramColor: graphColor.graphAreaColor[i],
          areaColor: graphColor.graphFillColor[i]
        };
        array.push(gData);
      }
      return null;
    });
    setYAxis(array);
  }, [formState.checked]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      return (
        <div
          className="subscribers-by-channel-tooltip"
          style={{
            backgroundColor: "white"
          }}
        >
          <p
            className="subscribers-by-channel-tooltip-label"
            style={{
              paddingTop: "5px",
              paddingLeft: "10px"
            }}
          >
            {"Schedule Data : "}
            {label}
          </p>
          {payload.map(data => (
            <p
              className="subscribers-by-channel-tooltip-value"
              style={{
                color: `${data.stroke}`,
                paddingLeft: "10px",
                marginBottom: "-10px"
              }}
            >
              {` ${data.name}`} : {` ${data.value}`}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  const onSaveFunction = async () => {
    setLoader(true);
    await saveChanges();
    await getGraphData();
  };

  const handleClick = event => {};

  const handleCheckedChange = event => {
    setFormState(formState => ({
      ...formState,
      checked: {
        ...formState.checked,
        [event.target.name]: event.target.checked
      }
    }));
  };

  const downloadGraph = async (measurementKeys, data, isPrint) => {
    setLoader(true);
    const urlParams = new URLSearchParams(window.location.search);
    let finalData = createFormData({
      s: clientView
        ? formState.clientFilter["scheduleDate"]
        : urlParams.get("s"),
      c: clientView ? Auth.getUserInfo().id : urlParams.get("c"),
      measurementKeys: measurementKeys,
      measurementData: data
    });
    if (clientView !== true) {
      await saveChanges();
    }
    setLoader(true);
    providerForDownloadWithGraph(printPdf, finalData, Auth.getToken(), {
      desc: "Download PDF for Cholesterol report",
      audit_log_clientid: clientView
        ? Auth.getUserInfo().id
        : urlParams.get("c")
    })
      .then(res => {
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          window.open(url,'_blank');
          // const pdfNewWindow = window.open();
          // pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "cholesterol.pdf");
          document.body.appendChild(link);
          link.click();
        }

        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        console.log("PrintError", error);
      });
    if (clientView !== true) {
      getGraphData();
    }
  };

  const handleDownload = async isPrint => {
    let measurementKeys = yAxis;
    let data = graphData.graphDataArray[0];
    downloadGraph(measurementKeys, data, isPrint);
  };

  const handleChangeAutoComplete = (eventName, event, value) => {
    if (eventName === "scheduleDate") {
      if (value !== null) {
        if (formState.scheduleDate !== null) {
          formState.scheduleDate = null;
        }
        setFormState(formState => ({
          ...formState,
          clientFilter: {
            ...formState.clientFilter,
            [eventName]: value.id,
            scheduleObj: value
          }
        }));
      } else {
        setFormState(formState => ({
          ...formState,
          clientFilter: {
            ...formState.clientFilter,
            [eventName]: formState.clientFilter.scheduleObj.id
          }
        }));
      }
    }
  };

  useEffect(() => {
    for (var i = 0; i < dateList.length; i++) {
      if (
        formState.clientFilter["scheduleDate"] === dateList[i].id &&
        formState.scheduleDate === null
      ) {
        getInitialData(dateList[i]);
        getPreviousData(dateList[i]);
        getGraphData(dateList[i]);
      }
    }
  }, [formState.clientFilter["scheduleDate"]]);

  useEffect(() => {
    if (formState.scheduleDate !== null) {
      getInitialData(formState.scheduleDate);
      getPreviousData(formState.scheduleDate);
      getGraphData(formState.scheduleDate);
    }
  }, [formState.scheduleDate]);

  const handleBackArrow = () => {
    let data = {};

    if (formState.fromData) {
      data["id"] = formState.clientFilter.scheduleObj.id;
      data["scheduleObj"] = formState.clientFilter.scheduleObj;
      history.push(formState.fromData, data);
    } else {
      history.push(CLIENTMYSCHEDULE, data);
    }
  };

  const handleDialogCancel = () => {
    setPopUpOpen(false);
  };

  const handleDialogOk = async () => {
    setLoader(true);
    let dataToPost = {
      measurements_client_notes: formState.notes ? formState.notes : null
    };
    await providerForPut(
      getClientScheduleData,
      formState.clientFilter.scheduleDate,
      dataToPost,
      Auth.getToken()
    )
      .then(res => {
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          isOpenSnackBar: true,
          isError: "success",
          successErrorMessage: "Measurement notes saved successfully!"
        }));

        handleDialogCancel();
        setLoader(false);
      })
      .catch(error => {
        handleDialogCancel();
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          isOpenSnackBar: true,
          isError: "error",
          successErrorMessage: ERROR_MESSAGE + error
        }));
        setLoader(false);
      });
  };

  const handleTextChange = event => {
    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  return (
    <>
      {Auth.getUserInfo().role.name !==
        process.env.REACT_APP_CLIENT_ROLE_NAME && (
        <Prompt
          message={(location, action) => {
            onSaveFunction();
          }}
        />

      )}
      <>

        <DialogBox
          skipCancel={false}
          skipOk={false}
          open={popUpOpen}
          title={"Add/Edit Measurement Notes"}
          children={
            <div>
              <Grid container spacing={1} className={classes.notesSection}>
                <p style={{ marginTop: "-1.25rem" }}>
                  {" "}
                  Schedule date :{" "}
                  {formState.clientFilter &&
                    formState.clientFilter.scheduleObj &&
                    formState.clientFilter.scheduleObj["schedule_start_date"]}
                </p>
                <Grid item md={12} xs={12}>
                  <Input
                    fullWidth
                    multiline
                    id="notes"
                    name="notes"
                    onChange={handleTextChange}
                    value={formState.notes}
                    rows={15}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </div>
          }
          handleOk={handleDialogOk}
          handleCancel={handleDialogCancel}
          buttonCancel={"Cancel"}
          buttonOk={"SAVE"}
        />
        {Auth.getUserInfo().role.name ===
          process.env.REACT_APP_CLIENT_ROLE_NAME && (
          <>
            <IconButton
              className={Styles.backIcon}
              onClick={() => handleBackArrow()}
            >
              <ChevronLeftIcon
                style={{
                  padding: "5px",
                  border: "solid #DADADA 1px",
                  borderRadius: "25px",
                  margin: "0px 1.875rem 0px 10px",
                  background: "#fff",
                  marginTop: 0
                }}
              />
            </IconButton>
            <HeadingOne>
              <img
                width="auto"
                height="1.875rem"
                src={MeasurementImg}
                alt={"MeasurementImg"}
              />
              <span style={{ position: "absolute", marginLeft: "10px" }}>
                MEASUREMENTS
              </span>
            </HeadingOne>
          </>
        )}
      </>
      {scheduleDateNotPresent === true ? (
        <Grid>
          <img
            src={noSchedule}
            className={classes.centerImage}
            alt="No schedule/data"
          />
        </Grid>
      ) : (
        <>
          {Auth.getUserInfo().role.name ===
            process.env.REACT_APP_CLIENT_ROLE_NAME && (
            <Grid container spacing={1} style={{ marginBottom: 15 }}>
              {/* Add schedule auto complete */}
              <Grid item md={4} xs={12}>
                <AutoCompleteDropDown
                  options={dateList}
                  getOptionLabel={option => option.schedule_start_date}
                  id="schedule_date"
                  value={
                    dateList[
                      dateList.findIndex(function (item, i) {
                        return (
                          item.id === formState.clientFilter["scheduleDate"]
                        );
                      })
                    ] || null
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("scheduleDate", event, value);
                  }}
                  renderInput={params => (
                    <Input
                      {...params}
                      id="schedule_date"
                      label="Schedule Date"
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          <Container
            className={classes.ClientMeasurementContainer}
            maxWidth={false}
          >
            <Grid container>
              <Grid
                container
                item
                xs={12}
                justify="flex-end"
                alignItems="center"
              >
                <IconButton
                  onClick={() => handleDownload(true)}
                  style={{ marginRight: 15, padding: 0, background: "none" }}
                >
                  <TooltipForButton title="Print Report">
                    <PrintIcon style={{ color: "#1C4979", fontSize: 30 }} />
                  </TooltipForButton>
                </IconButton>

                <IconButton
                  onClick={() => handleDownload(false)}
                  style={{ marginRight: 15, padding: 0, background: "none" }}
                >
                  <TooltipForButton title="Download Report">
                    <GetAppIcon style={{ color: "#1C4979", fontSize: 30 }} />
                  </TooltipForButton>
                </IconButton>

                {Auth.getUserInfo().role.name !==
                  process.env.REACT_APP_CLIENT_ROLE_NAME && (
                  <DarkBlueButton
                    type="button"
                    variant="contained"
                    onClick={onSaveFunction}
                    disabled={formState.isActive === false}
                  >
                    Save
                  </DarkBlueButton>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={5}>
                <div className={classes.trRoot}>
                  <Table className={Styles.measurementTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.borderNone}></TableCell>
                        <TableCell className={classes.borderNone}>
                          <Typography className={classes.cardTitleStyle}>
                            Category
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={classes.borderNone}
                          align="center"
                        >
                          <Typography className={classes.cardTitleStyle}>
                            Actual
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={classes.borderNone}
                          align="center"
                        >
                          <Typography className={classes.cardTitleStyle}>
                            Normal
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={classes.borderNone}
                          align="center"
                        >
                          <Typography className={classes.cardTitleStyle}>
                            %
                          </Typography>
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.borderNone}
                            align="center"
                          >
                            <Typography className={classes.cardTitleStyle}>
                              Previous
                            </Typography>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked[cholesterol]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              className={classes.label}
                              control={
                                <Checkbox
                                  name={cholesterol}
                                  onChange={handleCheckedChange}
                                  checked={
                                    formState.checked[cholesterol]
                                      ? true
                                      : false
                                  }
                                />
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          Cholesterol
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.inputRoot}>
                            <Input
                              placeholder="0"
                              id="standard-adornment-weight"
                              value={formState.values[cholesterol] || ""}
                              name={cholesterol}
                              onChange={handleChangeInput}
                              onBlur={handleSaveOnBlur}
                              variant="outlined"
                              disabled = {formState.isActive === false || clientView === true ? true : false}
                              size="small"
                              inputProps={{
                                "aria-label": "weight",
                                readOnly: clientView === true ? true : false
                              }}
                              error={
                                clientView !== true &&
                                formState.values[cholesterol] >
                                  formState.limit.cholesterol
                                  ? true
                                  : false
                              }
                              InputProps={{ style: { textAlign: "center" } }}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          {"<"}200
                          <br />
                          100 + age
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          {convertNumber(formState.percentValues.cholesterol)}%
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(
                              formState.previousValues.cholesterol
                            )}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked[hdl]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              className={classes.label}
                              control={
                                <Checkbox
                                  name={hdl}
                                  onChange={handleCheckedChange}
                                  checked={
                                    formState.checked[hdl] ? true : false
                                  }
                                />
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          HDL
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.inputRoot}>
                            <Input
                              placeholder="0"
                              id="standard-adornment-weight"
                              value={formState.values[hdl] || ""}
                              name={hdl}
                              onChange={handleChangeInput}
                              onBlur={handleSaveOnBlur}
                              variant="outlined"
                              disabled = {formState.isActive === false || clientView === true ? true : false}
                              size="small"
                              inputProps={{
                                "aria-label": "weight",
                                readOnly: clientView === true ? true : false
                              }}
                              error={
                                clientView !== true &&
                                formState.values[hdl] < 35
                              }
                              InputProps={{ style: { textAlign: "center" } }}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          {">"}35
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          {`${convertNumber(formState.percentValues.hdl)}%`}
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(formState.previousValues.hdl)}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked[ldl]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              className={classes.label}
                              control={
                                <Checkbox
                                  name={ldl}
                                  onChange={handleCheckedChange}
                                  checked={
                                    formState.checked[ldl] ? true : false
                                  }
                                />
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          LDL
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.inputRoot}>
                            <Input
                              placeholder="0"
                              id="standard-adornment-weight"
                              value={formState.values[ldl] || ""}
                              name={ldl}
                              onChange={handleChangeInput}
                              onBlur={handleSaveOnBlur}
                              variant="outlined"
                              disabled = {formState.isActive === false || clientView === true ? true : false}
                              size="small"
                              inputProps={{
                                "aria-label": "weight",
                                readOnly: clientView === true ? true : false
                              }}
                              error={
                                clientView !== true &&
                                formState.values[ldl] > 130
                              }
                              InputProps={{ style: { textAlign: "center" } }}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          {"<"}130
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          {`${convertNumber(formState.percentValues.ldl)}%`}
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {`${convertNumber(formState.previousValues.ldl)}`}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked[ratio]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              className={classes.label}
                              control={
                                <Checkbox
                                  name={ratio}
                                  onChange={handleCheckedChange}
                                  checked={
                                    formState.checked[ratio] ? true : false
                                  }
                                />
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          Ratio
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.inputRoot}>
                            <Input
                              placeholder="0"
                              id="standard-adornment-weight"
                              value={formState.values[ratio] || ""}
                              name={ratio}
                              onChange={handleChangeInput}
                              onBlur={handleSaveOnBlur}
                              variant="outlined"
                              disabled = {formState.isActive === false || clientView === true ? true : false}
                              size="small"
                              inputProps={{
                                "aria-label": "weight",
                                readOnly: clientView === true ? true : false
                              }}
                              error={
                                clientView !== true &&
                                formState.values[ratio] > formState.limit.ratio
                              }
                              InputProps={{ style: { textAlign: "center" } }}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          F: {"<"}4.5
                          <br />
                          M: {"<"}3.5
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          {`${convertNumber(formState.percentValues.ratio)}%`}
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {`${convertNumber(formState.previousValues.ratio)}`}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked[trigs]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              className={classes.label}
                              control={
                                <Checkbox
                                  name={trigs}
                                  onChange={handleCheckedChange}
                                  checked={
                                    formState.checked[trigs] ? true : false
                                  }
                                />
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          Trigs 
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.inputRoot}>
                            <Input
                              placeholder="0"
                              id="standard-adornment-weight"
                              value={formState.values[trigs] || ""}
                              name={trigs}
                              onChange={handleChangeInput}
                              onBlur={handleSaveOnBlur}
                              variant="outlined"
                              disabled = {formState.isActive === false || clientView === true ? true : false}
                              size="small"
                              inputProps={{
                                "aria-label": "weight",
                                readOnly: clientView === true ? true : false
                              }}
                              error={
                                clientView !== true &&
                                formState.values[trigs] > 175
                              }
                              InputProps={{ style: { textAlign: "center" } }}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          {"<"}175
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          {`${convertNumber(formState.percentValues.trigs)}%`}
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {`${convertNumber(formState.previousValues.trigs)}`}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked[vldl]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              className={classes.label}
                              control={
                                <Checkbox
                                  name={vldl}
                                  onChange={handleCheckedChange}
                                  checked={formState.checked[vldl]}
                                />
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          VLDL
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.inputRoot}>
                            <Input
                              placeholder="0"
                              id="standard-adornment-weight"
                              value={formState.values[vldl] || ""}
                              name={vldl}
                              onChange={handleChangeInput}
                              onBlur={handleSaveOnBlur}
                              variant="outlined"
                              disabled = {formState.isActive === false || clientView === true ? true : false}
                              size="small"
                              inputProps={{
                                "aria-label": "weight",
                                readOnly: clientView === true ? true : false
                              }}
                              InputProps={{ style: { textAlign: "center" } }}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        ></TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        ></TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {`${convertNumber(formState.previousValues.vldl)}`}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked[cr]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              className={classes.label}
                              control={
                                <Checkbox
                                  name={cr}
                                  onChange={handleCheckedChange}
                                  checked={formState.checked[cr]}
                                />
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          C/R
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.inputRoot}>
                            <Input
                              placeholder="0"
                              id="standard-adornment-weight"
                              value={formState.values[cr] || ""}
                              name={cr}
                              onChange={handleChangeInput}
                              onBlur={handleSaveOnBlur}
                              variant="outlined"
                              disabled = {formState.isActive === false || clientView === true ? true : false}
                              size="small"
                              inputProps={{
                                "aria-label": "weight",
                                readOnly: clientView === true ? true : false
                              }}
                              InputProps={{ style: { textAlign: "center" } }}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          5 Years
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        ></TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(formState.previousValues.cr)}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked[glucose]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              className={classes.label}
                              control={
                                <Checkbox
                                  name={glucose}
                                  onChange={handleCheckedChange}
                                  checked={formState.checked[glucose]}
                                />
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          Glucose
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.inputRoot}>
                            <Input
                              placeholder="0"
                              id="standard-adornment-weight"
                              value={formState.values[glucose] || ""}
                              name={glucose}
                              onChange={handleChangeInput}
                              onBlur={handleSaveOnBlur}
                              variant="outlined"
                              disabled = {formState.isActive === false || clientView === true ? true : false}
                              size="small"
                              inputProps={{
                                "aria-label": "weight",
                                readOnly: clientView === true ? true : false
                              }}
                              InputProps={{ style: { textAlign: "center" } }}
                              error={
                                clientView !== true &&
                                formState.values[glucose] > 120
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          {"<"}120
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          {`${convertNumber(formState.percentValues.glucose)}%`}
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {`${convertNumber(
                              formState.previousValues.glucose
                            )}`}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked[HB1AC]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              className={classes.label}
                              control={
                                <Checkbox
                                  name={HB1AC}
                                  onChange={handleCheckedChange}
                                  checked={formState.checked[HB1AC]}
                                />
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          HB1AC
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.inputRoot}>
                            <Input
                              placeholder="0"
                              id="standard-adornment-weight"
                              value={formState.values[HB1AC] || ""}
                              name={HB1AC}
                              onChange={handleChangeInput}
                              onBlur={handleSaveOnBlur}
                              variant="outlined"
                              disabled = {formState.isActive === false || clientView === true ? true : false}
                              size="small"
                              inputProps={{
                                "aria-label": "weight",
                                readOnly: clientView === true ? true : false
                              }}
                              InputProps={{ style: { textAlign: "center" } }}
                              error={
                                clientView !== true &&
                                formState.values[HB1AC] > 5.7
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          {"<"}5.7
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        ></TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(formState.previousValues.HB1AC)}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked[Hematocrit]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              className={classes.label}
                              control={
                                <Checkbox
                                  name={Hematocrit}
                                  onChange={handleCheckedChange}
                                  checked={formState.checked[Hematocrit]}
                                />
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          Hematocrit
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.inputRoot}>
                            <Input
                              placeholder="0"
                              id="standard-adornment-weight"
                              value={formState.values[Hematocrit] || ""}
                              name={Hematocrit}
                              onChange={handleChangeInput}
                              onBlur={handleSaveOnBlur}
                              variant="outlined"
                              disabled = {formState.isActive === false || clientView === true ? true : false}
                              size="small"
                              inputProps={{
                                "aria-label": "weight",
                                readOnly: clientView === true ? true : false
                              }}
                              InputProps={{ style: { textAlign: "center" } }}
                              error={
                                clientView !== true &&
                                formState.values[Hematocrit] > 52
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          F: 44 - 48
                          <br />
                          M: 48 - 52
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        ></TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(formState.previousValues.Hematocrit)}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked[cardiac_risk_years]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              className={classes.label}
                              control={
                                <Checkbox
                                  name={cardiac_risk_years}
                                  onChange={handleCheckedChange}
                                  checked={
                                    formState.checked[cardiac_risk_years]
                                  }
                                />
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          Cardiac Risk Years
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.inputRoot}>
                            <Input
                              placeholder="0"
                              id="standard-adornment-weight"
                              value={formState.values[cardiac_risk_years] || ""}
                              name={cardiac_risk_years}
                              onChange={handleChangeInput}
                              onBlur={handleSaveOnBlur}
                              variant="outlined"
                              disabled = {formState.isActive === false || clientView === true ? true : false}
                              size="small"
                              inputProps={{
                                "aria-label": "weight",
                                readOnly: clientView === true ? true : false
                              }}
                              InputProps={{ style: { textAlign: "center" } }}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        ></TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        ></TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(
                              formState.previousValues.cardiac_risk_years
                            )}
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </Grid>
              <Grid item xs={12} lg={7}>
                <Grid
                  item
                  xs={12}
                  md={8}
                  style={{
                    marginLeft: "7rem"
                  }}
                >
                  <DateRange
                    id={popOverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClick={handleClickPopUpOpen}
                    handlePopUpCheckBox={handlePopUpCheckBox}
                    onClose={handleClose}
                    handleInputChange={handleInputChange}
                    recentVisits={state.recentVisits}
                    selectDateRange={state.selectDateRange}
                    noOfVisits={state.noOfVisits}
                    valueNoOfVisitis={dateFilter.noOfVisits}
                    valueFromDate={dateFilter.fromDate}
                    valueToDate={dateFilter.toDate}
                    dateFromError={dateFilter.dateFromError}
                    dateToError={dateFilter.dateToError}
                    dateFromErrorText={dateFilter.dateFromErrorText}
                    dateToErrorText={dateFilter.dateToErrorText}
                    dateNoOfVisitsError={dateFilter.dateNoOfVisitsError}
                    dateNoOfVisitsErrorText={dateFilter.dateNoOfVisitsErrorText}
                    handleStartDateChange={event =>
                      handleStartDateChange(event)
                    }
                    handleEndDateChange={event => handleEndDateChange(event)}
                    handleClickFilterResetButton={handleClickFilterResetButton}
                    handleClickFilterDoneButton={handleClickFilterDoneButton}
                    filterButtonName={state.filterButtonName}
                  ></DateRange>
                </Grid>
                <Card
                  elevation={0}
                  className={isDesktop ? classes.paddingStyle : null}
                >
                  <CardHeader
                    title={
                      <div>
                        <Typography className={classes.cardHeaderStyle}>
                          Cholestrol and HDL Graph
                        </Typography>
                      </div>
                    }
                    action={
                      <Grid container>
                        <Grid item>
                          <div
                            style={{
                              textAlign: "right",
                              padding: "0rem 1.875rem"
                            }}
                          >
                            <BarChartIcon
                              variant="outlined"
                              style={{
                                height: "35px",
                                width: "35px",
                                marginRight: "15px",
                                color:
                                  radioValue === "Bar Graph"
                                    ? "#1C4979"
                                    : "#808080"
                              }}
                              onClick={() => handleChangeRadio("Bar Graph")}
                            />

                            <TimelineIcon
                              variant="contained"
                              style={{
                                height: "35px",
                                width: "35px",
                                marginRight: "15px",
                                color:
                                  radioValue === "Line Graph"
                                    ? "#1C4979"
                                    : "#808080"
                              }}
                              onClick={() => handleChangeRadio("Line Graph")}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    }
                  />

                  <CardContent>
                    <Box className={classes.responsiveContainerWrap}>
                      {graphData.showGraph ? (
                        <div style={{ marginTop: "150px" }}>
                          <CircularProgress color="secondary" />
                        </div>
                      ) : radioValue === "Line Graph" ? (
                        <ResponsiveContainer
                          id="responsiveContainer"
                          width="100%"
                          height={400}
                        >
                          <LineChart
                            width={imageSize.width}
                            height={imageSize.height}
                            data={graphData.graphDataArray[0]}
                            margin={{
                              top: 5,
                              bottom: 5
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey={xAxis[0]}
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                color: "#8A8A97"
                              }}
                            />
                            <YAxis
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                color: "#8A8A97"
                              }}
                            />
                            <Tooltip
                              content={<CustomTooltip />}
                              cursor={false}
                              position="top"
                            />
                            <Legend
                              iconType="square"
                              onClick={handleClick}
                              iconSize={18}
                              formatter={renderColorfulLegendText}
                              wrapperStyle={{ bottom: "-20px" }}
                            />
                            {yAxis.map((number, index) => (
                              <Line
                                connectNulls
                                id={index}
                                type="monotone"
                                dataKey={number.name}
                                stroke={number.paramColor}
                                strokeWidth={2.5}
                              />
                            ))}
                          </LineChart>
                        </ResponsiveContainer>
                      ) : (
                        <ResponsiveContainer
                          id="responsiveContainer"
                          width="100%"
                          height={400}
                        >
                          <BarChart
                            width={imageSize.width}
                            height={imageSize.height}
                            data={graphData.graphDataArray[0]}
                            margin={{
                              top: 5,
                              bottom: 5
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              interval={0}
                              dataKey={xAxis[0]}
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                color: "#8A8A97"
                              }}
                            />
                            <YAxis
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                color: "#8A8A97"
                              }}
                            />
                            <Tooltip
                              content={<CustomTooltip />}
                              cursor={false}
                              position="top"
                            />
                            <Legend
                              iconType="square"
                              onClick={handleClick}
                              iconSize={18}
                              formatter={renderColorfulLegendText}
                              wrapperStyle={{ bottom: "-20px" }}
                            />
                            {yAxis.map((number, index) => (
                              <Bar
                                id={index}
                                type="monotone"
                                dataKey={number.name}
                                stroke={number.paramColor}
                                fill={number.areaColor}
                                strokeWidth={2.5}
                              />
                            ))}
                          </BarChart>
                        </ResponsiveContainer>
                      )}
                    </Box>
                  </CardContent>
                </Card>
                <Backdrop className={classes.backdrop} open={loader}>
                  <CircularProgress color="inherit" />
                </Backdrop>

                <Grid>
                  <SnackBar
                    open={statusVariable.isOpenSnackBar}
                    severity={statusVariable.isError}
                    onClose={handleSnackbarClose}
                  >
                    {statusVariable.successErrorMessage}
                  </SnackBar>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

export default Measurements;
