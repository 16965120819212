import React, { useState, useEffect } from "react";
import {
  Auth,
  AutoCompleteDropDown,
  DarkBlueButton,
  HeadingOne,
  Input,
  SnackBar,
  WhiteIconButton
} from "../../components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { providerForGet, providerForPut } from "../../api";
import {
  getClientScheduleData,
  getScheduleDateForClient,
  getExchangeDayClientNotes
} from "../../constants";
import { convertDate, useStyles } from "../../utils";
import {
  Backdrop,
  CircularProgress,
  Grid,
  TextField,
  Tooltip
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ERROR_MESSAGE } from "../../constants/genericConstants";
import { CLIENTBODYMASS, CLIENTBODYMASSNOTES } from "../../paths";

const ClientExchangeNotes = props => {
  const auth = Auth.getToken();
  let propData = props["location"]["state"];
  const classes = useStyles();
  const noScheduleDataMessageFromBackend = "No schedule data";
  const history = useHistory();
  const userInfo = Auth.getUserInfo();
  const [loader, setLoader] = useState(false);
  const [dateList, setDateList] = useState([]);
  const [scheduleDateNotPresent, setScheduleDateNotPresent] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    exchangeDay: propData ? propData["id"] : 1,
    scheduleId: propData ? propData["id"] : null,
    scheduleObj: propData ? propData.scheduleObj : null,
    defaultScheduleObj: null,
    alert: false,
    errorMessage: "",
    severity: "success",
    notes: null,
    editData: false,
    exchange_day_id: null,
    isExchangeDayPresent: false
  });

  const getNotesData = (
    schedule_id = formState.scheduleId,
    ex_day = formState.exchangeDay
  ) => {
    setLoader(true);
    providerForGet(
      getExchangeDayClientNotes,
      {
        schedule_id: schedule_id,
        ex_day: ex_day
      },

      auth
    )
      .then(async res => {
        if (res.data.id) {
          setFormState(formState => ({
            ...formState,
            exchange_day_id: res.data.id,
            isExchangeDayPresent: true,
            notes: res.data.client_notes
          }));
        } else {
          setFormState(formState => ({
            ...formState,
            exchange_day_id: null,
            isExchangeDayPresent: false,
            notes: ""
          }));
        }
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        console.log("error", error.response);
        if (
          error.response &&
          error.response.data &&
          error.response.data.statusCode === 400 &&
          error.response.data.message === noScheduleDataMessageFromBackend
        ) {
          setScheduleDateNotPresent(true);
        }
      });
  };

  const getScheduleDates = () => {
    providerForGet(
      getScheduleDateForClient,
      {
        c: userInfo.id
      },
      auth
    )
      .then(async res => {
        let convertDateData = await convertDate(res.data);
        let sid = null;
        let ex_day = 1;
        setDateList(convertDateData);
        if (!formState.scheduleId) {
          sid = convertDateData[0].id;
          setFormState(formState => ({
            ...formState,
            exchangeDay: 1,
            scheduleId: convertDateData[0].id,
            scheduleObj: convertDateData[0],
            defaultScheduleObj: convertDateData[0]
          }));
        } else {
          ex_day = formState.exchangeDay;
          sid = formState.scheduleId;
        }
        getNotesData(sid, ex_day);
      })
      .catch(error => {
        console.log("error", error.response);
        if (
          error.response &&
          error.response.data &&
          error.response.data.statusCode === 400 &&
          error.response.data.message === noScheduleDataMessageFromBackend
        ) {
          setScheduleDateNotPresent(true);
        }
      });
  };

  useEffect(() => {
    getScheduleDates();
  }, []);

  const handleChangeAutoComplete = (eventName, event, value) => {
    if (eventName === "scheduleDate") {
      if (value !== null) {
        setFormState(formState => ({
          ...formState,
          scheduleId: value.id,
          scheduleObj: value,
          editData: false
        }));
      } else {
        setFormState(formState => ({
          ...formState,
          scheduleId: formState.scheduleObj.id,
          scheduleObj: formState.scheduleObj,
          editData: false
        }));
      }
    }
  };

  const handleChange = event => {
    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleEditClick = () => {
    setFormState(formState => ({
      ...formState,
      editData: true
    }));
  };

  const handleSave = async () => {
    setLoader(true);
    let dataToPost = {
      body_mass_client_notes: formState.notes ? formState.notes : null
    };
    await providerForPut(
      getClientScheduleData,
      formState.scheduleId,
      dataToPost,
      Auth.getToken()
    )
      .then(res => {
        setFormState(formState => ({
          ...formState,
          editData: false,
          alert: true,
          errorMessage: "Body Mass notes saved successfully!",
          severity: "success"
        }));
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        setFormState(formState => ({
          ...formState,
          editData: false,
          alert: true,
          errorMessage: ERROR_MESSAGE + "error",
          severity: "error"
        }));
      });
  };

  const handleViewTracking = () => {
    let data = {};
    if (scheduleDateNotPresent === true) {
      setFormState(formState => ({
        ...formState,
        alert: true,
        errorMessage: "Schedule Is not Present",
        severity: "error"
      }));
    } else {
      if (formState["scheduleObj"]) {
        data["id"] = formState.scheduleObj.id;
        data["from"] = CLIENTBODYMASSNOTES;
        history.push(CLIENTBODYMASS, data);
      }
    }
  };

  const handleCancel = () => {
    setFormState(formState => ({
      ...formState,
      editData: false
    }));
  };

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "error"
    }));
  };

  return (
    <div>
      <Grid>
        <SnackBar
          open={formState.alert}
          severity={formState.severity}
          onClose={handleSnackbarClose}
        >
          {formState.errorMessage}
        </SnackBar>
      </Grid>
      <HeadingOne>Body Mass Notes</HeadingOne>
      <Grid container spacing={1}>
        <Grid item md={4} xs={12}>
          <AutoCompleteDropDown
            options={dateList}
            getOptionLabel={option => option.schedule_start_date}
            id="schedule_date"
            value={
              dateList[
                dateList.findIndex(function (item, i) {
                  return item.id === formState.scheduleId;
                })
              ] || null
            }
            onChange={(event, value) => {
              handleChangeAutoComplete("scheduleDate", event, value);
            }}
            renderInput={params => (
              <Input {...params} id="schedule_date" label="Schedule Date" />
            )}
          />
        </Grid>
      </Grid>

      <Grid
        item
        style={{ display: "flex", justifyContent: "flex-end" }}
        xs={12}
        md={12}
        lg={12}
      >
        {!formState.editData && (
          <span style={{ marginRight: "15px" }}>
            <Tooltip title="Edit">
              <EditIcon
                color="primary"
                variant="contained"
                onClick={() => handleEditClick()}
              />
            </Tooltip>
          </span>
        )}
        <WhiteIconButton
          styles={{ marginRight: "15px" }}
          onClick={() => handleViewTracking()}
        >
          View Tracking
        </WhiteIconButton>
      </Grid>

      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px"
        }}
        xs={12}
        md={12}
        lg={12}
      >
        {formState.editData && (
          <>
            <DarkBlueButton
              variant="contained"
              size="small"
              onClick={handleSave}
              style={{
                marginRight: "10px",
                marginLeft: "10px"
              }}
            >
              SAVE
            </DarkBlueButton>
            <DarkBlueButton
              variant="contained"
              size="small"
              onClick={handleCancel}
            >
              Cancel
            </DarkBlueButton>
          </>
        )}
      </Grid>

      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            multiline
            id="notes"
            name="notes"
            onChange={handleChange}
            value={formState.notes || null}
            rows={15}
            variant="outlined"
            disabled={formState.editData ? false : true}
          />
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ClientExchangeNotes;
