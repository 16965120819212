const FranchiseSchema = {
  files: {
    label: "Upload",
    id: "files",
    required: false,
    placeholder: "Upload files",
    autoFocus: true,
    type: "file",
    validations: {}
  },
  site_website: {
    label: "Franchise Site Website",
    id: "site_website",
    autoComplete: "Franchise Site Website",
    required: true,
    placeholder: "Franchise Site Website",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  email: {
    label: "Franchise Site Email",
    id: "email",
    autoComplete: "Email",
    required: true,
    placeholder: "Franchise Site Email",
    autoFocus: true,
    type: "text",
    validations: {
      validateEmailRegex: {
        value: "true",
        message: "Email not valid"
      }
    }
  },
  phone: {
    label: "Phone",
    id: "franchiseSitePhone",
    autoComplete: "Phone",
    required: false,
    placeholder: "Phone",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  franchiseSiteDescription: {
    label: "Description",
    id: "franchiseSiteDescription",
    autoComplete: "Description",
    required: false,
    placeholder: "Description",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  mask_all_users: {
    label: "mask_all_users",
    id: "mask_all_users",
    autoComplete: "mask_all_users",
    required: false,
    placeholder: "mask_all_users",
    autoFocus: true,
    type: "boolean",
    validations: {}
  },
  giveAllLogo: {
    label: "Upload",
    id: "giveAllLogo",
    required: false,
    placeholder: "Upload files",
    autoFocus: true,
    type: "file",
    validations: {}
  },
  content: {
    title: "View Franchise",
    button: "Save",
    cancel: "Cancel"
  },
  franchiseSiteName: {
    label: "Franchise Site",
    id: "franchiseName",
    autoComplete: "Franchise Site",
    required: true,
    placeholder: "Franchise Site",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Franchise Site Name is required"
      }
    }
  },
  address_line_1: {
    label: "Address line 1",
    id: "franchiseAddress1",
    autoComplete: "Address",
    required: false,
    placeholder: "Address",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  address_line_2: {
    label: "Address line 2",
    id: "franchiseAddress2",
    autoComplete: "Address",
    required: false,
    placeholder: "Address",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  franchiseDescription: {
    label: "Description",
    id: "franchiseDescription",
    autoComplete: "Description",
    required: false,
    placeholder: "Description",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  franchiseName: {
    label: "Franchise",
    id: "franchiseName",
    autoComplete: "Franchise",
    required: false,
    placeholder: "Franchise",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Franchise Name is required"
      }
    }
  }
};
export default FranchiseSchema;
