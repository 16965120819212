import * as React from "react";
import { Route } from "react-router-dom";
import { Auth as auth, NotFoundPage } from "../components";

const PublicRoute = ({ component: Component, ...otherProps }) => {
  if (auth.getToken() === null) {
    return (
      <>
        <Route
          render={otherProps => (
            <>
              <Component {...otherProps} />
            </>
          )}
        />
      </>
    );
  } else {
    return (
      <React.Fragment>
        <NotFoundPage />
      </React.Fragment>
    );
  }
};
export default PublicRoute;
