import {
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@material-ui/core";
import React from "react";
import { convertNumber, roundTo1Digit, roundTo2Digit } from "../../../utils";
const ShowAllMealGoalsForADay = props => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });
  const useStyles = makeStyles(theme => ({
    goalsGrid: {
      overflowX: isDesktop ? "none" : "auto",
      marginTop: "15"
    },
    mainGridFoodStyles: {
      flexWrap: "inherit"
    },
    goalsContainer: {
      marginRight: "auto"
    },
    borderNone: {
      border: "none",
      padding: 0
    },
    cardTitleStyle: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "0.9375rem",
      color: "#110F48",
      minWidth: 60,
      textAlign: "center",
      padding: "1.5rem 1rem !important"
    },
    cardHeadTitleStyle: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "1rem",
      color: "#8A8A97",
      minWidth: 60,
      padding: "1rem"
    },
    mainGridFoodheaderStyles: {
      padding: "1.0625rem 0 !important"
    }
  }));

  const localClasses = useStyles();
  return (
    <>
      <Grid item xs={12} md={12} lg={12} className={localClasses.goalsGrid}>
        <div className={localClasses.goalsContainer}>
          <Grid
            container
            spacing={12}
            className={localClasses.mainGridFoodStyles}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      minWidth: "10.9375rem"
                    }}
                    className={localClasses.cardHeadTitleStyle}
                  >
                    <div className={localClasses.mainGridFoodheaderStyles}>
                      FROM MEAL GOALS
                    </div>
                  </TableCell>
                  <TableCell
                    className={localClasses.cardHeadTitleStyle}
                    align="center"
                  >
                    PORTION
                  </TableCell>
                  <TableCell
                    className={localClasses.cardHeadTitleStyle}
                    align="center"
                  >
                    CALORIES
                  </TableCell>
                  <TableCell
                    className={localClasses.cardHeadTitleStyle}
                    align="center"
                  >
                    PROTEINS
                  </TableCell>
                  <TableCell
                    className={localClasses.cardHeadTitleStyle}
                    align="center"
                  >
                    CARBS
                  </TableCell>
                  <TableCell
                    className={localClasses.cardHeadTitleStyle}
                    align="center"
                  >
                    FATS
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={localClasses.cardHeadTitleStyle}>
                    BREAD
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {convertNumber(props.goalValues.values.bread.portion)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo1Digit(props.goalValues.values.bread.avgcal)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.bread.avgpro)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.bread.avgcarb)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.bread.avgfat)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={localClasses.cardHeadTitleStyle}>
                    MEAT
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {convertNumber(props.goalValues.values.meat.portion)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo1Digit(props.goalValues.values.meat.avgcal)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.meat.avgpro)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.meat.avgcarb)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.meat.avgfat)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={localClasses.cardHeadTitleStyle}>
                    VEG
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {convertNumber(props.goalValues.values.veg.portion)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo1Digit(props.goalValues.values.veg.avgcal)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.veg.avgpro)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.veg.avgcarb)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.veg.avgfat)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={localClasses.cardHeadTitleStyle}>
                    FRUIT
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {convertNumber(props.goalValues.values.fruit.portion)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo1Digit(props.goalValues.values.fruit.avgcal)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.fruit.avgpro)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.fruit.avgcarb)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.fruit.avgfat)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={localClasses.cardHeadTitleStyle}>
                    MILK
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {convertNumber(props.goalValues.values.milk.portion)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo1Digit(props.goalValues.values.milk.avgcal)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.milk.avgpro)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.milk.avgcarb)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.milk.avgfat)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={localClasses.cardHeadTitleStyle}>
                    FAT
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {convertNumber(props.goalValues.values.fat.portion)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo1Digit(props.goalValues.values.fat.avgcal)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.fat.avgpro)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.fat.avgcarb)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.values.fat.avgfat)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={localClasses.cardHeadTitleStyle}>
                    TOTAL
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {convertNumber(props.goalValues.total.portion)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo1Digit(props.goalValues.total.avgcal)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.total.avgpro)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.total.avgcarb)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.total.avgfat)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={localClasses.cardHeadTitleStyle}>
                    AVG/MEALS
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {convertNumber(props.goalValues.avgPerMeal.portion)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo1Digit(props.goalValues.avgPerMeal.avgcal)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.avgPerMeal.avgpro)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.avgPerMeal.avgcarb)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.avgPerMeal.avgfat)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell className={localClasses.cardHeadTitleStyle}>
                    CALORIES CONTRIBUTED
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.calContributed.proContNum)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.calContributed.carbContNum)}
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(props.goalValues.calContributed.fatCalNum)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell className={localClasses.cardHeadTitleStyle}>
                    PERCENT
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(
                      props.goalValues.calContributed.proContPercent
                    )}
                    %
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(
                      props.goalValues.calContributed.carbContPercent
                    )}
                    %
                  </TableCell>
                  <TableCell className={localClasses.cardTitleStyle}>
                    {roundTo2Digit(
                      props.goalValues.calContributed.fatCalNumPercent
                    )}
                    %
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default ShowAllMealGoalsForADay;
