import { React, useState, useEffect, useContext } from "react";
import {
  Typography,
  CssBaseline,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  FormHelperText,
  CircularProgress,
  Card,
  AppBar,
  Toolbar,
  CardContent
} from "@material-ui/core";
import {
  Input,
  Auth,
  DarkBlueButton,
  BorderLessButton
} from "../../components";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import Styles from "./TwoFactorAuth.module.css";
import {
  VERIFYCODE,
  LOGIN,
  FRANCHISE,
  MANAGECLIENTS,
  USERS,
  CLIENTDASHBAORD,
  LOGOUT
} from "../../paths/Paths";
import { providerForPost } from "../../api";
import {
  apiBackendUrl,
  requestOtpTwoFAURL,
  Verify2FaCode
} from "../../constants";
import Logo from "../../components/Logo/Logo";
import { AuthContext } from "../../context";

const useStyles = makeStyles(theme => ({
  titleText: {
    color: "#110f48",
    fontSize: "1.25rem",
    fontFamily: "Montserrat",
    fontWeight: "500",
    marginBottom: "0.9375rem",
    padding: "8px"
  },

  normalText: {
    color: "#110f48",
    fontSize: "0.9375rem",
    fontFamily: "Montserrat",
    fontWeight: "500",
    padding: "8px"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  container: {
    margin: "117px auto 50px auto",
    maxWidth: "600px",
    height: "100%",
    width: "100%"
  },
  wrapper: {
    position: "relative"
  },
  Error: {
    "font-size": "larger",
    "text-align": "center"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  Heading: {
    marginBottom: theme.spacing(2),
    "padding-bottom": " 10px"
  },
  avatar: {
    margin: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const TwoFactorAuth = props => {
  const userInfo = Auth.getUserInfo();
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = useState("");
  const isLogin = props.location.isLogin;
  const [error, setError] = useState(false);
  const [isPhoneNumberPresent, setIsPhoneNumberPresent] = useState(false);
  const [isSecretKeyPresent, setIsSecretKeyPresent] = useState(false);
  const [isErrorWhileSendingOTP, setIsErrorWhileSendingOTP] = useState(false);
  const [otpStatusMessage, setOtpStatusMessage] = useState("");
  const [expanded, setExpanded] = useState("");
  const [showTwoFaTextBox, setShowTwoFaTextBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setUserInfo } = useContext(AuthContext);
  const [formState, setFormState] = useState({
    codeData: "",
    error: false,
    errorMessage: ""
  });

  if (!isLogin) {
    if (userInfo && userInfo.role) {
      if (userInfo.role.name === process.env.REACT_APP_NUTRITIONIST_ROLE) {
        history.push({
          pathname: MANAGECLIENTS
        });
      } else if (userInfo.role.name === process.env.REACT_APP_ADMIN_ROLE_NAME) {
        history.push({
          pathname: USERS
        });
      } else if (
        userInfo.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
      ) {
        history.push({
          pathname: FRANCHISE
        });
      } else if (
        userInfo.role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
      ) {
        history.push({
          pathname: CLIENTDASHBAORD
        });
      } else {
        history.push({
          pathname: LOGOUT
        });
      }
    } else {
      history.push({
        pathname: LOGIN
      });
    }
  }

  const onCancel = () => {
    history.push({
      pathname: LOGIN
    });
  };

  useEffect(() => {
    /**Api to get secret key is present or not */
    if (
      props.location &&
      props.location.userData &&
      props.location.userData.isSecretKeyPresent === true
    ) {
      setIsSecretKeyPresent(true);
      setValue("authApp");
      setExpanded("authApp");
      setShowTwoFaTextBox(true);
    }
    if (
      props.location &&
      props.location.userData &&
      props.location.userData.phone !== "0" &&
      props.location.userData.phone !== null
    ) {
      setIsPhoneNumberPresent(true);
    }
  }, []);

  const verify2FaPin = async () => {
    setLoading(true);
    if (formState.codeData !== "") {
      let payLoad = {
        token: formState.codeData
      };

      providerForPost(Verify2FaCode, payLoad, props.location.token, {
        desc: "Verify OTP for 2FA"
      })
        .then(res => {
          if (res.data.result && res.data.result.status === true) {
            setUserInfo(userInfo => ({
              ...userInfo,
              user: {
                ...userInfo.user,
                name: props.location.userData.full_name,
                logo: props.location.logo
                  ? apiBackendUrl + props.location.logo
                  : null
              }
            }));

            Auth.setToken(props.location.token, true);
            Auth.setUserInfo(props.location.userData, true);
            Auth.setLogo(props.location.logo, true);

            let userData = props.location.userData;
            if (
              userData.role.name === process.env.REACT_APP_NUTRITIONIST_ROLE
            ) {
              history.push({
                pathname: MANAGECLIENTS
              });
            } else if (
              userData.role.name === process.env.REACT_APP_ADMIN_ROLE_NAME ||
              userData.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
            ) {
              history.push({
                pathname: FRANCHISE
              });
            } else if (
              userData.role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
            ) {
              history.push({
                pathname: CLIENTDASHBAORD
              });
            } else {
              history.push({
                pathname: LOGOUT
              });
            }
          } else {
            setFormState(formState => ({
              ...formState,
              error: true,
              errorMessage: "Verification Code is Incorrect"
            }));
          }
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          console.log("error", err);
        });
    } else {
      setLoading(false);
      setFormState(formState => ({
        ...formState,
        error: true,
        errorMessage: "Verification Code is required"
      }));
    }
  };

  const sendOtpToIdentifier = () => {
    setLoading(true);
    let identifier = "";
    let status = "";
    if (value === "email") {
      identifier = props.location.userData.email;
      status = value;
    } else if (value === "txtMsg") {
      identifier = props.location.userData.phone;
      status = value;
    } else {
      identifier = props.location.userData.username;
      status = "username";
    }
    let payload = {
      reason: "2FA",
      status: status
    };
    providerForPost(requestOtpTwoFAURL, payload, props.location.token, {
      desc: "Request OTP for 2FA"
    })
      .then(res => {
        setLoading(false);
        history.push({
          pathname: VERIFYCODE,
          authMethod: value,
          identifier: identifier,
          status: status,
          token: props.location.token,
          userData: props.location.userData,
          logo: props.location.logo,
          type: value
        });
      })
      .catch(error => {
        console.log("Error", error);
        setLoading(false);
        setIsErrorWhileSendingOTP(true);
        setOtpStatusMessage(error.response.data.message);
      });
  };

  /** onNext
   *  After authentication method selection, API call to send OTP through selected method
   * @param myParam authentication method(auth app,email,text message)
   */
  const onNext = () => {
    if (value !== "") {
      if (value === "authApp") {
        verify2FaPin();
      } else {
        sendOtpToIdentifier();
      }
    } else {
      setError(true);
    }
  };

  const handleChange = event => {
    setValue(event.target.value);
    setExpanded(event.target.value);
    setError(false);
  };

  const handleChangeCode = event => {
    setFormState(formState => ({
      ...formState,
      codeData: event.target.value,
      error: false,
      errorMessage: ""
    }));
  };

  const handleChangeAccordion = panel => (event, newExpanded) => {
    setValue(panel);
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <AppBar position="fixed" className={Styles.loginAppBar} elevation={0}>
        <Toolbar className={Styles.loginToolbar}>
          <Logo className={Styles.loginLogo} />
        </Toolbar>
      </AppBar>{" "}
      <div>
        <Container component="main" maxWidth="xs" className={classes.container}>
          <Card variant="elevation" elevation={2}>
            <CardContent className={Styles.loginCardContent}>
              <CssBaseline />
              <div className={classes.paper}>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.titleText}
                >
                  Two-Factor Authentication
                </Typography>
                <FormControl component="fieldset">
                  <FormHelperText
                    className={classes.Error}
                    error={error || isErrorWhileSendingOTP}
                    id="my-helper-text"
                  >
                    {error
                      ? "Please select one authorization method"
                      : isErrorWhileSendingOTP
                      ? otpStatusMessage
                      : null}
                  </FormHelperText>
                  <RadioGroup
                    aria-label="Auth method"
                    name="authMethod"
                    value={value}
                    onChange={handleChange}
                  >
                    <Accordion
                      className={Styles.accordionWrap}
                      expanded={expanded === "authApp"}
                      onChange={handleChangeAccordion("authApp")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                      >
                        <FormControlLabel
                          value="authApp"
                          control={<Radio />}
                          label="Authentication app"
                          onClick={event => event.stopPropagation()}
                          onFocus={event => event.stopPropagation()}
                          className={Styles.authHeader}
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className={classes.normalText}>
                          {isSecretKeyPresent
                            ? "Use a mobile authentication app to get a verification code"
                            : "Enable autheticator by going to 2FA settings in your profile "}
                        </Typography>
                      </AccordionDetails>
                      {showTwoFaTextBox === true && (
                        <AccordionDetails>
                          <Typography>
                            <Input
                              id={"code"}
                              variant="outlined"
                              margin="normal"
                              error={formState.error}
                              fullWidth
                              autoFocus={"code"}
                              helperText={
                                formState.error ? formState.errorMessage : null
                              }
                              label={"Verification Code"}
                              name={"otpData"}
                              onChange={handleChangeCode}
                              value={
                                formState.codeData ? formState.codeData : ""
                              }
                            />
                          </Typography>
                        </AccordionDetails>
                      )}
                    </Accordion>
                    <Accordion
                      className={Styles.accordionWrap}
                      expanded={expanded === "txtMsg"}
                      onChange={handleChangeAccordion("txtMsg")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                      >
                        <FormControlLabel
                          value="txtMsg"
                          control={<Radio />}
                          label="Text message"
                          onClick={event => event.stopPropagation()}
                          onFocus={event => event.stopPropagation()}
                          className={Styles.authHeader}
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className={classes.normalText}>
                          {isPhoneNumberPresent
                            ? " Use your mobile phone to receive a text message with an authentication code "
                            : " Add phone number from your profile to recieve otp on your phone"}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      className={Styles.accordionWrap}
                      expanded={expanded === "email"}
                      onChange={handleChangeAccordion("email")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                      >
                        <FormControlLabel
                          value="email"
                          control={<Radio />}
                          label="Email"
                          onClick={event => event.stopPropagation()}
                          onFocus={event => event.stopPropagation()}
                          className={Styles.authHeader}
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className={classes.normalText}>
                          You will receive an email with an authentication code
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </RadioGroup>
                </FormControl>
                <div>
                  {" "}
                  <Grid container spacing={3} className={Styles.btnRight}>
                    <Grid item xs={6}>
                      <BorderLessButton
                        type="button"
                        fullWidth
                        onClick={onCancel}
                      >
                        Cancel
                      </BorderLessButton>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.wrapper}>
                        <DarkBlueButton
                          type="button"
                          fullWidth
                          disabled={loading}
                          onClick={onNext}
                        >
                          Next
                        </DarkBlueButton>

                        {loading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </CardContent>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default TwoFactorAuth;
