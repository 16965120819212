import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  inputFile: {
    display: "none"
  },
  cancelSaveButton: {
    float: "right"
  },
  center: {
    marginTop: "5%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "40%"
  },
  icon: {
    textAlign: "center"
  },
  dateErrorText: {
    textAlign: "center",
    color: "red"
  },
  filterByButton: {
    margin: theme.spacing(1),
    textAlign: "center",
    paddingBottom: "21px"
  },
  imageDiv: {
    width: "max-content",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px"
  },
  DefaultNoImage: {
    width: "750px",
    height: "200px",
    objectFit: "contain",
    backgroundColor: "#FFFFFF"
  },
  UploadImage: {
    width: "100%",
    height: "200px",
    objectFit: "contain",
    backgroundColor: "#FFFFFF"
    // marginLeft: "18%"
  },
  typography: {
    padding: theme.spacing(2)
  },
  checkBox: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  graphIcon: {
    marginRight: "15px",
    height: "1.25rem",
    width: "1.25rem"
  },
  formControlFilterBy: {
    margin: theme.spacing(1),
    width: "50%",
    display: "grid",
    marginRight: "auto",
    marginLeft: "auto"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 700
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  root: {
    maxWidth: "100%"
  },
  appBarMobileTitle: {
    marginBottom: "0%",
    color: "black"
  },
  addNewScheduleButton: {
    marginTop: "2%",
    float: "right"
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  content: {
    display: "content"
  },
  title: {
    display: "flex",
    marginBottom: theme.spacing(1),
    "& h4": {
      flex: "1",
      fontWeight: "700"
    }
  },
  addButton: {
    textAlign: "right",
    marginBottom: "7px !important",
    "& button": {
      marginLeft: "0.9375rem"
    }
  },
  actionButtonGrid: {
    padding: "8px !important"
  },
  actionButton: {
    marginLeft: "1.25rem"
  },
  filterButtonsMargin: {
    alignSelf: "center",
    marginLeft: "10px",
    marginTop: "10%"
  },
  tabledata: {
    marginTop: theme.spacing(3)
  },
  autoCompleteField: {
    width: 200
  },
  noDataMargin: {
    margin: "auto"
  },
  Cardtheming: {
    padding: "8px 8px 16px 8px !important"
  },
  filterButton: {
    width: "100%",
    marginBottom: "24px"
  },
  /**css for datatable action buttons */
  DisplayFlex: {
    display: "flex"
  },
  PaddingActionButton: {
    padding: "2px 8px 0px 8px",
    cursor: "pointer"
  },
  PaddingFirstActionButton: {
    padding: "3px 8px 0px 0px",
    cursor: "pointer"
  },
  PaddingSomeActionButton: {
    padding: "0px 8px 0px 0px",
    cursor: "pointer"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: "#fff"
  },
  paddingDate: {
    paddingTop: "41px !important",
    paddingLeft: "21px !important",
    paddingRight: "21px !important",
    paddingBottom: "41px !important"
  },

  container: {
    alignSelf: "center",
    padding: "1.25rem",
    margin: "10px auto"
  },
  RemoveLogoStyle: {
    position: "absolute",
    top: "1.25rem",
    left: "350px"
  },

  csvInput: {
    alignSelf: "center",
    padding: "1.875rem",
    display: "block",
    border: "2px solid #ccc",
    borderRadius: "10px",
    width: "100%",
    fontSize: "1.25rem"
  },

  importButtonMargin: {
    alignSelf: "center",
    width: "100%",
    margin: "10px auto"
  },

  InputFileButton: {
    color: "#353535",
    backgroundColor: "#f6c80a",
    fontSize: "13px",
    fontWeight: "700",
    marginLeft: "2%",
    width: "96%",
    borderRadius: "0 !important",
    "&:hover,&:focus": {
      color: "#353535",
      backgroundColor: "#f6c80a"
    }
  },

  ProgressBar: {
    marginLeft: "2%",
    paddingRight: "1.25rem",
    marginTop: "8px"
  },
  centerAligned: {
    textAlign: "center",
    paddingTop: "152px",
    fontSize: "1rem",
    fontWeight: 600,
    fontFamily: "Montserrat",
    justifyContent: "center"
  },
  filterOptions: {
    flexWrap: "wrap",
    alignItems: "flex-end"
  },
  titleText: {
    color: "#110f48",
    fontSize: "1.25rem !important",
    fontFamily: "Montserrat !important",
    fontWeight: "500 !important",
    marginBottom: "0.9375rem !important",
    padding: "8px !important"
  },
  editCard: {
    boxShadow: "none !important"
  },
  editCardContent: {
    padding: "8px !important"
  },
  groupHeader: {
    fontFamily: "Montserrat !important",
    fontWeight: "500 !important",
    borderTop: "1px solid #c4c4c4",
    padding: "18px 0px 0px 0px"
  },
  paperFilter: {
    boxShadow: "none !important"
  }
  /** */
}));

export default useStyles;
