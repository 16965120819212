import React from "react";
import styles from "./Layout.module.css";
import { Footer } from "../../components";
import { SideAndTopNavBar } from "./SideAndTopNavBar";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";

const drawerWidth = 228;

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 200
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff"
  },
  main: {
    padding: "50px",
    backgroundColor: "#ffffff !important",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 20px !important",
      width: "calc(100% - 40px) !important"
    },
    minHeight: "calc(100vh )"
  },
  content: {
    flexGrow: 1,
    height: "100%",
    backgroundColor: "#ffffff",
    minHeight: "calc(100vh )"
  },

  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  }
}));

const Layout = props => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  const handleContentShiftTrue = () => {
    setOpen(true);
  };

  const handleContentShiftFalse = () => {
    setOpen(false);
  };

  if (isDesktop) {
    return (
      <div
        className={clsx({
          [classes.root]: true
        })}
        data-testid="Layout"
      >
        <SideAndTopNavBar
          handleContentShiftTrue={handleContentShiftTrue}
          handleContentShiftFalse={handleContentShiftFalse}
          NavBar={props.NavBar}
          isTabLayout={props.isTabLayout ? props.isTabLayout : false}
          NameComponent={props.NameComponent}
          handleAddSchedule={props.handleAddSchedule}
          redirectToExchangesPage={props.redirectToExchangesPage}
          isCreateMealsPage={props.isCreateMealsPage}
          isLatest={props.isLatest}
          isActive={props.isActive}
          handleIsButtonClicked={props.handleIsButtonClicked}
          handleDone={props.handleDone}
          no_of_meals={props.no_of_meals}
        />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open
          })}
        >
          <div className={styles.mainContainer}>{props.children}</div>
        </main>
        <Footer />
      </div>
    );
  } else {
    return (
      <div
        className={clsx({
          [classes.root]: true
        })}
        data-testid="Layout"
      >
        <SideAndTopNavBar
          NavBar={props.NavBar}
          isTabLayout={props.isTabLayout ? props.isTabLayout : false}
          NameComponent={props.NameComponent}
          handleAddSchedule={props.handleAddSchedule}
          isCreateMealsPage={props.isCreateMealsPage}
          isLatest={props.isLatest}
          isActive={props.isActive}
          handleIsButtonClicked={props.handleIsButtonClicked}
          handleDone={props.handleDone}
          no_of_meals={props.no_of_meals}
        />
        <main className={classes.main}>
          <div>{props.children}</div>
        </main>
        <Footer />
      </div>
    );
  }
};
Layout.propTypes = {};

Layout.defaultProps = {};

export default Layout;
