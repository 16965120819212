// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SupplementGrid_exerciseGrid__3Q-4A th p {\n  font-family: Montserrat, sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 1rem;\n  line-height: 1.5rem;\n  letter-spacing: 0.15px;\n  color: #8a8a97;\n}\n.SupplementGrid_exerciseGrid__3Q-4A td {\n  background-color: #f4f8ff;\n}\n\n.SupplementGrid_exerciseGrid__3Q-4A td > div > div {\n  background-color: #fff;\n}\n.SupplementGrid_exerciseGrid__3Q-4A tr td:first-child {\n  border-top-left-radius: 10px;\n  border-bottom-left-radius: 10px;\n}\n.SupplementGrid_exerciseGrid__3Q-4A tr td:last-child {\n  border-top-right-radius: 10px;\n  border-bottom-right-radius: 10px;\n}\n", "",{"version":3,"sources":["webpack://src/components/SupplementGrid/SupplementGrid.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,sBAAsB;EACtB,cAAc;AAChB;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE,4BAA4B;EAC5B,+BAA+B;AACjC;AACA;EACE,6BAA6B;EAC7B,gCAAgC;AAClC","sourcesContent":[".exerciseGrid th p {\n  font-family: Montserrat, sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 1rem;\n  line-height: 1.5rem;\n  letter-spacing: 0.15px;\n  color: #8a8a97;\n}\n.exerciseGrid td {\n  background-color: #f4f8ff;\n}\n\n.exerciseGrid td > div > div {\n  background-color: #fff;\n}\n.exerciseGrid tr td:first-child {\n  border-top-left-radius: 10px;\n  border-bottom-left-radius: 10px;\n}\n.exerciseGrid tr td:last-child {\n  border-top-right-radius: 10px;\n  border-bottom-right-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"exerciseGrid": "SupplementGrid_exerciseGrid__3Q-4A"
};
export default ___CSS_LOADER_EXPORT___;
