const FoodSubCategorySchema = {
  foodName: {
    label: "Name",
    id: "foodName",
    autoComplete: "Name",
    required: true,
    placeholder: "Name",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Name is required"
      }
    }
  },
  franchiseName: {
    label: "Franchise",
    id: "franchiseName",
    autoComplete: "Franchise",
    required: false,
    placeholder: "Franchise",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  foodCategoryName: {
    label: "Food Category",
    id: "foodCategoryName",
    autoComplete: "Food Category",
    required: true,
    placeholder: "Food Category",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Food Category is required"
      }
    }
  }
};
export default FoodSubCategorySchema;
