import React, { useEffect, useState } from "react";
import styles from "./AddEditUsers.module.css";
import addUserForm from "./UsersSchema";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  LinearProgress,
  Switch,
  FormHelperText,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import {
  Auth,
  AutoCompleteDropDown,
  Input,
  SnackBar,
  DarkBlueButton,
  BorderLessButton
} from "../../../components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { get } from "lodash";
import {
  checkAllKeysPresent,
  checkEmpty,
  getListOfKeysNotPresent,
  setErrors
} from "../../../utils";
import SaveIcon from "@material-ui/icons/Save";
import { USERS } from "../../../paths";
import { providerForGet, providerForPost } from "../../../api";
import {
  franchise,
  user,
  addAdmin,
  getRole,
  addNutritionist,
  userMe,
  usaState,
  checkEmailAvailable,
  countryCodeList as countries,
  getAllCountries,
  franchiseSites
} from "../../../constants";
import {
  SAVE_MESSAGE,
  UPDATE_MESSAGE
} from "../../../constants/genericConstants";
import useStyles from "../../../utils/Styles/ManagePageStyles";
import PhoneInput from "react-phone-input-2";

const AddEditUsers = props => {
  const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
  const history = useHistory();
  const auth = Auth.getToken();
  const userInfo = Auth.getUserInfo();
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showStateDropDown, setShowStateDropDown] = useState(false);
  let propData = props["location"]["state"];
  const [clientEdit] = useState(() =>
    propData && propData.role === "Client" ? true : false
  );

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    franchiseList: [],
    franchiseSitesList: [],
    roleList: [],
    countryList: [],
    alert: false,
    severity: "success",
    errorMessage: "",
    /** This is when we return from edit page */
    rowData: propData ? propData : [],
    editData: propData ? (propData["id"] ? true : false) : false,
    editDataId: propData ? propData["id"] : null,
    addNutritionist: propData
      ? propData["addNutritionist"]
        ? true
        : false
      : false
  });
  const [contactId, setContactId] = useState();

  const franchiseName = "franchiseName";
  const email = "email";
  const full_name = "full_name";
  const phone = "phone";
  const address = "address";
  const state = "state";
  const city = "city";
  const zipcode = "zipcode";
  const country = "country";
  const franchiseSite = "franchiseSite";
  let apiUrl = "";
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const [addAdminPage] = useState(() =>
    props.location["addAdmin"] ? true : false
  );
  const [editAdmin, setEditAdmin] = useState(false);

  useEffect(() => {
    setLoading(true);
    getSuperAdmin();
    getListData();
    getUserData();
    setLoading(false);
  }, []);

  const getSuperAdmin = () => {
    if (userInfo.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME) {
      setIsSuperAdmin(true);
    } else {
      setIsSuperAdmin(false);
    }
  };

  const getUserData = async () => {
    await providerForGet(userMe, {}, auth)
      .then(res => {
        getFranchiseSiteListData(
          res.data.franchise ? res.data.franchise.id : null
        );
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            franchiseName: res.data.franchise ? res.data.franchise.id : null
          }
        }));
      })
      .catch(err => {
        console.log("error", err);
      });
  };

  /**Api call for getting autocomplete list itmes */
  const getListData = async () => {
    let body = {};
    setLoader(true);
    await providerForGet(franchise, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          franchiseList: res.data.data
        }));
        setLoader(false);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });

    await providerForGet(getRole, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          roleList: res.data.roles
        }));
        setLoader(false);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });

    await providerForGet(getAllCountries, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          countryList: res.data.data
        }));
        setLoader(false);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });
    if (formState.editData === true) {
      setLoading(true);

      let editId = {
        id: formState.editDataId,
        desc: `${userInfo.full_name} accessed an user for editing`,
        audit_log_clientid: formState.editDataId
      };
      await providerForGet(user, editId, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              franchiseName: res.data[0].franchise
                ? res.data[0].franchise.id
                : null,
              franchiseSite: res.data[0].franchise_site
                ? res.data[0].franchise_site.id
                : null,
              full_name: res.data[0].full_name,
              email: res.data[0].email,
              phone: res.data[0].phone,
              address:
                res.data[0] &&
                res.data[0].contact &&
                res.data[0].contact.address
                  ? res.data[0].contact.address
                  : "",
              state:
                res.data[0] && res.data[0].contact && res.data[0].contact.State
                  ? res.data[0].contact.State
                  : "",
              city:
                res.data[0] && res.data[0].contact && res.data[0].contact.city
                  ? res.data[0].contact.city
                  : "",
              zipcode:
                res.data[0] &&
                res.data[0].contact &&
                res.data[0].contact.zipcode
                  ? res.data[0].contact.zipcode
                  : "",
              country:
                res.data[0] &&
                res.data[0].contact &&
                res.data[0].contact.country
                  ? res.data[0].contact.country
                  : "",

              active: res.data[0] && res.data[0].active === true ? true : false,
              is2FAEnabled:
                res.data[0] && res.data[0].is2FAEnabled === true ? true : false,
              do_not_share:
                res.data[0] &&
                res.data[0].contact &&
                res.data[0].do_not_share === true
                  ? true
                  : false,
              role:
                res.data[0] && res.data[0].role ? res.data[0].role.name : null
            }
          }));

          if (res.data[0].role.name === process.env.REACT_APP_ADMIN_ROLE_NAME) {
            setEditAdmin(true);
          }
          setContactId(res.data[0].contact.id);
          setLoader(false);
        })
        .catch(err => {
          setLoader(false);
          console.log("err-->>", err);
        });
      setLoading(false);
    }
  };

  const getFranchiseSiteListData = async params => {
    // for getting franchise_sites list
    if (params) {
      await providerForGet(franchiseSites, { franchise: params }, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            franchiseSitesList: res.data.data
          }));
          setLoader(false);
        })
        .catch(err => {
          console.log("err", err);
          setLoader(false);
        });
    } else {
      setFormState(formState => ({
        ...formState,
        franchiseSitesList: []
      }));
    }
  };

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: ""
    }));
  };

  const handleChange = e => {
    e.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));

    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const handlePhoneNumberChange = (value, name) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value
      },
      touched: {
        ...formState.touched,
        [name]: true
      }
    }));
    delete formState.errors[name];
  };

  const handleChangeSwitch = e => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]: e.target.checked
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));
  };

  const handleChangeAutocompleteForFranchise = (eventName, event, value) => {
    if (value !== null) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: value.id,
          /** removing franchise site on franchise change*/
          [franchiseSite]: null
        },
        touched: {
          ...formState.touched,
          [eventName]: true
        }
      }));

      getFranchiseSiteListData(value.id);

      if (formState.errors.hasOwnProperty(eventName)) {
        delete formState.errors[eventName];
      }
    } else {
      delete formState.values[eventName];
      delete formState.values[franchiseSite];

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: null
        }
      }));
      getFranchiseSiteListData(null);
    }
  };

  const handleChangeAutoComplete = (eventName, event, value) => {
    if (eventName === state) {
      if (value !== null) {
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [eventName]: value.text
          },
          touched: {
            ...formState.touched,
            [eventName]: true
          }
        }));
        if (formState.errors.hasOwnProperty(eventName)) {
          delete formState.errors[eventName];
        }
      } else {
        delete formState.values[eventName];
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [eventName]: null
          }
        }));
      }
    } else if (value !== null) {
      if (eventName === country) {
        delete formState.values[state];
      }
      if (value.name === "United States" && eventName === country) {
        setShowStateDropDown(true);
      } else if (eventName === country) {
        setShowStateDropDown(false);
      }
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: value.id
        },
        touched: {
          ...formState.touched,
          [eventName]: true
        }
      }));
      if (formState.errors.hasOwnProperty(eventName)) {
        delete formState.errors[eventName];
      }
    } else {
      if (eventName === country) {
        delete formState.values[state];
      }
      delete formState.values[eventName];
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: null
        }
      }));
    }
  };

  const hasError = field => (formState.errors[field] ? true : false);

  async function validate(val, name, required) {
    var code = "";
    var n = false;

    if (val) {
      if (!val.startsWith("+")) {
        val = "+" + val;
      }

      for (var i = 0; i < countries.length; i++) {
        n = val.includes(countries[i].code);
        if (n) {
          break;
        }
      }

      if (n) {
        code = countries[i].code;
      } else {
        if (required) {
          formState.errors[name] = ["Invalid mobile number"];
        }
      }
      try {
        const number = await phoneUtil.parseAndKeepRawInput(val, code);
        let isValid = await phoneUtil.isValidNumber(number);
        if (isValid) {
          if (val.startsWith("+")) {
            val = val.substring(1);
          }
          formState.values[name] = val;
        } else {
          formState.errors[name] = ["Invalid mobile number"];
        }
      } catch {
        if (required) {
          formState.errors[name] = ["Mobile number is required"];
        }
      }
    } else {
      if (required) {
        formState.errors[name] = ["Mobile number is required"];
      }
    }
  }

  const handleSubmit = async event => {
    setLoading(true);
    event.preventDefault();
    if (addAdminPage || editAdmin) {
      apiUrl = addAdmin;
    } else {
      apiUrl = addNutritionist;
    }

    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      addUserForm
    );

    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, addUserForm);
      await validate(formState.values[phone], phone, false);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(formState.values, addUserForm);

      formState.errors = setErrors(formState.values, addUserForm);
    }

    if (isValid) {
      await providerForPost(
        checkEmailAvailable,
        {
          email: formState.values.email,
          c: formState.editDataId
        },
        Auth.getToken()
      )
        .then(res => {
          let userRole = "Nutritionist";

          if (apiUrl === addAdmin) userRole = "Admin";

          let desc = `${userInfo.full_name} added ${userRole} user ${formState.values.full_name}`;
          /**api call from here */
          let postData = {
            user: {
              full_name: formState.values.full_name,
              email: formState.values.email,
              username: formState.values.email,
              active: formState.values.active,
              phone: formState.values.phone,
              do_not_share: formState.values.do_not_share,
              is2FAEnabled: formState.values.is2FAEnabled ? true : false,
              isOneTimeClient: false,
              franchise: formState.values.franchiseName,
              franchise_site: formState.values.franchiseSite
                ? formState.values.franchiseSite
                : null
            },
            contact: {
              full_name: formState.values.full_name,
              city: formState.values.city,
              address: formState.values.address,
              zipcode: formState.values.zipcode
                ? parseInt(formState.values.zipcode)
                : null,
              country: formState.values.country
                ? formState.values.country
                : null,
              State: formState.values.state,
              home_phone: formState.values.phone
            }
          };

          let message = formState.editData ? UPDATE_MESSAGE : SAVE_MESSAGE;
          if (formState.editData || editAdmin) {
            postData.contactId = contactId;
            postData.userId = formState.editDataId;
            desc = `${userInfo.full_name} updated the ${userRole} user ${formState.values.full_name}`;
          }

          /**Post api call (Save a  nd update method)*/
          providerForPost(apiUrl, postData, auth, {
            desc: desc,
            audit_log_clientid: formState.editDataId
          })
            .then(res => {
              let successMessage = {
                successMessage: "User " + formState.values.full_name + message
              };
              setLoader(false);
              setLoading(false);
              history.push(USERS, successMessage);
            })
            .catch(err => {
              console.log("error+++", err.response);
              setFormState(formState => ({
                ...formState,
                alert: true,
                severity: "error",
                errorMessage: err["response"]["data"]["data"]
              }));
              setLoader(false);
              setLoading(false);
            });
        })
        .catch(err => {
          setLoading(false);
          setLoader(false);
          setFormState(formState => ({
            ...formState,
            isValid: false,
            errors: {
              ...formState.errors,
              [email]: [err["response"]["data"]["data"]]
            }
          }));
        });
    } else {
      setLoading(false);
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
      setLoader(false);
    }
  };

  const handleCancel = () => {
    history.push(USERS);
  };
  return (
    <div>
      {loader ? (
        <LinearProgress />
      ) : (
        <>
          <Grid item xs={12} className={styles.title}>
            {addAdminPage ? (
              <Typography variant="h4" className={classes.titleText}>
                Add Admin
              </Typography>
            ) : editAdmin ? (
              <Typography variant="h4" className={classes.titleText}>
                Edit Admin
              </Typography>
            ) : formState.editData === true && clientEdit === false ? (
              <Typography variant="h4" className={classes.titleText}>
                Edit Nutritionist
              </Typography>
            ) : clientEdit === true ? (
              <Typography variant="h4" className={classes.titleText}>
                Edit Client
              </Typography>
            ) : (
              <Typography variant="h4" className={classes.titleText}>
                Add Nutritionist
              </Typography>
            )}
          </Grid>
          <Grid>
            <SnackBar
              open={formState.alert}
              severity={formState.severity}
              onClose={handleSnackbarClose}
            >
              {formState.errorMessage}
            </SnackBar>
          </Grid>
          <Grid item xs={12} className={classes.formgrid}>
            <Card className={classes.editCard}>
              <CardContent className={classes.editCardContent}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addUserForm[full_name], "label")}
                      name={full_name}
                      value={formState.values[full_name] || ""}
                      error={hasError(full_name)}
                      placeholder={get(addUserForm[full_name], "placeholder")}
                      variant="outlined"
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(full_name)
                          ? formState.errors[full_name].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      // className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addUserForm[email], "label")}
                      name={email}
                      value={formState.values[email] || ""}
                      error={hasError(email)}
                      placeholder={get(addUserForm[email], "placeholder")}
                      variant="outlined"
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(email)
                          ? formState.errors[email].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <PhoneInput
                      country={"us"}
                      value={formState.values[phone] || ""}
                      onChange={phone =>
                        handlePhoneNumberChange(phone, "phone")
                      }
                      placeholder={"Add Mobile Number"}
                      specialLabel="Phone"
                      inputProps={{
                        name: "phone",
                        required: true,
                        style: {
                          width: "100%",
                          border: hasError(phone) ? "1px solid red" : null
                        }
                      }}
                    />
                    <FormHelperText
                      type="error"
                      visible={hasError(phone)}
                      style={{
                        "background-color": "White",
                        color: "red"
                      }}
                    >
                      {formState.errors[phone]}
                    </FormHelperText>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <AutoCompleteDropDown
                      disabled={!isSuperAdmin}
                      options={formState.franchiseList}
                      getOptionLabel={option => option.franchise_name}
                      id={get(addUserForm[franchiseName], "id")}
                      value={
                        formState.franchiseList[
                          formState.franchiseList.findIndex(function (item, i) {
                            return item.id === formState.values[franchiseName];
                          })
                        ] || null
                      }
                      onChange={(event, value) => {
                        handleChangeAutocompleteForFranchise(
                          franchiseName,
                          event,
                          value
                        );
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          id={get(addUserForm[franchiseName], "id")}
                          label={get(addUserForm[franchiseName], "label")}
                          variant="outlined"
                          error={hasError(franchiseName)}
                          helperText={
                            hasError(franchiseName)
                              ? formState.errors[franchiseName].map(error => {
                                  return error + " ";
                                })
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>

                  {formState.editData ? (
                    <Grid item md={3} xs={12}>
                      {" "}
                      <Switch
                        checked={
                          formState.values.active
                            ? formState.values.active
                            : false
                        }
                        onChange={handleChangeSwitch}
                        name={"active"}
                      />
                      Active
                    </Grid>
                  ) : null}
                  <Grid item md={3} xs={12}>
                    {" "}
                    <Switch
                      checked={
                        formState.values.is2FAEnabled
                          ? formState.values.is2FAEnabled
                          : false
                      }
                      onChange={handleChangeSwitch}
                      name={"is2FAEnabled"}
                    />
                    2FA
                  </Grid>
                  <br />
                  <Grid item md={12} xs={12}>
                    <Typography className={classes.groupHeader} variant="body1">
                      Address
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addUserForm[address], "label")}
                      name={address}
                      value={formState.values[address] || ""}
                      error={hasError(address)}
                      placeholder={get(addUserForm[address], "placeholder")}
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(address)
                          ? formState.errors[address].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AutoCompleteDropDown
                      options={formState.countryList}
                      getOptionLabel={option => option.name}
                      id={get(addUserForm[country], "id")}
                      value={
                        formState.countryList[
                          formState.countryList.findIndex(function (item, i) {
                            return item.id === formState.values[country];
                          })
                        ] || null
                      }
                      onChange={(event, value) => {
                        handleChangeAutoComplete(country, event, value);
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          id={get(addUserForm[country], "id")}
                          label={get(addUserForm[country], "label")}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    {showStateDropDown ? (
                      <AutoCompleteDropDown
                        options={usaState}
                        getOptionLabel={option => option.text}
                        id={get(addUserForm[state], "id")}
                        value={
                          usaState[
                            usaState.findIndex(function (item, i) {
                              return item.text === formState.values[state];
                            })
                          ] || null
                        }
                        onChange={(event, value) => {
                          handleChangeAutoComplete(state, event, value);
                        }}
                        renderInput={params => (
                          <Input
                            {...params}
                            id={get(addUserForm[state], "id")}
                            label={get(addUserForm[state], "label")}
                            variant="outlined"
                          />
                        )}
                      />
                    ) : (
                      <Input
                        label={get(addUserForm[state], "label")}
                        name={state}
                        value={formState.values[state] || ""}
                        error={hasError(state)}
                        placeholder={get(addUserForm[state], "placeholder")}
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        helperText={
                          hasError(state)
                            ? formState.errors[state].map(error => {
                                return error + " ";
                              })
                            : null
                        }
                        className={styles.elementroot}
                      />
                    )}
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addUserForm[city], "label")}
                      name={city}
                      value={formState.values[city] || ""}
                      error={hasError(city)}
                      placeholder={get(addUserForm[city], "placeholder")}
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(city)
                          ? formState.errors[city].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addUserForm[zipcode], "label")}
                      name={zipcode}
                      value={formState.values[zipcode] || ""}
                      error={hasError(zipcode)}
                      placeholder={get(addUserForm[zipcode], "placeholder")}
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(zipcode)
                          ? formState.errors[zipcode].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} className={classes.actionButtonGrid}>
            <DarkBlueButton
              type="submit"
              // variant="contained"
              // className={styles.actionButton}
              data-testid="ok-button"
              onClick={handleSubmit}
            >
              <SaveIcon />
              &nbsp; Save
            </DarkBlueButton>

            <BorderLessButton
              onClick={handleCancel}
              // className={classes.actionButton}
              // variant="contained"
            >
              cancel
            </BorderLessButton>
          </Grid>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </div>
  );
};

AddEditUsers.propTypes = {};

AddEditUsers.defaultProps = {};

export default AddEditUsers;
