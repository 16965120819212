import {
  Grid,
  Backdrop,
  CircularProgress,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  IconButton,
  Tooltip,
  TextField
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { providerForGet, providerForDownload } from "../../api";
import {
  AutoCompleteDropDown,
  HeadingOne,
  Auth,
  ShowAllMealGoalsForADay,
  DarkBlueButton,
  SnackBar,
  SelectDropdown,
  Table
} from "../../components";
import { getScheduleDateForClient } from "../../constants";
import {
  avgFoodCatValues,
  convertDate,
  goalAvgValues,
  isEmptyString
} from "../../utils";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { useTheme } from "@material-ui/core/styles";
import {
  downloadActualFoodForAllMealsBreakdownPdf,
  downloadSpecificMealsForTodayPdf,
  foodCategory,
  getAllMealInfoApi,
  getSingleDayBreakdownPdf,
  getMenuForTodaysMeals,
  getMenuForWeekMeal,
  getAllDaysBreakdownPdf,
  generateShoppingList
} from "../../constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CLIENTMEALS, CLIENTMYSCHEDULE } from "../../paths";
import GetAppIcon from "@material-ui/icons/GetApp";
import MenuIcon from "../../assets/small_icons/Menu.png";
import DialogBox from "../../components/DialogBox/DialogBox";
import noSchedule from "../../assets/images/No_schedule.jpg";
import Styles from "./ClientExchange.module.css";
import PrintIcon from "@material-ui/icons/Print";

const ClientExchange = props => {
  const columns = [{ title: "Name", field: "name" }];
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    summaryButtonBox: {
      display: "flex",
      marginBottom: "3%"
    },
    loadTemplate: {
      marginLeft: "auto"
    },
    duplicateReplicateStyle: {
      justifyContent: "flex-end",
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      marginRight: 30,
      [theme.breakpoints.up("md")]: {
        marginRight: 0
      },
      [theme.breakpoints.up("sm")]: {
        marginRight: 0
      },
      [theme.breakpoints.up("xs")]: {
        marginRight: 0
      }
    },
    margin: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    formControl: {
      margin: "10px 0px",
      minWidth: 270,
      flexDirection: "row"
    },
    mealForDayGrid: {
      display: "flex",
      flexWrap: "wrap"
    },
    saveButton: {
      display: "flex",
      justifyContent: "flex-end"
    },
    selectBox: {
      height: 30,
      weight: 10,
      borderRadius: 10,
      marginTop: theme.spacing(1)
    },
    selectBoxLabel: {
      marginTop: 11,
      marginRight: theme.spacing(1),
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.15px",
      color: "#000000"
    },
    favouriteClass: {
      [theme.breakpoints.down("xs")]: {
        "& button": {
          marginLeft: "0px !important",
          display: "flex !important",
          "&:first-child": {
            marginBottom: "10px !important"
          }
        }
      }
    },
    centerImage: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: "30%"
    }
  }));

  const reportList = [
    "Exchange Breakdown of Today's Meal",
    "Exchange Breakdown For All Meals Week",
    "Specific Food Break Down for Todays meals",
    "Actual food Breakdown for All Meals Week",
    "Menu for today's meal",
    "Menu for all meals for a week",
    "Shopping List"
  ];

  const reportListObj = [
    { name: "Exchange Breakdown of Today's Meal" },
    { name: "Exchange Breakdown For All Meals Week" },
    { name: "Specific Food Break Down for Todays meals" },
    { name: "Actual food Breakdown for All Meals Week" },
    { name: "Menu for today's meal" },
    { name: "Menu for all meals for a week" },
    { name: "Shopping List" }
  ];

  const localClasses = useStyles();
  const [dateList, setDateList] = useState([]);
  const [loader1, setLoader1] = useState(true);
  const [loader, setLoader] = useState(false);
  let propData = props["location"]["state"];
  const noScheduleDataMessageFromBackend = "No schedule data";
  const [scheduleDateNotPresent, setScheduleDateNotPresent] = useState(false);
  const userInfo = Auth.getUserInfo();
  const theme = useTheme();
  const [popUpOpen, setPopUpOpen] = useState(false);

  const [statusVariable, setStatusVariable] = useState({
    isError: false,
    isOpenSnackBar: false,
    successErrorMessage: ""
  });

  /**
   * * Create an array with 10 elements [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
   */
  const [exchangeDayList] = useState(
    Array.from({ length: 7 }, (_, i) => i + 1)
  );
  const [mealForm, setMealForm] = useState({
    exchangeDay: propData && propData.exchangeDay ? propData.exchangeDay : 1,
    mealValues: {},
    singleMealTotal: {},
    singleFoodTotal: {},
    total: 0,
    isValuesCalculated: false,
    scheduleId: propData ? propData.id : null,
    scheduleObj: propData ? propData.scheduleObj : null,
    clientId: userInfo.id,
    mealPerDay: null,
    report: "",
    notes: "",
    fromData: propData && propData.from ? propData.from : null
  });

  const [avgFoodCat, setAvgFoodCat] = useState(avgFoodCatValues);
  const [openReportDownload, setOpenReportDownload] = useState(false);
  const [goalValues, setGoalValues] = useState(goalAvgValues);

  const history = useHistory();

  const closeReortDwonloadDialogBox = () => {
    setOpenReportDownload(false);
  };

  const openDownloadReportPopUp = () => {
    setOpenReportDownload(true);
  };

  const updateAllGoals = (
    singleFoodTotal = mealForm.singleFoodTotal,
    avgFood = avgFoodCat,
    mealsPerDay = mealForm.mealPerDay
  ) => {
    setLoader(true);
    setLoader1(true);
    let finalValues = {};
    let totalValues = {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    };

    Object.keys(goalValues.values).map(fv => {
      let totalPortion = parseFloat(singleFoodTotal[fv]);
      let avgcal = totalPortion * parseFloat(avgFood[fv].avgcal);
      let avgpro = totalPortion * parseFloat(avgFood[fv].avgpro);
      let avgfat = totalPortion * parseFloat(avgFood[fv].avgfat);
      let avgcarb = totalPortion * parseFloat(avgFood[fv].avgcarb);
      totalValues.portion = totalValues.portion + totalPortion;
      totalValues.avgcal = totalValues.avgcal + avgcal;
      totalValues.avgpro = totalValues.avgpro + avgpro;
      totalValues.avgcarb = totalValues.avgcarb + avgcarb;
      totalValues.avgfat = totalValues.avgfat + avgfat;
      finalValues[fv] = {
        portion: totalPortion ? totalPortion.toFixed(2) : "0",
        avgcal: avgcal ? avgcal.toFixed(1) : "0",
        avgpro: avgpro ? avgpro.toFixed(2) : "0",
        avgcarb: avgcarb ? avgcarb.toFixed(2) : "0",
        avgfat: avgfat ? avgfat.toFixed(2) : "0"
      };
      return null;
    });

    let calContByPro = totalValues.avgpro * 4;
    let calContByCarb = totalValues.avgcarb * 4;
    let calContByFat = totalValues.avgfat * 9;
    let calContByProPercent = (calContByPro / totalValues.avgcal) * 100;
    let calContByCarbPercent = (calContByCarb / totalValues.avgcal) * 100;
    let calContByFatPercent = (calContByFat / totalValues.avgcal) * 100;
    if (isNaN(calContByProPercent)) {
      calContByProPercent = 0;
    }
    if (isNaN(calContByCarbPercent)) {
      calContByCarbPercent = 0;
    }
    if (isNaN(calContByFatPercent)) {
      calContByFatPercent = 0;
    }

    setGoalValues(goalValues => ({
      ...goalValues,
      values: finalValues,
      total: {
        portion: totalValues.portion ? totalValues.portion.toFixed(2) : "0",
        avgcal: totalValues.avgcal ? totalValues.avgcal.toFixed(1) : "0",
        avgpro: totalValues.avgpro ? totalValues.avgpro.toFixed(2) : "0",
        avgcarb: totalValues.avgcarb ? totalValues.avgcarb.toFixed(2) : "0",
        avgfat: totalValues.avgfat ? totalValues.avgfat.toFixed(2) : "0"
      },
      avgPerMeal: {
        portion:
          totalValues.portion / mealsPerDay
            ? (totalValues.portion / mealsPerDay).toFixed(2)
            : "0",
        avgcal:
          totalValues.avgcal / mealsPerDay
            ? (totalValues.avgcal / mealsPerDay).toFixed(1)
            : "0",
        avgpro:
          totalValues.avgpro / mealsPerDay
            ? (totalValues.avgpro / mealsPerDay).toFixed(2)
            : "0",
        avgcarb:
          totalValues.avgcarb / mealsPerDay
            ? (totalValues.avgcarb / mealsPerDay).toFixed(2)
            : "0",
        avgfat:
          totalValues.avgfat / mealsPerDay
            ? (totalValues.avgfat / mealsPerDay).toFixed(2)
            : "0"
      },
      calContributed: {
        proContNum: calContByPro ? calContByPro.toFixed(2) : "0",
        proContPercent: calContByProPercent
          ? calContByProPercent.toFixed(2)
          : "0",
        carbContNum: calContByCarb ? calContByCarb.toFixed(2) : "0",
        carbContPercent: calContByCarbPercent
          ? calContByCarbPercent.toFixed(2)
          : "0",
        fatCalNum: calContByFat ? calContByFat.toFixed(2) : "0",
        fatCalNumPercent: calContByFatPercent
          ? calContByFatPercent.toFixed(2)
          : "0"
      }
    }));
    setLoader(false);
    setLoader1(false);
  };

  /** Add Default values to meals */
  const addDefaultValuesToMeals = (
    avgFood = avgFoodCat,
    mealsPerDay = mealForm.mealPerDay
  ) => {
    setLoader(true);
    let mealsPerDayArr = Array.from(
      { length: parseInt(mealsPerDay) },
      (_, i) => i + 1
    );
    let data = {};
    let singleMealTotal = {};
    let defaultFoodValues = {
      bread: 0,
      meat: 0,
      veg: 0,
      fruit: 0,
      milk: 0,
      fat: 0
    };
    /*
     * * Add default values
     */
    mealsPerDayArr.map(mealNumber => {
      let key = `meal_${mealNumber}`;
      data[key] = defaultFoodValues;
      singleMealTotal[key] = 0;
      return null;
    });

    /**
     * This will make data as mealForm data as
     * mealForm = {
     *  mealValues : {
     *                meal_1 : {
     *                            bread : 0
     *                            .. all other food values
     *                         } ,
     *                 .... all other meal values
     *                }
     *
     * }
     *
     */

    setGoalValues(goalValues => ({
      ...goalValues,
      values: {
        bread: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        },
        meat: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        },
        veg: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        },
        fruit: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        },
        milk: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        },
        fat: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        }
      },
      total: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      avgPerMeal: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      calContributed: {
        proContNum: 0,
        proContPercent: 0,
        carbContNum: 0,
        carbContPercent: 0,
        fatCalNum: 0,
        fatCalNumPercent: 0
      }
    }));

    setMealForm(mealForm => ({
      ...mealForm,
      mealValues: data,
      singleMealTotal: singleMealTotal,
      singleFoodTotal: defaultFoodValues,
      total: 0,
      isValuesCalculated: true
    }));
    updateAllGoals(defaultFoodValues, avgFood, mealsPerDay);

    setLoader(false);
  };

  /** Get initial data */
  const getAllMealInfo = async (
    ex_day = mealForm.exchangeDay,
    avgFood = avgFoodCat,
    s = mealForm.scheduleId
  ) => {
    setLoader(true);
    await providerForGet(
      getAllMealInfoApi,
      { ex_day: ex_day, scheduleId: s },
      Auth.getToken()
    )
      .then(async res => {
        let mealsPerDay = res.data.no_of_meals_per_day;
        if (res.data.exchange_day) {
          setMealForm(mealForm => ({
            ...mealForm,
            mealValues: res.data.mealValues,
            singleMealTotal: res.data.singleMealTotal,
            singleFoodTotal: res.data.singleFoodTotal,
            total: res.data.total,
            isValuesCalculated: true,
            mealPerDay: parseInt(mealsPerDay)
          }));
          updateAllGoals(res.data.singleFoodTotal, avgFood, mealsPerDay);
        } else {
          setMealForm(mealForm => ({
            ...mealForm,
            mealPerDay: parseInt(mealsPerDay)
          }));
          addDefaultValuesToMeals(avgFood, mealsPerDay);
        }
      })
      .catch(error => {
        addDefaultValuesToMeals(avgFood);
        setLoader(false);
      });
  };

  const getAvgInfo = async (
    ex_day = mealForm.exchangeDay,
    scheduleId = mealForm.scheduleId
  ) => {
    setLoader(true);
    setMealForm(mealForm => ({
      ...mealForm,
      isValuesCalculated: false
    }));
    await providerForGet(foodCategory, { pageSize: -1 }, Auth.getToken())
      .then(async res => {
        let finalAvgFood = {};
        res.data.data.map(fc => {
          let name = "";
          let dataToAdd = {
            avgcal: 0,
            avgpro: 0,
            avgcarb: 0,
            avgfat: 0
          };
          if (fc.name === "Milk" || fc.name === "milk") {
            name = "milk";
          } else if (
            fc.name === "Vegetable" ||
            fc.name === "veg" ||
            fc.name === "vegetable" ||
            fc.name === "Veg"
          ) {
            name = "veg";
          } else if (fc.name === "Fruit" || fc.name === "fruit") {
            name = "fruit";
          } else if (fc.name === "Meat" || fc.name === "meat") {
            name = "meat";
          } else if (fc.name === "Fat" || fc.name === "fat") {
            name = "fat";
          } else if (fc.name === "Bread" || fc.name === "bread") {
            name = "bread";
          }
          if (name !== "") {
            dataToAdd.avgcal = fc.avgcal;
            dataToAdd.avgpro = fc.avgpro;
            dataToAdd.avgcarb = fc.avgcarb;
            dataToAdd.avgfat = fc.avgfat;
            finalAvgFood[name] = dataToAdd;
          }
          return null;
        });
        setAvgFoodCat(finalAvgFood);
        getAllMealInfo(ex_day, finalAvgFood, scheduleId);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });
  };

  const getInitialDates = async () => {
    setLoader(true);
    await providerForGet(
      getScheduleDateForClient,
      {
        c: userInfo.id
      },
      Auth.getToken()
    )
      .then(async res => {
        let convertDateData = convertDate(res.data);
        setDateList(convertDateData);
        let sid = null;
        if (!mealForm.scheduleId || !mealForm.scheduleObj) {
          setMealForm(mealForm => ({
            ...mealForm,
            scheduleId: convertDateData[0].id,
            scheduleObj: convertDateData[0],
            exchangeDay: 1
          }));
          sid = convertDateData[0].id;
        } else {
          sid = mealForm.scheduleId;
        }
        getAvgInfo(mealForm.exchangeDay, sid);
      })
      .catch(error => {
        setLoader(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.statusCode === 400 &&
          error.response.data.message === noScheduleDataMessageFromBackend
        ) {
          setScheduleDateNotPresent(true);
        }
        console.log("error", error);
      });
  };

  useEffect(() => {
    getInitialDates();
  }, []);

  const handleChange = event => {
    setLoader(true);
    setMealForm(mealForm => ({
      ...mealForm,
      exchangeDay: event.target.value
    }));
    getAllMealInfo(event.target.value);
  };

  const handleChangeReport = event => {
    setMealForm(mealForm => ({
      ...mealForm,
      report: event.target.value
    }));
  };

  const handleSnackbarClose = () => {
    setStatusVariable(statusVariable => ({
      ...statusVariable,
      isOpenSnackBar: false,
      isError: "",
      successErrorMessage: ""
    }));
  };

  const handleChangeAutoComplete = async (eventName, event, value) => {
    if (value !== null) {
      setMealForm(mealForm => ({
        ...mealForm,
        scheduleId: value.id,
        scheduleObj: value,
        exchangeDay: 1
      }));
      getAllMealInfo(1, avgFoodCat, value.id);
    } else {
      setMealForm(mealForm => ({
        ...mealForm,
        scheduleId: mealForm.scheduleId,
        scheduleObj: mealForm.scheduleObj
      }));
    }
  };

  const handleActualFoodForAllMeals = async (isPrint = false) => {
    setLoader(true);
    let finalData = {
      schedule_id: mealForm.scheduleId,
      c: mealForm.clientId
    };
    await providerForDownload(
      downloadActualFoodForAllMealsBreakdownPdf,
      finalData,
      Auth.getToken(),
      {
        desc: "Download Actual Food Breakdown For All Meals Week",
        audit_log_clientid: mealForm.clientId
      }
    )
      .then(res => {
        setLoader(false);
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          window.open(url,'_blank');
          // const pdfNewWindow = window.open();
          // pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "actual_food_for_all_meals_breakdown.pdf"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const handleSpecificMeals = async (isPrint = false) => {
    setLoader(true);
    let finalData = {
      exchange_day: mealForm.exchangeDay,
      schedule_id: mealForm.scheduleId,
      c: mealForm.clientId
    };
    await providerForDownload(
      downloadSpecificMealsForTodayPdf,
      finalData,
      Auth.getToken(),
      {
        desc: "Download report for specific food breakdown for today's meal",
        audit_log_clientid: mealForm.clientId
      }
    )
      .then(res => {
        setLoader(false);
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          window.open(url,'_blank');
          // const pdfNewWindow = window.open();
          // pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "specific_food_for_todays_meal.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const getPdfForTodaysMeal = async (isPrint = false) => {
    setLoader(true);
    await providerForDownload(
      getMenuForTodaysMeals,
      {
        exchange_day: mealForm.exchangeDay,
        schedule_id: mealForm.scheduleId,
        c: mealForm.clientId
      },
      Auth.getToken(),
      {
        desc: "Download Menu for todays meal pdf",
        audit_log_clientid: mealForm.clientId
      }
    )
      .then(res => {
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          window.open(url,'_blank');
          // const pdfNewWindow = window.open();
          // pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "menu_for_todays_meal.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const getPdfForWeeksMeal = async (isPrint = false) => {
    setLoader(true);
    await providerForDownload(
      getMenuForWeekMeal,
      {
        schedule_id: mealForm.scheduleId,
        c: mealForm.clientId
      },
      Auth.getToken(),
      {
        desc: "Download Menu for week's meal pdf",
        audit_log_clientid: mealForm.clientId
      }
    )
      .then(res => {
        setLoader(false);
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          window.open(url,'_blank');
          // const pdfNewWindow = window.open();
          // pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "menu_for_entire_weeks_meal.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const downloadSingleDayBreakdownPdf = async (isPrint = false) => {
    setLoader(true);
    let ex_day = mealForm.exchangeDay;
    let scheduleId = mealForm.scheduleId;
    let c = mealForm.clientId;
    let data = {
      scheduleId: scheduleId,
      ex_day: ex_day,
      c: c
    };
    await providerForDownload(getSingleDayBreakdownPdf, data, Auth.getToken(), {
      desc: "Download Exchange Breakdown of Today's Meal",
      audit_log_clientid: c
    })
      .then(res => {
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          window.open(url,'_blank');
          // const pdfNewWindow = window.open();
          // pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "exchange_breakdown_for_todays_meal.pdf"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const downloadAllDaysBreakdownPdf = async (isPrint = false) => {
    setLoader(true);
    let scheduleId = mealForm.scheduleId;
    let c = mealForm.clientId;

    let data = {
      scheduleId: scheduleId,
      c: c
    };
    await providerForDownload(getAllDaysBreakdownPdf, data, Auth.getToken(), {
      desc: "Download Exchange Breakdown For All Meals Week",
      audit_log_clientid: c
    })
      .then(res => {
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          window.open(url,'_blank');
          // const pdfNewWindow = window.open();
          // pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "exchange_breakdown_for_all_meals_week.pdf"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const handleGenerateShoppingList = async (isPrint = false) => {
    setLoader(true);
    let data = {
      schedule_id: mealForm.scheduleId,
      c: mealForm.clientId
    };
    await providerForDownload(generateShoppingList, data, Auth.getToken(), {
      desc: "Download report for shopping list",
      audit_log_clientid: mealForm.clientId
    })
      .then(res => {
        setLoader(false);
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          window.open(url,'_blank');
          // const pdfNewWindow = window.open();
          // pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "shopping_list.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  /**
   *  "Exchange Breakdown of Today's Meal",
      "Exchange Breakdown For All Meals Week",
      "Specific Food Break Down for Todays meals",
      "Actual food Breakdown for All Meals Week",
      "Menu for today's meal",
      "Menu for all meals for a week",
      "Shopping List"
   */

  const downloadReport = () => {
    if (isEmptyString(mealForm.report)) {
      setStatusVariable(statusVariable => ({
        ...statusVariable,
        isOpenSnackBar: true,
        isError: "error",
        successErrorMessage: "Please select a report"
      }));
    }
    if (mealForm.report === "Menu for today's meal") {
      getPdfForTodaysMeal();
    } else if (mealForm.report === "Menu for all meals for a week") {
      getPdfForWeeksMeal();
    } else if (mealForm.report === "Exchange Breakdown of Today's Meal") {
      downloadSingleDayBreakdownPdf();
    } else if (mealForm.report === "Exchange Breakdown For All Meals Week") {
      downloadAllDaysBreakdownPdf();
    } else if (
      mealForm.report === "Specific Food Break Down for Todays meals"
    ) {
      handleSpecificMeals();
    } else if (mealForm.report === "Actual food Breakdown for All Meals Week") {
      handleActualFoodForAllMeals();
    } else if (mealForm.report === "Shopping List") {
      handleGenerateShoppingList();
    }
  };

  const handlePrintReport = rowData => {
    if (rowData.name === "Menu for today's meal") {
      getPdfForTodaysMeal(true);
    } else if (rowData.name === "Menu for all meals for a week") {
      getPdfForWeeksMeal(true);
    } else if (rowData.name === "Exchange Breakdown of Today's Meal") {
      downloadSingleDayBreakdownPdf(true);
    } else if (rowData.name === "Exchange Breakdown For All Meals Week") {
      downloadAllDaysBreakdownPdf(true);
    } else if (rowData.name === "Specific Food Break Down for Todays meals") {
      handleSpecificMeals(true);
    } else if (rowData.name === "Actual food Breakdown for All Meals Week") {
      handleActualFoodForAllMeals(true);
    } else if (rowData.name === "Shopping List") {
      handleGenerateShoppingList(true);
    }

    closeReortDwonloadDialogBox();
  };

  const handleBackArrow = () => {
    let data = {};

    if (mealForm.fromData) {
      data["id"] = mealForm.scheduleObj.id;
      data["scheduleObj"] = mealForm.scheduleObj;
      history.push(mealForm.fromData, data);
    } else {
      history.push(CLIENTMYSCHEDULE, data);
    }
  };

  const handleDialogCancel = () => {
    setPopUpOpen(false);
  };

  const handleTextChange = event => {
    setMealForm(mealForm => ({
      ...mealForm,
      notes: event.target.value
    }));
  };

  return (
    <div>
      <IconButton className={Styles.backIcon} onClick={() => handleBackArrow()}>
        <ChevronLeftIcon
          style={{
            padding: "5px",
            border: "solid #DADADA 1px",
            borderRadius: "25px",
            margin: "0px 1.875rem 0px 10px",
            background: "#fff",
            marginTop: 0
          }}
        />
      </IconButton>

      <HeadingOne>
        <img width="auto" height="1.875rem" src={MenuIcon} alt={"MenuIcon"} />
        <span style={{ position: "absolute", marginLeft: "10px" }}>
          MENU/EXCHANGES
        </span>
      </HeadingOne>

      {scheduleDateNotPresent === true ? (
        <Grid>
          <img
            src={noSchedule}
            className={localClasses.centerImage}
            alt="No schedule/data"
          />
        </Grid>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <AutoCompleteDropDown
                options={dateList}
                getOptionLabel={option => option.schedule_start_date}
                id="schedule_date"
                label="Schedule Date"
                value={
                  dateList[
                    dateList.findIndex(function (item, i) {
                      return item.id === mealForm["scheduleId"];
                    })
                  ] || null
                }
                onChange={(event, value) => {
                  handleChangeAutoComplete("scheduleId", event, value);
                }}
              />
            </Grid>
          </Grid>

          <Grid container className={localClasses.root} spacing={2}>
            <Grid item xs={12} md={12} lg={12} style={{ textAlign: "right" }}>
              <DarkBlueButton
                variant="contained"
                size="small"
                onClick={() => {
                  let data = {};
                  data["id"] = mealForm.scheduleObj.id;
                  data["scheduleObj"] = mealForm.scheduleObj;
                  data["exchangeDay"] = mealForm.exchangeDay;
                  data["schedule_start_date"] =
                    mealForm.scheduleObj.schedule_start_date;
                  data["unformatted_schedule_start_date"] =
                    mealForm.scheduleObj.unformatted_schedule_start_date;
                  data["no_of_meals_per_day"] = mealForm.mealPerDay;
                  history.push(CLIENTMEALS, data);
                }}
              >
                YOUR MEALS
              </DarkBlueButton>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className={localClasses.mealForDayGrid}
              >
                <SelectDropdown
                  title="Meal for day"
                  value={mealForm.exchangeDay}
                  onChange={handleChange}
                >
                  {exchangeDayList.map(day => (
                    <MenuItem key={day} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </SelectDropdown>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginLeft: "auto"
                  }}
                >
                  <FormControl
                    variant="outlined"
                    className={localClasses.formControl}
                  >
                    <Typography className={localClasses.selectBoxLabel}>
                      Report
                    </Typography>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={mealForm.report}
                      onChange={handleChangeReport}
                      className={localClasses.selectBox}
                      style={{ width: 270 }}
                      displayEmpty
                      renderValue={selected => {
                        console.log(selected, "selected");
                        if (selected.length === 0) {
                          return <em>Select a report</em>;
                        }

                        return selected;
                      }}
                    >
                      <MenuItem value="">
                        <em>Select a report</em>
                      </MenuItem>
                      {reportList.map(day => (
                        <MenuItem key={day} value={day}>
                          {day}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <IconButton
                    variant="contained"
                    size="small"
                    style={{
                      marginTop: theme.spacing(1),
                      background: "none"
                    }}
                    onClick={downloadReport}
                  >
                    <Tooltip title="Download Report">
                      <GetAppIcon style={{ color: "#1C4979", fontSize: 30 }} />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    variant="contained"
                    size="small"
                    style={{
                      marginTop: theme.spacing(1),
                      background: "none"
                    }}
                    onClick={openDownloadReportPopUp}
                  >
                    <Tooltip title="Reports to print">
                      <PrintIcon style={{ color: "#1C4979", fontSize: 30 }} />
                    </Tooltip>
                  </IconButton>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{
                  textAlign: "center",
                  width: "100%",
                  marginTop: "0px",
                  overflow: "auto"
                }}
              >
                {loader1 ? null : (
                  <ShowAllMealGoalsForADay goalValues={goalValues} />
                )}
              </Grid>
            </Grid>
            <Backdrop className={localClasses.backdrop} open={loader}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Grid>
              <SnackBar
                open={statusVariable.isOpenSnackBar}
                severity={statusVariable.isError}
                onClose={handleSnackbarClose}
              >
                {statusVariable.successErrorMessage}
              </SnackBar>
            </Grid>

            <DialogBox
              skipCancel={false}
              skipOk={false}
              open={popUpOpen}
              title={"Add/Edit Body Mass Notes"}
              className={localClasses.abc}
              children={
                <div>
                  <p style={{ marginTop: "20px" }}>
                    Schedule date :{" "}
                    {mealForm.scheduleObj &&
                      mealForm.scheduleObj["schedule_start_date"]}
                  </p>
                  <Grid
                    container
                    spacing={1}
                    className={localClasses.notesSection}
                  >
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        id="notes"
                        name="notes"
                        onChange={handleTextChange}
                        value={mealForm.notes}
                        rows={15}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </div>
              }
              handleOk={() => {}}
              handleCancel={handleDialogCancel}
              buttonCancel={"Cancel"}
              buttonOk={"SAVE"}
            />

            <DialogBox
              open={openReportDownload}
              title="Select report to print"
              buttonCancel="Cancel"
              handleCancel={closeReortDwonloadDialogBox}
              skipOk={true}
            >
              <Table
                columns={columns}
                data={reportListObj}
                actions={[
                  {
                    icon: () => <PrintIcon />,
                    tooltip: "Print",
                    onClick: (event, rowData) => {
                      handlePrintReport(rowData);
                    }
                  }
                ]}
                options={{
                  actionsColumnIndex: -1,
                  search: false,
                  sorting: false,
                  paging: false,
                  thirdSortClick: false,
                  headerStyle: {
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    color: "#8A8A97",
                    borderBottom: "solid #E0E0E0 2px",
                    fontSize: "1rem"
                  },
                  cellStyle: {
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    color: "#000000",
                    fontSize: "1rem"
                  }
                }}
              />
            </DialogBox>
          </Grid>
        </>
      )}
    </div>
  );
};

export default ClientExchange;
