const SizeWeightSchema = {
  sizeWeightName: {
    label: "Name",
    id: "sizeWeightName",
    autoComplete: "Name",
    required: true,
    placeholder: "Name",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Name is required"
      }
    }
  },
  franchiseName: {
    label: "Franchise",
    id: "franchiseName",
    autoComplete: "Franchise",
    required: false,
    placeholder: "Franchise",
    autoFocus: true,
    type: "text",
    validations: {}
  }
};
export default SizeWeightSchema;
