import {
  Backdrop,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  useMediaQuery,
  IconButton,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  AddItemsGrid,
  Auth,
  AutoCompleteDropDown,
  CreateMealsGrid,
  DarkBlueButton,
  SnackBar,
  SelectDropdown,
  Input
} from "../../components";
import { providerForGet, providerForPost } from "../../api";
import {
  foodCategory,
  foodSubCategory,
  foodForFranchise,
  savemealInfoForTemplate,
  getExchangeMealInfoForAExchangeDay,
  getMealTemplate
} from "../../constants";
import Styles from "./Exchange.module.css";
import { Prompt } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { EX_TEMPLATE } from "../../paths";

const CreateMealTemplate = props => {
  const theme = useTheme();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [foodError, setFoodError] = useState({
    error: false,
    errorText: "",
    valueError: false,
    valueErrorText: ""
  });

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });
  const [fixedValues, setFixedValues] = useState({
    bread: 0,
    meat: 0,
    milk: 0,
    veg: 0,
    fat: 0,
    fruit: 0
  });
  const [mealsList, setMealsList] = useState(
    Array.from({ length: parseInt(props.no_of_meals_per_day) }, (_, i) => i + 1)
  );
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    mealForDayGrid: {
      display: "flex",
      flexWrap: "wrap"
    },
    inputRoot: {
      "& .MuiOutlinedInput-input": {
        padding: "1.2rem !important"
      },
      "& .MuiOutlinedInput-root": {},
      "& .MuiInputBase-root": {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "1rem",
        color: "#110F48"
      }
    },
    saveButton: {
      display: "flex",
      justifyContent: "flex-end",
      marginLeft: "auto"
    },
    scheduleDate: {
      marginTop: "auto",
      marginBottom: "auto",
      marginLeft: "2%"
    },
    inputClass: {
      marginLeft: "auto",
      marginRight: "auto",
      position: "relative",
      height: "19px",
      "text-align": "center",
      fontSize: "small"
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    addItemsMainGrid: {
      marginTop: "1%",
      border: "1px #ccccb3 solid"
    },
    addFoodItems: {
      marginTop: "1%",
      display: isDesktop ? "flex" : "grid",
      margin: theme.spacing(2)
    },
    individualSelectBoxes: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
      marginBottom: theme.spacing(3)
    },
    addItemsName: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1)
    },
    addedFoodItems: {},
    addedFoodItemsHeader: {
      flexWrap: "inherit"
    },
    formControl: {
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 10,
        paddingRight: 25
      },
      marginLeft: 5,
      marginRight: 5,
      "& .MuiSelect-iconOutlined": {
        right: 3
      },
      "& .MuiInputBase-root": {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "1rem",
        color: "#000000"
      }
    },
    labelStyle: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "1rem",
      color: "#000000",
      border: "none",
      padding: 8,
      margin: "18px 2px"
    },
    itemDropDowns: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "1rem",
      color: "#000000"
    }
  }));

  const [snackBar, setSnackBar] = useState({
    alert: false,
    severity: "success",
    errorMessage: ""
  });

  const localClasses = useStyles();

  const [loader, setLoader] = useState(false);
  const [mealForm, setMealForm] = useState({
    isValuesCalculated: false,
    templateId: null,
    exchangeDay: null,
    mealNo: 1,
    exchange_day_id: null,
    exchange_meal_id: null
  });

  const [selectedFood, setSelectedFood] = useState({
    foodCategoriesList: [],
    foodSubCategoriesList: [],
    foodList: [],
    foodCategory: null,
    foodSubCategory: null,
    food: null,
    foodData: null,
    defaultNoOfExchanges: 1,
    foodsAdded: {},
    totalFoods: {
      totalCal: 0,
      totalPro: 0,
      totalCarb: 0,
      totalfat: 0
    },
    noOfExchangesForActualFood: {
      bread: 0,
      meat: 0,
      veg: 0,
      fruit: 0,
      milk: 0,
      fat: 0,
      misc: 0
    }
  });

  const [goalValues, setGoalValues] = useState({
    bread: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    meat: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    veg: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    fruit: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    milk: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    fat: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    total: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    }
  });

  const [avgFoodCat, setAvgFoodCat] = useState({
    bread: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    meat: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    veg: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    fruit: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    milk: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    fat: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    }
  });

  const convertData = (data, avgData, flag) => {
    setLoader(true);
    let finaldata = {};
    let total = {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    };
    Object.keys(goalValues).map(f => {
      let name = "";
      if (f === "bread") {
        name = "goalbread";
      } else if (f === "meat") {
        name = "goalmeat";
      } else if (f === "veg") {
        name = "goalveg";
      } else if (f === "fruit") {
        name = "goalfruit";
      } else if (f === "milk") {
        name = "goalmilk";
      } else if (f === "fat") {
        name = "goalfat";
      }
      if (name !== "") {
        let portionVal = parseFloat(data.exchange_meal[name]);
        finaldata[f] = {
          portion: portionVal,
          avgcal: portionVal * parseFloat(avgData[f].avgcal),
          avgpro: portionVal * parseFloat(avgData[f].avgpro),
          avgcarb: portionVal * parseFloat(avgData[f].avgcarb),
          avgfat: portionVal * parseFloat(avgData[f].avgfat)
        };
        /** To set reference */
        if (flag) {
          setFixedValues(fixedValues => ({
            ...fixedValues,
            [f]: portionVal
          }));
        }

        total.portion = total.portion + portionVal;
        total.avgcal =
          total.avgcal + portionVal * parseFloat(avgData[f].avgcal);
        total.avgpro =
          total.avgpro + portionVal * parseFloat(avgData[f].avgpro);
        total.avgcarb =
          total.avgcarb + portionVal * parseFloat(avgData[f].avgcarb);
        total.avgfat =
          total.avgfat + portionVal * parseFloat(avgData[f].avgfat);
      }
      return null;
    });
    finaldata.total = total;
    Object.keys(finaldata).map(f => {
      finaldata[f].avgcal = finaldata[f].avgcal
        ? finaldata[f].avgcal.toFixed(1)
        : "0";
      finaldata[f].avgpro = finaldata[f].avgpro
        ? finaldata[f].avgpro.toFixed(1)
        : "0";
      finaldata[f].avgcarb = finaldata[f].avgcarb
        ? finaldata[f].avgcarb.toFixed(1)
        : "0";
      finaldata[f].avgfat = finaldata[f].avgfat
        ? finaldata[f].avgfat.toFixed(1)
        : "0";
      return null;
    });
    setGoalValues(finaldata);
    setLoader(false);
  };

  const getFoodCatAbbrName = foodName => {
    let name = "";
    if (foodName === "Milk" || foodName === "milk") {
      name = "milk";
    } else if (
      foodName === "Vegetable" ||
      foodName === "veg" ||
      foodName === "vegetable" ||
      foodName === "Veg"
    ) {
      name = "veg";
    } else if (foodName === "Fruit" || foodName === "fruit") {
      name = "fruit";
    } else if (foodName === "Meat" || foodName === "meat") {
      name = "meat";
    } else if (foodName === "Fat" || foodName === "fat") {
      name = "fat";
    } else if (foodName === "Bread" || foodName === "bread") {
      name = "bread";
    } else {
      name = "misc";
    }
    return name;
  };

  /** convert data for initial prefill */
  const convertInitailData = data => {
    setLoader(true);
    setMealForm(mealForm => ({
      ...mealForm,
      exchange_day_id: data.exchange_day.id,
      exchange_meal_id: data.exchange_meal.id
    }));

    /** set foods added */
    let num = 1;
    let foodsAddedData = {};
    let totalFoods = {
      totalCal: 0,
      totalPro: 0,
      totalCarb: 0,
      totalfat: 0
    };
    let noOfExchangesForActualFood = {
      bread: 0,
      meat: 0,
      veg: 0,
      fruit: 0,
      milk: 0,
      fat: 0,
      misc: 0
    };
    data.food_data.map(f => {
      if (f.food) {
        let num_of_exchanges = f.no_of_exchanges
          ? parseFloat(f.no_of_exchanges)
          : 0;
        let unit = f.food.unit ? parseFloat(f.food.unit) : 0;
        let proteins = f.food.proteins ? parseFloat(f.food.proteins) : 0;
        let calories = f.food.calories ? parseFloat(f.food.calories) : 0;
        let fats = f.food.fats ? parseFloat(f.food.fats) : 0;
        let carbs = f.food.carbs ? parseFloat(f.food.carbs) : 0;

        let foodCatAbbrName = getFoodCatAbbrName(
          f.food.food_category ? f.food.food_category.name : ""
        );

        noOfExchangesForActualFood[foodCatAbbrName] =
          noOfExchangesForActualFood[foodCatAbbrName] + num_of_exchanges;

        foodsAddedData[num] = {
          id: f.food.id,
          foodCatAbbrName: foodCatAbbrName,
          category: f.food.food_category ? f.food.food_category.name : "",
          name: f.food.name ? f.food.name : "",
          no_of_exchanges: num_of_exchanges,
          portion: num_of_exchanges * unit ? num_of_exchanges * unit : 0,
          proteins:
            num_of_exchanges * proteins
              ? (num_of_exchanges * proteins).toFixed(1)
              : "0",
          calories:
            num_of_exchanges * calories
              ? (num_of_exchanges * calories).toFixed(1)
              : "0",
          fats:
            num_of_exchanges * fats
              ? (num_of_exchanges * fats).toFixed(1)
              : "0",
          carbs:
            num_of_exchanges * carbs
              ? (num_of_exchanges * carbs).toFixed(1)
              : "0",
          portionBackup: unit,
          proteinsBackup: proteins,
          caloriesBackup: calories,
          fatsBackup: fats,
          carbsBackup: carbs,
          portion_unit: f.food.portion_unit ? f.food.portion_unit.name : ""
        };

        totalFoods.totalCal = totalFoods.totalCal + num_of_exchanges * calories;
        totalFoods.totalCarb = totalFoods.totalCarb + num_of_exchanges * carbs;
        totalFoods.totalPro = totalFoods.totalPro + num_of_exchanges * proteins;
        totalFoods.totalfat = totalFoods.totalfat + num_of_exchanges * fats;
      }
      num = num + 1;
      return null;
    });

    setSelectedFood(selectedFood => ({
      ...selectedFood,
      foodsAdded: foodsAddedData,
      totalFoods: {
        totalCal: totalFoods.totalCal ? totalFoods.totalCal.toFixed(1) : "0",
        totalfat: totalFoods.totalfat ? totalFoods.totalfat.toFixed(1) : "0",
        totalCarb: totalFoods.totalCarb ? totalFoods.totalCarb.toFixed(1) : "0",
        totalPro: totalFoods.totalPro ? totalFoods.totalPro.toFixed(1) : "0"
      },
      noOfExchangesForActualFood: noOfExchangesForActualFood
    }));
    setLoader(false);
  };

  const ConvertToDefaultValues = () => {
    setSelectedFood(selectedFood => ({
      ...selectedFood,
      foodSubCategoriesList: [],
      foodList: [],
      foodCategory: null,
      foodSubCategory: null,
      food: null,
      foodData: null,
      foodsAdded: {},
      totalFoods: {
        totalCal: 0,
        totalPro: 0,
        totalCarb: 0,
        totalfat: 0
      },
      noOfExchangesForActualFood: {
        bread: 0,
        meat: 0,
        veg: 0,
        fruit: 0,
        milk: 0,
        fat: 0,
        misc: 0
      }
    }));
    setGoalValues({
      bread: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      meat: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      veg: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      fruit: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      milk: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      fat: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      total: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      }
    });
  };

  const getMealInfo = async (
    mealNo,
    exchange_day = mealForm.exchangeDay,
    templateId = mealForm.templateId,
    finalAvgFood = avgFoodCat,
    flag = false
  ) => {
    /** Average calculated */
    await providerForGet(
      getExchangeMealInfoForAExchangeDay,
      {
        ex_day: exchange_day,
        templateId: templateId,
        meal_no: mealNo
      },
      Auth.getToken()
    )
      .then(r => {
        let changeValuesToZero = false;
        if (r.data.exchange_meal && r.data.exchange_day) {
          convertData(r.data, finalAvgFood, flag);
          convertInitailData(r.data);
        } else if (!r.data.exchange_meal && r.data.exchange_day) {
          setMealForm(mealForm => ({
            ...mealForm,
            exchange_day_id: r.data.exchange_day.id,
            exchange_meal_id: null
          }));
          changeValuesToZero = true;
        } else {
          setMealForm(mealForm => ({
            ...mealForm,
            exchange_day_id: null,
            exchange_meal_id: null
          }));
          changeValuesToZero = true;
        }
        if (changeValuesToZero) {
          ConvertToDefaultValues();
        }
        setLoader(false);
      })
      .catch(e => {
        setLoader(false);
      });
  };

  /** Get initial data */
  const getInitialData = async () => {
    setLoader(true);
    const urlParams = new URLSearchParams(window.location.search);
    let exchange_day = urlParams.get("e_day");
    let templateId = urlParams.get("s");

    setMealForm(mealForm => ({
      ...mealForm,
      templateId: templateId,
      exchangeDay: exchange_day
    }));

    await providerForGet(
      foodCategory,
      { pageSize: -1, _sort: "name:asc" },
      Auth.getToken()
    )
      .then(async res => {
        setSelectedFood(selectedFood => ({
          ...selectedFood,
          foodCategoriesList: res.data.data
        }));
        let finalAvgFood = {};
        res.data.data.map(fc => {
          let name = "";
          let dataToAdd = {
            avgcal: 0,
            avgpro: 0,
            avgcarb: 0,
            avgfat: 0
          };
          if (fc.name === "Milk" || fc.name === "milk") {
            name = "milk";
          } else if (
            fc.name === "Vegetable" ||
            fc.name === "veg" ||
            fc.name === "vegetable" ||
            fc.name === "Veg"
          ) {
            name = "veg";
          } else if (fc.name === "Fruit" || fc.name === "fruit") {
            name = "fruit";
          } else if (fc.name === "Meat" || fc.name === "meat") {
            name = "meat";
          } else if (fc.name === "Fat" || fc.name === "fat") {
            name = "fat";
          } else if (fc.name === "Bread" || fc.name === "bread") {
            name = "bread";
          }
          if (name !== "") {
            dataToAdd.avgcal = fc.avgcal;
            dataToAdd.avgpro = fc.avgpro;
            dataToAdd.avgcarb = fc.avgcarb;
            dataToAdd.avgfat = fc.avgfat;
            finalAvgFood[name] = dataToAdd;
          }
          return null;
        });
        setAvgFoodCat(finalAvgFood);
        getMealInfo(
          mealForm.mealNo,
          exchange_day,
          templateId,
          finalAvgFood,
          true
        );
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });
  };

  const getNoOfMeals = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    setLoader(true);
    await providerForGet(
      getMealTemplate + "/" + urlParams.get("s"),
      {},
      Auth.getToken()
    )
      .then(res => {
        if (res.data) {
          let no_of_meals =
            res.data[`no_of_meals_per_day_${urlParams.get("e_day")}`];
          let no_of_meals_arr = Array.from(
            {
              length: parseInt(no_of_meals)
            },
            (_, i) => i + 1
          );
          setMealsList(no_of_meals_arr);
          getInitialData();
        }
      })
      .catch(error => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getNoOfMeals();
  }, []);

  const getFoodSubCategories = async () => {
    setLoader(true);
    let body = {
      food_category: selectedFood.foodCategory,
      pageSize: -1
    };
    await providerForGet(foodSubCategory, body, Auth.getToken())
      .then(res => {
        setSelectedFood(selectedFood => ({
          ...selectedFood,
          foodSubCategoriesList: res.data.data
        }));
        setLoader(false);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (selectedFood.foodCategory) {
      getFoodSubCategories();
    }
  }, [selectedFood.foodCategory]);

  const getFoodList = async () => {
    setLoader(true);
    let body = {
      food_category: selectedFood.foodCategory,
      food_sub_category: selectedFood.foodSubCategory,
      pageSize: -1
    };
    await providerForGet(foodForFranchise, body, Auth.getToken())
      .then(res => {
        setSelectedFood(selectedFood => ({
          ...selectedFood,
          foodList: res.data
        }));
        setLoader(false);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (selectedFood.foodSubCategory) {
      getFoodList();
    }
  }, [selectedFood.foodSubCategory]);

  const handleSave = async flag => {
    setLoader(true);
    await providerForPost(
      savemealInfoForTemplate,
      {
        exchange_day: mealForm.exchangeDay,
        mealNo: mealForm.mealNo,
        foodsAdded: selectedFood.foodsAdded,
        goals: {
          bread: goalValues.bread.portion,
          meat: goalValues.meat.portion,
          veg: goalValues.veg.portion,
          fruit: goalValues.fruit.portion,
          milk: goalValues.milk.portion,
          fat: goalValues.fat.portion
        },
        templateId: mealForm.templateId,
        exchange_day_id: mealForm.exchange_day_id,
        exchange_meal_id: mealForm.exchange_meal_id
      },
      Auth.getToken(),
      {
        desc:
          "Save meal Info for exchange day " +
          mealForm.exchangeDay +
          " meal " +
          mealForm.mealNo
      }
    )
      .then(res => {
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
      });
  };

  const handleChange = event => {
    setLoader(true);
    handleSave();
    setMealForm(mealForm => ({
      ...mealForm,
      mealNo: event.target.value
    }));
    getMealInfo(event.target.value, undefined, undefined, undefined, true);
  };

  const handleChangeAutoComplete = (eventName, event, value) => {
    if (value) {
      if (eventName === "food") {
        setSelectedFood(selectedFood => ({
          ...selectedFood,
          [eventName]: value.id,
          foodData: value
        }));
      } else if (eventName === "foodCategory") {
        setSelectedFood(selectedFood => ({
          ...selectedFood,
          [eventName]: value.id,
          foodSubCategoriesList: [],
          foodSubCategory: null,
          foodList: [],
          food: null,
          foodData: null
        }));
      } else if (eventName === "foodSubCategory") {
        setSelectedFood(selectedFood => ({
          ...selectedFood,
          [eventName]: value.id,
          foodList: [],
          food: null,
          foodData: null
        }));
      }
    } else {
      if (eventName === "foodCategory") {
        setSelectedFood(selectedFood => ({
          ...selectedFood,
          foodSubCategoriesList: [],
          foodList: [],
          foodCategory: null,
          foodSubCategory: null,
          food: null
        }));
      } else if (eventName === "foodSubCategory") {
        setSelectedFood(selectedFood => ({
          ...selectedFood,
          foodList: [],
          foodSubCategory: null,
          food: null
        }));
      } else if (eventName === "food") {
        setSelectedFood(selectedFood => ({
          ...selectedFood,
          food: null
        }));
      }
    }
  };

  const handleDeleteFood = (id, name, value) => {
    let calToDelete = parseFloat(selectedFood.foodsAdded[id].calories);
    let proToDelete = parseFloat(selectedFood.foodsAdded[id].proteins);
    let carbToDelete = parseFloat(selectedFood.foodsAdded[id].carbs);
    let fatToDelete = parseFloat(selectedFood.foodsAdded[id].fats);
    selectedFood.totalFoods.totalCal =
      parseFloat(selectedFood.totalFoods.totalCal) - calToDelete
        ? (parseFloat(selectedFood.totalFoods.totalCal) - calToDelete).toFixed(
            1
          )
        : "0";
    selectedFood.totalFoods.totalPro =
      parseFloat(selectedFood.totalFoods.totalPro) - proToDelete
        ? (parseFloat(selectedFood.totalFoods.totalPro) - proToDelete).toFixed(
            1
          )
        : "0";
    selectedFood.totalFoods.totalCarb =
      parseFloat(selectedFood.totalFoods.totalCarb) - carbToDelete
        ? (
            parseFloat(selectedFood.totalFoods.totalCarb) - carbToDelete
          ).toFixed(1)
        : "0";
    selectedFood.totalFoods.totalfat =
      parseFloat(selectedFood.totalFoods.totalfat) - fatToDelete
        ? (parseFloat(selectedFood.totalFoods.totalfat) - fatToDelete).toFixed(
            1
          )
        : "0";

    delete selectedFood.foodsAdded[id];
    setSelectedFood(selectedFood => ({
      ...selectedFood,
      noOfExchangesForActualFood: {
        ...selectedFood.noOfExchangesForActualFood,
        [name]:
          selectedFood.noOfExchangesForActualFood[name] - parseFloat(value)
      }
    }));
  };

  const removeError = () => {
    setFoodError(foodError => ({
      ...foodError,
      error: false,
      errorText: ""
    }));
  };

  const handleAddFoodToAMeal = () => {
    removeError();
    if (selectedFood.food && value && value !== "0") {
      let foodCatAbbrName = getFoodCatAbbrName(
        selectedFood.foodData.food_category
          ? selectedFood.foodData.food_category.name
          : ""
      );
      let foodCatCount =
        selectedFood.noOfExchangesForActualFood[foodCatAbbrName];

      let foodCatGoalCount =
        foodCatAbbrName !== "misc" ? goalValues[foodCatAbbrName].portion : 0;

      foodCatCount = parseInt(foodCatCount) + parseInt(value);

      if (foodCatCount < foodCatGoalCount || foodCatAbbrName === "misc") {
        let arr = Object.keys(selectedFood.foodsAdded);
        let num = 1;
        if (arr.length) {
          num =
            parseInt(
              arr
                .sort(function (a, b) {
                  return a - b;
                })
                .pop()
            ) + 1;
        }

        let valToAdd = parseFloat(value);
        let cal = valToAdd * parseFloat(selectedFood.foodData.calories);
        let pro = valToAdd * parseFloat(selectedFood.foodData.proteins);
        let fat = valToAdd * parseFloat(selectedFood.foodData.fats);
        let carb = valToAdd * parseFloat(selectedFood.foodData.carbs);
        let portion = valToAdd * parseFloat(selectedFood.foodData.unit);

        setSelectedFood(selectedFood => ({
          ...selectedFood,
          foodsAdded: {
            ...selectedFood.foodsAdded,
            [num]: {
              id: selectedFood.foodData.id,
              foodCatAbbrName: foodCatAbbrName,
              category: selectedFood.foodData.food_category
                ? selectedFood.foodData.food_category.name
                : "",
              name: selectedFood.foodData.name
                ? selectedFood.foodData.name
                : "",
              no_of_exchanges: value,
              portion: portion ? portion : 0,
              portionBackup: selectedFood.foodData.unit
                ? selectedFood.foodData.unit
                : 0,
              proteins: pro ? pro.toFixed(2) : 0,
              calories: cal ? cal.toFixed(1) : 0,
              fats: fat ? fat.toFixed(2) : 0,
              carbs: carb ? carb.toFixed(2) : 0,
              proteinsBackup: selectedFood.foodData.proteins
                ? selectedFood.foodData.proteins
                : 0,
              caloriesBackup: selectedFood.foodData.calories
                ? selectedFood.foodData.calories
                : 0,
              fatsBackup: selectedFood.foodData.fats
                ? selectedFood.foodData.fats
                : 0,
              carbsBackup: selectedFood.foodData.carbs
                ? selectedFood.foodData.carbs
                : 0,
              portion_unit: selectedFood.foodData.portion_unit
                ? selectedFood.foodData.portion_unit.name
                : ""
            }
          },
          totalFoods: {
            totalCal:
              parseFloat(selectedFood.totalFoods.totalCal) + cal
                ? (parseFloat(selectedFood.totalFoods.totalCal) + cal).toFixed(
                    1
                  )
                : "0",
            totalPro:
              parseFloat(selectedFood.totalFoods.totalPro) + pro
                ? (parseFloat(selectedFood.totalFoods.totalPro) + pro).toFixed(
                    2
                  )
                : "0",
            totalCarb:
              parseFloat(selectedFood.totalFoods.totalCarb) + carb
                ? (
                    parseFloat(selectedFood.totalFoods.totalCarb) + carb
                  ).toFixed(2)
                : "0",
            totalfat:
              parseFloat(selectedFood.totalFoods.totalfat) + fat
                ? (parseFloat(selectedFood.totalFoods.totalfat) + fat).toFixed(
                    2
                  )
                : "0"
          },
          noOfExchangesForActualFood: {
            ...selectedFood.noOfExchangesForActualFood,
            [foodCatAbbrName]: foodCatCount
          }
        }));
        setValue(1);
      } else {
        setSnackBar(snackBar => ({
          ...snackBar,
          alert: true,
          severity: "error",
          errorMessage: `No of goal exchanges for ${foodCatAbbrName} doesn't match the actual ${foodCatAbbrName} exchange`
        }));
      }
    } else {
      if (
        value === undefined ||
        value === null ||
        value === "0" ||
        value === ""
      ) {
        setFoodError(foodError => ({
          ...foodError,
          valueError: true,
          valueErrorText: "No. of exchanges cannot be 0"
        }));
      } else {
        setSnackBar(snackBar => ({
          ...snackBar,
          alert: true,
          severity: "error",
          errorMessage: `Please select food item`
        }));
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackBar(snackBar => ({
      ...snackBar,
      alert: false,
      errorMessage: "",
      severity: "success"
    }));
  };

  const handleChangeForNoOfExchanges = (
    value,
    id,
    prevValue,
    name,
    isOnBlur
  ) => {
    if (isOnBlur) {
      value = prevValue;
    }
    let valToAdd = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    let valToSub = isNaN(parseFloat(prevValue)) ? 0 : parseFloat(prevValue);
    let val =
      selectedFood.noOfExchangesForActualFood[name] - valToSub + valToAdd;

    let oldPortion = isNaN(
      parseFloat(selectedFood.foodsAdded[id].portionBackup)
    )
      ? 0
      : parseFloat(selectedFood.foodsAdded[id].portionBackup);

    /** Protein calculation */
    let proBackupValue = isNaN(
      parseFloat(selectedFood.foodsAdded[id].proteinsBackup)
    )
      ? 0
      : parseFloat(selectedFood.foodsAdded[id].proteinsBackup);
    let proToAdd = valToAdd * proBackupValue;
    let proToSub = isNaN(parseFloat(selectedFood.foodsAdded[id].proteins))
      ? 0
      : parseFloat(selectedFood.foodsAdded[id].proteins);
    /** Protein calculation */
    let calBackup = isNaN(
      parseFloat(selectedFood.foodsAdded[id].caloriesBackup)
    )
      ? 0
      : parseFloat(selectedFood.foodsAdded[id].caloriesBackup);
    let calToAdd = valToAdd * calBackup;
    let calToSub = isNaN(parseFloat(selectedFood.foodsAdded[id].calories))
      ? 0
      : parseFloat(selectedFood.foodsAdded[id].calories);

    /** Protein calculation */
    let fatsBackupValue = isNaN(
      parseFloat(selectedFood.foodsAdded[id].fatsBackup)
    )
      ? 0
      : parseFloat(selectedFood.foodsAdded[id].fatsBackup);
    let fatsToAdd = valToAdd * fatsBackupValue;
    let fatsToSub = isNaN(parseFloat(selectedFood.foodsAdded[id].fats))
      ? 0
      : parseFloat(selectedFood.foodsAdded[id].fats);

    /** Protein calculation */
    let carbsBackupValue = isNaN(
      parseFloat(selectedFood.foodsAdded[id].carbsBackup)
    )
      ? 0
      : parseFloat(selectedFood.foodsAdded[id].carbsBackup);
    let carbsToAdd = valToAdd * carbsBackupValue;
    let carbsToSub = isNaN(parseFloat(selectedFood.foodsAdded[id].carbs))
      ? 0
      : parseFloat(selectedFood.foodsAdded[id].carbs);

    let foodCatCount = val;
    let foodCatGoalCount = name !== "misc" ? goalValues[name].portion : 0;

    if (foodCatCount <= foodCatGoalCount || name === "misc") {
      setSelectedFood(selectedFood => ({
        ...selectedFood,
        foodsAdded: {
          ...selectedFood.foodsAdded,
          [id]: {
            ...selectedFood.foodsAdded[id],
            no_of_exchanges: value,
            portion: parseFloat(oldPortion * valToAdd)
              ? parseFloat(oldPortion * valToAdd)
              : 0,
            proteins: proToAdd ? proToAdd.toFixed(1) : "0",
            calories: calToAdd ? calToAdd.toFixed(1) : "0",
            fats: fatsToAdd ? fatsToAdd.toFixed(1) : "0",
            carbs: carbsToAdd ? carbsToAdd.toFixed(1) : "0"
          }
        },
        noOfExchangesForActualFood: {
          ...selectedFood.noOfExchangesForActualFood,
          [name]: val
        },
        totalFoods: {
          totalCal:
            parseFloat(selectedFood.totalFoods.totalCal) - calToSub + calToAdd
              ? (
                  parseFloat(selectedFood.totalFoods.totalCal) -
                  calToSub +
                  calToAdd
                ).toFixed(1)
              : "0",
          totalPro:
            parseFloat(selectedFood.totalFoods.totalPro) - proToSub + proToAdd
              ? (
                  parseFloat(selectedFood.totalFoods.totalPro) -
                  proToSub +
                  proToAdd
                ).toFixed(1)
              : "0",
          totalCarb:
            parseFloat(selectedFood.totalFoods.totalCarb) -
            carbsToSub +
            carbsToAdd
              ? (
                  parseFloat(selectedFood.totalFoods.totalCarb) -
                  carbsToSub +
                  carbsToAdd
                ).toFixed(1)
              : "0",
          totalfat:
            parseFloat(selectedFood.totalFoods.totalfat) - fatsToSub + fatsToAdd
              ? (
                  parseFloat(selectedFood.totalFoods.totalfat) -
                  fatsToSub +
                  fatsToAdd
                ).toFixed(1)
              : "0"
        }
      }));
    } else {
      setSnackBar(snackBar => ({
        ...snackBar,
        alert: true,
        severity: "error",
        errorMessage: `No of goal exchanges for ${name} doesn't match the actual ${name} exchange`
      }));
    }
  };

  const onLooseFocus = (value, param) => {
    let total = {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    };

    let foodCatCount = selectedFood.noOfExchangesForActualFood[param];
    let valToAdd = isNaN(parseFloat(value)) ? foodCatCount : parseFloat(value);
    Object.keys(goalValues).map(f => {
      let val = parseFloat(goalValues[f].portion);
      let cal = parseFloat(goalValues[f].avgcal);
      let pro = parseFloat(goalValues[f].avgpro);
      let carb = parseFloat(goalValues[f].avgcarb);
      let fat = parseFloat(goalValues[f].avgfat);
      if (f !== "total") {
        if (f === param) {
          total.portion = total.portion + valToAdd;
          total.avgcal =
            total.avgcal + parseFloat(avgFoodCat[param].avgcal) * valToAdd;
          total.avgpro =
            total.avgpro + parseFloat(avgFoodCat[param].avgpro) * valToAdd;
          total.avgcarb =
            total.avgcarb + parseFloat(avgFoodCat[param].avgcarb) * valToAdd;
          total.avgfat =
            total.avgfat + parseFloat(avgFoodCat[param].avgfat) * valToAdd;
        } else {
          total.portion = total.portion + val;
          total.avgcal = total.avgcal + cal;
          total.avgpro = total.avgpro + pro;
          total.avgcarb = total.avgcarb + carb;
          total.avgfat = total.avgfat + fat;
        }
      }
      return null;
    });

    setGoalValues(goalValues => ({
      ...goalValues,
      [param]: {
        ...goalValues[param],
        portion: valToAdd,
        avgcal:
          parseFloat(avgFoodCat[param].avgcal) * valToAdd
            ? (parseFloat(avgFoodCat[param].avgcal) * valToAdd).toFixed(1)
            : "0",
        avgpro:
          parseFloat(avgFoodCat[param].avgpro) * valToAdd
            ? (parseFloat(avgFoodCat[param].avgpro) * valToAdd).toFixed(1)
            : "0",
        avgcarb:
          parseFloat(avgFoodCat[param].avgcarb) * valToAdd
            ? (parseFloat(avgFoodCat[param].avgcarb) * valToAdd).toFixed(1)
            : "0",
        avgfat:
          parseFloat(avgFoodCat[param].avgfat) * valToAdd
            ? (parseFloat(avgFoodCat[param].avgfat) * valToAdd).toFixed(1)
            : "0"
      },
      total: {
        ...goalValues.total,
        portion: total.portion ? total.portion.toFixed(1) : "0",
        avgcal: total.avgcal ? total.avgcal.toFixed(1) : "0",
        avgpro: total.avgpro ? total.avgpro.toFixed(1) : "0",
        avgcarb: total.avgcarb ? total.avgcarb.toFixed(1) : "0",
        avgfat: total.avgfat ? total.avgfat.toFixed(1) : "0"
      }
    }));
  };

  const handleChangeForGoals = (value, param) => {
    let total = {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    };

    let foodCatCount = selectedFood.noOfExchangesForActualFood[param];
    let valToAdd = isNaN(parseFloat(value)) ? foodCatCount : parseFloat(value);

    let foodCatGoalCount = valToAdd;

    if (foodCatGoalCount >= foodCatCount) {
      Object.keys(goalValues).map(f => {
        let val = parseFloat(goalValues[f].portion);
        let cal = parseFloat(goalValues[f].avgcal);
        let pro = parseFloat(goalValues[f].avgpro);
        let carb = parseFloat(goalValues[f].avgcarb);
        let fat = parseFloat(goalValues[f].avgfat);
        if (f !== "total") {
          if (f === param) {
            total.portion = total.portion + valToAdd;
            total.avgcal =
              total.avgcal + parseFloat(avgFoodCat[param].avgcal) * valToAdd;
            total.avgpro =
              total.avgpro + parseFloat(avgFoodCat[param].avgpro) * valToAdd;
            total.avgcarb =
              total.avgcarb + parseFloat(avgFoodCat[param].avgcarb) * valToAdd;
            total.avgfat =
              total.avgfat + parseFloat(avgFoodCat[param].avgfat) * valToAdd;
          } else {
            total.portion = total.portion + val;
            total.avgcal = total.avgcal + cal;
            total.avgpro = total.avgpro + pro;
            total.avgcarb = total.avgcarb + carb;
            total.avgfat = total.avgfat + fat;
          }
        }
        return null;
      });

      setGoalValues(goalValues => ({
        ...goalValues,
        [param]: {
          ...goalValues[param],
          portion: parseFloat(value),
          avgcal:
            parseFloat(avgFoodCat[param].avgcal) * valToAdd
              ? (parseFloat(avgFoodCat[param].avgcal) * valToAdd).toFixed(1)
              : "0",
          avgpro:
            parseFloat(avgFoodCat[param].avgpro) * valToAdd
              ? (parseFloat(avgFoodCat[param].avgpro) * valToAdd).toFixed(1)
              : "0",
          avgcarb:
            parseFloat(avgFoodCat[param].avgcarb) * valToAdd
              ? (parseFloat(avgFoodCat[param].avgcarb) * valToAdd).toFixed(1)
              : "0",
          avgfat:
            parseFloat(avgFoodCat[param].avgfat) * valToAdd
              ? (parseFloat(avgFoodCat[param].avgfat) * valToAdd).toFixed(1)
              : "0"
        },
        total: {
          ...goalValues.total,
          portion: total.portion ? total.portion.toFixed(1) : "0",
          avgcal: total.avgcal ? total.avgcal.toFixed(1) : "0",
          avgpro: total.avgpro ? total.avgpro.toFixed(1) : "0",
          avgcarb: total.avgcarb ? total.avgcarb.toFixed(1) : "0",
          avgfat: total.avgfat ? total.avgfat.toFixed(1) : "0"
        }
      }));
    } else {
      setSnackBar(snackBar => ({
        ...snackBar,
        alert: true,
        severity: "error",
        errorMessage: `No of goal exchanges for ${param} doesn't match the actual ${param} exchange`
      }));
    }
  };

  const copyFromActualFood = () => {
    setLoader(true);
    let finaldata = {};
    let total = {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    };
    Object.keys(goalValues).map(f => {
      if (f !== "total") {
        let portionVal = parseFloat(selectedFood.noOfExchangesForActualFood[f]);
        finaldata[f] = {
          portion: portionVal,
          avgcal: portionVal * parseFloat(avgFoodCat[f].avgcal),
          avgpro: portionVal * parseFloat(avgFoodCat[f].avgpro),
          avgcarb: portionVal * parseFloat(avgFoodCat[f].avgcarb),
          avgfat: portionVal * parseFloat(avgFoodCat[f].avgfat)
        };

        total.portion = total.portion + portionVal;
        total.avgcal =
          total.avgcal + portionVal * parseFloat(avgFoodCat[f].avgcal);
        total.avgpro =
          total.avgpro + portionVal * parseFloat(avgFoodCat[f].avgpro);
        total.avgcarb =
          total.avgcarb + portionVal * parseFloat(avgFoodCat[f].avgcarb);
        total.avgfat =
          total.avgfat + portionVal * parseFloat(avgFoodCat[f].avgfat);
      }
      return null;
    });
    finaldata.total = total;
    Object.keys(finaldata).map(f => {
      finaldata[f].avgcal = finaldata[f].avgcal
        ? finaldata[f].avgcal.toFixed(1)
        : "0";
      finaldata[f].avgpro = finaldata[f].avgpro
        ? finaldata[f].avgpro.toFixed(1)
        : "0";
      finaldata[f].avgcarb = finaldata[f].avgcarb
        ? finaldata[f].avgcarb.toFixed(1)
        : "0";
      finaldata[f].avgfat = finaldata[f].avgfat
        ? finaldata[f].avgfat.toFixed(1)
        : "0";
      return null;
    });
    setGoalValues(finaldata);
    setLoader(false);
  };

  const handleBackArrow = () => {
    history.push({
      pathname: EX_TEMPLATE,
      search: `s=${mealForm.templateId}&e_day=${mealForm.exchangeDay}`,
      isValid: true
    });
  };

  return (
    <>
      <Prompt
        message={(location, action) => {
          handleSave();
        }}
      />
      <IconButton className={Styles.backIcon} onClick={() => handleBackArrow()}>
        <ChevronLeftIcon
          style={{
            padding: "5px",
            border: "solid #DADADA 1px",
            borderRadius: "25px",
            margin: "0px 1.875rem 0px 10px",
            background: "#fff",
            marginTop: 0
          }}
        />
      </IconButton>
      <Grid container direction="row">
        <Grid item container xs={12} direction="row">
          <SelectDropdown
            title="Meal"
            value={mealForm.mealNo}
            onChange={handleChange}
            fromMealSection={true}
          >
            {mealsList.map(day => (
              <MenuItem key={day} value={day}>
                {day}
              </MenuItem>
            ))}
          </SelectDropdown>
          <InputLabel className={localClasses.labelStyle}>
            For Day {mealForm.exchangeDay}
          </InputLabel>
          <SnackBar
            open={snackBar.alert}
            severity={snackBar.severity}
            onClose={handleSnackbarClose}
          >
            {snackBar.errorMessage}
          </SnackBar>
          <div className={localClasses.saveButton}>
            <DarkBlueButton
              variant="contained"
              size="small"
              style={{
                margin: theme.spacing(1)
              }}
              onClick={handleSave}
            >
              SAVE
            </DarkBlueButton>
          </div>
        </Grid>
        <Grid item container xs={12} spacing={3} style={{ marginTop: "1%" }}>
          <Grid item xs={12} md={2}>
            <AutoCompleteDropDown
              id="Categories"
              options={
                selectedFood.foodCategoriesList
                  ? selectedFood.foodCategoriesList
                  : []
              }
              getOptionLabel={option => option.name}
              fullWidth
              value={
                selectedFood.foodCategoriesList[
                  selectedFood.foodCategoriesList.findIndex(function (item, i) {
                    return item.id === selectedFood.foodCategory;
                  })
                ] || null
              }
              onChange={(event, value) => {
                removeError();
                handleChangeAutoComplete("foodCategory", event, value);
              }}
              renderInput={params => (
                <Input
                  {...params}
                  label="Categories"
                  variant="outlined"
                  error={foodError.error}
                  helperText={
                    foodError.error ? (
                      <Typography variant="body2" gutterBottom>
                        {foodError.errorText}
                      </Typography>
                    ) : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <AutoCompleteDropDown
              id="Sub-categories"
              options={
                selectedFood.foodSubCategoriesList
                  ? selectedFood.foodSubCategoriesList
                  : []
              }
              getOptionLabel={option => option.name}
              fullWidth
              value={
                selectedFood.foodSubCategoriesList[
                  selectedFood.foodSubCategoriesList.findIndex(function (
                    item,
                    i
                  ) {
                    return item.id === selectedFood.foodSubCategory;
                  })
                ] || null
              }
              onChange={(event, value) => {
                removeError();
                handleChangeAutoComplete("foodSubCategory", event, value);
              }}
              renderInput={params => (
                <Input {...params} label="Sub Categories" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <AutoCompleteDropDown
              id="items"
              options={selectedFood.foodList ? selectedFood.foodList : []}
              getOptionLabel={option => option.name}
              fullWidth
              value={
                selectedFood.foodList[
                  selectedFood.foodList.findIndex(function (item, i) {
                    return item.id === selectedFood.food;
                  })
                ] || null
              }
              onChange={(event, value) => {
                removeError();
                handleChangeAutoComplete("food", event, value);
              }}
              renderInput={params => (
                <Input {...params} label="Items" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <div className={localClasses.inputRoot}>
              <Input
                id={"no_of_exchange"}
                label={"No. of exchanges"}
                onChange={e => {
                  removeError();
                  setFoodError(foodError => ({
                    ...foodError,
                    error: false,
                    errorText: "",
                    valueError: false,
                    valueErrorText: ""
                  }));
                  setValue(e.target.value);
                }}
                type="number"
                variant="outlined"
                value={value}
                className={localClasses.inputClass}
                InputProps={{
                  style: {
                    padding: "0.875rem !important"
                  }
                }}
                error={foodError.valueError}
                helperText={
                  foodError.valueError ? (
                    <Typography variant="body2" gutterBottom>
                      {foodError.valueErrorText}
                    </Typography>
                  ) : null
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <DarkBlueButton variant="contained" onClick={handleAddFoodToAMeal}>
              ADD ITEM
            </DarkBlueButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={3} style={{ marginTop: "1%" }}>
        <Grid item xs={12} md={4}>
          <CreateMealsGrid
            handleChangeForGoals={handleChangeForGoals}
            foodData={selectedFood}
            goalValues={goalValues}
            fixedValues={fixedValues}
            copyFromActualFood={copyFromActualFood}
            onLooseFocus={onLooseFocus}
          />
        </Grid>
        <Grid item xs={12} md={8} className={Styles.addItemGrid}>
          <AddItemsGrid
            foodData={selectedFood}
            goalValues={goalValues}
            handleChangeAutoComplete={handleChangeAutoComplete}
            handleAddFoodToAMeal={handleAddFoodToAMeal}
            handleDeleteFood={handleDeleteFood}
            handleSave={handleSave}
            handleChangeForNoOfExchanges={handleChangeForNoOfExchanges}
          />
        </Grid>
      </Grid>

      <Backdrop className={localClasses.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CreateMealTemplate;
