import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const localStyles = makeStyles(theme => ({
  outlinedButtonStyle: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    color: "#110F48",
    backgroundColor: "#ffffff",
    border: "solid #110F48 1px",
    fontSize: "0.875rem",
    "&:hover": {
      background: "#ffffff",
      color: "#110F48",
      border: "solid #110F48 1px"
    }
  }
}));

export default function OutlinedButton(props) {
  const classes = localStyles();

  return (
    <Button
      className={classes.outlinedButtonStyle}
      variant="contained"
      onClick={props.onClick}
      style={props.style}
      {...props}
    >
      {props.children}
    </Button>
  );
}
