export { validation } from "./Validation";

export {
  checkAllKeysPresent,
  getListOfKeysNotPresent,
  setErrors,
  checkEmpty,
  hasError,
  showError
} from "./FormUtilities";
export { encryptSHA256, decryptWithAES, encryptWithAES } from "./Encryption";
export {
  getInitials,
  isNumeric,
  calculateDiffTime,
  getFormattedTime,
  getDateInYYYYMMDD,
  getFormattedDate,
  converToFraction,
  isEmptyString,
  convertNumber,
  onlyUnique,
  roundTo1Digit,
  roundTo2Digit,
  convertDate,
  avgFoodCatValues,
  goalAvgValues,
  formatDate,
  plainDate,
  asyncForEach
} from "./CommonUtils";
export { default as useStyles } from "./Styles/ManagePageStyles";
