import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { Layout } from "../hoc";
import { Auth as auth } from "../components";
import { LOGIN } from "../paths";

const ClientRoute = ({
  component: Component,
  computedMatch: ComputedMatch,
  location: Location,
  ...otherProps
}) => {
  if (auth.getToken() !== null) {
    if (
      auth.getUserInfo().role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
    ) {
      return (
        <>
          <Route
            render={otherProps => (
              <>
                <Layout>
                  <Component
                    {...otherProps}
                    ComputedMatch={ComputedMatch}
                    location={Location}
                  />
                </Layout>
              </>
            )}
          />
        </>
      );
    } else {
      /*
       * * currently just logout the user later on will redirect the user to another page
       */
      return (
        <React.Fragment>
          {auth.clearAppStorage()}
          <Redirect
            to={{
              pathname: LOGIN
            }}
          />
        </React.Fragment>
      );
    }
  } else {
    return (
      <React.Fragment>
        {auth.clearAppStorage()}
        <Redirect
          to={{
            pathname: LOGIN
          }}
        />
      </React.Fragment>
    );
  }
};
export default ClientRoute;
