import React, { useState, useEffect } from "react";
import styles from "./ClientExercise.module.css";
import {
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import {
  AutoCompleteDropDown,
  ExerciseGrid,
  HeadingOne,
  Input,
  SelectDropdown,
  SnackBar
} from "../../components";
import auth from "../../components/Auth";
import { providerForDownload, providerForGet, providerForPut } from "../../api";
import {
  createExerciseReport,
  exerciseForFranchise,
  getDataForWorkout,
  getScheduleDateForClient,
  getWorkoutDayInfo,
  muscleGroup
} from "../../constants";
import GetAppIcon from "@material-ui/icons/GetApp";
import ExerciseIcon from "../../assets/small_icons/Exercise.png";
import DialogBox from "../../components/DialogBox/DialogBox";
import { CLIENTMYSCHEDULE } from "../../paths";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { ERROR_MESSAGE } from "../../constants/genericConstants";
import { convertDate } from "../../utils";
import noSchedule from "../../assets/images/No_schedule.jpg";
import PrintIcon from "@material-ui/icons/Print";

const ClientExercise = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    titleText: {
      color: "#110F48",
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "1.25rem",
      marginBottom: "1.5625rem",
      "& svg": {
        verticalAlign: "middle"
      }
    },
    summaryButtonBox: {
      display: "flex",
      marginBottom: "3%"
    },
    loadTemplate: {
      marginLeft: "auto"
    },
    favoritesOption: {
      display: "flex",
      marginRight: "auto"
    },
    margin: {
      margin: theme.spacing(1)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    formControl: {
      margin: "10px 0px",
      minWidth: 270,
      flexDirection: "row"
    },
    cancelSaveButton: {
      float: "right"
    },
    workoutForDayStyles: {
      marginTop: "2rem",
      display: "flex",
      marginBottom: "1.625rem"
    },
    workoutDayGrid: {
      paddingTop: "0px !Important"
    },
    dialogBoxDesign: {
      "& MuiPaper-root": {
        width: 900
      }
    },
    notesSection: {
      marginTop: "1.25rem",
      width: "500px"
    },
    selectBox: {
      height: 30,
      weight: 10,
      borderRadius: 10,
      marginTop: theme.spacing(1)
    },
    selectBoxLabel: {
      marginTop: 11,
      marginRight: theme.spacing(1),
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.15px",
      color: "#000000"
    },
    centerImage: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: "30%"
    }
  }));
  const localClasses = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [dateList, setDateList] = useState([]);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const userInfo = auth.getUserInfo();
  let propData = props["location"]["state"];
  const noScheduleDataMessageFromBackend = "No schedule data";
  const [scheduleDateNotPresent, setScheduleDateNotPresent] = useState(false);
  const [loader, setLoader] = useState(false);
  const [muscleGroups, setMuscleGroups] = useState([]);
  const [exerciseData, setExerciseData] = useState([]);
  const [offId, setOffId] = useState([]);
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  const [workoutDayList] = useState(Array.from({ length: 7 }, (_, i) => i + 1));
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    scheduleId: propData ? propData.id : null,
    notes: null
  });
  const [statusVariable, setStatusVariable] = useState({
    isAdd: false,
    isError: false,
    isOpenSnackBar: false,
    successErrorMessage: "",
    saveText: "",
    dummyTime: new Date(),
    savedTime: null,
    isSaving: false
  });
  const exerciseDummyData = {
    id: null,
    exerciseId: null,
    setReps: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0
    }
  };
  const [exerciseForm, setExerciseForm] = useState({
    clientId: userInfo.id,
    workoutDay: 1,
    muscleGroupId: null,
    exerciseList: {
      1: exerciseDummyData /** This is the initial data */
    }
  });

  const addDefaultData = () => {
    setExerciseForm(exerciseForm => ({
      ...exerciseForm,
      exerciseList: {
        1: exerciseDummyData /** This is the initial data */
      }
    }));
  };
  /**
   * get exercise data
   */
  const getExerciseInfo = async (workoutDay = exerciseForm.workoutDay) => {
    setLoader(true);
    await providerForGet(
      getDataForWorkout,
      { scheduleId: formState.scheduleId, workoutDay: workoutDay },
      auth.getToken()
    )
      .then(res => {
        if (res.data.isPresent) {
          setExerciseForm(exerciseForm => ({
            ...exerciseForm,
            muscleGroupId: res.data.muscleGroupId,
            exerciseList: res.data.exerciseList
          }));
        } else {
          if (res.data.muscleGroupId) {
            setExerciseForm(exerciseForm => ({
              ...exerciseForm,
              muscleGroupId: res.data.muscleGroupId
            }));
          } else {
            setExerciseForm(exerciseForm => ({
              ...exerciseForm,
              muscleGroupId: offId
            }));
          }
          addDefaultData();
        }
        setLoader(false);
      })
      .catch(err => {
        addDefaultData();
        setLoader(false);
      });
  };

  const getInitialExerciseData = async () => {
    setLoader(true);
    await providerForGet(
      getScheduleDateForClient,
      {
        c: userInfo.id
      },
      auth.getToken()
    )
      .then(async res => {
        setLoader(false);
        let convertDateData = await convertDate(res.data);
        setDateList(convertDateData);
        if (formState.scheduleId === null) {
          formState.scheduleId = convertDateData[0].id;
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              scheduleDate: convertDateData[0].id,
              scheduleObj: convertDateData[0]
            }
          }));
        }

        let resObj = convertDateData;
        resObj.map(r => {
          if (r.id === formState.scheduleId) {
            setFormState(formState => ({
              ...formState,
              values: {
                ...formState.values,
                scheduleDate: r.id,
                scheduleObj: r
              }
            }));
          }
          return null;
        });
      })
      .catch(error => {
        console.log("error", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.statusCode === 400 &&
          error.response.data.message === noScheduleDataMessageFromBackend
        ) {
          setScheduleDateNotPresent(true);
        }
        setLoader(false);
      });

    /**
     * get muscle group list data
     */

    await providerForGet(muscleGroup, { pageSize: -1 }, auth.getToken())
      .then(res => {
        let muscleId = null;

        res.data.data.map(r => {
          if (r.name === "Off") {
            setOffId(r.id);
            muscleId = r.id;
          }
          return null;
        });

        setMuscleGroups(res.data.data);
        if (res.data.data.length) {
          setExerciseForm(exerciseForm => ({
            ...exerciseForm,
            muscleGroupId: muscleId
          }));
        }
      })
      .catch(err => {
        console.log("err", err);
      });

    /**
     * get Exercise list data
     */
    await providerForGet(
      exerciseForFranchise,
      { pageSize: -1, desc: "Get Exercise Data for a Client" },
      auth.getToken()
    )
      .then(res => {
        setExerciseData(res.data);
        setLoader(false);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });

    /**
     * get exercise data for a schedule
     */
    getExerciseInfo();
  };

  useEffect(() => {
    getInitialExerciseData();
  }, []);

  useEffect(() => {
    getExerciseInfo();
  }, [formState.scheduleId]);

  const handleChange = event => {
    setLoader(true);
    setExerciseForm(exerciseForm => ({
      ...exerciseForm,
      workoutDay: event.target.value
    }));
    getExerciseInfo(event.target.value);
  };

  const handleChangeAutoComplete = async (eventName, event, value) => {
    if (eventName === "scheduleDate") {
      if (value !== null) {
        setFormState(formState => ({
          ...formState,
          scheduleId: value.id,
          values: {
            ...formState.values,
            [eventName]: value.id,
            scheduleObj: value
          }
        }));
      } else {
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [eventName]: formState.values.scheduleDate
          }
        }));
      }
    }
  };

  const generateReport = async isPrint => {
    setLoader(true);
    await providerForDownload(
      createExerciseReport,
      {
        schedule_id: formState.scheduleId,
        c: exerciseForm.clientId
      },
      auth.getToken(),
      {
        desc: "Download report for exercise by client"
      }
    )
      .then(res => {
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          const pdfNewWindow = window.open();
          pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Workout_program.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const checkIfDataProper = (isForDelete = false) => {
    let isValid = true;
    if (isForDelete) {
      let arr = Object.keys(exerciseForm.exerciseList);
      if (arr.length === 1 && !exerciseForm.exerciseList[arr[0]].exerciseId) {
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      let arr = Object.keys(exerciseForm.exerciseList);
      arr.map(i => {
        if (!exerciseForm.exerciseList[i].exerciseId) {
          isValid = false;
        }
        return null;
      });
    }
    return isValid;
  };

  const handleTextChange = event => {
    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleDialogCancel = () => {
    setPopUpOpen(false);
  };

  const handleDialogOk = async () => {
    /**
     * save notes data
     */
    setLoader(true);
    let dataToPost = {
      client_notes: formState.notes ? formState.notes : null
    };
    await providerForPut(
      getWorkoutDayInfo,
      formState.scheduleId,
      dataToPost,
      auth.getToken()
    )
      .then(res => {
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          isOpenSnackBar: true,
          isError: "success",
          successErrorMessage: "Exercise notes saved successfully!"
        }));
        setLoader(false);
        handleDialogCancel();
      })
      .catch(error => {
        setLoader(false);
        handleDialogCancel();
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          isOpenSnackBar: true,
          isError: "error",
          successErrorMessage: ERROR_MESSAGE + error
        }));
      });
  };

  const handleBackArrow = () => {
    history.push(CLIENTMYSCHEDULE);
  };

  const handleSnackbarClose = () => {
    setStatusVariable(statusVariable => ({
      ...statusVariable,
      isOpenSnackBar: false,
      isError: "",
      successErrorMessage: ""
    }));
  };

  console.log(exerciseData);

  return (
    <div>
      <IconButton className={styles.backIcon} onClick={() => handleBackArrow()}>
        <ChevronLeftIcon
          style={{
            padding: "5px",
            border: "solid #DADADA 1px",
            borderRadius: "25px",
            margin: "0px 1.875rem 0px 10px",
            background: "#fff",
            marginTop: 0
          }}
        />
      </IconButton>
      <HeadingOne className={localClasses.titleText}>
        <img
          width="auto"
          height="1.875rem"
          src={ExerciseIcon}
          alt={"ExerciseIcon"}
        />
        <span style={{ position: "absolute", marginLeft: "10px" }}>
          EXERCISES
        </span>
      </HeadingOne>

      {scheduleDateNotPresent === true ? (
        <Grid>
          <img
            src={noSchedule}
            className={localClasses.centerImage}
            alt="No schedule/data"
          />
        </Grid>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <AutoCompleteDropDown
                options={dateList}
                getOptionLabel={option => option.schedule_start_date}
                id="schedule_date"
                value={
                  dateList[
                    dateList.findIndex(function (item, i) {
                      return item.id === formState.values["scheduleDate"];
                    })
                  ] || null
                }
                onChange={(event, value) => {
                  handleChangeAutoComplete("scheduleDate", event, value);
                }}
                renderInput={params => (
                  <Input {...params} id="schedule_date" label="Schedule Date" />
                )}
              />
            </Grid>
          </Grid>
          {exerciseData.length ? (
            <div className={localClasses.cancelSaveButton}>
              <IconButton
                variant="contained"
                size="small"
                onClick={() => generateReport(false)}
              >
                <Tooltip title="Download Report">
                  <GetAppIcon style={{ color: "#1C4979", fontSize: 30 }} />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() => generateReport(true)}
                style={{ marginRight: 15, padding: 0, background: "none" }}
              >
                <Tooltip title="Print Report">
                  <PrintIcon style={{ color: "#1C4979", fontSize: 30 }} />
                </Tooltip>
              </IconButton>
            </div>
          ) : null}

          <DialogBox
            skipCancel={false}
            skipOk={false}
            open={popUpOpen}
            title={"Add/Edit Exercise Notes"}
            className={localClasses.dialogBoxDesign}
            children={
              <div>
                <p style={{ marginTop: "1.25rem" }}>
                  Schedule date :
                  {formState.values.scheduleObj
                    ? formState.values.scheduleObj.schedule_start_date
                    : null}
                </p>
                <Grid
                  container
                  spacing={1}
                  className={localClasses.notesSection}
                >
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      id="notes"
                      name="notes"
                      onChange={handleTextChange}
                      value={formState.notes}
                      rows={15}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </div>
            }
            handleOk={handleDialogOk}
            handleCancel={handleDialogCancel}
            buttonCancel={"Cancel"}
            buttonOk={"SAVE"}
          />
          <Grid
            item
            className={localClasses.workoutForDayStyles}
            xs={12}
            md={12}
            lg={12}
          >
            <Grid item md={2} xs={12}>
              <SelectDropdown
                title="   Workout for day"
                value={exerciseForm.workoutDay}
                onChange={handleChange}
              >
                {workoutDayList.map(day => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </SelectDropdown>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            className={localClasses.workoutDayGrid}
          >
            {exerciseForm.exerciseList[1].exerciseId ? (
              <ExerciseGrid
                viewOnly={true}
                isDesktop={isDesktop}
                exerciseData={exerciseData}
                exerciseList={exerciseForm.exerciseList}
                checkIfDataProper={checkIfDataProper}
                muscleGroupId={exerciseForm.muscleGroupId}
                muscleGroups={muscleGroups}
              />
            ) : (
              <Grid container>
                <Grid container item xs={12}>
                  <HeadingOne
                    style={{
                      marginInline: "auto",
                      marginTop: "80px",
                      color: "#000000"
                    }}
                  >
                    Off Day
                  </HeadingOne>
                </Grid>
              </Grid>
            )}
            <Grid>
              <SnackBar
                open={statusVariable.isOpenSnackBar}
                severity={statusVariable.isError}
                onClose={handleSnackbarClose}
              >
                {statusVariable.successErrorMessage}
              </SnackBar>
            </Grid>
            <Backdrop className={localClasses.backdrop} open={loader}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Grid>
        </>
      )}
    </div>
  );
};

ClientExercise.propTypes = {};

ClientExercise.defaultProps = {};

export default ClientExercise;
