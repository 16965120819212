import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getFormattedTime } from "../../utils";

const Timer = props => {
  const [text, setText] = useState("");

  useEffect(() => {
    if (props.savedTime) {
      setText(getFormattedTime(props.savedTime));
    }
  }, [props.savedTime]);

  return (
    <Grid>
      {props.isSaving ? <CircularProgress size={20} /> : null}
      <Typography variant="caption" display="block" gutterBottom>
        {props.isSaving
          ? "Saving..."
          : text && text !== ""
          ? "Last updated at " + text + "..."
          : ""}
      </Typography>
    </Grid>
  );
};

export default Timer;
