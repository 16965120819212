import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  IconButton,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import {
  Table,
  AutoCompleteDropDown,
  Input,
  InlineDatePicker,
  DarkBlueButton,
  BorderLessButton,
  Auth,
  DialogBox
} from "../../components";
import {
  auditlogs,
  getRole,
  verifyPassForLogs
} from "../../constants/urlConstants";
import useStyles from "../../utils/Styles/ManagePageStyles";
import { providerForGet, providerForPost } from "../../api";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const AuditLogs = () => {
  const auth = Auth.getToken();
  const tableRef = React.createRef();
  const classes = useStyles();
  const password = "password";
  const [loader, setLoader] = useState(false);
  const [openVerifyDialogBox, setOpenVerifyDialogBox] = useState(false);
  const [passNotPresent, setPassNotPresent] = React.useState(false);
  const [filter, setFilter] = useState({
    _sort: "id:desc"
  });
  const [enabledId, setEnabledId] = useState(null);
  const [enabledActionTypeId, setEnabledActionTypeId] = useState(null);
  const [formState, setFormState] = useState({
    auditLogList: [],
    roleList: [],
    isLoad: false,
    viewAccessorEmail: false,
    viewAccesseEmail: false,
    viewActionType: false,
    rowData: {},
    enableAccessorEmail: false,
    enableAccesseEmail: false,
    values: {},
    errors: {},
    showPassword: false,
    inValidPass: false
  });
  const hasError = field => (formState.errors[field] ? true : false);

  const [dateFilter, setDateFilter] = useState({
    fromDate: null,
    toDate: "",
    dateFromError: false,
    dateToError: false,
    dateFromErrorText: "",
    dateToErrorText: "",
    gte_key: "",
    lte_key: ""
  });

  const onDisableAccessorEmail = async () => {
    setEnabledId(null);
  };

  const onClickAccessorEmail = async (rowData, viewingAccessorEmail) => {
    setFormState(formState => ({
      ...formState,
      viewAccessorEmail: false,
      viewAccesseEmail: false,
      enableAccessorEmail: false,
      enableAccesseEmail: false,
      showPassword: false
    }));
    setEnabledId(rowData.id);
    setOpenVerifyDialogBox(true);
    if (viewingAccessorEmail) {
      setFormState(formState => ({ ...formState, viewAccessorEmail: true }));
    } else {
      setFormState(formState => ({ ...formState, viewAccesseEmail: true }));
    }
  };

  const getListData = async () => {
    let body = {};
    await providerForGet(getRole, body, auth)
      .then(res => {
        var filteredRoles = res.data.roles.filter(function (item) {
          return item.name !== "Authenticated" && item.name !== "Public";
        });
        setFormState(formState => ({
          ...formState,
          roleList: filteredRoles
        }));
      })
      .catch(err => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getListData();
  }, []);

  useEffect(() => {
    let dateFromError = false;
    let dateFromErrorText = "";
    let dateToError = false;
    let dateToErrorText = "";
    if (
      dateFilter.toDate &&
      dateFilter.toDate !== "" &&
      dateFilter.fromDate &&
      dateFilter.fromDate !== "" &&
      moment(dateFilter.fromDate).isAfter(moment(dateFilter.toDate))
    ) {
      dateFromError = true;
      dateFromErrorText = ["From date cannot be greater than to date"];
      setDateFilter(dateFilter => ({
        ...dateFilter,

        dateFromError: dateFromError,
        dateFromErrorText: dateFromErrorText
      }));
    } else if (
      dateFilter.fromDate &&
      dateFilter.fromDate !== "" &&
      dateFilter.toDate &&
      dateFilter.toDate !== "" &&
      moment(dateFilter.toDate).isBefore(moment(dateFilter.fromDate))
    ) {
      dateToError = true;
      dateToErrorText = ["To date cannot be less than from date"];
      setDateFilter(dateFilter => ({
        ...dateFilter,

        dateToError: dateToError,
        dateToErrorText: dateToErrorText
      }));
    } else {
      setDateFilter(dateFilter => ({
        ...dateFilter,

        dateToError: false,
        dateToErrorText: "",
        dateFromError: false,
        dateFromErrorText: ""
      }));
    }
  }, [dateFilter.fromDate, dateFilter.toDate]);

  const onClickActionType = async (rowData, viewingActionType) => {
    setEnabledActionTypeId(rowData.id);
    if (viewingActionType) {
      setFormState(formState => ({ ...formState, viewActionType: true }));
    } else {
      setFormState(formState => ({ ...formState, viewActionType: false }));
    }
  };

  const columns = [
    { title: " Record Id", field: "id" },
    { title: "Audit Date", field: "eventDateTime" },
    { title: "IP Address", field: "ip_address" },
    { title: "Role Name", field: "role_name" },
    { title: "Accessor Name", field: "accessor_name" },
    {
      title: "Accessor Email Id",
      field: "accessor_email",
      render: rowData => {
        return (
          <>
            {enabledId &&
            enabledId === rowData.id &&
            formState.enableAccessorEmail ? (
              <>
                {rowData.accessor_email}
                <VisibilityOffIcon
                  style={{ textAlign: "center", color: "gray" }}
                  iconProps={classes.icon}
                  onClick={event => {
                    onDisableAccessorEmail();
                  }}
                />
              </>
            ) : (
              <VisibilityIcon
                style={{ textAlign: "center", color: "#1C4979" }}
                onClick={event => {
                  onClickAccessorEmail(rowData, true);
                }}
              />
            )}
          </>
        );
      },
      width: "100%"
    },
    {
      title: "Action Type",
      field: "action_type",
      render: rowData => {
        return (
          <>
            {enabledActionTypeId &&
            enabledActionTypeId === rowData.id &&
            formState.viewActionType ? (
              <>
                {rowData.action_type}
                <ExpandLessIcon
                  style={{
                    textAlign: "center",
                    color: "#1C4979",
                    position: "relative",
                    top: "8px"
                  }}
                  onClick={event => {
                    onClickActionType(rowData, false);
                  }}
                />
              </>
            ) : (
              <>
                {rowData.action_type && rowData.action_type.length > 35
                  ? rowData.action_type.substring(0, 20) + "....."
                  : rowData.action_type.substring(
                      0,
                      rowData.action_type.length - 4
                    ) + "....."}
                <ExpandMoreIcon
                  style={{ textAlign: "center", color: "gray" }}
                  iconProps={classes.icon}
                  onClick={event => {
                    onClickActionType(rowData, true);
                  }}
                />
              </>
            )}
          </>
        );
      },

      width: "100%"
    },
    { title: "Accessee Name", field: "accessee_name" },
    {
      title: "Accessee Email Id",
      field: "accessee_email",
      render: rowData => {
        return (
          <>
            {enabledId &&
            enabledId === rowData.id &&
            formState.enableAccesseEmail ? (
              <>
                {rowData.accessee_email}
                <VisibilityOffIcon
                  style={{ textAlign: "center", color: "gray" }}
                  iconProps={classes.icon}
                  onClick={event => {
                    onDisableAccessorEmail();
                  }}
                />
              </>
            ) : (
              <VisibilityIcon
                iconProps={classes.icon}
                style={{ textAlign: "center", color: "#1C4979" }}
                onClick={event => {
                  onClickAccessorEmail(rowData, false);
                }}
              />
            )}
          </>
        );
      },
      width: "100%"
    }
  ];

  const handleClickShowPassword = () => {
    setFormState({
      ...formState,
      showPassword: !formState.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const closeDialogBox = () => {
    setOpenVerifyDialogBox(false);
    delete formState.values[password];
    setFormState(formState => ({
      ...formState,
      inValidPass: false
    }));
    setPassNotPresent(false);
  };

  const onClickVerify = async () => {
    let isValid = false;
    setFormState(formState => ({
      ...formState,
      inValidPass: false
    }));

    if (formState.values.password && formState.values.password.length > 0) {
      isValid = true;
    } else {
      setPassNotPresent(true);
    }
    if (isValid) {
      setLoader(true);
      let payLoad = {
        password: formState.values.password
      };
      providerForPost(verifyPassForLogs, payLoad, Auth.getToken(), {
        desc: "Verify password for viewing accessor email and accesse email"
      })
        .then(res => {
          setFormState(formState => ({
            ...formState,
            enableAccessorEmail: formState.viewAccessorEmail ? true : false,
            enableAccesseEmail: formState.viewAccesseEmail ? true : false
          }));
          delete formState.values[password];
          setOpenVerifyDialogBox(false);
          setLoader(false);
        })
        .catch(err => {
          setEnabledId(null);
          setFormState(formState => ({
            ...formState,
            inValidPass: true
          }));
          setLoader(false);
        });
    }
    tableRef.current.onQueryChange();
  };

  const handleChange = e => {
    setPassNotPresent(false);
    setFormState(formState => ({
      ...formState,
      inValidPass: false,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value
      }
    }));
    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const handleStartDateChange = event => {
    let startDate = moment(event).format("YYYY-MM-DDT00:00:00.000Z");
    let fromDate = null;
    let gte_key = "eventDateTime_gte";

    if (startDate === "Invalid date") {
      delete filter[dateFilter.gte_key];
      setFilter(filter => ({
        ...filter
      }));
    } else {
      fromDate = new Date(startDate).toISOString();
      setDateFilter(dateFilter => ({
        ...dateFilter,
        fromDate: fromDate,

        gte_key: gte_key
      }));
      setFilter(filter => ({
        ...filter,
        [gte_key]: fromDate
      }));
    }
  };

  const handleEndDateChange = event => {
    let endDate = moment(event).endOf("day").format("YYYY-MM-DDT23:59:59.999Z");
    let toDate = "";

    let lte_key = "eventDateTime_lte";
    if (endDate === "Invalid date") {
      delete filter[dateFilter.lte_key];
      setFilter(filter => ({
        ...filter
      }));
    } else {
      toDate = new Date(endDate).toISOString();
      setDateFilter(dateFilter => ({
        ...dateFilter,
        toDate: toDate,
        lte_key: lte_key
      }));
      setFilter(filter => ({
        ...filter,
        [lte_key]: toDate
      }));
    }
  };

  const handleFilterChange = event => {
    if (event.target.value !== "") {
      setFilter(filter => ({
        ...filter,
        [event.target.name]: event.target.value
      }));
    } else {
      delete filter[event.target.name];
      setFilter(filter => ({
        ...filter
      }));
    }
  };

  const handleFilterAutoCompleteChange = (eventName, event, value) => {
    if (value !== null) {
      setFilter(filter => ({
        ...filter,
        [eventName]: value.name
      }));
    } else {
      delete filter[eventName];
      setFilter(filter => ({
        ...filter
      }));
    }
  };

  const getDateAndTime = utcDate => {
    if (utcDate === "" || utcDate === null) {
      return null;
    } else {
      var date = new Date(utcDate);
      var dd = date.getDate();
      var mm = date.getMonth();
      var yy = date.getFullYear();
      var hh = date.getHours();
      var min = date.getMinutes();
      return mm + 1 + "/" + dd + "/" + yy + " " + hh + ":" + min;
    }
  };

  const convertTableData = res => {
    let arr = [];
    if (res && res.length > 0) {
      for (let i in res) {
        let tempArr = {};
        tempArr["id"] = res[i].id;
        tempArr["ip_address"] = res[i].ip_address;
        tempArr["accessor_user_id"] = res[i].accessor_user_id;
        tempArr["accessor_name"] = res[i].accessor_name;
        tempArr["accessor_email"] = res[i].accessor_email;
        tempArr["action_type"] = res[i].action_type;
        tempArr["accessee_user_id"] = res[i].accessee_user_id;
        tempArr["accessee_name"] = res[i].accessee_name;
        tempArr["accessee_email"] = res[i].accessee_email;
        tempArr["api_route"] = res[i].api_route;
        tempArr["api_method"] = res[i].api_method;
        tempArr["api_params"] = res[i].api_params;
        tempArr["api_request_params"] = res[i].api_request_params;
        tempArr["api_status_code"] = res[i].api_status_code;
        tempArr["eventDateTime"] = getDateAndTime(res[i].eventDateTime);
        tempArr["role_name"] = res[i].role_name ? res[i].role_name : "";
        arr.push(tempArr);
      }
      return arr;
    } else {
      return [];
    }
  };

  const getAuditlogData = async (page, pageSize) => {
    /**
     ** It sets the page and pageSize in filter variable
     ** so as to keep the record of what page and pageSize was set
     */

    let params = { page: page, pageSize: pageSize };
    Object.keys(filter).map(res => {
      if (!params.hasOwnProperty(res)) {
        params[res] = filter[res];
      }
      return null;
    });

    params.desc = "Get auditlog data";
    return new Promise((resolve, reject) => {
      fetch(auditlogs + "?" + new URLSearchParams(params), {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth
        }
      })
        .then(response => response.json())
        .then(result => {
          resolve({
            data: convertTableData(result.data),
            page: result.page - 1,
            totalCount: result.totalCount
          });

          setFormState(formState => ({
            ...formState,
            auditLogList: result.data
          }));
        });
    });
  };

  const orderFunc = (columnId, direction) => {
    let orderByColumn;
    let orderBy = "";
    if (columnId >= 0) {
      orderByColumn = columns[columnId]["field"];
    }
    orderBy = orderByColumn + ":" + direction;
    setFilter(filter => ({
      ...filter,
      _sort: orderBy
    }));
    tableRef.current.onQueryChange();
  };

  const handleReset = event => {
    delete filter["accessor_name_contains"];
    delete filter["accessor_user_id_contains"];
    delete filter["ip_address_contains"];
    delete filter["role_name"];
    delete filter["accessee_name_contains"];
    delete filter["accessee_user_id_contains"];
    delete filter["accessee_email_contains"];

    setDateFilter(dateFilter => ({
      ...dateFilter,
      fromDate: null,
      toDate: null,
      dateFromError: false,
      dateToError: false,
      dateFromErrorText: "",
      dateToErrorText: "",
      gte_key: "",
      lte_key: ""
    }));
    if (filter.hasOwnProperty(dateFilter.gte_key)) {
      delete filter[dateFilter.gte_key];
    }
    if (filter.hasOwnProperty(dateFilter.lte_key)) {
      delete filter[dateFilter.lte_key];
    }
    setFilter(filter => ({
      ...filter
    }));
    getAuditlogData(1, filter.pageSize);
    tableRef.current.onQueryChange();
  };

  return (
    <div data-testid="AuditLogs">
      <Typography variant="h4" className={classes.titleText}>
        Manage Audit logs
      </Typography>
      <Card className={classes.paperFilter}>
        <CardContent className={classes.Cardtheming}>
          <Grid className={classes.filterOptions} container spacing={2}>
            <Grid item>
              <Input
                label={"IP Address"}
                placeholder="IP Address"
                name="ip_address_contains"
                value={
                  filter.ip_address_contains ? filter.ip_address_contains : ""
                }
                variant="outlined"
                onChange={handleFilterChange}
              />
            </Grid>

            <Grid item>
              <AutoCompleteDropDown
                options={formState.roleList}
                getOptionLabel={option => option.name}
                id={"role"}
                value={
                  formState.roleList[
                    formState.roleList.findIndex(function (item, i) {
                      return item.name === filter.role_name;
                    })
                  ] || null
                }
                onChange={(event, value) => {
                  handleFilterAutoCompleteChange("role_name", event, value);
                }}
                renderInput={params => (
                  <Input
                    variant="outlined"
                    {...params}
                    name={"role_name"}
                    style={{ "min-width": "223px" }}
                    id={"role"}
                    label={"Role"}
                  />
                )}
              />
            </Grid>

            <Grid item>
              <Input
                label={"Accessor User Id"}
                placeholder="Accessor User Id"
                name="accessor_user_id_contains"
                value={
                  filter.accessor_user_id_contains
                    ? filter.accessor_user_id_contains
                    : ""
                }
                variant="outlined"
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid item>
              <Input
                label={"Accessor Name"}
                placeholder="Accessor Name"
                name="accessor_name_contains"
                value={
                  filter.accessor_name_contains
                    ? filter.accessor_name_contains
                    : ""
                }
                variant="outlined"
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid item></Grid>
            <Grid item>
              <Input
                label={"Accessee User Id"}
                placeholder="Accessee User Id"
                name="accessee_user_id_contains"
                value={
                  filter.accessee_user_id_contains
                    ? filter.accessee_user_id_contains
                    : ""
                }
                variant="outlined"
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid item>
              <Input
                label={"Accessee Name"}
                placeholder="Accessee Name"
                name="accessee_name_contains"
                value={
                  filter.accessee_name_contains
                    ? filter.accessee_name_contains
                    : ""
                }
                variant="outlined"
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid item>
              <InlineDatePicker
                InputLabelProps={{ shrink: true }}
                id="startDate"
                label="From Date"
                placeholder="MM/DD/YYYY"
                value={dateFilter.fromDate || null}
                name={"From Date"}
                onChange={event => handleStartDateChange(event)}
              />
            </Grid>
            <Grid item>
              <InlineDatePicker
                InputLabelProps={{ shrink: true }}
                id="endDate"
                label="To Date"
                placeholder="MM/DD/YYYY"
                value={dateFilter.toDate || null}
                name={"To Date"}
                onChange={event => handleEndDateChange(event)}
              />
            </Grid>
            {dateFilter.dateFromError ? (
              <Grid item>
                <FormHelperText className={classes.dateErrorText}>
                  {dateFilter.dateFromErrorText}
                </FormHelperText>
              </Grid>
            ) : null}
            {dateFilter.dateToError ? (
              <Grid item>
                <FormHelperText className={classes.dateErrorText}>
                  {dateFilter.dateToErrorText}
                </FormHelperText>
              </Grid>
            ) : null}
            <Grid item>
              <DarkBlueButton
                disableElevation
                onClick={() => {
                  setFormState(formState => ({
                    ...formState,
                    isLoad: true
                  }));
                  tableRef.current.onQueryChange();
                }}
              >
                search
              </DarkBlueButton>
            </Grid>
            <Grid item>
              <BorderLessButton onClick={handleReset} disableElevation>
                reset{" "}
              </BorderLessButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Table
        tableRef={tableRef}
        columns={columns}
        tableLayout="auto"
        data={async query => {
          let params = { page: query.page + 1, pageSize: query.pageSize };
          Object.keys(filter).map(res => {
            if (!params.hasOwnProperty(res)) {
              params[res] = filter[res];
            }
            return null;
          });
          params.desc = "Get Audit log data";
          return new Promise((resolve, reject) => {
            fetch(auditlogs + "?" + new URLSearchParams(params), {
              method: "GET",
              headers: {
                "content-type": "application/json",
                Authorization: "Bearer " + auth
              }
            })
              .then(response => response.json())
              .then(result => {
                resolve({
                  data: convertTableData(result.data),
                  page: result.page - 1,
                  totalCount: result.totalCount
                });
              });
          });
        }}
        actions={[]}
        options={{
          pageSize: 25,
          actionsColumnIndex: -1,
          search: false,
          sorting: true,
          thirdSortClick: false,
          pageSizeOptions: [25, 50, 100]
        }}
        onOrderChange={(orderedColumnId, orderDirection) => {
          orderFunc(orderedColumnId, orderDirection);
        }}
      />

      <DialogBox
        open={openVerifyDialogBox}
        title="Enter password"
        buttonCancel="Cancel"
        handleCancel={closeDialogBox}
        buttonOk="Enter"
        handleOk={onClickVerify}
      >
        <Grid container spacing={2}>
          <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid item md={12} xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginTop: "10px" }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                fullWidth
                error={
                  hasError(password) || formState.inValidPass || passNotPresent
                }
              >
                Password
              </InputLabel>
              <OutlinedInput
                label="Password"
                name={password}
                type={formState.showPassword ? "text" : "password"}
                value={formState.values[password] || ""}
                onChange={handleChange}
                fullWidth
                autocomplete="off"
                inputProps={{
                  autoComplete: "new-password"
                }}
                error={
                  hasError(password) || formState.inValidPass || passNotPresent
                }
                endAdornment={
                  <InputAdornment
                    position="end"
                    error={
                      hasError(password) ||
                      formState.inValidPass ||
                      passNotPresent
                    }
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {formState.showPassword ? (
                        <Visibility style={{ color: "#1C4979" }} />
                      ) : (
                        <VisibilityOff style={{ color: "gray" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              ></OutlinedInput>
              <FormHelperText
                error={
                  hasError(password) || formState.inValidPass || passNotPresent
                }
                style={{ fontSize: "larger" }}
              >
                {hasError(password)
                  ? formState.errors[password].map(error => {
                      return error + " ";
                    })
                  : formState.inValidPass
                  ? "Invalid Password"
                  : passNotPresent
                  ? "Please enter the password"
                  : null}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </DialogBox>
    </div>
  );
};

export default AuditLogs;
