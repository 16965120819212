// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FoodCategory_addButton__3xPZH {\n  margin-left: auto !important;\n}\n.FoodCategory_titleText__29PsZ {\n  color: #110f48;\n  font-size: 1.25rem !important;\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 500 !important;\n  margin-bottom: 15px !important;\n  padding: 8px !important;\n}\n", "",{"version":3,"sources":["webpack://src/containers/Food/FoodCategory/FoodCategory.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;AACA;EACE,cAAc;EACd,6BAA6B;EAC7B,8CAA8C;EAC9C,2BAA2B;EAC3B,8BAA8B;EAC9B,uBAAuB;AACzB","sourcesContent":[".addButton {\n  margin-left: auto !important;\n}\n.titleText {\n  color: #110f48;\n  font-size: 1.25rem !important;\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 500 !important;\n  margin-bottom: 15px !important;\n  padding: 8px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButton": "FoodCategory_addButton__3xPZH",
	"titleText": "FoodCategory_titleText__29PsZ"
};
export default ___CSS_LOADER_EXPORT___;
