import React from "../../node_modules/react";
import { Redirect, Route } from "../../node_modules/react-router-dom";
import PropTypes from "../../node_modules/prop-types";
import { Auth, NotFoundPage } from "../components";
import { CLIENTDASHBAORD, FRANCHISE, MANAGECLIENTS, USERS } from "../paths";
import { LogIn } from "../containers";

const DefaultRoute = props => {
  const auth = Auth.getToken();
  const userInfo = Auth.getUserInfo();
  if (auth !== null) {
    if (userInfo.role.name === process.env.REACT_APP_ADMIN_ROLE_NAME) {
      return (
        <Redirect
          to={{
            pathname: USERS,
            state: { from: props.location }
          }}
        />
      );
    }

    if (userInfo.role.name === process.env.REACT_APP_NUTRITIONIST_ROLE) {
      return (
        <Redirect
          to={{
            pathname: MANAGECLIENTS,
            state: { from: props.location }
          }}
        />
      );
    }
    if (userInfo.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME) {
      return (
        <Redirect
          to={{
            pathname: FRANCHISE,
            state: { from: props.location }
          }}
        />
      );
    }
    if (userInfo.role.name === process.env.REACT_APP_CLIENT_ROLE_NAME) {
      return (
        <Redirect
          to={{
            pathname: CLIENTDASHBAORD,
            state: { from: props.location }
          }}
        />
      );
    } else {
      return <NotFoundPage />;
    }
  } else {
    return (
      <>
        <Route
          render={otherProps => (
            <>
              <LogIn {...otherProps} />
            </>
          )}
        />
      </>
    );
  }
};

DefaultRoute.propTypes = {
  path: PropTypes.string
};

export default DefaultRoute;
