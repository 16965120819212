import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";

export default function InlineDatePicker(props) {
  const useStyles = makeStyles(theme => ({
    inputRoot: {
      "& .MuiOutlinedInput-input": {
        padding: "0.875rem !important"
      },
      "& .MuiOutlinedInput-root": {
        margin: "8px 0px"
      },
      "& .MuiInputBase-root": {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "1rem",
        color: "#110F48"
      }
    }
  }));
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.inputRoot}>
        <KeyboardDatePicker
          autoOk
          // disableToolbar
          label="With keyboard"
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          id={props.id}
          color={props.color}
          value={props.value}
          error={props.error}
          maxDate={props.maxDate}
          disabled={props.disabled}
          placeholder={props.placeholder}
          helperText={props.helperText}
          onChange={props.onChange}
          clearable
          defaultValue={null}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
          {...props}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
}
