import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  makeStyles
} from "@material-ui/core";
import React, { useEffect } from "react";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing(3)
  }
}));

const DuplicateMealDialogContent = props => {
  const classes = useStyles();
  const [state, setState] = React.useState(props.daysMap);

  useEffect(() => {
    setState(props.daysMap);
  }, [props.daysMap]);

  return (
    <div>
      <FormControl
        error={props.error}
        component="fieldset"
        className={classes.formControl}
      >
        <FormLabel component="legend">
          {"Pick atleast one exchange day to duplicate from Exchange day " +
            props.exchangeDay}
        </FormLabel>
        <FormGroup>
          {Object.keys(state).map(s => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={state[s]}
                  onChange={e => props.handleChange(e.target, s)}
                  name={"Exchange Day " + s}
                />
              }
              label={"Exchange Day " + s}
            />
          ))}
        </FormGroup>
        {props.dupError ? (
          <FormHelperText error={props.dupError}>
            Select atleast one{" "}
          </FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
};

export default DuplicateMealDialogContent;
