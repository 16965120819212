import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Grid,
  makeStyles,
  MenuItem,
  useMediaQuery
} from "@material-ui/core";
import {
  AllMealsOfADayGrid,
  Auth,
  ShowAllMealGoalsForADay,
  DarkBlueButton,
  DuplicateMealDialogContent,
  Input,
  HeadingOne,
  SnackBar,
  SelectDropdown
} from "../../components";
import { useTheme } from "@material-ui/core/styles";
import "./Exchange.module.css";
import { providerForGet, providerForPost, providerForPut } from "../../api";
import auth from "../../components/Auth";
import {
  foodCategory,
  getAllMealInfoForTemplate,
  saveAllMealsGoalsForTemplate,
  replicateMealTemplate,
  getMealTemplate
} from "../../constants";
import { ERROR_MESSAGE, SAVE_MESSAGE } from "../../constants/genericConstants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DialogBox from "../../components/DialogBox/DialogBox";
import { Prompt } from "react-router-dom";
import { CREATEMEALTEMPLATE } from "../../paths";

const ExchangeTemplate = props => {
  const urlParams = new URLSearchParams(window.location.search);
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    summaryButtonBox: {
      display: "flex",
      marginBottom: "3%"
    },
    loadTemplate: {
      marginLeft: "auto"
    },
    duplicateReplicateStyle: {
      justifyContent: "flex-end",
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      marginRight: 30,
      [theme.breakpoints.up("md")]: {
        marginRight: 0
      },
      [theme.breakpoints.up("sm")]: {
        marginRight: 0
      },
      [theme.breakpoints.up("xs")]: {
        marginRight: 0
      }
    },
    margin: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    formControl: {
      margin: "10px 0px",
      minWidth: 270,
      flexDirection: "row"
    },
    mealForDayGrid: {
      display: "flex",
      flexWrap: "wrap"
    },
    saveButton: {
      display: "flex",
      justifyContent: "flex-end"
    },
    selectBox: {
      height: 30,
      weight: 10,
      borderRadius: 10,
      marginTop: theme.spacing(1)
    },
    selectBoxLabel: {
      marginTop: 11,
      marginRight: theme.spacing(1),
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.15px",
      color: "#000000"
    }
  }));

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  /**
   * * Create an array with 10 elements [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
   */
  const [defaultMeals, setDefaultMeals] = useState(
    Array.from({ length: parseInt(props.no_of_meals_per_day) }, (_, i) => i + 1)
  );
  let propData = props["location"];
  const [exchangeDayList] = useState(
    Array.from({ length: 7 }, (_, i) => i + 1)
  );
  const [statusVariable, setStatusVariable] = useState({
    isError: "",
    isOpenSnackBar: false,
    successErrorMessage: "",
    saveText: "",
    dummyTime: new Date(),
    savedTime: null,
    isSaving: false,
    severity: ""
  });
  const [foodItem] = useState(["bread", "meat", "veg", "fruit", "milk", "fat"]);
  const [daysMap, setDaysMap] = useState({});
  const [dupError, setDupError] = useState(false);
  const [mealForm, setMealForm] = useState({
    exchangeDay: 1,
    mealValues: {},
    singleMealTotal: {},
    singleFoodTotal: {},
    total: 0,
    isValuesCalculated: false,
    templateId: urlParams.get("s"),
    mealPerDay: props.no_of_meals_per_day,
    editData: propData ? (propData["editData"] ? true : false) : false,
    report: "",
    template_name: "",
    templateError: "",
    isError: false,
    isTemplateNameError: false
  });

  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    children: "",
    isDuplicate: false,
    isReplicate: false,
    isDuplicateOk: false
  });

  const [avgFoodCat, setAvgFoodCat] = useState({
    bread: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    meat: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    veg: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    fruit: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    milk: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    fat: {
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    }
  });

  const [goalValues, setGoalValues] = useState({
    values: {
      bread: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      meat: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      veg: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      fruit: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      milk: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      fat: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      }
    },
    total: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    avgPerMeal: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    calContributed: {
      proContNum: 0,
      proContPercent: 0,
      carbContNum: 0,
      carbContPercent: 0,
      fatCalNum: 0,
      fatCalNumPercent: 0
    }
  });

  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(true);

  const history = useHistory();

  const localClasses = useStyles();

  const updateAllGoals = (
    singleFoodTotal = mealForm.singleFoodTotal,
    avgFood = avgFoodCat,
    meal_per_day = mealForm.mealPerDay
  ) => {
    setLoader1(true);
    let finalValues = {};
    let totalValues = {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    };

    Object.keys(goalValues.values).map(fv => {
      let totalPortion = parseFloat(singleFoodTotal[fv]);
      let avgcal = totalPortion * parseFloat(avgFood[fv].avgcal);
      let avgpro = totalPortion * parseFloat(avgFood[fv].avgpro);
      let avgfat = totalPortion * parseFloat(avgFood[fv].avgfat);
      let avgcarb = totalPortion * parseFloat(avgFood[fv].avgcarb);
      totalValues.portion = totalValues.portion + totalPortion;
      totalValues.avgcal = totalValues.avgcal + avgcal;
      totalValues.avgpro = totalValues.avgpro + avgpro;
      totalValues.avgcarb = totalValues.avgcarb + avgcarb;
      totalValues.avgfat = totalValues.avgfat + avgfat;
      finalValues[fv] = {
        portion: totalPortion ? totalPortion.toFixed(1) : "0",
        avgcal: avgcal ? avgcal.toFixed(1) : "0",
        avgpro: avgpro ? avgpro.toFixed(1) : "0",
        avgcarb: avgcarb ? avgcarb.toFixed(1) : "0",
        avgfat: avgfat ? avgfat.toFixed(1) : "0"
      };
      return null;
    });

    let calContByPro = totalValues.avgpro * 4;
    let calContByCarb = totalValues.avgcarb * 4;
    let calContByFat = totalValues.avgfat * 9;
    let calContByProPercent = (calContByPro / totalValues.avgcal) * 100;
    let calContByCarbPercent = (calContByCarb / totalValues.avgcal) * 100;
    let calContByFatPercent = (calContByFat / totalValues.avgcal) * 100;
    if (isNaN(calContByProPercent)) {
      calContByProPercent = 0;
    }
    if (isNaN(calContByCarbPercent)) {
      calContByCarbPercent = 0;
    }
    if (isNaN(calContByFatPercent)) {
      calContByFatPercent = 0;
    }

    setGoalValues(goalValues => ({
      ...goalValues,
      values: finalValues,
      total: {
        portion: totalValues.portion ? totalValues.portion.toFixed(1) : "0",
        avgcal: totalValues.avgcal ? totalValues.avgcal.toFixed(1) : "0",
        avgpro: totalValues.avgpro ? totalValues.avgpro.toFixed(1) : "0",
        avgcarb: totalValues.avgcarb ? totalValues.avgcarb.toFixed(1) : "0",
        avgfat: totalValues.avgfat ? totalValues.avgfat.toFixed(1) : "0"
      },
      avgPerMeal: {
        portion:
          totalValues.portion / meal_per_day
            ? (totalValues.portion / meal_per_day).toFixed(1)
            : "0",
        avgcal:
          totalValues.avgcal / meal_per_day
            ? (totalValues.avgcal / meal_per_day).toFixed(1)
            : "0",
        avgpro:
          totalValues.avgpro / meal_per_day
            ? (totalValues.avgpro / meal_per_day).toFixed(1)
            : "0",
        avgcarb:
          totalValues.avgcarb / meal_per_day
            ? (totalValues.avgcarb / meal_per_day).toFixed(1)
            : "0",
        avgfat:
          totalValues.avgfat / meal_per_day
            ? (totalValues.avgfat / meal_per_day).toFixed(1)
            : "0"
      },
      calContributed: {
        proContNum: calContByPro ? calContByPro.toFixed(1) : "0",
        proContPercent: calContByProPercent
          ? calContByProPercent.toFixed(1)
          : "0",
        carbContNum: calContByCarb ? calContByCarb.toFixed(1) : "0",
        carbContPercent: calContByCarbPercent
          ? calContByCarbPercent.toFixed(1)
          : "0",
        fatCalNum: calContByFat ? calContByFat.toFixed(1) : "0",
        fatCalNumPercent: calContByFatPercent
          ? calContByFatPercent.toFixed(1)
          : "0"
      }
    }));
    setLoader1(false);
  };

  /** Add Default values to meals */
  const addDefaultValuesToMeals = (
    avgFood = avgFoodCat,
    no_of_meals = defaultMeals
  ) => {
    setLoader(true);
    let data = {};
    let singleMealTotal = {};
    let defaultFoodValues = {
      bread: 0,
      meat: 0,
      veg: 0,
      fruit: 0,
      milk: 0,
      fat: 0
    };
    /*
     * * Add default values
     */
    no_of_meals.map(mealNumber => {
      let key = `meal_${mealNumber}`;
      data[key] = defaultFoodValues;
      singleMealTotal[key] = 0;
      return null;
    });

    /**
     * This will make data as mealForm data as
     * mealForm = {
     *  mealValues : {
     *                meal_1 : {
     *                            bread : 0
     *                            .. all other food values
     *                         } ,
     *                 .... all other meal values
     *                }
     *
     * }
     *
     */

    setGoalValues(goalValues => ({
      ...goalValues,
      values: {
        bread: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        },
        meat: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        },
        veg: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        },
        fruit: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        },
        milk: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        },
        fat: {
          portion: 0,
          avgcal: 0,
          avgpro: 0,
          avgcarb: 0,
          avgfat: 0
        }
      },
      total: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      avgPerMeal: {
        portion: 0,
        avgcal: 0,
        avgpro: 0,
        avgcarb: 0,
        avgfat: 0
      },
      calContributed: {
        proContNum: 0,
        proContPercent: 0,
        carbContNum: 0,
        carbContPercent: 0,
        fatCalNum: 0,
        fatCalNumPercent: 0
      }
    }));

    setMealForm(mealForm => ({
      ...mealForm,
      mealValues: data,
      singleMealTotal: singleMealTotal,
      singleFoodTotal: defaultFoodValues,
      total: 0,
      isValuesCalculated: true
    }));
    updateAllGoals(defaultFoodValues, avgFood, no_of_meals.length);
    setLoader1(false);
    setLoader(false);
  };

  /** Get initial data */
  const getAllMealInfo = async (
    ex_day = mealForm.exchangeDay,
    avgFood = avgFoodCat,
    no_of_meals = defaultMeals
  ) => {
    let templateId = urlParams.get("s");
    await providerForGet(
      getAllMealInfoForTemplate,
      { templateId: templateId, ex_day: ex_day },
      Auth.getToken()
    )
      .then(async res => {
        if (res.data.exchange_day) {
          setMealForm(mealForm => ({
            ...mealForm,
            mealValues: res.data.mealValues,
            singleMealTotal: res.data.singleMealTotal,
            singleFoodTotal: res.data.singleFoodTotal,
            total: res.data.total,
            isValuesCalculated: true,
            templateError: "",
            template_name: res.data.template_name,
            isTemplateNameError: false
          }));
          updateAllGoals(res.data.singleFoodTotal, avgFood, no_of_meals.length);
        } else {
          setMealForm(mealForm => ({
            ...mealForm,
            templateError: "",
            template_name: res.data.template_name,
            isTemplateNameError: false
          }));
          addDefaultValuesToMeals(avgFood, no_of_meals);
        }
        setLoader(false);
      })
      .catch(error => {
        addDefaultValuesToMeals(avgFood, no_of_meals);
        setLoader(false);
      });
  };

  const getNoOfMealsPerDay = async (
    ex_day = mealForm.exchangeDay,
    finalAvgFood = avgFoodCat
  ) => {
    setLoader1(true);
    await providerForGet(
      getMealTemplate + "/" + urlParams.get("s"),
      {},
      auth.getToken()
    )
      .then(res => {
        if (res.data) {
          let no_of_meals = res.data[`no_of_meals_per_day_${ex_day}`];
          let no_of_meals_arr = Array.from(
            { length: parseInt(no_of_meals) },
            (_, i) => i + 1
          );
          setMealForm(mealForm => ({
            ...mealForm,
            mealPerDay: no_of_meals
          }));
          setDefaultMeals(no_of_meals_arr);
          getAllMealInfo(ex_day, finalAvgFood, no_of_meals_arr);
        }
      })
      .catch(error => {
        setLoader1(false);
      });
  };

  const getAvgInfo = async (ex_day = mealForm.exchangeDay) => {
    setLoader(true);
    setMealForm(mealForm => ({
      ...mealForm,
      isValuesCalculated: false
    }));
    await providerForGet(foodCategory, { pageSize: -1 }, Auth.getToken())
      .then(async res => {
        let finalAvgFood = {};
        res.data.data.map(fc => {
          let name = "";
          let dataToAdd = {
            avgcal: 0,
            avgpro: 0,
            avgcarb: 0,
            avgfat: 0
          };
          if (fc.name === "Milk" || fc.name === "milk") {
            name = "milk";
          } else if (
            fc.name === "Vegetable" ||
            fc.name === "veg" ||
            fc.name === "vegetable" ||
            fc.name === "Veg"
          ) {
            name = "veg";
          } else if (fc.name === "Fruit" || fc.name === "fruit") {
            name = "fruit";
          } else if (fc.name === "Meat" || fc.name === "meat") {
            name = "meat";
          } else if (fc.name === "Fat" || fc.name === "fat") {
            name = "fat";
          } else if (fc.name === "Bread" || fc.name === "bread") {
            name = "bread";
          }
          if (name !== "") {
            dataToAdd.avgcal = fc.avgcal;
            dataToAdd.avgpro = fc.avgpro;
            dataToAdd.avgcarb = fc.avgcarb;
            dataToAdd.avgfat = fc.avgfat;
            finalAvgFood[name] = dataToAdd;
          }
          return null;
        });
        setAvgFoodCat(finalAvgFood);
        getNoOfMealsPerDay(ex_day, finalAvgFood);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    setMealForm(mealForm => ({
      ...mealForm,
      templateId: urlParams.get("s")
    }));
    getAvgInfo(mealForm.exchangeDay);
  }, []);

  /**
   * Set exchange day
   * @param {*} event
   */

  const handleSave = async (flag = false) => {
    let val1 = !mealForm.template_name || /^\s*$/.test(mealForm.template_name);
    if (val1) {
      setMealForm(mealForm => ({
        ...mealForm,
        templateError: "Favorite name is required",
        isTemplateNameError: true
      }));
    } else {
      setLoader(true);
      await providerForPost(
        saveAllMealsGoalsForTemplate,
        {
          exchangeDay: mealForm.exchangeDay,
          templateId: mealForm.templateId,
          mealValues: mealForm.mealValues,
          templateName: mealForm.template_name
        },
        Auth.getToken()
      )
        .then(res => {
          if (flag) {
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              isSaving: false,
              savedTime: new Date(),
              isOpenSnackBar: true,
              isError: "success",
              successErrorMessage: "Echange's/Meals Favorite " + SAVE_MESSAGE
            }));
          }
          setLoader(false);
        })
        .catch(error => {
          if (flag) {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              setStatusVariable(statusVariable => ({
                ...statusVariable,
                isSaving: false,
                isOpenSnackBar: true,
                isError: "error",
                successErrorMessage:
                  ERROR_MESSAGE + error.response.data.message,
                severity: "error"
              }));
            } else {
              setStatusVariable(statusVariable => ({
                ...statusVariable,
                isSaving: false,
                savedTime: null,
                isOpenSnackBar: true,
                isError: "error",
                successErrorMessage: ERROR_MESSAGE + error
              }));
            }
          }
          setLoader(false);
        });
    }
  };

  const handleChange = event => {
    setLoader(true);
    handleSave();
    setMealForm(mealForm => ({
      ...mealForm,
      exchangeDay: event.target.value
    }));
    getNoOfMealsPerDay(event.target.value);
  };

  /*
   * * Updates food values
   */
  const updateTotal = (food, mealNo, goal, target) => {
    let mealTotalKey = `meal_${mealNo}`;
    let mealTotal = 0;
    /** Calculate single meal total */
    foodItem.map(f => {
      let dataToAdd = parseFloat(mealForm.mealValues[`meal_${mealNo}`][f]);
      /** This check the value to add is the current added value or not this is done because it takes a while for the current value to update */
      if (f === food) {
        dataToAdd = goal;
      }
      mealTotal = mealTotal + dataToAdd;
      return null;
    });
    mealTotal = mealTotal ? mealTotal.toFixed(2) : "0";

    /** Calculate single food total */
    let foodTotal = 0;
    defaultMeals.map(mealNumber => {
      let key = `meal_${mealNumber}`;
      let dataToAdd = parseFloat(mealForm.mealValues[key][food]);
      /** This check the value to add is the current added value or not this is done because it takes a while for the current value to update */
      if (mealNumber === mealNo) {
        dataToAdd = goal;
      }
      foodTotal = foodTotal + dataToAdd;
      return null;
    });
    foodTotal = foodTotal ? foodTotal.toFixed(2) : "0";
    let total = 0;
    Object.keys(mealForm.singleFoodTotal).map(f => {
      let value = 0;
      if (f === food) {
        value = parseFloat(foodTotal);
      } else {
        value = mealForm.singleFoodTotal[f];
      }
      total = total + value;
      return null;
    });

    let singleFoodTotal = {
      ...mealForm.singleFoodTotal,
      [food]: parseFloat(foodTotal)
    };

    setMealForm(mealForm => ({
      ...mealForm,
      singleMealTotal: {
        ...mealForm.singleMealTotal,
        [mealTotalKey]: parseFloat(mealTotal)
      },
      singleFoodTotal: singleFoodTotal,
      total: parseFloat(total ? total.toFixed(2) : "0")
    }));

    updateAllGoals(singleFoodTotal);
  };

  const handleMealChange = (event, food, mealNo) => {
    const floatRegExp = new RegExp(
      process.env.REACT_APP_REG_EX_FOR_EMPTY_STRING
    );
    let value = event.target.value;

    if (value !== "" && floatRegExp.test(value)) {
      setMealForm(mealForm => ({
        ...mealForm,
        mealValues: {
          ...mealForm.mealValues,
          ["meal_" + mealNo]: {
            ...mealForm.mealValues["meal_" + mealNo],
            [food]: value
          }
        }
      }));
      updateTotal(food, mealNo, parseFloat(value), event.target.id);
    } else if (event.target.value === "") {
      setMealForm(mealForm => ({
        ...mealForm,
        mealValues: {
          ...mealForm.mealValues,
          ["meal_" + mealNo]: {
            ...mealForm.mealValues["meal_" + mealNo],
            [food]: 0
          }
        }
      }));
      updateTotal(food, mealNo, 0, event.target.id);
    }
  };

  const handleCreateMeals = async () => {
    await handleSave();
    history.push({
      pathname: CREATEMEALTEMPLATE,
      search: `s=${mealForm.templateId}&e_day=${mealForm.exchangeDay}`,
      isValid: true
    });
  };

  const handleContinue = async () => {
    let counter = 0;
    let days = "";
    let num = 0;
    let daysArr = [];
    Object.keys(daysMap).map(d => {
      if (!daysMap[d]) {
        counter += 1;
      } else {
        if (!num) {
          days = d;
        } else {
          days = days + ", " + d;
        }
        num = num + 1;
        daysArr.push(d);
      }
      return null;
    });
    if (counter === Object.keys(daysMap).length) {
      setDupError(true);
    } else {
      setDialog(dialog => ({
        ...dialog,
        open: true,
        children:
          "This will duplicate exchanges and meals from day 1 into days " +
          days +
          " overriding previous data, are you sure want to continue?",
        title: "Duplicate warning",
        isDuplicate: false,
        isReplicate: false,
        isDuplicateOk: true
      }));
      if (dialog.isDuplicateOk) {
        /** saving previous data  */

        let val1 =
          !mealForm.template_name || /^\s*$/.test(mealForm.template_name);
        if (val1) {
          setMealForm(mealForm => ({
            ...mealForm,
            templateError: "Favorite name is required",
            isTemplateNameError: true
          }));
        } else {
          setLoader(true);
          await providerForPost(
            saveAllMealsGoalsForTemplate,
            {
              exchangeDay: mealForm.exchangeDay,
              templateId: mealForm.templateId,
              mealValues: mealForm.mealValues,
              templateName: mealForm.template_name
            },
            Auth.getToken()
          )
            .then(async res => {
              setDialog(dialog => ({
                ...dialog,
                open: false,
                children: "",
                title: "",
                isDuplicate: false,
                isReplicate: false,
                isDuplicateOk: false
              }));
              await providerForPost(
                replicateMealTemplate,
                {
                  fromDay: mealForm.exchangeDay,
                  toDay: daysArr,
                  schedule_id: mealForm.templateId
                },
                Auth.getToken(),
                {
                  desc:
                    "Duplicate the template  meals and menus from day " +
                    mealForm.exchangeDay +
                    " into days " +
                    days
                }
              )
                .then(res => {
                  setLoader(false);
                  getAllMealInfo();
                })
                .catch(error => {
                  setLoader(false);
                });
            })
            .catch(error => {
              setStatusVariable(statusVariable => ({
                ...statusVariable,
                isSaving: false,
                savedTime: null,
                isOpenSnackBar: true,
                isError: "error",
                successErrorMessage: ERROR_MESSAGE + error
              }));

              setLoader(false);
            });
        }
      }
    }
  };

  const handleDialogOk = async () => {
    /** saving previous data  */

    let val1 = !mealForm.template_name || /^\s*$/.test(mealForm.template_name);
    if (val1) {
      setMealForm(mealForm => ({
        ...mealForm,
        templateError: "Favorite name is required",
        isTemplateNameError: true
      }));
    } else {
      setLoader(true);
      await providerForPost(
        saveAllMealsGoalsForTemplate,
        {
          exchangeDay: mealForm.exchangeDay,
          templateId: mealForm.templateId,
          mealValues: mealForm.mealValues,
          templateName: mealForm.template_name
        },
        Auth.getToken()
      )
        .then(async res => {
          let isReplicate = dialog.isReplicate;
          if (isReplicate) {
            setDialog(dialog => ({
              ...dialog,
              open: false,
              children: "",
              title: "",
              isDuplicate: false,
              isReplicate: false,
              isDuplicateOk: false
            }));

            await providerForPost(
              replicateMealTemplate,
              {
                fromDay: 1,
                toDay: [2, 3, 4, 5, 6, 7],
                schedule_id: mealForm.templateId
              },
              Auth.getToken(),
              {
                desc:
                  "Replicate the template  meals and menus from day 1 into days 2-7"
              }
            )
              .then(res => {
                setLoader(false);
                getAllMealInfo();
              })
              .catch(error => {
                setLoader(false);
              });
          }
        })
        .catch(error => {
          setLoader(false);
        });
    }
  };

  const handleDialogCancel = () => {
    setDialog(dialog => ({
      ...dialog,
      open: false,
      children: "",
      title: "",
      isReplicate: false,
      isDuplicate: false,
      isDuplicateOk: false
    }));
  };

  const handleOpenReplicate = () => {
    setDialog(dialog => ({
      ...dialog,
      isReplicate: true,
      open: true,
      children:
        "This will replicate the meals and menus from day 1 into days 2-7, overwriting any previous meals and menus. Are you sure you want to continue?",
      title: "Replicate"
    }));
  };

  const handleChangeDuplicateMeals = (event, s) => {
    if (event.checked) {
      setDupError(false);
    }
    setDaysMap(daysMap => ({
      ...daysMap,
      [s]: event.checked
    }));
  };

  const handleOpenDuplicate = () => {
    let days = [1, 2, 3, 4, 5, 6, 7];
    var index = days.indexOf(mealForm.exchangeDay);
    if (index !== -1) {
      days.splice(index, 1);
    }
    let d_Map = {};
    days.map(d => {
      d_Map = {
        ...d_Map,
        [d]: false
      };
      return null;
    });
    setDaysMap(d_Map);
    setDialog(dialog => ({
      ...dialog,
      isDuplicate: true,
      open: true,
      title: "Duplicate"
    }));
  };

  const handleChangeTemplateName = e => {
    setMealForm(mealForm => ({
      ...mealForm,
      template_name: e.target.value,
      isTemplateNameError: false,
      templateError: ""
    }));
  };

  const handleSnackbarClose = () => {
    setStatusVariable(statusVariable => ({
      ...statusVariable,
      isOpenSnackBar: false,
      isError: "",
      successErrorMessage: "",
      savedTime: null,
      isSaving: false,
      severity: ""
    }));
  };

  const handleAddNewMeal = async () => {
    setLoader(true);
    setLoader1(true);
    await handleSave();
    let ex_day = mealForm.exchangeDay;
    await providerForPut(
      getMealTemplate,
      urlParams.get("s"),
      {
        [`no_of_meals_per_day_${ex_day}`]: defaultMeals.length + 1
      },
      auth.getToken()
    )
      .then(res => {
        let no_of_meals = res.data[`no_of_meals_per_day_${ex_day}`];
        let no_of_meals_arr = Array.from(
          { length: parseInt(no_of_meals) },
          (_, i) => i + 1
        );
        setMealForm(mealForm => ({
          ...mealForm,
          mealPerDay: no_of_meals
        }));
        setDefaultMeals(no_of_meals_arr);
        getAllMealInfo(ex_day, avgFoodCat, no_of_meals_arr);
      })
      .catch(error => {
        setLoader(false);
        setLoader1(false);
      });
  };

  return (
    <>
      <Prompt
        message={(location, action) => {
          handleSave();
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {mealForm.editData ? (
            <HeadingOne>Edit Exchange's/Menus Favorite</HeadingOne>
          ) : (
            <HeadingOne>Add Exchange's/Menus Favorite</HeadingOne>
          )}
        </Grid>
      </Grid>
      <Grid container className={localClasses.root} spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <Grid item xs={12} md={12} lg={12}>
            <Input
              label={"Favorite Name"}
              name={"template_name"}
              value={mealForm.template_name}
              error={mealForm.isTemplateNameError}
              placeholder={"Favorite Name"}
              variant="outlined"
              required
              fullWidth
              onChange={handleChangeTemplateName}
              helperText={
                mealForm.isTemplateNameError ? mealForm.templateError : null
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Grid
            item
            className={localClasses.saveButton}
            xs={12}
            md={12}
            lg={12}
          >
            <DarkBlueButton
              variant="contained"
              size="small"
              style={{
                marginRight: theme.spacing(1),
                marginLeft: theme.spacing(1)
              }}
              onClick={handleCreateMeals}
            >
              CREATE MEALS
            </DarkBlueButton>
            <DarkBlueButton
              variant="contained"
              size="small"
              style={{
                marginRight: 0,
                marginLeft: theme.spacing(1)
              }}
              onClick={() => handleSave(true)}
            >
              Save
            </DarkBlueButton>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            className={localClasses.mealForDayGrid}
          >
            <SelectDropdown
              title="Meal for day"
              value={mealForm.exchangeDay}
              onChange={handleChange}
            >
              {exchangeDayList.map(day => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              ))}
            </SelectDropdown>

            <div className={localClasses.duplicateReplicateStyle}>
              <DarkBlueButton
                variant="contained"
                size="small"
                style={{
                  marginRight: 0,
                  marginLeft: theme.spacing(1)
                }}
                onClick={handleOpenDuplicate}
              >
                Duplicate
              </DarkBlueButton>
              <DarkBlueButton
                variant="contained"
                size="small"
                style={{
                  marginRight: 0,
                  marginLeft: theme.spacing(1)
                }}
                onClick={handleOpenReplicate}
              >
                Replicate
              </DarkBlueButton>
            </div>
          </Grid>
          {mealForm.isValuesCalculated && !loader1 ? (
            <AllMealsOfADayGrid
              isDesktop={isDesktop}
              defaultMeals={defaultMeals}
              mealForm={mealForm}
              handleMealChange={handleMealChange}
              loader1={loader1}
              handleAddNewMeal={handleAddNewMeal}
              totalValues={Object.keys(mealForm.mealValues).length}
            />
          ) : null}
          &nbsp;
          <SnackBar
            open={statusVariable.isOpenSnackBar}
            severity={statusVariable.isError}
            onClose={handleSnackbarClose}
          >
            {statusVariable.successErrorMessage}
          </SnackBar>
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{
              textAlign: "center",
              width: "100%",
              marginTop: "0px",
              overflow: "auto"
            }}
          >
            {loader1 ? null : (
              <ShowAllMealGoalsForADay goalValues={goalValues} />
            )}
          </Grid>
        </Grid>
        <DialogBox
          skipCancel={false}
          skipOk={false}
          open={dialog.open}
          title={dialog.title}
          children={
            dialog.isReplicate ? (
              dialog.children
            ) : dialog.isDuplicateOk ? (
              dialog.children
            ) : dialog.isDuplicate ? (
              <DuplicateMealDialogContent
                dupError={dupError}
                daysMap={daysMap}
                handleChange={handleChangeDuplicateMeals}
                exchangeDay={mealForm.exchangeDay}
              />
            ) : null
          }
          handleOk={dialog.isReplicate ? handleDialogOk : handleContinue}
          handleCancel={handleDialogCancel}
          buttonCancel={"Cancel"}
          buttonOk={
            dialog.isReplicate ? "Ok" : dialog.isDuplicateOk ? "Ok" : "Continue"
          }
        />

        <Backdrop className={localClasses.backdrop} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid>
          <SnackBar
            open={statusVariable.isOpenSnackBar}
            severity={statusVariable.isError}
            onClose={handleSnackbarClose}
          >
            {statusVariable.successErrorMessage}
          </SnackBar>
        </Grid>
      </Grid>
    </>
  );
};

export default ExchangeTemplate;
