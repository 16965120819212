// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardComponent_CardComponent__23mtf {\n  margin-top: 1.25rem;\n  flex-wrap: wrap;\n  flex: 1 1;\n  height: 100%;\n}\n.CardComponent_CardComponent__23mtf > div {\n  flex-wrap: wrap;\n  flex: 1 1;\n  height: 100%;\n}\n.CardComponent_CardComponent__23mtf button {\n  display: flex !important;\n  flex-direction: column;\n  flex-wrap: wrap;\n  flex: 1 1;\n  height: 100%;\n}\n.CardComponent_titleButton__117mc {\n  justify-content: center;\n  display: flex;\n}\n.CardComponent_contentText__3N4Sa {\n  display: flex;\n  text-align: center;\n}\n.CardComponent_mediaBox__1xKO3 {\n  margin: 1.25rem;\n  display: flex;\n  border-radius: 10px;\n  max-width: calc(100% - 1.25rem);\n  width: auto !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/CardComponent/CardComponent.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,eAAe;EACf,SAAO;EACP,YAAY;AACd;AACA;EACE,eAAe;EACf,SAAO;EACP,YAAY;AACd;AACA;EACE,wBAAwB;EACxB,sBAAsB;EACtB,eAAe;EACf,SAAO;EACP,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,+BAA+B;EAC/B,sBAAsB;AACxB","sourcesContent":[".CardComponent {\n  margin-top: 1.25rem;\n  flex-wrap: wrap;\n  flex: 1;\n  height: 100%;\n}\n.CardComponent > div {\n  flex-wrap: wrap;\n  flex: 1;\n  height: 100%;\n}\n.CardComponent button {\n  display: flex !important;\n  flex-direction: column;\n  flex-wrap: wrap;\n  flex: 1;\n  height: 100%;\n}\n.titleButton {\n  justify-content: center;\n  display: flex;\n}\n.contentText {\n  display: flex;\n  text-align: center;\n}\n.mediaBox {\n  margin: 1.25rem;\n  display: flex;\n  border-radius: 10px;\n  max-width: calc(100% - 1.25rem);\n  width: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CardComponent": "CardComponent_CardComponent__23mtf",
	"titleButton": "CardComponent_titleButton__117mc",
	"contentText": "CardComponent_contentText__3N4Sa",
	"mediaBox": "CardComponent_mediaBox__1xKO3"
};
export default ___CSS_LOADER_EXPORT___;
