import { React, useState } from "react";
import {
  Typography,
  Backdrop,
  CircularProgress,
  FormHelperText,
  Card,
  CardContent,
  Box,
  useMediaQuery,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { commonStyles } from "../../common";
import { Input, DarkBlueButton, Auth as auth, Auth } from "../../components";
import {
  checkAllKeysPresent,
  getListOfKeysNotPresent,
  setErrors,
  checkEmpty
} from "../../utils";
import { get } from "lodash";
import changepassword from "./changepassword.json";
import { changePasswordURL } from "../../constants";
import { providerForPost } from "../../api";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useHistory } from "react-router-dom";
import { CLIENTDASHBAORD, FRANCHISE, MANAGECLIENTS, USERS } from "../../paths";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: "6%"
  },
  cardContent: {
    padding: 60,
    paddingBottom: "45px !important"
  },
  loginTextDesktop: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1.25rem",
    color: "#110F48"
  },
  loginTextMobile: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#110F48",
    fontSize: "1.25rem"
  },
  form: {
    width: "100%"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#0152CC",
    fontFamily: "Montserrat",
    fontWeight: 400,
    color: "#fff",
    fontSize: "0.875rem"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    position: "relative"
  },
  welcomeText: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000000",
    fontSize: "1rem"
  },
  inputText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1rem"
  },
  label: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.875rem"
  },
  Heading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000000",
    fontSize: "1rem"
  }
}));

const ChangePassword = props => {
  const newPassword = "newPassword";
  const oldPassword = "oldPassword";
  const confirmPassword = "confirmPassword";
  const classes = useStyles();
  const commonClass = commonStyles();
  const [isErrorWhilePassReset, setIsErrorWhilePassReset] = useState(false);
  const [passStatusMessage, setPassStatusMessage] = useState("");
  const [pwdChanged, setPwdChanged] = useState(false);
  const themes = useTheme();
  const userInfo = Auth.getUserInfo();
  const history = useHistory();

  const isDesktop = useMediaQuery(themes.breakpoints.up("lg"), {
    defaultMatches: true
  });

  /** Use state for form */
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const handleChange = ({ target }) => {
    setPwdChanged(false);
    setIsErrorWhilePassReset(false);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [target.name]: target.value
      },
      touched: {
        ...formState.touched,
        [target.name]: true
      }
    }));
    delete formState.errors[target.name];
    setIsErrorWhilePassReset(false);
    setPassStatusMessage("");
  };

  const hasError = field => {
    return formState.errors[field] ? true : false;
  };

  const onChangePass = () => {
    let payload = {
      email: auth.getUserInfo().email,
      oldpassword: formState.values.oldPassword,
      password: formState.values.newPassword,
      type: props.type
    };
    providerForPost(changePasswordURL, payload, auth.getToken(), {
      desc: `${auth.getUserInfo().full_name} changed the password`
    })
      .then(res => {
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [oldPassword]: "",
            [newPassword]: "",
            [confirmPassword]: ""
          }
        }));
        setPwdChanged(true);
        setIsErrorWhilePassReset(false);
      })
      .catch(error => {
        setPwdChanged(false);
        setIsErrorWhilePassReset(true);
        if (error.response) setPassStatusMessage(error.response.data.message);
        else
          setPassStatusMessage(
            "Could not change password.Please try again later"
          );
        console.log("Error", error);
      });
  };

  /** onValidatePass
   *  If passwords entered in valid format, API call to change user's password is done
   * @param myParam New password,confirm password fields
   */
  const onValidatePass = () => {
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      changepassword
    );
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, changepassword);
      if (checkEmpty(formState.errors)) {
        isValid = true;
        if (formState.values.newPassword !== formState.values.confirmPassword) {
          setIsErrorWhilePassReset(true);
          setPassStatusMessage("Passwords do not match");
          isValid = false;
        }
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        changepassword
      );
      formState.errors = setErrors(formState.values, changepassword);
    }
    if (isValid) {
      setFormState(formState => ({
        ...formState,
        isValid: true
      }));
      onChangePass();
    } else {
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
    }
  };

  const handleBackArrow = async () => {
    if (userInfo.role.name === process.env.REACT_APP_NUTRITIONIST_ROLE) {
      history.push({
        pathname: MANAGECLIENTS
      });
    } else if (
      userInfo.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
    ) {
      history.push({
        pathname: FRANCHISE
      });
    } else if (userInfo.role.name === process.env.REACT_APP_ADMIN_ROLE_NAME) {
      history.push({
        pathname: USERS
      });
    } else if (userInfo.role.name === process.env.REACT_APP_CLIENT_ROLE_NAME) {
      history.push({
        pathname: CLIENTDASHBAORD
      });
    }
  };

  return (
    <>
      <Container component="main" maxWidth="sm" className={classes.container}>
        <Card variant="elevation" elevation={2}>
          <CardContent className={classes.cardContent}>
            <Box>
              <Tooltip title={"Back"}>
                <IconButton
                  style={{
                    position: "relative",
                    right: "27px"
                  }}
                  onClick={() => handleBackArrow()}
                >
                  <ChevronLeftIcon
                    style={{
                      padding: "5px",
                      border: "solid #DADADA 1px",
                      borderRadius: "25px",
                      margin: "0px 30px 0px 10px",
                      background: "#fff",
                      marginTop: 0
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Typography
                className={
                  isDesktop ? classes.loginTextDesktop : classes.loginTextMobile
                }
                align="center"
              >
                Change Password
              </Typography>
            </Box>
            <Box>
              <FormHelperText
                align="center"
                error={isErrorWhilePassReset}
                style={{ "font-size": "large", "text-align": " center" }}
                id="my-helper-text"
              >
                {isErrorWhilePassReset ? passStatusMessage : null}
              </FormHelperText>
              {pwdChanged && (
                <FormHelperText
                  align="center"
                  style={{
                    color: "green",
                    "font-size": "large",
                    "text-align": " center"
                  }}
                  id="my-helper-text"
                >
                  Password changed successfully
                </FormHelperText>
              )}
              {/* Create new password */}
            </Box>
            <form className={classes.form} noValidate>
              <Input
                type="password"
                variant="outlined"
                margin="normal"
                error={hasError("oldPassword")}
                fullWidth
                autoFocus={get(changepassword[oldPassword], "autoFocus")}
                helperText={
                  hasError(oldPassword)
                    ? formState.errors[oldPassword].map(error => {
                        return error + " ";
                      })
                    : null
                }
                label="Current Password"
                name={oldPassword}
                value={formState.values[oldPassword] || ""}
                onChange={handleChange}
                InputProps={{
                  classes: {
                    root: classes.inputText,
                    focused: classes.inputText,
                    notchedOutline: classes.inputText
                  }
                }}
              />
              <Input
                type="password"
                variant="outlined"
                margin="normal"
                error={hasError("newPassword")}
                fullWidth
                autoFocus={get(changepassword[newPassword], "autoFocus")}
                helperText={
                  hasError(newPassword)
                    ? formState.errors[newPassword].map(error => {
                        return error + " ";
                      })
                    : null
                }
                label="New Password"
                name={newPassword}
                value={formState.values[newPassword] || ""}
                onChange={handleChange}
                InputProps={{
                  classes: {
                    root: classes.inputText,
                    focused: classes.inputText,
                    notchedOutline: classes.inputText
                  }
                }}
              />
              <Input
                type="password"
                variant="outlined"
                margin="normal"
                error={hasError("confirmPassword")}
                fullWidth
                autoFocus={get(changepassword[confirmPassword], "autoFocus")}
                helperText={
                  hasError(confirmPassword)
                    ? formState.errors[confirmPassword].map(error => {
                        return error + " ";
                      })
                    : null
                }
                label="Confirm Password"
                name={confirmPassword}
                value={
                  formState.values[confirmPassword]
                    ? formState.values[confirmPassword]
                    : ""
                }
                onChange={handleChange}
                InputProps={{
                  classes: {
                    root: classes.inputText,
                    focused: classes.inputText,
                    notchedOutline: classes.inputText
                  }
                }}
              />

              <div>
                <DarkBlueButton
                  type="button"
                  fullWidth
                  variant="contained"
                  onClick={onValidatePass}
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  Ok
                </DarkBlueButton>
              </div>
            </form>

            <Backdrop className={commonClass.backdrop}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default ChangePassword;
