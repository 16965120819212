import React from "react";
import { FormControl, makeStyles, Select, Typography } from "@material-ui/core";

const SelectDropdown = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    summaryButtonBox: {
      display: "flex",
      marginBottom: "3%"
    },
    loadTemplate: {
      marginLeft: "auto"
    },
    duplicateReplicateStyle: {
      justifyContent: "flex-end",
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      marginRight: 30,
      [theme.breakpoints.up("md")]: {
        marginRight: 0
      },
      [theme.breakpoints.up("sm")]: {
        marginRight: 0
      },
      [theme.breakpoints.up("xs")]: {
        marginRight: 0
      }
    },
    margin: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    formControl: {
      margin: props.formControlForNotes ? "0px" : "10px 0px",
      minWidth: props.fromMealSection ? 120 : 270,
      flexDirection: "row"
    },

    mealForDayGrid: {
      display: "flex",
      flexWrap: "wrap"
    },
    saveButton: {
      display: "flex",
      justifyContent: "flex-end"
    },
    selectBox: {
      height: 30,
      weight: 10,
      borderRadius: 10,
      marginTop: theme.spacing(1)
    },
    selectBoxLabel: {
      marginTop: 11,
      marginRight: theme.spacing(1),
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.15px",
      color: "#000000"
    }
  }));

  const localClasses = useStyles();

  return (
    <div>
      <FormControl
        variant="outlined"
        className={localClasses.formControl}
        style={props.formControlStyle}
      >
        <Typography className={localClasses.selectBoxLabel}>
          {props.title}
        </Typography>
        <Select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          value={props.value}
          onChange={props.onChange}
          displayEmpty
          variant="outlined"
          className={localClasses.selectBox}
        >
          {props.children}
        </Select>
      </FormControl>
    </div>
  );
};

SelectDropdown.propTypes = {};

SelectDropdown.defaultProps = {};

export default SelectDropdown;
