export const LOGIN = "/login";
export const USERS = "/users";
export const ADDUSERS = "/users/add-users";
export const EDITUSERS = "/users/edit-users";
export const CLIENT = "/client";
export const SCHOOLING = "/schooling";
export const ADDSCHOOLING = "/schooling/add-schooling";
export const EDITSCHOOLING = "/schooling/edit-schooling";
export const GENDER = "/gender";
export const ADDGENDER = "/gender/add-gender";
export const EDITGENDER = "/gender/edit-gender";
export const EMERGENCYCONTACTS = "/emergency-contacts";
export const ADDEMERGENCYCONTACTS =
  "/emergency-contacts/add-emergency-contacts";
export const EDITEMERGENCYCONTACTS =
  "/emergency-contacts/edit-emergency-contacts";
export const MARITALSTATUS = "/marital-status";
export const ADDMARITALSTATUS = "/marital-status/add-marital-status";
export const EDITMARITALSTATUS = "/marital-status/edit-marital-status";
export const COUNTRIES = "/country";
export const ADDCOUNTRIES = "/country/add-country";
export const EDITCOUNTRIES = "/country/edit-country";
export const USERTAG = "/user-tag";
export const ADDUSERTAG = "/user-tag/add-user-tag";
export const EDITUSERTAG = "/user-tag/edit-user-tag";
export const ADDCLIENT = "/add-client";
export const EDITCLIENT = "/edit-client";
export const NUTRITIONIST = "/nutritionist";
export const CHANGEPASSWORD = "/change-password";
export const FRANCHISE = "/franchise";
export const ADDFRANCHISE = "/franchise/add-frachise";
export const EDITFRANCHISE = "/franchise/edit-frachise";
export const FRANCHISESITES = "/franchise-sites";
export const ADDFRANCHISESITES = "/franchise-sites/add-frachise-sites";
export const EDITFRANCHISESITES = "/franchise-sites/edit-frachise-sites";
export const LOGOUT = "/logout";
export const AUDITLOGS = "/auditlogs";
export const DOCUMENTLIST = "/documentlist";
export const MANAGECLIENTS = "/manage-clients";
export const FORGOTPASSWORD = "/forgot-password";
export const NEWACCOUNT = "/set-up-password";
export const VERIFYOTP = "/forgot-password/verify-otp";
export const RESETPASSWORD = "/forgot-password/reset-password";
export const PASSWORDCHANGED = "/forgot-password/password-changed";
export const TWOFACTORAUTH = "/two-factor-auth/select";
export const VERIFYCODE = "/two-factor-auth/verify-code";
export const SUPPLEMENT = "/supplement";
export const ADDSUPPLEMENT = "/supplement/add-supplement";
export const EDITSUPPLEMENT = "/supplement/edit-supplement";
export const EXERCISE = "/exercise";
export const ADDEXERCISE = "/exercise/add-exercise";
export const EDITEXERCISE = "/exercise/edit-exercise";
export const FOOD = "/food";
export const ADDFOOD = "/food/add-food";
export const EDITFOOD = "/food/edit-food";
export const FOODCATEGORY = "/food-category";
export const ADDFOODCATEGORY = "/food-category/add-food-category";
export const EDITFOODCATEGORY = "/food-category/edit-food-category";
export const FOODSUBCATEGORY = "/food-sub-category";
export const ADDFOODSUBCATEGORY = "/food-sub-category/add-food-sub-category";
export const EDITFOODSUBCATEGORY = "/food-sub-category/edit-food-sub-category";
export const SIZEWEIGHT = "/size-weight";
export const ADDSIZEWEIGHT = "/size-weight/add-size-weight";
export const EDITSIZEWEIGHT = "/size-weight/edit-size-weight";
export const MUSCLEGROUP = "/muscle-group";
export const SCHEDULE = "/manage-schedule";
export const ADDSCHEDULE = "/add-schedule";
export const ADDMUSCLEGROUP = "/muscle-group/add-muscle-group";
export const EDITMUSCLEGROUP = "/muscle-group/edit-muscle-group";
export const AUTHENTICATOR = "/2fa-authenticator";
export const VIEWPROFILE = "/view-profile";
export const VIEWFILE = "/view-file";

export const CUSTOMCOLOR = "/custom-color";
/**
 * * Schedules
 */
export const BODYMASS = "/body-mass";
export const MEASUREMENTS = "/measurements";
export const EXCHANGEMENU = "/exchange-menu";
export const CREATEMEALS = "/create-meals";
export const SUPPLEMENTS = "/supplements";
export const WORKOUT = "/workout";
export const GIVEALL = "/resources";
/**
 * * Favourites
 */
export const SUPPLEMENTTEMPLATE = "/supplement-favourite";
export const ADDSUPPLEMENTTEMPLATE = "/add-supplement-favourite";
export const EDITSUPPLEMENTTEMPLATE = "/edit-supplement-favourite";
export const EXERCISETEMPLATE = "/exercise-favourite";
export const ADDEXERCISETEMPLATE = "/add-exercise-favourite";
export const EDITEXERCISETEMPLATE = "/edit-exercise-favourite";
export const EX_TEMPLATE = "/exchange-favourite";
export const CREATEMEALTEMPLATE = "/meals-favourite";
export const MANAGEEXCHANGEMEALTEMPLATE = "/manage-exchange-meal";

/**
 * * Client pages
 */
export const CLIENTDASHBAORD = "/client-dashboard";
export const CLIENTMYSCHEDULE = "/client-my-schedule";
export const CLIENTMYPROFILE = "/client-my-profile";
export const CLIENTMYREPORTS = "/client-my-reports";
export const CLIENTMYNOTES = "/notes";
export const ADDEDITNOTES = "/add-edit-notes";
export const CLIENTSUPPLEMENT = "/client-supplement";
export const CLIENTEXERCISE = "/client-exercise";
export const CLIENTEXCHANGE = "/client-exchange";
export const CLIENTMEASUREMENT = "/client-measurement";
export const CLIENTBODYMASS = "/client-body-mass";
export const CLIENTSHOPPINGLIST = "/client-shopping-list";
export const CLIENTMEALS = "/client-meals";
export const CLIENTBODYMASSNOTES = "/client-body-mass-notes";
export const CLIENTMEASUREMENTSNOTES = "/client-measurements-notes";
export const CLIENTEXCHANGENOTES = "/client-exchange-notes";
export const CLIENTFOODDIARY = "/food-diary";
