import {
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useWindowDimensions from "../Window";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import styles from "./SupplementGrid.module.css";
import Checkbox from "@material-ui/core/Checkbox";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import auth from "../../components/Auth";
import { AutoCompleteDropDown } from "../Form/Autocomplete";
import { Input } from "../Form/Input";
const SupplementGrid = props => {
  const { height, width } = useWindowDimensions();
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    summaryButtonBox: {
      display: "flex",
      marginBottom: "3%"
    },
    loadTemplate: {
      marginLeft: "auto"
    },
    individualItem: {
      textAlignLast: "center",
      textAlign: "center",
      width: props.isDesktop ? width / 30 : 60,
      minWidth: props.isDesktop ? width / 30 : 60
    },
    individualItemBig: {
      textAlignLast: "center",
      textAlign: "center",
      width: props.isDesktop ? width / 8 : 150,
      minWidth: props.isDesktop ? width / 8 : 150
    },
    mainHeaderStyles: {
      color: "white",
      "background-color": "#EEF1F1",
      "border-radius": "3px",
      alignContent: "center",
      marginTop: "6.5%",
      width: props.isDesktop ? "100%" : "1100px"
    },
    mainSupplementListData: {
      color: "white",
      "background-color": "#EEF1F1",
      "border-radius": "3px",
      alignContent: "center",
      marginTop: "15px",
      padding: "15px 0px",
      borderRadius: 10,
      width: props.isDesktop ? "100%" : "1100px"
    },
    foodHeaderStyles: {
      color: "white",
      "background-color": "#EEF1F1",
      "border-radius": "3px",
      height: height * 0.04,
      alignContent: "center",
      marginTop: "2%",
      width: props.isDesktop ? width / 2.5 : "640px"
    },
    mainGrid: {
      overflow: props.isDesktop ? "none" : "auto"
    },
    duplicateReplicateStyle: {
      display: "flex",
      justifyContent: "flex-end",
      marginLeft: "auto"
    },

    addExchangesForMealsGrid: {
      height: props.isDesktop ? height * 0.5 : "500px",
      overflowY: "auto",
      overflowX: props.isDesktop ? "hidden" : "auto",
      marginTop: "1%",
      marginBottom: "1%",
      width: "fit-content"
    },
    addExchangesForMealsGridMobile: {
      height: props.isDesktop ? height * 0.5 : "500px",
      marginTop: "1%",
      marginBottom: "1%"
    },
    margin: {
      margin: theme.spacing(1)
    },
    inputClass: {
      marginLeft: "auto",
      marginRight: "auto",
      position: "relative",
      height: "19px",
      "text-align": "center",
      fontSize: "small"
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    mealForDayGrid: {
      display: "flex",
      flexWrap: "wrap"
    },
    textbox: {
      width: "100%"
    },
    cardTitleStyle: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "1rem",
      color: "#8A8A97",
      padding: "1rem !important"
    },
    borderNone: {
      border: "none",
      padding: "1rem !important",
      fontFamily: "Montserrat"
    },
    backGroundColorWhenTrue: {
      backgroundColor: "black"
    },
    checkBoxStyle: {
      border: "none",
      paddingLeft: "75px"
    },
    checkBoxStyle2: {
      "& .Mui-checked": {
        color: "#110F48"
      }
    },
    exercisesBorder: {
      border: "none",
      paddingLeft: 0
    },
    trRoot: {
      overflow: "auto",
      "& .MuiTable-root": {
        borderSpacing: " 0 1.0625rem !important",
        borderCollapse: "separate  !important"
      },
      "& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover": {
        background: "#F8F8FA"
      },
      "& .MuiTableRow-root": {
        "&:hover": {
          background: "#F8F8FA !important"
        }
      },
      marginTop: "15px"
    },

    ".MuiTableCell-root": {
      padding: "1rem !important",
      fontFamily: "Montserrat"
    }
  }));
  const localClasses = useStyles();

  const [formState, setFormState] = useState({
    checked: {},
    isActive: props ? props.isActive : null
  });
  const handleChange = event => {
    if (event.target.name === "cross") {
      setFormState(formState => ({
        ...formState,
        checked: {
          ...formState.checked,
          [event.target.id]: !event.target.checked
        }
      }));
    } else {
      setFormState(formState => ({
        ...formState,
        checked: {
          ...formState.checked,
          [event.target.id]: !formState.checked[event.target.id]
        }
      }));
    }
  };

  useEffect(() => {
    if (
      auth.getUserInfo().role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
    ) {
      Object.keys(props.suppData).map(suppNo => {
        if (props.suppData[suppNo].trackedStatus === true) {
          setFormState(formState => ({
            ...formState,
            checked: {
              ...formState.checked,
              [suppNo]: true
            }
          }));
        } else {
          setFormState(formState => ({
            ...formState,
            checked: {
              ...formState.checked,
              [suppNo]: false
            }
          }));
        }
        return null;
      });
    }
  }, [props.suppData]);
  return (
    <>
      <Grid item className={localClasses.mainGrid}>
        <div className={localClasses.trRoot}>
          <Table className={styles.exerciseGrid}>
            <TableHead>
              <TableRow>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItemBig}>
                    SUPPLEMENTS
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItem}>
                    MORN
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItem}>
                    PRE
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItem}>
                    POST
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItem}>
                    EVE
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItem}>
                    TOTAL
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItemBig}>
                    SUPPLEMENT NOTES
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItemBig}>
                    PERSONAL NOTES
                  </Typography>
                </TableCell>
                {props.openAction && (
                  <TableCell className={localClasses.borderNone}>
                    <Typography className={localClasses.individualItemBig}>
                      Action
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(props.suppData).map(suppNo => (
                <TableRow>
                  <TableCell
                    className={localClasses.borderNone}
                    style={{
                      backgroundColor: props.openAction
                        ? formState.checked[suppNo]
                          ? "#FFF4F4"
                          : "#DDFAEC"
                        : ""
                    }}
                  >
                    {props.viewOnly === true ? (
                      <>
                        {props.supplementData.map(r => {
                          if (
                            props.suppData[suppNo] &&
                            props.suppData[suppNo].id &&
                            r.id === props.suppData[suppNo].id
                          ) {
                            return r.name;
                          } else {
                            return "";
                          }
                        })}
                      </>
                    ) : (
                      <AutoCompleteDropDown
                        id={"supplement_name" + suppNo}
                        name={"supplement_name" + suppNo}
                        options={props.supplementData}
                        getOptionLabel={option => option.name}
                        style={{
                          width: "100%",
                          marginTop: "auto",
                          marginBottom: "auto"
                        }}
                        disabled={formState.isActive === false}
                        onChange={(event, value) => {
                          props.handleSupplementChange(
                            true,
                            false,
                            false,
                            "Supplement_name",
                            event,
                            value,
                            suppNo
                          );
                        }}
                        value={
                          props.supplementData[
                            props.supplementData.findIndex(function (item, i) {
                              if (
                                props.suppData[suppNo] &&
                                props.suppData[suppNo].id
                              ) {
                                return item.id === props.suppData[suppNo].id;
                              } else {
                                return null;
                              }
                            })
                          ] || null
                        }
                        renderInput={params => (
                          <Input {...params} variant="outlined" />
                        )}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    className={localClasses.borderNone}
                    style={{
                      backgroundColor: props.openAction
                        ? formState.checked[suppNo]
                          ? "#FFF4F4"
                          : "#DDFAEC"
                        : ""
                    }}
                  >
                    <Input
                      variant="outlined"
                      className={localClasses.textbox}
                      onChange={event => {
                        props.handleSupplementChange(
                          false,
                          false,
                          true,
                          "morn",
                          event,
                          event.target.value,
                          suppNo
                        );
                      }}
                      disabled={
                        formState.isActive === false ||
                        !props.suppData[suppNo] ||
                        !props.suppData[suppNo].id
                      }
                      InputProps={{
                        readOnly: props.viewOnly === true ? true : false
                      }}
                      placeholder={"0"}
                      value={
                        props.suppData[suppNo]
                          ? props.suppData[suppNo].morn
                          : ""
                      }
                    />
                  </TableCell>
                  <TableCell
                    className={localClasses.borderNone}
                    style={{
                      backgroundColor: props.openAction
                        ? formState.checked[suppNo]
                          ? "#FFF4F4"
                          : "#DDFAEC"
                        : ""
                    }}
                  >
                    <Input
                      variant="outlined"
                      className={localClasses.textbox}
                      onChange={event => {
                        props.handleSupplementChange(
                          false,
                          false,
                          true,
                          "pre",
                          event,
                          event.target.value,
                          suppNo
                        );
                      }}
                      disabled={
                        formState.isActive === false ||
                        !props.suppData[suppNo] ||
                        !props.suppData[suppNo].id
                      }
                      InputProps={{
                        readOnly: props.viewOnly === true ? true : false
                      }}
                      placeholder={"0"}
                      value={
                        props.suppData[suppNo] ? props.suppData[suppNo].pre : ""
                      }
                    />
                  </TableCell>
                  <TableCell
                    className={localClasses.borderNone}
                    style={{
                      backgroundColor: props.openAction
                        ? formState.checked[suppNo]
                          ? "#FFF4F4"
                          : "#DDFAEC"
                        : ""
                    }}
                  >
                    <Input
                      variant="outlined"
                      className={localClasses.textbox}
                      onChange={event => {
                        props.handleSupplementChange(
                          false,
                          false,
                          true,
                          "post",
                          event,
                          event.target.value,
                          suppNo
                        );
                      }}
                      disabled={
                        formState.isActive === false ||
                        !props.suppData[suppNo] ||
                        !props.suppData[suppNo].id
                      }
                      InputProps={{
                        readOnly: props.viewOnly === true ? true : false
                      }}
                      placeholder={"0"}
                      value={
                        props.suppData[suppNo]
                          ? props.suppData[suppNo].post
                          : ""
                      }
                    />
                  </TableCell>
                  <TableCell
                    className={localClasses.borderNone}
                    style={{
                      backgroundColor: props.openAction
                        ? formState.checked[suppNo]
                          ? "#FFF4F4"
                          : "#DDFAEC"
                        : ""
                    }}
                  >
                    <Input
                      variant="outlined"
                      className={localClasses.textbox}
                      onChange={event => {
                        props.handleSupplementChange(
                          false,
                          false,
                          true,
                          "eve",
                          event,
                          event.target.value,
                          suppNo
                        );
                      }}
                      disabled={
                        formState.isActive === false ||
                        !props.suppData[suppNo] ||
                        !props.suppData[suppNo].id
                      }
                      InputProps={{
                        readOnly: props.viewOnly === true ? true : false
                      }}
                      placeholder={"0"}
                      value={
                        props.suppData[suppNo] ? props.suppData[suppNo].eve : ""
                      }
                    />
                  </TableCell>
                  <TableCell
                    className={localClasses.borderNone}
                    style={{
                      backgroundColor: props.openAction
                        ? formState.checked[suppNo]
                          ? "#FFF4F4"
                          : "#DDFAEC"
                        : ""
                    }}
                  >
                    <Input
                      variant="outlined"
                      disabled={
                        formState.isActive === false && props.viewOnly === true
                          ? false
                          : true
                      }
                      InputProps={{
                        readOnly: props.viewOnly === true ? true : false
                      }}
                      className={localClasses.textbox}
                      onChange={event => {
                        props.handleSupplementChange(
                          false,
                          false,
                          true,
                          "total",
                          event,
                          event.target.value,
                          suppNo
                        );
                      }}
                      placeholder={"0"}
                      value={
                        props.suppData[suppNo]
                          ? props.suppData[suppNo].total
                          : ""
                      }
                    />
                  </TableCell>
                  <TableCell
                    className={localClasses.borderNone}
                    style={{
                      backgroundColor: props.openAction
                        ? formState.checked[suppNo] === true
                          ? "#FFF4F4"
                          : "#DDFAEC"
                        : ""
                    }}
                  >
                    <Input
                      id={"supp_notes_" + suppNo}
                      multiline
                      disabled={
                        formState.isActive === false && props.viewOnly === true
                          ? false
                          : true
                      }
                      InputProps={{
                        readOnly: props.viewOnly === true ? true : false
                      }}
                      rows={4}
                      className={localClasses.textbox}
                      variant="outlined"
                      value={
                        props.suppData[suppNo]
                          ? props.suppData[suppNo].supp_notes
                          : ""
                      }
                    />
                  </TableCell>
                  <TableCell
                    className={localClasses.borderNone}
                    style={{
                      backgroundColor: props.openAction
                        ? formState.checked[suppNo] === true
                          ? "#FFF4F4"
                          : "#DDFAEC"
                        : ""
                    }}
                  >
                    <Input
                      id={"PersonalNotes_" + suppNo}
                      multiline
                      rows={4}
                      disabled={
                        formState.isActive === false ||
                        !props.suppData[suppNo] ||
                        !props.suppData[suppNo].id
                      }
                      InputProps={{
                        readOnly: props.viewOnly === true ? true : false
                      }}
                      className={localClasses.textbox}
                      variant="outlined"
                      onChange={event => {
                        props.handleSupplementChange(
                          false,
                          true,
                          false,
                          "PersonalNotes",
                          event,
                          event.target.value,
                          suppNo
                        );
                      }}
                      value={
                        props.suppData[suppNo]
                          ? props.suppData[suppNo].PersonalNotes
                          : ""
                      }
                    />
                  </TableCell>
                  {props.viewOnly === true ? null : (
                    <TableCell
                      className={localClasses.borderNone}
                      style={{
                        backgroundColor: props.openAction
                          ? formState.checked[suppNo] === true
                            ? "#FFF4F4"
                            : "#DDFAEC"
                          : ""
                      }}
                    >
                      <IconButton
                        color="secondary"
                        aria-label="Delete supplement"
                        onClick={event => {
                          props.handleDeleteSupplement(suppNo);
                        }}
                        disabled={
                          formState.isActive === false ||
                          !props.checkIfDataProper(true)
                        }
                      >
                        <DeleteForeverIcon
                          id={"PersonalNotes_" + suppNo}
                          style={
                            !props.checkIfDataProper(true) ||
                            formState.isActive === false
                              ? { color: "gray" }
                              : { color: "red" }
                          }
                        />
                      </IconButton>
                    </TableCell>
                  )}
                  {props.viewOnly === true ? null : (
                    <TableCell
                      className={localClasses.borderNone}
                      style={{
                        backgroundColor: props.openAction
                          ? formState.checked[suppNo] === true
                            ? "#FFF4F4"
                            : "#DDFAEC"
                          : ""
                      }}
                    >
                      <IconButton
                        color="secondary"
                        aria-label="Add supplement"
                        onClick={event => {
                          props.handleAddSupplement(suppNo);
                        }}
                        disabled={
                          formState.isActive === false ||
                          !props.checkIfDataProper()
                        }
                      >
                        <AddCircleOutlineIcon
                          id={"PersonaladdNotes_" + suppNo}
                          style={
                            !props.checkIfDataProper() ||
                            formState.isActive === false
                              ? { color: "gray" }
                              : { color: "green" }
                          }
                        />
                      </IconButton>
                    </TableCell>
                  )}
                  {props.openAction === true && (
                    <TableCell
                      className={localClasses.checkBoxStyle}
                      style={{
                        backgroundColor: props.openAction
                          ? formState.checked[suppNo] === true
                            ? "#FFF4F4"
                            : "#DDFAEC"
                          : ""
                      }}
                    >
                      <Checkbox
                        checkedIcon={<CheckCircleOutlineOutlinedIcon />}
                        checked={true}
                        onChange={handleChange}
                        id={suppNo}
                        disabled={formState.isActive === false}
                        name={props.suppData[suppNo].trackedStatus}
                        style={{
                          color:
                            formState.checked[suppNo] === true
                              ? "green"
                              : "grey"
                        }}
                      />
                      <Checkbox
                        icon={<CancelRoundedIcon />}
                        checkedIcon={
                          <CancelRoundedIcon style={{ color: "red" }} />
                        }
                        id={suppNo}
                        name={props.suppData[suppNo].trackedStatus}
                        checked={
                          formState.checked[suppNo] === true ? false : true
                        }
                        disabled={formState.isActive === false}
                        onChange={handleChange}
                        className={localClasses.checkBoxStyle2}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Grid>
    </>
  );
};

export default SupplementGrid;
