import React from "react";
import styles from "./CardComponent.module.css";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const localStyles = makeStyles(theme => ({
  titleText: {
    color: "#1C4979",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1.25rem",
    marginBottom: "1.5625rem",
    textAlign: "center"
  },
  textDesign: {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.5",
    letterSpacing: "-0.015em",
    color: "#000000"
  }
}));

const CardComponent = props => {
  const classes = localStyles();

  return (
    <div className={styles.CardComponent} style={{ display: "flex" }}>
      <Card onClick={props.handleCardClick}>
        <CardActionArea>
          <CardMedia
            component={props.imgComponent}
            alt={props.imgAlt}
            height={props.imgHeight}
            width={props.imgWidth}
            image={props.imgImage}
            title={props.imgTitle}
            className={styles.mediaBox}
          />
          <CardContent style={{ flex: "auto" }}>
            <div className={styles.titleButton}>
              <Typography className={classes.titleText} gutterBottom>
                {props.cardTitle}
              </Typography>
            </div>
            <div className={styles.contentText}>
              <Typography
                variant="body2"
                className={classes.textDesign}
                component="p"
              >
                {props.contentText}
              </Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

CardComponent.propTypes = {};

CardComponent.defaultProps = {};

export default CardComponent;
