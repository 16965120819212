import React, { useEffect, useState } from "react";
import styles from "./AddEditExercise.module.css";
import addExerciseForm from "../ExerciseSchema";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  LinearProgress
} from "@material-ui/core";
import {
  Auth,
  AutoCompleteDropDown,
  Input,
  SnackBar,
  DarkBlueButton,
  BorderLessButton
} from "../../../components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { get } from "lodash";
import {
  checkAllKeysPresent,
  checkEmpty,
  getListOfKeysNotPresent,
  setErrors
} from "../../../utils";
import SaveIcon from "@material-ui/icons/Save";
import { EXERCISE } from "../../../paths";
import { providerForGet, providerForPost, providerForPut } from "../../../api";
import {
  exercise,
  findMuscleGroupForAdmin,
  franchise
} from "../../../constants";
import {
  ERROR_MESSAGE,
  SAVE_MESSAGE,
  UPDATE_MESSAGE
} from "../../../constants/genericConstants";
import useStyles from "../../../utils/Styles/ManagePageStyles";
import DialogBox from "../../../components/DialogBox/DialogBox";

const AddEditExercise = props => {
  const history = useHistory();
  const auth = Auth.getToken();
  const userInfo = Auth.getUserInfo();
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  let propData = props["location"]["state"];
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    franchiseList: [],
    muscleGroupList: [],
    alert: false,
    severity: "success",
    errorMessage: "",
    /** This is when we return from edit page */
    rowData: propData ? propData : [],
    editData: propData ? (propData["id"] ? true : false) : false,
    editDataId: propData ? propData["id"] : null
  });
  const [isAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_ADMIN_ROLE_NAME ? true : false
  );
  const [isSuperAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
      ? true
      : false
  );
  const exerciseName = "exerciseName";
  const muscleGroupName = "muscleGroupName";
  const franchiseName = "franchiseName";

  /**Api call for getting autocomplete list itmes */
  const getListData = async () => {
    setLoader(true);
    let body = {};
    await providerForGet(franchise, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          franchiseList: res.data.data
        }));
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });

    if (isAdmin || isSuperAdmin) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [franchiseName]: Auth.getUserInfo().franchise
            ? Auth.getUserInfo().franchise.id
            : null
        }
      }));
    }

    await providerForGet(findMuscleGroupForAdmin, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          muscleGroupList: res.data.data
        }));
        setLoader(false);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });

    if (
      Auth.getUserInfo().role.name ===
      process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
    ) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [franchiseName]: Auth.getUserInfo().franchise
            ? Auth.getUserInfo().franchise.id
            : null
        }
      }));
    }

    if (formState.editData === true) {
      let editId = {
        id: formState.editDataId,
        desc: `${userInfo.full_name} accessed an exercise for editing`
      };
      await providerForGet(exercise, editId, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              exerciseName: res.data.data[0].name,
              muscleGroupName: res.data.data[0].muscle_group
                ? res.data.data[0].muscle_group.id
                : null,
              franchiseName: res.data.data[0].franchise
                ? res.data.data[0].franchise.id
                : null
            },
            touched: {
              ...formState.touched,
              exerciseName: true,
              muscleGroupName: true,
              franchiseName: true
            }
          }));
          setLoader(false);
        })
        .catch(err => {
          console.log("err", err);
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    getListData();
  }, []);

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: ""
    }));
  };

  const handleChange = e => {
    e.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));
    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const handleChangeAutoComplete = (eventName, event, value) => {
    if (value !== null) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: value.id
        },
        touched: {
          ...formState.touched,
          [eventName]: true
        }
      }));
      if (formState.errors.hasOwnProperty(eventName)) {
        delete formState.errors[eventName];
      }
    } else {
      if (eventName === franchiseName) {
        if (isAdmin) {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              [franchiseName]: Auth.getUserInfo().franchise.id
            }
          }));
        } else {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              [franchiseName]: null
            }
          }));
        }
      } else {
        delete formState.values[eventName];
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [eventName]: null
          }
        }));
      }
    }
  };

  const hasError = field => (formState.errors[field] ? true : false);

  const handleSubmit = () => {
    setLoader(true);
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      addExerciseForm
    );
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, addExerciseForm);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        addExerciseForm
      );
      formState.errors = setErrors(formState.values, addExerciseForm);
    }
    if (isValid) {
      let isGlobal = false;
      if (isSuperAdmin) {
        if (!formState.values.franchiseName) {
          isGlobal = true;
        }
      }
      /**api call from here */
      let postData = {
        name: formState.values.exerciseName,
        muscle_group: formState.values.muscleGroupName,
        franchise: formState.values.franchiseName,
        isGlobal: isGlobal
      };
      /**Checking add/edit  */
      if (formState.editData === true) {
        /**Put api call (Update method)*/
        providerForPut(exercise, formState.editDataId, postData, auth, {
          desc: `${userInfo.full_name} updated the exercise ${formState.values.exerciseName}`
        })
          .then(res => {
            let successMessage = {
              successMessage: "Exercise  " + res.data.name + UPDATE_MESSAGE
            };
            setLoader(false);
            history.push(EXERCISE, successMessage);
          })
          .catch(err => {
            console.log("err", err);
            if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              setFormState(formState => ({
                ...formState,
                alert: true,
                severity: "error",
                errorMessage: ERROR_MESSAGE + err.response.data.message
              }));
            } else {
              setFormState(formState => ({
                ...formState,
                alert: true,
                severity: "error",
                errorMessage: ERROR_MESSAGE + err
              }));
            }
            setOpenDialog(false);
            setLoader(false);
          });
      } else {
        /**Post api call (Save method)*/
        providerForPost(exercise, postData, auth, {
          desc: `${userInfo.full_name} added a new exercise ${formState.values.exerciseName}`
        })
          .then(res => {
            let successMessage = {
              successMessage: "Exercise " + res.data.name + SAVE_MESSAGE
            };
            setLoader(false);
            history.push(EXERCISE, successMessage);
          })
          .catch(err => {
            console.log("error", err);
            if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              setFormState(formState => ({
                ...formState,
                alert: true,
                severity: "error",
                errorMessage: ERROR_MESSAGE + err.response.data.message
              }));
            } else {
              setFormState(formState => ({
                ...formState,
                alert: true,
                severity: "error",
                errorMessage: ERROR_MESSAGE + err
              }));
            }
            setOpenDialog(false);
            setLoader(false);
          });
      }
    } else {
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
      setLoader(false);
    }
  };

  const checkIfFranchiseSelected = () => {
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      addExerciseForm
    );
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, addExerciseForm);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        addExerciseForm
      );
      formState.errors = setErrors(formState.values, addExerciseForm);
    }
    if (isValid) {
      if (formState.values.franchiseName) {
        handleSubmit();
      } else {
        setOpenDialog(true);
      }
    } else {
      setFormState(formState => ({
        ...formState
      }));
    }
  };

  const handleCancel = () => {
    history.push(EXERCISE);
  };
  return (
    <div>
      {loader ? (
        <LinearProgress />
      ) : (
        <>
          <Grid item xs={12} className={styles.title}>
            {formState.editData === true ? (
              <Typography variant="h4" className={classes.titleText}>
                Edit Exercise
              </Typography>
            ) : (
              <Typography variant="h4" className={classes.titleText}>
                Add Exercise
              </Typography>
            )}
          </Grid>
          <Grid>
            <SnackBar
              open={formState.alert}
              severity={formState.severity}
              onClose={handleSnackbarClose}
            >
              {formState.errorMessage}
            </SnackBar>
          </Grid>
          <Grid item xs={12} className={classes.formgrid}>
            <Card className={classes.editCard}>
              <CardContent className={classes.editCardContent}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addExerciseForm[exerciseName], "label")}
                      name={exerciseName}
                      value={formState.values[exerciseName] || ""}
                      error={hasError(exerciseName)}
                      placeholder={get(
                        addExerciseForm[exerciseName],
                        "placeholder"
                      )}
                      variant="outlined"
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(exerciseName)
                          ? formState.errors[exerciseName].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AutoCompleteDropDown
                      options={formState.muscleGroupList}
                      getOptionLabel={option => option.name}
                      id={get(addExerciseForm[muscleGroupName], "id")}
                      value={
                        formState.muscleGroupList[
                          formState.muscleGroupList.findIndex(function (
                            item,
                            i
                          ) {
                            return (
                              item.id === formState.values[muscleGroupName]
                            );
                          })
                        ] || null
                      }
                      onChange={(event, value) => {
                        handleChangeAutoComplete(muscleGroupName, event, value);
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          id={get(addExerciseForm[muscleGroupName], "id")}
                          label={get(addExerciseForm[muscleGroupName], "label")}
                          variant="outlined"
                          required
                          error={hasError(muscleGroupName)}
                          helperText={
                            hasError(muscleGroupName)
                              ? formState.errors[muscleGroupName].map(error => {
                                  return error + " ";
                                })
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AutoCompleteDropDown
                      options={formState.franchiseList}
                      getOptionLabel={option => option.franchise_name}
                      id={get(addExerciseForm[franchiseName], "id")}
                      value={
                        formState.franchiseList[
                          formState.franchiseList.findIndex(function (item, i) {
                            return item.id === formState.values[franchiseName];
                          })
                        ] || null
                      }
                      disabled={isAdmin}
                      onChange={(event, value) => {
                        handleChangeAutoComplete(franchiseName, event, value);
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          id={get(addExerciseForm[franchiseName], "id")}
                          label={get(addExerciseForm[franchiseName], "label")}
                          variant="outlined"
                          disabled={isAdmin}
                          error={hasError(franchiseName)}
                          helperText={
                            hasError(franchiseName)
                              ? formState.errors[franchiseName].map(error => {
                                  return error + " ";
                                })
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} className={classes.actionButtonGrid}>
            <DarkBlueButton
              type="submit"
              data-testid="ok-button"
              onClick={checkIfFranchiseSelected}
            >
              <SaveIcon />
              &nbsp; Save
            </DarkBlueButton>
            <BorderLessButton onClick={handleCancel}>cancel</BorderLessButton>
          </Grid>
          <DialogBox
            open={openDialog}
            title="Save Exercise"
            buttonCancel="Cancel"
            handleCancel={() => setOpenDialog(false)}
            skipOk={false}
            buttonOk={"Ok"}
            handleOk={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Typography gutterBottom>
                  Since you haven't chosen a franchise, this entry will be seen
                  by all the franchises. Are you sure you want to go on?
                </Typography>
              </Grid>
            </Grid>
          </DialogBox>
        </>
      )}
    </div>
  );
};

AddEditExercise.propTypes = {};

AddEditExercise.defaultProps = {};

export default AddEditExercise;
