import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Auth,
  Input,
  SnackBar,
  Table,
  DarkBlueButton,
  BorderLessButton,
  AutoCompleteDropDown
} from "../../../components";
import styles from "./Gender.module.css";
import { findIfDataAvailableInContacts, getGender } from "../../../constants";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "../../../utils/Styles/ManagePageStyles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ADDGENDER, EDITGENDER } from "../../../paths";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { providerForDelete, providerForGet } from "../../../api";
import {
  DELETE_MESSAGE,
  ERROR_MESSAGE
} from "../../../constants/genericConstants";
import {
  franchise,
  franchiseListForAdmin,
  getGenderForFranchise
} from "../../../constants/urlConstants";

const Gender = props => {
  const propData = props["location"]["state"];
  const history = useHistory();
  const classes = useStyles();
  const [filter, setFilter] = useState({
    _sort: "name:asc"
  });
  const tableRef = React.createRef();
  const auth = Auth.getToken();
  const userInfo = Auth.getUserInfo();
  const columns = [
    { title: "Name", field: "name" },
    {
      title: "Franchise",
      field: "franchise",
      sorting: false
    }
  ];

  const [formState, setFormState] = useState({
    dataTable: {
      data: [],
      page: 1,
      totalCount: 0
    },
    alert: propData ? propData["successMessage"] : false,
    errorMessage: propData ? propData["successMessage"] : false,
    severity: "success",
    isLoad: false,
    franchiseList: []
  });
  let apiUrl = "";

  const [isAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_ADMIN_ROLE_NAME ? true : false
  );
  const [isSuperAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
      ? true
      : false
  );

  const getListData = async () => {
    let body = {};
    if (isAdmin) {
      await providerForGet(franchiseListForAdmin, body, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            franchiseList: res.data
          }));
        })
        .catch(err => {
          console.log("err", err);
        });
    } else {
      await providerForGet(franchise, body, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            franchiseList: res.data.data
          }));
        })
        .catch(err => {
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    getListData();
  }, []);

  /**
   ** Use key names exactly same as API response,they'll be used for filtering & sorting purpose
   * */
  const convertTableData = res => {
    let arr = [];
    if (res && res.length > 0) {
      for (let i in res) {
        let tempArr = {};
        tempArr["id"] = res[i].id;
        tempArr["name"] = res[i].name;
        tempArr["franchise"] = res[i].franchise
          ? res[i].franchise.franchise_name
          : "";
        tempArr["isGlobal"] = res[i].isGlobal ? res[i].isGlobal : false;
        arr.push(tempArr);
      }
      return arr;
    } else {
      return [];
    }
  };

  const getUserData = async (page, pageSize) => {
    /**
     ** It sets the page and pageSize in filter variable
     ** so as to keep the record of what page and pageSize was set
     */
    if (isSuperAdmin) {
      apiUrl = getGender;
    } else {
      apiUrl = getGenderForFranchise;
    }
    setFilter(filter => ({
      ...filter,
      page: page,
      pageSize: pageSize
    }));

    let params = { page: page, pageSize: pageSize };
    Object.keys(filter).map(res => {
      if (!params.hasOwnProperty(res)) {
        params[res] = filter[res];
      }
      return null;
    });
    params.desc = `${userInfo.full_name} viewed the genders listing page`;
    return new Promise((resolve, reject) => {
      fetch(apiUrl + "?" + new URLSearchParams(params), {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth
        }
      })
        .then(response => response.json())
        .then(result => {
          resolve({
            data: convertTableData(result.data),
            page: result.page - 1,
            totalCount: result.totalCount
          });
        });
    });
  };

  const handleClickOpen = rowData => {
    let data = {};
    data["id"] = rowData.id;

    history.push(EDITGENDER, data);
  };

  const handleFilterChange = event => {
    if (event.target.value !== "") {
      setFilter(filter => ({
        ...filter,
        [event.target.name]: event.target.value
      }));
    } else {
      delete filter[event.target.name];
      setFilter(filter => ({
        ...filter
      }));
    }
  };

  const handleFilterAutoCompleteChange = (eventName, event, value) => {
    if (value !== null) {
      setFilter(filter => ({
        ...filter,
        [eventName]: value.id
      }));
    } else {
      delete filter[eventName];
      setFilter(filter => ({
        ...filter
      }));
    }
  };

  const orderFunc = (columnId, direction) => {
    let orderByColumn;
    let orderBy = "";
    if (columnId >= 0) {
      orderByColumn = columns[columnId]["field"];
    }
    orderBy = orderByColumn + ":" + direction;
    setFilter(filter => ({
      ...filter,
      _sort: orderBy
    }));
    tableRef.current.onQueryChange();
  };

  const handleReset = event => {
    delete filter["name_contains"];
    delete filter["franchise"];

    setFilter(filter => ({
      ...filter
    }));
    getUserData(1, filter.pageSize);
    tableRef.current.onQueryChange();
  };

  const handleSnackbarClose = () => {
    if (propData) {
      propData["successMessage"] = false;
    }
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "success"
    }));
  };

  return (
    <div className={styles.User}>
      <Typography variant="h4" className={styles.titleText}>
        Manage Gender
      </Typography>

      <Grid>
        <SnackBar
          open={formState.alert}
          severity={formState.severity}
          onClose={handleSnackbarClose}
        >
          {formState.errorMessage}
        </SnackBar>
      </Grid>

      <Grid container spacing={1}>
        <Grid item sm={12} className={classes.addButton}>
          <DarkBlueButton
            onClick={() => {
              history.push(ADDGENDER);
            }}
          >
            <AddIcon /> &nbsp; Add Gender{" "}
          </DarkBlueButton>
        </Grid>
      </Grid>

      <Card className={classes.paperFilter}>
        <CardContent className={classes.Cardtheming}>
          <Grid className={classes.filterOptions} container spacing={1}>
            <Grid item>
              <Input
                label={"Name"}
                placeholder="Name"
                name="name_contains"
                value={filter.name_contains ? filter.name_contains : ""}
                variant="outlined"
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid item>
              <AutoCompleteDropDown
                options={formState.franchiseList}
                getOptionLabel={option => option.franchise_name}
                id={"franchiseName"}
                value={
                  formState.franchiseList[
                    formState.franchiseList.findIndex(function (item, i) {
                      return item.id === filter.franchise;
                    })
                  ] || null
                }
                onChange={(event, value) => {
                  handleFilterAutoCompleteChange("franchise", event, value);
                }}
                renderInput={params => (
                  <Input
                    {...params}
                    name={franchise}
                    style={{ "min-width": "150px" }}
                    id={"franchiseName"}
                    label={"Franchise"}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <DarkBlueButton
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => {
                  setFormState(formState => ({
                    ...formState,
                    isLoad: true
                  }));
                  tableRef.current.onQueryChange();
                }}
              >
                search
              </DarkBlueButton>
            </Grid>
            <Grid item>
              <BorderLessButton onClick={handleReset} disableElevation>
                reset{" "}
              </BorderLessButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Table
        tableRef={tableRef}
        columns={columns}
        data={async query => {
          return await getUserData(query.page + 1, query.pageSize);
        }}
        localization={{
          body: {
            editRow: {
              deleteText: "Are you sure you want to delete this Gender?",
              saveTooltip: "Delete"
            }
          }
        }}
        actions={[
          rowData => ({
            icon: () => <EditOutlinedIcon />,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              handleClickOpen(rowData);
            },
            disabled: isAdmin && rowData.isGlobal
          })
        ]}
        editable={{
          isDeletable: rowData =>
            (!rowData.isGlobal && isAdmin) || isSuperAdmin,
          onRowDelete: oldData =>
            new Promise(async resolve => {
              let query = { gender: oldData.id };
              await providerForGet(findIfDataAvailableInContacts, query, auth)
                .then(res => {
                  if (res.data.data > 0) {
                    let userOrUsers = res.data.data > 1 ? " Users" : " User";
                    let names = [];
                    let allNames = res.data.names;
                    if (res.data.data > 2) {
                      let finalLength = res.data.data - 2;
                      names = [allNames[0], allNames[1], " + " + finalLength];
                    } else {
                      names = allNames;
                    }
                    setFormState(formState => ({
                      ...formState,
                      alert: true,
                      errorMessage:
                        "cannot delete ! as gender data is used by " +
                        names.join(" ,") +
                        userOrUsers,
                      severity: "error"
                    }));
                    resolve();
                  } else {
                    setTimeout(async () => {
                      await providerForDelete(getGender, oldData.id, auth, {
                        desc: `${userInfo.full_name} deleted a gender info`
                      })
                        .then(res => {
                          setFormState(formState => ({
                            ...formState,
                            alert: true,
                            errorMessage: res.data.name + DELETE_MESSAGE,
                            severity: "success"
                          }));
                        })
                        .catch(err => {
                          console.log("error", err);
                          setFormState(formState => ({
                            ...formState,
                            alert: true,
                            errorMessage: ERROR_MESSAGE + err,
                            severity: "error"
                          }));
                        });
                      resolve();
                    }, 1000);
                  }
                })
                .catch(err => {
                  console.log("error", err);
                });
            })
        }}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          sorting: true,
          thirdSortClick: false
        }}
        onOrderChange={(orderedColumnId, orderDirection) => {
          orderFunc(orderedColumnId, orderDirection);
        }}
      />
    </div>
  );
};

Gender.propTypes = {};

Gender.defaultProps = {};

export default Gender;
