import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { useHistory } from "react-router-dom";
import styles from "./AddEditClient.module.css";

import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  withStyles,
  Box,
  Backdrop,
  FormGroup,
  Tooltip
} from "@material-ui/core";
import {
  Input,
  AutoCompleteDropDown,
  Auth,
  SnackBar,
  HeadingOne,
  DialogForGettingMealNumbers,
  BorderLessButton,
  DarkBlueButton,
  InlineDatePicker
} from "../../../components";
import addClientForm from "./ClientSchema";
import {
  checkAllKeysPresent,
  getListOfKeysNotPresent,
  setErrors,
  checkEmpty,
  isEmptyString
} from "../../../utils";
import { MANAGECLIENTS, USERS, BODYMASS } from "../../../paths";
import {
  getRelation,
  getGender,
  getMaritalStatus,
  addClient,
  userTagForAdmin,
  schoolingForAdmin,
  getOneClient,
  updateClient,
  checkEmailAvailable,
  countryCodeList as countries,
  createScheduleApi,
  usaState,
  duplicateScheduleData,
  getAllCountries,
  franchiseSites,
  franchise,
  getGenderForFranchise
} from "../../../constants";
import { providerForGet, providerForPost, providerForPut } from "../../../api";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Switch from "@material-ui/core/Switch";
import {
  ERROR_MESSAGE,
  UPDATE_MESSAGE
} from "../../../constants/genericConstants";
import PersonalInfoSchema from "./ClientInfoSchema/PersonalInfoSchema";
import SpouseSchema from "./ClientInfoSchema/SpouseSchema";
import EmergencyContactSchema from "./ClientInfoSchema/EmergencyContactSchema";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import FormHelperText from "@material-ui/core/FormHelperText";
import Styles from "./AddEditClient.module.css";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

/**
 * * Purple Switch is used to do custom color in switch button
 */
const PurpleSwitch = withStyles({
  switchBase: {
    color: "#F4F9FF",
    "&$checked": {
      color: "#1C4979"
    },
    "&$checked + $track": {
      backgroundColor: "#1C4979"
    }
  },
  checked: {
    color: "#F4F9FF"
  },
  track: {
    backgroundColor: "#1C4979"
  }
})(Switch);
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: "#fff"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: "100%"
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto"
  },
  nextbackbutton: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "flex-end"
  },
  finishtext: {
    marginLeft: "40%"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    position: "relative"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  switchBtnTxt: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.875rem",
    color: "#000000",
    alignSelf: "center",
    lineHeight: "1.3125rem"
  },
  step: {
    margin: "5px 0px",
    "& $completed": {
      fill: "#1C4979 !important"
    },
    "& $active": {
      fill: "#1C4979 !important"
    },
    "& $active text": {
      fill: "#ffffff"
    },
    "& $disabled": {
      color: "#EDEDF2"
    },
    color: "#EDEDF2",
    "& .MuiTypography-caption": {
      color: "#1c4979 !important"
    }
  },
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {},

  labelActive: {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    fontWeight: "500 !important",
    color: "#110F48 !important"
  },
  label: {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    fontWeight: "500 !important",
    color: "#8A8A97"
  },
  text: {
    fill: "#8A8A97",
    fontSize: "0.875rem",
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  caption: {
    color: "#110F48"
  }
}));

const AddEditClient = props => {
  const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
  const countryTxt = "United States";
  const PersonalSchemaRequiredKeys = [
    "full_name",
    "email",
    "birthdate",
    "gender"
  ];
  const classes = useStyles();
  const history = useHistory();
  const userInfo = Auth.getUserInfo();

  const auth = Auth.getToken();
  let propData = props["location"]["state"];
  const [schoolingList, setSchoolingList] = useState([]);
  const [addTagList, setAddTagList] = useState([]);
  const [relation, setRelation] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showStateDropDown, setShowStateDropDown] = useState(false);
  /** Create new meals */
  const [meals, setMeals] = useState({
    noOfMeals: 6,
    dialogOpenClose: false,
    isError: false,
    helperText: ""
  });
  const [isSuperAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
      ? true
      : false
  );

  /**
   * * Get No Of Meals Dialog Close
   */
  const handleCloseGetNoOfMealsDialogOpen = () => {
    setMeals(meals => ({
      ...meals,
      dialogOpenClose: false
    }));
  };

  const handleChangeNoOfMealsPerDay = e => {
    let isError = false;
    let text = "";
    if (
      parseInt(e.target.value) === 0 ||
      parseInt(e.target.value) === 100 ||
      parseInt(e.target.value) > 100 ||
      parseInt(e.target.value) < 0
    ) {
      isError = true;
      text = "Meals can be between 1 - 99";
    } else if (isEmptyString(e.target.value)) {
      isError = true;
      text = "No of meals cannot be empty";
    }
    setMeals(meals => ({
      ...meals,
      noOfMeals: e.target.value,
      isError: isError,
      helperText: text
    }));
  };

  /**
   * * This is the list of variables declared whose name should be the same as
   * * declared in the schema
   */
  const full_name = "full_name";
  const address = "address";
  const city = "city";
  const State = "State";
  const zipcode = "zipcode";
  const birthdate = "birthdate";
  const home_phone = "home_phone";
  const business_phone = "business_phone";
  const dl_number = "dl_number";
  const ss_number = "ss_number";
  const schooling = "schooling";
  const clientReferredBy = "clientReferredBy";
  const gender = "gender";
  const clientEmail = "email";
  const country = "country";
  const phone = "phone";

  /**Add Tags */
  const addTags = "addTags";

  /**Relationship status */
  const isMarried = "isMarried";
  const partner_name = "partner_name";
  const partner_dob = "partner_dob";
  const partner_employer = "partner_employer";
  const partner_employer_phone = "partner_employer_phone";
  const partner_emp_address = "partner_emp_address";

  /**Const for emplyment form */
  const occupation = "occupation";
  const employer = "employer";
  const employment_address = "employment_address";
  const employment_phone = "employment_phone";

  /**Const for emergency contact  form */
  const emergency_contact_name = "emergency_contact_name";
  const emergency_contact_relation = "emergency_contact_relation";
  const emergency_contact_home_phone = "emergency_contact_home_phone";
  const emergency_contact_work_phone = "emergency_contact_work_phone";

  /**Const for notes  */
  const notes = "notes";
  const franchiseName = "franchiseName";
  const franchiseSite = "franchiseSite";

  const do_not_share = "do_not_share";
  const active = "active";
  const is2FAEnabled = "is2FAEnabled";

  const [error, setError] = useState({
    alert: false,
    severity: "success",
    errorMessage: ""
  });

  const [spouseInfoBackup, setSpouseInfoBackup] = useState({
    partner_name: "",
    partner_dob: new Date(),
    partner_employer: "",
    partner_employer_phone: "",
    partner_emp_address: ""
  });

  const [employeerBackup, setEmployeerBackup] = useState({
    occupation: "",
    employer: "",
    employment_address: "",
    employment_phone: ""
  });

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      franchiseName: userInfo.franchise ? userInfo.franchise.id : null,
      birthdate: new Date()
    },
    dateValues: {},
    isMarried: false,
    isEmployeed: false,
    touched: {},
    errors: {},
    clientSwitch: false,
    clientId: null,
    clientName: null,
    dataToShowForMultiSelect: [],
    franchiseList: [],
    franchiseSiteList: [],
    isSuccess: false,
    franchise: null,
    /**
     * * isError variable is used to check whether is there any issue adding the client apart from form validations
     */
    isError: false,
    editData: propData ? (propData["id"] ? true : false) : false,
    editDataId: propData ? propData["id"] : null,
    clientViewProfile:
      userInfo.role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
        ? true
        : false,
    clientProfileId:
      userInfo.role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
        ? userInfo.id
        : null,
    editDataUserId: null,
    editDataContactId: null,
    duplicateId: propData ? propData["duplicateId"] : null,
    isDuplicate: propData ? propData["isDuplicate"] : false,
    fromAdmin: propData ? propData["fromAdmin"] : false,
    counter: 0,
    tags: []
  });

  /**
   * * Stepper functions
   */

  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (!formState.clientSwitch) {
      setSteps([
        "Personal Info",
        "Residence Info",
        "Add Tags",
        "Spouse Information",
        "Emergency Contact",
        "Employer",
        "Notes",
        "Profile Settings"
      ]);
    } else {
      setSteps([
        "Personal Info",
        "Residence Info",
        "Add Tags",
        "Notes",
        "Profile Settings"
      ]);
    }
  }, [formState.clientSwitch]);

  const [activeStep, setActiveStep] = React.useState(0);

  const checkEmail = async (stepClicked = false, step) => {
    await providerForPost(
      checkEmailAvailable,
      {
        email: formState.values[clientEmail],
        c: formState.editData
          ? formState.editDataId
          : formState.clientViewProfile
          ? formState.clientProfileId
          : null
      },
      Auth.getToken()
    )
      .then(res => {
        setLoading(false);
        if (stepClicked === true) {
          setActiveStep(step);
        } else {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
        setFormState(formState => ({
          ...formState,
          isValid: true
        }));
      })
      .catch(err => {
        setLoading(false);
        setFormState(formState => ({
          ...formState,
          isValid: false,
          errors: {
            ...formState.errors,
            [clientEmail]: [err["response"]["data"]["data"]]
          }
        }));
      });
  };

  const handleNext = async (stepClicked = false, step) => {
    let isValid = false;
    if (activeStep === 0) {
      setLoading(true);
      let checkAllFieldsValid = checkAllKeysPresent(
        formState.values,
        PersonalInfoSchema
      );
      if (checkAllFieldsValid) {
        formState.errors = setErrors(formState.values, PersonalInfoSchema);
        await validate(formState.values[phone], phone, false);
        await validate(formState.values[home_phone], home_phone, false);
        await validate(formState.values[business_phone], business_phone, false);
        if (checkEmpty(formState.errors)) {
          isValid = true;
        }
      } else {
        formState.values = getListOfKeysNotPresent(
          formState.values,
          PersonalInfoSchema
        );
        formState.errors = setErrors(formState.values, PersonalInfoSchema);
      }
      if (isValid) {
        await checkEmail(stepClicked, step);
      } else {
        setLoading(false);
        setFormState(formState => ({
          ...formState,
          isValid: false
        }));
      }
    } else if (activeStep === 3) {
      let checkAllFieldsValid = checkAllKeysPresent(
        formState.values,
        SpouseSchema
      );
      if (checkAllFieldsValid) {
        formState.errors = setErrors(formState.values, SpouseSchema);
        await validate(
          formState.values[partner_employer_phone],
          partner_employer_phone,
          false
        );
        if (checkEmpty(formState.errors)) {
          isValid = true;
        }
      } else {
        formState.values = getListOfKeysNotPresent(
          formState.values,
          SpouseSchema
        );
        formState.errors = setErrors(formState.values, SpouseSchema);
      }
      if (isValid) {
        if (stepClicked === true) {
          setActiveStep(step);
        } else {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
        setFormState(formState => ({
          ...formState,
          isValid: true
        }));
      } else {
        setLoading(false);
        setFormState(formState => ({
          ...formState,
          isValid: false
        }));
      }
    } else if (activeStep === 4) {
      let checkAllFieldsValid = checkAllKeysPresent(
        formState.values,
        EmergencyContactSchema
      );
      if (checkAllFieldsValid) {
        formState.errors = setErrors(formState.values, EmergencyContactSchema);
        await validate(
          formState.values[emergency_contact_home_phone],
          emergency_contact_home_phone,
          false
        );
        await validate(
          formState.values[emergency_contact_work_phone],
          emergency_contact_work_phone,
          false
        );
        if (checkEmpty(formState.errors)) {
          isValid = true;
        }
      } else {
        formState.values = getListOfKeysNotPresent(
          formState.values,
          EmergencyContactSchema
        );
        formState.errors = setErrors(formState.values, EmergencyContactSchema);
      }
      if (isValid) {
        if (stepClicked === true) {
          setActiveStep(step);
        } else {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
        setFormState(formState => ({
          ...formState,
          isValid: true
        }));
      } else {
        setLoading(false);
        setFormState(formState => ({
          ...formState,
          isValid: false
        }));
      }
    } else if (activeStep === 5 && formState.isEmployeed === true) {
      let checkAllFieldsValid = checkAllKeysPresent(
        formState.values,
        PersonalInfoSchema
      );
      if (checkAllFieldsValid) {
        formState.errors = setErrors(formState.values, PersonalInfoSchema);
        await validate(
          formState.values[employment_phone],
          employment_phone,
          false
        );
        if (checkEmpty(formState.errors)) {
          isValid = true;
        }
      } else {
        formState.values = getListOfKeysNotPresent(
          formState.values,
          PersonalInfoSchema
        );
        formState.errors = setErrors(formState.values, PersonalInfoSchema);
      }
      if (isValid) {
        if (stepClicked === true) {
          setActiveStep(step);
        } else {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
        setFormState(formState => ({
          ...formState,
          isValid: true
        }));
      } else {
        setLoading(false);
        setFormState(formState => ({
          ...formState,
          isValid: false
        }));
      }
      // }
    } else {
      if (stepClicked === true) {
        setActiveStep(step);
      } else {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleCancel = () => {
    if (formState.fromAdmin) {
      history.push({
        pathname: USERS
      });
    } else {
      history.push({
        pathname: MANAGECLIENTS
      });
    }
  };

  /**
   * * Check whether the person adding is super user or not so to select the franchise
   */
  useEffect(() => {
    /**
     * * dependeing on user take the franchise i.e if the user is super admin then take it from props else
     * * from user data
     */
    if (userInfo.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME) {
      setFormState(formState => ({
        ...formState,
        franchise: propData ? propData["franchiseId"] : null
      }));
    } else {
      /**
       * * Check if the franchise is not null else don't give user the ability to add the data
       */
      if (userInfo.franchise) {
        setFormState(formState => ({
          ...formState,
          franchise: userInfo.franchise.id
        }));
      } else {
        setFormState(formState => ({
          ...formState,
          isError: true
        }));
      }
    }
  }, []);

  /**
   * * useEffect to get all the basic info
   */
  useEffect(() => {
    let franchiseId = null;
    if (userInfo.role.name !== process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME) {
      franchiseId = userInfo.franchise ? userInfo.franchise.id : null;
    } else {
      franchiseId = propData
        ? propData["franchiseId"]
          ? propData["franchiseId"]
          : null
        : null;
    }
    getCountryList();
    getSchoolingList();
    getAddTagList();
    getGenderList();
    getMaritalStatusList();
    getRelationList();
    getFranchiseList();
    getFranchiseSiteListData(franchiseId);
    getEditData();
  }, []);

  useEffect(() => {
    setActiveStep(0);
    /**
     * this isused to delete errors when  we switch client status
     */
    Object.keys(formState.errors).map(errorName => {
      delete formState.errors[errorName];
      return null;
    });

    /**
     * deleting all other values than common fields
     */
  }, [formState.clientSwitch]);

  const getEditData = async () => {
    if (formState.editData === true || formState.clientViewProfile) {
      setLoading(true);
      let id =
        formState.clientViewProfile === true
          ? formState.clientProfileId
          : formState.editDataId;

      let editId = {
        desc: `${userInfo.full_name} accessed client data for editing`,
        audit_log_clientid: id
      };

      await providerForGet(getOneClient + "/" + id, editId, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            isEmployeed: res.data.contact.isEmployed
              ? res.data.contact.isEmployed
              : null,
            editDataUserId: res.data.id,
            editDataContactId: res.data.contact ? res.data.contact.id : null,
            isMarried: res.data.contact.isMarried
              ? res.data.contact.isMarried.name
              : null,
            clientSwitch: res.data.isOneTimeClient === true ? true : false,
            dataToShowForMultiSelect: res.data.user_tags,
            tags: res.data.user_tags.map(t => t.id),
            values: {
              ...formState.values,
              franchiseName: res.data.franchise ? res.data.franchise.id : null,
              franchiseSite: res.data.franchise_site
                ? res.data.franchise_site
                : null,
              full_name: res.data.full_name ? res.data.full_name : null,
              address: res.data.contact.address
                ? res.data.contact.address
                : null,
              email: res.data.email,

              //data from here
              country: res.data.contact.country
                ? res.data.contact.country
                : null,
              State: res.data.contact.State ? res.data.contact.State : null,
              city: res.data.contact.city ? res.data.contact.city : null,
              zipcode: res.data.contact.zipcode
                ? res.data.contact.zipcode
                : null,
              birthdate: res.data.contact.birthdate
                ? res.data.contact.birthdate
                : null,
              home_phone: res.data.contact.home_phone
                ? res.data.contact.home_phone
                : null,
              business_phone: res.data.contact.business_phone
                ? res.data.contact.business_phone
                : null,
              dl_number: res.data.contact.dl_number
                ? res.data.contact.dl_number
                : null,
              ss_number: res.data.contact.ss_number
                ? res.data.contact.ss_number
                : null,
              schooling: res.data.contact.schooling
                ? res.data.contact.schooling
                : null,
              gender: res.data.contact.gender ? res.data.contact.gender : null,
              isMarried: res.data.contact.isMarried
                ? res.data.contact.isMarried.id
                : null,
              emergency_contact_name: res.data.contact.emergency_contact_name
                ? res.data.contact.emergency_contact_name
                : null,
              emergency_contact_relation: res.data.contact
                .emergency_contact_relation
                ? res.data.contact.emergency_contact_relation
                : null,
              emergency_contact_work_phone: res.data.contact
                .emergency_contact_work_phone
                ? res.data.contact.emergency_contact_work_phone
                : null,
              emergency_contact_home_phone: res.data.contact
                .emergency_contact_home_phone
                ? res.data.contact.emergency_contact_home_phone
                : null,
              notes: res.data.contact.notes ? res.data.contact.notes : null,
              phone: res.data.contact.phone ? res.data.contact.phone : null,
              partner_name: res.data.contact.partner_name
                ? res.data.contact.partner_name
                : null,
              partner_dob: res.data.contact.partner_dob
                ? res.data.contact.partner_dob
                : null,
              partner_employer: res.data.contact.partner_employeer
                ? res.data.contact.partner_employeer
                : null,
              partner_employer_phone: res.data.contact.partner_employeer_phone
                ? res.data.contact.partner_employeer_phone
                : null,
              partner_emp_address: res.data.contact.partner_emp_address
                ? res.data.contact.partner_emp_address
                : null,
              occupation: res.data.contact.occupation
                ? res.data.contact.occupation
                : null,
              employer: res.data.contact.employeer
                ? res.data.contact.employeer
                : null,
              employment_address: res.data.contact.employment_address
                ? res.data.contact.employment_address
                : null,
              employment_phone: res.data.contact.employment_phone
                ? res.data.contact.employment_phone
                : null,
              clientReferredBy: res.data.contact.clientReferredBy
                ? res.data.contact.clientReferredBy
                : "",
              do_not_share: res.data.do_not_share === true ? true : false,
              active: res.data.active === true ? true : false,
              is2FAEnabled: res.data.is2FAEnabled ? true : false
            },
            dateValues: {
              [birthdate]: res.data.contact.birthdate
                ? res.data.contact.birthdate
                : null,
              [partner_dob]: res.data.contact.partner_dob
                ? res.data.contact.partner_dob
                : null
            }
          }));

          setSpouseInfoBackup(spouseInfoBackup => ({
            ...spouseInfoBackup,
            partner_name: res.data.contact.partner_name
              ? res.data.contact.partner_name
              : null,
            partner_dob: res.data.contact.partner_dob
              ? res.data.contact.partner_dob
              : null,
            partner_employer: res.data.contact.partner_employeer
              ? res.data.contact.partner_employeer
              : null,
            partner_employer_phone: res.data.contact.partner_employeer_phone
              ? res.data.contact.partner_employeer_phone
              : null,
            partner_emp_address: res.data.contact.partner_emp_address
              ? res.data.contact.partner_emp_address
              : null
          }));

          setEmployeerBackup(employeerBackup => ({
            ...employeerBackup,
            occupation: res.data.contact.occupation
              ? res.data.contact.occupation
              : null,
            employer: res.data.contact.employeer
              ? res.data.contact.employeer
              : null,
            employment_address: res.data.contact.employment_address
              ? res.data.contact.employment_address
              : null,
            employment_phone: res.data.contact.employment_phone
              ? res.data.contact.employment_phone
              : null
          }));

          getFranchiseSiteListData(
            res.data.franchise.id ? res.data.franchise.id : null
          );
          /**
           * * code for if US is selected show state as dropdown
           */
          stateTextOrDropdownFunction(res.data.contact.country);
          setLoading(false);
        })
        .catch(err => {
          console.log("err--**", err);
          setLoading(false);
        });
    }
  };

  const getFranchiseSiteListData = async params => {
    // for getting franchise_sites list
    if (params) {
      await providerForGet(franchiseSites, { franchise: params }, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            franchiseSitesList: res.data.data
          }));
        })
        .catch(err => {
          console.log("err", err);
        });
    } else {
      setFormState(formState => ({
        ...formState,
        franchiseSitesList: []
      }));
    }
  };

  /**
   * *get franchise list data
   */

  const getFranchiseList = async () => {
    let body = {};
    await providerForGet(franchise, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          franchiseList: res.data.data
        }));
      })
      .catch(err => {
        console.log("err", err);
      });
  };

  /**
   * * Get schooling list
   */
  const getSchoolingList = async () => {
    await providerForGet(
      schoolingForAdmin,
      {
        pageSize: "-1",
        _sort: "name:asc"
      },
      auth
    )
      .then(res => {
        setSchoolingList(res.data.data);
      })
      .catch(err => {
        setSchoolingList([]);
      });
  };

  /**
   * * Get Add Tags List
   */
  const getAddTagList = async () => {
    await providerForGet(
      userTagForAdmin,
      {
        pageSize: "-1",
        _sort: "name:asc"
      },
      auth
    )
      .then(res => {
        setAddTagList(res.data.data);
      })
      .catch(err => {
        setAddTagList([]);
      });
  };
  /**
   * * Get Gender List
   */
  const getGenderList = async () => {
    let url = "";
    if (isSuperAdmin) {
      url = getGender;
    } else {
      url = getGenderForFranchise;
    }
    await providerForGet(
      url,
      {
        pageSize: "-1",
        _sort: "name:asc"
      },
      auth
    )
      .then(res => {
        setGenderList(res.data);
      })
      .catch(err => {
        setGenderList([]);
      });
  };

  /**
   * * Get Marital status
   */
  const getMaritalStatusList = async () => {
    await providerForGet(
      getMaritalStatus,
      {
        pageSize: "-1",
        _sort: "name:asc"
      },
      auth
    )
      .then(res => {
        setStatusList(res.data);
      })
      .catch(err => {
        setStatusList([]);
      });
  };

  /**
   * * Get relation list
   */
  const getRelationList = async () => {
    await providerForGet(
      getRelation,
      {
        pageSize: "-1",
        _sort: "name:asc"
      },
      auth
    )
      .then(res => {
        setRelation(res.data);
      })
      .catch(err => {
        setRelation([]);
      });
  };

  /**
   * * Get country list
   */
  const getCountryList = async () => {
    await providerForGet(
      getAllCountries,
      {
        _sort: "name:asc"
      },
      auth
    )
      .then(res => {
        setCountryList(res.data.data);
      })
      .catch(err => {
        setCountryList([]);
      });
  };

  const stateTextOrDropdownFunction = async country => {
    await providerForGet(
      getAllCountries,
      {
        _sort: "name:asc"
      },
      auth
    )
      .then(res => {
        let obj = res.data.data;
        obj.map(item => {
          if (item.id === country) {
            if (item.name === countryTxt) {
              setShowStateDropDown(true);
            } else {
              setShowStateDropDown(false);
            }
          }
          return null;
        });
      })
      .catch(err => {
        setCountryList([]);
      });

    countryList.map(item => {
      if (item.id === country) {
        if (item.name === countryTxt) {
          setShowStateDropDown(true);
        } else {
          setShowStateDropDown(false);
        }
      }
      return null;
    });
  };

  const handleChange = e => {
    e.persist();
    /**
     * * If the client is employeed
     */
    ///
    if (e.target.name === "isEmployed") {
      if (e.target.checked) {
        restoreEmployeerBackup();
      } else {
        makeEmployeerBackup();
      }
      setFormState(formState => ({
        ...formState,
        isEmployeed: e.target.checked
      }));
    } else {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [e.target.name]: e.target.value
        },
        touched: {
          ...formState.touched,
          [e.target.name]: true
        }
      }));
      if (formState.errors.hasOwnProperty(e.target.name)) {
        delete formState.errors[e.target.name];
      }
    }
  };

  const handleDateChange = (date, eventName) => {
    var dob = new Date(date);
    var month = dob.getMonth() + 1;
    var day = dob.getDate();
    var year = dob.getFullYear();
    var finalDob = null;
    if (date) {
      finalDob =
        (day <= 9 ? "0" + day : day) +
        "/" +
        (month <= 9 ? "0" + month : month) +
        "/" +
        year;
    }
    if (eventName === "birthdate") {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [birthdate]: date
        },
        dateValues: {
          ...formState.dateValues,
          [birthdate]: finalDob
        },
        touched: {
          ...formState.touched,
          [birthdate]: true
        }
      }));
    } else if (eventName === "partner_dob") {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [partner_dob]: date
        },
        dateValues: {
          ...formState.dateValues,
          [partner_dob]: date ? finalDob : null
        },
        touched: {
          ...formState.touched,
          [partner_dob]: true
        }
      }));
    }
    if (formState.errors.hasOwnProperty(eventName)) {
      delete formState.errors[eventName];
    }
  };

  const handleChangeAutocompleteForFranchise = (eventName, event, value) => {
    if (value !== null) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: value.id
        },
        touched: {
          ...formState.touched,
          [eventName]: true
        }
      }));

      getFranchiseSiteListData(value.id);

      if (formState.errors.hasOwnProperty(eventName)) {
        delete formState.errors[eventName];
      }
    } else {
      delete formState.values[eventName];
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: null
        }
      }));
      getFranchiseSiteListData(null);
    }
  };

  const handleChangeAutocompleteForState = (eventName, event, value) => {
    if (value !== null) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: value.text
        },
        touched: {
          ...formState.touched,
          [eventName]: true
        }
      }));
      if (formState.errors.hasOwnProperty(eventName)) {
        delete formState.errors[eventName];
      }
    } else {
      delete formState.values[eventName];
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: null
        }
      }));
    }
  };

  const makeEmployeerBackup = () => {
    setEmployeerBackup(employeerBackup => ({
      ...employeerBackup,
      occupation: formState.values.occupation,
      employer: formState.values.employer,
      employment_address: formState.values.employment_address,
      employment_phone: formState.values.employment_phone
    }));

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        occupation: null,
        employer: null,
        employment_address: null,
        employment_phone: null
      }
    }));
  };

  const restoreEmployeerBackup = () => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        occupation: employeerBackup.occupation,
        employer: employeerBackup.employer,
        employment_address: employeerBackup.employment_address,
        employment_phone: employeerBackup.employment_phone
      }
    }));
  };

  const makeSpouseBackup = () => {
    setSpouseInfoBackup(spouseInfoBackup => ({
      ...spouseInfoBackup,
      partner_name: formState.values[partner_name],
      partner_dob: formState.values[partner_dob],
      partner_employer: formState.values[partner_employer],
      partner_employer_phone: formState.values[partner_employer_phone],
      partner_emp_address: formState.values[partner_emp_address]
    }));

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        partner_name: "",
        partner_dob: null,
        partner_employer: "",
        partner_employer_phone: "",
        partner_emp_address: ""
      },
      dateValues: {
        ...formState.dateValues,
        [partner_dob]: null
      }
    }));
  };

  const restoreBackUpForSpouse = () => {
    var dob = new Date(spouseInfoBackup[partner_dob]);
    var finalDob = null;
    if (dob == "Invalid Date") {
      console.log("invalid date");
    } else {
      var month = dob.getMonth() + 1;
      var day = dob.getDate();
      var year = dob.getFullYear();
      finalDob =
        (day <= 9 ? "0" + day : day) +
        "/" +
        (month <= 9 ? "0" + month : month) +
        "/" +
        year;
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        partner_name: spouseInfoBackup[partner_name],
        partner_dob: spouseInfoBackup[partner_dob],
        partner_employer: spouseInfoBackup[partner_employer],
        partner_employer_phone: spouseInfoBackup[partner_employer_phone],
        partner_emp_address: spouseInfoBackup[partner_emp_address]
      },
      dateValues: {
        ...formState.dateValues,
        [partner_dob]: finalDob
      }
    }));
  };

  const handleChangeAutoComplete = (eventName, event, value) => {
    if (eventName === addTags) {
      formState.dataToShowForMultiSelect = value;
    }
    if (get(addClientForm[eventName], "type") === "multi-select") {
      let finalValues = [];
      let finalIds = [];
      for (let i in value) {
        finalValues.push(value[i]["name"]);
        finalIds.push(value[i]["id"]);
      }
      value = {
        id: finalValues,
        tpoId: finalIds
      };

      setFormState(formState => ({
        ...formState,
        tags: finalIds
      }));
    } else if (value !== null) {
      if (eventName === country) {
        delete formState.values[State];
      }
      if (value.name === countryTxt && eventName === country) {
        setShowStateDropDown(true);
      } else if (eventName === country) {
        setShowStateDropDown(false);
      }
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: value.id
        },
        touched: {
          ...formState.touched,
          [eventName]: true
        }
      }));

      if (eventName === isMarried) {
        if (value.name === "Married") {
          restoreBackUpForSpouse();
          setFormState(formState => ({
            ...formState,
            isMarried: value.name
          }));
        } else {
          makeSpouseBackup();
          setFormState(formState => ({
            ...formState,
            isMarried: false
          }));
        }
      }

      if (formState.errors.hasOwnProperty(eventName)) {
        delete formState.errors[eventName];
      }
    } else {
      if (eventName === isMarried) {
        makeSpouseBackup();
        setFormState(formState => ({
          ...formState,
          isMarried: false
        }));
      }
      delete formState.values[eventName];
    }
    if (value !== null) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: value.id
        },
        touched: {
          ...formState.touched,
          [eventName]: true
        }
      }));
      if (formState.errors.hasOwnProperty(eventName)) {
        delete formState.errors[eventName];
      }
    } else {
      delete formState.values[eventName];
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: null
        }
      }));
    }
  };

  const hasError = field => (formState.errors[field] ? true : false);

  const postClientData = async () => {
    const dataToSend = convertClientData();

    if (formState.editData === true) {
      providerForPut(
        updateClient + "/" + formState.editDataUserId + "/contact",
        formState.editDataContactId,
        dataToSend,
        auth,
        {
          desc: `${userInfo.role.name} ${userInfo.full_name} updated client ${
            formState.values.full_name ? formState.values.full_name : ""
          }`,
          audit_log_clientid: formState.editDataUserId
        }
      )
        .then(res => {
          let successMessage = {
            successMessage:
              "Client  " + formState.values[full_name] + UPDATE_MESSAGE
          };
          setLoading(false);

          if (propData && propData["fromAdmin"]) {
            history.push(USERS, successMessage);
          } else {
            history.push(MANAGECLIENTS, successMessage);
          }
        })
        .catch(err => {
          setLoading(false);
          setError(error => ({
            ...error,
            alert: true,
            severity: "error",
            errorMessage: ERROR_MESSAGE + err["response"]["data"]["data"]
          }));
        });
    } else if (formState.clientViewProfile) {
      providerForPut(
        updateClient + "/" + userInfo.id + "/contact",
        userInfo.contact.id,
        dataToSend,
        auth,
        {
          desc: `${userInfo.full_name} updated client ${formState.values.full_name}`
        }
      )
        .then(res => {
          let successMessage = "Profile " + UPDATE_MESSAGE;
          setError(error => ({
            ...error,
            alert: true,
            severity: "success",
            errorMessage: successMessage
          }));
          setLoading(false);

          //sending link to the user for setting up password
        })
        .catch(err => {
          console.log("err", err);
          setLoading(false);
          setError(error => ({
            ...error,
            alert: true,
            severity: "error",
            errorMessage: ERROR_MESSAGE + err["response"]["data"]["data"]
          }));
        });
    } else {
      await providerForPost(addClient, dataToSend, auth, {
        desc: `Nutritionist ${userInfo.full_name} added a new client ${formState.values.full_name}`
      })
        .then(res => {
          setLoading(false);
          let successMessage = {
            successMessage:
              "Client  " + formState.values[full_name] + " added successfully"
          };

          if (propData && propData["fromAdmin"]) {
            history.push(USERS, successMessage);
          } else {
            history.push(MANAGECLIENTS, successMessage);
          }
        })
        .catch(err => {
          setLoading(false);
          setError(error => ({
            ...error,
            alert: true,
            severity: "error",
            errorMessage: ERROR_MESSAGE + err["response"]["data"]["data"]
          }));
        });
    }
  };

  const postClientDataAndDuplicate = async () => {
    const dataToSend = convertClientData();
    await providerForPost(addClient, dataToSend, auth, {
      desc: `Nutritionist ${userInfo.full_name} added a new client ${formState.values.full_name}`
    })
      .then(res => {
        setLoading(false);
        duplicateSchedule(res.data.id);
      })
      .catch(err => {
        setLoading(false);
        setError(error => ({
          ...error,
          alert: true,
          severity: "error",
          errorMessage: ERROR_MESSAGE + err["response"]["data"]["data"]
        }));
      });
  };

  const duplicateSchedule = async id => {
    setLoading(true);

    let postData = {
      duplicateId: formState.duplicateId,
      newClientId: id
    };

    await providerForPost(duplicateScheduleData, postData, auth, {
      desc: `Nutritionist  ${userInfo.full_name} duplicated client with id ${formState.duplicateId} into client ${formState.values.full_name}`,
      audit_log_clientid: id
    })
      .then(res => {
        setLoading(false);
        console.log("res",res)
        if(res.data.c){
        history.push({
          pathname: BODYMASS,
          search: `?c=${res.data.c}&s=${res.data.s}`
        });}
        else{
          history.push({
          pathname: MANAGECLIENTS,
        });
        }
      })
      .catch(err => {});
  };

  const postClientDataAndCreateSchedule = async () => {
    const dataToSend = convertClientData();
    if (formState.editData === true) {
      providerForPut(
        updateClient + "/" + formState.editDataUserId + "/contact",
        formState.editDataContactId,
        dataToSend,
        auth,
        {
          desc: `Nutritionist ${userInfo.full_name} updated client '${formState.values.full_name}'`,
          audit_log_clientid: formState.editDataUserId
        }
      )
        .then(res => {
          setLoading(false);
          setFormState(formState => ({
            ...formState,
            clientId: res.data.id,
            clientName: res.data.full_name
          }));
          setMeals(meals => ({
            ...meals,
            dialogOpenClose: true
          }));
        })
        .catch(err => {
          console.log("err", err);
          setFormState(formState => ({
            ...formState,
            alert: true,
            severity: "error",
            errorMessage: ERROR_MESSAGE + err
          }));
          setLoading(false);
        });
    } else {
      await providerForPost(addClient, dataToSend, auth, {
        desc: `Nutritionist ${userInfo.full_name} added a new client ${formState.values.full_name}`
      })
        .then(res => {
          setLoading(false);
          setFormState(formState => ({
            ...formState,
            clientId: res.data.id,
            clientName: res.data.full_name
          }));
          setMeals(meals => ({
            ...meals,
            dialogOpenClose: true
          }));
        })
        .catch(err => {
          setLoading(false);
          setError(error => ({
            ...error,
            alert: true,
            severity: "error",
            errorMessage: ERROR_MESSAGE + err["response"]["data"]["data"]
          }));
        });
    }
  };

  /**
   * * This function basically converts the entered data in the format to
   * * send to backend
   * *
   */

  const convertClientData = () => {
    /**
     *  returns {
     * *  user, contact
     * * }
     */

    const userData = {
      email: formState.values.email,
      phone: formState.values.phone,
      franchise: formState.values.franchiseName,
      isOneTimeClient: formState.clientSwitch,
      full_name: formState.values.full_name,
      do_not_share: formState.values.do_not_share === true ? true : false,
      active: formState.values.active === true ? true : false,
      user_tags: formState.tags,
      is2FAEnabled: formState.values.is2FAEnabled ? true : false,
      franchise_site: formState.values.franchiseSite
    };

    /**
     * * TO check whether the key exist in formstate.values or send null directly
     */
    const contactData = {
      full_name: formState.values.full_name ? formState.values.full_name : null,
      address: formState.values.address ? formState.values.address : null,
      country: formState.values.country ? formState.values.country : null,
      State: formState.values.State ? formState.values.State : null,
      city: formState.values.city ? formState.values.city : null,
      zipcode: formState.values.zipcode ? formState.values.zipcode : null,
      birthdate: formState.dateValues.birthdate
        ? formState.dateValues.birthdate.split("/").reverse().join("-")
        : null,
      home_phone: formState.values.home_phone
        ? formState.values.home_phone
        : null,
      business_phone: formState.values.business_phone
        ? formState.values.business_phone
        : null,
      dl_number: formState.values.dl_number ? formState.values.dl_number : null,
      ss_number: formState.values.ss_number ? formState.values.ss_number : null,
      schooling: formState.values.schooling ? formState.values.schooling : null,
      gender: formState.values.gender ? formState.values.gender : null,
      isMarried: formState.values.isMarried ? formState.values.isMarried : null,

      isEmployed: formState.isEmployeed ? formState.isEmployeed : null,

      emergency_contact_name: formState.values.emergency_contact_name
        ? formState.values.emergency_contact_name
        : null,
      emergency_contact_relation: formState.values.emergency_contact_relation
        ? formState.values.emergency_contact_relation
        : null,
      emergency_contact_work_phone: formState.values
        .emergency_contact_work_phone
        ? formState.values.emergency_contact_work_phone
        : null,
      emergency_contact_home_phone: formState.values
        .emergency_contact_home_phone
        ? formState.values.emergency_contact_home_phone
        : null,
      notes: formState.values.notes ? formState.values.notes : "",
      phone: formState.values.phone ? formState.values.phone : null,
      clientReferredBy: formState.values.clientReferredBy
        ? formState.values.clientReferredBy
        : "",
      partner_name: formState.values.partner_name
        ? formState.values.partner_name
        : null,
      partner_dob: formState.dateValues.partner_dob
        ? formState.dateValues.partner_dob.split("/").reverse().join("-")
        : null,
      partner_employeer: formState.values.partner_employer
        ? formState.values.partner_employer
        : null,
      partner_employeer_phone: formState.values.partner_employer_phone
        ? formState.values.partner_employer_phone
        : null,
      partner_emp_address: formState.values.partner_emp_address
        ? formState.values.partner_emp_address
        : null
    };
    /**
     * * Added isEmployeed data if the client is actually employeed
     */
    contactData.occupation = formState.values.occupation
      ? formState.values.occupation
      : null;
    contactData.employeer = formState.values.employer
      ? formState.values.employer
      : null;
    contactData.employment_address = formState.values.employment_address
      ? formState.values.employment_address
      : null;
    contactData.employment_phone = formState.values.employment_phone
      ? formState.values.employment_phone
      : null;
    return {
      user: userData,
      contact: contactData
    };
  };

  const handleChangeSwitch = event => {
    if (event.target.name === "clientSwitch") {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values
        },
        [event.target.name]: event.target.checked
      }));
    } else {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: event.target.checked
        },
        [event.target.name]: event.target.checked
      }));
    }
  };
  /**
   * *Below function is to delete when we switch between one time client and regular client
   */
  const deleteNotRequiredValues = () => {
    delete formState.values[partner_name];
    delete formState.values[partner_dob];
    delete formState.values[partner_emp_address];
    delete formState.values[isMarried];
    delete formState.values[emergency_contact_name];
    delete formState.values[emergency_contact_relation];
    delete formState.values[emergency_contact_home_phone];
    delete formState.values[emergency_contact_work_phone];
    delete formState.values[occupation];
    delete formState.values[employer];
    delete formState.values[employment_address];
    delete formState.values[employment_phone];
    delete formState.isMarried;
    delete formState.isEmployeed;
  };

  const handleSubmit = async event => {
    setLoading(true);
    event.preventDefault();
    let isValid = false;
    /**
     ** If ClientSwitch is true then delete prev errors and non required fields
     */
    if (formState.clientSwitch) {
      deleteNotRequiredValues();
    }

    if (formState.clientSwitch && !formState.editData) {
      let checkAllFieldsValid = checkAllKeysPresent(
        formState.values,
        addClientForm
      );
      if (checkAllFieldsValid) {
        formState.errors = setErrors(formState.values, addClientForm);
        if (checkEmpty(formState.errors)) {
          isValid = true;
        }
      }
    } else {
      let checkAllFieldsValid = checkAllKeysPresent(
        formState.values,
        addClientForm
      );
      if (checkAllFieldsValid) {
        formState.errors = setErrors(formState.values, addClientForm);
        if (formState.isEmployeed === true) {
          await validate(
            formState.values[employment_phone],
            employment_phone,
            false
          );
        }
        if (formState.isMarried === "Married") {
          await validate(
            formState.values[partner_employer_phone],
            partner_employer_phone,
            false
          );
        }
        if (checkEmpty(formState.errors)) {
          isValid = true;
        }
        if (formState.values.email) {
          await providerForPost(
            checkEmailAvailable,
            {
              email: formState.values[clientEmail],
              c: formState.editData
                ? formState.editDataId
                : formState.clientViewProfile
                ? formState.clientProfileId
                : null
            },
            Auth.getToken()
          )
            .then(res => {
              setLoading(false);
              setFormState(formState => ({
                ...formState,
                isValid: true
              }));
            })
            .catch(err => {
              isValid = false;
              setLoading(false);
              setFormState(formState => ({
                ...formState,
                isValid: false,
                errors: {
                  ...formState.errors,
                  [clientEmail]: [err["response"]["data"]["data"]]
                }
              }));
            });
        }
      } else {
        formState.values = getListOfKeysNotPresent(
          formState.values,
          addClientForm
        );
        formState.errors = setErrors(formState.values, addClientForm);
      }
    }
    /**
     * * Below if is used to add validation when we change one time client to  regular client while editing
     */
    if (formState.editData && !formState.clientSwitch) {
      await validate(
        formState.values[emergency_contact_home_phone],
        emergency_contact_home_phone,
        false
      );
    }
    if (isValid) {
      postClientData();
      /** Call axios from here */
      setFormState(formState => ({
        ...formState,
        isValid: true
      }));
    } else {
      setLoading(false);
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
    }
  };

  const handleSubmitCreateSchedule = event => {
    setLoading(true);
    event.preventDefault();
    let isValid = false;
    if (formState.clientSwitch) {
      isValid = true;
    } else {
      let checkAllFieldsValid = checkAllKeysPresent(
        formState.values,
        addClientForm
      );
      if (checkAllFieldsValid) {
        formState.errors = setErrors(formState.values, addClientForm);
        if (checkEmpty(formState.errors)) {
          isValid = true;
        }
      } else {
        formState.values = getListOfKeysNotPresent(
          formState.values,
          addClientForm
        );
        formState.errors = setErrors(formState.values, addClientForm);
      }
    }
    if (isValid) {
      postClientDataAndCreateSchedule();
      /** Call axios from here */
      setFormState(formState => ({
        ...formState,
        isValid: true
      }));
    } else {
      setLoading(false);
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
    }
  };

  const handleDuplicate = async event => {
    setLoading(true);
    event.preventDefault();
    let isValid = false;

    if (formState.clientSwitch && !formState.editData) {
      isValid = true;
    } else {
      let checkAllFieldsValid = checkAllKeysPresent(
        formState.values,
        addClientForm
      );

      if (checkAllFieldsValid) {
        formState.errors = setErrors(formState.values, addClientForm);
        if (checkEmpty(formState.errors)) {
          isValid = true;
        }
      } else {
        formState.values = getListOfKeysNotPresent(
          formState.values,
          addClientForm
        );
        formState.errors = setErrors(formState.values, addClientForm);
      }
    }
    /**
     * * Below if is used to add validation when we change one time client to  regular client while editing
     */
    if (formState.editData && !formState.clientSwitch) {
      await validate(
        formState.values[emergency_contact_home_phone],
        emergency_contact_home_phone,
        false
      );
    }

    if (isValid) {
      if (formState.clientSwitch) {
        deleteNotRequiredValues();
      }
      postClientDataAndDuplicate();
      /** Call axios from here */
      setFormState(formState => ({
        ...formState,
        isValid: true
      }));
    } else {
      setLoading(false);
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
    }
  };

  const createSchedule = async () => {
    setLoading(true);
    setMeals(meals => ({
      ...meals,
      dialogOpenClose: false,
      helperText: "",
      isError: false
    }));
    await providerForPost(
      createScheduleApi,
      {
        c: formState.clientId,
        mealsPerDay: meals.noOfMeals
      },
      Auth.getToken(),
      {
        desc: "Nutritionist creates a new schedule for client",
        audit_log_clientid: formState.clientId
      }
    )
      .then(res => {
        setLoading(false);
        history.push({
          pathname: BODYMASS,
          search: `?c=${formState.clientId}&s=${res.data.id}`
        });
      })
      .catch(err => {
        setLoading(false);
        setError(error => ({
          ...error,
          alert: true,
          severity: "error",
          errorMessage: err["response"]["data"]["data"]
        }));
      });
  };

  const isStepMarriedOptional = step => {
    return step === 3;
  };
  const isStepEmployedOptional = step => {
    return step === 5;
  };

  const isStepAtPersonalWithErrorPresent = step => {
    return step === 0;
  };

  const personalCheck = () => {
    const PersonalKeys = PersonalSchemaRequiredKeys;
    const ErrorKeys = Object.keys(formState.errors);

    let returnStatus = "";
    for (let i = 0; i < PersonalKeys.length; i++) {
      for (let j = 0; j < ErrorKeys.length; j++) {
        if (PersonalKeys[i] === ErrorKeys[j]) {
          returnStatus = "ErrorInPersonal";
        }
      }
    }

    return returnStatus;
  };

  const handleSnackbarClose = () => {
    setError(error => ({
      ...error,
      alert: false,
      severity: "",
      errorMessage: ""
    }));
  };

  const handlePhoneNumberChange = (value, name) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value
      },
      touched: {
        ...formState.touched,
        [name]: true
      }
    }));
    delete formState.errors[name];
  };

  async function validate(val, name, required) {
    var code = "";
    var n = false;

    if (val) {
      if (!val.startsWith("+")) {
        val = "+" + val;
      }

      for (var i = 0; i < countries.length; i++) {
        n = val.includes(countries[i].code);
        if (n) {
          break;
        }
      }

      if (n) {
        code = countries[i].code;
      } else {
        if (required) {
          formState.errors[name] = ["Invalid mobile number"];
        }
      }
      try {
        const number = phoneUtil.parseAndKeepRawInput(val, code);
        let isValid = phoneUtil.isValidNumber(number);
        if (isValid) {
          if (val.startsWith("+")) {
            val = val.substring(1);
          }
          formState.values[name] = val;
        } else {
          formState.errors[name] = ["Invalid mobile number"];
        }
      } catch {
        if (required) {
          formState.errors[name] = ["Mobile number is required"];
        }
      }
    } else {
      if (required) {
        formState.errors[name] = ["Mobile number is required"];
      }
    }
  }

  const handleStep = step => () => {
    handleNext(true, step);
  };

  const handleBackArrow = () => {
    if (userInfo.role.name === process.env.REACT_APP_NUTRITIONIST_ROLE) {
      history.push({
        pathname: MANAGECLIENTS
      });
    } else {
      history.push({
        pathname: USERS
      });
    }
  };

  return (
    <div>
      <div>
        {!formState.clientViewProfile ? (
          <Tooltip
            title={
              formState.isBackAvailable ? formState.backName : "Back To Users"
            }
          >
            <IconButton
              style={{
                position: "relative",
                right: "120px"
              }}
              onClick={() => handleBackArrow()}
            >
              <ChevronLeftIcon
                style={{
                  padding: "5px",
                  border: "solid #DADADA 1px",
                  borderRadius: "15px",
                  margin: "0px 20px 0px 5px",
                  background: "#fff",
                  marginTop: 0
                }}
              />
            </IconButton>
          </Tooltip>
        ) : null}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {formState.editData ? (
              <HeadingOne>Edit Client</HeadingOne>
            ) : formState.clientViewProfile ? (
              <HeadingOne>My Profile </HeadingOne>
            ) : (
              <HeadingOne>Add New Client</HeadingOne>
            )}
          </Grid>
        </Grid>

        {!formState.clientViewProfile && (
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flex: "wrap",
                justifyContent: "flex-end"
              }}
            >
              <Box className={classes.switchBtnTxt}>Regular Client</Box>
              <Box>
                <PurpleSwitch
                  checked={formState.clientSwitch}
                  onChange={handleChangeSwitch}
                  name={"clientSwitch"}
                />
              </Box>
              <Box className={classes.switchBtnTxt}>One Time Client</Box>
            </Grid>
          </Grid>
        )}

        <Grid>
          <SnackBar
            open={error.alert}
            severity={error.severity}
            onClose={handleSnackbarClose}
          >
            {error.errorMessage}
          </SnackBar>
        </Grid>
        <Stepper activeStep={activeStep} className={styles.addClientStepper}>
          {steps.map((label, index) => {
            const labelProps = {};
            if (formState.isMarried !== "Married" && activeStep === index) {
              if (isStepMarriedOptional(index) && !formState.clientSwitch) {
                labelProps.optional = (
                  <>
                    <Typography variant="caption" color="info">
                      Skip this form as per client Relationship status
                    </Typography>
                  </>
                );
              }
            }
            if (!formState.isEmployeed && activeStep === index) {
              if (isStepEmployedOptional(index)) {
                labelProps.optional = (
                  <>
                    <Typography variant="caption" color="warning">
                      Skip this form as per client Employment status
                    </Typography>
                  </>
                );
              }
            }
            /**
             * * Below this used to check errors if present when we click on save from any page while editing ot when we switch between regular client and one time client
             */
            if (formState.errors) {
              const tempPersonalErr = personalCheck();
              if (tempPersonalErr === "ErrorInPersonal") {
                if (isStepAtPersonalWithErrorPresent(index)) {
                  labelProps.optional = (
                    <Typography variant="caption" color="error">
                      To save the details, please fill in all correct and
                      required fields.
                    </Typography>
                  );
                  labelProps.error = true;
                }
              }
            }

            return (
              <Step
                key={label}
                classes={{
                  root: classes.step,
                  completed: classes.completed,
                  active: classes.active
                }}
              >
                <StepLabel
                  onClick={
                    formState.editData || formState.clientViewProfile
                      ? handleStep(index)
                      : null
                  }
                  {...labelProps}
                  classes={{
                    alternativeLabel: classes.alternativeLabel,
                    active: !labelProps.error && classes.labelActive,
                    label: classes.label,
                    caption: classes.caption
                  }}
                  StepIconProps={{
                    classes: {
                      root: !labelProps.error && classes.step,
                      completed: classes.completed,
                      active: !labelProps.error && classes.active,
                      disabled: classes.disabled,
                      text: classes.text
                    }
                  }}
                  Ste
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          <Grid container>
            {activeStep === 0 ? (
              /**
               *  Personal Info
               */
              <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                  <Input
                    label={get(addClientForm[full_name], "label")}
                    name={full_name}
                    value={formState.values[full_name] || ""}
                    error={hasError(full_name)}
                    placeholder={get(addClientForm[full_name], "placeholder")}
                    required
                    onChange={handleChange}
                    helperText={
                      hasError(full_name)
                        ? formState.errors[full_name].map(error => {
                            return error + " ";
                          })
                        : null
                    }
                    className={styles.elementroot}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <InlineDatePicker
                    label="Birthdate"
                    id="birthdate"
                    placeholder="MM/DD/YYYY"
                    InputLabelProps={{ shrink: true }}
                    maxDate={new Date()}
                    value={formState.values[birthdate] || null}
                    onChange={value => {
                      handleDateChange(value, "birthdate");
                    }}
                    error={hasError(birthdate)}
                    required
                    fullWidth
                    helperText={
                      hasError(birthdate)
                        ? formState.errors[birthdate].map(error => {
                            return error + " ";
                          })
                        : null
                    }
                    disabled={formState.clientViewProfile ? true : false}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <AutoCompleteDropDown
                    options={genderList}
                    getOptionLabel={option => option.name}
                    id={get(addClientForm[gender], "id")}
                    value={
                      genderList[
                        genderList.findIndex(function (item, i) {
                          return item.id === formState.values[gender];
                        })
                      ] || null
                    }
                    onChange={(event, value) => {
                      handleChangeAutoComplete(gender, event, value);
                    }}
                    disabled={formState.clientViewProfile ? true : false}
                    renderInput={params => (
                      <Input
                        {...params}
                        required
                        id={get(addClientForm[gender], "id")}
                        label={get(addClientForm[gender], "label")}
                        error={hasError(gender)}
                        helperText={
                          hasError(gender)
                            ? formState.errors[gender].map(error => {
                                return error + " ";
                              })
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                {formState.clientSwitch ? null : (
                  <Grid item md={4} xs={12}>
                    <AutoCompleteDropDown
                      options={schoolingList}
                      getOptionLabel={option => option.name}
                      id={get(addClientForm[schooling], "id")}
                      value={
                        schoolingList[
                          schoolingList.findIndex(function (item, i) {
                            return item.id === formState.values[schooling];
                          })
                        ] || null
                      }
                      onChange={(event, value) => {
                        handleChangeAutoComplete(schooling, event, value);
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          id={get(addClientForm[schooling], "id")}
                          label={get(addClientForm[schooling], "label")}
                          error={hasError(schooling)}
                          helperText={
                            hasError(schooling)
                              ? formState.errors[schooling].map(error => {
                                  return error + " ";
                                })
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                )}
                {formState.clientSwitch ? null : (
                  <Grid item md={4} xs={12}>
                    <AutoCompleteDropDown
                      options={statusList}
                      getOptionLabel={option => option.name}
                      id={get(addClientForm[isMarried], "id")}
                      value={
                        statusList[
                          statusList.findIndex(function (item, i) {
                            return item.id === formState.values[isMarried];
                          })
                        ] || null
                      }
                      onChange={(event, value) => {
                        handleChangeAutoComplete(isMarried, event, value);
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          id={get(addClientForm[isMarried], "id")}
                          label={get(addClientForm[isMarried], "label")}
                          error={hasError(isMarried)}
                          helperText={
                            hasError(isMarried)
                              ? formState.errors[isMarried].map(error => {
                                  return error + " ";
                                })
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                )}
                {formState.clientSwitch ? null : (
                  <Grid item md={4} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.isEmployeed}
                          onChange={handleChange}
                          name="isEmployed"
                          color="primary"
                          className={Styles.ReactCheckbox}
                        />
                      }
                      label={"Employed?"}
                    />
                  </Grid>
                )}
                <Grid item md={4} xs={12} className={Styles.ReactPhoneInput}>
                  <PhoneInput
                    country={"us"}
                    value={formState.values[phone] || ""}
                    onChange={phone => handlePhoneNumberChange(phone, "phone")}
                    placeholder={"Add Mobile Number"}
                    specialLabel="Phone"
                    inputProps={{
                      name: "phone",
                      style: {
                        width: "100%",
                        border: hasError(phone) ? "1px solid red" : null
                      }
                    }}
                  />
                  <FormHelperText
                    type="error"
                    visible={hasError(phone)}
                    style={{
                      "background-color": "White",
                      color: "red"
                    }}
                  >
                    {formState.errors[phone]}
                  </FormHelperText>
                </Grid>
                {formState.clientSwitch ? null : (
                  <>
                    <Grid
                      item
                      md={4}
                      xs={12}
                      className={Styles.ReactPhoneInput}
                    >
                      <PhoneInput
                        country={"us"}
                        value={formState.values[home_phone] || ""}
                        onChange={phone =>
                          handlePhoneNumberChange(phone, home_phone)
                        }
                        specialLabel="Home Phone"
                        placeholder={"Add Home Phone "}
                        inputProps={{
                          name: "home_phone",
                          style: {
                            width: "100%",
                            border: hasError(home_phone)
                              ? "1px solid red"
                              : null
                          }
                        }}
                      />
                      <FormHelperText
                        type="error"
                        visible={hasError(home_phone)}
                        style={{
                          "background-color": "White",
                          color: "red"
                        }}
                      >
                        {formState.errors[home_phone]}
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                      className={Styles.ReactPhoneInput}
                    >
                      <PhoneInput
                        country={"us"}
                        value={formState.values[business_phone] || ""}
                        onChange={phone =>
                          handlePhoneNumberChange(phone, business_phone)
                        }
                        specialLabel="Business Phone"
                        placeholder={"Add Business Phone "}
                        inputProps={{
                          name: "business_phone",
                          style: {
                            width: "100%",
                            border: hasError(business_phone)
                              ? "1px solid red"
                              : null
                          }
                        }}
                      />
                      <FormHelperText
                        type="error"
                        visible={hasError(business_phone)}
                        style={{
                          "background-color": "White",
                          color: "red"
                        }}
                      >
                        {formState.errors[business_phone]}
                      </FormHelperText>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Input
                        label={get(addClientForm[dl_number], "label")}
                        name={dl_number}
                        value={formState.values[dl_number] || ""}
                        placeholder={get(
                          addClientForm[dl_number],
                          "placeholder"
                        )}
                        type={get(addClientForm[dl_number], "type")}
                        fullWidth
                        onChange={handleChange}
                        className={styles.elementroot}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Input
                        label={get(addClientForm[ss_number], "label")}
                        name={ss_number}
                        value={formState.values[ss_number] || ""}
                        placeholder={get(
                          addClientForm[ss_number],
                          "placeholder"
                        )}
                        type={get(addClientForm[ss_number], "type")}
                        fullWidth
                        onChange={handleChange}
                        className={styles.elementroot}
                        error={hasError(ss_number)}
                        helperText={
                          hasError(ss_number)
                            ? formState.errors[ss_number].map(error => {
                                return error + " ";
                              })
                            : null
                        }
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <Input
                        label={get(addClientForm[clientReferredBy], "label")}
                        name={clientReferredBy}
                        value={formState.values[clientReferredBy] || ""}
                        placeholder={get(
                          addClientForm[clientReferredBy],
                          "placeholder"
                        )}
                        type={get(addClientForm[clientReferredBy], "type")}
                        fullWidth
                        onChange={handleChange}
                        className={styles.elementroot}
                      />
                    </Grid>
                  </>
                )}

                <Grid item md={4} xs={12}>
                  <Input
                    label={get(addClientForm[clientEmail], "label")}
                    name={clientEmail}
                    value={formState.values[clientEmail] || ""}
                    error={hasError(clientEmail)}
                    placeholder={get(addClientForm[clientEmail], "placeholder")}
                    required
                    fullWidth
                    onChange={handleChange}
                    helperText={
                      hasError(clientEmail)
                        ? formState.errors[clientEmail].map(error => {
                            return error + " ";
                          })
                        : null
                    }
                    disabled={formState.clientViewProfile ? true : false}
                    className={styles.elementroot}
                  />
                </Grid>
              </Grid>
            ) : activeStep === 1 ? (
              /**
               *  Residence Info
               */
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Input
                    label={get(addClientForm[address], "label")}
                    name={address}
                    value={formState.values[address] || ""}
                    error={hasError(address)}
                    placeholder={get(addClientForm[address], "placeholder")}
                    rows={2}
                    fullWidth
                    multiline
                    onChange={handleChange}
                    helperText={
                      hasError(address)
                        ? formState.errors[address].map(error => {
                            return error + " ";
                          })
                        : null
                    }
                    className={styles.elementroot}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <AutoCompleteDropDown
                    options={countryList}
                    getOptionLabel={option => option.name}
                    id={get(addClientForm[country], "id")}
                    value={
                      countryList[
                        countryList.findIndex(function (item, i) {
                          return item.id === formState.values[country];
                        })
                      ] || null
                    }
                    onChange={(event, value) => {
                      handleChangeAutoComplete(country, event, value);
                    }}
                    renderInput={params => (
                      <Input
                        {...params}
                        id={get(addClientForm[country], "id")}
                        label={get(addClientForm[country], "label")}
                        error={hasError(country)}
                        helperText={
                          hasError(country)
                            ? formState.errors[country].map(error => {
                                return error + " ";
                              })
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <Input
                    label={get(addClientForm[city], "label")}
                    name={city}
                    value={formState.values[city] || ""}
                    error={hasError(city)}
                    placeholder={get(addClientForm[city], "placeholder")}
                    fullWidth
                    onChange={handleChange}
                    helperText={
                      hasError(city)
                        ? formState.errors[city].map(error => {
                            return error + " ";
                          })
                        : null
                    }
                    className={styles.elementroot}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  {showStateDropDown ? (
                    <AutoCompleteDropDown
                      options={usaState}
                      getOptionLabel={option => option.text}
                      id={get(addClientForm[State], "id")}
                      value={
                        usaState[
                          usaState.findIndex(function (item, i) {
                            return item.text === formState.values[State];
                          })
                        ] || null
                      }
                      onChange={(event, value) => {
                        handleChangeAutocompleteForState(State, event, value);
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          id={get(addClientForm[State], "id")}
                          label={get(addClientForm[State], "label")}
                          variant="outlined"
                        />
                      )}
                    />
                  ) : (
                    <Input
                      label={get(addClientForm[State], "label")}
                      name={State}
                      value={formState.values[State] || ""}
                      error={hasError(State)}
                      placeholder={get(addClientForm[State], "placeholder")}
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(State)
                          ? formState.errors[State].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  )}
                </Grid>
                <Grid item md={3} xs={12}>
                  <Input
                    label={get(addClientForm[zipcode], "label")}
                    name={zipcode}
                    value={formState.values[zipcode] || ""}
                    error={hasError(zipcode)}
                    placeholder={get(addClientForm[zipcode], "placeholder")}
                    type={get(addClientForm[zipcode], "type")}
                    fullWidth
                    onChange={handleChange}
                    helperText={
                      hasError(zipcode)
                        ? formState.errors[zipcode].map(error => {
                            return error + " ";
                          })
                        : null
                    }
                    className={styles.elementroot}
                  />
                </Grid>
              </Grid>
            ) : activeStep === 2 ? (
              /**
               *  Tags
               */
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <AutoCompleteDropDown
                    options={addTagList}
                    getOptionLabel={option => option.name}
                    id={get(addClientForm[addTags], "id")}
                    value={formState.dataToShowForMultiSelect || null}
                    multiple={true}
                    onChange={(event, value) => {
                      handleChangeAutoComplete(addTags, event, value);
                    }}
                    disabled={formState.clientViewProfile ? true : false}
                    renderInput={params => (
                      <Input
                        {...params}
                        id={get(addClientForm[addTags], "id")}
                        label={get(addClientForm[addTags], "label")}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : activeStep === 3 ? (
              /**
               * * Notes info for one time client
               */
              formState.clientSwitch ? (
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Input
                      label={get(addClientForm[notes], "label")}
                      name={notes}
                      value={formState.values[notes] || ""}
                      placeholder={get(addClientForm[notes], "placeholder")}
                      multiline
                      rows={4}
                      InputProps={{
                        readOnly: formState.clientViewProfile
                      }}
                      fullWidth
                      onChange={handleChange}
                      className={styles.elementroot}
                    />
                  </Grid>
                </Grid>
              ) : (
                /**
                 * * Spouse Info
                 */
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addClientForm[partner_name], "label")}
                      name={partner_name}
                      value={formState.values[partner_name] || ""}
                      error={hasError(partner_name)}
                      placeholder={get(
                        addClientForm[partner_name],
                        "placeholder"
                      )}
                      required={get(addClientForm[partner_name], "required")}
                      fullWidth
                      disabled={formState.isMarried !== "Married"}
                      onChange={handleChange}
                      helperText={
                        hasError(partner_name)
                          ? formState.errors[partner_name].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <InlineDatePicker
                      label={get(addClientForm[partner_dob], "label")}
                      id="partnerbirthdate"
                      placeholder="MM/DD/YYYY"
                      InputLabelProps={{ shrink: true }}
                      maxDate={new Date()}
                      value={formState.values.partner_dob || null}
                      disabled={formState.isMarried !== "Married"}
                      onChange={value => {
                        handleDateChange(value, "partner_dob");
                      }}
                      error={hasError(partner_dob)}
                      fullWidth
                      helperText={
                        hasError(partner_dob)
                          ? formState.errors[partner_dob].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addClientForm[partner_employer], "label")}
                      name={partner_employer}
                      value={formState.values[partner_employer] || ""}
                      placeholder={get(
                        addClientForm[partner_employer],
                        "placeholder"
                      )}
                      disabled={formState.isMarried !== "Married"}
                      fullWidth
                      onChange={handleChange}
                      className={styles.elementroot}
                      error={hasError(partner_employer)}
                      helperText={
                        hasError(partner_employer)
                          ? formState.errors[partner_employer].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <PhoneInput
                      disabled={formState.isMarried !== "Married"}
                      country={"us"}
                      value={formState.values[partner_employer_phone] || ""}
                      onChange={phone =>
                        handlePhoneNumberChange(phone, partner_employer_phone)
                      }
                      specialLabel="Partner Emp. Phone*"
                      placeholder="Add Partner Emp. Phone*"
                      inputProps={{
                        name: partner_employer_phone,
                        required: false,
                        label: "Add Partner Emp. Phone*",
                        placeholder: "Add Partner Emp. Phone*",
                        style: {
                          width: "100%",
                          border: hasError(partner_employer_phone)
                            ? "1px solid red"
                            : null
                        }
                      }}
                    />

                    <FormHelperText
                      type="error"
                      visible={hasError(partner_employer_phone)}
                      style={{
                        "background-color": "White",
                        color: "red"
                      }}
                    >
                      {formState.errors[partner_employer_phone]}
                    </FormHelperText>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Input
                      label={get(addClientForm[partner_emp_address], "label")}
                      name={partner_emp_address}
                      value={formState.values[partner_emp_address] || ""}
                      placeholder={get(
                        addClientForm[partner_emp_address],
                        "placeholder"
                      )}
                      disabled={formState.isMarried !== "Married"}
                      fullWidth
                      onChange={handleChange}
                      className={styles.elementroot}
                    />
                  </Grid>
                </Grid>
              )
            ) : activeStep === 4 ? (
              formState.clientSwitch ? (
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <AutoCompleteDropDown
                      disabled={
                        userInfo.role.name ===
                        process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
                          ? false
                          : true
                      }
                      options={formState.franchiseList}
                      getOptionLabel={option => option.franchise_name}
                      id={get(addClientForm[franchiseName], "id")}
                      value={
                        formState.franchiseList[
                          formState.franchiseList.findIndex(function (item, i) {
                            return item.id === formState.values[franchiseName];
                          })
                        ] || null
                      }
                      onChange={(event, value) => {
                        handleChangeAutocompleteForFranchise(
                          franchiseName,
                          event,
                          value
                        );
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          id={get(addClientForm[franchiseName], "id")}
                          label={get(addClientForm[franchiseName], "label")}
                          variant="outlined"
                          error={hasError(franchiseName)}
                          helperText={
                            hasError(franchiseName)
                              ? formState.errors[franchiseName].map(error => {
                                  return error + " ";
                                })
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AutoCompleteDropDown
                      disabled={
                        userInfo.role.name ===
                        process.env.REACT_APP_CLIENT_ROLE_NAME
                          ? true
                          : false
                      }
                      options={formState.franchiseSitesList}
                      getOptionLabel={option => option.name}
                      id={get(addClientForm[franchiseSite], "id")}
                      value={
                        formState.franchiseSitesList[
                          formState.franchiseSitesList.findIndex(function (
                            item,
                            i
                          ) {
                            return item.id === formState.values[franchiseSite];
                          })
                        ] || null
                      }
                      onChange={(event, value) => {
                        handleChangeAutoComplete(franchiseSite, event, value);
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          id={get(addClientForm[franchiseSite], "id")}
                          label={get(addClientForm[franchiseSite], "label")}
                          variant="outlined"
                          error={hasError(franchiseSite)}
                          helperText={
                            hasError(franchiseSite)
                              ? formState.errors[franchiseSite].map(error => {
                                  return error + " ";
                                })
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  {propData && propData["fromAdmin"] ? (
                    <>
                      <Grid item md={2} xs={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <PurpleSwitch
                                checked={
                                  formState.values.do_not_share
                                    ? formState.values.do_not_share
                                    : false
                                }
                                onChange={handleChangeSwitch}
                                name={do_not_share}
                              />
                            }
                            label={"Do not Share"}
                          />
                        </FormGroup>
                      </Grid>
                      {formState.clientSwitch ? null : (
                        <Grid item md={2} xs={12}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <PurpleSwitch
                                  checked={
                                    formState.values.active
                                      ? formState.values.active
                                      : false
                                  }
                                  onChange={handleChangeSwitch}
                                  name={active}
                                />
                              }
                              label={"Active"}
                            />
                          </FormGroup>
                        </Grid>
                      )}
                    </>
                  ) : null}
                </Grid>
              ) : (
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(
                        addClientForm[emergency_contact_name],
                        "label"
                      )}
                      name={emergency_contact_name}
                      value={formState.values[emergency_contact_name] || ""}
                      error={hasError(emergency_contact_name)}
                      placeholder={get(
                        addClientForm[emergency_contact_name],
                        "placeholder"
                      )}
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(emergency_contact_name)
                          ? formState.errors[emergency_contact_name].map(
                              error => {
                                return error + " ";
                              }
                            )
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AutoCompleteDropDown
                      options={relation}
                      getOptionLabel={option => option.name}
                      id={get(addClientForm[emergency_contact_relation], "id")}
                      value={
                        relation[
                          relation.findIndex(function (item, i) {
                            return (
                              item.id ===
                              formState.values[emergency_contact_relation]
                            );
                          })
                        ] || null
                      }
                      onChange={(event, value) => {
                        handleChangeAutoComplete(
                          emergency_contact_relation,
                          event,
                          value
                        );
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          id={get(
                            addClientForm[emergency_contact_relation],
                            "id"
                          )}
                          label={get(
                            addClientForm[emergency_contact_relation],
                            "label"
                          )}
                          error={hasError(emergency_contact_relation)}
                          helperText={
                            hasError(emergency_contact_relation)
                              ? formState.errors[
                                  emergency_contact_relation
                                ].map(error => {
                                  return error + " ";
                                })
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={Styles.ReactPhoneInput}>
                    <PhoneInput
                      country={"us"}
                      value={
                        formState.values[emergency_contact_home_phone] || ""
                      }
                      onChange={phone =>
                        handlePhoneNumberChange(
                          phone,
                          emergency_contact_home_phone
                        )
                      }
                      specialLabel="Phone*"
                      placeholder="Add Phone Number"
                      inputProps={{
                        name: "emergency_contact_home_phone",
                        label: "Add Phone Number",
                        placeholder: "Add Phone Number",
                        style: {
                          width: "100%",
                          border: hasError(emergency_contact_home_phone)
                            ? "1px solid red"
                            : null
                        }
                      }}
                    />

                    <FormHelperText
                      type="error"
                      visible={hasError(emergency_contact_home_phone)}
                      style={{
                        "background-color": "White",
                        color: "red"
                      }}
                    >
                      {formState.errors[emergency_contact_home_phone]}
                    </FormHelperText>
                  </Grid>
                  <Grid item md={6} xs={12} className={Styles.ReactPhoneInput}>
                    <PhoneInput
                      country={"us"}
                      value={
                        formState.values[emergency_contact_work_phone] || ""
                      }
                      onChange={phone =>
                        handlePhoneNumberChange(
                          phone,
                          emergency_contact_work_phone
                        )
                      }
                      specialLabel="Work Phone"
                      placeholder="Add Work Phone"
                      inputProps={{
                        name: "emergency_contact_work_phone",
                        label: "Add Work Phone",
                        placeholder: "Add Work Phone",
                        style: {
                          width: "100%",
                          border: hasError(emergency_contact_work_phone)
                            ? "1px solid red"
                            : null
                        }
                      }}
                    />
                    <FormHelperText
                      type="error"
                      visible={hasError(emergency_contact_work_phone)}
                      style={{
                        "background-color": "White",
                        color: "red"
                      }}
                    >
                      {formState.errors[emergency_contact_work_phone]}
                    </FormHelperText>
                  </Grid>
                </Grid>
              )
            ) : activeStep === 5 ? (
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Input
                    label={get(addClientForm[occupation], "label")}
                    name={occupation}
                    value={formState.values[occupation] || ""}
                    error={hasError(occupation)}
                    placeholder={get(addClientForm[occupation], "placeholder")}
                    fullWidth
                    disabled={!formState.isEmployeed}
                    onChange={handleChange}
                    helperText={
                      hasError(occupation)
                        ? formState.errors[occupation].map(error => {
                            return error + " ";
                          })
                        : null
                    }
                    className={styles.elementroot}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Input
                    label={get(addClientForm[employer], "label")}
                    name={employer}
                    value={formState.values[employer] || ""}
                    error={hasError(employer)}
                    placeholder={get(addClientForm[employer], "placeholder")}
                    fullWidth
                    disabled={!formState.isEmployeed}
                    onChange={handleChange}
                    helperText={
                      hasError(employer)
                        ? formState.errors[employer].map(error => {
                            return error + " ";
                          })
                        : null
                    }
                    className={styles.elementroot}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Input
                    label={get(addClientForm[employment_address], "label")}
                    name={employment_address}
                    value={formState.values[employment_address] || ""}
                    error={hasError(employment_address)}
                    placeholder={get(
                      addClientForm[employment_address],
                      "placeholder"
                    )}
                    fullWidth
                    disabled={!formState.isEmployeed}
                    onChange={handleChange}
                    helperText={
                      hasError(employment_address)
                        ? formState.errors[employment_address].map(error => {
                            return error + " ";
                          })
                        : null
                    }
                    className={styles.elementroot}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <PhoneInput
                    country={"us"}
                    value={formState.values[employment_phone] || ""}
                    onChange={phone =>
                      handlePhoneNumberChange(phone, employment_phone)
                    }
                    disabled={!formState.isEmployeed}
                    specialLabel="Employer Phone"
                    placeholder={"Add Employer Phone "}
                    inputProps={{
                      name: "employment_phone",
                      style: {
                        width: "100%",
                        border: hasError(employment_phone)
                          ? "1px solid red"
                          : null
                      }
                    }}
                  />
                  <FormHelperText
                    type="error"
                    visible={hasError(employment_phone)}
                    style={{
                      "background-color": "White",
                      color: "red"
                    }}
                  >
                    {formState.errors[employment_phone]}
                  </FormHelperText>
                </Grid>
              </Grid>
            ) : activeStep === 6 ? (
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <Input
                    label={get(addClientForm[notes], "label")}
                    name={notes}
                    value={formState.values[notes] || ""}
                    placeholder={get(addClientForm[notes], "placeholder")}
                    multiline
                    rows={4}
                    InputProps={{
                      readOnly: formState.clientViewProfile
                    }}
                    fullWidth
                    onChange={handleChange}
                    className={styles.elementroot}
                  />
                </Grid>
              </Grid>
            ) : activeStep === 7 ? (
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <AutoCompleteDropDown
                    disabled={
                      userInfo.role.name ===
                      process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
                        ? false
                        : true
                    }
                    options={formState.franchiseList}
                    getOptionLabel={option => option.franchise_name}
                    id={get(addClientForm[franchiseName], "id")}
                    value={
                      formState.franchiseList[
                        formState.franchiseList.findIndex(function (item, i) {
                          return item.id === formState.values[franchiseName];
                        })
                      ] || null
                    }
                    onChange={(event, value) => {
                      handleChangeAutocompleteForFranchise(
                        franchiseName,
                        event,
                        value
                      );
                    }}
                    renderInput={params => (
                      <Input
                        {...params}
                        id={get(addClientForm[franchiseName], "id")}
                        label={get(addClientForm[franchiseName], "label")}
                        variant="outlined"
                        error={hasError(franchiseName)}
                        helperText={
                          hasError(franchiseName)
                            ? formState.errors[franchiseName].map(error => {
                                return error + " ";
                              })
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <AutoCompleteDropDown
                    disabled={
                      userInfo.role.name ===
                      process.env.REACT_APP_CLIENT_ROLE_NAME
                        ? true
                        : false
                    }
                    options={formState.franchiseSitesList}
                    getOptionLabel={option => option.name}
                    id={get(addClientForm[franchiseSite], "id")}
                    value={
                      formState.franchiseSitesList[
                        formState.franchiseSitesList.findIndex(function (
                          item,
                          i
                        ) {
                          return item.id === formState.values[franchiseSite];
                        })
                      ] || null
                    }
                    onChange={(event, value) => {
                      handleChangeAutoComplete(franchiseSite, event, value);
                    }}
                    renderInput={params => (
                      <Input
                        {...params}
                        id={get(addClientForm[franchiseSite], "id")}
                        label={get(addClientForm[franchiseSite], "label")}
                        variant="outlined"
                        error={hasError(franchiseSite)}
                        helperText={
                          hasError(franchiseSite)
                            ? formState.errors[franchiseSite].map(error => {
                                return error + " ";
                              })
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
                {propData && propData["fromAdmin"] ? (
                  <>
                    <Grid item md={2} xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <PurpleSwitch
                              checked={
                                formState.values.do_not_share
                                  ? formState.values.do_not_share
                                  : false
                              }
                              onChange={handleChangeSwitch}
                              name={do_not_share}
                            />
                          }
                          label={"Do not Share"}
                        />
                      </FormGroup>
                    </Grid>
                    {formState.editData ? (
                      <Grid item md={2} xs={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <PurpleSwitch
                                checked={
                                  formState.values.active
                                    ? formState.values.active
                                    : false
                                }
                                onChange={handleChangeSwitch}
                                name={active}
                              />
                            }
                            label={"Active"}
                          />
                        </FormGroup>
                      </Grid>
                    ) : null}
                    <Grid item md={2} xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <PurpleSwitch
                              checked={
                                formState.values.is2FAEnabled
                                  ? formState.values.is2FAEnabled
                                  : false
                              }
                              onChange={handleChangeSwitch}
                              name={is2FAEnabled}
                            />
                          }
                          label={"2FA"}
                        />
                      </FormGroup>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            ) : null}

            <Grid item xs={12} className={classes.nextbackbutton}>
              {!formState.clientViewProfile && (
                <DarkBlueButton
                  onClick={handleCancel}
                  type="button"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  style={{ marginLeft: 15 }}
                >
                  Cancel
                </DarkBlueButton>
              )}
              {activeStep === steps.length - 1 ? (
                <>
                  <BorderLessButton
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    style={{ marginLeft: 15 }}
                    className={classes.backButton}
                  >
                    Back
                  </BorderLessButton>
                  {formState.isDuplicate ? (
                    <DarkBlueButton
                      onClick={handleDuplicate}
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={loading}
                      style={{ marginLeft: 15 }}
                    >
                      Save & Duplicate
                    </DarkBlueButton>
                  ) : (
                    <>
                      <DarkBlueButton
                        onClick={handleSubmit}
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={loading}
                        style={{ marginLeft: 15 }}
                      >
                        Save
                      </DarkBlueButton>
                      {formState.clientViewProfile ||
                      formState.fromAdmin ? null : (
                        <DarkBlueButton
                          onClick={handleSubmitCreateSchedule}
                          type="submit"
                          color="primary"
                          variant="contained"
                          disabled={loading}
                          style={{ marginLeft: 15 }}
                        >
                          Save & Create Schedule
                        </DarkBlueButton>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <BorderLessButton
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </BorderLessButton>
                  <DarkBlueButton
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    style={{ marginLeft: 15 }}
                  >
                    {"Next"}
                  </DarkBlueButton>

                  {formState.editData && (
                    <DarkBlueButton
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      style={{ marginLeft: 15 }}
                    >
                      {"Save"}
                    </DarkBlueButton>
                  )}
                  {formState.clientViewProfile && (
                    <DarkBlueButton
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      style={{ marginLeft: 15 }}
                    >
                      {"Save"}
                    </DarkBlueButton>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogForGettingMealNumbers
        open={meals.dialogOpenClose}
        handleClose={handleCloseGetNoOfMealsDialogOpen}
        createSchedule={createSchedule}
        textbox={{
          error: meals.isError,
          handleChange: handleChangeNoOfMealsPerDay,
          id: "no_of_meals_per_day",
          helperText: meals.helperText,
          value: meals.noOfMeals
        }}
      />
    </div>
  );
};

AddEditClient.propTypes = {};

AddEditClient.defaultProps = {};

export default AddEditClient;
