import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const localStyles = makeStyles(theme => ({
  grayButtonStyle: {
    backgroundColor: "#EDEDF2",
    color: "#545470",
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "0.875rem",
    borderRadius: 25,
    textTransform: "none",
    height: 22
  }
}));

export default function SmallGrayButton(props) {
  const classes = localStyles();

  return (
    <Button
      className={classes.grayButtonStyle}
      variant="contained"
      onClick={props.onClick}
      style={props.style}
      {...props}
    >
      {props.children}
    </Button>
  );
}
