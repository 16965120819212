// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ForgotPassword_loginAppBar__pDKWc {\n  background: rgb(244, 248, 255) !important;\n  box-shadow: 0px 3px 5px #e8eef7 !important;\n}\n\n.ForgotPassword_loginToolbar__nti5y {\n  padding: 0px 25px;\n}\n\n.ForgotPassword_loginLogo__2LFZV {\n  max-width: 178px;\n  margin: 0px 5px;\n  max-height: 50px;\n}\n\n.ForgotPassword_loginCardContent__1QA85 {\n  padding: 60px !important;\n  background-color: rgb(244 248 255) !important;\n  border: 1px solid #1c4979 !important;\n  border-radius: 4px !important;\n}\n", "",{"version":3,"sources":["webpack://src/containers/AccountRecovery/ForgotPassword/ForgotPassword.module.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,0CAA0C;AAC5C;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;EACxB,6CAA6C;EAC7C,oCAAoC;EACpC,6BAA6B;AAC/B","sourcesContent":[".loginAppBar {\n  background: rgb(244, 248, 255) !important;\n  box-shadow: 0px 3px 5px #e8eef7 !important;\n}\n\n.loginToolbar {\n  padding: 0px 25px;\n}\n\n.loginLogo {\n  max-width: 178px;\n  margin: 0px 5px;\n  max-height: 50px;\n}\n\n.loginCardContent {\n  padding: 60px !important;\n  background-color: rgb(244 248 255) !important;\n  border: 1px solid #1c4979 !important;\n  border-radius: 4px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginAppBar": "ForgotPassword_loginAppBar__pDKWc",
	"loginToolbar": "ForgotPassword_loginToolbar__nti5y",
	"loginLogo": "ForgotPassword_loginLogo__2LFZV",
	"loginCardContent": "ForgotPassword_loginCardContent__1QA85"
};
export default ___CSS_LOADER_EXPORT___;
