const SupplementTemplateSchema = {
  supplementName: {
    label: "Favorite Name",
    id: "supplementName",
    autoComplete: "Name",
    required: true,
    placeholder: "Favorite Name",
    autoFocus: true,
    type: "text",
    validations: {
      noEmptySpaces: {
        value: "true",
        message: "Favorite Name is required, No blank spaces"
      }
    }
  },
  supplementDescription: {
    label: "Description",
    id: "supplementDescription",
    autoComplete: "Description",
    required: false,
    placeholder: "Description",
    autoFocus: true,
    type: "text",
    validations: {}
  }
};
export default SupplementTemplateSchema;
