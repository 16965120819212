import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import styles from "./DialogForGettingMealNumbers.module.css";
import { DarkBlueButton, Input, BorderLessButton } from "../../components";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

export default function DialogForGettingMealNumbers(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        classes={{
          root: styles.rootMealNumbers,
          container: styles.containerMealNumbers,
          paper: styles.paperMealNumbers,
          scrollPaper: styles.scrollPaperMealNumbers
        }}
      >
        <DialogTitle
          id="form-dialog-title"
          className={styles.headerMealNumbers}
        >
          {props.createTemplate ? `Add Favorite` : `New Schedule`}
        </DialogTitle>
        <HighlightOffIcon
          className={styles.closeIcon}
          onClick={props.handleClose}
        />
        {props.createTemplate ? (
          <DialogContent className={styles.dialogContentMealNumbers}>
            <DialogContentText className={styles.contentMealNumbers}>
              Favorite Name
            </DialogContentText>

            <Input
              autoFocus
              error={props.textbox1.error}
              helperText={props.textbox1.helperTextFavouriteName}
              onChange={props.textbox1.handleChangeFavouriteName}
              variant="outlined"
              margin="dense"
              id={props.textbox1.id}
              label="Favorite name"
              type="text"
              value={props.textbox1.FavouriteNameValue}
              {...props.textbox1}
            />
          </DialogContent>
        ) : null}

        <DialogContent className={styles.dialogContentMealNumbers}>
          <DialogContentText className={styles.contentMealNumbers}>
            {props.createTemplate
              ? `How many meals
            per day for this Favorite?`
              : `This will create a new schedule from today's date. How many meals
            per day for this week?`}
          </DialogContentText>

          <Input
            autoFocus={props.createTemplate ? false : true}
            error={props.textbox.error}
            helperText={props.textbox.helperText}
            onChange={props.textbox.handleChange}
            variant="outlined"
            margin="dense"
            id={props.textbox.id}
            label="No of meals per day"
            type="number"
            value={props.textbox.value}
            {...props.textbox}
          />
        </DialogContent>
        <DialogActions className={styles.actionsMealNumbers}>
          <BorderLessButton
            variant="contained"
            onClick={props.handleClose}
            color="primary"
          >
            Cancel
          </BorderLessButton>
          <DarkBlueButton
            type="button"
            variant="contained"
            onClick={props.createSchedule}
            color="primary"
            disabled={props.textbox.error}
          >
            OK
          </DarkBlueButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
