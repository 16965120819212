import React, { useState, useEffect } from "react";
import {
  Auth,
  AutoCompleteDropDown,
  HeadingOne,
  Input,
  SmallCardComponent,
  SnackBar,
  HeadingTwo
} from "../../components";
import AssessmentIcon from "@material-ui/icons/Assessment";
import {
  Backdrop,
  CircularProgress,
  Grid,
  makeStyles
} from "@material-ui/core";
import { providerForGet } from "../../api";
import {
  createExerciseReport,
  downloadActualFoodForAllMealsBreakdownPdf,
  downloadSupplementsPdf,
  generateGiveAllWithInserts,
  generateShoppingList,
  getAllDaysBreakdownPdf,
  getGenericDocument,
  getMenuForWeekMeal,
  getScheduleDateForClient,
  printPdf,
  viewPdf,
  getCustomizationUrl,
  getAllReports
} from "../../constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import JSZip from "jszip";
import FileSaver from "file-saver";
import { convertDate } from "../../utils";
import { VIEWFILE } from "../../paths";
import noSchedule from "../../assets/images/No_schedule.jpg";
import actual_food_breakdown_for_all_meals_week_image from "../../assets/reports_icon/Actual Food Breakdown for All Meals Week.png";
import bmi_report_image from "../../assets/reports_icon/Body Mass Report.png";
import exchange_breakdown_of_all_meals_week_image from "../../assets/reports_icon/Exchange Breakdown of All Meals Week.png";
import exercise_reports_image from "../../assets/reports_icon/Exercise Reports.png";
import give_all_book_with_inserts_image from "../../assets/reports_icon/Give All Book with Inserts.png";
import give_all_image from "../../assets/reports_icon/Give All Book.png";
import measurement_report_image from "../../assets/reports_icon/Measurement Reports.png";
import menu_for_all_meals_for_the_week_image from "../../assets/reports_icon/Menu for All Meals for the Week.png";
import shopping_list_image from "../../assets/reports_icon/Shopping List w.png";
import supplements_image from "../../assets/reports_icon/Supplement Reports.png";
import all_Reports from "../../assets/reports_icon/All Reports.png";

const ClientMyReports = () => {
  const [zip, setZip] = useState(new JSZip());
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    summaryButtonBox: {
      display: "flex",
      marginBottom: "3%"
    },
    loadTemplate: {
      marginLeft: "auto"
    },
    favoritesOption: {
      display: "flex",
      marginRight: "auto"
    },
    margin: {
      margin: theme.spacing(1)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    formControl: {
      margin: "10px 10px 10px 0px",
      minWidth: 180
    },
    cancelSaveButton: {
      marginLeft: "auto"
    },
    buttonPadding: {
      paddingLeft: "10 px"
    },
    duplicateReplicateStyle: {
      display: "flex",
      justifyContent: "end"
    },
    workoutDayGrid: {
      paddingTop: "0px !Important"
    },
    pdfGrid: {
      paddingBottom: "1.25rem"
    },
    centerImage: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: "30%"
    }
  }));
  const localClasses = useStyles();
  const noScheduleDataMessageFromBackend = "No schedule data";
  const [scheduleDateNotPresent, setScheduleDateNotPresent] = useState(false);
  const auth = Auth.getToken();
  const history = useHistory();
  const userInfo = Auth.getUserInfo();
  const [checked, setChecked] = useState({
    isChecked: false,
    values: {}
  });

  const [loader, setLoader] = useState(false);
  const [dateList, setDateList] = useState([]);
  const [downloadCount] = useState(0);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      scheduleObj: {}
    },
    alert: false,
    errorMessage: "",
    severity: "error"
  });
  const [showFile, setShowFile] = useState({});

  const setPermission = data => {
    Object.keys(data).map(number => {
      setShowFile(showFile => ({
        ...showFile,
        [number]: data[number].view === true ? true : false
      }));
      return null;
    });
  };

  const getPermissionDetails = () => {
    let body = {
      is_document_permission_custom: true
    };
    providerForGet(getCustomizationUrl, body, auth)
      .then(res => {
        if (
          res.data &&
          res.data[0] &&
          res.data[0].customization_json_field &&
          res.data[0].customization_json_field.allDocuments
        ) {
          setPermission(res.data[0].customization_json_field.allDocuments);
        }
      })
      .catch(err => {
        console.log("errpermission", err);
      });
  };

  const getScheduleDates = () => {
    providerForGet(
      getScheduleDateForClient,
      {
        c: userInfo.id
      },
      auth
    )
      .then(async res => {
        let convertDateData = await convertDate(res.data);
        setDateList(convertDateData);
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            scheduleDate: convertDateData[0].id,
            scheduleObj: convertDateData[0],
            defaultScheduleObj: convertDateData[0]
          }
        }));
      })
      .catch(error => {
        console.log("error", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.statusCode === 400 &&
          error.response.data.message === noScheduleDataMessageFromBackend
        ) {
          setScheduleDateNotPresent(true);
        }
      });
  };

  useEffect(() => {
    getPermissionDetails();
    getScheduleDates();
  }, []);

  useEffect(() => {
    let count = 0;
    Object.keys(checked.values).map((key, i) => {
      if (checked.values[key] === true) {
        count = count + 1;
      }
      return null;
    });

    if (count && downloadCount === count) {
      zip
        .generateAsync({
          type: "blob"
        })
        .then(function (content) {
          FileSaver.saveAs(content, new Date() + ".zip");
          setZip(new JSZip());
        });
    }
  }, [downloadCount]);

  useEffect(() => {
    let count = 0;
    Object.keys(checked.values).map((key, i) => {
      if (checked.values[key] === true) {
        count = count + 1;
      }
      return null;
    });

    if (count === 0) {
      setChecked(checked => ({
        ...checked,
        isChecked: false
      }));
    } else {
      setChecked(checked => ({
        ...checked,
        isChecked: true
      }));
    }
  }, [checked.values]);

  const handleChangeAutoComplete = (eventName, event, value) => {
    if (eventName === "scheduleDate") {
      if (value !== null) {
        setFormState(formState => ({
          ...formState,

          values: {
            ...formState.values,
            [eventName]: value.id,
            scheduleObj: value
          }
        }));
      } else {
        delete formState.values[eventName];
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [eventName]: formState.values.defaultScheduleObj.id,
            scheduleObj: formState.values.defaultScheduleObj
          }
        }));
      }
    }
  };

  const handleSupplementDownload = async (isMultiDownload = false) => {
    let data = {
      selectedDateObj: formState.values["scheduleObj"],
      scheduleId: formState.values["scheduleObj"].id,
      reportData: {
        name: "Supplement Reports",
        url: downloadSupplementsPdf,
        download: "report",
        sendBody: true,
        scheduleId: false,
        clientId: false
      },
      fromReport: true
    };

    history.push(VIEWFILE, data);
  };

  const handleExerciseDownload = async (isMultiDownload = false) => {
    let data = {
      selectedDateObj: formState.values["scheduleObj"],
      scheduleId: formState.values["scheduleObj"].id,
      reportData: {
        name: "Exercise Reports",
        url: createExerciseReport,
        download: "report",
        sendBody: true,
        scheduleId: true,
        clientId: false
      },
      fromReport: true
    };

    history.push(VIEWFILE, data);
  };

  const handleBodyMassDownload = (isMultiDownload = false) => {
    let data = {
      selectedDateObj: formState.values["scheduleObj"],
      scheduleId: formState.values["scheduleObj"].id,
      reportData: {
        name: "Body Mass Reports",
        url: viewPdf,
        download: "graph",
        sendBody: true,
        scheduleId: false,
        clientId: false
      },
      fromReport: true
    };

    history.push(VIEWFILE, data);
  };

  const handleMeasurementsDownload = (isMultiDownload = false) => {
    let data = {
      selectedDateObj: formState.values["scheduleObj"],
      scheduleId: formState.values["scheduleObj"].id,
      reportData: {
        name: "Measurements Reports",
        url: printPdf,
        download: "graph",
        sendBody: true,
        scheduleId: false,
        clientId: false
      },
      fromReport: true,
      showFile: showFile
    };

    history.push(VIEWFILE, data);
  };

  const handleDownloadGiveAllWithInserts = async (isMultiDownload = false) => {
    let data = {
      selectedDateObj: formState.values["scheduleObj"],
      scheduleId: formState.values["scheduleObj"].id,
      reportData: {
        name: "Give All With Insert",
        url: generateGiveAllWithInserts,
        download: "report",
        sendBody: true,
        scheduleId: true,
        clientId: true
      },
      fromReport: true
    };

    history.push(VIEWFILE, data);
  };

  const handleGiveAllBookDownload = () => {
    let data = {
      selectedDateObj: formState.values["scheduleObj"],
      scheduleId: formState.values["scheduleObj"].id,
      reportData: {
        name: "Give All",
        url: getGenericDocument,
        download: "report",
        sendBody: false,
        scheduleId: false,
        clientId: false
      },
      fromReport: true
    };

    history.push(VIEWFILE, data);
  };

  const handleDownloadAllReports = async (isMultiDownload = false) => {
    let data = {
      selectedDateObj: formState.values["scheduleObj"],
      scheduleId: formState.values["scheduleObj"].id,
      reportData: {
        name: "All Reports",
        url: getAllReports,
        download: "graph",
        sendBody: true,
        scheduleId: false,
        clientId: false
      },
      fromReport: true
    };

    history.push(VIEWFILE, data);
  };

  const handleExchangeBreakdownForAllMealDownload = async (
    isMultiDownload = false
  ) => {
    let data = {
      selectedDateObj: formState.values["scheduleObj"],
      scheduleId: formState.values["scheduleObj"].id,
      reportData: {
        name: "Exchange Break Down For All Meals Week",
        url: getAllDaysBreakdownPdf,
        download: "report",
        sendBody: true,
        scheduleId: true,
        clientId: false
      },
      fromReport: true
    };

    history.push(VIEWFILE, data);
  };

  const handleShoppingListDownload = async (isMultiDownload = false) => {
    setLoader(true);
    let data = {
      selectedDateObj: formState.values["scheduleObj"],
      scheduleId: formState.values["scheduleObj"].id,
      reportData: {
        name: "Shopping List",
        url: generateShoppingList,
        download: "report",
        sendBody: true,
        scheduleId: true,
        clientId: false
      },
      fromReport: true
    };

    history.push(VIEWFILE, data);
  };

  const handleMenuForWeeksMealDownload = async (isMultiDownload = false) => {
    let data = {
      selectedDateObj: formState.values["scheduleObj"],
      scheduleId: formState.values["scheduleObj"].id,
      reportData: {
        name: "Menu For Week List",
        url: getMenuForWeekMeal,
        download: "report",
        sendBody: true,
        scheduleId: true,
        clientId: false
      },
      fromReport: true
    };

    history.push(VIEWFILE, data);
  };

  const handleActualFoodForAllMealsDownload = async (
    isMultiDownload = false
  ) => {
    let data = {
      selectedDateObj: formState.values["scheduleObj"],
      scheduleId: formState.values["scheduleObj"].id,
      reportData: {
        name: "Actual Food For All Meal",
        url: downloadActualFoodForAllMealsBreakdownPdf,
        download: "report",
        sendBody: true,
        scheduleId: true,
        clientId: false
      },
      fromReport: true
    };

    history.push(VIEWFILE, data);
  };

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "error"
    }));
  };

  return (
    <div>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <HeadingOne>
          <AssessmentIcon style={{ marginRight: 10, color: "#1C4979" }} />
          MY REPORTS
        </HeadingOne>
      </Grid>
      {scheduleDateNotPresent ? (
        <Grid>
          <img
            src={noSchedule}
            className={localClasses.centerImage}
            alt="No schedule/data"
          />
        </Grid>
      ) : (
        <>
          <Grid container className={localClasses.root} spacing={2}>
            <Grid>
              <SnackBar
                open={formState.alert}
                severity={formState.severity}
                onClose={handleSnackbarClose}
              >
                {formState.errorMessage}
              </SnackBar>
            </Grid>

            <Grid
              item
              lg={4}
              md={4}
              sm={12}
              xs={12}
              className={localClasses.pdfGrid}
              spacing={1}
            >
              <AutoCompleteDropDown
                options={dateList}
                getOptionLabel={option => option.schedule_start_date}
                id="schedule_date"
                value={
                  dateList[
                    dateList.findIndex(function (item, i) {
                      return item.id === formState.values["scheduleDate"];
                    })
                  ] || null
                }
                onChange={(event, value) => {
                  handleChangeAutoComplete("scheduleDate", event, value);
                }}
                renderInput={params => (
                  <Input {...params} id="schedule_date" label="Schedule Date" />
                )}
              />
            </Grid>
            {showFile["Give All"] || showFile["Give All With Insert"] ? (
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={localClasses.pdfGrid}
                spacing={1}
              >
                <Grid lg={12} md={12} sm={12} xs={12} spacing={1}>
                  <HeadingTwo>GIVE ALL BOOK</HeadingTwo>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  style={{
                    display: "flex",
                    marginRight: "1%"
                  }}
                >
                  {showFile["Give All"] ? (
                    <Grid
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        margin: "1%"
                      }}
                    >
                      <SmallCardComponent
                        imgComponent="img"
                        imgAlt="Give All"
                        imgHeight="140"
                        imgWidth="140"
                        imgImage={give_all_image}
                        handleCardClick={() => {
                          handleGiveAllBookDownload();
                        }}
                        title="PFC nutrition"
                        cardTitle="Give All Book (Without Inserts)"
                      />
                    </Grid>
                  ) : null}

                  {showFile["Give All With Insert"] ? (
                    <Grid
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        margin: "1%"
                      }}
                    >
                      <SmallCardComponent
                        imgComponent="img"
                        imgAlt="Give All with insert"
                        imgHeight="140"
                        imgWidth="140"
                        imgImage={give_all_book_with_inserts_image}
                        handleCardClick={() => {
                          handleDownloadGiveAllWithInserts();
                        }}
                        title="PFC nutrition"
                        cardTitle="Give All Book (With Inserts)"
                      />
                    </Grid>
                  ) : null}
                  <Grid lg={4} md={12} sm={12} xs={12} spacing={1}></Grid>
                </Grid>
              </Grid>
            ) : null}

            {showFile["All Reports"] ? (
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={localClasses.pdfGrid}
                spacing={1}
              >
                <Grid lg={12} md={12} sm={12} xs={12} spacing={1}>
                  <HeadingTwo>All Reports</HeadingTwo>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  style={{
                    display: "flex",
                    marginRight: "1%"
                  }}
                >
                  {showFile["All Reports"] ? (
                    <Grid
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        margin: "1%"
                      }}
                    >
                      <SmallCardComponent
                        imgComponent="img"
                        imgAlt="All Reports"
                        imgHeight="140"
                        imgWidth="140"
                        imgImage={all_Reports}
                        handleCardClick={() => {
                          handleDownloadAllReports();
                        }}
                        title="PFC nutrition"
                        cardTitle="All Reports"
                      />
                    </Grid>
                  ) : null}
                  <Grid lg={4} md={12} sm={12} xs={12} spacing={1}></Grid>

                  <Grid lg={4} md={12} sm={12} xs={12} spacing={1}></Grid>
                </Grid>
              </Grid>
            ) : null}

            {showFile["Actual Food For All Meal"] ||
            showFile["Exchnage Break Down Of All"] ||
            showFile["Menu For Week List"] ||
            showFile["Shopping List"] ? (
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={localClasses.pdfGrid}
                spacing={1}
              >
                <Grid lg={12} md={12} sm={12} xs={12} spacing={1}>
                  <HeadingTwo>MENU/ EXCHANGES</HeadingTwo>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  style={{
                    display: "flex",
                    marginRight: "1%"
                  }}
                >
                  {showFile["Actual Food For All Meal"] ? (
                    <Grid
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        margin: "1%"
                      }}
                    >
                      <SmallCardComponent
                        imgComponent="img"
                        imgAlt="Actual Food For All Meal"
                        imgHeight="140"
                        imgWidth="140"
                        imgImage={
                          actual_food_breakdown_for_all_meals_week_image
                        }
                        handleCardClick={() => {
                          handleActualFoodForAllMealsDownload();
                        }}
                        title="PFC nutrition"
                        cardTitle="Actual Food Breakdown for All Meals Week"
                      />
                    </Grid>
                  ) : null}
                  {showFile["Exchange Break Down For All Meals Week"] ? (
                    <Grid
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        margin: "1%"
                      }}
                    >
                      <SmallCardComponent
                        imgComponent="img"
                        imgAlt="Exchnage Break Down Of All"
                        imgHeight="140"
                        imgWidth="140"
                        imgImage={exchange_breakdown_of_all_meals_week_image}
                        handleCardClick={() => {
                          handleExchangeBreakdownForAllMealDownload();
                        }}
                        title="PFC nutrition"
                        cardTitle="Exchange Breakdown of All Meals Week"
                      />
                    </Grid>
                  ) : null}

                  {showFile["Menu For Week List"] ? (
                    <Grid
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        margin: "1%"
                      }}
                    >
                      <SmallCardComponent
                        imgComponent="img"
                        imgAlt="Menu For Week List"
                        imgHeight="140"
                        imgWidth="140"
                        imgImage={menu_for_all_meals_for_the_week_image}
                        handleCardClick={() => {
                          handleMenuForWeeksMealDownload();
                        }}
                        title="PFC nutrition"
                        cardTitle="Menu for All Meals for the Week"
                      />
                    </Grid>
                  ) : null}
                  {showFile["Shopping List"] ? (
                    <Grid
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        margin: "1%"
                      }}
                    >
                      <SmallCardComponent
                        imgComponent="img"
                        imgAlt="Shopping List"
                        imgHeight="140"
                        imgWidth="140"
                        imgImage={shopping_list_image}
                        handleCardClick={() => {
                          handleShoppingListDownload();
                        }}
                        title="PFC nutrition"
                        cardTitle="Shopping List"
                      />
                    </Grid>
                  ) : null}
                  <Grid lg={4} md={12} sm={12} xs={12} spacing={1}></Grid>

                  <Grid lg={4} md={12} sm={12} xs={12} spacing={1}></Grid>
                </Grid>
              </Grid>
            ) : null}

            {showFile["Supplement Reports"] ? (
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={localClasses.pdfGrid}
                spacing={1}
              >
                <Grid lg={12} md={12} sm={12} xs={12} spacing={1}>
                  <HeadingTwo>SUPPLEMENTS REPORT</HeadingTwo>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  style={{
                    display: "flex",
                    marginRight: "1%"
                  }}
                >
                  {showFile["Supplement Reports"] ? (
                    <Grid
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        margin: "1%"
                      }}
                    >
                      <SmallCardComponent
                        imgComponent="img"
                        imgAlt="Supplement Reports"
                        imgHeight="140"
                        imgWidth="140"
                        imgImage={supplements_image}
                        handleCardClick={() => {
                          handleSupplementDownload();
                        }}
                        title="PFC nutrition"
                        cardTitle="Supplement Reports"
                      />
                    </Grid>
                  ) : null}
                  <Grid lg={4} md={12} sm={12} xs={12} spacing={1}></Grid>

                  <Grid lg={4} md={12} sm={12} xs={12} spacing={1}></Grid>
                </Grid>
              </Grid>
            ) : null}

            {showFile["Exercise Reports"] ? (
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={localClasses.pdfGrid}
                spacing={1}
              >
                <Grid lg={12} md={12} sm={12} xs={12} spacing={1}>
                  <HeadingTwo>EXERCISE REPORT</HeadingTwo>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  style={{
                    display: "flex",
                    marginRight: "1%"
                  }}
                >
                  {showFile["Exercise Reports"] ? (
                    <Grid
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        margin: "1%"
                      }}
                    >
                      <SmallCardComponent
                        imgComponent="img"
                        imgAlt="Exercise Reports"
                        imgHeight="140"
                        imgWidth="140"
                        imgImage={exercise_reports_image}
                        handleCardClick={() => {
                          handleExerciseDownload();
                        }}
                        title="PFC nutrition"
                        cardTitle="Exercise Reports"
                      />
                    </Grid>
                  ) : null}
                  <Grid lg={4} md={12} sm={12} xs={12} spacing={1}></Grid>

                  <Grid lg={4} md={12} sm={12} xs={12} spacing={1}></Grid>
                </Grid>
              </Grid>
            ) : null}

            {showFile["Measurements Reports"] ? (
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={localClasses.pdfGrid}
                spacing={1}
              >
                <Grid lg={12} md={12} sm={12} xs={12} spacing={1}>
                  <HeadingTwo>MEASUREMENTS REPORT</HeadingTwo>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  style={{
                    display: "flex",
                    marginRight: "1%"
                  }}
                >
                  {showFile["Measurements Reports"] ? (
                    <Grid
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        margin: "1%"
                      }}
                    >
                      <SmallCardComponent
                        imgComponent="img"
                        imgAlt="Measurements Reports"
                        imgHeight="140"
                        imgWidth="140"
                        imgImage={measurement_report_image}
                        handleCardClick={() => {
                          handleMeasurementsDownload();
                        }}
                        title="PFC nutrition"
                        cardTitle="Measurement Reports"
                      />
                    </Grid>
                  ) : null}
                  <Grid lg={4} md={12} sm={12} xs={12} spacing={1}></Grid>

                  <Grid lg={4} md={12} sm={12} xs={12} spacing={1}></Grid>
                </Grid>
              </Grid>
            ) : null}

            {showFile["Body Mass Reports"] ? (
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={localClasses.pdfGrid}
                spacing={1}
              >
                <Grid lg={12} md={12} sm={12} xs={12} spacing={1}>
                  <HeadingTwo>BODY MASS REPORT</HeadingTwo>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  style={{
                    display: "flex",
                    marginRight: "1%"
                  }}
                >
                  {showFile["Body Mass Reports"] ? (
                    <Grid
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{
                        margin: "1%"
                      }}
                    >
                      <SmallCardComponent
                        imgComponent="img"
                        imgAlt="Body Mass Reports"
                        imgHeight="140"
                        imgWidth="140"
                        imgImage={bmi_report_image}
                        handleCardClick={() => {
                          handleBodyMassDownload();
                        }}
                        title="PFC nutrition"
                        cardTitle="Body Mass Reports"
                      />
                    </Grid>
                  ) : null}
                  <Grid lg={4} md={12} sm={12} xs={12} spacing={1}></Grid>

                  <Grid lg={4} md={12} sm={12} xs={12} spacing={1}></Grid>
                </Grid>
              </Grid>
            ) : null}
            <Backdrop className={localClasses.backdrop} open={loader}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Grid>
        </>
      )}
    </div>
  );
};

ClientMyReports.propTypes = {};

ClientMyReports.defaultProps = {};

export default ClientMyReports;
