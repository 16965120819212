const ProfileSchema = {
  full_name: {
    label: "Full Name",
    id: "fullName",
    autoComplete: "fullName",
    required: true,
    placeholder: "fullName",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: " FullName is required"
      }
    }
  },
  // password: {
  //   label: "Password",
  //   id: "password",
  //   autoComplete: "password",
  //   required: false,
  //   placeholder: "password",
  //   autoFocus: true,
  //   type: "password",
  //   validations: {}
  // },

  phone: {
    label: "Phone",
    id: "phone",
    autoComplete: "phone",
    required: false,
    placeholder: "Phone",
    autoFocus: true,
    type: "phone",
    validations: {}
  },
  franchiseName: {
    label: "Franchise",
    id: "franchiseName",
    autoComplete: "Franchise",
    required: true,
    placeholder: "Franchise",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  franchiseSite: {
    label: "Franchise Site",
    id: "franchiseSite",
    autoComplete: "Franchise Site",
    required: false,
    placeholder: "Franchise Site",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  email: {
    label: "Email",
    id: "email",
    autoComplete: "email",
    required: true,
    placeholder: "email",
    autoFocus: true,
    type: "email",
    validations: {
      required: {
        value: "true",
        message: "Email is required"
      },
      validateEmailRegex: {
        value: "true",
        message: "Email not valid"
      }
    }
  },
  address: {
    label: "Address",
    id: "address",
    autoComplete: "address",
    required: false,
    placeholder: "address",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  state: {
    label: "State",
    id: "state",
    autoComplete: "state",
    required: false,
    placeholder: "state",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  city: {
    label: "City",
    id: "city",
    autoComplete: "city",
    required: false,
    placeholder: "city",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  zipcode: {
    label: "Zipcode",
    id: "zipcode",
    autoComplete: "zipcode",
    required: false,
    placeholder: "zipcode",
    autoFocus: true,
    type: "number",
    validations: {}
  },
  country: {
    label: "Country",
    id: "country",
    autoComplete: "country",
    required: false,
    placeholder: "country",
    autoFocus: true,
    type: "text",
    validations: {}
  }
};
export default ProfileSchema;
