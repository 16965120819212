// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Routes_navWrap__3qQxi {\n  padding-top: 127px;\n}\n.Routes_navWrap__3qQxi > div,\n.Routes_mobNavWrap__U-Taa > div {\n  padding: 0px !important;\n}\n.Routes_mobNavWrap__U-Taa {\n  padding-top: 180px;\n}\n.Routes_mobMenuTabs__1D1UG {\n  width: calc(100% - 20px);\n  margin: 0 auto 50px auto;\n  box-shadow: rgb(232 238 247) 0px 3px 5px;\n}\n.Routes_mobNavWrap__U-Taa > div:first-child {\n  width: calc(100% + 40px) !important;\n  margin-left: -20px !important;\n  position: fixed;\n  left: 0;\n  top: 178px;\n  z-index: 10000;\n}\n@media screen and (max-width: 1278px) {\n  .Routes_mobMenuTabs__1D1UG {\n    margin-bottom: 0px;\n  }\n}\n@media screen and (max-width: 437px) {\n  .Routes_mobNavWrap__U-Taa > div:first-child {\n    top: 200px;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/routes/Routes.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;;EAEE,uBAAuB;AACzB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,wBAAwB;EACxB,wBAAwB;EACxB,wCAAwC;AAC1C;AACA;EACE,mCAAmC;EACnC,6BAA6B;EAC7B,eAAe;EACf,OAAO;EACP,UAAU;EACV,cAAc;AAChB;AACA;EACE;IACE,kBAAkB;EACpB;AACF;AACA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".navWrap {\n  padding-top: 127px;\n}\n.navWrap > div,\n.mobNavWrap > div {\n  padding: 0px !important;\n}\n.mobNavWrap {\n  padding-top: 180px;\n}\n.mobMenuTabs {\n  width: calc(100% - 20px);\n  margin: 0 auto 50px auto;\n  box-shadow: rgb(232 238 247) 0px 3px 5px;\n}\n.mobNavWrap > div:first-child {\n  width: calc(100% + 40px) !important;\n  margin-left: -20px !important;\n  position: fixed;\n  left: 0;\n  top: 178px;\n  z-index: 10000;\n}\n@media screen and (max-width: 1278px) {\n  .mobMenuTabs {\n    margin-bottom: 0px;\n  }\n}\n@media screen and (max-width: 437px) {\n  .mobNavWrap > div:first-child {\n    top: 200px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navWrap": "Routes_navWrap__3qQxi",
	"mobNavWrap": "Routes_mobNavWrap__U-Taa",
	"mobMenuTabs": "Routes_mobMenuTabs__1D1UG"
};
export default ___CSS_LOADER_EXPORT___;
