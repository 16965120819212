// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Client_popoverFilter__2k-17 p {\n  padding: 5px 15px !important;\n  font-size: 0.9375rem !important;\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 500 !important;\n  cursor: pointer;\n  letter-spacing: 0.46px;\n}\n\n.Client_popoverFilter__2k-17 p:first-child {\n  padding-top: 10px !important;\n}\n\n.Client_popoverFilter__2k-17 p:last-child {\n  padding-bottom: 10px !important;\n}\n.Client_filterChip__1-lbL {\n  margin-left: 7px;\n  margin-bottom: 15px;\n  background-color: #f4f8ff !important;\n  font-size: 12px !important;\n  font-weight: 500;\n  font-family: Montserrat, sans-serif !important;\n}\n", "",{"version":3,"sources":["webpack://src/containers/Client/Client.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,+BAA+B;EAC/B,8CAA8C;EAC9C,2BAA2B;EAC3B,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,+BAA+B;AACjC;AACA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,oCAAoC;EACpC,0BAA0B;EAC1B,gBAAgB;EAChB,8CAA8C;AAChD","sourcesContent":[".popoverFilter p {\n  padding: 5px 15px !important;\n  font-size: 0.9375rem !important;\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 500 !important;\n  cursor: pointer;\n  letter-spacing: 0.46px;\n}\n\n.popoverFilter p:first-child {\n  padding-top: 10px !important;\n}\n\n.popoverFilter p:last-child {\n  padding-bottom: 10px !important;\n}\n.filterChip {\n  margin-left: 7px;\n  margin-bottom: 15px;\n  background-color: #f4f8ff !important;\n  font-size: 12px !important;\n  font-weight: 500;\n  font-family: Montserrat, sans-serif !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popoverFilter": "Client_popoverFilter__2k-17",
	"filterChip": "Client_filterChip__1-lbL"
};
export default ___CSS_LOADER_EXPORT___;
