import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Snackbar, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

const SnackBar = props => {
  const appliedClasses = useStyles();

  return (
    <div className={props.classes ? props.classes.root : appliedClasses.root}>
      <Snackbar
        open={props.open}
        anchorOrigin={
          props.anchorOrigin
            ? props.anchorOrigin
            : { vertical: "top", horizontal: "center" }
        }
        autoHideDuration={
          props.autoHideDuration ? props.autoHideDuration : 30000
        }
        {...props}
      >
        <Alert onClose={props.onClose} severity={props.severity}>
          <Typography variant="button" display="block" gutterBottom>
            {props.children}
          </Typography>
        </Alert>
      </Snackbar>
    </div>
  );
};

SnackBar.propTypes = {};

SnackBar.defaultProps = {};

export default SnackBar;
