const CountrySchema = {
  countryName: {
    label: "Name",
    id: "countryName",
    autoComplete: "Name",
    required: true,
    placeholder: "Name",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Name is required"
      }
    }
  }
};
export default CountrySchema;
