import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const localStyles = makeStyles(theme => ({
  RedButtonStyle: {
    backgroundColor: "#D30E2F",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "0.875rem",
    "&:hover": {
      background: "#D30E2F",
      color: "#FFFFFF"
    }
  }
}));

export default function RedButton(props) {
  const classes = localStyles();

  return (
    <Button
      className={classes.RedButtonStyle}
      variant="contained"
      onClick={props.onClick}
      style={props.style}
      {...props}
      disableRipple
      disableFocusRipple
    >
      {props.children}
    </Button>
  );
}
