import React, { forwardRef, useState } from "react";
import { Grid } from "@material-ui/core";
import { ADDEXERCISETEMPLATE, EDITEXERCISETEMPLATE } from "../../paths";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Auth,
  BorderLessButton,
  LightGrayButton,
  SearchBar,
  SnackBar,
  Table,
  HeadingOne
} from "../../components";

import {
  deleteWorkoutTemplateData,
  getWorkoutTemplateForFranchise
} from "../../constants";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import {
  DELETE_MESSAGE,
  ERROR_MESSAGE
} from "../../constants/genericConstants";
import { providerForPost } from "../../api";

const ExerciseTemplate = props => {
  const propData = props["location"]["state"];
  const history = useHistory();
  const auth = Auth.getToken();

  const [filter, setFilter] = useState({
    _sort: "template_name:asc"
  });
  const tableRef = React.createRef();

  let apiUrl = "";
  const columns = [{ title: "Name", field: "name" }];

  const [formState, setFormState] = useState({
    dataTable: {
      data: [],
      page: 1,
      totalCount: 0
    },
    alert: propData ? propData["successMessage"] : false,
    errorMessage: propData ? propData["successMessage"] : false,
    severity: "success",
    isLoad: false,
    franchiseList: []
  });

  /**
   ** Use key names exactly same as API response,they'll be used for filtering & sorting purpose
   * */
  const convertTableData = res => {
    let arr = [];
    if (res && res.length > 0) {
      for (let i in res) {
        let tempArr = {};
        tempArr["id"] = res[i].id;
        tempArr["name"] = res[i].template_name;
        arr.push(tempArr);
      }
      return arr;
    } else {
      return [];
    }
  };

  const getExerciseData = async (page, pageSize) => {
    /**
     ** It sets the page and pageSize in filter variable
     ** so as to keep the record of what page and pageSize was set
     */
    apiUrl = getWorkoutTemplateForFranchise;

    setFilter(filter => ({
      ...filter,
      page: page,
      pageSize: pageSize
    }));
    let params = { page: page, pageSize: pageSize };
    Object.keys(filter).map(res => {
      if (!params.hasOwnProperty(res)) {
        params[res] = filter[res];
      }
      return null;
    });
    params.desc = "Get Workout favorite data";
    return new Promise((resolve, reject) => {
      fetch(apiUrl + "?" + new URLSearchParams(params), {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth
        }
      })
        .then(response => response.json())
        .then(result => {
          resolve({
            data: convertTableData(result.data),
            page: result.page - 1,
            totalCount: result.totalCount
          });
        });
    });
  };

  const handleClickOpen = rowData => {
    let data = {};
    data["id"] = rowData.id;
    history.push(EDITEXERCISETEMPLATE, data);
  };

  const handleFilterChange = event => {
    if (event.target.value !== "") {
      setFilter(filter => ({
        ...filter,
        [event.target.name]: event.target.value
      }));
    } else {
      delete filter[event.target.name];
      setFilter(filter => ({
        ...filter
      }));
    }
  };
  const orderFunc = (columnId, direction) => {
    let orderByColumn;
    let orderBy = "";
    if (columnId >= 0) {
      orderByColumn = columns[columnId]["field"];
    }
    orderBy = orderByColumn + ":" + direction;
    setFilter(filter => ({
      ...filter,
      _sort: orderBy
    }));
    tableRef.current.onQueryChange();
  };

  const handleSearch = event => {
    setFormState(formState => ({
      ...formState,
      isLoad: true
    }));
    tableRef.current.onQueryChange();
  };

  const handleReset = event => {
    delete filter["template_name_contains"];
    setFilter(filter => ({
      ...filter
    }));
    getExerciseData(1, filter.pageSize);
    tableRef.current.onQueryChange();
  };

  const handleSnackbarClose = () => {
    if (propData) {
      propData["successMessage"] = false;
    }
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "success"
    }));
  };
  return (
    <>
      <div data-testid="clients">
        <div>
          <Grid
            container
            spacing={3}
            style={{ justifyContent: "flex-end", marginTop: "2%" }}
          >
            <Grid item>
              <LightGrayButton
                variant="contained"
                onClick={() => {
                  history.push(ADDEXERCISETEMPLATE);
                }}
              >
                Add New Favorite
              </LightGrayButton>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <HeadingOne>Manage Exercise Favorite</HeadingOne>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
          <Grid>
            <SnackBar
              open={formState.alert}
              severity={formState.severity}
              onClose={handleSnackbarClose}
            >
              {formState.errorMessage}
            </SnackBar>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <SearchBar
                handleFilterChange={handleFilterChange}
                filter={
                  filter.template_name_contains
                    ? filter.template_name_contains
                    : ""
                }
                name="template_name_contains"
                placeholder="Search by Favorite Name"
                handleSearch={handleSearch}
                handleReset={handleReset}
              />
            </Grid>
          </Grid>

          <Table
            tableRef={tableRef}
            columns={columns}
            data={async query => {
              return await getExerciseData(query.page + 1, query.pageSize);
            }}
            localization={{
              body: {
                editRow: {
                  deleteText:
                    "Are you sure you want to delete this Exercise Favorite?",
                  saveTooltip: "Delete"
                }
              }
            }}
            actions={[
              {
                icon: () => <BorderLessButton>Edit</BorderLessButton>,
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  handleClickOpen(rowData);
                }
              }
            ]}
            icons={{
              Delete: forwardRef((props, ref) => (
                <DeleteOutlineIcon {...props} ref={ref}></DeleteOutlineIcon>
              ))
            }}
            editable={{
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(async () => {
                    let postData = {
                      workoutTemplateId: oldData.id,
                      desc: "Delete Exercise Favorite"
                    };
                    await providerForPost(
                      deleteWorkoutTemplateData,
                      postData,
                      auth
                    )
                      .then(async res => {
                        setFormState(formState => ({
                          ...formState,
                          alert: true,
                          errorMessage: res.data.template_name + DELETE_MESSAGE,
                          severity: "success"
                        }));
                        await getExerciseData(1, filter.pageSize);
                      })
                      .catch(err => {
                        console.log("error", err);
                        setFormState(formState => ({
                          ...formState,
                          alert: true,
                          errorMessage: ERROR_MESSAGE + err,
                          severity: "error"
                        }));
                      });
                    resolve();
                  }, 1000);

                  tableRef.current.onQueryChange();
                })
            }}
            options={{
              actionsColumnIndex: -1,
              search: false,
              sorting: true,
              pageSize: 10,
              thirdSortClick: false,
              headerStyle: {
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "#8A8A97",
                borderBottom: "solid #E0E0E0 2px",
                fontSize: "1rem"
              },
              cellStyle: {
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "#000000",
                fontSize: "1rem"
              },
              paginationStyle: {
                justifyContent: "center"
              }
            }}
            onOrderChange={(orderedColumnId, orderDirection) => {
              orderFunc(orderedColumnId, orderDirection);
            }}
          />
        </div>
      </div>
    </>
  );
};

ExerciseTemplate.propTypes = {};

ExerciseTemplate.defaultProps = {};

export default ExerciseTemplate;
