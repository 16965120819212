import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import styles from "./DialogBox.module.css";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { BorderLessButton, DarkBlueButton } from "..";

const DialogBox = props => {
  let cancelButtonDisplay = null;
  let okButtonDisplay = null;
  if (!props.skipCancel) {
    cancelButtonDisplay = (
      <BorderLessButton
        variant={"contained"}
        onClick={props.handleCancel}
        data-testid="cancel-button"
      >
        {props.buttonCancel}
      </BorderLessButton>
    );
  }
  if (!props.skipOk) {
    okButtonDisplay = (
      <DarkBlueButton
        variant={"contained"}
        onClick={props.handleOk}
        data-testid="ok-button"
      >
        {props.buttonOk}
      </DarkBlueButton>
    );
  }
  return (
    <div
      className={styles.DialogBox}
      styles={{
        minWidth: "400px"
      }}
    >
      {" "}
      <Dialog
        data-testid="dialogBox"
        open={props.open}
        onClose={props.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          root: styles.rootDialogBox,
          container: styles.containerDialogBox,
          paper: styles.paperDialogBox,
          scrollPaper: styles.scrollPaperDialogBox
        }}
      >
        <DialogTitle
          onClose={props.handleCancel}
          id="alert-dialog-title"
          className={styles.DialogTitle}
        >
          {props.title}
        </DialogTitle>
        <HighlightOffIcon
          className={styles.closeIcon}
          onClick={props.handleCancel}
        />
        <DialogContent className={styles.DialogBody}>
          {props.children}
        </DialogContent>
        <DialogActions className={styles.actionsDialogBox}>
          {cancelButtonDisplay}
          {okButtonDisplay}
        </DialogActions>
      </Dialog>
    </div>
  );
};

DialogBox.propTypes = {};

DialogBox.defaultProps = {};

export default DialogBox;
