// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Authenticator_title__39JCD {\n  font-family: \"AvenirNext\", sans-serif;\n  font-weight: 100;\n}\n\n.Authenticator_titleText__29LOI {\n  color: #110f48;\n  font-size: 1.25rem !important;\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 500 !important;\n  margin-bottom: 15px !important;\n  padding: 8px !important;\n}\n\n.Authenticator_normalText__RSQDQ {\n  color: #110f48;\n  font-size: 0.9375rem !important;\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 500 !important;\n  margin-bottom: 15px !important;\n  padding: 8px !important;\n}\n.Authenticator_listAuth__1MJkd {\n  font-size: 0.9375rem !important;\n  margin-bottom: 0.4375rem;\n}\n", "",{"version":3,"sources":["webpack://src/containers/Authenticator/Authenticator.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,6BAA6B;EAC7B,8CAA8C;EAC9C,2BAA2B;EAC3B,8BAA8B;EAC9B,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,+BAA+B;EAC/B,8CAA8C;EAC9C,2BAA2B;EAC3B,8BAA8B;EAC9B,uBAAuB;AACzB;AACA;EACE,+BAA+B;EAC/B,wBAAwB;AAC1B","sourcesContent":[".title {\n  font-family: \"AvenirNext\", sans-serif;\n  font-weight: 100;\n}\n\n.titleText {\n  color: #110f48;\n  font-size: 1.25rem !important;\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 500 !important;\n  margin-bottom: 15px !important;\n  padding: 8px !important;\n}\n\n.normalText {\n  color: #110f48;\n  font-size: 0.9375rem !important;\n  font-family: Montserrat, sans-serif !important;\n  font-weight: 500 !important;\n  margin-bottom: 15px !important;\n  padding: 8px !important;\n}\n.listAuth {\n  font-size: 0.9375rem !important;\n  margin-bottom: 0.4375rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "Authenticator_title__39JCD",
	"titleText": "Authenticator_titleText__29LOI",
	"normalText": "Authenticator_normalText__RSQDQ",
	"listAuth": "Authenticator_listAuth__1MJkd"
};
export default ___CSS_LOADER_EXPORT___;
