// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DateRange_DateRange__37q5V p {\n  padding: 0px 0px 30px 30px;\n}\n", "",{"version":3,"sources":["webpack://src/components/DateRange/DateRange.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B","sourcesContent":[".DateRange p {\n  padding: 0px 0px 30px 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DateRange": "DateRange_DateRange__37q5V"
};
export default ___CSS_LOADER_EXPORT___;
