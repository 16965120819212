import styles from "./SmallCardComponent.module.css";
import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%"
  },
  titleButton: {
    color: "#110F48",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "1.125rem",
    marginBottom: "0.9375rem"
    // marginTop: 11
  },
  details: {
    display: "flex",
    flexDirection: "row",
    width: "inherit",
    textAlign: "inherit",
    justifyContent: "inherit",
    alignItems: "flex-start"
  },
  content: {
    display: "inline-flex",
    flexDirection: "column",
    padding: "16px 32px 16px 16px"
  },
  cover: {
    width: "85px",
    height: "auto",
    display: "inline-flex",
    padding: "16px 16px 16px 32px",
    objectFit: "contain"
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

const SmallCardComponent = props => {
  const classes = useStyles();
  return (
    <div className={styles.CardComponent} style={{ display: "flex" }}>
      <Card className={classes.root} onClick={props.handleCardClick}>
        <CardActionArea className={classes.details}>
          <CardMedia
            className={classes.cover}
            component={props.imgComponent}
            alt={props.imgAlt}
            height={props.imgHeight}
            width={props.imgWidth}
            image={props.imgImage}
            title={props.imgTitle}
          />
          <CardContent className={classes.content}>
            <div className={classes.titleButton}>{props.cardTitle}</div>
            <Typography variant="body2" color="textSecondary" component="p">
              {props.contentText}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

SmallCardComponent.propTypes = {};

SmallCardComponent.defaultProps = {};

export default SmallCardComponent;
