import React, { useEffect, useState, useContext } from "react";
import { providerForGet, providerForPost } from "../../api";
import {
  franchise,
  userMe,
  updateProfile,
  usaState,
  checkEmailAvailable,
  getAllCountries,
  franchiseSites,
  countryCodeList as countries
} from "../../constants";

import {
  Auth,
  AutoCompleteDropDown,
  Input,
  SnackBar,
  DarkBlueButton,
  BorderLessButton
} from "../../components";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import ProfileSchema from "./ProfileSchema";
import useStyles from "../../utils/Styles/ManagePageStyles";
import SaveIcon from "@material-ui/icons/Save";
import { get } from "lodash";
import {
  checkAllKeysPresent,
  checkEmpty,
  getListOfKeysNotPresent,
  setErrors
} from "../../utils";
import { commonStyles } from "../../common";
import { AuthContext } from "../../context";
import PhoneInput from "react-phone-input-2";
import FormHelperText from "@material-ui/core/FormHelperText";
import Styles from "../Client/AddEditClient/AddEditClient.module.css";
const franchiseName = "franchiseName";
const franchiseSite = "franchiseSite";
const email = "email";
const full_name = "full_name";
const phone = "phone";
const address = "address";
const state = "state";
const city = "city";
const zipcode = "zipcode";
const country = "country";
const Profile = props => {
  const { setUserInfo } = useContext(AuthContext);
  const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
  const commonClass = commonStyles();
  const auth = Auth.getToken();
  const user = Auth.getUserInfo();
  const [loader, setLoader] = useState(false);
  const [formState, setFormState] = useState({
    values: {
      franchiseName: "",
      email: "",
      full_name: "",
      phone: "",

      address: "",
      state: "",
      city: "",
      zipcode: "",
      country: ""
    },
    contactId: "",
    userId: "",
    errors: {},
    franchiseList: [],
    franchiseSitesList: [],
    countryList: [],
    alert: false,
    severity: "success",
    alertMessage: ""
  });
  const [showStateDropDown, setShowStateDropDown] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const classes = useStyles();

  const getFranchiseSiteListData = async params => {
    // for getting franchise_sites list
    if (params) {
      await providerForGet(franchiseSites, { franchise: params }, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            franchiseSitesList: res.data.data
          }));
          setLoader(false);
        })
        .catch(err => {
          console.log("err", err);
          setLoader(false);
        });
    } else {
      setFormState(formState => ({
        ...formState,
        franchiseSitesList: []
      }));
    }
  };

  const getUserDetails = async () => {
    setLoader(true);
    let body = {};
    await providerForGet(franchise, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          franchiseList: res.data.data
        }));
        setLoader(false);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });

    await providerForGet(getAllCountries, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          countryList: res.data.data
        }));
        setLoader(false);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });
    await providerForGet(userMe, {}, auth)
      .then(res => {
        if (res.data !== null) {
          if (res.data.franchis) {
            getFranchiseSiteListData(res.data.franchise.id);
          }
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              franchiseName: res.data.franchise ? res.data.franchise.id : null,
              franchiseSite: res.data.franchise_site
                ? res.data.franchise_site
                : null,
              full_name: res.data.full_name,
              email: res.data.email,
              phone: res.data.phone,
              address:
                res.data.contact && res.data.contact.address
                  ? res.data.contact.address
                  : "",
              state:
                res.data.contact && res.data.contact.State
                  ? res.data.contact.State
                  : "",
              city:
                res.data.contact && res.data.contact.city
                  ? res.data.contact.city
                  : "",
              zipcode:
                res.data.contact && res.data.contact.zipcode
                  ? res.data.contact.zipcode
                  : "",
              country:
                res.data.contact &&
                res.data.contact.country &&
                res.data.contact.country.id
                  ? res.data.contact.country.id
                  : ""
            },
            // contactId: res.data.contact.id,
            userId: res.data.id
          }));
        }
      })
      .catch(err => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleChange = e => {
    e.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));

    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const handleChangeAutocompleteForFranchise = (eventName, event, value) => {
    if (value !== null) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: value.id
        },
        touched: {
          ...formState.touched,
          [eventName]: true
        }
      }));

      getFranchiseSiteListData(value.id);

      if (formState.errors.hasOwnProperty(eventName)) {
        delete formState.errors[eventName];
      }
    } else {
      delete formState.values[eventName];
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: null
        }
      }));
      getFranchiseSiteListData(null);
    }
  };

  const handleChangeAutoComplete = (eventName, event, value) => {
    if (eventName === state) {
      if (value !== null) {
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [eventName]: value.text
          },
          touched: {
            ...formState.touched,
            [eventName]: true
          }
        }));
        if (formState.errors.hasOwnProperty(eventName)) {
          delete formState.errors[eventName];
        }
      } else {
        delete formState.values[eventName];
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [eventName]: null
          }
        }));
      }
    } else if (value !== null) {
      if (eventName === country) {
        delete formState.values[state];
      }
      if (value.name === "United States" && eventName === country) {
        setShowStateDropDown(true);
      } else if (eventName === country) {
        setShowStateDropDown(false);
      }
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: value.id
        },
        touched: {
          ...formState.touched,
          [eventName]: true
        }
      }));
      if (formState.errors.hasOwnProperty(eventName)) {
        delete formState.errors[eventName];
      }
    } else {
      delete formState.values[eventName];
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: null
        }
      }));
    }
  };

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleCancel = () => {
    Object.keys(formState.errors).map(errorName => {
      delete formState.errors[errorName];
      return null;
    });

    setIsEdit(false);
    getUserDetails();
  };

  const hasError = field => (formState.errors[field] ? true : false);
  async function validate(val, name, required) {
    var code = "";
    var n = false;

    if (val) {
      if (!val.startsWith("+")) {
        val = "+" + val;
      }

      for (var i = 0; i < countries.length; i++) {
        n = val.includes(countries[i].code);
        if (n) {
          break;
        }
      }

      if (n) {
        code = countries[i].code;
      } else {
        if (required) {
          formState.errors[name] = ["Invalid mobile number"];
        }
      }
      try {
        const number = phoneUtil.parseAndKeepRawInput(val, code);
        let isValid = phoneUtil.isValidNumber(number);
        if (isValid) {
          if (val.startsWith("+")) {
            val = val.substring(1);
          }
          formState.values[name] = val;
        } else {
          formState.errors[name] = ["Invalid mobile number"];
        }
      } catch {
        if (required) {
          formState.errors[name] = ["Mobile number is required"];
        }
      }
    } else {
      if (required) {
        formState.errors[name] = ["Mobile number is required"];
      }
    }
  }
  const handleSubmit = async () => {
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      ProfileSchema
    );
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, ProfileSchema);
      await validate(formState.values[phone], phone, false);
      if (user.role.name !== process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME) {
        ProfileSchema.franchiseName.validations = {
          required: {
            value: "true",
            message: "Franchise Name is required"
          }
        };
      }
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        ProfileSchema
      );
      formState.errors = setErrors(formState.values, ProfileSchema);
    }
    if (isValid) {
      setLoader(true);
      await providerForPost(
        checkEmailAvailable,
        {
          email: formState.values.email,
          c: formState.userId
        },
        Auth.getToken()
      )
        .then(res => {
          let postData = {
            user: {
              full_name: formState.values.full_name,
              email: formState.values.email,
              username: formState.values.email,
              phone: formState.values.phone
            },
            contact: {
              full_name: formState.values.full_name,
              city: formState.values.city,
              address: formState.values.address,
              zipcode: formState.values.zipcode,
              country: parseInt(formState.values.country),
              State: formState.values.state
            }
          };

          let apiUrl = updateProfile;
          let desc = `${user.full_name} updated their profile details`;
          providerForPost(apiUrl, postData, auth, {
            desc: desc
          })
            .then(res => {
              Auth.setUserInfo(res.data.user, true);
              setIsEdit(false);
              setUserInfo(userInfo => ({
                ...userInfo,
                user: {
                  ...userInfo.user,
                  name: formState.values.full_name
                }
              }));
              getUserDetails();

              setFormState(formState => ({
                ...formState,
                alert: true,
                severity: "success",
                alertMessage: "Profile has been updated successfully"
              }));
              setLoader(false);
            })
            .catch(err => {
              console.log("error", err);
              setFormState(formState => ({
                ...formState,
                alert: true,
                severity: "error",
                alertMessage: "Error while updating profile"
              }));
              setLoader(false);
            });
        })
        .catch(err => {
          console.log("error", err["response"]);

          setLoader(false);
          setFormState(formState => ({
            ...formState,
            isValid: false,
            errors: {
              ...formState.errors,
              [email]: [err["response"]["data"]["data"]]
            }
          }));
        });
    } else {
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
      setLoader(false);
    }
  };

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      alertMessage: ""
    }));
  };

  const handlePhoneNumberChange = (value, name) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value
      },
      touched: {
        ...formState.touched,
        [name]: true
      }
    }));
    delete formState.errors[name];
  };

  return (
    <>
      <Grid>
        <SnackBar
          open={formState.alert}
          severity={formState.severity}
          onClose={handleSnackbarClose}
        >
          {formState.alertMessage}
        </SnackBar>
      </Grid>
      <Grid item xs={12} className={classes.formgrid}>
        <Card className={classes.editCard}>
          <CardContent className={classes.editCardContent}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Input
                  label={get(ProfileSchema[full_name], "label")}
                  name={full_name}
                  value={formState.values[full_name] || ""}
                  placeholder={get(ProfileSchema[full_name], "placeholder")}
                  required
                  fullWidth
                  onChange={handleChange}
                  disabled={!isEdit}
                  error={hasError(full_name)}
                  helperText={
                    hasError(full_name)
                      ? formState.errors[full_name].map(error => {
                          return error + " ";
                        })
                      : null
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Input
                  label={get(ProfileSchema[email], "label")}
                  name={email}
                  value={formState.values[email] || ""}
                  placeholder={get(ProfileSchema[email], "placeholder")}
                  variant="outlined"
                  required
                  fullWidth
                  onChange={handleChange}
                  disabled={!isEdit}
                  error={hasError(email)}
                  helperText={
                    hasError(email)
                      ? formState.errors[email].map(error => {
                          return error + " ";
                        })
                      : null
                  }
                />
              </Grid>
              <Grid item md={6} xs={12} className={Styles.ReactPhoneInput}>
                <PhoneInput
                  country={"us"}
                  value={formState.values[phone] || ""}
                  onChange={phoneData =>
                    handlePhoneNumberChange(phoneData, phone)
                  }
                  label={get(ProfileSchema[phone], "label")}
                  placeholder={get(ProfileSchema[phone], "placeholder")}
                  disabled={!isEdit}
                  inputProps={{
                    name: phone,
                    style: {
                      width: "100%",
                      border: hasError(phone) ? "1px solid red" : null,
                      color: !isEdit ? "grey" : "black"
                    }
                  }}
                />
                <FormHelperText
                  type="error"
                  visible={hasError(phone)}
                  style={{
                    "background-color": "White",
                    color: "red"
                  }}
                >
                  {formState.errors[phone]}
                </FormHelperText>
              </Grid>
              {user.role.name !==
                process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME && (
                <Grid item md={6} xs={12}>
                  <AutoCompleteDropDown
                    disabled={true}
                    options={formState.franchiseList}
                    getOptionLabel={option => option.franchise_name}
                    id={get(ProfileSchema[franchiseName], "id")}
                    value={
                      formState.franchiseList[
                        formState.franchiseList.findIndex(function (item, i) {
                          return item.id === formState.values[franchiseName];
                        })
                      ] || null
                    }
                    onChange={(event, value) => {
                      handleChangeAutocompleteForFranchise(
                        franchiseName,
                        event,
                        value
                      );
                    }}
                    renderInput={params => (
                      <Input
                        {...params}
                        id={get(ProfileSchema[franchiseName], "id")}
                        label={get(ProfileSchema[franchiseName], "label")}
                        variant="outlined"
                        error={hasError(franchiseName)}
                        helperText={
                          hasError(franchiseName)
                            ? formState.errors[franchiseName].map(error => {
                                return error + " ";
                              })
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
              )}
              {/* Showing only when client or nutritionist data is shown. */}
              {user.role.name === process.env.REACT_APP_CLIENT_ROLE_NAME ||
              user.role.name === process.env.REACT_APP_NUTRITIONIST_ROLE ? (
                <Grid item md={6} xs={12}>
                  <AutoCompleteDropDown
                    disabled={true}
                    options={formState.franchiseSitesList}
                    getOptionLabel={option => option.name}
                    id={get(ProfileSchema[franchiseSite], "id")}
                    value={
                      formState.franchiseSitesList[
                        formState.franchiseSitesList.findIndex(function (
                          item,
                          i
                        ) {
                          return item.id === formState.values[franchiseSite];
                        })
                      ] || null
                    }
                    onChange={(event, value) => {
                      handleChangeAutoComplete(franchiseSite, event, value);
                    }}
                    renderInput={params => (
                      <Input
                        {...params}
                        id={get(ProfileSchema[franchiseSite], "id")}
                        label={get(ProfileSchema[franchiseSite], "label")}
                        variant="outlined"
                        error={hasError(franchiseSite)}
                        helperText={
                          hasError(franchiseSite)
                            ? formState.errors[franchiseSite].map(error => {
                                return error + " ";
                              })
                            : null
                        }
                      />
                    )}
                  />
                </Grid>
              ) : null}
              <br />
              <Grid item md={12} xs={12}>
                <Typography variant="body1" className={classes.groupHeader}>
                  Address
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Input
                  label={get(ProfileSchema[address], "label")}
                  name={address}
                  value={formState.values[address] || ""}
                  placeholder={get(ProfileSchema[address], "placeholder")}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  disabled={!isEdit}
                  error={hasError(address)}
                  helperText={
                    hasError(address)
                      ? formState.errors[address].map(error => {
                          return error + " ";
                        })
                      : null
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <AutoCompleteDropDown
                  disabled={!isEdit}
                  options={formState.countryList}
                  getOptionLabel={option => option.name}
                  id={get(ProfileSchema[country], "id")}
                  value={
                    formState.countryList[
                      formState.countryList.findIndex(function (item, i) {
                        return item.id === formState.values[country];
                      })
                    ] || null
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete(country, event, value);
                  }}
                  renderInput={params => (
                    <Input
                      {...params}
                      id={get(ProfileSchema[country], "id")}
                      label={get(ProfileSchema[country], "label")}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {showStateDropDown ? (
                  <AutoCompleteDropDown
                    options={usaState}
                    getOptionLabel={option => option.text}
                    id={get(ProfileSchema[state], "id")}
                    value={
                      usaState[
                        usaState.findIndex(function (item, i) {
                          return item.text === formState.values[state];
                        })
                      ] || null
                    }
                    onChange={(event, value) => {
                      handleChangeAutoComplete(state, event, value);
                    }}
                    renderInput={params => (
                      <Input
                        {...params}
                        id={get(ProfileSchema[state], "id")}
                        label={get(ProfileSchema[state], "label")}
                        variant="outlined"
                      />
                    )}
                  />
                ) : (
                  <Input
                    label={get(ProfileSchema[state], "label")}
                    name={state}
                    value={formState.values[state] || ""}
                    placeholder={get(ProfileSchema[state], "placeholder")}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    disabled={!isEdit}
                    error={hasError(state)}
                    helperText={
                      hasError(state)
                        ? formState.errors[state].map(error => {
                            return error + " ";
                          })
                        : null
                    }
                  />
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Input
                  label={get(ProfileSchema[city], "label")}
                  name={city}
                  value={formState.values[city] || ""}
                  placeholder={get(ProfileSchema[city], "placeholder")}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  disabled={!isEdit}
                  error={hasError(city)}
                  helperText={
                    hasError(city)
                      ? formState.errors[city].map(error => {
                          return error + " ";
                        })
                      : null
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Input
                  label={get(ProfileSchema[zipcode], "label")}
                  name={zipcode}
                  value={formState.values[zipcode] || ""}
                  placeholder={get(ProfileSchema[zipcode], "placeholder")}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  disabled={!isEdit}
                  error={hasError(zipcode)}
                  helperText={
                    hasError(zipcode)
                      ? formState.errors[zipcode].map(error => {
                          return error + " ";
                        })
                      : null
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} className={classes.actionButtonGrid}>
        {isEdit ? (
          <div>
            <DarkBlueButton
              type="submit"
              data-testid="ok-button"
              onClick={handleSubmit}
            >
              <SaveIcon />
              &nbsp; Save
            </DarkBlueButton>

            <BorderLessButton onClick={handleCancel}>cancel</BorderLessButton>
          </div>
        ) : (
          <DarkBlueButton onClick={handleEditClick}>Edit</DarkBlueButton>
        )}
      </Grid>
      <Backdrop className={commonClass.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Profile;
