import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  LinearProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  IconButton,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import {
  Auth,
  AutoCompleteDropDown,
  Input,
  SnackBar,
  Table,
  NotFoundPage,
  DarkBlueButton,
  BorderLessButton
} from "../../components";
import {
  franchise,
  getRole,
  deleteUserContact,
  getUsersDataUrl,
  deleteClientData,
  franchiseSites,
  getFranchiseSitesForAdmin,
  verifyPassForLogs,
  getClientScheduleDates
} from "../../constants";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import useStyles from "../../utils/Styles/ManagePageStyles";
import {
  providerForDelete,
  providerForDownload,
  providerForGet,
  providerForPost
} from "../../api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { EDITUSERS, ADDUSERS, ADDCLIENT, EDITCLIENT } from "../../paths";
import {
  DELETE_MESSAGE,
  ERROR_MESSAGE
} from "../../constants/genericConstants";
import DialogBox from "../../components/DialogBox/DialogBox";
import GetAppIcon from "@material-ui/icons/GetApp";

const Users = props => {
  const propData = props["location"]["state"];
  const history = useHistory();
  const classes = useStyles();
  const [filter, setFilter] = useState({
    _sort: "full_name:asc",
    page: 1,
    pageSize: 10
  });
  const password = "password";
  const [enabledId, setEnabledId] = useState(null);
  const [openVerifyDialogBox, setOpenVerifyDialogBox] = useState(false);
  const tableRef = React.createRef();
  const auth = Auth.getToken();
  const userInfo = Auth.getUserInfo();
  const [loader, setLoader] = useState(false);
  const [passNotPresent, setPassNotPresent] = React.useState(false);
  const [formState, setFormState] = useState({
    dataTable: {
      data: [],
      page: 1,
      totalCount: 0
    },
    alert: propData ? propData["successMessage"] : false,
    errorMessage: propData ? propData["successMessage"] : false,
    severity: "success",
    isLoad: false,
    isAdmin: false,
    franchiseList: [],
    franchiseSiteList: [],
    showPassword: false,
    roleList: [],
    values: {},
    errors: {},
    inValidPass: false,
    viewUserPhone: false,
    viewUserEmail: false,
    enableUserEmail: false,
    enableUserPhone: false,
    booleanList: [
      { id: true, value: "Yes" },
      { id: false, value: "No" }
    ],
    activeList: [
      { id: true, value: "Yes" },
      { id: "false", value: "No" },
      { id: "true", value: "One Time Client" }
    ],
    is2FaList: [
      { id: true, value: "Yes" },
      { id: false, value: "No" }
    ]
  });
  const onDisableUserEmail = async (rowData, disablingAccessorEmail) => {
    setEnabledId(null);
  };

  const onClickUserEmail = async (rowData, viewingUserEmail) => {
    setFormState(formState => ({
      ...formState,
      viewUserPhone: false,
      viewUserEmail: false,
      enableUserEmail: false,
      enableUserPhone: false,
      showPassword: false
    }));
    setEnabledId(rowData.id);
    setOpenVerifyDialogBox(true);
    if (viewingUserEmail) {
      setFormState(formState => ({ ...formState, viewUserEmail: true }));
    } else {
      setFormState(formState => ({ ...formState, viewUserPhone: true }));
    }
  };
  const columns = [
    { title: "Name", field: "full_name" },
    { title: "Role", field: "role" },

    {
      title: "Email",
      field: "email",
      render: rowData => {
        return (
          <>
            {(rowData.userTagList && rowData.userTagList.includes("VVIP")) ||
            (rowData.userTagList && rowData.userTagList.includes("VIP")) ? (
              enabledId &&
              enabledId === rowData.id &&
              formState.enableUserEmail ? (
                <>
                  {rowData.email}
                  <VisibilityOffIcon
                    style={{ textAlign: "center", color: "gray" }}
                    iconProps={classes.icon}
                    onClick={event => {
                      onDisableUserEmail(rowData, true);
                    }}
                  />
                </>
              ) : (
                <VisibilityIcon
                  style={{ textAlign: "center", color: "#1C4979" }}
                  onClick={event => {
                    onClickUserEmail(rowData, true);
                  }}
                />
              )
            ) : (
              <>{rowData.email}</>
            )}
          </>
        );
      },
      width: "100%"
    },
    {
      title: "Phone",
      field: "phone",
      render: rowData => {
        return (
          <>
            {(rowData.userTagList && rowData.userTagList.includes("VVIP")) ||
            (rowData.userTagList && rowData.userTagList.includes("VIP")) ? (
              enabledId &&
              enabledId === rowData.id &&
              formState.enableUserPhone ? (
                <>
                  {rowData.phone
                    ? rowData.phone.includes("+")
                      ? rowData.phone
                      : `+${rowData.phone}`
                    : ""}
                  <VisibilityOffIcon
                    style={{ textAlign: "center", color: "gray" }}
                    iconProps={classes.icon}
                    onClick={event => {
                      onDisableUserEmail(rowData, true);
                    }}
                  />
                </>
              ) : (
                <VisibilityIcon
                  style={{ textAlign: "center", color: "#1C4979" }}
                  onClick={event => {
                    onClickUserEmail(rowData, false);
                  }}
                />
              )
            ) : (
              <>
                {rowData.phone
                  ? rowData.phone.includes("+")
                    ? rowData.phone
                    : `+${rowData.phone}`
                  : ""}
              </>
            )}
          </>
        );
      },
      width: "100%"
    },
    { title: "2FA enabled", field: "is2FAEnabled" },
    {
      title: "Active/One Time Client",
      field: "active"
    },
    { title: "Do Not Share", field: "do_not_share" },
    { title: "Franchise", field: "franchise" },
    { title: "Franchise Site", field: "franchise_site" }
  ];

  const handleClickShowPassword = () => {
    setFormState({
      ...formState,
      showPassword: !formState.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleChange = e => {
    setPassNotPresent(false);
    setFormState(formState => ({
      ...formState,
      inValidPass: false,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value
      }
    }));
    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const onClickVerify = async () => {
    let isValid = false;
    setFormState(formState => ({
      ...formState,
      inValidPass: false
    }));

    if (formState.values.password && formState.values.password.length > 0) {
      isValid = true;
    } else {
      setPassNotPresent(true);
    }
    if (isValid) {
      setLoader(true);
      let payLoad = {
        password: formState.values.password
      };
      providerForPost(verifyPassForLogs, payLoad, Auth.getToken(), {
        desc: "Verify password for viewing user's email"
      })
        .then(res => {
          setFormState(formState => ({
            ...formState,
            enableUserEmail: formState.viewUserEmail ? true : false,
            enableUserPhone: formState.viewUserPhone ? true : false
          }));
          delete formState.values[password];
          setOpenVerifyDialogBox(false);
          setLoader(false);
        })
        .catch(err => {
          setEnabledId(null);
          setFormState(formState => ({
            ...formState,
            inValidPass: true
          }));
          setLoader(false);
        });
    }
    tableRef.current.onQueryChange();
  };

  const hasError = field => (formState.errors[field] ? true : false);

  const closeDialogBox = () => {
    setOpenVerifyDialogBox(false);
    delete formState.values[password];
    setFormState(formState => ({
      ...formState,
      inValidPass: false
    }));
    setPassNotPresent(false);
  };

  const [isSuperAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
      ? true
      : false
  );

  const getListData = async () => {
    let body = {};

    await providerForGet(franchise, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          franchiseList: res.data.data
        }));
      })
      .catch(err => {
        console.log("err", err);
      });

    let api = "";
    if (isSuperAdmin) {
      api = franchiseSites;
    } else {
      api = getFranchiseSitesForAdmin;
    }

    //getting franchise site list
    let parameters = { pageSize: -1 };
    await providerForGet(api, parameters, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          franchiseSiteList: res.data.data
        }));
      })
      .catch(err => {
        console.log("err", err);
      });

    await providerForGet(getRole, body, auth)
      .then(res => {
        var filteredRoles = res.data.roles.filter(function (item) {
          return item.name !== "Authenticated" && item.name !== "Public";
        });
        setFormState(formState => ({
          ...formState,
          roleList: filteredRoles
        }));
        setLoader(false);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getListData();
  }, []);

  /**
   ** Use key names exactly same as API response,they'll be used for filtering & sorting purpose
   * */
  const convertTableData = res => {
    let arr = [];
    if (res && res.length > 0) {
      for (let i in res) {
        let tempArr = {};
        tempArr["id"] = res[i].id;
        tempArr["username"] = res[i].username;
        tempArr["full_name"] = res[i].full_name;
        tempArr["email"] = res[i].email;
        tempArr["phone"] = res[i].phone;
        tempArr["role"] = res[i].role ? res[i].role.name : "";
        tempArr["active"] =
          res[i].isOneTimeClient === true
            ? "One Time Client"
            : res[i].active
            ? "Yes"
            : "No";
        tempArr["do_not_share"] = res[i].do_not_share ? "Yes" : "No";
        tempArr["franchise"] = res[i].franchise
          ? res[i].franchise.franchise_name
          : "";
        tempArr["franchise_site"] = res[i].franchise_site
          ? res[i].franchise_site.name
          : "";
        tempArr["franchiseId"] = res[i].franchise ? res[i].franchise.id : "";
        tempArr["isOneTimeClient"] =
          res[i].isOneTimeClient === true ? "Yes" : "No";
        tempArr["is2FAEnabled"] = res[i].is2FAEnabled === true ? "Yes" : "No";
        tempArr["contactId"] = res[i].contact ? res[i].contact.id : null;
        tempArr["userTagList"] = res[i].userTagList;

        arr.push(tempArr);
      }
      return arr;
    } else {
      return [];
    }
  };

  /**
   * * get's user data
   * @param {*} page
   * @param {*} pageSize
   */

  const getUsersData = async (page, pageSize) => {
    /**
     ** It sets the page and pageSize in filter variable
     ** so as to keep the record of what page and pageSize was set
     */

    let params = { page: page, pageSize: pageSize };
    Object.keys(filter).map(res => {
      if (!params.hasOwnProperty(res)) {
        params[res] = filter[res];
      }
      return null;
    });
    params.desc = `${userInfo.full_name} viewed the users listing page`;
    return new Promise((resolve, reject) => {
      fetch(getUsersDataUrl + "?" + new URLSearchParams(params), {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth
        }
      })
        .then(response => response.json())
        .then(result => {
          resolve({
            data: convertTableData(result.data),
            page: result.page - 1,
            totalCount: result.totalCount
          });
        });
    });
  };

  const handleClickOpen = rowData => {
    let data = {};
    data["id"] = rowData.id;
    if (rowData.role === process.env.REACT_APP_CLIENT_ROLE_NAME) {
      data["id"] = rowData.id;
      data["fromAdmin"] = true;
      data["franchiseId"] = rowData.franchiseId;
      history.push(EDITCLIENT, data);
    } else {
      data["editData"] = true;
      data["role"] = rowData.role;
      history.push(EDITUSERS, data);
    }
  };

  const handleFilterChange = event => {
    if (event.target.value !== "") {
      setFilter(filter => ({
        ...filter,
        [event.target.name]: event.target.value
      }));
    } else {
      delete filter[event.target.name];
      setFilter(filter => ({
        ...filter
      }));
    }
  };

  const handleFilterAutoCompleteChange = (eventName, event, value) => {
    if (eventName === "is2FAEnabled") {
      if (value !== null) {
        setFilter(filter => ({
          ...filter,
          [eventName]: value.id
        }));
      } else {
        delete filter[eventName];
        setFilter(filter => ({
          ...filter
        }));
      }
    }

    if (eventName === "active") {
      if (value !== null && value.id === true) {
        setFilter(filter => ({
          ...filter,
          [eventName]: value.id,
          isOneTimeClient: false
        }));
      } else {
        delete filter[eventName];
        delete filter["isOneTimeClient"];
        setFilter(filter => ({
          ...filter
        }));
      }
      if (value !== null && value.id === "false") {
        setFilter(filter => ({
          ...filter,
          [eventName]: value.id
        }));
      } else {
        delete filter[eventName];
        setFilter(filter => ({
          ...filter
        }));
      }

      if (value !== null && value.value === "One Time Client") {
        setFilter(filter => ({
          ...filter,
          isOneTimeClient: "true"
        }));
      } else {
        delete filter["isOneTimeClient"];
        setFilter(filter => ({
          ...filter
        }));
      }
    } else if (value !== null) {
      setFilter(filter => ({
        ...filter,
        [eventName]: value.id
      }));
    } else {
      delete filter[eventName];
      setFilter(filter => ({
        ...filter
      }));
    }
  };

  const orderFunc = (columnId, direction) => {
    let orderByColumn;
    let orderBy = "";
    if (columnId >= 0) {
      orderByColumn = columns[columnId]["field"];
    }
    orderBy = orderByColumn + ":" + direction;
    setFilter(filter => ({
      ...filter,
      _sort: orderBy
    }));
    tableRef.current.onQueryChange();
  };

  const handleReset = event => {
    delete filter["full_name_contains"];
    delete filter["role"];
    delete filter["phone_contains"];
    delete filter["is2FAEnabled"];
    delete filter["franchise"];
    delete filter["do_not_share"];
    delete filter["active"];
    delete filter["isOneTimeClient"];
    delete filter["franchise_site"];

    setFilter(filter => ({
      ...filter
    }));

    getUsersData(1, filter.pageSize);
    tableRef.current.onQueryChange();
  };

  const handleSnackbarClose = () => {
    console.log("propData--", propData);
    if (propData) {
      propData["successMessage"] = false;
    }
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "success"
    }));
  };

  const handleGiveScheduleDates = async rowData => {
    setLoader(true);
    await providerForDownload(
      getClientScheduleDates,
      {
        c: rowData.id
      },
      Auth.getToken(),
      {
        desc: "Download client schedule dates",
        audit_log_clientid: rowData.id
      }
    )
      .then(res => {
        setLoader(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Pii Information.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  return (
    <div>
      {!formState.dataTable.data && loader ? (
        <LinearProgress />
      ) : !formState.dataTable.data && !loader ? (
        <NotFoundPage />
      ) : (
        <>
          <Typography variant="h4" className={classes.titleText}>
            Manage Users
          </Typography>

          <Grid>
            <SnackBar
              open={formState.alert}
              severity={formState.severity}
              onClose={handleSnackbarClose}
            >
              {formState.errorMessage}
            </SnackBar>
          </Grid>

          <Grid container spacing={2}>
            <Grid item sm={12} className={classes.addButton}>
              <DarkBlueButton
                onClick={() => {
                  let data = {};
                  data["fromAdmin"] = true;
                  history.push(ADDCLIENT, data);
                }}
              >
                <AddIcon /> &nbsp;
                {"Add Client"}
              </DarkBlueButton>
              <DarkBlueButton
                onClick={() => {
                  let data = { addNutritionist: true };
                  history.push(ADDUSERS, data);
                }}
              >
                <AddIcon /> &nbsp;
                {"Add Nutritionist"}
              </DarkBlueButton>
              {isSuperAdmin && (
                <DarkBlueButton
                  onClick={() => {
                    history.push({ pathname: ADDUSERS, addAdmin: true });
                  }}
                >
                  <AddIcon /> &nbsp; Add Admin
                </DarkBlueButton>
              )}
            </Grid>
          </Grid>

          <Card className={classes.paperFilter}>
            <CardContent className={classes.Cardtheming}>
              <Grid className={classes.filterOptions} container spacing={2}>
                <Grid item>
                  <Input
                    label={"Name"}
                    placeholder="Name"
                    name="full_name_contains"
                    value={
                      filter.full_name_contains ? filter.full_name_contains : ""
                    }
                    variant="outlined"
                    onChange={handleFilterChange}
                  />
                </Grid>
                <Grid item>
                  <AutoCompleteDropDown
                    options={formState.roleList}
                    getOptionLabel={option => option.name}
                    id={"role"}
                    value={
                      formState.roleList[
                        formState.roleList.findIndex(function (item, i) {
                          return item.id === filter.role;
                        })
                      ] || null
                    }
                    onChange={(event, value) => {
                      handleFilterAutoCompleteChange("role", event, value);
                    }}
                    renderInput={params => (
                      <Input
                        variant="outlined"
                        {...params}
                        style={{ "min-width": "223px" }}
                        name={"role"}
                        id={"role"}
                        label={"Role"}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Input
                    label={"Phone Number"}
                    placeholder="Phone Number"
                    name="phone_contains"
                    value={filter.phone_contains ? filter.phone_contains : ""}
                    variant="outlined"
                    onChange={handleFilterChange}
                  />
                </Grid>
                <Grid item>
                  <AutoCompleteDropDown
                    options={formState.is2FaList}
                    getOptionLabel={option => option.value}
                    id={"is2FAList"}
                    value={
                      formState.is2FaList[
                        formState.is2FaList.findIndex(function (item, i) {
                          return item.id === filter.is2FAEnabled;
                        })
                      ] || null
                    }
                    onChange={(event, value) => {
                      handleFilterAutoCompleteChange(
                        "is2FAEnabled",
                        event,
                        value
                      );
                    }}
                    renderInput={params => (
                      <Input
                        variant="outlined"
                        style={{ "min-width": "223px" }}
                        {...params}
                        name="is2FAEnabled"
                        id={"is2FAEnabled"}
                        label={"2FAEnabled"}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  {isSuperAdmin && (
                    <AutoCompleteDropDown
                      options={formState.franchiseList}
                      getOptionLabel={option => option.franchise_name}
                      id={"franchiseName"}
                      value={
                        formState.franchiseList[
                          formState.franchiseList.findIndex(function (item, i) {
                            return item.id === filter.franchise;
                          })
                        ] || null
                      }
                      onChange={(event, value) => {
                        handleFilterAutoCompleteChange(
                          "franchise",
                          event,
                          value
                        );
                      }}
                      renderInput={params => (
                        <Input
                          variant="outlined"
                          {...params}
                          style={{ "min-width": "223px" }}
                          name={franchise}
                          id={"franchiseName"}
                          label={"Franchise"}
                        />
                      )}
                    />
                  )}
                </Grid>

                <Grid item>
                  <AutoCompleteDropDown
                    options={formState.franchiseSiteList}
                    getOptionLabel={option => option.name}
                    id={"franchiseSite"}
                    value={
                      formState.franchiseSiteList[
                        formState.franchiseSiteList.findIndex(function (
                          item,
                          i
                        ) {
                          return item.id === filter.franchise_site;
                        })
                      ] || null
                    }
                    onChange={(event, value) => {
                      handleFilterAutoCompleteChange(
                        "franchise_site",
                        event,
                        value
                      );
                    }}
                    renderInput={params => (
                      <Input
                        variant="outlined"
                        {...params}
                        style={{ "min-width": "223px" }}
                        name={"franchise_site"}
                        id={"franchiseSite"}
                        label={"Franchise Site"}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <AutoCompleteDropDown
                    options={formState.activeList}
                    getOptionLabel={option => option.value}
                    id={"active"}
                    value={
                      filter.active
                        ? formState.activeList[
                            formState.activeList.findIndex(function (item, i) {
                              return item.id === filter.active;
                            })
                          ] || null
                        : formState.activeList[
                            formState.activeList.findIndex(function (item, i) {
                              return item.id === filter.isOneTimeClient;
                            })
                          ] || null
                    }
                    onChange={(event, value) => {
                      handleFilterAutoCompleteChange("active", event, value);
                    }}
                    renderInput={params => (
                      <Input
                        variant="outlined"
                        {...params}
                        style={{ "min-width": "223px" }}
                        name={"active"}
                        id={"active"}
                        label={"Active/One Time Client"}
                      />
                    )}
                  />
                </Grid>

                <Grid item>
                  <AutoCompleteDropDown
                    options={formState.booleanList}
                    getOptionLabel={option => option.value}
                    id={"do_not_share"}
                    value={
                      formState.booleanList[
                        formState.booleanList.findIndex(function (item, i) {
                          return item.id === filter.do_not_share;
                        })
                      ] || null
                    }
                    onChange={(event, value) => {
                      handleFilterAutoCompleteChange(
                        "do_not_share",
                        event,
                        value
                      );
                    }}
                    renderInput={params => (
                      <Input
                        variant="outlined"
                        {...params}
                        style={{ "min-width": "223px" }}
                        name={"do_not_share"}
                        id={"do_not_share"}
                        label={"Do not share"}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <DarkBlueButton
                    disableElevation
                    onClick={() => {
                      setFormState(formState => ({
                        ...formState,
                        isLoad: true
                      }));
                      tableRef.current.onQueryChange();
                    }}
                  >
                    search
                  </DarkBlueButton>
                </Grid>
                <Grid item>
                  <BorderLessButton onClick={handleReset} disableElevation>
                    reset{" "}
                  </BorderLessButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Table
            tableRef={tableRef}
            columns={columns}
            data={async query => {
              return await getUsersData(query.page + 1, query.pageSize);
            }}
            localization={{
              body: {
                editRow: {
                  deleteText: `Are you sure you want to delete this User?`,
                  saveTooltip: "Delete"
                }
              }
            }}
            actions={[
              rowData => ({
                icon: () => <EditOutlinedIcon />,
                tooltip: "Edit",
                disabled: rowData.id === Auth.getUserInfo().id,
                onClick: (event, rowData) => {
                  handleClickOpen(rowData);
                }
              }),
              rowData => ({
                icon: () => (
                  <GetAppIcon style={{ color: "#1C4979", fontSize: 30 }} />
                ),
                tooltip: "PII Information",
                disabled: rowData.role !== "Client",
                onClick: (event, rowData) => {
                  handleGiveScheduleDates(rowData);
                }
              })
            ]}
            editable={{
              isEditable: rowData => rowData.id !== Auth.getUserInfo().id,
              isDeletable: rowData => rowData.id !== Auth.getUserInfo().id,
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(async () => {
                    if (
                      oldData.role === process.env.REACT_APP_CLIENT_ROLE_NAME
                    ) {
                      let dataToSend = {
                        id: oldData.id
                      };
                      await providerForPost(
                        deleteClientData,
                        dataToSend,
                        auth,
                        {
                          desc: `${userInfo.role.name} ${userInfo.full_name} deleted client ${oldData.full_name}`,
                          audit_log_clientid: oldData.id
                        }
                      )
                        .then(async res => {
                          setFormState(formState => ({
                            ...formState,
                            alert: true,
                            errorMessage:
                              "User " + res.data.full_name + DELETE_MESSAGE,
                            severity: "success"
                          }));
                        })
                        .catch(err => {
                          setFormState(formState => ({
                            ...formState,
                            alert: true,
                            errorMessage: ERROR_MESSAGE + err,
                            severity: "error"
                          }));
                        });
                    } else {
                      await providerForDelete(
                        deleteUserContact,
                        oldData.id,
                        auth,
                        {
                          desc: `${userInfo.role.name} ${userInfo.full_name} deleted ${oldData.role} ${oldData.full_name}`,
                          audit_log_clientid: oldData.id
                        }
                      )
                        .then(res => {
                          setFormState(formState => ({
                            ...formState,
                            alert: true,
                            errorMessage:
                              "User " + res.data.full_name + DELETE_MESSAGE,
                            severity: "success"
                          }));
                        })
                        .catch(err => {
                          console.log("error", err);
                          setFormState(formState => ({
                            ...formState,
                            alert: true,
                            errorMessage: ERROR_MESSAGE + err,
                            severity: "error"
                          }));
                        });
                    }

                    resolve();
                  }, 1000);
                })
            }}
            options={{
              pageSize: 10,
              actionsColumnIndex: -1,
              search: false,
              sorting: true,
              thirdSortClick: false
            }}
            onOrderChange={(orderedColumnId, orderDirection) => {
              orderFunc(orderedColumnId, orderDirection);
            }}
          />
          <DialogBox
            open={openVerifyDialogBox}
            title="Enter password"
            buttonCancel="Cancel"
            handleCancel={closeDialogBox}
            buttonOk="Enter"
            handleOk={onClickVerify}
          >
            <Grid container spacing={2}>
              <Backdrop className={classes.backdrop} open={loader}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Grid item md={12} xs={12}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{ marginTop: "10px" }}
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    fullWidth
                    error={
                      hasError(password) ||
                      formState.inValidPass ||
                      passNotPresent
                    }
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    label="Password"
                    name={password}
                    type={formState.showPassword ? "text" : "password"}
                    value={formState.values[password] || ""}
                    onChange={handleChange}
                    fullWidth
                    error={
                      hasError(password) ||
                      formState.inValidPass ||
                      passNotPresent
                    }
                    endAdornment={
                      <InputAdornment
                        position="end"
                        error={
                          hasError(password) ||
                          formState.inValidPass ||
                          passNotPresent
                        }
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {formState.showPassword ? (
                            <Visibility style={{ color: "#1C4979" }} />
                          ) : (
                            <VisibilityOff style={{ color: "gray" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  ></OutlinedInput>
                  <FormHelperText
                    error={hasError(password) || formState.inValidPass}
                    style={{ fontSize: "larger" }}
                    error={
                      hasError(password) ||
                      formState.inValidPass ||
                      passNotPresent
                    }
                  >
                    {hasError(password)
                      ? formState.errors[password].map(error => {
                          return error + " ";
                        })
                      : formState.inValidPass
                      ? "Invalid Password"
                      : passNotPresent
                      ? "Please enter the password"
                      : null}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </DialogBox>
          <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </div>
  );
};
Users.propTypes = {};

Users.defaultProps = {};

export default Users;
