import React, { forwardRef, useState } from "react";
import { Backdrop, CircularProgress, Grid } from "@material-ui/core";
import { EX_TEMPLATE } from "../../paths";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Auth,
  BorderLessButton,
  LightGrayButton,
  SearchBar,
  SnackBar,
  Table,
  HeadingOne,
  DialogForGettingMealNumbers
} from "../../components";

import {
  getExchangesAndMenuTemplate,
  createMealTemplate,
  deleteExchangeMealTemplateData
} from "../../constants";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import {
  DELETE_MESSAGE,
  ERROR_MESSAGE
} from "../../constants/genericConstants";
import { providerForPost } from "../../api";
import { useStyles as ManagePageStyles } from "../../utils";

const ManageExchangeTemplate = props => {
  const [loader, setLoader] = useState(false);
  const propData = props["location"]["state"];
  const history = useHistory();
  const auth = Auth.getToken();

  const [meals, setMeals] = useState({
    noOfMeals: 6,
    dialogOpenClose: false,
    isError: false,
    helperText: "",
    FavouriteNameValue: "",
    helperTextFavouriteName: "",
    isNameError: false
  });

  const managePageStyles = ManagePageStyles();

  const [filter, setFilter] = useState({
    _sort: "template_name:asc"
  });
  const tableRef = React.createRef();

  let apiUrl = "";
  const columns = [{ title: "Name", field: "name" }];

  const [formState, setFormState] = useState({
    dataTable: {
      data: [],
      page: 1,
      totalCount: 0
    },
    alert: propData ? propData["successMessage"] : false,
    errorMessage: propData ? propData["successMessage"] : false,
    severity: "success",
    isLoad: false,
    franchiseList: []
  });

  /**
   ** Use key names exactly same as API response,they'll be used for filtering & sorting purpose
   * */
  const convertTableData = res => {
    let arr = [];
    if (res && res.length > 0) {
      for (let i in res) {
        let tempArr = {};
        tempArr["id"] = res[i].id;
        tempArr["name"] = res[i].template_name;
        arr.push(tempArr);
      }
      return arr;
    } else {
      return [];
    }
  };

  const getExchangeMealTemplateData = async (page, pageSize) => {
    /**
     ** It sets the page and pageSize in filter variable
     ** so as to keep the record of what page and pageSize was set
     */
    apiUrl = getExchangesAndMenuTemplate;

    setFilter(filter => ({
      ...filter,
      page: page,
      pageSize: pageSize
    }));
    let params = { page: page, pageSize: pageSize };
    Object.keys(filter).map(res => {
      if (!params.hasOwnProperty(res)) {
        params[res] = filter[res];
      }
      return null;
    });
    params.desc = "Get Exchange Meal template data";
    return new Promise((resolve, reject) => {
      fetch(apiUrl + "?" + new URLSearchParams(params), {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth
        }
      })
        .then(response => response.json())
        .then(result => {
          resolve({
            data: convertTableData(result.data),
            page: result.page - 1,
            totalCount: result.totalCount
          });
        });
    });
  };

  const handleClickOpen = rowData => {
    history.push({
      pathname: EX_TEMPLATE,
      search: `s=${rowData.id}`,
      isValid: true,
      editData: true
    });
  };

  const handleFilterChange = event => {
    if (event.target.value !== "") {
      setFilter(filter => ({
        ...filter,
        [event.target.name]: event.target.value
      }));
    } else {
      delete filter[event.target.name];
      setFilter(filter => ({
        ...filter
      }));
    }
  };
  const orderFunc = (columnId, direction) => {
    let orderByColumn;
    let orderBy = "";
    if (columnId >= 0) {
      orderByColumn = columns[columnId]["field"];
    }
    orderBy = orderByColumn + ":" + direction;
    setFilter(filter => ({
      ...filter,
      _sort: orderBy
    }));
    tableRef.current.onQueryChange();
  };

  const handleSearch = event => {
    setFormState(formState => ({
      ...formState,
      isLoad: true
    }));
    tableRef.current.onQueryChange();
  };

  const handleReset = event => {
    delete filter["template_name_contains"];
    setFilter(filter => ({
      ...filter
    }));
    getExchangeMealTemplateData(1, filter.pageSize);
    tableRef.current.onQueryChange();
  };

  const handleSnackbarClose = () => {
    if (propData) {
      propData["successMessage"] = false;
    }
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "success"
    }));
  };

  const handleCloseGetNoOfMealsDialogOpen = () => {
    setMeals(meals => ({
      ...meals,
      dialogOpenClose: false
    }));
  };

  const handleAddTemplate = () => {
    setMeals(meals => ({
      ...meals,
      dialogOpenClose: true,
      helperText: "",
      isError: false,
      noOfMeals: 6,
      isNameError: false,
      FavouriteNameValue: "",
      helperTextFavouriteName: ""
    }));
  };

  const handleChangeNoOfMealsPerDay = e => {
    let isError = false;
    let text = "";
    if (
      parseInt(e.target.value) === 0 ||
      parseInt(e.target.value) === 100 ||
      parseInt(e.target.value) > 100 ||
      parseInt(e.target.value) < 0
    ) {
      isError = true;
      text = "Meals can be between 1 - 99";
    }
    setMeals(meals => ({
      ...meals,
      noOfMeals: e.target.value,
      isError: isError,
      helperText: text
    }));
  };

  const handleChangeFavouriteName = e => {
    let val = !e.target.value || /^\s*$/.test(e.target.value);
    setMeals(meals => ({
      ...meals,
      FavouriteNameValue: e.target.value,
      helperTextFavouriteName: !val ? "" : meals.helperTextFavouriteName,
      isNameError: !val ? false : meals.isNameError
    }));
  };

  const createSchedule = async () => {
    let helperTextNoOFMeals = "";
    let helperTextTemplateName = "";
    let isError1 = false;
    let isError = false;
    let val1 = !meals.noOfMeals || /^\s*$/.test(meals.noOfMeals);
    let val2 =
      !meals.FavouriteNameValue || /^\s*$/.test(meals.FavouriteNameValue);
    if (val1 || val2) {
      if (val1) {
        isError = true;
        helperTextNoOFMeals = "No of meals required";
      }
      if (val2) {
        isError1 = true;
        helperTextTemplateName = "Favorite name required";
      }
      setMeals(meals => ({
        ...meals,
        helperText: helperTextNoOFMeals,
        isError: isError,
        helperTextFavouriteName: helperTextTemplateName,
        isNameError: isError1
      }));
    } else {
      setLoader(true);

      await providerForPost(
        createMealTemplate,
        {
          templateName: meals.FavouriteNameValue,
          mealsPerDay: meals.noOfMeals
        },
        Auth.getToken(),
        { desc: "Create New Exchange/Menu Favorite" }
      )
        .then(res => {
          setMeals(meals => ({
            ...meals,
            dialogOpenClose: false,
            helperText: "",
            isError: false,
            helperTextFavouriteName: "",
            isNameError: false
          }));
          setLoader(false);
          history.push({
            pathname: EX_TEMPLATE,
            search: `s=${res.data.id}`,
            isValid: true
          });
        })
        .catch(error => {
          //add error
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setFormState(formState => ({
              ...formState,
              alert: true,
              errorMessage: ERROR_MESSAGE + error.response.data.message,
              severity: "error"
            }));
          } else {
            setMeals(meals => ({
              ...meals,
              dialogOpenClose: false,
              helperText: "",
              isError: false,
              helperTextFavouriteName: "",
              isNameError: false
            }));
          }
          setLoader(false);
        });
    }
  };

  return (
    <>
      <div data-testid="clients">
        <div>
          <Grid
            container
            spacing={3}
            style={{ justifyContent: "flex-end", marginTop: "2%" }}
          >
            <Grid item>
              <LightGrayButton
                variant="contained"
                onClick={() => handleAddTemplate()}
              >
                Add New Favorite
              </LightGrayButton>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <HeadingOne>Manage Exchange's/Menus Favorite</HeadingOne>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
          <Grid>
            <SnackBar
              open={formState.alert}
              severity={formState.severity}
              onClose={handleSnackbarClose}
            >
              {formState.errorMessage}
            </SnackBar>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <SearchBar
                handleFilterChange={handleFilterChange}
                filter={
                  filter.template_name_contains
                    ? filter.template_name_contains
                    : ""
                }
                name="template_name_contains"
                placeholder="Search by Favorite Name"
                handleSearch={handleSearch}
                handleReset={handleReset}
              />
            </Grid>
          </Grid>

          <DialogForGettingMealNumbers
            open={meals.dialogOpenClose}
            handleClose={handleCloseGetNoOfMealsDialogOpen}
            createSchedule={createSchedule}
            createTemplate={true}
            textbox1={{
              error: meals.isNameError,
              handleChangeFavouriteName: handleChangeFavouriteName,
              id: "favourite_name",
              FavouriteNameValue: meals.FavouriteNameValue,
              helperTextFavouriteName: meals.helperTextFavouriteName,
              placeholder: "Favorite name"
            }}
            textbox={{
              error: meals.isError,
              handleChange: handleChangeNoOfMealsPerDay,
              id: "no_of_meals_per_day",
              helperText: meals.helperText,
              value: meals.noOfMeals,
              placeholder: "Meal no"
            }}
          />

          <Table
            tableRef={tableRef}
            columns={columns}
            data={async query => {
              return await getExchangeMealTemplateData(
                query.page + 1,
                query.pageSize
              );
            }}
            localization={{
              body: {
                editRow: {
                  deleteText:
                    "Are you sure you want to delete this Exchange/Meal Favorite?",
                  saveTooltip: "Delete"
                }
              }
            }}
            actions={[
              {
                icon: () => <BorderLessButton>Edit</BorderLessButton>,
                tooltip: "Edit",
                onClick: (event, rowData) => {
                  handleClickOpen(rowData);
                }
              }
            ]}
            icons={{
              Delete: forwardRef((props, ref) => (
                <DeleteOutlineIcon {...props} ref={ref}></DeleteOutlineIcon>
              ))
            }}
            editable={{
              onRowDelete: oldData =>
                new Promise(resolve => {
                  setTimeout(async () => {
                    let postData = {
                      exchangeMealTemplateId: oldData.id,
                      desc: "Delete Exchange/Meal Template"
                    };
                    await providerForPost(
                      deleteExchangeMealTemplateData,
                      postData,
                      auth
                    )
                      .then(async res => {
                        setFormState(formState => ({
                          ...formState,
                          alert: true,
                          errorMessage:
                            res.data.data.template_name + DELETE_MESSAGE,
                          severity: "success"
                        }));
                        await getExchangeMealTemplateData(1, filter.pageSize);
                      })
                      .catch(err => {
                        console.log("error", err);
                        setFormState(formState => ({
                          ...formState,
                          alert: true,
                          errorMessage: ERROR_MESSAGE + err,
                          severity: "error"
                        }));
                      });
                    resolve();
                  }, 1000);
                })
            }}
            options={{
              actionsColumnIndex: -1,
              search: false,
              sorting: true,
              pageSize: 10,
              thirdSortClick: false,
              headerStyle: {
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "#8A8A97",
                borderBottom: "solid #E0E0E0 2px",
                fontSize: "1rem"
              },
              cellStyle: {
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "#000000",
                fontSize: "1rem"
              },
              paginationStyle: {
                justifyContent: "center"
              }
            }}
            onOrderChange={(orderedColumnId, orderDirection) => {
              orderFunc(orderedColumnId, orderDirection);
            }}
          />
        </div>
        <Backdrop className={managePageStyles.backdrop} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
};

export default ManageExchangeTemplate;
