import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Collapse
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import {
  sideDrawerMenus,
  sideDrawerMenusForAdmin,
  sideDrawerMenusForSuperAdmin,
  nutritionistClientView,
  selectFranchise
} from "./menu";
import styles from "./SideMenus.module.css";
import auth from "../../../components/Auth";

const SideMenus = props => {
  const [openMenu, setOpenMenu] = useState({});
  const [openSub, setOpenSub] = useState(false);
  const [sideMenus, setSideMenus] = useState([]);

  /** Setting what to show in side menu depending on the role */
  useEffect(() => {
    if (auth.getUserInfo().franchise) {
      if (
        auth.getUserInfo().role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
      ) {
        setSideMenus(sideDrawerMenus);
      } else if (
        auth.getUserInfo().role.name ===
        process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
      ) {
        setSideMenus(sideDrawerMenusForSuperAdmin);
      } else if (
        auth.getUserInfo().role.name === process.env.REACT_APP_ADMIN_ROLE_NAME
      ) {
        setSideMenus(sideDrawerMenusForAdmin);
      } else if (
        auth.getUserInfo().role.name === process.env.REACT_APP_NUTRITIONIST_ROLE
      ) {
        setSideMenus(nutritionistClientView);
      }
    } else {
      if (
        auth.getUserInfo().role.name ===
        process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
      ) {
        setSideMenus(sideDrawerMenusForSuperAdmin);
      } else {
        setSideMenus(selectFranchise);
      }
    }
  }, [auth.getToken()]);

  useEffect(() => {
    for (let i in sideMenus) {
      if (sideMenus[i].children) {
        setOpenMenu(openMenu => ({
          ...openMenu,
          [sideMenus[i].path]: false
        }));
      }
    }
  }, [sideMenus]);

  /**
   * While accessing sub menus direct from URL the main menu should be open
   * eg. if user enters url ("/food-category") then food menu should be opened
   */

  const handleToggle = (path, title, children) => {
    for (let i in sideMenus) {
      if (sideMenus[i].path !== path) {
        setOpenMenu(openMenu => ({
          ...openMenu,
          [sideMenus[i].path]: false
        }));
      }
    }
    if (children && children.length > 0) {
      if (openMenu[path] === true) {
        setOpenMenu(openMenu => ({
          ...openMenu,
          [path]: false
        }));
      } else {
        setOpenMenu(openMenu => ({
          ...openMenu,
          [path]: true
        }));
      }
    }
  };

  const menuList = sideMenus.map((menu, i) => {
    let currUrl = window.location.pathname;
    let pathName = currUrl.split("/")[1];
    let isSelected = menu.pathList.includes("/".concat(pathName));
    return (
      <List disablePadding className={styles.menuWrap}>
        {menu.type ? (
          <ListItem
            button
            disableRipple={true}
            selected={openMenu[menu.path] || isSelected}
            key={menu.icon}
            onClick={() => {
              handleToggle(menu.path, menu.title, menu.children);
            }}
          >
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText
              disableTypography
              className={clsx(styles.Text, {
                [styles.SelectedText]: isSelected,
                [styles.UnselectedText]: !isSelected
              })}
              primary={menu.title}
            />
            {menu.children ? (
              openMenu[menu.path] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : null}
          </ListItem>
        ) : (
          <ListItem
            button
            selected={isSelected}
            className={clsx({
              [styles.OpenItem]: props.opened,
              [styles.ClosedItem]: !props.opened
            })}
            classes={{
              root: styles.IconItem,
              selected: styles.SelectedItem
            }}
            key={menu.icon}
            component={NavLink}
            to={menu.path}
            onClick={() => {
              handleToggle(menu.path, menu.title, menu.children);
            }}
          >
            <ListItemIcon>{menu.icon}</ListItemIcon>
            <ListItemText
              disableTypography
              className={clsx(styles.Text, {
                [styles.SelectedText]: isSelected,
                [styles.UnselectedText]: !isSelected
              })}
              primary={menu.title}
            />
            {menu.children ? (
              openMenu[menu.path] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : null}
          </ListItem>
        )}

        <Collapse in={openMenu[menu.path]} timeout="auto" unmountOnExit>
          {menu.children
            ? menu.children.map(subMenu => {
                let currUrlSubList = window.location.pathname;
                let pathNameSubList = currUrlSubList.split("/")[1];
                let isSelectedSubList = subMenu.pathList.includes(
                  "/".concat(pathNameSubList)
                );
                return (
                  <List disablePadding>
                    {subMenu.type ? (
                      <div className={styles.SubChildren}>
                        <ListItem
                          button
                          disableRipple={true}
                          selected={openSub || isSelectedSubList}
                          className={clsx({
                            [styles.OpenItem]: props.opened,
                            [styles.ClosedItem]: !props.opened
                          })}
                          classes={{
                            root: styles.IconItem,
                            selected: styles.SelectedItem
                          }}
                          key={subMenu.icon}
                          onClick={() => {
                            setOpenSub(!openSub);
                          }}
                        >
                          <ListItemIcon>{subMenu.icon}</ListItemIcon>
                          <ListItemText
                            disableTypography
                            className={clsx(styles.Text, {
                              [styles.SelectedText]: isSelectedSubList,
                              [styles.UnselectedText]: !isSelectedSubList
                            })}
                            primary={subMenu.title}
                          />
                          {subMenu.children ? (
                            openSub ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )
                          ) : null}
                        </ListItem>
                      </div>
                    ) : (
                      <div className={styles.SubChildren}>
                        <ListItem
                          button
                          disableRipple={true}
                          selected={isSelectedSubList}
                          className={clsx({
                            [styles.OpenItem]: props.opened,
                            [styles.ClosedItem]: !props.opened
                          })}
                          classes={{
                            root: styles.IconItem,
                            selected: styles.SelectedItem
                          }}
                          component={NavLink}
                          to={subMenu.path}
                          key={subMenu.icon}
                          onClick={() => {
                            setOpenSub(!openSub);
                          }}
                        >
                          <ListItemIcon>{subMenu.icon}</ListItemIcon>
                          <ListItemText
                            disableTypography
                            className={clsx(styles.Text, {
                              [styles.SelectedText]: isSelectedSubList,
                              [styles.UnselectedText]: !isSelectedSubList
                            })}
                            primary={subMenu.title}
                          />
                          {subMenu.children ? (
                            openSub ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )
                          ) : null}
                        </ListItem>
                      </div>
                    )}

                    {/* third children */}
                    <Collapse in={openSub} timeout="auto" unmountOnExit>
                      <List disablePadding>
                        {subMenu.children
                          ? subMenu.children.map(thirdMenu => {
                              let currUrlThirdList = window.location.pathname;
                              let pathNameThirdList = currUrlThirdList.split(
                                "/"
                              )[1];
                              let isSelectedThirdList = thirdMenu.pathList.includes(
                                "/".concat(pathNameThirdList)
                              );
                              return (
                                <div className={styles.ThirdChildren}>
                                  <ListItem
                                    button
                                    disableRipple={true}
                                    selected={isSelectedThirdList}
                                    className={clsx({
                                      [styles.OpenItem]: props.opened,
                                      [styles.ClosedItem]: !props.opened
                                    })}
                                    classes={{
                                      root: styles.IconItem,
                                      selected: styles.SelectedItem
                                    }}
                                    key={thirdMenu.icon}
                                    component={NavLink}
                                    to={thirdMenu.path}
                                  >
                                    <ListItemIcon>
                                      {thirdMenu.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                      disableTypography
                                      className={clsx(styles.Text, {
                                        [styles.SelectedText]: isSelectedThirdList,
                                        [styles.UnselectedText]: !isSelectedThirdList
                                      })}
                                      primary={thirdMenu.title}
                                    />
                                  </ListItem>
                                </div>
                              );
                            })
                          : null}
                      </List>
                    </Collapse>
                  </List>
                );
              })
            : null}
        </Collapse>
      </List>
    );
  });
  return <List className={styles.List}>{menuList}</List>;
};

SideMenus.propTypes = {};

SideMenus.defaultProps = {};

export default SideMenus;
