import React, { useContext } from "react";
import siteLogo from "../../assets/images/pfc_nutrition_logo.png";
import { AuthContext } from "../../context";
import auth from "../Auth";
import { useTheme } from "@material-ui/core/styles";

const Logo = props => {
  const { userInfo } = useContext(AuthContext);
  const logo = userInfo.user.logo ? userInfo.user.logo : siteLogo;
  const theme = useTheme();

  return (
    <div
      style={
        auth.getToken()
          ? theme.breakpoints.up("sm")
            ? {
                marginTop: "2%",
                marginBottom: "4%"
              }
            : {
                margin: "auto"
              }
          : {
              marginTop: "0.4%"
            }
      }
    >
      <img
        src={logo}
        alt="PFC-logo"
        style={props.style}
        className={props.className}
        {...props}
      />
    </div>
  );
};

export default Logo;
