import Fraction from "fraction.js";
import moment from "moment";

export const getInitials = function (name) {
  const regEx1 = /(^\S\S?|\b\S)?/g;
  const regEx2 = /(^\S|\S$)?/g;
  const strRegEx1 = regEx1.source;
  const strRegEx2 = regEx2.source;
  var restoreRegex1 = new RegExp(strRegEx1, "g");
  var restoreRegex2 = new RegExp(strRegEx2, "g");

  return name
    .match(restoreRegex1)
    .join("")
    .match(restoreRegex2)
    .join("")
    .toUpperCase();
};

export const isEmptyString = val => {
  return !val || /^\s*$/.test(val);
};

export const converToFraction = num => {
  var x = new Fraction(parseFloat(num));
  var res = x.toFraction(true);
  let arr = [...res];
  let str = "";
  let flag = false;
  arr.map(r => {
    if (r === " ") {
      flag = true;
      str = str + "(";
    } else {
      str = str + r;
    }
    return null;
  });
  if (flag) {
    str = str + ")";
  }
  return str;
};

export const isNumeric = str => {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const calculateDiffTime = (date_future, date_now) => {
  let seconds = Math.floor((date_future - date_now) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

  if (hours !== 0 && minutes !== 0 && seconds !== 0) {
    return (
      "updated " +
      hours +
      " hours " +
      minutes +
      " minutes " +
      seconds +
      " seconds ago..."
    );
  } else if (hours === 0 && minutes !== 0 && seconds !== 0) {
    return "updated " + minutes + " minutes " + seconds + " seconds ago...";
  } else if (hours === 0 && minutes === 0 && seconds !== 0) {
    return "updated " + seconds + " seconds ago...";
  } else {
    return "updated 0 sec ago";
  }
};

export const getDateInYYYYMMDD = date => {
  var today = date;

  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  return today;
};

export const formatDate = date => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const getFormattedTime = date => {
  var str = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  return str;
};

export const convertNumber = num => {
  return Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100;
};

export const roundTo1Digit = num => {
  let val = 0;
  if (!isNaN(parseFloat(num))) {
    val = parseFloat(num).toFixed(1);
  }
  return val;
};

export const roundTo2Digit = num => {
  let val = 0;
  if (!isNaN(parseFloat(num))) {
    val = parseFloat(num).toFixed(2);
  }
  return val;
};

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const getFormattedDate = dateEntered => {
  if (dateEntered) {
    var date = new Date(dateEntered);
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    var dateString =
      (m <= 9 ? "0" + m : m) + "/" + (d <= 9 ? "0" + d : d) + "/" + y;
    return dateString;
  } else {
    return " ";
  }
};

export const convertDate = data => {
  let arr = [];
  let count = 0;
  for (let i in data) {
    let temp = {};
    var newDate2 = moment(data[i].schedule_start_date).format("MMMM Do YYYY");
    temp["id"] = data[i].id;
    if (!count) {
      temp["schedule_start_date"] = newDate2 + " - Latest Schedule";
      count = count + 1;
    } else {
      temp["schedule_start_date"] = newDate2;
    }
    temp["unformatted_schedule_start_date"] = data[i].schedule_start_date;
    arr.push(temp);
  }
  return arr;
};

export const plainDate = data => {
  var newDate2 = moment(data).format("MMMM Do YYYY");
  return newDate2;
};

export const avgFoodCatValues = {
  bread: {
    avgcal: 0,
    avgpro: 0,
    avgcarb: 0,
    avgfat: 0
  },
  meat: {
    avgcal: 0,
    avgpro: 0,
    avgcarb: 0,
    avgfat: 0
  },
  veg: {
    avgcal: 0,
    avgpro: 0,
    avgcarb: 0,
    avgfat: 0
  },
  fruit: {
    avgcal: 0,
    avgpro: 0,
    avgcarb: 0,
    avgfat: 0
  },
  milk: {
    avgcal: 0,
    avgpro: 0,
    avgcarb: 0,
    avgfat: 0
  },
  fat: {
    avgcal: 0,
    avgpro: 0,
    avgcarb: 0,
    avgfat: 0
  }
};

export const goalAvgValues = {
  values: {
    bread: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    meat: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    veg: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    fruit: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    milk: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    },
    fat: {
      portion: 0,
      avgcal: 0,
      avgpro: 0,
      avgcarb: 0,
      avgfat: 0
    }
  },
  total: {
    portion: 0,
    avgcal: 0,
    avgpro: 0,
    avgcarb: 0,
    avgfat: 0
  },
  avgPerMeal: {
    portion: 0,
    avgcal: 0,
    avgpro: 0,
    avgcarb: 0,
    avgfat: 0
  },
  calContributed: {
    proContNum: 0,
    proContPercent: 0,
    carbContNum: 0,
    carbContPercent: 0,
    fatCalNum: 0,
    fatCalNumPercent: 0
  }
};
