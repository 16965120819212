import React, { useEffect, useState } from "react";
import styles from "./AddEditGender.module.css";
import addGenderForm from "../GenderSchema";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  LinearProgress
} from "@material-ui/core";
import {
  Auth,
  Input,
  SnackBar,
  DarkBlueButton,
  BorderLessButton,
  AutoCompleteDropDown
} from "../../../../components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { get } from "lodash";
import {
  checkAllKeysPresent,
  checkEmpty,
  getListOfKeysNotPresent,
  setErrors
} from "../../../../utils";
import SaveIcon from "@material-ui/icons/Save";
import { GENDER } from "../../../../paths";
import {
  providerForGet,
  providerForPost,
  providerForPut
} from "../../../../api";
import { franchise, getGender } from "../../../../constants";
import {
  ERROR_MESSAGE,
  SAVE_MESSAGE,
  UPDATE_MESSAGE
} from "../../../../constants/genericConstants";
import useStyles from "../../../../utils/Styles/ManagePageStyles";
import DialogBox from "../../../../components/DialogBox/DialogBox";
const AddEditGender = props => {
  const history = useHistory();
  const auth = Auth.getToken();
  const userInfo = Auth.getUserInfo();
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  let propData = props["location"]["state"];
  const [openDialog, setOpenDialog] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    franchiseList: [],
    alert: false,
    severity: "success",
    errorMessage: "",
    /** This is when we return from edit page */
    rowData: propData ? propData : [],
    editData: propData ? (propData["id"] ? true : false) : false,
    editDataId: propData ? propData["id"] : null
  });
  const genderName = "genderName";
  const franchiseName = "franchiseName";

  const [isAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_ADMIN_ROLE_NAME ? true : false
  );
  const [isSuperAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
      ? true
      : false
  );

  /**Api call for getting autocomplete list itmes */
  const getListData = async () => {
    setLoader(true);
    let body = {};
    await providerForGet(franchise, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          franchiseList: res.data.data
        }));
        setLoader(false);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });

    if (isAdmin || isSuperAdmin) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [franchiseName]: Auth.getUserInfo().franchise
            ? Auth.getUserInfo().franchise.id
            : null
        }
      }));
    }
    if (formState.editData === true) {
      setLoader(true);
      let editId = {
        id: formState.editDataId
      };
      await providerForGet(getGender, editId, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              genderName: res.data.data[0].name
            }
          }));
          setLoader(false);
        })
        .catch(err => {
          console.log("err", err);
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    getListData();
  }, []);

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: ""
    }));
  };

  const handleChange = e => {
    e.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));
    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const handleChangeAutoComplete = (eventName, event, value) => {
    if (value !== null) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: value.id
        },
        touched: {
          ...formState.touched,
          [eventName]: true
        }
      }));
      if (formState.errors.hasOwnProperty(eventName)) {
        delete formState.errors[eventName];
      }
    } else {
      if (eventName === franchiseName) {
        if (isAdmin) {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              [franchiseName]: Auth.getUserInfo().franchise.id
            }
          }));
        } else {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              [franchiseName]: null
            }
          }));
        }
      } else {
        delete formState.values[eventName];
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [eventName]: null
          }
        }));
      }
    }
  };

  const hasError = field => (formState.errors[field] ? true : false);

  const handleSubmit = () => {
    setLoader(true);
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      addGenderForm
    );
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, addGenderForm);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        addGenderForm
      );
      formState.errors = setErrors(formState.values, addGenderForm);
    }
    if (isValid) {
      let isGlobal = false;
      if (isSuperAdmin) {
        if (!formState.values.franchiseName) {
          isGlobal = true;
        }
      }
      /**api call from here */
      let postData = {
        name: formState.values.genderName,
        franchise: formState.values.franchiseName,
        isGlobal: isGlobal
      };
      if (formState.editData === true) {
        /**Put api call (Update method)*/

        providerForPut(getGender, formState.editDataId, postData, auth, {
          desc: `${userInfo.full_name} updated the gender ${formState.values.genderName}`
        })
          .then(res => {
            let successMessage = {
              successMessage: "Gender  " + res.data.name + UPDATE_MESSAGE
            };
            setLoader(false);
            history.push(GENDER, successMessage);
          })
          .catch(err => {
            console.log("err", err);
            setFormState(formState => ({
              ...formState,
              alert: true,
              severity: "error",
              errorMessage: ERROR_MESSAGE + err
            }));
            setOpenDialog(false);

            setLoader(false);
          });
      } else {
        /**Post api call (Save method)*/
        providerForPost(getGender, postData, auth, {
          desc: `${userInfo.full_name} added a new gender ${formState.values.genderName}`
        })
          .then(res => {
            let successMessage = {
              successMessage: "Gender " + res.data.name + SAVE_MESSAGE
            };
            setLoader(false);
            history.push(GENDER, successMessage);
          })
          .catch(err => {
            console.log("error", err);
            setFormState(formState => ({
              ...formState,
              alert: true,
              severity: "error",
              errorMessage: ERROR_MESSAGE + err
            }));
            setOpenDialog(false);
            setLoader(false);
          });
      }
    } else {
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
      setLoader(false);
    }
  };

  const checkIfFranchiseSelected = () => {
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      addGenderForm
    );
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, addGenderForm);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        addGenderForm
      );
      formState.errors = setErrors(formState.values, addGenderForm);
    }
    if (isValid) {
      if (formState.values.franchiseName) {
        handleSubmit();
      } else {
        setOpenDialog(true);
      }
    } else {
      setFormState(formState => ({
        ...formState
      }));
    }
  };

  const handleCancel = () => {
    history.push(GENDER);
  };
  return (
    <div>
      {loader ? (
        <LinearProgress />
      ) : (
        <>
          <Grid item xs={12} className={styles.title}>
            {formState.editData === true ? (
              <Typography variant="h4" className={styles.titleText}>
                Edit Gender
              </Typography>
            ) : (
              <Typography variant="h4" className={styles.titleText}>
                Add Gender
              </Typography>
            )}
          </Grid>
          <Grid>
            <SnackBar
              open={formState.alert}
              severity={formState.severity}
              onClose={handleSnackbarClose}
            >
              {formState.errorMessage}
            </SnackBar>
          </Grid>
          <Grid item xs={12} className={styles.formgrid}>
            <Card className={classes.editCard}>
              <CardContent className={classes.editCardContent}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addGenderForm[genderName], "label")}
                      name={genderName}
                      value={formState.values[genderName] || ""}
                      error={hasError(genderName)}
                      placeholder={get(
                        addGenderForm[genderName],
                        "placeholder"
                      )}
                      variant="outlined"
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(genderName)
                          ? formState.errors[genderName].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <AutoCompleteDropDown
                      options={formState.franchiseList}
                      getOptionLabel={option => option.franchise_name}
                      id={get(addGenderForm[franchiseName], "id")}
                      value={
                        formState.franchiseList[
                          formState.franchiseList.findIndex(function (item, i) {
                            return item.id === formState.values[franchiseName];
                          })
                        ] || null
                      }
                      disabled={isAdmin}
                      onChange={(event, value) => {
                        handleChangeAutoComplete(franchiseName, event, value);
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          id={get(addGenderForm[franchiseName], "id")}
                          label={get(addGenderForm[franchiseName], "label")}
                          variant="outlined"
                          disabled={isAdmin}
                          error={hasError(franchiseName)}
                          helperText={
                            hasError(franchiseName)
                              ? formState.errors[franchiseName].map(error => {
                                  return error + " ";
                                })
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} className={classes.actionButtonGrid}>
            <DarkBlueButton
              type="submit"
              data-testid="ok-button"
              onClick={checkIfFranchiseSelected}
            >
              <SaveIcon />
              &nbsp; Save
            </DarkBlueButton>

            <BorderLessButton onClick={handleCancel}>cancel</BorderLessButton>
          </Grid>
          <DialogBox
            open={openDialog}
            title="Save Supplement"
            buttonCancel="Cancel"
            handleCancel={() => setOpenDialog(false)}
            skipOk={false}
            buttonOk={"Ok"}
            handleOk={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Typography gutterBottom>
                  Since you haven't chosen a franchise, this entry will be seen
                  by all the franchises. Are you sure you want to go on?
                </Typography>
              </Grid>
            </Grid>
          </DialogBox>
        </>
      )}
    </div>
  );
};

AddEditGender.propTypes = {};

AddEditGender.defaultProps = {};

export default AddEditGender;
