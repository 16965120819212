const FoodSchema = {
  foodName: {
    label: "Name",
    id: "foodName",
    autoComplete: "Name",
    required: true,
    placeholder: "Name",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Name is required"
      }
    }
  },
  unitNumber: {
    label: "Unit",
    id: "unitNumber",
    autoComplete: "Unit",
    required: true,
    placeholder: "Unit",
    autoFocus: true,
    type: "number",
    validations: {
      required: {
        value: "true",
        message: "Unit is required"
      }
    }
  },
  caloriesNumber: {
    label: "Calories",
    id: "caloriesNumber",
    autoComplete: "Calories",
    required: true,
    placeholder: "Calories",
    autoFocus: true,
    type: "number",
    validations: {
      required: {
        value: "true",
        message: "Calories are required"
      }
    }
  },
  proteinsNumber: {
    label: "Proteins",
    id: "proteinsNumber",
    autoComplete: "Proteins",
    required: true,
    placeholder: "Proteins",
    autoFocus: true,
    type: "number",
    validations: {
      required: {
        value: "true",
        message: "Proteins are required"
      }
    }
  },
  fatsNumber: {
    label: "Fats",
    id: "fatsNumber",
    autoComplete: "Fats",
    required: true,
    placeholder: "Fats",
    autoFocus: true,
    type: "number",
    validations: {
      required: {
        value: "true",
        message: "Fats are required"
      }
    }
  },
  carbsNumber: {
    label: "Carbs",
    id: "carbsNumber",
    autoComplete: "Carbs",
    required: true,
    placeholder: "Carbs",
    autoFocus: true,
    type: "number",
    validations: {
      required: {
        value: "true",
        message: "Carbs are required"
      }
    }
  },
  franchiseName: {
    label: "Franchise",
    id: "franchiseName",
    autoComplete: "Franchise",
    required: false,
    placeholder: "Franchise",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  foodCategoryName: {
    label: "Food Category",
    id: "foodCategoryName",
    autoComplete: "Food Category",
    required: true,
    placeholder: "Food Category",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Food Category is required"
      }
    }
  },
  foodSubCategoryName: {
    label: "Food Sub Category",
    id: "foodSubCategoryName",
    autoComplete: "Food Sub Category",
    required: true,
    placeholder: "Food Sub Category",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Food Sub Category is required"
      }
    }
  },
  sizeWeightName: {
    label: "Portion/Unit",
    id: "sizeWeightName",
    autoComplete: "Portion/Unit",
    required: true,
    placeholder: "Portion/Unit",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Portion/Unit is required"
      }
    }
  }
};
export default FoodSchema;
