import React from "react";

const AccountRecovery = () => (
  <div data-testid="AccountRecovery">AccountRecovery Component</div>
);

AccountRecovery.propTypes = {};

AccountRecovery.defaultProps = {};

export default AccountRecovery;
