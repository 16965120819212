export const ADD_TEXT = "Add";
export const EDIT_TEXT = "Edit";
export const DELETE_TEXT = "Delete";

/**Client */
export const EDIT_CLIENT_TEXT = "Edit Client";
export const VIEW_CLIENT_TEXT = "Manage Client";
export const ADD_CLIENT_TEXT = "Add Client";

/**Snackbar messages */
export const ERROR_MESSAGE = "An error occured! ";
export const UPDATE_MESSAGE = " updated successfully!";
export const SAVE_MESSAGE = " saved successfully!";
export const DELETE_MESSAGE = " deleted successfully!";
