import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";

const localStyles = makeStyles(theme => ({
  WhiteIconButtonStyle: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
    letterSpacing: "0.4px",
    textTransform: "uppercase",
    padding: "0.25rem 1.125rem",
    "&:hover": {
      background: "#FFFFFF",
      color: "#000000"
    }
  },
  IconStyle: {
    color: "#000000",
    "&:hover": {
      color: "#ffc400"
    },
    "&:active": {
      color: "#ffc400"
    }
  }
}));

export default function WhiteIconButton(props) {
  const classes = localStyles();

  return (
    <Button
      className={classes.WhiteIconButtonStyle}
      variant="contained"
      onClick={props.onClick}
      style={props.style}
      {...props}
      startIcon={<StarIcon className={classes.IconStyle} />}
      disableRipple
      disableFocusRipple
    >
      {props.children}
    </Button>
  );
}
