// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotFoundPage_mydiv__30oBf {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  height: 18em;\n  margin-top: -9em; /*set to a negative number 1/2 of your height*/\n  margin-left: -15em; /*set to a negative number 1/2 of your width*/\n}\n", "",{"version":3,"sources":["webpack://src/components/NotFoundPage/NotFoundPage.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,YAAY;EACZ,gBAAgB,EAAE,8CAA8C;EAChE,kBAAkB,EAAE,6CAA6C;AACnE","sourcesContent":[".mydiv {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  height: 18em;\n  margin-top: -9em; /*set to a negative number 1/2 of your height*/\n  margin-left: -15em; /*set to a negative number 1/2 of your width*/\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mydiv": "NotFoundPage_mydiv__30oBf"
};
export default ___CSS_LOADER_EXPORT___;
