import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const localStyles = makeStyles(theme => ({
  buttonStyle: {
    backgroundColor: "transparent",
    color: "#1C4979",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "0.875rem",
    border: "none",
    boxShadow: "none",
    padding: "6px 15px",
    "&:hover": {
      background: "transparent",
      color: "#1C4979",
      border: "none",
      boxShadow: "none"
    }
  }
}));

export default function BorderLessButton(props) {
  const classes = localStyles();

  return (
    <Button
      className={classes.buttonStyle}
      variant="text"
      onClick={props.onClick}
      style={props.style}
      {...props}
      disableRipple
      disableTouchRipple
      disableFocusRibble
    >
      {props.children}
    </Button>
  );
}
