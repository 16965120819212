import { React, useState } from "react";
import {
  Typography,
  Backdrop,
  CircularProgress,
  FormHelperText,
  AppBar,
  Toolbar,
  Box,
  Card,
  CardContent,
  Grid,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { commonStyles } from "../../../common";
import { SnackBar, DarkBlueButton, Logo } from "../../../components";
import { useHistory, Link } from "react-router-dom";
import { FORGOTPASSWORD, LOGIN, RESETPASSWORD } from "../../../paths/Paths";
import {
  checkAllKeysPresent,
  getListOfKeysNotPresent,
  setErrors,
  checkEmpty
} from "../../../utils";
import verifyotp from "./verifyotp.json";
import OtpInput from "react-otp-input";
import { providerForPublicPost } from "../../../api";
import {
  verifyOtpForgotPassURL,
  requestOtpForgotPassURL
} from "../../../constants";

const useStyles = makeStyles(theme => ({
  otpinput: {
    margin: theme.spacing(2, 0, 2),
    width: "40px !important;",
    height: "50px",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000000",
    fontSize: "1rem"
  },
  otpError: {
    "font-size": "larger",
    "text-align": "center"
  },
  dont_resend: {
    fontSize: "0.9375rem",
    fontFamily: "Montserrat",
    padding: "5px"
  },
  txtClass: {
    "text-align": "center",
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000000",
    fontSize: "1rem"
  },
  container: {
    margin: "117px auto 50px auto"
  },
  loginAppBar: {
    background: "rgb(244, 248, 255) !important",
    "box-shadow": "0px 3px 5px #e8eef7 !important"
  },
  loginToolbar: {
    padding: "0px 25px"
  },
  loginLogo: {
    "max-width": "178px",
    margin: "0px 5px",
    "max-height": "50px"
  },
  cardContent: {
    padding: 60,
    paddingBottom: "45px !important"
  },
  loginCardContent: {
    padding: " 60px !important",
    paddingBottom: "45px !important",
    "background-color": " rgb(244 248 255) !important",
    border: "1px solid #1C4979 !important",
    "border-radius": "4px !important"
  },
  loginTextDesktop: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1.25rem",
    color: "#110F48",
    paddingTop: 40
  },
  loginTextMobile: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#110F48",
    fontSize: "1.25rem"
  },
  form: {
    width: "100%"
  },
  otpDiv: {
    "& > div": {
      flexWrap: "wrap",
      "& > div": {
        flexWrap: "wrap",
        "& span": {
          display: "none"
        }
      }
    }
  },
  otpBox: {
    "& > div > div": {
      minWidth: "58px !important",
      justifyContent: "center"
    }
  },
  txtClassotpvalid: {
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "13px",
    padding: "3px"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#0152CC",
    fontFamily: "Montserrat",
    fontWeight: 400,
    color: "#fff",
    fontSize: "0.875rem"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    position: "relative"
  },
  welcomeText: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000000",
    fontSize: "1rem"
  },
  resend: {
    fontSize: "0.875rem",
    fontFamily: "Montserrat"
  },
  inputText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1rem"
  },
  label: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.875rem"
  },
  Heading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000000",
    fontSize: "1rem"
  }
}));

const VerifyOtp = props => {
  const otp = "otp";
  const identifier = "identifier";
  const classes = useStyles();
  const commonClass = commonStyles();
  const history = useHistory();
  const themes = useTheme();

  const isDesktop = useMediaQuery(themes.breakpoints.up("lg"), {
    defaultMatches: true
  });

  const [isErrorWhileVerifyingOTP, setIsErrorWhileVerifyingOTP] = useState(
    false
  );
  const [otpStatusMessage, setOtpStatusMessage] = useState("");
  const [isErrorWhileResendingOTP, setIsErrorWhileResendingOTP] = useState(
    false
  );
  /** Use state for form */
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    errors: {},
    identifierPresent: props.location.identifier,
    resendOTP: false,
    setNewPassword: props.location.setNewPassword
      ? props.location.setNewPassword
      : false
  });

  const [statusVariable, setStatusVariable] = useState({
    isError: false,
    isOpenSnackBar: false,
    successErrorMessage: ""
  });

  if (!formState.identifierPresent || formState.identifierPresent === null) {
    history.push({
      pathname: FORGOTPASSWORD
    });
  }

  const handleChange = otpValue => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [otp]: otpValue
      },
      resendOTP: false
    }));
    delete formState.errors[otp];
    setIsErrorWhileVerifyingOTP(false);
  };

  const hasError = field => {
    return formState.errors[field] ? true : false;
  };

  const onVerifyOtp = () => {
    let payload = {
      [identifier]: formState.identifierPresent,
      otpEntered: formState.values.otp,
      reason: formState.setNewPassword ? "set_password" : "forgot_pass"
    };
    providerForPublicPost(verifyOtpForgotPassURL, payload, {
      desc:
        "Verify OTP for " + formState.setNewPassword
          ? "Set Password"
          : "Forget password"
    })
      .then(res => {
        history.push({
          pathname: RESETPASSWORD,
          identifier: formState.identifierPresent,
          type: props.type,
          setNewPassword: formState.setNewPassword
        });
      })
      .catch(error => {
        console.log("Error", error);
        setIsErrorWhileVerifyingOTP(true);
        setOtpStatusMessage(error.response.data.message);
        setFormState(formState => ({
          ...formState,
          resendOTP: false
        }));
      });
  };

  const reSendOtpToIdentifier = async () => {
    setIsErrorWhileVerifyingOTP(false);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [otp]: ""
      }
    }));
    let payload = {
      [identifier]: formState.identifierPresent,
      reason: formState.setNewPassword ? "set_password" : "forgot_pass"
    };
    await providerForPublicPost(requestOtpForgotPassURL, payload, {
      desc:
        "Resend OTP for " + formState.setNewPassword
          ? "Set Password"
          : "Forget password"
    })
      .then(res => {
        setFormState(formState => ({
          ...formState,
          resendOTP: true
        }));
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          isOpenSnackBar: true,
          isError: "success",
          successErrorMessage:
            "OTP successfully resent to " + formState.identifierPresent
        }));
      })
      .catch(error => {
        console.log("Error", error);
        setIsErrorWhileResendingOTP(true);
        setOtpStatusMessage(error.response.data.message);
      });
  };

  /** validateOtp
   *  If OTP entered is valid, API is called to verify Otp
   * @param myParam Otp is required field
   */
  const validateOtp = () => {
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(formState.values, verifyotp);

    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, verifyotp);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(formState.values, verifyotp);
      formState.errors = setErrors(formState.values, verifyotp);
    }
    if (isValid) {
      setFormState(formState => ({
        ...formState,
        isValid: true
      }));
      onVerifyOtp();
    } else {
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
    }
  };

  const handleSnackbarClose = () => {
    setStatusVariable(statusVariable => ({
      ...statusVariable,
      isOpenSnackBar: false,
      isError: "",
      successErrorMessage: ""
    }));
  };

  return (
    <>
      <AppBar position="fixed" className={classes.loginAppBar} elevation={0}>
        <Toolbar className={classes.loginToolbar}>
          <Logo className={classes.loginLogo} />
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" className={classes.container}>
        <Card variant="elevation" elevation={2}>
          <CardContent className={classes.loginCardContent}>
            <Box>
              <Typography
                className={
                  isDesktop ? classes.loginTextDesktop : classes.loginTextMobile
                }
                align="center"
              >
                Verify OTP
              </Typography>
            </Box>
            <Box>
              <>
                <Typography
                  align="center"
                  className={classes.welcomeText}
                  style={{ marginBottom: 0 }}
                >
                  If you have an account with us, you will receive an OTP
                </Typography>
                <Typography align="center" className={classes.welcomeText}>
                  sent at <b>{formState.identifierPresent}</b>
                </Typography>
              </>
            </Box>
            <form className={classes.form} noValidate>
              <div className={classes.otpDiv}>
                <Box
                  style={{ justifyContent: "center" }}
                  className={classes.otpBox}
                >
                  <OtpInput
                    value={formState.values[otp] ? formState.values[otp] : ""}
                    onChange={handleChange}
                    numInputs={6}
                    separator={<span> &nbsp; &nbsp;</span>}
                    inputStyle={classes.otpinput}
                    isInputNum
                    containerStyle={{
                      justifyContent: "center",
                      minWidth: "55px"
                    }}
                  />
                </Box>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.txtClassotpvalid}
                >
                  OTP is valid for 5 minutes
                </Typography>
                <FormHelperText
                  className={classes.otpError}
                  error={
                    hasError(otp) ||
                    isErrorWhileVerifyingOTP ||
                    isErrorWhileResendingOTP
                  }
                  id="my-helper-text"
                >
                  {hasError(otp)
                    ? formState.errors[otp].map(error => {
                        return error + " ";
                      })
                    : isErrorWhileVerifyingOTP || isErrorWhileResendingOTP
                    ? otpStatusMessage
                    : null}
                </FormHelperText>
              </div>
              <div>
                <div className={classes.wrapper}>
                  <DarkBlueButton
                    type="button"
                    fullWidth
                    onClick={validateOtp}
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    Verify
                  </DarkBlueButton>
                </div>
              </div>
            </form>
            <Typography component="h6" className={classes.dont_resend}>
              Didn't recieve an OTP?{" "}
              <span className={classes.resend}>
                <Link href="#" variant="body2" onClick={reSendOtpToIdentifier}>
                  Resend OTP
                </Link>
              </span>
            </Typography>{" "}
            <Typography component="h6" className={classes.dont_resend}>
              Go to{" "}
              <span className={classes.resend}>
                <Link
                  href="#"
                  variant="body2"
                  onClick={() => {
                    history.push(LOGIN);
                  }}
                >
                  Login
                </Link>
              </span>{" "}
              page
            </Typography>{" "}
            <Backdrop className={commonClass.backdrop}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </CardContent>
        </Card>
      </Container>
      <Grid>
        <SnackBar
          open={statusVariable.isOpenSnackBar}
          severity={statusVariable.isError}
          onClose={handleSnackbarClose}
        >
          {statusVariable.successErrorMessage}
        </SnackBar>
      </Grid>
    </>
  );
};

export default VerifyOtp;
