import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Paper,
  Backdrop,
  CircularProgress,
  TableContainer,
  TableHead,
  TableCell
} from "@material-ui/core";
import { Auth, DarkBlueButton, SnackBar } from "../../components";
import { providerForDownload } from "../../api";
import { getGenericDocument } from "../../constants";
import { Table, TableBody, TableRow } from "material-ui";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.primary
  },
  button: {
    marginTop: 15
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: "#fff"
  },
  tableCell: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "1.1rem",
    border: "1px solid rgba(224, 224, 224, 1)"
  },
  tableHead: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "1.2rem",
    border: "1px solid rgba(224, 224, 224, 1)"
  },
  tableCellButton: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "1.1rem",
    border: "1px solid rgba(224, 224, 224, 1)"
  }
}));

const GiveAllDoc = props => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [reportStatus, setReportStatus] = useState({
    isError: false,
    isOpenSnackBar: false,
    successErrorMessage: "",
    saveText: "",
    dummyTime: new Date(),
    savedTime: null
  });

  const handleDownloadButton = async isPrint => {
    setLoader(true);
    await providerForDownload(getGenericDocument, {}, Auth.getToken(), {
      desc: "Download Generic Give All"
    })
      .then(res => {
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
         window.open(url,'_blank');
          // const pdfNewWindow = window.open();
          // console.log("win open ",pdfNewWindow)
          // pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "give_all.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
          setReportStatus(reportStatus => ({
            ...reportStatus,
            isOpenSnackBar: true,
            isError: "success",
            successErrorMessage:
              "Generic give all document dowloaded successfully"
          }));
        }
        setLoader(false);
      })
      .catch(error => {
        setReportStatus(reportStatus => ({
          ...reportStatus,
          isOpenSnackBar: true,
          isError: "error",
          successErrorMessage: "Could not download Generic Give All document."
        }));
        setLoader(false);
        console.log(error);
      });
  };

  const handleSnackbarClose = () => {
    setReportStatus(reportStatus => ({
      ...reportStatus,
      isOpenSnackBar: false,
      isError: "",
      successErrorMessage: ""
    }));
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid container item xs={12} justify="center">
          <h1>Download Resources</h1>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Resources Table">
          <TableHead>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                colSpan={6}
                align={"center"}
                className={classes.tableHead}
              >
                Resources
              </TableCell>
              <TableCell
                align={"center"}
                className={classes.tableHead}
                colSpan={2}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={1}>
              <TableCell
                component="th"
                scope="row"
                colSpan={6}
                align={"center"}
                className={classes.tableCell}
              >
                <Paper className={classes.paper}>
                  Generic Give-all Document
                </Paper>
              </TableCell>
              <TableCell align={"center"} className={classes.tableCellButton}>
                <DarkBlueButton
                  variant="contained"
                  onClick={() => handleDownloadButton(false)}
                >
                  Download
                </DarkBlueButton>
              </TableCell>
              <TableCell align={"center"} className={classes.tableCellButton}>
                <DarkBlueButton
                  variant="contained"
                  onClick={() => handleDownloadButton(true)}
                >
                  Print
                </DarkBlueButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid>
        <SnackBar
          autoHideDuration={null}
          open={reportStatus.isOpenSnackBar}
          severity={reportStatus.isError}
          onClose={handleSnackbarClose}
        >
          {reportStatus.successErrorMessage}
        </SnackBar>
      </Grid>
    </Container>
  );
};

export default GiveAllDoc;
