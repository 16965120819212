import React, { useEffect, useState } from "react";
import styles from "./Authenticator.module.css";
import {
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import {
  Auth,
  SnackBar,
  DarkBlueButton,
  BorderLessButton,
  Input
} from "../../components";
import { providerForPost, providerForGet } from "../../api";
import {
  getQRCode,
  Check2FaCode,
  userMe,
  verifyPasswordUrl
} from "../../constants";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { commonStyles } from "../../common";

const Authenticator = props => {
  const auth = Auth.getUserInfo();
  const commonClass = commonStyles();
  /**list applications user can use to add authentication */
  const [applicationArray] = useState([
    "Google Authenticator",
    "Oracle Authenticator",
    "Authy Authenticator",
    "Free OTP Authenticator",
    "Microsoft Authenticator"
  ]);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    enable: false,
    showQrCode: false,
    qrData: "",
    otpData: "",
    error: false,
    errorMessage: "",
    keyPresentStatus: false,
    showPassword: false,
    passwordData: "",
    errorPassword: false,
    errorPasswordMessage: "",
    showPasswordText: true,
    alertStatus: false,
    alertMessage: "",
    alertSeverity: ""
  });

  /**Function to generate QrCode and secret key */
  const getQRCodeFunction = async () => {
    setLoading(true);
    await providerForPost(getQRCode, {}, Auth.getToken(), {
      desc: `${auth.full_name} accessed the 2FA setup page`
    })
      .then(res => {
        setLoading(false);
        setFormState(formState => ({
          ...formState,
          enable: true,
          qrData: res.data.result ? res.data.result : null,
          showQrCode: true,
          keyPresentStatus: false
        }));
      })
      .catch(err => {
        setLoading(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    /**Api to get secret key is present or not */
    setLoading(true);
    providerForGet(userMe, {}, Auth.getToken())
      .then(res => {
        if (res.data && res.data.isSecretKeyPresent === true) {
          setLoading(false);
          setFormState(formState => ({
            ...formState,
            enable: true,
            keyPresentStatus: true
          }));
        } else {
          setLoading(false);
          getQRCodeFunction();
        }
      })
      .catch(err => {
        setLoading(false);
        console.log("error", err);
      });
  }, []);

  /**Function to handle change in text field */
  const handleChange = event => {
    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value,
      error: false,
      errorPassword: false,
      errorMessage: null,
      errorPasswordMessage: null
    }));
  };
  /**Functions to verify verification code */
  const Check2FaCodeFunction = () => {
    if (formState.otpData !== "") {
      setLoading(true);
      let payLoad = {
        secret:
          formState.qrData &&
          formState.qrData.secret &&
          formState.qrData.secret.base32
            ? formState.qrData.secret.base32
            : "",
        token: formState.otpData
      };
      providerForPost(Check2FaCode, payLoad, Auth.getToken(), {
        desc: `${auth.full_name} sets up 2FA `
      })
        .then(res => {
          if (res.data.result && res.data.result.status === true) {
            setLoading(false);
            setFormState(formState => ({
              ...formState,
              enable: true,
              showQrCode: false,
              keyPresentStatus: true,
              otpData: "",
              qrData: "",
              alertStatus: true,
              alertMessage: "2FA added successfully",
              alertSeverity: "success"
            }));
          } else {
            setLoading(false);
            setFormState(formState => ({
              ...formState,
              error: true,
              errorMessage: "Verification Code is Incorrect"
            }));
          }
        })
        .catch(err => {
          setLoading(false);
          console.log("error", err);
        });
    } else {
      setLoading(false);
      setFormState(formState => ({
        ...formState,
        error: true,
        errorMessage: "Verification Code is required"
      }));
    }
  };

  const showPassword = () => {
    setFormState(formState => ({
      ...formState,
      showPassword: true,
      keyPresentStatus: false
    }));
  };

  const verifyPassword = () => {
    if (formState.passwordData !== "") {
      setLoading(true);
      let payLoad = {
        password: formState.passwordData
      };
      providerForPost(verifyPasswordUrl, payLoad, Auth.getToken(), {
        desc: "Verify password for removing 2FA from authenticator app"
      })
        .then(res => {
          setLoading(false);
          if (res.data.result && res.data.result.status === true) {
            setFormState(formState => ({
              ...formState,
              enable: false,
              showPassword: false,
              keyPresentStatus: false,
              alertStatus: true,
              alertMessage: "2FA removed successfully",
              alertSeverity: "success"
            }));
            getQRCodeFunction();
          } else {
            setLoading(false);
            setFormState(formState => ({
              ...formState,
              errorPassword: true,
              errorPasswordMessage: "Incorrect password"
            }));
          }
        })
        .catch(err => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setFormState(formState => ({
        ...formState,
        errorPassword: true,
        errorPasswordMessage: "Password is required"
      }));
    }
  };

  const handlePasswordCancel = () => {
    setFormState(formState => ({
      ...formState,
      errorPassword: false,
      errorPasswordMessage: "",
      passwordData: "",
      keyPresentStatus: true,
      showPassword: false
    }));
  };

  const handleClickShowPassword = () => {
    setFormState({
      ...formState,
      showPasswordText: !formState.showPasswordText
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alertStatus: false,
      alertMessage: "",
      alertSeverity: ""
    }));
  };

  return (
    <div className={styles.AuditLogs} data-testid="AuditLogs">
      <Typography variant="h4" className={styles.titleText}>
        2-FACTOR AUTHENTICATION
      </Typography>
      <Grid>
        <SnackBar
          open={formState.alertStatus}
          severity={formState.alertSeverity}
          onClose={handleSnackbarClose}
        >
          {formState.alertMessage}
        </SnackBar>
      </Grid>
      <Typography
        // variant="h4"
        // gutterBottom
        // style={{ fontSize: "medium", marginBottom: "35px" }}
        className={styles.normalText}
      >
        <strong>Note :</strong> - You should delete the old account in your
        mobile or desktop app before adding this new one.
      </Typography>

      <div>
        {
          // formState.enable === false ? (
          //   <Grid item md={2} xs={12}>
          //     <Button
          //       type="button"
          //       fullWidth
          //       variant="contained"
          //       color="primary"
          //       className={classes.submit}
          //       onClick={getQRCodeFunction}
          //     >
          //       Add authenticator
          //     </Button>
          //   </Grid>
          // ) : (
          formState.showQrCode && (
            <div>
              <Typography
                // variant="h4"
                // gutterBottom
                // style={{
                //   fontSize: "medium",
                //   marginTop: "30px",
                //   fontWeight: "bold"
                // }}
                className={styles.normalText}
              >
                {
                  "Install authentication code application on your mobile or desktop device"
                }
              </Typography>
              <ul>
                {applicationArray.map(e => (
                  <li gutterBottom className={styles.listAuth}>
                    {e}
                  </li>
                ))}
              </ul>
              <Grid item md={12} xs={12}>
                <Typography
                  // variant="h4"
                  // gutterBottom
                  // style={{ fontSize: "medium", marginTop: "30px" }}
                  className={styles.normalText}
                >
                  The two-factor authentication application will be used during
                  this setup and for generating codes during regular
                  authentication. If the application supports it, scan the QR
                  code below to get the setup code otherwise you can manually
                  enter the text code displayed below.
                </Typography>
              </Grid>

              <div>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography>
                      <Input
                        id={"secretKey"}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        autoFocus={"secretKey"}
                        helperText={
                          "Enter this code into your two-factor authentication app or scan the QR code below."
                        }
                        label={"Secret Key"}
                        name={"secretKey"}
                        InputProps={{
                          readOnly: true
                        }}
                        value={formState.qrData.secret.base32}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                {formState.qrData &&
                  formState.qrData.secret &&
                  formState.qrData.secret.qr && (
                    <Grid item xs={6}>
                      <img src={formState.qrData.secret.qr} alt="qr" />
                    </Grid>
                  )}

                <Typography variant="h4" className={styles.titleText}>
                  2FA verification code
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Input
                      id={"code"}
                      variant="outlined"
                      margin="normal"
                      error={formState.error}
                      fullWidth
                      autoFocus={"code"}
                      helperText={
                        formState.error ? formState.errorMessage : null
                      }
                      label={"Verification Code"}
                      name={"otpData"}
                      onChange={handleChange}
                      value={formState.otpData ? formState.otpData : ""}
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={3}>
                    <DarkBlueButton onClick={Check2FaCodeFunction}>
                      Verify
                    </DarkBlueButton>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              </div>
            </div>
            // )
          )
        }
        {formState.keyPresentStatus && (
          <div>
            <Grid item xs={4}>
              <DarkBlueButton type="button" fullWidth onClick={showPassword}>
                Remove 2 factor authentication
              </DarkBlueButton>
            </Grid>
          </div>
        )}
        {formState.showPassword && (
          <div>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    fullWidth
                    error={formState.errorPassword}
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id={"password"}
                    name={"passwordData"}
                    type={formState.showPasswordText ? "text" : "password"}
                    value={formState.passwordData ? formState.passwordData : ""}
                    onChange={handleChange}
                    fullWidth
                    error={formState.errorPassword}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        error={formState.errorPassword}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {formState.showPasswordText ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                    inputProps={{
                      autoComplete: "new-password"
                    }}
                  ></OutlinedInput>
                  <FormHelperText error={formState.errorPassword}>
                    {formState.errorPassword
                      ? formState.errorPasswordMessage
                      : null}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={3}>
                <DarkBlueButton type="button" onClick={verifyPassword}>
                  Verify
                </DarkBlueButton>
                <BorderLessButton type="button" onClick={handlePasswordCancel}>
                  Cancel
                </BorderLessButton>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <Backdrop className={commonClass.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Authenticator;
