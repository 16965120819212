const FranchiseSchema = {
  files: {
    label: "Upload",
    id: "files",
    required: false,
    placeholder: "Upload files",
    autoFocus: true,
    type: "file",
    validations: {}
  },
  giveAllLogo: {
    label: "Upload",
    id: "giveAllLogo",
    required: false,
    placeholder: "Upload files",
    autoFocus: true,
    type: "file",
    validations: {}
  },
  franchiseName: {
    label: "Franchise Name",
    id: "franchiseName",
    autoComplete: "Franchise Name",
    required: true,
    placeholder: "Franchise Name",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Franchise Name is required"
      }
    }
  },
  no_of_franchise_sites: {
    label: "No of Franchise Sites",
    id: "no_of_franchise_sites",
    autoComplete: "No of Franchise Sites",
    required: true,
    placeholder: "No of Franchise Sites",
    autoFocus: true,
    type: "number",
    validations: {
      required: {
        value: "true",
        message: "No of Franchise Sites is required"
      }
    }
  },
  active_users: {
    label: "Max Active Clients",
    id: "active_users",
    autoComplete: "Active Clients field",
    required: true,
    placeholder: "Active Clients field",
    autoFocus: true,
    type: "number",
    validations: {
      required: {
        value: "true",
        message: "Active Clients field is required"
      }
    }
  },
  active_nutritionists: {
    label: "Active Nutritionists",
    id: "active_nutritionists",
    autoComplete: "Active Nutritionists field",
    required: true,
    placeholder: "Active Nutritionists field",
    autoFocus: true,
    type: "number",
    validations: {
      required: {
        value: "true",
        message: "Active Nutritionists field is required"
      }
    }
  },
  address_line_1: {
    label: "Address line 1",
    id: "franchiseAddress1",
    autoComplete: "Address",
    required: true,
    placeholder: "Address",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Address line 1 is required"
      }
    }
  },
  address_line_2: {
    label: "Address line 2",
    id: "franchiseAddress2",
    autoComplete: "Address",
    required: false,
    placeholder: "Address",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  email: {
    label: "Franchise Email",
    id: "email",
    autoComplete: "Email",
    required: true,
    placeholder: "Franchise Email",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Franchise Email is required"
      },
      validateEmailRegex: {
        value: "true",
        message: "Email not valid"
      }
    }
  },
  franchise_website: {
    label: "Franchise Website",
    id: "franchise_website",
    autoComplete: "Franchise Website",
    required: true,
    placeholder: "Franchise Website",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  phone: {
    label: "Phone",
    id: "franchiseSitePhone",
    autoComplete: "Phone",
    required: true,
    placeholder: "Phone",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Phone no is required"
      }
    }
  },
  franchiseDescription: {
    label: "Description",
    id: "franchiseDescription",
    autoComplete: "Description",
    required: false,
    placeholder: "Description",
    autoFocus: true,
    type: "text",
    validations: {}
  }
};
export default FranchiseSchema;
