import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  IconButton,
  Backdrop,
  Tooltip,
  TableHead,
  TableCell,
  Typography
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import "./food_diary.css";
import { providerForDelete, providerForGet, providerForPost } from "../../api";
import { apiBackendUrl, food_diary, saveFoodDiary } from "../../constants";
import {
  Auth as auth,
  DarkBlueButton,
  HeadingOne,
  InlineDatePicker,
  SnackBar,
  useWindowDimensions,
  HeadingTwo,
  Body1,
  Auth,
  Input,
  Spinner,
  NameComponent
} from "../../components";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CLIENTDASHBAORD, CLIENTEXCHANGE } from "../../paths";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import moment from "moment";
import { formatDate, getFormattedDate } from "../../utils";
import { CircularProgress, Table, TableBody, TableRow } from "material-ui";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import styles from "./FoodDiary.module.css";

import PublishIcon from "@material-ui/icons/Publish";
import DialogBox from "../../components/DialogBox/DialogBox";

export default function FoodDiary(props) {
  const { width } = useWindowDimensions();
  const useStyles = makeStyles(theme => ({
    table: {
      minWidth: 700
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    borderNone: {
      textAlign: "center",
      padding: "1rem !important"
    },
    individualItem: {
      textAlignLast: "center",
      textAlign: "center",
      width: props.isDesktop ? width / 30 : 60,
      minWidth: props.isDesktop ? width / 30 : 60
    },
    individualItemBig: {
      textAlignLast: "center",
      textAlign: "center",
      width: props.isDesktop ? width / 8 : 150,
      minWidth: props.isDesktop ? width / 8 : 150
    },
    trRoot: {
      overflow: "auto",
      "& .MuiTable-root": {
        borderSpacing: " 0 1.0625rem !important",
        borderCollapse: "separate  !important"
      },
      "& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover": {
        background: "#F8F8FA"
      },
      "& .MuiTableRow-root": {
        "&:hover": {
          background: "#F8F8FA !important"
        }
      },
      marginTop: "15px"
    },

    ".MuiTableCell-root": {
      padding: "1rem !important"
    },
    inputRoot: {
      "& .MuiOutlinedInput-input": {
        textAlign: "center",
        padding: "0.875rem !important"
      },
      "& .MuiOutlinedInput-root": {
        margin: "8px 0px"
      },
      "& .MuiInputBase-root": {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "1rem",
        color: "#110F48"
      }
    },
    mainGrid: {
      overflow: props.isDesktop ? "none" : "auto"
    },
    imageDiv: {
      marginTop: "10px"
    },
    DefaultNoImage: {
      width: "750px",
      height: "200px",
      objectFit: "contain",
      backgroundColor: "#FFFFFF"
    },
    UploadImage: {
      width: "100%",
      height: "200px",
      objectFit: "contain",
      backgroundColor: "#FFFFFF"
    },
    centerImage: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: "30%"
    },
    paddingDate: {
      marginRight: "0.8rem",
      marginBottom: "1rem"
    },
    nameComponent: {
      marginBottom: "10px !important"
    }
  }));

  const classes = useStyles();
  let propData = props["location"]["state"];
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([
    {
      id: null,
      dateTime: new Date().toISOString(),
      date: getFormattedDate(new Date()),
      quantity: "",
      food: "",
      user: Auth.getUserInfo().id,
      franchise: Auth.getUserInfo().franchise.id,
      time: new Date().toLocaleTimeString(),
      notes: "",
      files: null,
      showPreviewImage: false,
      previewImage: null,
      showEditImage: false,
      previewEditImage: null,
      showNoImage: true,
      isNew: true
    }
  ]);
  const [openDialogBox, setOpenDialogBox] = useState({
    state: false,
    value: null
  });
  const [dateFilter, setDateFilter] = useState({
    fromDate: new Date(
      moment(new Date().setMonth(new Date().getMonth() - 3)).format(
        "YYYY-MM-DDT00:00:00.000Z"
      )
    ).toISOString(),
    toDate: new Date(
      moment(new Date()).format("YYYY-MM-DDT23:59:59.999Z")
    ).toISOString(),
    dateFromError: false,
    dateToError: false,
    dateFromErrorText: "",
    dateToErrorText: "",
    filterSelectError: false,
    filterSelectErrorText: "",
    gte_key: "",
    lte_key: ""
  });

  const [statusVariable, setStatusVariable] = useState({
    severity: "",
    isOpenSnackBar: false,
    successErrorMessage: ""
  });
  const history = useHistory();
  const [formState] = useState({
    userId:
      auth.getUserInfo().role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
        ? Auth.getUserInfo().id
        : propData
        ? propData["userId"]
          ? propData["userId"]
          : null
        : null,
    franchiseId: auth.getUserInfo().franchise.id,
    isClient:
      auth.getUserInfo().role.name === process.env.REACT_APP_CLIENT_ROLE_NAME,
    isNutritionist:
      auth.getUserInfo().role.name === process.env.REACT_APP_NUTRITIONIST_ROLE,

    isBackAvailable: propData ? propData.isBackAvailable : false,
    backPath: propData
      ? propData.isBackAvailable
        ? propData.backPath
        : null
      : null,
    backName: propData
      ? propData.isBackAvailable
        ? propData.backName
        : ""
      : "",
    customHeader: propData ? propData.customHeader : "FOOD DIARY"
  });

  const [totalCount, setTotalCount] = useState(0);

  const [filter, setFilter] = useState({
    _sort: "datetime:desc",
    page: 1,
    pageSize: 10,
    datetime_gte: dateFilter.fromDate,
    datetime_lte: dateFilter.toDate,
    user: formState.userId,
    franchise: formState.franchiseId,
    desc: `View client food diary from ${formatDate(
      dateFilter.fromDate
    )} to ${formatDate(dateFilter.toDate)}`
  });

  const convertData = data => {
    let arr = [];
    data.map(r => {
      let temp = {};
      temp = {
        id: r.id,
        dateTime: new Date(r.datetime).toISOString(),
        date: getFormattedDate(r.datetime),
        quantity: r.quantity,
        food: r.food,
        user: r.user.id,
        franchise: r.franchise.id,
        time: new Date(r.datetime).toLocaleTimeString(),
        notes: r.notes,
        files: null,
        showPreviewImage: false,
        previewImage: null,
        showEditImage: r.media.length && r.media[0].url ? true : false,
        previewEditImage:
          r.media.length && r.media[0].url ? r.media[0].url : null,
        showNoImage: !r.media.length ? true : false,
        isNew: false
      };
      arr.push(temp);
      return null;
    });
    return arr;
  };

  const getData = async (apiFilter = filter, addANewData = false) => {
    setLoader(true);
    if (formState.isNutritionist) {
      apiFilter = {
        ...apiFilter,
        audit_log_clientid: formState.userId
      };
    }
    await providerForGet(food_diary, apiFilter, Auth.getToken())
      .then(res => {
        if (res.data.totalCount) {
          let data = convertData(res.data.data);

          setData(data);
        } else {
          setData([]);
        }
        setFilter(filter => ({
          ...filter,
          page: res.data.page,
          pageSize: res.data.pageSize
        }));
        setTotalCount(res.data.totalCount);
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSnackbarClose = () => {
    setStatusVariable(statusVariable => ({
      ...statusVariable,
      severity: "",
      isOpenSnackBar: false,
      successErrorMessage: ""
    }));
  };

  const handleEndDateChange = event => {
    let endDate = moment(event).endOf("day").format("YYYY-MM-DDT23:59:59.999Z");
    let toDate = "";
    let dateToError = false;
    let dateToErrorText = "";
    if (endDate !== "Invalid date") {
      toDate = new Date(endDate).toISOString();
      if (
        dateFilter.fromDate &&
        dateFilter.fromDate !== "" &&
        moment(endDate).isBefore(moment(dateFilter.fromDate))
      ) {
        dateToError = true;
        dateToErrorText = ["To date cannot be less than from date"];
      }
    }

    setDateFilter(dateFilter => ({
      ...dateFilter,
      toDate: toDate,
      dateToError: dateToError,
      dateToErrorText: dateToErrorText
    }));
  };

  /** Handle Start Date filter change */
  const handleStartDateChange = event => {
    let startDate = moment(event).format("YYYY-MM-DDT00:00:00.000Z");
    let fromDate = null;
    let dateFromError = false;
    let dateFromErrorText = "";
    if (startDate !== "Invalid date") {
      fromDate = new Date(startDate).toISOString();
      if (
        dateFilter.toDate &&
        dateFilter.toDate !== "" &&
        moment(fromDate).isAfter(moment(dateFilter.toDate))
      ) {
        dateFromError = true;
        dateFromErrorText = ["From date cannot be greater than to date"];
      }
    }

    setDateFilter(dateFilter => ({
      ...dateFilter,
      fromDate: fromDate,
      dateFromError: dateFromError,
      dateFromErrorText: dateFromErrorText
    }));
  };

  const handleSearchButton = () => {
    if (
      !dateFilter.dateFromError &&
      !dateFilter.dateToError &&
      dateFilter.toDate &&
      dateFilter.fromDate &&
      dateFilter.toDate !== "" &&
      dateFilter.fromDate !== ""
    ) {
      let gte_key = "datetime_gte";
      let lte_key = "datetime_lte";

      setDateFilter(dateFilter => ({
        ...dateFilter,
        gte_key: gte_key,
        lte_key: lte_key
      }));
      setFilter(filter => ({
        ...filter,
        page: 1,
        datetime_gte: formatDate(dateFilter.fromDate),
        datetime_lte: formatDate(dateFilter.toDate)
      }));
      getData({
        ...filter,
        page: 1,
        datetime_gte: dateFilter.fromDate,
        datetime_lte: dateFilter.toDate,
        desc: `View client food diary from ${formatDate(
          dateFilter.fromDate
        )} to ${formatDate(dateFilter.toDate)}`
      });
    }
  };

  const handleAddNewNote = () => {
    setData([
      {
        id: null,
        dateTime: new Date().toISOString(),
        date: getFormattedDate(new Date()),
        quantity: "",
        food: "",
        user: Auth.getUserInfo().id,
        franchise: Auth.getUserInfo().franchise.id,
        time: new Date().toLocaleTimeString(),
        notes: "",
        files: null,
        showPreviewImage: false,
        previewImage: null,
        showEditImage: false,
        previewEditImage: null,
        showNoImage: true,
        isNew: true
      },
      ...data
    ]);
  };

  const saveData = async (hideAlert = false) => {
    setLoader(true);
    const formData = new FormData();
    data.map((d, i) => {
      if (d.food) {
        let dataToAppend = {
          id: d.id,
          dateTime: d.dateTime,
          quantity: d.quantity,
          food: d.food,
          user: d.user,
          franchise: d.franchise,
          notes: d.notes,
          imageRemoved: d.imageRemoved ? d.imageRemoved : false
        };

        if (d.files && d.showPreviewImage) {
          formData.append(`data${i}`, JSON.stringify(dataToAppend));
          formData.append(`files.media${i}`, d.files);
        } else {
          formData.append(`data${i}`, JSON.stringify(dataToAppend));
        }
      }
      return null;
    });

    await providerForPost(saveFoodDiary, formData, Auth.getToken(), {
      desc: `Save Update food Diary`
    })
      .then(res => {
        if (!hideAlert) {
          getData();
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            severity: "success",
            isOpenSnackBar: true,
            successErrorMessage: "food diary notes saved successfully"
          }));
        }
      })
      .catch(error => {
        setLoader(false);
        if (!hideAlert) {
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            severity: "error",
            isOpenSnackBar: true,
            successErrorMessage: "An error occured! " + error
          }));
        }
      });
  };

  const handleBackArrow = async () => {
    //calling save data function
    await saveData();
    if (formState.isNutritionist) {
      history.push(formState.backPath);
    } else {
      if (formState.isBackAvailable && formState.backPath) {
        let data = {
          id: formState.scheduleIdToSendBack,
          scheduleObj: formState.scheduleObjToSendBack
        };

        if (formState.backPath === CLIENTEXCHANGE) {
          data = {
            ...data,
            exchangeDay: formState.dayToSendBack
          };
        }
        history.push(formState.backPath, data);
      } else {
        history.push(CLIENTDASHBAORD);
      }
    }
  };

  const handleUploadImage = async (event, i) => {
    if (
      event.target.files &&
      event.target.files.length &&
      event.target.files[0].type
    ) {
      if (
        !(
          event.target.files[0].type === "image/jpg" ||
          event.target.files[0].type === "image/jpeg" ||
          event.target.files[0].type === "image/png"
        )
      ) {
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          severity: "error",
          isOpenSnackBar: true,
          successErrorMessage: "Image format not supported"
        }));
      } else if (event.target.files[0].size > 5000000) {
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          severity: "error",
          isOpenSnackBar: true,
          successErrorMessage: "File size cannot be greater than 5 Mb"
        }));
      } else {
        let arr = data[i];
        arr.files = event.target.files[0];
        arr.showPreviewImage = true;
        arr.previewImage = URL.createObjectURL(event.target.files[0]);
        arr.showEditImage = false;
        arr.previewEditImage = null;
        arr.showNoImage = false;
        setData([...data.slice(0, i), arr, ...data.slice(i + 1)]);
      }
    }
  };

  const goToNextPage = () => {
    // saving data
    saveData(true);
    let testFilter = filter;
    testFilter = {
      ...testFilter,
      page: filter.page + 1
    };
    setFilter(testFilter);
    getData(testFilter);
  };

  const goToBackPage = () => {
    let testFilter = filter;
    testFilter = {
      ...testFilter,
      page: filter.page - 1
    };
    setFilter(testFilter);
    getData(testFilter);
  };

  const goToFirstPage = () => {
    let testFilter = filter;
    testFilter = {
      ...testFilter,
      page: 1
    };
    setFilter(testFilter);
    getData(testFilter);
  };

  const goToLastPage = () => {
    //saving data
    saveData(true);
    let page = parseInt(Math.round(totalCount / filter.pageSize));
    if (!page) {
      page = 1;
    }
    let testFilter = filter;
    testFilter = {
      ...testFilter,
      page: page
    };
    setFilter(testFilter);
    getData(testFilter);
  };

  const removeImage = i => {
    let arr = data[i];
    if (data[i].id && data[i].showEditImage) {
      arr = {
        ...arr,
        imageRemoved: true
      };
    }
    arr = {
      ...arr,
      files: null,
      showPreviewImage: false,
      previewImage: null,
      showEditImage: false,
      previewEditImage: null,
      showNoImage: true
    };

    setData([...data.slice(0, i), arr, ...data.slice(i + 1)]);
  };

  const closeDialogBox = () => {
    setOpenDialogBox({ state: false, value: null });
  };

  const handleFoodDiaryDelete = async () => {
    let id = data[openDialogBox.value].id;
    if (id) {
      //save data and then delete
      saveData(true);
      await providerForDelete(food_diary, id, auth.getToken(), {
        desc: "Delete Food Diary Data"
      })
        .then(async res => {
          getData();
        })
        .catch(err => {});
    } else {
      if (data.length > 1) {
        let index = openDialogBox.value;
        setData([...data.slice(0, index), ...data.slice(index + 1)]);
      } else {
        setData([]);
      }
    }
    closeDialogBox();
  };

  return (
    <>
      <div>
        <SnackBar
          open={statusVariable.isOpenSnackBar}
          severity={statusVariable.severity}
          onClose={handleSnackbarClose}
        >
          {statusVariable.successErrorMessage}
        </SnackBar>
        <Tooltip
          title={
            formState.isBackAvailable ? formState.backName : "Back To Dashboard"
          }
        >
          <IconButton
            style={{
              position: "relative",
              right: "120px"
            }}
            onClick={() => handleBackArrow()}
          >
            <ChevronLeftIcon
              style={{
                padding: "5px",
                border: "solid #DADADA 1px",
                borderRadius: "25px",
                margin: "0px 30px 0px 10px",
                background: "#fff",
                marginTop: 0
              }}
            />
          </IconButton>
        </Tooltip>
        {propData ? (
          propData.NameComponent ? (
            <Grid md={6} xs={12} className={classes.nameComponent}>
              <NameComponent
                initials={propData.NameComponent.initials}
                full_name={propData.NameComponent.full_name}
                id={propData.NameComponent.id}
                scheduleData={propData.NameComponent.scheduleData}
              />
            </Grid>
          ) : null
        ) : null}
        <HeadingOne>
          <FileCopyIcon />
          <span style={{ position: "absolute", marginLeft: "10px" }}>
            {formState.customHeader ? formState.customHeader : "FOOD DIARY"}
          </span>
        </HeadingOne>
        <Grid container spacing={1} alignItems="center">
          <Grid
            item
            xs={12}
            md={10}
            lg={10}
            style={{
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              className={classes.paddingDate}
            >
              <InlineDatePicker
                id="startDate"
                label="From Date"
                placeholder="MM/DD/YYYY"
                maxDate={new Date()}
                value={dateFilter.fromDate || null}
                name={"From Date"}
                onChange={event => handleStartDateChange(event)}
                error={dateFilter.dateFromError}
                required
                fullWidth
                helperText={
                  dateFilter.dateFromError ? dateFilter.dateFromErrorText : null
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              className={classes.paddingDate}
            >
              <InlineDatePicker
                id="endDate"
                label="To Date"
                maxDate={new Date()}
                placeholder="MM/DD/YYYY"
                value={dateFilter.toDate || null}
                name={"To Date"}
                onChange={event => handleEndDateChange(event)}
                error={dateFilter.dateToError}
                required
                fullWidth
                helperText={
                  dateFilter.dateToError ? dateFilter.dateToErrorText : null
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              lg={2}
              className={classes.paddingDate}
              style={{
                alignSelf: "center"
              }}
            >
              <DarkBlueButton
                variant="contained"
                size="small"
                style={{
                  margin: "8px 0 8px 5px"
                }}
                onClick={handleSearchButton}
              >
                SEARCH
              </DarkBlueButton>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            lg={2}
            style={{
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            {formState.isNutritionist || filter.page !== 1 ? null : (
              <IconButton
                color="secondary"
                aria-label="Add supplement"
                onClick={event => {
                  handleAddNewNote();
                }}
                size="medium"
              >
                <AddCircleOutlineIcon id={"Add"} style={{ color: "green" }} />
              </IconButton>
            )}
            {formState.isNutritionist ? null : (
              <DarkBlueButton
                variant="contained"
                size="small"
                style={{
                  margin: "8px 0 8px 5px"
                }}
                onClick={() => saveData()}
              >
                Save
              </DarkBlueButton>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center">
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{
              textAlignLast: "center"
            }}
          >
            <IconButton
              color="secondary"
              aria-label="first_page"
              onClick={() => goToFirstPage()}
              size="medium"
              disabled={filter.page === 1 ? true : false}
            >
              <FirstPageIcon
                id={"Add"}
                style={{
                  color: filter.page !== 1 ? "green" : "grey"
                }}
              />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="before"
              onClick={() => goToBackPage()}
              size="medium"
              disabled={filter.page === 1 ? true : false}
            >
              <NavigateBeforeIcon
                id={"Add"}
                style={{
                  color: filter.page !== 1 ? "green" : "grey"
                }}
              />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="first_page"
              onClick={() => goToNextPage()}
              size="medium"
              disabled={
                totalCount - filter.page * filter.pageSize > 0 ? false : true
              }
            >
              <NavigateNextIcon
                id={"Add"}
                style={{
                  color:
                    totalCount - filter.page * filter.pageSize > 0
                      ? "green"
                      : "grey"
                }}
              />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="before"
              onClick={() => goToLastPage()}
              size="medium"
              disabled={
                totalCount - filter.page * filter.pageSize > 0 ? false : true
              }
            >
              <LastPageIcon
                id={"Add"}
                style={{
                  color:
                    totalCount - filter.page * filter.pageSize > 0
                      ? "green"
                      : "grey"
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            marginTop: "2%"
          }}
        >
          <Grid item className={classes.mainGrid}>
            <div className={classes.trRoot}>
              {data.length ? (
                <Table className={styles.food_diary}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.borderNone}>
                        <HeadingTwo>Date</HeadingTwo>
                      </TableCell>
                      <TableCell className={classes.borderNone}>
                        <HeadingTwo>Time</HeadingTwo>
                      </TableCell>
                      <TableCell className={classes.borderNone}>
                        <HeadingTwo>Quantity</HeadingTwo>
                      </TableCell>
                      <TableCell className={classes.borderNone}>
                        <HeadingTwo>Food/Water</HeadingTwo>
                      </TableCell>
                      <TableCell className={classes.borderNone}>
                        <HeadingTwo>Image</HeadingTwo>
                      </TableCell>
                      <TableCell className={classes.borderNone}>
                        <HeadingTwo>Notes</HeadingTwo>
                      </TableCell>
                      <TableCell className={classes.borderNone}></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.borderNone}>
                          <Body1>{row.date}</Body1>
                        </TableCell>
                        <TableCell className={classes.borderNone}>
                          <Body1>{row.time}</Body1>
                        </TableCell>
                        {formState.isNutritionist ? (
                          <TableCell className={classes.borderNone}>
                            <Body1>{row.quantity}</Body1>
                          </TableCell>
                        ) : (
                          <TableCell className={classes.borderNone}>
                            <Input
                              id={"quantity" + index}
                              InputProps={{
                                readOnly:
                                  formState.isNutritionist === true
                                    ? true
                                    : false
                              }}
                              style={{
                                width: "100%"
                              }}
                              variant="outlined"
                              onChange={event => {
                                let arr = data[index];
                                arr.quantity = event.target.value;
                                setData([
                                  ...data.slice(0, index),
                                  arr,
                                  ...data.slice(index + 1)
                                ]);
                              }}
                              value={row.quantity ? row.quantity : ""}
                            />
                          </TableCell>
                        )}
                        <TableCell
                          className={classes.borderNone}
                          style={{
                            minWidth: "150px"
                          }}
                        >
                          {formState.isNutritionist ? (
                            <Body1>{row.food}</Body1>
                          ) : (
                            <Input
                              id={"food" + index}
                              InputProps={{
                                readOnly:
                                  formState.isNutritionist === true
                                    ? true
                                    : false
                              }}
                              style={{
                                width: "100%"
                              }}
                              variant="outlined"
                              onChange={event => {
                                let arr = data[index];
                                arr.food = event.target.value;
                                setData([
                                  ...data.slice(0, index),
                                  arr,
                                  ...data.slice(index + 1)
                                ]);
                              }}
                              value={row.food ? row.food : ""}
                            />
                          )}
                        </TableCell>
                        <TableCell className={classes.borderNone}>
                          <Grid
                            item
                            style={{
                              textAlign: "center"
                            }}
                          >
                            <div className={classes.imageDiv}>
                              <Grid item md={12} xs={12}>
                                {" "}
                                {row.showPreviewImage ? (
                                  <div className="image">
                                    <img
                                      src={row.previewImage}
                                      alt="abc"
                                      style={{
                                        height: "150px",
                                        width: "300px"
                                      }}
                                      loader={<Spinner />}
                                      className={classes.UploadImage}
                                    />
                                    {formState.isNutritionist ? null : (
                                      <div
                                        className="delete"
                                        type="button"
                                        onClick={() =>
                                          formState.isNutritionist
                                            ? null
                                            : removeImage(index)
                                        }
                                      >
                                        X
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                                {row.showEditImage ? (
                                  <div className="image">
                                    <img
                                      src={apiBackendUrl + row.previewEditImage}
                                      loader={<Spinner />}
                                      style={{
                                        height: "150px",
                                        width: "300px"
                                      }}
                                      alt="abc"
                                      className={classes.UploadImage}
                                    />
                                    {formState.isNutritionist ? null : (
                                      <div
                                        className="delete"
                                        type="button"
                                        onClick={() =>
                                          formState.isNutritionist
                                            ? null
                                            : removeImage(index)
                                        }
                                      >
                                        X
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                                {row.showNoImage ? (
                                  <>
                                    <Input
                                      fullWidth
                                      id={"upload-logo" + index}
                                      name={index}
                                      onClick={event => {
                                        event.target.value = null;
                                      }}
                                      onChange={event =>
                                        handleUploadImage(event, index)
                                      }
                                      type="file"
                                      inputProps={{ accept: "image/*" }}
                                      variant="outlined"
                                      className={classes.inputFile}
                                      style={{
                                        display: "none"
                                      }}
                                    />
                                    <label htmlFor={"upload-logo" + index}>
                                      {formState.isNutritionist ? null : (
                                        <IconButton
                                          variant="contained"
                                          size="small"
                                          component="span"
                                          style={{}}
                                        >
                                          <Tooltip title="Upload Image">
                                            <PublishIcon
                                              style={{
                                                color: "#1C4979",
                                                fontSize: 40
                                              }}
                                            />
                                          </Tooltip>
                                        </IconButton>
                                      )}
                                    </label>
                                  </>
                                ) : null}
                              </Grid>
                            </div>
                          </Grid>
                        </TableCell>
                        <TableCell
                          className={classes.borderNone}
                          style={{
                            minWidth: "200px"
                          }}
                        >
                          {formState.isNutritionist ? (
                            <Body1>{row.notes}</Body1>
                          ) : (
                            <Input
                              id={"notes" + index}
                              multiline
                              rows={7}
                              InputProps={{
                                readOnly:
                                  formState.isNutritionist === true
                                    ? true
                                    : false
                              }}
                              style={{
                                width: "100%"
                              }}
                              variant="outlined"
                              onChange={event => {
                                let arr = data[index];
                                arr.notes = event.target.value;
                                setData([
                                  ...data.slice(0, index),
                                  arr,
                                  ...data.slice(index + 1)
                                ]);
                              }}
                              value={row.notes ? row.notes : ""}
                            />
                          )}
                        </TableCell>
                        {formState.isNutritionist ? null : (
                          <TableCell className={classes.borderNone}>
                            <IconButton
                              color="secondary"
                              aria-label="Delete food diary data"
                              onClick={async () => {
                                setOpenDialogBox({
                                  state: true,
                                  value: index
                                });
                              }}
                            >
                              <DeleteForeverIcon
                                id={"PersonalNotes_" + index}
                                style={{ color: "red" }}
                              />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Grid
                  style={{
                    textAlign: "center"
                  }}
                >
                  <Typography
                    variant="caption"
                    color="info"
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      fontSize: "1.25rem",
                      marginBottom: "0.9375rem"
                    }}
                  >
                    No records to display
                  </Typography>
                </Grid>
              )}
            </div>
            <DialogBox
              open={openDialogBox.state}
              title="Delete food diary notes"
              buttonCancel="Cancel"
              handleCancel={closeDialogBox}
              skipOk={false}
              buttonOk={"DELETE"}
              handleOk={handleFoodDiaryDelete}
            >
              Are you sure you want to delete this food diary note?
            </DialogBox>
          </Grid>
        </Grid>
      </div>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
