import React, { useEffect, useState } from "react";
import addSizeWeightForm from "../SizeWeightSchema";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  LinearProgress
} from "@material-ui/core";
import {
  Auth,
  Input,
  SnackBar,
  DarkBlueButton,
  BorderLessButton
} from "../../../../components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { get } from "lodash";
import {
  checkAllKeysPresent,
  checkEmpty,
  getListOfKeysNotPresent,
  setErrors
} from "../../../../utils";
import SaveIcon from "@material-ui/icons/Save";
import { SIZEWEIGHT } from "../../../../paths";
import {
  providerForGet,
  providerForPost,
  providerForPut
} from "../../../../api";
import { sizeWeight } from "../../../../constants";
import {
  ERROR_MESSAGE,
  SAVE_MESSAGE,
  UPDATE_MESSAGE
} from "../../../../constants/genericConstants";
import useStyles from "../../../../utils/Styles/ManagePageStyles";

const AddEditSizeWeight = props => {
  const history = useHistory();
  const auth = Auth.getToken();
  const userInfo = Auth.getUserInfo();
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  let propData = props["location"]["state"];
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    alert: false,
    severity: "success",
    errorMessage: "",
    /** This is when we return from edit page */
    rowData: propData ? propData : [],
    editData: propData ? (propData["id"] ? true : false) : false,
    editDataId: propData ? propData["id"] : null
  });
  const sizeWeightName = "sizeWeightName";

  /**Api call for getting autocomplete list itmes */
  const getListData = async () => {
    if (formState.editData === true) {
      let editId = {
        id: formState.editDataId,
        desc: `${userInfo.full_name} accessed a portion/unit for editing`
      };
      await providerForGet(sizeWeight, editId, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              sizeWeightName: res.data.data[0].name
            }
          }));
          setLoader(false);
        })
        .catch(err => {
          console.log("err", err);
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    getListData();
  }, []);

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: ""
    }));
  };

  const handleChange = e => {
    e.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));
    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const hasError = field => (formState.errors[field] ? true : false);

  const handleSubmit = () => {
    setLoader(true);
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      addSizeWeightForm
    );
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, addSizeWeightForm);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        addSizeWeightForm
      );
      formState.errors = setErrors(formState.values, addSizeWeightForm);
    }
    if (isValid) {
      /**api call from here */
      let postData = {
        name: formState.values.sizeWeightName
      };
      if (formState.editData === true) {
        /**Put api call (Update method)*/
        providerForPut(sizeWeight, formState.editDataId, postData, auth, {
          desc: `${userInfo.full_name} updated the portion/unit ${formState.values.sizeWeightName}`
        })
          .then(res => {
            let successMessage = {
              successMessage: "Portion/Unit  " + res.data.name + UPDATE_MESSAGE
            };
            setLoader(false);
            history.push(SIZEWEIGHT, successMessage);
          })
          .catch(err => {
            console.log("err", err);
            if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              setFormState(formState => ({
                ...formState,
                alert: true,
                severity: "error",
                errorMessage: err.response.data.message
              }));
            } else {
              setFormState(formState => ({
                ...formState,
                alert: true,
                severity: "error",
                errorMessage: ERROR_MESSAGE + err
              }));
            }
            setLoader(false);
          });
      } else {
        /**Post api call (Save method)*/

        providerForPost(sizeWeight, postData, auth, {
          desc: `${userInfo.full_name} added a new portion/unit ${formState.values.sizeWeightName}`
        })
          .then(res => {
            let successMessage = {
              successMessage: "Portion/Unit " + res.data.name + SAVE_MESSAGE
            };
            setLoader(false);
            history.push(SIZEWEIGHT, successMessage);
          })
          .catch(err => {
            console.log("error", err);
            if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              setFormState(formState => ({
                ...formState,
                alert: true,
                severity: "error",
                errorMessage: err.response.data.message
              }));
            } else {
              setFormState(formState => ({
                ...formState,
                alert: true,
                severity: "error",
                errorMessage: ERROR_MESSAGE + err
              }));
            }
            setLoader(false);
          });
      }
    } else {
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
      setLoader(false);
    }
  };

  const handleCancel = () => {
    history.push(SIZEWEIGHT);
  };
  return (
    <div>
      {loader ? (
        <LinearProgress />
      ) : (
        <>
          <Grid item xs={12}>
            {formState.editData === true ? (
              <Typography className={classes.titleText}>
                Edit Portion/Unit
              </Typography>
            ) : (
              <Typography className={classes.titleText}>
                Add Portion/Unit
              </Typography>
            )}
          </Grid>
          <Grid>
            <SnackBar
              open={formState.alert}
              severity={formState.severity}
              onClose={handleSnackbarClose}
            >
              {formState.errorMessage}
            </SnackBar>
          </Grid>
          <Grid item xs={12} className={classes.formgrid}>
            <Card className={classes.editCard}>
              <CardContent className={classes.editCardContent}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addSizeWeightForm[sizeWeightName], "label")}
                      name={sizeWeightName}
                      value={formState.values[sizeWeightName] || ""}
                      error={hasError(sizeWeightName)}
                      placeholder={get(
                        addSizeWeightForm[sizeWeightName],
                        "placeholder"
                      )}
                      variant="outlined"
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(sizeWeightName)
                          ? formState.errors[sizeWeightName].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} className={classes.actionButtonGrid}>
            <DarkBlueButton
              type="submit"
              data-testid="ok-button"
              onClick={handleSubmit}
            >
              <SaveIcon />
              &nbsp; Save
            </DarkBlueButton>

            <BorderLessButton onClick={handleCancel}>cancel</BorderLessButton>
          </Grid>
        </>
      )}
    </div>
  );
};

AddEditSizeWeight.propTypes = {};

AddEditSizeWeight.defaultProps = {};

export default AddEditSizeWeight;
