import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/AvenirNext-Bold-01.ttf";
import "./fonts/AvenirNext-BoldItalic-02.ttf";
import "./fonts/AvenirNext-DemiBold-03.ttf";
import "./fonts/AvenirNext-DemiBoldItalic-04.ttf";
import "./fonts/AvenirNext-Heavy-09.ttf";
import "./fonts/AvenirNext-HeavyItalic-10.ttf";
import "./fonts/AvenirNext-Italic-05.ttf";
import "./fonts/AvenirNext-Medium-06.ttf";
import "./fonts/AvenirNext-MediumItalic-07.ttf";
import "./fonts/AvenirNext-Regular-08.ttf";
import "./fonts/AvenirNext-UltraLight-11.ttf";
import "./fonts/AvenirNext-UltraLightItalic-12.ttf";
import "./fonts/Raleway-Bold.ttf";
import "./fonts/Raleway-Medium.ttf";
import "./fonts/Raleway-Regular.ttf";
import "./fonts/Raleway-SemiBold.ttf";
import "./fonts/Montserrat-Bold.ttf";
import "./fonts/Montserrat-Medium.ttf";
import "./fonts/Montserrat-Regular.ttf";
import "./fonts/Montserrat-SemiBold.ttf";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_API,
  integrations: [new Integrations.BrowserTracing()],
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
