import React, { useEffect, useState } from "react";
import { providerForGet, providerForPut } from "../../api";
import { getCustomizationUrl } from "../../constants/urlConstants";
import { Auth, SnackBar, DarkBlueButton, HeadingOne } from "../../components";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Backdrop, CircularProgress, Grid } from "@material-ui/core";

const AllDocumentListPermission = props => {
  const userInfo = Auth.getUserInfo();
  const useStyles = makeStyles(theme => ({
    table: {
      minWidth: 650
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    cardTitleStyle: {
      padding: "1rem !important",
      fontSize: "0.9375rem !important",
      fontFamily: "Montserrat !important",
      fontWeight: "500 !important",
      lineHeight: "1.5rem !important",
      borderBottom: "1px solid #e0e0e0 !important",
      letterSpacing: "0.15px !important",
      textAlign: "center"
    },
    cardHeadTitleStyle: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "1rem",
      color: "#8A8A97",
      minWidth: 60,
      textAlign: "center",
      padding: "1rem"
    },
    goalsGrid: {
      marginTop: "15"
    }
  }));
  const auth = Auth.getToken();
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [allDocumentListKeys, setAllDocumentListKeys] = useState([]);
  const [formState, setFormState] = useState({
    areaValues: {},
    strokeValues: {},
    updateId: "",
    alert: false,
    errorMessage: "",
    severity: "success"
  });

  const getDocumentName = data => {
    let tempArray = [];
    Object.keys(data).map(number => {
      let temp = {};
      temp = {
        key: number,
        status: data[number]
      };
      tempArray.push(temp);
      return null;
    });

    return tempArray;
  };

  const getListOfDocument = () => {
    setLoader(true);
    let body = {
      is_document_permission_custom: true,
      desc: `${userInfo.full_name} viewed the document listing page`
    };
    providerForGet(getCustomizationUrl, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          updateId: res.data[0].id ? res.data[0].id : ""
        }));
        setAllDocumentListKeys(
          getDocumentName(res.data[0].customization_json_field.allDocuments)
        );
        setLoader(false);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });
  };

  useEffect(() => {
    setLoader(true);
    getListOfDocument();
  }, []);

  const handleChange = event => {
    let id = parseInt(event.target.id);
    let name = event.target.name;
    let dataCopy = [...allDocumentListKeys];
    dataCopy[id].status[name] = event.target.checked;
    //below if is used to set view checkbox as checked when download checkbox is checked
    if (name === "download" && event.target.checked) {
      dataCopy[id].status["view"] = event.target.checked;
    }
    if (!event.target.checked && name === "view") {
      dataCopy[id].status["download"] = false;
    }

    setAllDocumentListKeys(dataCopy);
  };

  const handleSubmit = () => {
    setLoader(true);
    let temp = {};
    for (var i = 0; i < allDocumentListKeys.length; i++) {
      let key = allDocumentListKeys[i].key;

      temp[key] = allDocumentListKeys[i].status;
    }

    let finalData = {
      allDocuments: temp
    };
    var myJsonString = JSON.stringify(finalData);
    let postData = {
      customization_json_field: myJsonString,
      users_permissions_role: Auth.getUserInfo().role.id,
      desc: `${userInfo.full_name} changed the document's permission`
    };

    providerForPut(getCustomizationUrl, formState.updateId, postData, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          alert: true,
          errorMessage: "New permissions has been updated",
          severity: "success"
        }));
        setLoader(false);
        getListOfDocument();
      })
      .catch(err => {
        console.log("Err", err);
        setLoader(false);
      });
  };

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "success"
    }));
  };

  const getName = key => {
    let name = "";
    if (key === "All Reports") {
      name = "All Reports";
    } else if (key === "Give All With Insert") {
      name = "Give All Book (With Inserts)";
    } else if (key === "Actual Food For All Meal") {
      name = "Actual Food Breakdown for All Meals Week";
    } else if (key === "Body Mass Reports") {
      name = "Body Mass Reports";
    } else if (key === "Give All") {
      name = "Give All Book (Without Inserts)";
    } else if (key === "Menu For Week List") {
      name = "Menu for All Meals for the Week";
    } else if (key === "Shopping List") {
      name = "Shopping List";
    } else if (key === "Measurements Reports") {
      name = "Measurements Reports";
    } else if (key === "Exercise Reports") {
      name = "Exercise Reports";
    } else if (key === "Supplement Reports") {
      name = "Supplement Reports";
    } else if (key === "Exchange Break Down For All Meals Week") {
      name = "Exchange Break Down For All Meals Week";
    }
    return name;
  };

  return (
    <>
      <Grid item xs={12} md={12} lg={12} className={classes.goalsGrid}>
        <HeadingOne>Document List</HeadingOne>
        <Grid>
          <SnackBar
            open={formState.alert}
            severity={formState.severity}
            onClose={handleSnackbarClose}
          >
            {formState.errorMessage}
          </SnackBar>
        </Grid>

        <div
          style={{
            float: "right",
            marginTop: "15px",
            marginBottom: "15px"
          }}
        >
          <DarkBlueButton
            onClick={() => {
              handleSubmit();
            }}
          >
            SAVE
          </DarkBlueButton>
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cardHeadTitleStyle}>
                  Sr no.{" "}
                </TableCell>
                <TableCell className={classes.cardHeadTitleStyle}>
                  Document list
                </TableCell>
                <TableCell className={classes.cardHeadTitleStyle}>
                  View
                </TableCell>
                <TableCell className={classes.cardHeadTitleStyle}>
                  Download
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allDocumentListKeys.map((row, i) => (
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.cardTitleStyle}
                  >
                    {i + 1}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.cardTitleStyle}
                  >
                    {getName(row.key)}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.cardTitleStyle}
                  >
                    <Checkbox
                      id={i}
                      value={row.key}
                      name={"view"}
                      checked={row.status.view}
                      onChange={handleChange}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.cardTitleStyle}
                  >
                    <Checkbox
                      id={i}
                      value={row.key}
                      name={"download"}
                      checked={row.status.download}
                      onChange={handleChange}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Backdrop className={classes.backdrop} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </>
  );
};

AllDocumentListPermission.propTypes = {};

AllDocumentListPermission.defaultProps = {};

export default AllDocumentListPermission;
