import { Container, Toolbar, Typography } from "@material-ui/core";
import React from "react";

const Footer = () => (
  <Container
    maxWidth="md"
    style={{
      marginRight: "0px"
    }}
  >
    <Toolbar>
      <Typography
        variant="body1"
        style={{
          textAlign: "right",
          fontFamily: "Montserrat",
          fontWeight: 500,
          color: "#110F48",
          fontSize: "0.75rem"
        }}
      >
        Copyright © {new Date().getFullYear()} Performance Fitness Concepts, All
        Rights Reserved.
      </Typography>
    </Toolbar>
  </Container>
);

export default Footer;
