import React, { useState, useEffect } from "react";
import styles from "./ClientMyNotes.module.css";
import { Grid, Tooltip } from "@material-ui/core";
import {
  AutoCompleteDropDown,
  HeadingOne,
  Input,
  Auth,
  HeadingTwo,
  DarkBlueButton,
  SelectDropdown,
  Spinner,
  BorderLessButton,
  SnackBar
} from "../../components";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { providerForGet, providerForPost } from "../../api";
import {
  getScheduleDateForClient,
  bodyMassTitle,
  measurementsTitle,
  exchangeMenuTitle,
  supplementTitle,
  exerciseTitle,
  foodDiaryTitle,
  nutritionistNotesIcon,
  clientNotesIcons,
  getNutrititionistNotes,
  getClientNotes,
  defaultIconsModel,
  addUpdateClientNotes,
  addUpdateNutrititionistNotes,
  apiBackendUrl,
  clientAddNewNotesTitleLogo,
  nutritionistAddNewNotesTitleLogo
} from "../../constants";
import { CLIENTDASHBAORD, CLIENTEXCHANGE } from "../../paths";
import coloredMenu from "../../assets/small_icons/Menu.png";
import coloredBodyMass from "../../assets/small_icons/Body Mass.png";
import coloredSupplements from "../../assets/small_icons/Supplements.png";
import coloredExercise from "../../assets/small_icons/Exercise.png";
import coloredMeasurement from "../../assets/small_icons/Measurement.png";
import food_diary from "../../assets/small_icons/Food Diary.png";
import { convertDate } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { Card, IconButton, LinearProgress, MenuItem } from "material-ui";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DialogBox from "../../components/DialogBox/DialogBox";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import noImageIcon from "../../assets/images/no_image_icon.png";
import EditIcon from "@material-ui/icons/Edit";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import noSchedule from "../../assets/images/No_schedule.jpg";
import Typography from "@material-ui/core/Typography";

const localStyles = makeStyles(theme => ({
  titleText: {
    color: "#110F48",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1.25rem",
    marginBottom: "1.5625rem",
    "& svg": {
      verticalAlign: "middle"
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: "#fff"
  },
  scheduleDate: {
    marginBottom: 25
  },
  textDesign: {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.5",
    letterSpacing: "-0.015em",
    color: "#000000"
  },
  textBox: {
    border: "1px solid lightgray"
  },
  iconStyle: {
    paddingTop: "4px",
    paddingBottom: "4px"
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  dialogBoxDesign: {
    "& MuiPaper-root": {
      width: 900
    }
  },
  notesSection: {
    marginTop: "1.25rem",
    width: "500px"
  },
  imageDiv: {
    marginTop: "10px"
  },
  DefaultNoImage: {
    width: "750px",
    height: "200px",
    objectFit: "contain",
    backgroundColor: "#FFFFFF"
  },
  UploadImage: {
    width: "100%",
    height: "200px",
    objectFit: "contain",
    backgroundColor: "#FFFFFF"
  },
  centerImage: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "30%"
  }
}));

const ClientMyNotes = props => {
  const [fileFormatErrorForLogo, setfileFormatErrorForLogo] = useState(false);
  const [fileFormatErrorText, setFileFormatErrorText] = useState("");
  const classes = localStyles();
  const auth = Auth.getToken();
  let propData = props["location"]["state"];
  const noScheduleDataMessageFromBackend = "No schedule data";
  const history = useHistory();
  const userInfo = Auth.getUserInfo();
  const [dateList, setDateList] = useState([]);
  const [scheduleDateNotPresent, setScheduleDateNotPresent] = useState(false);
  const [loader, setLoader] = useState(false);
  const [addNewNotePopUp, setAddNewNotePopUp] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [dayList] = useState(Array.from({ length: 7 }, (_, i) => i + 1));

  const [formState, setFormState] = useState({
    isValid: false,
    scheduleId: propData ? propData["id"] : null,
    scheduleIdToSendBack: propData ? propData["id"] : null,
    scheduleObj: propData ? propData.scheduleObj : null,
    scheduleObjToSendBack: propData ? propData.scheduleObj : null,
    dayToSendBack: propData ? propData.day : null,
    isClient: propData
      ? propData.isClient
        ? propData.isClient
        : false
      : false,
    isNutritionist: propData
      ? propData.isNutritionist
        ? propData.isNutritionist
        : false
      : false,
    isPopUp: propData ? (propData.isPopUp ? propData.isPopUp : false) : false,
    isBackAvailable: propData ? propData.isBackAvailable : false,
    backPath: propData
      ? propData.isBackAvailable
        ? propData.backPath
        : null
      : null,
    backName: propData
      ? propData.isBackAvailable
        ? propData.backName
        : ""
      : "",
    isViewOnly: propData ? propData.isViewOnly : false,
    customHeader: propData ? propData.customHeader : "NOTES",
    fromNutritionist: propData
      ? propData.fromNutritionist
        ? true
        : false
      : false,
    clientId: propData ? (propData.clientId ? propData.clientId : null) : null
  });

  const [newNotes, setNewNotes] = useState({
    showNewNotesLogoPreviewNoImage: true,
    showNewNotesLogoPreviewImage: false,
    newNotesLogo: null,
    newNotesLogoEditPreviewImage: null,
    previewNotesLogo: null,
    showNewNotesLogoEditImage: false,
    notesTitle: "",
    noteId: null,
    notesLogoError: [],
    isNotesTitleError: false,
    notesTitleError: ""
  });

  const [notesData, setNotesData] = useState({
    media: null,
    isDayPresent: propData
      ? propData.isDayPresent && propData.day
        ? true
        : false
      : false,
    entityLogo: null,
    data: "",
    day: propData
      ? propData.isDayPresent && propData.day
        ? propData.day
        : null
      : null,
    presentEntityKey: propData
      ? propData.initialKey
        ? propData.initialKey
        : "bodyMass"
      : "bodyMass"
  });

  const [icons, setIcons] = useState({
    bodyMass: {
      isSet: propData ? (propData.bodyMass ? propData.bodyMass : false) : false,
      id: null,
      title: bodyMassTitle,
      data: null,
      media: null,
      content: "",
      isHeaderFixed: true
    },
    measurement: {
      isSet: propData
        ? propData.measurement
          ? propData.measurement
          : false
        : false,
      id: null,
      title: measurementsTitle,
      data: null,
      media: null,
      content: "",
      isHeaderFixed: true
    },
    exchangeMenu: {
      id: null,
      isSet: propData
        ? propData.exchangeMenu
          ? propData.exchangeMenu
          : false
        : false,
      isDayPresent: true,
      title: exchangeMenuTitle,
      data: null,
      media: null,
      content: "",
      day: propData
        ? propData.isDayPresent && propData.day
          ? propData.day
          : null
        : null,
      isHeaderFixed: true
    },
    supplement: {
      isSet: propData
        ? propData.supplement
          ? propData.supplement
          : false
        : false,
      id: null,
      title: supplementTitle,
      data: null,
      media: null,
      content: "",
      isHeaderFixed: true
    },
    workout: {
      id: null,
      isSet: propData ? (propData.workout ? propData.workout : false) : false,
      isDayPresent: true,
      title: exerciseTitle,
      data: null,
      media: null,
      content: "",
      day: propData
        ? propData.isDayPresent && propData.day
          ? propData.day
          : null
        : null,
      isHeaderFixed: true
    },
    foodDiary: {
      isSet: propData
        ? propData.foodDiary
          ? propData.foodDiary
          : false
        : false,
      id: null,
      title: foodDiaryTitle,
      data: null,
      media: null,
      content: "",
      isHeaderFixed: true
    }
  });

  const [statusVariable, setStatusVariable] = useState({
    severity: "",
    isOpenSnackBar: false,
    successErrorMessage: ""
  });

  const getInitialInfo = async (
    scheduleId = formState.scheduleId,
    key = notesData.presentEntityKey,
    isDayPresent = notesData.isDayPresent,
    day = notesData.day
  ) => {
    let api = clientNotesIcons;
    if (formState.isClient) {
      api = clientNotesIcons;
    } else if (formState.isNutritionist) {
      api = nutritionistNotesIcon;
    }
    setEditorState(EditorState.createEmpty());
    await providerForGet(
      api,
      {
        schedule: scheduleId,
        key: key,
        isDayPresent: isDayPresent,
        day: day
      },
      auth
    )
      .then(async res => {
        let data = res.data;
        if (Object.keys(data).length) {
          Object.keys(data).map(r => {
            if (r === key) {
              const blocksFromHtml = htmlToDraft(data[r].content);
              const { contentBlocks, entityMap } = blocksFromHtml;
              const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
              );
              const editorState = EditorState.createWithContent(contentState);
              setEditorState(editorState);
              if (isDayPresent && day) {
                setIcons(icons => ({
                  ...icons,
                  [r]: {
                    ...icons[r],
                    isSet: true,
                    id: data[r].id,
                    media: data[r].media,
                    content: data[r].content,
                    data: data[r].data,
                    day: data[r].day,
                    isDayPresent: true,
                    isHeaderFixed: data[r].isHeaderFixed
                  }
                }));
              } else {
                setIcons(icons => ({
                  ...icons,
                  [r]: {
                    ...icons[r],
                    isSet: true,
                    id: data[r].id,
                    media: data[r].media,
                    content: data[r].content,
                    data: data[r].data,
                    title: data[r].title,
                    isHeaderFixed: data[r].isHeaderFixed
                  }
                }));
              }
              setNotesData(notesData => ({
                ...notesData,
                media: data[r].media
              }));
            } else {
              setIcons(icons => ({
                ...icons,
                [data[r].id]: {
                  isSet: false,
                  id: data[r].id,
                  title: data[r].title,
                  isOther: data[r].isOther,
                  iconPath: data[r].iconPath,
                  isHeaderFixed: data[r].isHeaderFixed
                }
              }));
            }
            return null;
          });
        }

        Object.keys(icons).map(i => {
          if (i === key) {
            setIcons(icons => ({
              ...icons,
              [i]: {
                ...icons[i],
                isSet: true
              }
            }));
          }
          return null;
        });
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
      });
  };

  const getScheduleDates = async () => {
    await providerForGet(
      getScheduleDateForClient,
      {
        c: formState.fromNutritionist ? formState.clientId : userInfo.id
      },
      auth
    )
      .then(async res => {
        let convertDateData = await convertDate(res.data);
        setDateList(convertDateData);
        let sid = null;
        if (!formState.scheduleId) {
          setFormState(formState => ({
            ...formState,
            scheduleId: convertDateData[0].id,
            scheduleObj: convertDateData[0]
          }));
          sid = convertDateData[0].id;
        } else {
          sid = formState.scheduleId;
          convertDateData.map(sd => {
            if (sd.id === sid) {
              setFormState(formState => ({
                ...formState,
                scheduleId: sid,
                scheduleObj: sd
              }));
            }
            return null;
          });
        }
        getInitialInfo(sid);
      })
      .catch(error => {
        setLoader(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.statusCode === 400 &&
          error.response.data.message === noScheduleDataMessageFromBackend
        ) {
          setScheduleDateNotPresent(true);
        }
      });
  };

  useEffect(() => {
    setLoader(true);
    getScheduleDates();
  }, []);

  const getNotesInfo = async (
    id = icons[notesData.presentEntityKey].id,
    key = notesData.presentEntityKey,
    day = notesData.day,
    isDayPresent = notesData.isDayPresent,
    scheduleId = formState.scheduleId
  ) => {
    setEditorState(EditorState.createEmpty());
    let api = getClientNotes;
    if (formState.isClient) {
      api = getClientNotes;
    } else if (formState.isNutritionist) {
      api = getNutrititionistNotes;
    }
    await providerForGet(
      api,
      {
        id,
        day: day,
        isDayPresent: isDayPresent,
        key: key,
        schedule: scheduleId
      },
      auth
    )
      .then(async res => {
        let data = res.data;
        const blocksFromHtml = htmlToDraft(data.content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
        if (data.isDayPresent) {
          setIcons(icons => ({
            ...icons,
            [key]: {
              ...icons[key],
              isSet: true,
              id: data.id,
              media: data.media,
              content: data.content,
              data: data.data,
              day: data.day,
              isDayPresent: true,
              isHeaderFixed: data.isHeaderFixed
            }
          }));
        } else {
          setIcons(icons => ({
            ...icons,
            [key]: {
              ...icons[key],
              isSet: true,
              id: data.id,
              media: data.media,
              content: data.content,
              data: data.data,
              isHeaderFixed: data.isHeaderFixed
            }
          }));
        }
        setNotesData(notesData => ({
          ...notesData,
          media: data.media
        }));
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
      });
  };

  const handleChangeAutoComplete = async (eventName, event, value) => {
    if (value !== null) {
      setLoader(true);
      setNewNotes(newNotes => ({
        ...newNotes,
        showNewNotesLogoPreviewNoImage: true,
        showNewNotesLogoPreviewImage: false,
        newNotesLogo: null,
        newNotesLogoEditPreviewImage: null,
        showNewNotesLogoEditImage: false,
        notesTitle: "",
        noteId: null,
        notesLogoError: [],
        isNotesTitleError: false,
        notesTitleError: ""
      }));
      setFormState(formState => ({
        ...formState,
        scheduleId: value.id,
        scheduleObj: value
      }));
      setNotesData(notesData => ({
        ...notesData,
        presentEntityKey: "bodyMass",
        isDayPresent: false,
        day: null
      }));

      setIcons(defaultIconsModel);

      getInitialInfo(value.id, "bodyMass", false, null);
    } else {
      setFormState(formState => ({
        ...formState,
        scheduleId: formState.scheduleId,
        scheduleObj: formState.scheduleObj
      }));
    }
  };

  const updateNotes = async () => {
    setLoader(true);
    let iconData = icons[notesData.presentEntityKey];
    let title = iconData.title;
    let api = addUpdateClientNotes;
    let desc = "Client - Add/Update Notes for " + title;
    if (formState.isClient) {
      api = addUpdateClientNotes;
      desc = "Client - Add/Update Notes for " + title;
    } else if (formState.isNutritionist) {
      api = addUpdateNutrititionistNotes;
      desc = "Nutritionist - Add/Update Notes for " + title;
    }
    await providerForPost(
      api,
      {
        id: iconData.id,
        title: iconData.title,
        description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        key: notesData.presentEntityKey,
        isDayPresent: iconData.hasOwnProperty("isDayPresent")
          ? iconData.isDayPresent
          : false,
        day: iconData.hasOwnProperty("day") ? iconData.day : null,
        schedule: formState.scheduleId
      },
      auth,
      {
        desc: desc
      }
    )
      .then(async res => {
        let data = res.data;
        let key = notesData.presentEntityKey;
        const blocksFromHtml = htmlToDraft(data.content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
        if (data.isDayPresent) {
          setIcons(icons => ({
            ...icons,
            [key]: {
              ...icons[key],
              isSet: true,
              id: data.id,
              media: data.media,
              content: data.content,
              data: data.data,
              day: data.day,
              isDayPresent: true,
              isHeaderFixed: data.isHeaderFixed
            }
          }));
        } else {
          setIcons(icons => ({
            ...icons,
            [key]: {
              ...icons[key],
              isSet: true,
              id: data.id,
              media: data.media,
              content: data.content,
              data: data.data,
              isHeaderFixed: data.isHeaderFixed
            }
          }));
        }
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
      });
  };

  const handleBackArrow = () => {
    if (formState.fromNutritionist) {
      history.push({
        pathname: formState.backPath,
        search: `?c=${propData.clientId}&s=${propData.id}`,
        state: {
          day: propData.day
        }
      });
    } else {
      if (formState.isBackAvailable && formState.backPath) {
        let data = {
          id: formState.scheduleIdToSendBack,
          scheduleObj: formState.scheduleObjToSendBack
        };

        if (formState.backPath === CLIENTEXCHANGE) {
          data = {
            ...data,
            exchangeDay: formState.dayToSendBack
          };
        }
        history.push(formState.backPath, data);
      } else {
        history.push(CLIENTDASHBAORD);
      }
    }
  };

  const switchNotes = (notes, day = null, isDayPresent = false) => {
    setLoader(true);
    setIcons(icons => ({
      ...icons,
      [notes]: {
        ...icons[notes],
        isSet: true,
        day: day
      },
      [notesData.presentEntityKey]: {
        ...icons[notesData.presentEntityKey],
        isSet: false
      }
    }));

    setNotesData(notesData => ({
      ...notesData,
      presentEntityKey: notes,
      day: day,
      isDayPresent: isDayPresent
    }));
    getNotesInfo(icons[notes].id, notes, day, isDayPresent);
  };

  const handleChange = event => {
    setLoader(true);
    setIcons(icons => ({
      ...icons,
      [notesData.presentEntityKey]: {
        ...icons[notesData.presentEntityKey],
        day: event.target.value
      }
    }));
    setNotesData(notesData => ({
      ...notesData,
      day: event.target.value
    }));
    getNotesInfo(null, undefined, event.target.value, true);
  };

  const handleFileChangeForNewNotesLogo = event => {
    setfileFormatErrorForLogo(false);
    setFileFormatErrorText("");
    if (
      event.target.name === "notesLogo" &&
      event.target.files &&
      event.target.files.length &&
      event.target.files[0].type
    ) {
      if (
        !(
          event.target.files[0].type === "image/jpg" ||
          event.target.files[0].type === "image/jpeg" ||
          event.target.files[0].type === "image/png"
        )
      ) {
        setfileFormatErrorForLogo(true);
        setFileFormatErrorText("Image format not supported");
      } else if (event.target.files[0].size > 1000000) {
        setfileFormatErrorForLogo(true);
        setFileFormatErrorText("File size cannot be greater than 1 Mb");
      } else {
        setfileFormatErrorForLogo(false);
        setFileFormatErrorText("");
        setNewNotes(newNotes => ({
          ...newNotes,
          newNotesLogo: event.target.files[0],
          previewNotesLogo: URL.createObjectURL(event.target.files[0]),
          showNewNotesLogoEditImage: false,
          showNewNotesLogoPreviewNoImage: false,
          showNewNotesLogoPreviewImage: true,
          notesLogoError: []
        }));
      }
    }
  };

  const editHeaderOfNewNotes = () => {
    let isImagePresent = false;
    if (
      icons[notesData.presentEntityKey].iconPath &&
      icons[notesData.presentEntityKey].iconPath.url
    ) {
      isImagePresent = true;
    }

    setNewNotes(newNotes => ({
      ...newNotes,
      showNewNotesLogoPreviewNoImage: isImagePresent ? false : true,
      showNewNotesLogoPreviewImage: false,
      newNotesLogo: null,
      newNotesLogoEditPreviewImage: isImagePresent
        ? icons[notesData.presentEntityKey].iconPath.url
        : null,
      showNewNotesLogoEditImage: isImagePresent ? true : false,
      notesTitle: icons[notesData.presentEntityKey].title,
      noteId: icons[notesData.presentEntityKey].id,
      notesLogoError: [],
      isNotesTitleError: false,
      notesTitleError: ""
    }));
    setAddNewNotePopUp(true);
  };

  const addEditLogoAndTitleOfNewNote = async () => {
    /** For validation */
    let val = !newNotes.notesTitle || /^\s*$/.test(newNotes.notesTitle);
    let isTitleError = false;
    let info = "";

    Object.keys(icons).map(i => {
      if (
        newNotes.noteId !== icons[i].id &&
        i.toLowerCase() === newNotes.notesTitle.toLowerCase()
      ) {
        isTitleError = true;
      }
      return null;
    });

    let isError = false;
    if (val || isTitleError) {
      if (isTitleError) {
        info = "Title already present";
      } else {
        info = "This Title cannot be used";
      }
      setNewNotes(newNotes => ({
        ...newNotes,
        isNotesTitleError: true,
        notesTitleError: info
      }));
      isError = true;
    }

    let postData = {};
    let body = {
      title: newNotes.notesTitle,
      id: newNotes.noteId,
      schedule: formState.scheduleId
    };

    if (
      newNotes.noteId &&
      icons[notesData.presentEntityKey].iconPath &&
      icons[notesData.presentEntityKey].iconPath.id
    ) {
      if (
        !newNotes.showNewNotesLogoPreviewImage &&
        !newNotes.showNewNotesLogoEditImage
      ) {
        body = {
          ...body,
          isRemoveImage: true
        };
      }
    }

    if (newNotes.newNotesLogo) {
      const data = new FormData();
      data.append("files.logo", newNotes.newNotesLogo);
      data.append("data", JSON.stringify(body));
      postData = data;
    } else {
      postData = body;
    }

    if (!isError) {
      let api = clientAddNewNotesTitleLogo;
      let desc = "Client - Add/Update Note " + newNotes.notesTitle;
      if (formState.isClient) {
        api = clientAddNewNotesTitleLogo;
        desc = "Client - Add/Update Note " + newNotes.notesTitle;
      } else if (formState.isNutritionist) {
        api = nutritionistAddNewNotesTitleLogo;
        desc = "Nutritionist - Add/Update Note for " + newNotes.notesTitle;
      }
      await providerForPost(api, postData, Auth.getToken(), {
        desc: desc
      })
        .then(res => {
          let data = res.data;
          if (data) {
            const blocksFromHtml = htmlToDraft(data.description);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
              contentBlocks,
              entityMap
            );
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);

            setIcons(icons => ({
              ...icons,
              [notesData.presentEntityKey]: {
                ...icons[notesData.presentEntityKey],
                isSet: false
              },
              [data.id]: {
                isSet: true,
                id: data.id,
                media: data.media,
                content: data.description,
                data: data,
                isHeaderFixed: false,
                title: data.title,
                isOther: data.isOther,
                iconPath: data.logo
              }
            }));

            setNotesData(notesData => ({
              ...notesData,
              presentEntityKey: data.id,
              day: null,
              isDayPresent: false
            }));

            setNewNotes(newNotes => ({
              ...newNotes,
              showNewNotesLogoPreviewNoImage: true,
              showNewNotesLogoPreviewImage: false,
              newNotesLogo: null,
              newNotesLogoEditPreviewImage: null,
              previewNotesLogo: null,
              showNewNotesLogoEditImage: false,
              notesTitle: "",
              noteId: null,
              notesLogoError: [],
              isNotesTitleError: false,
              notesTitleError: ""
            }));
            setAddNewNotePopUp(false);
          }
        })
        .catch(error => {});
    }
  };

  const handleClosePopup = () => {
    setNewNotes(newNotes => ({
      ...newNotes,
      showNewNotesLogoPreviewNoImage: true,
      showNewNotesLogoPreviewImage: false,
      newNotesLogo: null,
      newNotesLogoEditPreviewImage: null,
      previewNotesLogo: null,
      showNewNotesLogoEditImage: false,
      notesTitle: "",
      noteId: null,
      notesLogoError: [],
      isNotesTitleError: false,
      notesTitleError: ""
    }));
    setAddNewNotePopUp(false);
  };

  const addNewNotes = () => {
    setNewNotes(newNotes => ({
      ...newNotes,
      showNewNotesLogoPreviewNoImage: true,
      showNewNotesLogoPreviewImage: false,
      newNotesLogo: null,
      newNotesLogoEditPreviewImage: null,
      showNewNotesLogoEditImage: false,
      notesTitle: "",
      noteId: null,
      notesLogoError: [],
      isNotesTitleError: false,
      notesTitleError: ""
    }));
    setAddNewNotePopUp(true);
  };

  const onEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  const imageUploadCallBack = file =>
    new Promise((resolve, reject) => {
      if (file && file.size && file.size <= 1000000) {
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          severity: "",
          isOpenSnackBar: false,
          successErrorMessage: ""
        }));
        var reader = new FileReader();
        reader.readAsDataURL(file);
        let img = new Image();
        reader.onload = function (e) {
          img.src = this.result;
        };
        img.onload = function () {
          var canvas = document.createElement("canvas");
          var context = canvas.getContext("2d");

          // image original size
          var originWidth = this.width;
          var originHeight = this.height;

          // Maximum size limit, you can achieve image compression by setting the width and height
          var maxWidth = 400,
            maxHeight = 500;
          // target size
          var targetWidth = originWidth,
            targetHeight = originHeight;
          // Image size exceeds 300x300 limit
          if (originWidth > maxWidth || originHeight > maxHeight) {
            if (originWidth / originHeight > maxWidth / maxHeight) {
              // wider, size limited by width
              targetWidth = maxWidth;
              targetHeight = Math.round(
                maxWidth * (originHeight / originWidth)
              );
            } else {
              targetHeight = maxHeight;
              targetWidth = Math.round(
                maxHeight * (originWidth / originHeight)
              );
            }
          }
          // canvas scales the image
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          // clear the canvas
          context.clearRect(0, 0, targetWidth, targetHeight);
          // Image Compression
          context.drawImage(img, 0, 0, targetWidth, targetHeight);
          // The first parameter is the created img object; the second three parameters are the upper left corner coordinates
          // and the second two are the canvas area width and height

          // compressed image to base64 url
          /*canvas.toDataURL(mimeType, qualityArgument), mimeType The default value is 'image/png';
           * qualityArgument indicates the quality of the exported image. This parameter is valid only when exported to jpeg and webp formats. The default value is 0.92*/
          var newUrl = canvas.toDataURL("image/jpeg", 0.92); //base64 format

          resolve({
            data: {
              link: newUrl
            }
          });
        };
      } else {
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          severity: "error",
          isOpenSnackBar: true,
          successErrorMessage: "File size cannot be more than 1 Mb"
        }));
        reject();
      }
    });

  const handleSnackbarClose = () => {
    setStatusVariable(statusVariable => ({
      ...statusVariable,
      severity: "",
      isOpenSnackBar: false,
      successErrorMessage: ""
    }));
  };

  return (
    <div>
      <SnackBar
        open={statusVariable.isOpenSnackBar}
        severity={statusVariable.severity}
        onClose={handleSnackbarClose}
      >
        {statusVariable.successErrorMessage}
      </SnackBar>
      <Tooltip
        title={
          formState.isBackAvailable ? formState.backName : "Back To Schedules"
        }
      >
        <IconButton
          className={styles.backIcon}
          onClick={() => handleBackArrow()}
        >
          <ChevronLeftIcon
            style={{
              padding: "5px",
              border: "solid #DADADA 1px",
              borderRadius: "25px",
              margin: "0px 1.875rem 0px 10px",
              background: "#fff",
              marginTop: 0
            }}
          />
        </IconButton>
      </Tooltip>
      <HeadingOne>
        <FileCopyIcon />
        <span style={{ position: "absolute", marginLeft: "10px" }}>
          {formState.customHeader ? formState.customHeader : "ADD NOTES"}
        </span>
      </HeadingOne>
      {scheduleDateNotPresent ? (
        <Grid>
          <img
            src={noSchedule}
            className={classes.centerImage}
            alt="No schedule/data"
          />
        </Grid>
      ) : (
        <>
          <Grid
            container
            spacing={1}
            style={{
              marginBottom: "1%"
            }}
          >
            <Grid item md={4} xs={12}>
              <AutoCompleteDropDown
                options={dateList}
                getOptionLabel={option => option.schedule_start_date}
                id="schedule_date"
                value={
                  dateList[
                    dateList.findIndex(function (item, i) {
                      return item.id === formState["scheduleId"];
                    })
                  ] || null
                }
                onChange={(event, value) => {
                  handleChangeAutoComplete("scheduleDate", event, value);
                }}
                renderInput={params => (
                  <Input {...params} id="schedule_date" label="Schedule Date" />
                )}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              {icons[notesData.presentEntityKey] &&
              icons[notesData.presentEntityKey].isDayPresent ? (
                <SelectDropdown
                  formControlForNotes
                  title="Day"
                  value={icons[notesData.presentEntityKey].day}
                  onChange={handleChange}
                >
                  {dayList.map(day => (
                    <MenuItem key={day} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </SelectDropdown>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <Grid item xs={6}>
                <HeadingTwo>
                  {`${icons[notesData.presentEntityKey].title}`}
                  {!icons[notesData.presentEntityKey].isHeaderFixed ? (
                    formState.isViewOnly ? null : (
                      <IconButton
                        variant="contained"
                        size="small"
                        onClick={editHeaderOfNewNotes}
                      >
                        <Tooltip title="Edit header">
                          <EditIcon
                            style={{ color: "#1C4979", fontSize: "1.25rem" }}
                          />
                        </Tooltip>
                      </IconButton>
                    )
                  ) : null}
                </HeadingTwo>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              lg={5}
              style={{ justifyContent: "flex-end", textAlign: "end" }}
            >
              {formState.isViewOnly ? null : (
                <>
                  <DarkBlueButton
                    variant="contained"
                    size="small"
                    style={{
                      margin: "8px 0 8px 5px"
                    }}
                    onClick={() => updateNotes()}
                  >
                    SAVE
                  </DarkBlueButton>
                </>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item style={{ width: "calc(100% - 93px)" }}>
              <Card
                style={{
                  width: "100%",
                  height: "600px"
                }}
              >
                {loader ? <LinearProgress /> : null}
                <div
                  style={{
                    padding: "1%"
                  }}
                >
                  <>
                    <Editor
                      readOnly={formState.isViewOnly ? true : false}
                      toolbarHidden={formState.isViewOnly ? true : false}
                      editorState={editorState}
                      toolbarClassName="rdw-toolbar"
                      wrapperClassName="rdw-wrapper"
                      editorClassName="rdw-editor"
                      onEditorStateChange={onEditorStateChange}
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "textAlign",
                          "colorPicker",
                          "image",
                          "remove",
                          "history"
                        ],
                        inline: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: ["bold", "italic", "underline"]
                        },
                        history: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        image: {
                          uploadEnabled: true,
                          alignmentEnabled: false, // Whether to display the arrange button is equivalent to text-align
                          uploadCallback: imageUploadCallBack,
                          previewImage: true,
                          inputAccept: "image/*",
                          alt: { present: false, mandatory: false }
                        }
                      }}
                      spellCheck
                      Localization={{
                        locale: "zh",
                        translations: { "generic.add": "add" }
                      }}
                      editorStyle={{ maxHeight: "500px" }}
                    />
                  </>
                </div>
              </Card>
            </Grid>
            <Grid item style={{ width: "93px" }}>
              <Card
                style={{
                  width: "auto",
                  height: "auto",
                  textAlign: "center",
                  paddingTop: "10%",
                  paddingBottom: "10%"
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className={classes.iconStyle}
                  style={
                    icons.bodyMass.isSet
                      ? {
                          backgroundColor: "#F1EEED"
                        }
                      : null
                  }
                >
                  <IconButton onClick={() => switchNotes("bodyMass")}>
                    <Tooltip title="Body Mass Notes">
                      <img
                        width="auto"
                        height="50px"
                        src={coloredBodyMass}
                        alt={"body_mass_notes"}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className={classes.iconStyle}
                  style={
                    icons.measurement.isSet
                      ? {
                          backgroundColor: "#F1EEED"
                        }
                      : null
                  }
                >
                  <IconButton onClick={() => switchNotes("measurement")}>
                    <Tooltip title="Measurements Notes">
                      <img
                        width="auto"
                        height="50px"
                        src={coloredMeasurement}
                        alt={"measurements_notes"}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className={classes.iconStyle}
                  style={
                    icons.exchangeMenu.isSet
                      ? {
                          backgroundColor: "#F1EEED"
                        }
                      : null
                  }
                >
                  <IconButton
                    onClick={() => switchNotes("exchangeMenu", 1, true)}
                  >
                    <Tooltip title="Exchange/Menu Notes">
                      <img
                        width="auto"
                        height="50px"
                        src={coloredMenu}
                        alt={"exchange_menu_notes"}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className={classes.iconStyle}
                  style={
                    icons.supplement.isSet
                      ? {
                          backgroundColor: "#F1EEED"
                        }
                      : null
                  }
                >
                  <IconButton onClick={() => switchNotes("supplement")}>
                    <Tooltip title="Supplements Notes">
                      <img
                        width="auto"
                        height="50px"
                        src={coloredSupplements}
                        alt={"supplement_notes"}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className={classes.iconStyle}
                  style={
                    icons.workout.isSet
                      ? {
                          backgroundColor: "#F1EEED"
                        }
                      : null
                  }
                >
                  <IconButton onClick={() => switchNotes("workout", 1, true)}>
                    <Tooltip title="Exercise Notes">
                      <img
                        width="auto"
                        height="50px"
                        src={coloredExercise}
                        alt={"exerceise_notes"}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
                {formState.isClient ? (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className={classes.iconStyle}
                    style={
                      icons.foodDiary.isSet
                        ? {
                            backgroundColor: "#F1EEED"
                          }
                        : null
                    }
                  >
                    <IconButton onClick={() => switchNotes("foodDiary")}>
                      <Tooltip title="Food Diary">
                        <img
                          width="auto"
                          height="50px"
                          src={food_diary}
                          alt={"food_diary"}
                        />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                ) : null}
                {Object.keys(icons).map(i =>
                  icons[i].isOther ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        className={classes.iconStyle}
                        style={
                          icons[i].isSet
                            ? {
                                backgroundColor: "#F1EEED"
                              }
                            : null
                        }
                      >
                        <IconButton onClick={() => switchNotes(icons[i].id)}>
                          <Tooltip title={icons[i].title}>
                            {icons[i].iconPath ? (
                              <img
                                width="auto"
                                height="50px"
                                src={apiBackendUrl + icons[i].iconPath.url}
                                alt={"exerceise_notes"}
                              />
                            ) : (
                              <img
                                width="auto"
                                height="50px"
                                src={noImageIcon}
                                alt={"exerceise_notes"}
                              />
                            )}
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </>
                  ) : null
                )}
                {formState.isViewOnly ? null : (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className={classes.iconStyle}
                  >
                    <IconButton onClick={addNewNotes}>
                      <Tooltip title="Add New Note">
                        <AddCircleOutlineIcon fontSize={"large"} />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                )}
              </Card>
            </Grid>
          </Grid>
          <DialogBox
            skipCancel={false}
            skipOk={false}
            open={addNewNotePopUp}
            title={"Add Note"}
            className={classes.dialogBoxDesign}
            children={
              <div>
                <p style={{ marginTop: "1.25rem" }}>Add Title</p>
                <Grid container spacing={1} className={classes.notesSection}>
                  <Grid item md={12} xs={12}>
                    <Input
                      fullWidth
                      id="notesTitle"
                      name="notesTitle"
                      onChange={event => {
                        setNewNotes(newNotes => ({
                          ...newNotes,
                          isNotesTitleError: false,
                          notesTitleError: "",
                          notesTitle: event.target.value
                        }));
                      }}
                      value={newNotes.notesTitle || ""}
                      variant="outlined"
                      error={newNotes.isNotesTitleError}
                      helperText={
                        newNotes.isNotesTitleError
                          ? newNotes.notesTitleError
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.notesSection}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      textAlign: "center"
                    }}
                  >
                    <div className={classes.imageDiv}>
                      <Grid item md={12} xs={12}>
                        {" "}
                        {newNotes.showNewNotesLogoPreviewImage ? (
                          <img
                            src={newNotes.previewNotesLogo}
                            alt="abc"
                            style={{
                              height: "150px",
                              width: "300px"
                            }}
                            loader={<Spinner />}
                            className={classes.UploadImage}
                          />
                        ) : null}
                        {newNotes.showNewNotesLogoEditImage ? (
                          <>
                            <img
                              src={
                                apiBackendUrl +
                                newNotes.newNotesLogoEditPreviewImage
                              }
                              loader={<Spinner />}
                              style={{
                                height: "150px",
                                width: "300px"
                              }}
                              alt="abc"
                              className={classes.UploadImage}
                            />
                          </>
                        ) : null}
                        {newNotes.showNewNotesLogoPreviewNoImage ? (
                          <img
                            src={noImageIcon}
                            alt="abc"
                            style={{
                              height: "150px",
                              width: "300px"
                            }}
                            loader={<Spinner />}
                            className={classes.DefaultNoImage}
                          />
                        ) : null}
                      </Grid>
                    </div>
                  </Grid>
                  {fileFormatErrorForLogo ? (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        textAlign: "center"
                      }}
                    >
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        style={{
                          color: "red"
                        }}
                      >
                        {fileFormatErrorText}
                      </Typography>
                    </Grid>
                  ) : null}

                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Input
                        fullWidth
                        id="upload-logo"
                        name="notesLogo"
                        onClick={event => {
                          event.target.value = null;
                        }}
                        onChange={handleFileChangeForNewNotesLogo}
                        type="file"
                        error={fileFormatErrorForLogo}
                        inputProps={{ accept: "image/*" }}
                        helperText={
                          fileFormatErrorForLogo
                            ? "Image file format not supported"
                            : null
                        }
                        variant="outlined"
                        className={classes.inputFile}
                        style={{
                          display: "none"
                        }}
                      />
                      <label htmlFor={"upload-logo"}>
                        <DarkBlueButton
                          variant="contained"
                          color="primary"
                          component="span"
                          fullWidth
                          startIcon={<AddOutlinedIcon />}
                        >
                          ADD LOGO{" "}
                        </DarkBlueButton>
                      </label>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <BorderLessButton
                        variant="contained"
                        color="primary"
                        component="span"
                        fullWidth
                        onClick={() => {
                          setNewNotes(newNotes => ({
                            ...newNotes,
                            newNotesLogo: null,
                            previewNotesLogo: null,
                            newNotesLogoEditPreviewImage: null,
                            showNewNotesLogoEditImage: false,
                            showNewNotesLogoPreviewNoImage: true,
                            showNewNotesLogoPreviewImage: false,
                            notesLogoError: []
                          }));
                        }}
                      >
                        REMOVE LOGO{" "}
                      </BorderLessButton>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            }
            handleOk={addEditLogoAndTitleOfNewNote}
            handleCancel={handleClosePopup}
            buttonCancel={"Cancel"}
            buttonOk={"Save"}
          />
        </>
      )}
    </div>
  );
};

ClientMyNotes.propTypes = {};

ClientMyNotes.defaultProps = {};

export default ClientMyNotes;
