// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Autocomplete_ReactAutoComplete__3kl_l:focus,\n.Autocomplete_ReactInput__2bfqN:hover fieldset,\n.Autocomplete_ReactInput__2bfqN input + fieldset {\n  display: flex;\n}\n\n.Autocomplete_ReactAutoComplete__3kl_l label {\n  font-size: 0.9375rem;\n  font-family: Montserrat, sans-serif;\n}\n.Autocomplete_ReactAutoComplete__3kl_l label + div {\n  padding: 1.15625rem 2.25rem 1.15625rem 0.875rem !important;\n}\n.Autocomplete_ReactAutoComplete__3kl_l legend {\n  font-family: Montserrat, sans-serif;\n}\n\n.Autocomplete_ReactAutoComplete__3kl_l input {\n  font-size: 0.9375rem;\n  font-family: Montserrat, sans-serif;\n  padding: 0px !important;\n}\n.Autocomplete_ReactAutoComplete__3kl_l {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/components/Form/Autocomplete/Autocomplete.module.css"],"names":[],"mappings":"AAAA;;;EAGE,aAAa;AACf;;AAEA;EACE,oBAAoB;EACpB,mCAAmC;AACrC;AACA;EACE,0DAA0D;AAC5D;AACA;EACE,mCAAmC;AACrC;;AAEA;EACE,oBAAoB;EACpB,mCAAmC;EACnC,uBAAuB;AACzB;AACA;EACE,WAAW;AACb","sourcesContent":[".ReactAutoComplete:focus,\n.ReactInput:hover fieldset,\n.ReactInput input + fieldset {\n  display: flex;\n}\n\n.ReactAutoComplete label {\n  font-size: 0.9375rem;\n  font-family: Montserrat, sans-serif;\n}\n.ReactAutoComplete label + div {\n  padding: 1.15625rem 2.25rem 1.15625rem 0.875rem !important;\n}\n.ReactAutoComplete legend {\n  font-family: Montserrat, sans-serif;\n}\n\n.ReactAutoComplete input {\n  font-size: 0.9375rem;\n  font-family: Montserrat, sans-serif;\n  padding: 0px !important;\n}\n.ReactAutoComplete {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ReactAutoComplete": "Autocomplete_ReactAutoComplete__3kl_l",
	"ReactInput": "Autocomplete_ReactInput__2bfqN"
};
export default ___CSS_LOADER_EXPORT___;
