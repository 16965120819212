import {
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import React from "react";
import useWindowDimensions from "../Window";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import styles from "./ExerciseGrid.module.css";
import { Input } from "../Form/Input";
import { AutoCompleteDropDown } from "../Form/Autocomplete";

const ExerciseGrid = props => {
  const { height, width } = useWindowDimensions();

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    summaryButtonBox: {
      display: "flex",
      marginBottom: "3%"
    },
    loadTemplate: {
      marginLeft: "auto"
    },
    individualItemTitle: {
      textAlignLast: "center",
      textAlign: "center",
      width: props.isDesktop ? width / 10 : 60,
      minWidth: props.isDesktop ? width / 10 : 60
    },
    individualItem: {
      textAlignLast: "center",
      textAlign: "center",
      width: props.isDesktop ? width / 30 : 60,
      minWidth: props.isDesktop ? width / 30 : 60
    },
    individualItemBig: {
      textAlignLast: "center",
      textAlign: "center",
      width: props.isDesktop ? width / 8 : 150,
      minWidth: props.isDesktop ? width / 8 : 150
    },
    mainHeaderStyles: {
      color: "white",
      "background-color": "#EEF1F1",
      "border-radius": "3px",
      alignContent: "center",
      marginTop: "6.5%",
      width: props.isDesktop ? "100%" : "1100px"
    },
    mainSupplementListData: {
      color: "white",
      "background-color": "#EEF1F1",
      "border-radius": "3px",
      alignContent: "center",
      marginTop: "15px",
      padding: "15px 0px",
      borderRadius: 10,
      width: props.isDesktop ? "100%" : "1100px"
    },
    foodHeaderStyles: {
      color: "white",
      "background-color": "#EEF1F1",
      "border-radius": "3px",
      height: height * 0.04,
      alignContent: "center",
      marginTop: "2%",
      width: props.isDesktop ? width / 2.5 : "640px"
    },
    mainGrid: {
      overflow: props.isDesktop ? "none" : "auto"
    },
    duplicateReplicateStyle: {
      display: "flex",
      justifyContent: "flex-end",
      marginLeft: "auto"
    },

    addExchangesForMealsGrid: {
      height: props.isDesktop ? height * 0.5 : "500px",
      overflowY: "auto",
      overflowX: props.isDesktop ? "hidden" : "auto",
      marginTop: "1%",
      marginBottom: "1%",
      width: "fit-content"
    },
    addExchangesForMealsGridMobile: {
      height: props.isDesktop ? height * 0.5 : "500px",
      //overflow: props.isDesktop ? "auto" : "overlay",
      marginTop: "1%",
      marginBottom: "1%"
    },
    margin: {
      margin: theme.spacing(1)
    },
    inputClass: {
      marginLeft: "auto",
      marginRight: "auto",
      position: "relative",
      height: "19px",
      "text-align": "center",
      fontSize: "small"
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    mealForDayGrid: {
      display: "flex",
      flexWrap: "wrap"
    },
    textbox: {
      marginLeft: "2%",
      marginRight: "1%",
      width: "100%"
    },
    cardTitleStyle: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "1rem",
      color: "#8A8A97",
      padding: "1rem !important"
    },
    borderNone: {
      border: "none"
    },
    exercisesBorder: {
      border: "none",
      paddingLeft: 0
    },
    trRoot: {
      overflow: "auto",
      "& .MuiTable-root": {
        borderSpacing: " 0 1.0625rem !important",
        borderCollapse: "separate  !important"
      },
      "& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover": {
        background: "#F8F8FA"
      },
      "& .MuiTableRow-root": {
        "&:hover": {
          background: "#F8F8FA !important"
        }
      },
      marginTop: "15px"
    }
  }));
  const localClasses = useStyles();
  return (
    <>
      <Grid item className={localClasses.mainGrid}>
        <div className={localClasses.trRoot}>
          <Table className={styles.exerciseGrid}>
            <TableHead>
              <TableRow>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItemBig}>
                    Exercise
                  </Typography>
                </TableCell>
                {props.viewOnly ? (
                  <TableCell className={localClasses.borderNone}>
                    <Typography className={localClasses.individualItem}>
                      Muscle Group
                    </Typography>
                  </TableCell>
                ) : null}
                {/* <TableCell className={localClasses.borderNone}></TableCell> */}
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItem}>
                    Sets
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItem}>
                    1
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItem}>
                    2
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItem}>
                    3
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItem}>
                    4
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItem}>
                    5
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItem}>
                    6
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItem}>
                    7
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItem}>
                    8
                  </Typography>
                </TableCell>
                <TableCell className={localClasses.borderNone}>
                  <Typography className={localClasses.individualItem}>
                    9
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(props.exerciseList).map(exerciseNo => (
                <TableRow>
                  <TableCell className={localClasses.borderNone}>
                    {props.viewOnly === true ? (
                      <Typography className={localClasses.individualItemBig}>
                        {props.exerciseList[exerciseNo].exerciseId
                          ? props.exerciseData.map(r => {
                              if (
                                props.exerciseList[exerciseNo] &&
                                props.exerciseList[exerciseNo].exerciseId &&
                                r.id ===
                                  props.exerciseList[exerciseNo].exerciseId
                              ) {
                                return r.name;
                              } else {
                                return "";
                              }
                            })
                          : "Off Day"}
                      </Typography>
                    ) : (
                      <AutoCompleteDropDown
                        id={"exercise_name" + exerciseNo}
                        name={"supplement_name" + exerciseNo}
                        options={props.exerciseData}
                        getOptionLabel={option => option.name}
                        style={{
                          width: "100%",
                          marginTop: "auto",
                          marginBottom: "auto"
                        }}
                        onChange={(event, value) => {
                          props.handleExerciseChange(
                            true,
                            false,
                            false,
                            "exercise_name",
                            event,
                            value,
                            exerciseNo
                          );
                        }}
                        disabled={
                          !props.isActive
                            ? true
                            : props.muscleGroupData
                            ? props.muscleGroupData.name === "Off"
                            : false
                        }
                        value={
                          props.exerciseData[
                            props.exerciseData.findIndex(function (item, i) {
                              if (
                                props.exerciseList[exerciseNo] &&
                                props.exerciseList[exerciseNo].exerciseId
                              ) {
                                return (
                                  item.id ===
                                  props.exerciseList[exerciseNo].exerciseId
                                );
                              } else {
                                return null;
                              }
                            })
                          ] || null
                        }
                        renderInput={params => (
                          <Input {...params} variant="outlined" />
                        )}
                      />
                    )}
                  </TableCell>
                  {props.viewOnly ? (
                    <TableCell className={localClasses.borderNone}>
                      <Typography className={localClasses.individualItem}>
                        <Typography>
                          {props.muscleGroups
                            .map(r => {
                              if (
                                props.muscleGroupId &&
                                r.id === props.muscleGroupId
                              ) {
                                return r.name;
                              }
                            })
                            .join("")}
                        </Typography>
                      </Typography>
                    </TableCell>
                  ) : null}
                  {/* <TableCell className={localClasses.borderNone}></TableCell> */}
                  <TableCell className={localClasses.borderNone}>
                    <Typography className={localClasses.individualItem}>
                      Reps
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.borderNone}>
                    <Typography className={localClasses.individualItem}>
                      <Input
                        variant="outlined"
                        className={localClasses.textbox}
                        onChange={event => {
                          props.handleExerciseChange(
                            false,
                            false,
                            true,
                            "1",
                            event,
                            event.target.value,
                            exerciseNo
                          );
                        }}
                        InputProps={{
                          readOnly: props.viewOnly === true ? true : false
                        }}
                        value={
                          props.exerciseList[exerciseNo]
                            ? props.exerciseList[exerciseNo].setReps[1]
                            : ""
                        }
                        disabled={
                          props.isActive === false
                            ? true
                            : props.muscleGroupData
                            ? props.muscleGroupData.name === "Off"
                            : false
                        }
                      />
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.borderNone}>
                    <Typography className={localClasses.individualItem}>
                      <Input
                        variant="outlined"
                        className={localClasses.textbox}
                        onChange={event => {
                          props.handleExerciseChange(
                            false,
                            false,
                            true,
                            "2",
                            event,
                            event.target.value,
                            exerciseNo
                          );
                        }}
                        value={
                          props.exerciseList[exerciseNo]
                            ? props.exerciseList[exerciseNo].setReps[2]
                            : ""
                        }
                        InputProps={{
                          readOnly: props.viewOnly ? true : false
                        }}
                        disabled={
                          !props.isActive
                            ? true
                            : props.muscleGroupData
                            ? props.muscleGroupData.name === "Off"
                            : false
                        }
                      />
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.borderNone}>
                    <Typography className={localClasses.individualItem}>
                      <Input
                        variant="outlined"
                        className={localClasses.textbox}
                        onChange={event => {
                          props.handleExerciseChange(
                            false,
                            false,
                            true,
                            "3",
                            event,
                            event.target.value,
                            exerciseNo
                          );
                        }}
                        value={
                          props.exerciseList[exerciseNo]
                            ? props.exerciseList[exerciseNo].setReps[3]
                            : ""
                        }
                        InputProps={{
                          readOnly: props.viewOnly === true ? true : false
                        }}
                        disabled={
                          !props.isActive
                            ? true
                            : props.muscleGroupData
                            ? props.muscleGroupData.name === "Off"
                            : false
                        }
                      />
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.borderNone}>
                    <Typography className={localClasses.individualItem}>
                      <Input
                        variant="outlined"
                        className={localClasses.textbox}
                        onChange={event => {
                          props.handleExerciseChange(
                            false,
                            false,
                            true,
                            "4",
                            event,
                            event.target.value,
                            exerciseNo
                          );
                        }}
                        value={
                          props.exerciseList[exerciseNo]
                            ? props.exerciseList[exerciseNo].setReps[4]
                            : ""
                        }
                        InputProps={{
                          readOnly: props.viewOnly === true ? true : false
                        }}
                        disabled={
                          !props.isActive
                            ? true
                            : props.muscleGroupData
                            ? props.muscleGroupData.name === "Off"
                            : false
                        }
                      />
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.borderNone}>
                    <Typography className={localClasses.individualItem}>
                      <Input
                        variant="outlined"
                        className={localClasses.textbox}
                        onChange={event => {
                          props.handleExerciseChange(
                            false,
                            false,
                            true,
                            "5",
                            event,
                            event.target.value,
                            exerciseNo
                          );
                        }}
                        value={
                          props.exerciseList[exerciseNo]
                            ? props.exerciseList[exerciseNo].setReps[5]
                            : ""
                        }
                        InputProps={{
                          readOnly: props.viewOnly === true ? true : false
                        }}
                        disabled={
                          !props.isActive
                            ? true
                            : props.muscleGroupData
                            ? props.muscleGroupData.name === "Off"
                            : false
                        }
                      />
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.borderNone}>
                    <Typography className={localClasses.individualItem}>
                      <Input
                        variant="outlined"
                        className={localClasses.textbox}
                        onChange={event => {
                          props.handleExerciseChange(
                            false,
                            false,
                            true,
                            "6",
                            event,
                            event.target.value,
                            exerciseNo
                          );
                        }}
                        value={
                          props.exerciseList[exerciseNo]
                            ? props.exerciseList[exerciseNo].setReps[6]
                            : ""
                        }
                        InputProps={{
                          readOnly: props.viewOnly === true ? true : false
                        }}
                        disabled={
                          !props.isActive
                            ? true
                            : props.muscleGroupData
                            ? props.muscleGroupData.name === "Off"
                            : false
                        }
                      />
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.borderNone}>
                    <Typography className={localClasses.individualItem}>
                      <Input
                        variant="outlined"
                        className={localClasses.textbox}
                        onChange={event => {
                          props.handleExerciseChange(
                            false,
                            false,
                            true,
                            "7",
                            event,
                            event.target.value,
                            exerciseNo
                          );
                        }}
                        value={
                          props.exerciseList[exerciseNo]
                            ? props.exerciseList[exerciseNo].setReps[7]
                            : ""
                        }
                        InputProps={{
                          readOnly: props.viewOnly === true ? true : false
                        }}
                        disabled={
                          !props.isActive
                            ? true
                            : props.muscleGroupData
                            ? props.muscleGroupData.name === "Off"
                            : false
                        }
                      />
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.borderNone}>
                    <Typography className={localClasses.individualItem}>
                      <Input
                        variant="outlined"
                        className={localClasses.textbox}
                        onChange={event => {
                          props.handleExerciseChange(
                            false,
                            false,
                            true,
                            "8",
                            event,
                            event.target.value,
                            exerciseNo
                          );
                        }}
                        value={
                          props.exerciseList[exerciseNo]
                            ? props.exerciseList[exerciseNo].setReps[8]
                            : ""
                        }
                        InputProps={{
                          readOnly: props.viewOnly ? true : false
                        }}
                        disabled={
                          !props.isActive
                            ? true
                            : props.muscleGroupData
                            ? props.muscleGroupData.name === "Off"
                            : false
                        }
                      />
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.borderNone}>
                    <Typography className={localClasses.individualItem}>
                      <Input
                        variant="outlined"
                        className={localClasses.textbox}
                        onChange={event => {
                          props.handleExerciseChange(
                            false,
                            false,
                            true,
                            "9",
                            event,
                            event.target.value,
                            exerciseNo
                          );
                        }}
                        value={
                          props.exerciseList[exerciseNo]
                            ? props.exerciseList[exerciseNo].setReps[9]
                            : ""
                        }
                        InputProps={{
                          readOnly: props.viewOnly ? true : false
                        }}
                        disabled={
                          !props.isActive
                            ? true
                            : props.muscleGroupData
                            ? props.muscleGroupData.name === "Off"
                            : false
                        }
                      />
                    </Typography>
                  </TableCell>
                  {props.viewOnly === true ? null : (
                    <TableCell className={localClasses.borderNone}>
                      <IconButton
                        color="secondary"
                        aria-label="Delete Exercise"
                        onClick={event => {
                          props.handelDeleteExercise(exerciseNo);
                        }}
                        disabled={
                          !props.isActive || !props.checkIfDataProper(true)
                        }
                      >
                        <DeleteForeverIcon
                          id={"delete" + exerciseNo}
                          style={
                            !props.isActive || !props.checkIfDataProper(true)
                              ? { color: "gray" }
                              : { color: "red" }
                          }
                        />
                      </IconButton>
                    </TableCell>
                  )}
                  {props.viewOnly ? null : (
                    <TableCell className={localClasses.borderNone}>
                      <IconButton
                        color="secondary"
                        aria-label="Add Exercise"
                        onClick={event => {
                          props.handleAddExercise();
                        }}
                        disabled={
                          props.isActive === false || !props.checkIfDataProper()
                        }
                      >
                        <AddCircleOutlineIcon
                          id={"PersonaladdNotes_" + exerciseNo}
                          style={
                            !props.isActive || !props.checkIfDataProper()
                              ? { color: "gray" }
                              : { color: "green" }
                          }
                        />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Grid>
    </>
  );
};

export default ExerciseGrid;
