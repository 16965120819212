// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClientSupplement_downloadIcon__15ZbN {\n  float: right;\n}\n.ClientSupplement_centerImage__3s461 {\n  display: \"block\" !important;\n  margin-left: \"auto\" !important;\n  margin-right: \"auto\" !important;\n  width: \"30%\" !important;\n}\n@media only screen and (min-width: 1280px) {\n  .ClientSupplement_backIcon__3hgch {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/containers/ClientSupplement/ClientSupplement.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,2BAA2B;EAC3B,8BAA8B;EAC9B,+BAA+B;EAC/B,uBAAuB;AACzB;AACA;EACE;IACE,6BAA6B;IAC7B,6BAA6B;EAC/B;AACF","sourcesContent":[".downloadIcon {\n  float: right;\n}\n.centerImage {\n  display: \"block\" !important;\n  margin-left: \"auto\" !important;\n  margin-right: \"auto\" !important;\n  width: \"30%\" !important;\n}\n@media only screen and (min-width: 1280px) {\n  .backIcon {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadIcon": "ClientSupplement_downloadIcon__15ZbN",
	"centerImage": "ClientSupplement_centerImage__3s461",
	"backIcon": "ClientSupplement_backIcon__3hgch"
};
export default ___CSS_LOADER_EXPORT___;
