import React, { useState, useContext } from "react";
import clsx from "clsx";
import {
  Auth,
  BorderLessButton,
  CustomTypography,
  DarkBlueButton,
  SnackBar,
  Input
} from "../../components";
import {
  Grid,
  Link,
  Typography,
  FormHelperText,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
  CircularProgress,
  AppBar,
  Toolbar,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
  Backdrop,
  useMediaQuery
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  checkAllKeysPresent,
  getListOfKeysNotPresent,
  setErrors,
  checkEmpty
} from "../../utils";
import { useHistory } from "react-router-dom";
import {
  CLIENTDASHBAORD,
  FORGOTPASSWORD,
  FRANCHISE,
  LOGOUT,
  MANAGECLIENTS,
  TWOFACTORAUTH,
  USERS
} from "../../paths/Paths";
import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { get } from "lodash";
import form from "./loginform.json";
import { providerForPost, providerForPublicPost } from "../../api";
import { apiBackendUrl, loginURL, policyacceptstatus } from "../../constants";
import Logo from "../../components/Logo/Logo";
import { AuthContext } from "../../context";

import Styles from "./Login.module.css";
import DialogBox from "../../components/DialogBox/DialogBox";

function Copyright() {
  return (
    <Typography
      align="center"
      style={{
        color: "#8A8A97",
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "0.875rem"
      }}
    >
      Copyright © {new Date().getFullYear()} Performance Fitness Concepts, All
      Rights Reserved.
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  boldText: {
    fontWeight: "800"
  },
  container: {
    margin: "117px auto 50px auto"
  },
  loginTextDesktop: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1.25rem",
    color: "#110F48"
  },
  loginTextMobile: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#110F48",
    fontSize: "1.25rem"
  },
  form: {
    width: "100%"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#0152CC",
    fontFamily: "Montserrat",
    fontWeight: 400,
    color: "#fff",
    fontSize: "0.875rem"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    position: "relative"
  },
  welcomeText: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000000",
    fontSize: "0.9375rem"
  },
  inputText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1rem"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: "#fff"
  },
  label: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.875rem"
  },
  textField: {
    marginTop: "8px",
    "& input": {
      padding: "1.15625rem 0rem 1.15625rem 0.875rem "
    }
  }
}));

const LogIn = () => {
  const classes = useStyles();
  const history = useHistory();
  const identifier = "identifier";
  const password = "password";
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const themes = useTheme();
  const { setUserInfo } = useContext(AuthContext);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [isAcceptPolicy, setIsAcceptPolicy] = useState(false);
  const [userId, setUserId] = useState(null);
  const [franchiseData, setFranchiseData] = useState(null);
  const [token, setToken] = useState(null);
  const isDesktop = useMediaQuery(themes.breakpoints.up("lg"), {
    defaultMatches: true
  });

  /** Use state for form */
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    isPolicyAccepted: false,
    errors: {},
    isSuccess: false,
    userIndisposed: false,
    userDeceased: false,
    loginPageflag: false
  });

  /**All input changes handled from here */
  const handleChange = ({ target }) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [target.name]: target.value
      },
      touched: {
        ...formState.touched,
        [target.name]: true
      }
    }));
    delete formState.errors[target.name];
  };

  const handleClickShowPassword = () => {
    setFormState({
      ...formState,
      showPassword: !formState.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const hasError = field => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  };

  const submit = async () => {
    await providerForPublicPost(
      loginURL,
      {
        identifier: formState.values[identifier],
        password: formState.values[password]
      },
      { desc: "User Login Request" }
    )
      .then(res => {
        if (res.data.user.isPolicyAccepted) {
          setLoading(false);
          const is2FAEnabled = res.data.user.is2FAEnabled;
          if (!is2FAEnabled) {
            setUserInfo(userInfo => ({
              ...userInfo,
              user: {
                ...userInfo.user,
                name: res.data.user.full_name,
                logo: res.data.logo ? apiBackendUrl + res.data.logo : null
              }
            }));

            Auth.setToken(res.data.jwt, true);
            Auth.setUserInfo(res.data.user, true);
            Auth.setLogo(res.data.logo, true);
            if (
              res.data.user.role.name ===
              process.env.REACT_APP_NUTRITIONIST_ROLE
            ) {
              history.push({
                pathname: MANAGECLIENTS
              });
            } else if (
              res.data.user.role.name ===
              process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
            ) {
              history.push({
                pathname: FRANCHISE
              });
            } else if (
              res.data.user.role.name === process.env.REACT_APP_ADMIN_ROLE_NAME
            ) {
              history.push({
                pathname: USERS
              });
            } else if (
              res.data.user.role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
            ) {
              history.push({
                pathname: CLIENTDASHBAORD
              });
            } else {
              /** redirect to not found page */
              history.push({
                pathname: LOGOUT
              });
            }
          } else {
            history.push({
              pathname: TWOFACTORAUTH,
              isLogin: true,
              identifier: formState.values.identifier,
              token: res.data.jwt,
              userData: res.data.user,
              logo: res.data.logo
            });
          }
        } else {
          setUserId(res.data.user.id);
          setFranchiseData({
            email: res.data.user.franchise.email,
            mail:
              res.data.user.franchise.address_line_1 +
              res.data.user.franchise.address_line_2
          });
          setToken(res.data.jwt);
          setLoading(false);
          setIsAcceptPolicy(true);
        }
      })
      .catch(err => {
        setLoading(false);
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message[0] &&
          err.response.data.message[0].messages[0] &&
          err.response.data.message[0].messages[0].message
        ) {
          setFormState(formState => ({
            ...formState,
            alert: true,
            severity: "error",
            errorMessage: err.response.data.message[0].messages[0].message
          }));
        } else {
          console.log("err ", err);
          setFormState(formState => ({
            ...formState,
            alert: true,
            severity: "error",
            errorMessage: ""
          }));
        }
      });
  };

  const onSend = event => {
    event.preventDefault();
    setLoading(true);
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(formState.values, form);
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, form);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(formState.values, form);
      formState.errors = setErrors(formState.values, form);
    }
    if (isValid) {
      submit();
      /** Call axios from here */
      setFormState(formState => ({
        ...formState,
        isValid: true
      }));
    } else {
      setLoading(false);
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
    }
  };

  const onForgotPassword = () => {
    history.push({
      pathname: FORGOTPASSWORD
    });
  };

  const closeDialogBox = () => {
    setOpenDialogBox(false);
    setFormState(formState => ({
      ...formState,
      isPolicyAccepted: false
    }));
  };

  const handlePolicySave = () => {
    closeDialogBox();
  };

  const handleCheckBoxChange = e => {
    e.persist();
    setFormState(formState => ({
      ...formState,
      [e.target.name]: e.target.checked
    }));
  };

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "success"
    }));
  };

  const setPolicyAcceptedStatus = async () => {
    setLoading1(true);
    await providerForPost(
      policyacceptstatus,
      {
        id: userId
      },
      token,
      {
        desc: "Policy accepted by user with id " + userId
      }
    )
      .then(res => {
        setLoading1(false);
        const is2FAEnabled = res.data.user.is2FAEnabled;
        if (!is2FAEnabled) {
          setUserInfo(userInfo => ({
            ...userInfo,
            user: {
              ...userInfo.user,
              name: res.data.user.full_name,
              logo: res.data.logo ? apiBackendUrl + res.data.logo : null
            }
          }));

          Auth.setToken(res.data.jwt, true);
          Auth.setUserInfo(res.data.user, true);
          Auth.setLogo(res.data.logo, true);
          if (
            res.data.user.role.name === process.env.REACT_APP_NUTRITIONIST_ROLE
          ) {
            history.push({
              pathname: MANAGECLIENTS
            });
          } else if (
            res.data.user.role.name ===
            process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
          ) {
            history.push({
              pathname: FRANCHISE
            });
          } else if (
            res.data.user.role.name === process.env.REACT_APP_ADMIN_ROLE_NAME
          ) {
            history.push({
              pathname: USERS
            });
          } else if (
            res.data.user.role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
          ) {
            history.push({
              pathname: CLIENTDASHBAORD
            });
          } else {
            /** redirect to not found page */
            history.push({
              pathname: LOGOUT
            });
          }
        } else {
          history.push({
            pathname: TWOFACTORAUTH,
            isLogin: true,
            identifier: formState.values.identifier,
            token: res.data.jwt,
            userData: res.data.user,
            logo: res.data.logo
          });
        }
      })
      .catch(error => {
        setIsAcceptPolicy(false);
        setUserId(null);
        setToken(null);
        setFranchiseData(null);
        setFormState(formState => ({
          ...formState,
          isPolicyAccepted: false
        }));
      });
  };

  return (
    <>
      <AppBar position="fixed" className={Styles.loginAppBar} elevation={0}>
        <Toolbar className={Styles.loginToolbar}>
          <Logo className={Styles.loginLogo} />
        </Toolbar>
      </AppBar>
      <Grid>
        <SnackBar
          open={formState.alert}
          severity={formState.severity}
          onClose={handleSnackbarClose}
        >
          {formState.errorMessage}
        </SnackBar>
      </Grid>
      {isAcceptPolicy ? (
        <Container component="main" maxWidth="md" className={classes.container}>
          <Grid item md={12} xs={12}>
            <Typography style={{ fontSize: "medium", color: "#110F48" }}>
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="h5"
                  gutterBottom
                  style={{
                    fontWeight: 800
                  }}
                >
                  <u>User Agreement</u>
                </CustomTypography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="body1"
                  gutterBottom
                  style={{
                    fontWeight: 550
                  }}
                >
                  You agree that by clicking “Register”, or similar,
                  registering, accessing or using our services, you are agreeing
                  to enter into a legally binding contract with Performance
                  Fitness Concepts (even if you are using our Services on behalf
                  of a company). If you do not agree to this “User Agreement”,
                  do not click “Register” (or similar) and do not access or
                  otherwise use any of our Services. If you wish to terminate
                  this contract, at any time you can do so by closing your
                  account and no longer accessing or using our Services.
                </CustomTypography>
              </Grid>
              <br />
              {/* <br /> */}
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="h5"
                  gutterBottom
                  style={{
                    fontWeight: 800
                  }}
                >
                  <u>Your Rights and Choices </u>
                </CustomTypography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="body1"
                  gutterBottom
                  style={{
                    fontWeight: 550
                  }}
                >
                  The CCPA provides consumers (California residents) with
                  specific rights regarding their personal information. This
                  section describes your CCPA rights and explains how to
                  exercise those rights.
                </CustomTypography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="body1"
                  gutterBottom
                  style={{
                    fontWeight: 550
                  }}
                >
                  Access to Specific Information and Data Portability Rights​
                  You have the right to request that Performance Fitness
                  Concepts disclose certain information to you about our
                  collection and use of your personal information over the past
                  12 months. Once we receive and confirm your verifiable
                  consumer request we will disclose to you:
                </CustomTypography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <ul>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      The categories of personal information we collected about
                      you.
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      The categories of sources for the personal information we
                      collected about you.
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      Our business or commercial purpose for collecting or
                      selling that personal information.
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      The categories of third parties with whom we share that
                      personal information.
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      The specific pieces of personal information we collected
                      about you (also called a data portability request).
                    </CustomTypography>
                    <ul>
                      <li>
                        <CustomTypography
                          variant="body1"
                          gutterBottom
                          style={{
                            fontWeight: 550
                          }}
                        >
                          sales, identifying the personal information categories
                          that each category of recipient purchased; and
                        </CustomTypography>
                      </li>
                      <li>
                        <CustomTypography
                          variant="body1"
                          gutterBottom
                          style={{
                            fontWeight: 550
                          }}
                        >
                          disclosures for a business purpose, identifying the
                          personal information categories that each category of
                          recipient obtained.
                        </CustomTypography>
                      </li>
                    </ul>
                  </li>
                </ul>
              </Grid>
              <br />
              {/* <br /> */}
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="h5"
                  gutterBottom
                  style={{
                    fontWeight: 800
                  }}
                >
                  Deletion Request Rights
                </CustomTypography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="body1"
                  gutterBottom
                  style={{
                    fontWeight: 550
                  }}
                >
                  You have the right to request that Performance Fitness
                  Concepts delete any of your personal information that we
                  collected from you and retained, subject to certain
                  exceptions. Once we receive and confirm your verifiable
                  consumer request, we will delete your personal information
                  from our records, unless an exception applies.
                </CustomTypography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="body1"
                  gutterBottom
                  style={{
                    fontWeight: 550
                  }}
                >
                  We may deny your deletion request if retaining the information
                  is necessary for us or our service provider(s) to:
                </CustomTypography>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <ol>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      Complete the transaction for which we collected the
                      personal information, provide a good or service that you
                      requested, take actions reasonably anticipated within the
                      context of our ongoing business relationship with you, or
                      otherwise perform our contract with you.
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      Detect security incidents, protect against malicious,
                      deceptive, fraudulent, or illegal activity, or prosecute
                      those responsible for such activities.
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      Debug products to identify and repair errors that impair
                      existing intended functionality.
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      Exercise free speech, ensure the right of another consumer
                      to exercise their free speech rights, or exercise another
                      right provided for by law.
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      Comply with the California Electronic Communications
                      Privacy Act (Cal. Penal Code § 1546 et. seq.).
                    </CustomTypography>
                    <li>
                      <CustomTypography
                        variant="body1"
                        gutterBottom
                        style={{
                          fontWeight: 550
                        }}
                      >
                        Engage in public or peer-reviewed scientific,
                        historical, or statistical research in the public
                        interest that adheres to all other applicable ethics and
                        privacy laws, when the information’s deletion may likely
                        render impossible or seriously impair the research’s
                        achievement, if you previously provided informed
                        consent.
                      </CustomTypography>
                    </li>
                    <li>
                      <CustomTypography
                        variant="body1"
                        gutterBottom
                        style={{
                          fontWeight: 550
                        }}
                      >
                        Enable solely internal uses that are reasonably aligned
                        with consumer expectations based on your relationship
                        with us.
                      </CustomTypography>
                    </li>
                    <li>
                      <CustomTypography
                        variant="body1"
                        gutterBottom
                        style={{
                          fontWeight: 550
                        }}
                      >
                        Comply with a legal obligation.
                      </CustomTypography>
                    </li>
                    <li>
                      <CustomTypography
                        variant="body1"
                        gutterBottom
                        style={{
                          fontWeight: 550
                        }}
                      >
                        Make other internal and lawful uses of that information
                        that are compatible with the context in which you
                        provided it.
                      </CustomTypography>
                    </li>
                  </li>
                </ol>
              </Grid>
              <br />
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="h5"
                  gutterBottom
                  style={{
                    fontWeight: 800
                  }}
                >
                  Information We Collect
                </CustomTypography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="body1"
                  gutterBottom
                  style={{
                    fontWeight: 550
                  }}
                >
                  Our Website collects information that identifies, relates to,
                  describes, references, is capable of being associated with, or
                  could reasonably be linked, directly or indirectly, with a
                  particular consumer or device (“personal information”). In
                  particular, Performance Fitness Concepts has collected the
                  following categories of personal information from its
                  consumers within the last twelve (12) months that include, but
                  is not limited to:
                </CustomTypography>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <ul>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      Contact information (e.g., name, phone number, address,
                      and email address);​ Social Security number and other
                      government identification numbers (e.g. EIN, driver’s
                      license number);​ Date of birth
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      Financial information (e.g., income, revenue, assets,
                      credits, deductions, expenses, and bank account
                      information)
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      Payment data (e.g., checking, debit and credit card
                      account numbers, and payment history)
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      Geo-location information
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      Website, mobile application, and email usage data (e.g.,
                      interactions with a website, application or advertisement)
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      Device information (e.g., internet protocol (IP) address,
                      device type, unique identifier, app version, operating
                      system, network data, and phone state)
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      Login information
                    </CustomTypography>
                    <li>
                      <CustomTypography
                        variant="body1"
                        gutterBottom
                        style={{
                          fontWeight: 550
                        }}
                      >
                        Demographic information
                      </CustomTypography>
                    </li>
                    <li>
                      <CustomTypography
                        variant="body1"
                        gutterBottom
                        style={{
                          fontWeight: 550
                        }}
                      >
                        Professional or employment-related information
                      </CustomTypography>
                    </li>
                  </li>
                </ul>
              </Grid>
              <br />
              {/* <br /> */}
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="h5"
                  gutterBottom
                  style={{
                    fontWeight: 800
                  }}
                >
                  Use of Personal Information{" "}
                </CustomTypography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="body1"
                  gutterBottom
                  style={{
                    fontWeight: 550
                  }}
                >
                  We may use, or disclose the personal information we collect
                  for one or more of the following business purposes:
                  <p></p>
                  To fulfill or meet the reason you provided the information.
                  For example, if you share your name and contact information to
                  ask a question about our products or services, we will use
                  that personal information to respond to your inquiry. If you
                  provide your personal information to purchase a product or
                  service, we will use that information to process your payment
                  and facilitate delivery.
                </CustomTypography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="body1"
                  gutterBottom
                  style={{
                    fontWeight: 550
                  }}
                >
                  To provide, support, personalize, and develop our Website,
                  products, and services.
                </CustomTypography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <ul>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      To create, maintain, customize, and secure your account
                      with us.
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      To process your requests, purchases, transactions, and
                      payments and prevent transactional fraud.
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      To provide you with support and to respond to your
                      inquiries, including to investigate and address your
                      concerns and monitor and improve our responses.
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      To personalize your Website experience and to deliver
                      content and product and service offerings relevant to your
                      interests, including targeted offers and ads through our
                      Website, third-party sites, and via email or text message
                      (with your consent, where required by law).
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      To help maintain the safety, security, and integrity of
                      our Website, products and services, databases and other
                      technology assets, and business.
                    </CustomTypography>
                    <li>
                      <CustomTypography
                        variant="body1"
                        gutterBottom
                        style={{
                          fontWeight: 550
                        }}
                      >
                        To respond to law enforcement requests and as required
                        by applicable law, court order, or governmental
                        regulations.
                      </CustomTypography>
                    </li>
                    <li>
                      <CustomTypography
                        variant="body1"
                        gutterBottom
                        style={{
                          fontWeight: 550
                        }}
                      >
                        As described to you when collecting your personal
                        information or as otherwise set forth in the CCPA.{" "}
                      </CustomTypography>
                    </li>
                    <li>
                      <CustomTypography
                        variant="body1"
                        gutterBottom
                        style={{
                          fontWeight: 550
                        }}
                      >
                        To evaluate or conduct a merger, divestiture,
                        restructuring, reorganization, dissolution, or other
                        sale or transfer of some or all of Electronic Forms
                        LLC’s assets, whether as a going concern or as part of
                        bankruptcy, liquidation, or similar proceeding, in which
                        personal information held by Performance Fitness
                        Concepts about our Website users is among the assets
                        transferred. Performance Fitness Concepts will not
                        collect additional categories of personal information or
                        use the personal information we collected for materially
                        different, unrelated, or incompatible purposes without
                        providing you notice.
                      </CustomTypography>
                    </li>
                  </li>
                </ul>
              </Grid>
              <br />
              {/* <br /> */}
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="h5"
                  gutterBottom
                  style={{
                    fontWeight: 800
                  }}
                >
                  Sharing Personal Information{" "}
                </CustomTypography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="body1"
                  gutterBottom
                  style={{
                    fontWeight: 550
                  }}
                >
                  Performance Fitness Concepts may disclose your personal
                  information to a third party for a business purpose. When we
                  disclose personal information for a business purpose, we enter
                  a contract that describes the purpose and requires the
                  recipient to both keep that personal information confidential
                  and not use it for any purpose except performing the
                  contract.​ We share your personal information with the
                  following categories of third parties:​ Service providers.
                </CustomTypography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <ul>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      Disclosures of Personal Information for a Business Purpose
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      In the preceding twelve (12) months, Company has not
                      disclosed personal information for a business purpose.
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      Exercising Access, Data Portability, and Deletion Rights
                    </CustomTypography>
                  </li>
                  <li>
                    <CustomTypography
                      variant="body1"
                      gutterBottom
                      style={{
                        fontWeight: 550
                      }}
                    >
                      To exercise the access, data portability, and deletion
                      rights described above, please submit a verifiable
                      consumer request to us at:
                      <br />
                      <br />
                      E-Mail: &nbsp; &nbsp; {franchiseData.email}
                      <br />
                      Mail: &nbsp; &nbsp; &nbsp; &nbsp;{franchiseData.mail}
                    </CustomTypography>
                  </li>
                </ul>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="body1"
                  gutterBottom
                  style={{
                    fontWeight: 550
                  }}
                >
                  Only you, or a person registered with the California Secretary
                  of State that you authorize to act on your behalf, may make a
                  verifiable consumer request related to your personal
                  information. You may also make a verifiable consumer request
                  on behalf of your minor child.
                </CustomTypography>
              </Grid>
              <br />
              {/* <br /> */}
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="h5"
                  gutterBottom
                  style={{
                    fontWeight: 800
                  }}
                >
                  Changes to Our Privacy Notice
                </CustomTypography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CustomTypography
                  variant="body1"
                  gutterBottom
                  style={{
                    fontWeight: 550
                  }}
                >
                  Performance Fitness Concepts reserves the right to amend this
                  privacy notice at our discretion and at any time. When we make
                  changes to this privacy notice, we will post the updated
                  notice on the Website and update the notice’s effective date.
                  Your continued use of our Website following the posting of
                  changes constitutes your acceptance of such changes. parties:​
                  Service providers.
                  <br />
                  <br />
                  <b
                    style={{
                      fontSize: "17px"
                    }}
                  >
                    {" "}
                    WE DO NOT SELL ANY PERSONAL INFORMATION{" "}
                  </b>
                </CustomTypography>
              </Grid>
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.isPolicyAccepted}
                  onChange={handleCheckBoxChange}
                  name="isPolicyAccepted"
                  color="primary"
                  className={Styles.ReactCheckbox}
                />
              }
              label={
                <CustomTypography
                  variant="body1"
                  gutterBottom
                  style={{
                    margin: "auto",
                    fontWeight: 800,
                    color: "#110F48"
                  }}
                >
                  I have read the User Agreement and Agree to the same.
                </CustomTypography>
              }
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <DarkBlueButton
              variant="contained"
              size="small"
              style={{
                margin: "8px 0 8px 5px"
              }}
              disabled={!formState["isPolicyAccepted"]}
              disableRipple
              onClick={() => {
                setPolicyAcceptedStatus();
              }}
            >
              Accept
            </DarkBlueButton>

            <BorderLessButton
              variant="contained"
              size="small"
              style={{
                margin: "8px 0 8px 5px"
              }}
              onClick={() => {
                setIsAcceptPolicy(false);
                setUserId(null);
                setToken(null);
                setFranchiseData(null);
                setFormState(formState => ({
                  ...formState,
                  isPolicyAccepted: false
                }));
              }}
            >
              Cancel
            </BorderLessButton>
          </Grid>
        </Container>
      ) : (
        <Container component="main" maxWidth="sm" className={classes.container}>
          <Card variant="elevation" elevation={2}>
            <CardContent className={Styles.loginCardContent}>
              <Box>
                <Typography
                  className={
                    isDesktop
                      ? classes.loginTextDesktop
                      : classes.loginTextMobile
                  }
                  align="center"
                >
                  Log In
                </Typography>
              </Box>
              <Box>
                <Typography align="center" className={classes.welcomeText}>
                  Welcome to PFC! Enter your credentials to begin
                </Typography>
              </Box>
              <form className={classes.form} onSubmit={onSend} noValidate>
                <Input
                  id={get(form[identifier], "id")}
                  variant="outlined"
                  margin="normal"
                  error={hasError("identifier")}
                  fullWidth
                  autoFocus={get(form[identifier], "autoFocus")}
                  helperText={
                    hasError(identifier)
                      ? formState.errors[identifier].map(error => {
                          return error + " ";
                        })
                      : null
                  }
                  label={get(form[identifier], "label")}
                  name={identifier}
                  onChange={handleChange}
                  type={get(form[identifier], "type")}
                  value={formState.values[identifier] || ""}
                  className={classes.inputText}
                />

                <FormControl
                  fullWidth
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  style={{ marginTop: "10px" }}
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    fullWidth
                    error={hasError(password)}
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id={get(form[password], "id")}
                    name={password}
                    type={formState.showPassword ? "text" : "password"}
                    value={formState.values[password] || ""}
                    onChange={handleChange}
                    fullWidth
                    error={hasError(password)}
                    endAdornment={
                      <InputAdornment position="end" error={hasError(password)}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {formState.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                        focused: classes.label
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.inputText,
                        focused: classes.inputText,
                        notchedOutline: classes.inputText
                      }
                    }}
                  ></OutlinedInput>
                  <FormHelperText error={hasError(password)}>
                    {hasError(password)
                      ? formState.errors[password].map(error => {
                          return error + " ";
                        })
                      : null}
                  </FormHelperText>
                </FormControl>
                <div className={classes.wrapper}>
                  <DarkBlueButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={loading}
                    disableRipple
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    Log In
                  </DarkBlueButton>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>

                <DialogBox
                  open={openDialogBox}
                  title="Accept Policy "
                  buttonCancel="Cancel"
                  handleCancel={closeDialogBox}
                  skipOk={false}
                  buttonOk={"Accept"}
                  handleOk={handlePolicySave}
                >
                  <Typography
                    style={{
                      color: "#ACACB8",
                      fontSize: "14px",
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      marginBottom: "1.875rem"
                    }}
                  >
                    I accept all policies.
                  </Typography>

                  <Grid item md={4} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formState.isPolicyAccepted}
                          onChange={handleCheckBoxChange}
                          name="isPolicyAccepted"
                          color="primary"
                          className={Styles.ReactCheckbox}
                        />
                      }
                      label={"Accept Policy"}
                    />
                  </Grid>
                </DialogBox>
                <Grid container style={{ justifyContent: "flex-end" }}>
                  <Grid item>
                    <Box>
                      <Link
                        href="#"
                        onClick={onForgotPassword}
                        style={{
                          color: "#1c4979",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                          fontSize: "0.875rem",
                          alignSelf: "flex-end"
                        }}
                      >
                        Forgot password?
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>

          <Box mt={2}>
            <Copyright />
          </Box>
        </Container>
      )}
      <Backdrop className={classes.backdrop} open={loading1}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default LogIn;
