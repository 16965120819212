import React from "react";
import {
  Box,
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody
} from "@material-ui/core";
import { Input } from "../../../components";
import { convertNumber } from "../../../utils";
import { CircularProgress, IconButton } from "material-ui";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const AllMealsOfADayGrid = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    margin: {
      margin: theme.spacing(1)
    },
    borderNone: {
      border: "none",
      padding: 0
    },
    cardTitleStyle: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "1rem",
      color: "#8A8A97",
      padding: "1rem !important"
    },
    tableRowStyle: {
      background: "#f4f8ff"
    },
    tableCellStyle: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "0.9375rem",
      color: "#110F48",
      border: "none",
      padding: 5,
      textAlign: "center",
      backgroundColor: "#f4f8ff"
    },
    rowstyles: {},
    tableCellNoStyle: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "0.9375rem",
      color: "#110F48",
      border: "none",
      padding: 5,
      textAlign: "center"
    },
    inputRoot: {
      "& .MuiOutlinedInput-input": {
        textAlign: "center",
        width: "3.125rem"
      }
    },
    trRoot: {
      overflow: "auto",
      "& .MuiTable-root": {
        borderSpacing: " 0 1.0625rem !important",
        borderCollapse: "separate  !important"
      },
      "& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover": {
        background: "#F8F8FA"
      },
      "& .MuiTableRow-root": {
        "&:hover": {
          background: "#F8F8FA !important"
        }
      }
    },
    cardFoodTitleStyle: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "0.875rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.4px",
      textTransform: "uppercase",
      color: "#110F48"
    },
    exchangeTable: {
      "& tbody tr td:first-child": {
        borderTopLeftRadius: "10px !important",
        borderBottomLeftRadius: "10px !important",
        padding: "0"
      },
      "& tbody tr td:last-child": {
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px"
      }
    },
    leftMealTable: {
      paddingRight: "1.875rem !important",
      paddingTop: "15px !important",
      [theme.breakpoints.up("md")]: {
        paddingRight: "12px !important"
      },
      [theme.breakpoints.up("sm")]: {
        paddingRight: "12px !important"
      },
      [theme.breakpoints.up("xs")]: {
        paddingRight: "12px !important"
      }
    },
    tableCurveCellStyle: {
      borderTopLeftRadius: "10px !important",
      borderBottomLeftRadius: "10px !important",
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "0.9375rem",
      color: "#110F48",
      border: "none",
      padding: 5,
      textAlign: "center",
      backgroundColor: "#f4f8ff"
    },
    tableTitleCurveCellStyle: {
      borderTopLeftRadius: "10px !important",
      borderBottomLeftRadius: "10px !important",
      backgroundColor: "#f4f8ff",
      border: "none",
      padding: 10
    }
  }));

  const localClasses = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={12} className={localClasses.leftMealTable}>
        {props.loader1 ? (
          <CircularProgress />
        ) : (
          <div className={localClasses.trRoot}>
            <Table className={localClasses.exchangeTable}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={localClasses.borderNone}
                    style={{
                      backgroundColor: "#EDEAF0",
                      borderRadius: 5
                    }}
                    align="center"
                  >
                    <Typography
                      style={{ color: "#110F48" }}
                      className={localClasses.cardFoodTitleStyle}
                    >
                      FOODS
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.borderNone} align="center">
                    <Typography className={localClasses.cardTitleStyle}>
                      BREAD
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.borderNone} align="center">
                    <Typography className={localClasses.cardTitleStyle}>
                      MEAT
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.borderNone} align="center">
                    <Typography className={localClasses.cardTitleStyle}>
                      VEG
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.borderNone} align="center">
                    <Typography className={localClasses.cardTitleStyle}>
                      FRUIT
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.borderNone} align="center">
                    <Typography className={localClasses.cardTitleStyle}>
                      MILK
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.borderNone} align="center">
                    <Typography className={localClasses.cardTitleStyle}>
                      FAT
                    </Typography>
                  </TableCell>

                  <TableCell className={localClasses.borderNone} align="center">
                    <Typography className={localClasses.cardTitleStyle}>
                      TOTAL
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={localClasses.borderNone}
                    align="center"
                  ></TableCell>
                  <TableCell
                    className={localClasses.borderNone}
                    align="center"
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.defaultMeals.map((mealNo, index) => (
                  <TableRow className={localClasses.tableRowStyle} hover={true}>
                    <TableCell
                      className={localClasses.borderNone}
                      style={{ backgroundColor: "#FFF" }}
                    >
                      <Box className={localClasses.cardTitleStyle}>
                        {mealNo}
                      </Box>
                    </TableCell>
                    <TableCell
                      className={localClasses.tableCurveCellStyle}
                      align="center"
                    >
                      <div className={localClasses.inputRoot}>
                        <Input
                          id={
                            "bread_day_" +
                            props.mealForm.exchangeDay +
                            "_mealNo_" +
                            mealNo
                          }
                          value={
                            props.mealForm.mealValues["meal_" + mealNo][
                              "bread"
                            ] || ""
                          }
                          onChange={e => {
                            props.handleMealChange(e, "bread", mealNo);
                          }}
                          placeholder={"0"}
                          type="number"
                          variant="outlined"
                          disableUnderline={true}
                          size="small"
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      className={localClasses.tableCellStyle}
                      align="center"
                    >
                      {" "}
                      <div className={localClasses.inputRoot}>
                        <Input
                          id={
                            "meat_day_" +
                            props.mealForm.exchangeDay +
                            "_mealNo_" +
                            mealNo
                          }
                          value={
                            props.mealForm.mealValues["meal_" + mealNo][
                              "meat"
                            ] || ""
                          }
                          onChange={e => {
                            props.handleMealChange(e, "meat", mealNo);
                          }}
                          placeholder={"0"}
                          type="number"
                          variant="outlined"
                          className={localClasses.inputClass}
                          disableUnderline={true}
                          size="small"
                          inputProps={{ style: { textAlign: "center" } }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className={localClasses.tableCellStyle}>
                      {" "}
                      <div className={localClasses.inputRoot}>
                        <Input
                          id={
                            "veg_day_" +
                            props.mealForm.exchangeDay +
                            "_mealNo_" +
                            mealNo
                          }
                          value={
                            props.mealForm.mealValues["meal_" + mealNo][
                              "veg"
                            ] || ""
                          }
                          placeholder={"0"}
                          onChange={e => {
                            props.handleMealChange(e, "veg", mealNo);
                          }}
                          type="number"
                          variant="outlined"
                          className={localClasses.inputClass}
                          disableUnderline={true}
                          size="small"
                          inputProps={{ style: { textAlign: "center" } }}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      className={localClasses.tableCellStyle}
                      align="center"
                    >
                      {" "}
                      <div className={localClasses.inputRoot}>
                        <Input
                          id={
                            "fruit_day_" +
                            props.mealForm.exchangeDay +
                            "_mealNo_" +
                            mealNo
                          }
                          value={
                            props.mealForm.mealValues["meal_" + mealNo][
                              "fruit"
                            ] || ""
                          }
                          placeholder={"0"}
                          onChange={e => {
                            props.handleMealChange(e, "fruit", mealNo);
                          }}
                          type="number"
                          variant="outlined"
                          className={localClasses.inputClass}
                          disableUnderline={true}
                          size="small"
                          inputProps={{ style: { textAlign: "center" } }}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      className={localClasses.tableCellStyle}
                      align="center"
                    >
                      {" "}
                      <div className={localClasses.inputRoot}>
                        <Input
                          id={
                            "milk_day_" +
                            props.mealForm.exchangeDay +
                            "_mealNo_" +
                            mealNo
                          }
                          value={
                            props.mealForm.mealValues["meal_" + mealNo][
                              "milk"
                            ] || ""
                          }
                          placeholder={"0"}
                          onChange={e => {
                            props.handleMealChange(e, "milk", mealNo);
                          }}
                          type="number"
                          variant="outlined"
                          className={localClasses.inputClass}
                          disableUnderline={true}
                          size="small"
                          inputProps={{
                            style: { textAlign: "center" }
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      className={localClasses.tableCellStyle}
                      align="center"
                    >
                      {" "}
                      <div className={localClasses.inputRoot}>
                        <Input
                          id={
                            "fat_day_" +
                            props.mealForm.exchangeDay +
                            "_mealNo_" +
                            mealNo
                          }
                          value={
                            props.mealForm.mealValues["meal_" + mealNo][
                              "fat"
                            ] || ""
                          }
                          placeholder={"0"}
                          mealNo={mealNo}
                          onChange={e => {
                            props.handleMealChange(e, "fat", mealNo);
                          }}
                          type="number"
                          variant="outlined"
                          className={localClasses.inputClass}
                          disableUnderline={true}
                          size="small"
                          inputProps={{ style: { textAlign: "center" } }}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      className={localClasses.tableCellStyle}
                      align="center"
                    >
                      {props.mealForm.singleMealTotal["meal_" + mealNo]}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow hover={false} className={localClasses.rowstyles}>
                  <TableCell
                    className={localClasses.tableCellNoStyle}
                  ></TableCell>
                  <TableCell
                    className={localClasses.tableCellNoStyle}
                  ></TableCell>
                  <TableCell
                    className={localClasses.tableCellNoStyle}
                  ></TableCell>
                  <TableCell
                    className={localClasses.tableCellNoStyle}
                  ></TableCell>
                  <TableCell
                    className={localClasses.tableCellNoStyle}
                  ></TableCell>
                  <TableCell
                    className={localClasses.tableCellNoStyle}
                  ></TableCell>
                  <TableCell
                    className={localClasses.tableCellNoStyle}
                  ></TableCell>
                  <TableCell className={localClasses.tableCellNoStyle}>
                    <IconButton
                      color="secondary"
                      aria-label="Add supplement"
                      onClick={event => {
                        props.handleAddNewMeal();
                      }}
                    >
                      <AddCircleOutlineIcon style={{ color: "gray" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow hover={true}>
                  <TableCell
                    className={localClasses.tableTitleCurveCellStyle}
                    align="center"
                  >
                    <Typography className={localClasses.cardTitleStyle}>
                      TOTAL
                    </Typography>
                  </TableCell>
                  <TableCell className={localClasses.tableCellStyle}>
                    {convertNumber(props.mealForm.singleFoodTotal["bread"])}
                  </TableCell>
                  <TableCell className={localClasses.tableCellStyle}>
                    {convertNumber(props.mealForm.singleFoodTotal["meat"])}
                  </TableCell>
                  <TableCell className={localClasses.tableCellStyle}>
                    {convertNumber(props.mealForm.singleFoodTotal["veg"])}
                  </TableCell>
                  <TableCell className={localClasses.tableCellStyle}>
                    {convertNumber(props.mealForm.singleFoodTotal["fruit"])}
                  </TableCell>
                  <TableCell className={localClasses.tableCellStyle}>
                    {convertNumber(props.mealForm.singleFoodTotal["milk"])}
                  </TableCell>
                  <TableCell className={localClasses.tableCellStyle}>
                    {convertNumber(props.mealForm.singleFoodTotal["fat"])}
                  </TableCell>
                  <TableCell className={localClasses.tableCellStyle}>
                    {convertNumber(props.mealForm.total)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default AllMealsOfADayGrid;
