// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClientMyNotes_editor-class__KzIsc figure {\n  margin: 0;\n}\n.ClientMyNotes_editor-class__KzIsc .ClientMyNotes_rdw-image-left__1WlzH {\n  display: unset;\n  float: left;\n}\n.ClientMyNotes_editor-class__KzIsc .ClientMyNotes_rdw-image-right__sIlcN {\n  display: unset;\n  justify-content: unset;\n  float: right;\n}\n@media only screen and (min-width: 1280px) {\n  .ClientMyNotes_backIcon__38DI_ {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/containers/ClientMyNotes/ClientMyNotes.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;AACA;EACE,cAAc;EACd,WAAW;AACb;AACA;EACE,cAAc;EACd,sBAAsB;EACtB,YAAY;AACd;AACA;EACE;IACE,6BAA6B;IAC7B,6BAA6B;EAC/B;AACF","sourcesContent":[".editor-class figure {\n  margin: 0;\n}\n.editor-class .rdw-image-left {\n  display: unset;\n  float: left;\n}\n.editor-class .rdw-image-right {\n  display: unset;\n  justify-content: unset;\n  float: right;\n}\n@media only screen and (min-width: 1280px) {\n  .backIcon {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor-class": "ClientMyNotes_editor-class__KzIsc",
	"rdw-image-left": "ClientMyNotes_rdw-image-left__1WlzH",
	"rdw-image-right": "ClientMyNotes_rdw-image-right__sIlcN",
	"backIcon": "ClientMyNotes_backIcon__38DI_"
};
export default ___CSS_LOADER_EXPORT___;
