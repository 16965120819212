import React, { useState, useEffect } from "react";
import {
  AutoCompleteDropDown,
  HeadingOne,
  Input,
  Auth,
  useWindowDimensions
} from "../../components";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import {
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  TableContainer,
  Tooltip,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import {
  providerForDownload,
  providerForGet,
  providerForPost
} from "../../api";
import {
  generateShoppingList,
  getScheduleDateForClient,
  getShoppingListForSchedule
} from "../../constants";
import Paper from "@material-ui/core/Paper";
import GetAppIcon from "@material-ui/icons/GetApp";
import { convertDate } from "../../utils";
import noSchedule from "../../assets/images/No_schedule.jpg";
import PrintIcon from "@material-ui/icons/Print";

const ClientMyShoppingList = props => {
  const { height, width } = useWindowDimensions();

  const useStyles = makeStyles(theme => ({
    titleText: {
      color: "#110F48",
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "1.25rem",
      marginBottom: "1.5625rem",
      "& svg": {
        verticalAlign: "middle"
      }
    },
    table: {
      minWidth: 650
    },
    root: {
      flexGrow: 1
    },
    summaryButtonBox: {
      display: "flex",
      marginBottom: "3%"
    },
    loadTemplate: {
      marginLeft: "auto"
    },
    individualItem: {
      textAlignLast: "center",
      textAlign: "center",
      width: props.isDesktop ? width / 30 : 60,
      minWidth: props.isDesktop ? width / 30 : 60
    },
    individualItemBig: {
      textAlignLast: "center",
      textAlign: "center",
      width: props.isDesktop ? width / 8 : 150,
      minWidth: props.isDesktop ? width / 8 : 150
    },
    mainHeaderStyles: {
      color: "white",
      "background-color": "#EEF1F1",
      "border-radius": "3px",
      alignContent: "center",
      marginTop: "6.5%",
      width: props.isDesktop ? "100%" : "1100px"
    },
    mainSupplementListData: {
      color: "white",
      "background-color": "#EEF1F1",
      "border-radius": "3px",
      alignContent: "center",
      marginTop: "15px",
      padding: "15px 0px",
      borderRadius: 10,
      width: props.isDesktop ? "100%" : "1100px"
    },
    foodHeaderStyles: {
      color: "white",
      "background-color": "#EEF1F1",
      "border-radius": "3px",
      height: height * 0.04,
      alignContent: "center",
      marginTop: "2%",
      width: props.isDesktop ? width / 2.5 : "640px"
    },
    mainGrid: {
      overflow: props.isDesktop ? "none" : "auto"
    },
    duplicateReplicateStyle: {
      display: "flex",
      justifyContent: "flex-end",
      marginLeft: "auto"
    },

    addExchangesForMealsGrid: {
      height: props.isDesktop ? height * 0.5 : "500px",
      overflowY: "auto",
      overflowX: props.isDesktop ? "hidden" : "auto",
      marginTop: "1%",
      marginBottom: "1%",
      width: "fit-content"
    },
    addExchangesForMealsGridMobile: {
      height: props.isDesktop ? height * 0.5 : "500px",
      marginTop: "1%",
      marginBottom: "1%"
    },
    margin: {
      margin: theme.spacing(1)
    },
    inputClass: {
      marginLeft: "auto",
      marginRight: "auto",
      position: "relative",
      height: "19px",
      "text-align": "center",
      fontSize: "small"
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    mealForDayGrid: {
      display: "flex",
      flexWrap: "wrap"
    },
    textbox: {
      marginLeft: "2%",
      marginRight: "1%",
      width: "100%"
    },
    borderNone: {
      border: "none"
    },
    exercisesBorder: {
      border: "none",
      paddingLeft: 0
    },
    trRoot: {
      overflow: "auto",
      "& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover": {
        background: "#F8F8FA"
      },
      "& .MuiTableRow-root": {
        "&:hover": {
          background: "#F8F8FA !important"
        }
      },
      marginTop: "15px"
    },
    cardTitleStyle: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "0.9375rem",
      color: "#110F48",
      minWidth: 60,
      textAlign: "center",
      padding: "1rem !important"
    },
    cardHeadTitleStyle: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "1rem",
      color: "#8A8A97",
      minWidth: 60,
      textAlign: "center",
      padding: "1rem"
    },
    centerImage: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: "30%"
    }
  }));
  const localClasses = useStyles();
  const auth = Auth.getToken();
  const userInfo = Auth.getUserInfo();
  let propData = props["location"]["state"];
  const noScheduleDataMessageFromBackend = "No schedule data";
  const [scheduleDateNotPresent, setScheduleDateNotPresent] = useState(false);

  const [dateList, setDateList] = useState([]);
  const [tableData, setTableData] = useState({});
  const [loader, setLoader] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    scheduleId: propData ? propData.id : null,
    alert: false,
    errorMessage: "",
    severity: "error"
  });

  const getScheduleDates = async () => {
    setLoader(true);
    await providerForGet(
      getScheduleDateForClient,
      {
        c: userInfo.id
      },
      auth
    )
      .then(async res => {
        let convertDateData = await convertDate(res.data);
        setDateList(convertDateData);
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            scheduleDate: convertDateData[0].id,
            scheduleObj: convertDateData[0],
            defaultScheduleObj: convertDateData[0]
          }
        }));
        setLoader(false);
      })
      .catch(error => {
        console.log("error", error);
        setLoader(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.statusCode === 400 &&
          error.response.data.message === noScheduleDataMessageFromBackend
        ) {
          setScheduleDateNotPresent(true);
        }
      });
  };

  useEffect(() => {
    getScheduleDates();
  }, []);

  const getShoppingListData = async () => {
    if (formState.values.scheduleDate) {
      setLoader(true);
      let data = {
        schedule_id: formState.values.scheduleObj.id,
        c: userInfo.id
      };
      await providerForPost(getShoppingListForSchedule, data, auth, {
        desc: "access shopping list by client"
      })
        .then(res => {
          setTableData(res.data);
          setLoader(false);
        })
        .catch(error => {
          setLoader(false);
          console.log("error", error);
        });
    } else {
      return <div>No Data</div>;
    }
  };

  useEffect(() => {
    getShoppingListData();
  }, [formState.values]);

  const handleChangeAutoComplete = (eventName, event, value) => {
    if (eventName === "scheduleDate") {
      if (value !== null) {
        setFormState(formState => ({
          ...formState,

          values: {
            ...formState.values,
            [eventName]: value.id,
            scheduleObj: value
          }
        }));
      } else {
        delete formState.values[eventName];
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [eventName]: formState.values.defaultScheduleObj.id,
            scheduleObj: formState.values.defaultScheduleObj
          }
        }));
      }
    }
  };

  const handleShoppingListDownload = async isPrint => {
    setLoader(true);
    let data = {
      schedule_id: formState.values.scheduleObj.id,
      c: userInfo.id
    };
    await providerForDownload(generateShoppingList, data, auth, {
      desc: "Download report of shopping list by client"
    })
      .then(res => {
        setLoader(false);
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          const pdfNewWindow = window.open();
          pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "shopping_list.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  return (
    <div>
      <HeadingOne className={localClasses.titleText}>
        <ShoppingCartIcon style={{ paddingRight: 10 }} />
        MY SHOPPING LIST
      </HeadingOne>

      {scheduleDateNotPresent === true ? (
        <Grid>
          <img
            src={noSchedule}
            className={localClasses.centerImage}
            alt="No schedule/data"
          />
        </Grid>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <AutoCompleteDropDown
                options={dateList}
                getOptionLabel={option => option.schedule_start_date}
                id="schedule_date"
                value={
                  dateList[
                    dateList.findIndex(function (item, i) {
                      return item.id === formState.values["scheduleDate"];
                    })
                  ] || null
                }
                onChange={(event, value) => {
                  handleChangeAutoComplete("scheduleDate", event, value);
                }}
                renderInput={params => (
                  <Input {...params} id="schedule_date" label="Schedule Date" />
                )}
              />
            </Grid>
          </Grid>
          <Grid>
            <Grid
              item
              xs={12}
              md={5}
              lg={5}
              style={{
                justifyContent: "flex-end",
                marginLeft: "auto",
                textAlign: "end",
                padding: "12px !important"
              }}
            >
              <IconButton
                variant="contained"
                size="small"
                onClick={() => handleShoppingListDownload(false)}
              >
                <Tooltip title="Download SHopping List">
                  <GetAppIcon style={{ color: "#1C4979", fontSize: 30 }} />
                </Tooltip>
              </IconButton>
              <IconButton
                variant="contained"
                size="small"
                onClick={() => handleShoppingListDownload(true)}
              >
                <Tooltip title="Print Reportt">
                  <PrintIcon style={{ color: "#1C4979", fontSize: 30 }} />
                </Tooltip>
              </IconButton>
            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{ marginTop: "0px" }}>
              <div className={localClasses.trRoot}>
                <TableContainer component={Paper}>
                  <Table
                    className={localClasses.table}
                    aria-label="shopping-list-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className={localClasses.cardHeadTitleStyle}>
                          QUANTITY
                        </TableCell>
                        <TableCell className={localClasses.cardHeadTitleStyle}>
                          CATEGORY
                        </TableCell>
                        <TableCell className={localClasses.cardHeadTitleStyle}>
                          SUB CATEGORY
                        </TableCell>
                        <TableCell className={localClasses.cardHeadTitleStyle}>
                          FOOD NAME
                        </TableCell>
                        <TableCell className={localClasses.cardHeadTitleStyle}>
                          PROTION/UNIT
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {Object.keys(tableData).length ? (
                      <TableBody>
                        {Object.keys(tableData).map(item => (
                          <TableRow>
                            <TableCell className={localClasses.cardTitleStyle}>
                              {tableData[item].quantity
                                ? Math.round(tableData[item].quantity)
                                : null}
                            </TableCell>
                            <TableCell
                              className={localClasses.cardTitleStyle}
                              sortDirection="asc"
                            >
                              {tableData[item].foodCategory
                                ? tableData[item].foodCategory
                                : null}
                            </TableCell>
                            <TableCell className={localClasses.cardTitleStyle}>
                              {tableData[item].foodSubCategory
                                ? tableData[item].foodSubCategory
                                : null}
                            </TableCell>
                            <TableCell className={localClasses.cardTitleStyle}>
                              {item}
                            </TableCell>
                            <TableCell className={localClasses.cardTitleStyle}>
                              {tableData[item].portionUnit
                                ? tableData[item].portionUnit
                                : null}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : null}
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </>
      )}
      <Backdrop className={localClasses.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

ClientMyShoppingList.propTypes = {};

ClientMyShoppingList.defaultProps = {};

export default ClientMyShoppingList;
