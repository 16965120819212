const EmergencyContactSchema = {
  franchiseName: {
    label: "Franchise",
    id: "franchiseName",
    autoComplete: "Franchise",
    required: false,
    placeholder: "Franchise",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  franchiseSite: {
    label: "Franchise Site",
    id: "franchiseSite",
    autoComplete: "Franchise Site",
    required: false,
    placeholder: "Franchise Site",
    autoFocus: true,
    type: "text",
    validations: {}
  },

  do_not_share: {
    label: "do_not_share",
    id: "do_not_share",
    autoComplete: "do_not_share",
    required: false,
    placeholder: "do_not_share",
    autoFocus: true,
    type: "boolean",
    validations: {}
  },
  active: {
    label: "active",
    id: "active",
    autoComplete: "active",
    required: false,
    placeholder: "active",
    autoFocus: true,
    type: "boolean",
    validations: {}
  },
  is2FAEnabled: {
    label: "is2FAEnabled",
    id: "is2FAEnabled",
    autoComplete: "is2FAEnabled",
    required: false,
    placeholder: "2FA",
    autoFocus: true,
    type: "boolean",
    validations: {}
  },
  full_name: {
    label: "Full Name",
    id: "clientName",
    autoComplete: "Full Name",
    required: false,
    placeholder: "Full Name",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  email: {
    label: "Your Email",
    id: "email",
    autoComplete: "Your Email",
    required: false,
    placeholder: "Your Email",
    autoFocus: true,
    type: "email",
    validations: {}
  },
  address: {
    label: "Address",
    id: "clientAddress",
    autoComplete: "Address",
    required: false,
    placeholder: "Address",
    autoFocus: true,
    type: "text",
    validations: {}
  },

  country: {
    label: "Country",
    id: "clientCountry",
    autoComplete: "Country",
    required: false,
    placeholder: "Country",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  phone: {
    label: "Phone",
    id: "clientPhone",
    autoComplete: "Phone",
    required: false,
    placeholder: "Phone",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  city: {
    label: "City",
    id: "clientCity",
    autoComplete: "City",
    required: false,
    placeholder: "City",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  State: {
    label: "State",
    id: "clientState",
    autoComplete: "State",
    required: false,
    placeholder: "State",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  zipcode: {
    label: "Zip",
    id: "clientZip",
    autoComplete: "Zip",
    required: false,
    placeholder: "Zip",
    autoFocus: true,
    type: "number",
    validations: {}
  },
  birthdate: {
    label: "Birthdate",
    id: "clientBirthdate",
    autoComplete: "Birthdate",
    required: false,
    placeholder: "Birthdate",
    autoFocus: true,
    type: "date",
    validations: {}
  },
  home_phone: {
    label: "Home Phone",
    id: "home_phone",
    autoComplete: "Home Phone",
    required: false,
    placeholder: "Home Phone",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  business_phone: {
    label: "Bussiness Phone",
    id: "business_phone",
    autoComplete: "Bussiness Phone",
    required: false,
    placeholder: "Bussiness Phone",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  dl_number: {
    label: "DL Number",
    id: "dl_number",
    autoComplete: "Dl Number",
    required: false,
    placeholder: "DL Number",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  ss_number: {
    label: "Social Security Number",
    id: "ss_number",
    autoComplete: "Social Security Number",
    required: false,
    placeholder: "Social Security Number",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  /**For Autocomplete */
  schooling: {
    label: "Schooling",
    id: "schooling",
    autoComplete: "Schooling",
    required: false,
    placeholder: "Schooling",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  clientReferredBy: {
    label: "Referred By",
    id: "clientReferredBy",
    autoComplete: "Referred By",
    required: false,
    placeholder: "Referred By",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  gender: {
    label: "Sex",
    id: "gender",
    autoComplete: "Sex",
    required: false,
    placeholder: "Sex",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  //add tags
  addTags: {
    label: "Add Tags",
    id: "addTags",
    autoComplete: "Add Tags",
    required: false,
    placeholder: "Add Tags",
    autoFocus: true,
    type: "multi-select",
    validations: {}
  },
  //relationship status
  isMarried: {
    label: "Married?",
    id: "isMarried",
    autoComplete: "status",
    required: false,
    placeholder: "status",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  partner_name: {
    label: "Partmer Name",
    id: "Name",
    autoComplete: "Full Name",
    required: false,
    placeholder: "Partmer Name",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  partner_dob: {
    label: "Birthdate",
    id: "partner_dob",
    autoComplete: "Birthdate",
    required: false,
    placeholder: "Birthdate",
    autoFocus: true,
    type: "date",
    validations: {}
  },
  partner_employer: {
    label: "Partner Employer Name",
    id: "partner_employer",
    autoComplete: "Partner Employer Name",
    required: false,
    placeholder: "Employer",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  partner_employer_phone: {
    label: "Partner Emp. Phone",
    id: "partner_employer_phone",
    autoComplete: "Emp. Phone",
    required: false,
    placeholder: "Partner Emp. Phone",
    autoFocus: true,
    type: "number",
    validations: {}
  },
  partner_emp_address: {
    label: "Partner Emp. Address",
    id: "partner_emp_address",
    autoComplete: "Address",
    required: false,
    placeholder: "Partner Emp. Address",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  //employment fields
  occupation: {
    label: "Occupation",
    id: "occupation",
    autoComplete: "Occupation",
    required: false,
    placeholder: "Occupation",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  employer: {
    label: "Employer",
    id: "employer",
    autoComplete: "Employer",
    required: false,
    placeholder: "Employer",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  employment_address: {
    label: "Address",
    id: "employment_address",
    autoComplete: "Address",
    required: false,
    placeholder: "Address",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  employment_phone: {
    label: "Phone",
    id: "employment_phone",
    autoComplete: "Phone",
    required: false,
    placeholder: "Phone",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  //emergency contact
  emergency_contact_name: {
    label: "Name",
    id: "emergency_contact_name",
    autoComplete: "Name",
    required: false,
    placeholder: "Name",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  emergency_contact_relation: {
    label: "Relation",
    id: "emergency_contact_relation",
    autoComplete: "Relation",
    required: false,
    placeholder: "Relation",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  emergency_contact_home_phone: {
    label: "Phone",
    id: "emergency_contact_home_phone",
    autoComplete: "Phone",
    required: false,
    placeholder: "Phone",
    autoFocus: true,
    type: "text",
    validations: {}
  },
  emergency_contact_work_phone: {
    label: "Work Phone",
    id: "emergency_contact_work_phone",
    autoComplete: "Work Phone",
    required: false,
    placeholder: "Work Phone",
    autoFocus: true,
    type: "number",
    validations: {}
  },
  // Notes
  notes: {
    label: "Notes",
    id: "notes",
    autoComplete: "Notes",
    required: false,
    placeholder: "Notes",
    autoFocus: true,
    validations: {}
  },
  // Callback
  callBack: {
    label: "Call Back",
    id: "callBack",
    autoComplete: "Call Back",
    required: false,
    placeholder: "Call Back",
    autoFocus: true,
    validations: {}
  }
};
export default EmergencyContactSchema;
