import React from "react";
import { Typography, Avatar, makeStyles } from "@material-ui/core";
import { getFormattedDate } from "../../../src/utils";

const localStyles = makeStyles(theme => ({
  Iconroot: {
    display: "flex",
    marginRight: "0.5%",
    marginTop: "0px",
    width: "100%"
  },
  inputclass: {
    marginLeft: "1%"
  },
  appBarMobileTitle: {
    color: "black",
    marginBottom: "0px",
    alignItems: "flex-end"
  },
  addNewScheduleButton: {
    display: "flex",
    marginTop: "2%",
    float: "right"
  },
  middleDivider: {
    marginTop: "2%"
  },
  iconGrid: {
    paddingLeft: "0px !Important",
    paddingTop: "0px !Important"
  },
  large: {
    width: "3.25rem",
    height: "3.25rem",
    color: "#110F48",
    backgroundColor: "#D8F4FF",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "1.25rem"
  },
  nameStyle: {
    color: "#000",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1.25rem",
    display: "inline-block"
  },
  clientIdStyle: {
    color: "#727272",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "0.875rem",
    display: "inline-block",
    marginLeft: "10px"
  },
  scheduleDateStyle: {
    color: "#ACACB8",
    fontSize: "0.875rem",
    fontFamily: "Montserrat",
    fontWeight: "600",
    marginBottom: "0px"
  }
}));

export default function NameComponent(props) {
  const localClasses = localStyles();
  const formattedDate = getFormattedDate(
    props.scheduleData.schedule_start_date
  );
  return (
    <div className={localClasses.Iconroot}>
      <Avatar className={localClasses.large}>{props.initials}</Avatar>
      <div style={{ marginLeft: "15px" }}>
        <Typography className={localClasses.nameStyle}>
          {props.full_name}
        </Typography>
        <Typography className={localClasses.clientIdStyle}>
          [Client ID - {props.id}]
        </Typography>
        <Typography className={localClasses.scheduleDateStyle}>
          Schedule dated {formattedDate}
        </Typography>
      </div>
    </div>
  );
}
