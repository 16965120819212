import {
  USERS,
  SCHOOLING,
  MARITALSTATUS,
  EMERGENCYCONTACTS,
  CHANGEPASSWORD,
  LOGOUT,
  AUDITLOGS,
  DOCUMENTLIST,
  FRANCHISE,
  MANAGECLIENTS,
  SUPPLEMENT,
  EXERCISE,
  FOOD,
  FOODCATEGORY,
  FOODSUBCATEGORY,
  SIZEWEIGHT,
  MUSCLEGROUP,
  GENDER,
  COUNTRIES,
  USERTAG,
  BODYMASS,
  MEASUREMENTS,
  CLIENTMEASUREMENT,
  EXCHANGEMENU,
  SUPPLEMENTS,
  WORKOUT,
  CREATEMEALS,
  SUPPLEMENTTEMPLATE,
  EXERCISETEMPLATE,
  MANAGEEXCHANGEMEALTEMPLATE,
  GIVEALL,
  CLIENTDASHBAORD,
  CLIENTMYSCHEDULE,
  CLIENTMYNOTES,
  CLIENTMYREPORTS,
  CLIENTMYPROFILE,
  CLIENTSUPPLEMENT,
  FRANCHISESITES,
  CLIENTEXERCISE,
  CLIENTBODYMASS,
  CLIENTEXCHANGE,
  CLIENTSHOPPINGLIST,
  CUSTOMCOLOR,
  VIEWPROFILE,
  AUTHENTICATOR,
  CLIENTFOODDIARY
} from "../../../paths/Paths";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import FavoriteIcon from "@material-ui/icons/Favorite";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import SwapHorizontalCircleOutlinedIcon from "@material-ui/icons/SwapHorizontalCircleOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import StorefrontIcon from "@material-ui/icons/Storefront";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import EmojiFoodBeverageIcon from "@material-ui/icons/EmojiFoodBeverage";
import KitchenIcon from "@material-ui/icons/Kitchen";
import SpeedIcon from "@material-ui/icons/Speed";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import PeopleIcon from "@material-ui/icons/People";
import SchoolIcon from "@material-ui/icons/School";
import WcIcon from "@material-ui/icons/Wc";
import ContactsIcon from "@material-ui/icons/Contacts";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import LanguageIcon from "@material-ui/icons/Language";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import BodyMassIcon from "../../../assets/images/Body_Mass.png";
import ColorBodyMassIcon from "../../../assets/images/ColoredBodyMass.png";
import ExerciseImg from "../../../assets/images/Exercise.png";
import ColorExerciseImg from "../../../assets/images/ColoredExercise.png";
import MeasurementImg from "../../../assets/images/Measurement .png";
import ColorMeasurementImg from "../../../assets/images/ColoredMeasurement.png";
import SupplementImg from "../../../assets/images/Supplements.png";
import ColorSupplementImg from "../../../assets/images/ColoredSupplements.png";
import MenuImg from "../../../assets/images/Menu.png";
import ColorMenuImg from "../../../assets/images/ColoredMenu.png";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AssessmentIcon from "@material-ui/icons/Assessment";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import exerciseIcon from "../../../assets/small_icons/Exercise.png";
import bodyMassIcon from "../../../assets/small_icons/Body Mass.png";
import measurementIcon from "../../../assets/small_icons/Measurement.png";
import supplementIcon from "../../../assets/small_icons/Supplements.png";
import menuIcon from "../../../assets/small_icons/Menu.png";

export const sideDrawerMenusForSuperAdmin = [
  // {
  //   title: "Super admin dashboard",
  //   path: DASHBOARD,
  //   icon: <DashboardIcon />,
  //   pathList: [DASHBOARD]
  // },
  {
    title: "Users",
    path: "Users",
    icon: <PeopleIcon />,
    pathList: [
      USERS,
      SCHOOLING,
      GENDER,
      EMERGENCYCONTACTS,
      MARITALSTATUS,
      COUNTRIES
    ],
    children: [
      {
        title: "Schooling",
        path: SCHOOLING,
        icon: <SchoolIcon />,
        pathList: [SCHOOLING]
      },
      {
        title: "Gender",
        path: GENDER,
        icon: <WcIcon />,
        pathList: [GENDER]
      },
      {
        title: "Relation",
        path: EMERGENCYCONTACTS,
        icon: <ContactsIcon />,
        pathList: [EMERGENCYCONTACTS]
      },
      {
        title: "Marital Status",
        path: MARITALSTATUS,
        icon: <GroupAddIcon />,
        pathList: [MARITALSTATUS]
      },
      {
        title: "Countries",
        path: COUNTRIES,
        icon: <LanguageIcon />,
        pathList: [COUNTRIES]
      },
      {
        title: "User Tag",
        path: USERTAG,
        icon: <LoyaltyIcon />,
        pathList: [USERTAG]
      }
    ]
  },
  {
    title: "Franchise",
    path: FRANCHISE,
    icon: <StorefrontIcon />,
    pathList: [FRANCHISE, FRANCHISESITES],
    children: [
      {
        title: "Franchise Sites",
        path: FRANCHISESITES,
        icon: <AccountBalanceOutlinedIcon />,
        pathList: [FRANCHISESITES]
      }
    ]
  },

  {
    title: "Food",
    path: FOOD,
    icon: <FastfoodIcon />,
    pathList: [FOOD, FOODCATEGORY, FOODSUBCATEGORY, SIZEWEIGHT],
    children: [
      {
        title: "Food Category",
        // type: "superAdmin",
        path: FOODCATEGORY,
        icon: <EmojiFoodBeverageIcon />,
        pathList: [FOODCATEGORY]
      },
      {
        title: "Food Sub Category",
        // type: "superAdmin",
        path: FOODSUBCATEGORY,
        icon: <KitchenIcon />,
        pathList: [FOODSUBCATEGORY]
      },
      {
        title: "Portion/Unit",
        path: SIZEWEIGHT,
        icon: <SpeedIcon />,
        pathList: [SIZEWEIGHT]
      }
    ]
  },
  {
    title: "Supplement",
    path: SUPPLEMENT,
    icon: <LocalHospitalIcon />,
    pathList: [SUPPLEMENT]
  },
  {
    title: "Exercise",
    path: EXERCISE,
    icon: <FitnessCenterIcon />,
    pathList: [EXERCISE, MUSCLEGROUP],
    children: [
      {
        title: "Muscle Group",
        path: MUSCLEGROUP,
        icon: <AccessibilityIcon />,
        pathList: [MUSCLEGROUP]
      }
    ]
  },
  {
    title: "Audit logs",
    path: AUDITLOGS,
    icon: <LibraryBooksIcon />,
    pathList: [AUDITLOGS]
  },
  {
    title: "Customisation",
    path: DOCUMENTLIST,
    icon: <ColorLensIcon />,
    pathList: [DOCUMENTLIST]
  }
];

/**
 * * For PFC-admin
 * */
export const sideDrawerMenusForPfcAdmin = [
  // {
  //   title: "Super admin dashboard",
  //   path: DASHBOARD,
  //   icon: <DashboardIcon />,
  //   pathList: [DASHBOARD]
  // },
  {
    title: "Users",
    path: "Users",
    icon: <PeopleIcon />,
    pathList: [
      USERS,
      SCHOOLING,
      GENDER,
      EMERGENCYCONTACTS,
      MARITALSTATUS,
      COUNTRIES
    ],
    children: [
      {
        title: "Schooling",
        path: SCHOOLING,
        icon: <SchoolIcon />,
        pathList: [SCHOOLING]
      },
      {
        title: "Gender",
        path: GENDER,
        icon: <WcIcon />,
        pathList: [GENDER]
      },
      {
        title: "Relation",
        path: EMERGENCYCONTACTS,
        icon: <ContactsIcon />,
        pathList: [EMERGENCYCONTACTS]
      },
      {
        title: "Marital Status",
        path: MARITALSTATUS,
        icon: <GroupAddIcon />,
        pathList: [MARITALSTATUS]
      },
      {
        title: "Countries",
        path: COUNTRIES,
        icon: <LanguageIcon />,
        pathList: [COUNTRIES]
      },
      {
        title: "User Tag",
        path: USERTAG,
        icon: <LoyaltyIcon />,
        pathList: [USERTAG]
      }
    ]
  },
  {
    title: "Franchise",
    path: FRANCHISE,
    icon: <StorefrontIcon />,
    pathList: [FRANCHISE]
  },
  {
    title: "Franchise Sites",
    path: FRANCHISESITES,
    icon: <AccountBalanceOutlinedIcon />,
    pathList: [FRANCHISESITES]
  },
  {
    title: "Food",
    path: FOOD,
    icon: <FastfoodIcon />,
    pathList: [FOOD, FOODCATEGORY, FOODSUBCATEGORY, SIZEWEIGHT],
    children: [
      {
        title: "Food Category",
        // type: "superAdmin",
        path: FOODCATEGORY,
        icon: <EmojiFoodBeverageIcon />,
        pathList: [FOODCATEGORY]
      },
      {
        title: "Food Sub Category",
        // type: "superAdmin",
        path: FOODSUBCATEGORY,
        icon: <KitchenIcon />,
        pathList: [FOODSUBCATEGORY]
      },
      {
        title: "Portion/Unit",
        path: SIZEWEIGHT,
        icon: <SpeedIcon />,
        pathList: [SIZEWEIGHT]
      }
    ]
  },
  {
    title: "Supplement",
    path: SUPPLEMENT,
    icon: <LocalHospitalIcon />,
    pathList: [SUPPLEMENT]
  },
  {
    title: "Exercise",
    path: EXERCISE,
    icon: <FitnessCenterIcon />,
    pathList: [EXERCISE, MUSCLEGROUP],
    children: [
      {
        title: "Muscle Group",
        path: MUSCLEGROUP,
        icon: <AccessibilityIcon />,
        pathList: [MUSCLEGROUP]
      }
    ]
  }
];
export const selectFranchise = [
  // {
  //   title: "Select Franchise",
  //   path: DASHBOARD,
  //   icon: <DashboardIcon />,
  //   pathList: [DASHBOARD]
  // }
];
export const sideDrawerMenusForAdmin = [
  {
    title: "Users",
    path: "USERS",
    icon: <PeopleIcon />,
    pathList: [USERS],
    children: [
      {
        title: "Gender",
        path: GENDER,
        icon: <WcIcon />,
        pathList: [GENDER]
      }
    ]
  },
  {
    title: "Franchise",
    path: FRANCHISE,
    icon: <StorefrontIcon />,
    pathList: [FRANCHISE, FRANCHISESITES],
    children: [
      {
        title: "Franchise Sites",
        path: FRANCHISESITES,
        icon: <AccountBalanceOutlinedIcon />,
        pathList: [FRANCHISESITES]
      }
    ]
  },
  {
    title: "Food",
    path: FOOD,
    icon: <FastfoodIcon />,
    pathList: [FOOD]
  },
  {
    title: "Supplement",
    path: SUPPLEMENT,
    icon: <LocalHospitalIcon />,
    pathList: [SUPPLEMENT]
  },
  {
    title: "Exercise",
    path: EXERCISE,
    icon: <FitnessCenterIcon />,
    pathList: [EXERCISE]
  },
  {
    title: "Customisation",
    path: CUSTOMCOLOR,
    icon: <ColorLensIcon />,
    pathList: [CUSTOMCOLOR]
  }
];

/**
 * * Nutritionist drawer
 */

export const ScheduleTabView = [
  {
    name: "BODY MASS",
    title: "BODY MASS",
    src: BodyMassIcon,
    hover: ColorBodyMassIcon,
    path: BODYMASS,
    pathList: [BODYMASS]
  },
  {
    type: "nutritionist",
    name: "MEASUREMENTS",
    src: MeasurementImg,
    hover: ColorMeasurementImg,
    title: "MEASUREMENTS",
    path: MEASUREMENTS,
    pathList: [MEASUREMENTS]
  },
  {
    name: "EXCHANGE/MENU",
    title: "EXCHANGE/MENU",
    src: MenuImg,
    hover: ColorMenuImg,
    path: EXCHANGEMENU,
    pathList: [EXCHANGEMENU, CREATEMEALS]
  },
  {
    name: "SUPPLEMENTS",
    title: "SUPPLEMENTS",
    src: SupplementImg,
    hover: ColorSupplementImg,
    path: SUPPLEMENTS,
    pathList: [SUPPLEMENTS]
  },
  {
    name: "EXERCISE",
    title: "EXERCISE",
    src: ExerciseImg,
    hover: ColorExerciseImg,
    path: WORKOUT,
    pathList: [WORKOUT]
  }
];

/**
 * * For Nutritionist (Side  Drawer)
 * */
export const nutritionistClientView = [
  {
    title: "CLIENTS",
    path: MANAGECLIENTS,
    icon: <PeopleAltIcon />,
    pathList: [
      MANAGECLIENTS,
      BODYMASS,
      MEASUREMENTS,
      EXCHANGEMENU,
      WORKOUT,
      SUPPLEMENTS
    ]
  },

  {
    title: "FAVORITES",
    path: SUPPLEMENTTEMPLATE,
    type: "nutritionist",
    icon: <FavoriteIcon />,
    pathList: [
      SUPPLEMENTTEMPLATE,
      EXERCISETEMPLATE,
      MANAGEEXCHANGEMEALTEMPLATE
    ],
    children: [
      {
        title: "Supplement Favorite",
        path: SUPPLEMENTTEMPLATE,
        icon: <LocalHospitalIcon />,
        pathList: [SUPPLEMENTTEMPLATE]
      },
      {
        title: "Exercise Favorite",
        path: EXERCISETEMPLATE,
        icon: <LocalHospitalIcon />,
        pathList: [EXERCISETEMPLATE]
      },
      {
        title: "Exchange/Menu Favorite",
        path: MANAGEEXCHANGEMEALTEMPLATE,
        icon: <LocalHospitalIcon />,
        pathList: [MANAGEEXCHANGEMEALTEMPLATE]
      }
    ]
  },
  {
    title: "RESOURCES",
    path: GIVEALL,
    icon: <FindInPageIcon />,
    pathList: [GIVEALL]
  }
];

/**
 * * For clients
 * */
export const sideDrawerMenus = [
  {
    title: "Dashboard",
    path: CLIENTDASHBAORD,
    icon: <DashboardIcon />,
    pathList: [CLIENTDASHBAORD]
  },
  {
    title: "My Schedule",
    // type: "client",
    path: CLIENTMYSCHEDULE,
    icon: <DateRangeIcon />,
    pathList: [CLIENTMYSCHEDULE],
    children: [
      {
        title: "Body Mass",
        path: CLIENTBODYMASS,
        icon: (
          <img
            src={bodyMassIcon}
            alt="exercise"
            style={{
              padding: 2,
              height: 25
            }}
          />
        ),
        pathList: [CLIENTBODYMASS]
      },
      {
        title: "Measurements",
        path: CLIENTMEASUREMENT,
        icon: (
          <img
            src={measurementIcon}
            alt="exercise"
            style={{
              padding: 2,
              height: 25
            }}
          />
        ),
        pathList: [CLIENTMEASUREMENT]
      },
      {
        title: "Menu/ Exchanges",
        path: CLIENTEXCHANGE,
        icon: (
          <img
            src={menuIcon}
            alt="exercise"
            style={{
              padding: 2,
              height: 25
            }}
          />
        ),
        pathList: [CLIENTEXCHANGE]
      },
      {
        title: "Supplement",
        path: CLIENTSUPPLEMENT,
        icon: (
          <img
            src={supplementIcon}
            alt="exercise"
            style={{
              padding: 2,
              height: 25
            }}
          />
        ),
        pathList: [CLIENTSUPPLEMENT]
      },
      {
        title: "Exercises",
        path: CLIENTEXERCISE,
        icon: (
          <img
            src={exerciseIcon}
            alt="exercise"
            style={{
              padding: 2,
              height: 25
            }}
          />
        ),

        pathList: [CLIENTEXERCISE]
      }
    ]
  },
  {
    title: "Food Diary",
    path: CLIENTFOODDIARY,
    icon: <FileCopyIcon />,
    pathList: [CLIENTFOODDIARY]
  },
  {
    title: "Notes",
    path: CLIENTMYNOTES,
    icon: <FileCopyIcon />,
    pathList: [CLIENTMYNOTES]
  },
  {
    title: "My Reports",
    path: CLIENTMYREPORTS,
    icon: <AssessmentIcon />,
    pathList: [CLIENTMYREPORTS]
  },
  {
    title: "My Shopping List",
    path: CLIENTSHOPPINGLIST,
    icon: <ShoppingCartIcon />,
    pathList: [CLIENTSHOPPINGLIST]
  },
  {
    title: "My Profile",
    path: CLIENTMYPROFILE,
    icon: <PersonIcon />,
    pathList: [CLIENTMYPROFILE]
  }
];

export const sideDrawerAuthMenusForClient = [
  {
    title: "AUTH",
    type: "auth",
    path: "",
    icon: <AccountCircleOutlinedIcon />,
    pathList: [CHANGEPASSWORD, LOGOUT],
    children: [
      {
        title: "Profile",
        path: CLIENTMYPROFILE,
        icon: <SwapHorizontalCircleOutlinedIcon />,
        pathList: [CLIENTMYPROFILE]
      },
      {
        title: "Change Password",
        path: CHANGEPASSWORD,
        icon: <SwapHorizontalCircleOutlinedIcon />,
        pathList: [CHANGEPASSWORD]
      },
      {
        title: "2FA",
        path: AUTHENTICATOR,
        icon: <SwapHorizontalCircleOutlinedIcon />,
        pathList: [AUTHENTICATOR]
      },
      {
        title: "Sign Out",
        path: LOGOUT,
        icon: <ExitToAppIcon />,
        pathList: [LOGOUT]
      }
    ]
  }
];

export const sideDrawerAuthMenus = [
  {
    title: "AUTH",
    type: "auth",
    path: "",
    icon: <AccountCircleOutlinedIcon />,
    pathList: [CHANGEPASSWORD, LOGOUT],
    children: [
      {
        title: "Profile",
        path: VIEWPROFILE,
        icon: <SwapHorizontalCircleOutlinedIcon />,
        pathList: [VIEWPROFILE]
      },
      {
        title: "Change Password",
        path: CHANGEPASSWORD,
        icon: <SwapHorizontalCircleOutlinedIcon />,
        pathList: [CHANGEPASSWORD]
      },
      {
        title: "2FA",
        path: AUTHENTICATOR,
        icon: <SwapHorizontalCircleOutlinedIcon />,
        pathList: [AUTHENTICATOR]
      },
      {
        title: "Sign Out",
        path: LOGOUT,
        icon: <ExitToAppIcon />,
        pathList: [LOGOUT]
      }
    ]
  }
];
