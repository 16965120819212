import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";
const CryptoJS = require("crypto-js");

let secret = process.env.REACT_APP_SECRET;

export const encryptSHA256 = message => {
  const hashDigest = sha256(message);
  const hmacDigest = Base64.stringify(hmacSHA512(hashDigest, secret));
  return hmacDigest;
};

export const encryptWithAES = text => {
  const passphrase = secret;
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

export const decryptWithAES = ciphertext => {
  const passphrase = secret;
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
