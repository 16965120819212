import React, { useEffect, useState } from "react";
import styles from "./AddEditCountry.module.css";
import addCountryForm from "../CountrySchema";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  LinearProgress
} from "@material-ui/core";
import {
  Auth,
  Input,
  SnackBar,
  DarkBlueButton,
  BorderLessButton
} from "../../../../components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { get } from "lodash";
import {
  checkAllKeysPresent,
  checkEmpty,
  getListOfKeysNotPresent,
  setErrors
} from "../../../../utils";
import SaveIcon from "@material-ui/icons/Save";
import { COUNTRIES } from "../../../../paths";
import {
  providerForGet,
  providerForPost,
  providerForPut
} from "../../../../api";
import { getCountry } from "../../../../constants";
import {
  ERROR_MESSAGE,
  SAVE_MESSAGE,
  UPDATE_MESSAGE
} from "../../../../constants/genericConstants";
import useStyles from "../../../../utils/Styles/ManagePageStyles";
const AddEditCountry = props => {
  const history = useHistory();
  const auth = Auth.getToken();
  const userInfo = Auth.getUserInfo();
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  let propData = props["location"]["state"];
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    alert: false,
    severity: "success",
    errorMessage: "",
    /** This is when we return from edit page */
    rowData: propData ? propData : [],
    editData: propData ? (propData["id"] ? true : false) : false,
    editDataId: propData ? propData["id"] : null
  });
  const countryName = "countryName";

  /**Api call for getting autocomplete list itmes */
  const getListData = async () => {
    if (formState.editData === true) {
      setLoader(true);
      let editId = {
        id: formState.editDataId,
        desc: `${userInfo.full_name} accessed a country for editing`
      };
      await providerForGet(getCountry, editId, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              countryName: res.data.data[0].name
            }
          }));
          setLoader(false);
        })
        .catch(err => {
          setLoader(false);
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    getListData();
  }, []);

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: ""
    }));
  };

  const handleChange = e => {
    e.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));
    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const hasError = field => (formState.errors[field] ? true : false);

  const handleSubmit = () => {
    setLoader(true);
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      addCountryForm
    );
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, addCountryForm);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        addCountryForm
      );
      formState.errors = setErrors(formState.values, addCountryForm);
    }
    if (isValid) {
      /**api call from here */
      let postData = {
        name: formState.values.countryName
      };
      if (formState.editData === true) {
        /**Put api call (Update method)*/

        providerForPut(getCountry, formState.editDataId, postData, auth, {
          desc: `${userInfo.full_name} updated the country ${formState.values.countryName}`
        })
          .then(res => {
            let successMessage = {
              successMessage: "Country  " + res.data.name + UPDATE_MESSAGE
            };
            setLoader(false);
            history.push(COUNTRIES, successMessage);
          })
          .catch(err => {
            console.log("err", err);
            setFormState(formState => ({
              ...formState,
              alert: true,
              severity: "error",
              errorMessage: ERROR_MESSAGE + err
            }));
            setLoader(false);
          });
      } else {
        /**Post api call (Save method)*/
        providerForPost(getCountry, postData, auth, {
          desc: `${userInfo.full_name} added a new country ${formState.values.countryName}`
        })
          .then(res => {
            let successMessage = {
              successMessage: "Country " + res.data.name + SAVE_MESSAGE
            };
            setLoader(false);
            history.push(COUNTRIES, successMessage);
          })
          .catch(err => {
            console.log("error", err);
            setFormState(formState => ({
              ...formState,
              alert: true,
              severity: "error",
              errorMessage: ERROR_MESSAGE + err
            }));
            setLoader(false);
          });
      }
    } else {
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
      setLoader(false);
    }
  };

  const handleCancel = () => {
    history.push(COUNTRIES);
  };
  return (
    <div>
      {loader ? (
        <LinearProgress />
      ) : (
        <>
          {" "}
          <Grid item xs={12} className={styles.title}>
            {formState.editData === true ? (
              <Typography variant="h4" className={styles.titleText}>
                Edit Country
              </Typography>
            ) : (
              <Typography variant="h4" className={styles.titleText}>
                Add Country
              </Typography>
            )}
          </Grid>
          <Grid>
            <SnackBar
              open={formState.alert}
              severity={formState.severity}
              onClose={handleSnackbarClose}
            >
              {formState.errorMessage}
            </SnackBar>
          </Grid>
          <Grid item xs={12} className={styles.formgrid}>
            <Card className={classes.editCard}>
              <CardContent className={classes.editCardContent}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addCountryForm[countryName], "label")}
                      name={countryName}
                      value={formState.values[countryName] || ""}
                      error={hasError(countryName)}
                      placeholder={get(
                        addCountryForm[countryName],
                        "placeholder"
                      )}
                      variant="outlined"
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(countryName)
                          ? formState.errors[countryName].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} className={classes.actionButtonGrid}>
            <DarkBlueButton
              type="submit"
              // variant="contained"
              // className={styles.actionButton}
              data-testid="ok-button"
              onClick={handleSubmit}
            >
              <SaveIcon />
              &nbsp; Save
            </DarkBlueButton>
            <BorderLessButton
              onClick={handleCancel}
              // className={classes.actionButton}
              // variant="contained"
            >
              cancel
            </BorderLessButton>
          </Grid>
        </>
      )}
    </div>
  );
};

AddEditCountry.propTypes = {};

AddEditCountry.defaultProps = {};

export default AddEditCountry;
