import * as React from "react";
import { Route, useHistory, Redirect } from "react-router-dom";
import { Layout } from "../hoc";
import {
  Auth,
  Auth as auth,
  DialogForGettingMealNumbers,
  NameComponent,
  NotFoundPage,
  SnackBar
} from "../components";
import { BODYMASS, CREATEMEALS, EXCHANGEMENU, LOGIN } from "../paths";
import {
  Tabs,
  Tab,
  Backdrop,
  CircularProgress,
  Grid,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { includes } from "lodash";
import { useState, useEffect, useRef } from "react";
import { ScheduleTabView } from "../hoc/Layout/SideMenus/menu.js";
import {
  checkClientValidityForAFranchise,
  createScheduleApi,
  getOneClient
} from "../constants";
import {
  getInitials,
  isEmptyString,
  useStyles as ManagePageStyles
} from "../utils";
import { providerForGet, providerForPost } from "../api";
import noImage from "../assets/images/No_schedule.jpg";
import styles from "./Routes.module.css";

/**
 * * Styles ------------------------------------------------------------------------------
 */
const StyledTabs = withStyles({
  root: {},
  indicator: {
    height: "4px",
    background: "#1C4979"
  }
})(Tabs);

const StyledTab = withStyles(theme => ({
  root: {
    color: "#8A8A97",
    textTransform: "uppercase",
    minWidth: 20,
    fontWeight: 700,
    fontSize: "0.9375rem",
    fontFamily: "Montserrat",
    height: "53px !important",
    minHeight: "53px !important",
    padding: "0.5625rem 0.75rem 0.375rem 0.75rem",
    "& > span": {
      display: "flex",
      flexDirection: "row"
    },
    "&:hover": {
      color: "#110F48",
      opacity: 1
    },
    "&$selected": {
      color: "#110F48",
      borderBottom: "solid #110F48 1px",
      fontFamily: "Montserrat",
      fontWeight: 700,
      background: "tranparent",
      fontSize: "0.9375rem",
      lineHeight: "1.6875rem"
    },
    "&:focus": {
      color: "#110F48"
    },
    "& .MuiTab-wrapper": {
      display: "flex !important",
      flexDirection: "row"
    },
    "& .MuiTab-labelIcon": {
      minHeight: "50px !important"
    },
    "& .MuiTab-wrapper > *:first-child": {
      margin: "0.625rem 0.625rem 0.625rem 0px !important"
    }
  },
  selected: {}
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: "1 !Important",
    textTransform: "uppercase"
  },
  padding: {
    padding: theme.spacing(3)
  },
  marginY: {
    marginBottom: "16px",
    marginTop: "8px"
  },
  middleDivider: {
    marginTop: "2%"
  },
  addNewScheduleButton: {
    display: "flex",
    marginTop: "2%",
    float: "right"
  },
  scheduleDateStyle: {
    color: "#ACACB8",
    fontSize: "1rem",
    fontFamily: "Montserrat",
    fontWeight: "600",
    marginBottom: "15px"
  }
}));

/**
 * * ----------------------------------------------------------------------------------------
 */

const RouteWithTabLayout = props => {
  const {
    component: Component,
    computedMatch: ComputedMatch,
    location: Location
  } = props;

  const themes = useTheme();
  const childRef = useRef();
  const isDesktop = useMediaQuery(themes.breakpoints.up("lg"), {
    defaultMatches: true
  });
  const [error, setError] = useState({
    alert: false,
    severity: "success",
    errorMessage: ""
  });

  let history = useHistory();
  const classes = useStyles();
  const managePageStyles = ManagePageStyles();
  let navMenu = ScheduleTabView;
  const [isCreateMealsPage, setIsCreateMealsPage] = useState(false);
  const [scheduleData, setScheduleData] = useState([]);
  const [clientData, setClientData] = useState({
    id: null,
    name: null,
    schedule: null,
    gender: null
  });
  const [no_of_meals, setNoOfMeals] = useState({
    no_of_meals_from_previous_schedule: 0,
    no_of_meals_in_current_schedule: 0
  });
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isLatest, setIsLatest] = useState(false);
  const [active, setActive] = useState(false);

  const [loader, setLoader] = useState(false);
  const [initials, setInitials] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [isExistingSchedulePresent, setIsExistingSchedulePresent] = useState(
    false
  );
  const [meals, setMeals] = useState({
    noOfMeals: 6,
    dialogOpenClose: false,
    isError: false,
    helperText: ""
  });

  /**
   * * Get No Of Meals Dialog Close
   */
  const handleCloseGetNoOfMealsDialogOpen = () => {
    setMeals(meals => ({
      ...meals,
      dialogOpenClose: false
    }));
  };

  const handleIsButtonClicked = () => {
    setIsButtonClicked(true);
  };

  const handleDone = () => {
    setIsButtonClicked(false);
  };

  /**
   * * Check if the user is valid for a franchise.
   */
  const checkUserIfValid = async (
    clientId,
    schedule,
    exchange_day = null,
    checkExchangeDay
  ) => {
    await providerForPost(
      checkClientValidityForAFranchise,
      {
        c: clientId,
        s: schedule
      },
      Auth.getToken()
    )
      .then(res => {
        /** Checked client belongs to a franchise or not and then the data is set */
        if (res.data && res.data.schedule) {
          if (
            checkExchangeDay &&
            (!exchange_day ||
              !parseInt(exchange_day) ||
              parseInt(exchange_day) < 0 ||
              parseInt(exchange_day) === 0 ||
              parseInt(exchange_day) >
                parseInt(res.data.schedule.no_of_exchange_workout_days))
          ) {
            setLoader(false);
            return (
              <>
                <Route
                  render={otherProps => (
                    <>
                      <Layout>
                        <NotFoundPage />
                      </Layout>
                    </>
                  )}
                />
              </>
            );
          }
          setNoOfMeals(no_of_meals => ({
            ...no_of_meals,
            no_of_meals_from_previous_schedule:
              res.data.no_of_meals_from_previous_schedule,
            no_of_meals_in_current_schedule:
              res.data.no_of_meals_in_current_schedule
          }));
          setIsLatest(res.data.isLatest);
          setActive(res.data.active);
          setScheduleData(res.data.schedule);
          setInitials(getInitials(res.data.clientData.full_name));
          setClientData(clientData => ({
            ...clientData,
            id: clientId,
            name: res.data.clientData.full_name,
            schedule: schedule
          }));
          setIsExistingSchedulePresent(true);
        } else {
          setInitials(null);
          return (
            <>
              <Route
                render={otherProps => (
                  <>
                    <Layout>
                      <NotFoundPage />
                    </Layout>
                  </>
                )}
              />
            </>
          );
        }

        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        setInitials(null);
        return (
          <>
            <Route
              render={otherProps => (
                <>
                  <Layout>
                    <NotFoundPage />
                  </Layout>
                </>
              )}
            />
          </>
        );
      });
  };

  const getClientCreds = async (client_id, schedule) => {
    await providerForGet(getOneClient + "/" + client_id, {}, auth.getToken())
      .then(res => {
        if (res.data) {
          setActive(res.data.active);
          setInitials(getInitials(res.data.full_name));
        }
        setClientData(clientData => ({
          ...clientData,
          id: client_id,
          name: res.data.full_name,
          schedule: schedule
        }));
        setIsExistingSchedulePresent(false);
        setLoader(false);
      })
      .catch(error => {
        setInitials(null);
        setClientData(clientData => ({
          ...clientData,
          id: client_id,
          name: null,
          schedule: schedule
        }));
        setIsExistingSchedulePresent(false);
        setLoader(false);
      });
  };

  useEffect(() => {
    setLoader(true);
    const urlParams = new URLSearchParams(window.location.search);
    let clientId = null;
    let clientName = null;
    let schedule = null;
    let exchange_day = urlParams.get("e_day");
    let checkExchangeDay = false;
    if (props.path === CREATEMEALS) {
      setIsCreateMealsPage(true);
      checkExchangeDay = true;
      if (!exchange_day) {
        setLoader(false);
        return (
          <>
            <Route
              render={otherProps => (
                <>
                  <Layout>
                    <NotFoundPage />
                  </Layout>
                </>
              )}
            />
          </>
        );
      }
    } else {
      setIsCreateMealsPage(false);
    }

    if (Auth.getUserInfo() === undefined || Auth.getUserInfo() === null) {
      return (
        <React.Fragment>
          {auth.clearAppStorage()}
          <Redirect
            to={{
              pathname: LOGIN
            }}
          />
        </React.Fragment>
      );
    }

    /**
     * * If the user is client then take client id from local storage
     */
    if (
      Auth.getUserInfo().role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
    ) {
      let isError = false;
      /*
       * * Set data for client
       */
      clientId = Auth.getUserInfo().id;
      clientName = Auth.getUserInfo().full_name;

      /*
       * *If the accessing user is client then there should always be an schedule id associated
       */
      schedule = urlParams.get("s");

      if (!schedule) {
        isError = true;
      } else {
        checkUserIfValid(clientId, schedule, exchange_day, checkExchangeDay);
      }
      if (clientName) {
        setInitials(getInitials(clientName));
      }

      setClientData(clientData => ({
        ...clientData,
        id: clientId,
        name: clientName,
        schedule: schedule
      }));
      setLoader(false);
      if (isError) {
        return (
          <>
            <Route
              render={otherProps => (
                <>
                  <Layout>
                    <NotFoundPage />
                  </Layout>
                </>
              )}
            />
          </>
        );
      }
    } else {
      /** Set data for users other than client */

      clientId = urlParams.get("c");
      schedule = urlParams.get("s");
      schedule = parseInt(schedule);
      /*
       * * When the schedule is not present we pass 0
       */
      if (schedule) {
        /**
         * * If schedule value is greater than 0
         */

        checkUserIfValid(clientId, schedule, exchange_day, checkExchangeDay);
      } else {
        /**
         *
         * * If schedule value is 0
         */
        getClientCreds(clientId, schedule);
      }
    }
  }, [props]);

  /**
   * * This gets the selected tab
   */
  const getSelectedTab = () => {
    /**
     * * This function finds the tab to be highlighted
     */
    const tab = navMenu.find(tab => {
      if (includes(tab.pathList, props.path)) {
        return tab;
      }
    });
    if (tab) {
      return tab.path;
    } else {
      if (
        Auth.getUserInfo().role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
      ) {
        /**
         * Redirect to some other client url maybe to clients profile
         */
      } else if (
        Auth.getUserInfo().role.name === process.env.REACT_APP_NUTRITIONIST_ROLE
      ) {
        return "/manage-clients";
      }
    }
  };

  useEffect(() => {
    if (props.path) {
      /** Get initial tab */
      setSelectedTab(getSelectedTab);
    }
  }, [props]);

  /**
   * * Handle add schedule
   */

  const createSchedule = async () => {
    setLoader(true);
    setMeals(meals => ({
      ...meals,
      dialogOpenClose: false,
      helperText: "",
      isError: false
    }));
    await providerForPost(
      createScheduleApi,
      {
        c: clientData.id,
        mealsPerDay: meals.noOfMeals
      },
      Auth.getToken(),
      { desc: "Create New Schedule", audit_log_clientid: clientData.id }
    )
      .then(res => {
        setLoader(false);
        history.push({
          pathname: BODYMASS,
          search: `?c=${clientData.id}&s=${res.data.id}`
        });
        window.location.reload();
      })
      .catch(err => {
        setLoader(false);
        setError(error => ({
          ...error,
          alert: true,
          severity: "error",
          errorMessage: err["response"]["data"]["data"]
        }));
      });
  };

  const handleAddSchedule = () => {
    setMeals(meals => ({
      ...meals,
      dialogOpenClose: true,
      helperText: "",
      isError: false,
      noOfMeals: 6
    }));
  };

  const handleChangeNoOfMealsPerDay = e => {
    let isError = false;
    let text = "";
    if (
      parseInt(e.target.value) === 0 ||
      parseInt(e.target.value) > 100 ||
      parseInt(e.target.value) < 0
    ) {
      isError = true;
      text = "Meals can be between 1 - 99";
    } else if (isEmptyString(e.target.value)) {
      isError = true;
      text = "No of meals cannot be empty";
    }
    setMeals(meals => ({
      ...meals,
      noOfMeals: e.target.value,
      isError: isError,
      helperText: text
    }));
  };

  const NavBar = props => {
    const handleTabChange = val => {
      setSelectedTab(val);
      history.push({
        pathname: val,
        search: `?c=${clientData.id}&s=${clientData.schedule}`
      });
      if (val === EXCHANGEMENU || val === CREATEMEALS) {
        window.location.reload();
      }
    };

    const TabImage = event => {};

    return (
      <div
        className={classes.root}
        id="handletabchange"
        style={{
          margin: "0.9375rem 0.9375rem 0px -40px",
          maxWidth: "calc(100% - 22.8rem)"
        }}
      >
        <StyledTabs
          value={selectedTab}
          variant="scrollable"
          scrollButtons="on"
          onChange={(event, value) => handleTabChange(value)}
        >
          {navMenu.map((tab, index) => {
            let Icon = tab.src;
            let HoverIcon = tab.hover;
            return (
              <StyledTab
                icon={
                  <img
                    onMouseOver={e => (e.currentTarget.src = HoverIcon)}
                    width="auto"
                    height="30px"
                    src={Icon}
                    alt={tab.name}
                    onClick={TabImage(tab)}
                  />
                }
                label={tab.name}
                value={tab.path}
                key={index}
              />
            );
          })}
        </StyledTabs>
      </div>
    );
  };

  const MobileNavBar = () => {
    const handleTabChange = val => {
      setSelectedTab(val);
      history.push({
        pathname: val,
        search: `?c=${clientData.id}&s=${clientData.schedule}`
      });
    };

    return (
      <div
        className={classes.root}
        id="handletabchange"
        style={{
          padding: "0px",
          marginTop: "0px",
          width: "calc(100% + 100px)",
          marginLeft: "-50px",
          backgroundColor: "#f4f8ff"
        }}
      >
        <div className={styles.mobMenuTabs}>
          <StyledTabs
            value={selectedTab}
            variant="scrollable"
            scrollButtons="on"
            onChange={(event, value) => handleTabChange(value)}
          >
            {navMenu.map((tab, index) => {
              return (
                <StyledTab label={tab.name} value={tab.path} key={index} />
              );
            })}
          </StyledTabs>
        </div>
      </div>
    );
  };

  const redirectToExchangesPage = () => {
    history.push({
      pathname: EXCHANGEMENU,
      search: `?c=${clientData.id}&s=${clientData.schedule}`
    });
  };

  const handleSnackbarClose = () => {
    setError(error => ({
      ...error,
      alert: false,
      severity: "",
      errorMessage: ""
    }));
  };

  if (auth.getToken() !== null) {
    if (initials && clientData.id && clientData.name) {
      return (
        <>
          <Route
            render={otherProps => (
              <>
                <Layout
                  isCreateMealsPage={isCreateMealsPage}
                  NavBar={NavBar}
                  redirectToExchangesPage={redirectToExchangesPage}
                  handleAddSchedule={handleAddSchedule}
                  isTabLayout={true}
                  handleIsButtonClicked={handleIsButtonClicked}
                  handleDone={handleDone}
                  isLatest={isLatest}
                  isActive={active}
                  no_of_meals={no_of_meals}
                  NameComponent={
                    <NameComponent
                      initials={initials}
                      full_name={clientData.name}
                      id={clientData.id}
                      scheduleData={scheduleData}
                    />
                  }
                >
                  <DialogForGettingMealNumbers
                    open={meals.dialogOpenClose}
                    handleClose={handleCloseGetNoOfMealsDialogOpen}
                    createSchedule={createSchedule}
                    textbox={{
                      error: meals.isError,
                      handleChange: handleChangeNoOfMealsPerDay,
                      id: "no_of_meals_per_day",
                      helperText: meals.helperText,
                      value: meals.noOfMeals
                    }}
                  />

                  {Auth.getUserInfo().role.name !==
                    process.env.REACT_APP_CLIENT_ROLE_NAME &&
                  !isExistingSchedulePresent ? (
                    <Grid container className={managePageStyles.centerAligned}>
                      <div>
                        <img
                          src={noImage}
                          className={managePageStyles.center}
                          alt="No schedule/data"
                        />
                      </div>
                    </Grid>
                  ) : isDesktop ? (
                    <div className={styles.navWrap}>
                      <Component
                        {...otherProps}
                        urlParams={ComputedMatch}
                        location={Location}
                        isActive={active}
                        scheduleData={scheduleData}
                        ref={childRef}
                        isButtonClicked={isButtonClicked}
                      />
                    </div>
                  ) : (
                    <div className={styles.mobNavWrap}>
                      <MobileNavBar />
                      <Component
                        {...otherProps}
                        urlParams={ComputedMatch}
                        location={Location}
                        isActive={active}
                        scheduleData={scheduleData}
                        isButtonClicked={isButtonClicked}
                      />
                    </div>
                  )}
                </Layout>

                <Grid>
                  <SnackBar
                    open={error.alert}
                    severity={error.severity}
                    onClose={handleSnackbarClose}
                  >
                    {error.errorMessage}
                  </SnackBar>
                </Grid>
                <Backdrop className={managePageStyles.backdrop} open={loader}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </>
            )}
          />
        </>
      );
    } else {
      if (loader) {
        return (
          <>
            <Route
              render={otherProps => (
                <>
                  <Layout>
                    <Backdrop
                      className={managePageStyles.backdrop}
                      open={loader}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </Layout>
                </>
              )}
            />
          </>
        );
      } else {
        return (
          <>
            <Route
              render={otherProps => (
                <>
                  <Layout>
                    <NotFoundPage />
                  </Layout>
                </>
              )}
            />
          </>
        );
      }
    }
  } else {
    return (
      <React.Fragment>
        {auth.clearAppStorage()}
        <Redirect
          to={{
            pathname: LOGIN
          }}
        />
      </React.Fragment>
    );
  }
};
export default RouteWithTabLayout;
