/**
 * * Public urls
 */
const apiUrl = process.env.REACT_APP_SERVER_URL;
const apiBackendUrl = `${apiUrl}`;
const loginURL = `${apiUrl}/pfc-backend/auth/local`;

/**
 *  * Super Admin/Common level urls
 */
const findMuscleGroupForAdmin = `${apiUrl}/pfc-backend/muscle-groups/findForAdmin`;
const requestOtpForgotPassURL = `${apiUrl}/pfc-backend/otps/requestotpforforgotpass`;
const requestOtpTwoFAURL = `${apiUrl}/pfc-backend/otps/requestotpfortwofa`;
const verifyOtpForgotPassURL = `${apiUrl}/pfc-backend/otps/verifyOtpforforgotpass`;
const verifyOtpForTwoFAURL = `${apiUrl}/pfc-backend/otps/verifyOtpfortwofa`;
const forgotPasswordURL = `${apiUrl}/pfc-backend/forgotpassword`;
const changePasswordURL = `${apiUrl}/pfc-backend/changepassword`;
const updateContact = `${apiUrl}/pfc-backend/contacts`;
const contacts = `${apiUrl}/pfc-backend/contacts`;
const findIfDataAvailableInContacts = `${apiUrl}/pfc-backend/findIfDataAvailable`;
const auditlogs = `${apiUrl}/pfc-backend/pfc-audit-logs`;
const verifyPassForLogs = `${apiUrl}/pfc-backend/pfc-audit-logs/verifyPasswordForLogs`;
const getDocumentListUrl = `${apiUrl}/pfc-backend/allDocumentListForSuperAdmin`;
const updateDocumentListUrl = `${apiUrl}/pfc-backend/updateAllDocumentListForSuperAdmin`;
const franchise = `${apiUrl}/pfc-backend/franchise-masters`;
const franchiseSites = `${apiUrl}/pfc-backend/franchise-sites`;
const checkIfFranchiseSiteIsUsed = `${apiUrl}/pfc-backend/checkIfFranchiseSiteIsUsed`;
const getFranchiseSitesForAdmin = `${apiUrl}/pfc-backend/get-franchise-sites`;
const franchiseListForAdmin = `${apiUrl}/pfc-backend/get-franchise-list-for-admin`;
const schoolingForAdmin = `${apiUrl}/pfc-backend/schoolings`;
const userTagForAdmin = `${apiUrl}/pfc-backend/user-tags`;
const getRelation = `${apiUrl}/pfc-backend/relations`;
const getGender = `${apiUrl}/pfc-backend/genders`;
const getGenderForFranchise = `${apiUrl}/pfc-backend/genders-for-franchise`;
const getMaritalStatus = `${apiUrl}/pfc-backend/marital-statuses`;
const getCountry = `${apiUrl}/pfc-backend/countries`;
const getAllCountries = `${apiUrl}/pfc-backend/getAllCountries`;
const addClient = `${apiUrl}/pfc-backend/add-client`;
const addAdmin = `${apiUrl}/pfc-backend/add-admin`;
const addNutritionist = `${apiUrl}/pfc-backend/add-nutritionist`;
const updateNutritionist = `${apiUrl}/pfc-backend/update-nutritionist`;
const user = `${apiUrl}/users`;
const findIfDataAvailableUser = `${apiUrl}/pfc-backend/findIfDataAvailableUser`;
const getUsers = `${apiUrl}/pfc-backend/findForUsers`;
const userLogOut = `${apiUrl}/pfc-backend/logoutUser`;
const getSuperAdminFranchiseId = `${apiUrl}/pfc-backend/getSuperAdminFranchiseId`;
const checkClientValidityForAFranchise = `${apiUrl}/pfc-backend/checkClientValidityForAFranchise`;
const createScheduleApi = `${apiUrl}/pfc-backend/createScheduleFromToday`;
const getBMofSchedule = `${apiUrl}/pfc-backend/getBMInfoOfASchedule`;
const bodyMassTable = `${apiUrl}/pfc-backend/body-mass-tables`;
const getCholestrolOfSchedule = `${apiUrl}/pfc-backend/getCholestrolOfSchedule`;
const cholesterols_url = `${apiUrl}/pfc-backend/cholesterols`;
const addUpdateSupplementData = `${apiUrl}/pfc-backend/addUpdateSupplementData`;
const addUpdateSupplementTemplateData = `${apiUrl}/pfc-backend/addUpdateSupplementTemplateData`;
const getSupplementDataOfASchedule = `${apiUrl}/pfc-backend/getSupplementDataOfASchedule`;
const checkEmailAvailable = `${apiUrl}/pfc-backend/checkEmailAvailable`;

const updateProfile = `${apiUrl}/pfc-backend/update-profile`;
const savemealInfo = `${apiUrl}/pfc-backend/savemealInfo`;
const getMenuForTodaysMeals = `${apiUrl}/pfc-backend/getMenuForTodaysMeals`;
const createMealTemplate = `${apiUrl}/pfc-backend/meal-templates/createExchangesMenusTemplates`;
const getAllReports = `${apiUrl}/pfc-backend/getAllReportsOfAClient`;
const getGenericDocument = `${apiUrl}/pfc-backend/getGenericGiveAllDocument`;
const generateGiveAllWithInserts = `${apiUrl}/pfc-backend/generateGiveAllWithInserts`;

/**
 *  * Admin/Nutritionist level urls
 */
const schoolingForFranchise = `${apiUrl}/pfc-backend/schooling-for-franchise`;
const userTagForFranchise = `${apiUrl}/pfc-backend/user-tag-for-franchise`;
//get perticular franchise data
const franchiseAdmin = `${apiUrl}/pfc-backend/get-franchise-data`;
const franchiseAdminWithPaging = `${apiUrl}/pfc-backend/get-franchise-data-with-paging`;

const supplement = `${apiUrl}/pfc-backend/supplements`;
const supplementForFranchise = `${apiUrl}/pfc-backend/supplements-for-franchise`;
const exercise = `${apiUrl}/pfc-backend/exercises`;
const exerciseForFranchise = `${apiUrl}/pfc-backend/exercises-for-franchise`;
const food = `${apiUrl}/pfc-backend/foods`;
const checkIfDataAailableForFood = `${apiUrl}/pfc-backend/checkIfDataAailable`;
const checkIfDataAailableForFoodCatAndSubCat = `${apiUrl}/pfc-backend/checkIfDataAailableForFoodCatAndSubCat`;
const checkIfSupplementDataAvailable = `${apiUrl}/pfc-backend/checkIfSupplementDataAvailable`;
const checkIfExerciseDataAvailable = `${apiUrl}/pfc-backend/checkIfExerciseDataAvailable`;
const checkIfMuscleGroupDataAvailable = `${apiUrl}/pfc-backend/checkIfMuscleGroupDataAvailable`;
const foodForFranchise = `${apiUrl}/pfc-backend/foods-for-franchise`;
const foodCategory = `${apiUrl}/pfc-backend/food-categories`;
const foodSubCategory = `${apiUrl}/pfc-backend/food-sub-categories`;
const foodSubCategoryForFranchise = `${apiUrl}/pfc-backend/food-sub-categories-for-franchise`;
const sizeWeight = `${apiUrl}/pfc-backend/size-weights`;
const sizeWeightForFranchise = `${apiUrl}/pfc-backend/size-weights-for-franchise`;
const muscleGroup = `${apiUrl}/pfc-backend/muscle-groups`;
const muscleGroupForFranchise = `${apiUrl}/pfc-backend/muscle-groups-for-franchise`;
const getRole = `${apiUrl}/users-permissions/roles`;
const getClient = `${apiUrl}/pfc-backend/getClientOfAFranchise`;
const getOneClient = `${apiUrl}/pfc-backend/user/getOneClient`;
const updateClient = `${apiUrl}/pfc-backend/contacts/updateClient/user`;
const getSingleClientData = `${apiUrl}/pfc-backend/getClientDataForAFranchise`;
const getCurrentScheduleOfClient = `${apiUrl}/pfc-backend/getcurrentscheduleofclient`;
const printPdf = `${apiUrl}/pfc-backend/cholesterols-print`;
const viewPdf = `${apiUrl}/pfc-backend/body-mass-tables-view-report`;
const downloadSupplementsPdf = `${apiUrl}/pfc-backend/supplement-schedules-report`;
const downloadSpecificMealsForTodayPdf = `${apiUrl}/pfc-backend/supplement-download-specific-meals-for-today-report`;
const downloadActualFoodForAllMealsBreakdownPdf = `${apiUrl}/pfc-backend/actual-food-for-all-meals-breakdown-report`;
const getSupplementTemplateDataForTemplate = `${apiUrl}/pfc-backend/getSupplementTemplateDataForLoadingDataIntoTemplate`;

const getGraphDataUrl = `${apiUrl}/pfc-backend/getGraphData`;
const getCholestrolGraphData = `${apiUrl}/pfc-backend/get-cholesterols-graph-data/`;
const getSupplementTemplate = `${apiUrl}/pfc-backend/supplement-templates`;
const getSupplementTemplateForFranchise = `${apiUrl}/pfc-backend/supplement-templates/findForFranchise`;
const getSupplementTemplateData = `${apiUrl}/pfc-backend/getSupplementTemplateData`;
const deleteSupplementTemplateData = `${apiUrl}/pfc-backend/deleteSupplementTemplateData`;
const getPreviousMeasurementDataUrl = `${apiUrl}/pfc-backend/getPreviousCholestrolData`;
const getPreviousBmiDataUrl = `${apiUrl}/pfc-backend/getPreviousBMIData`;
const getSingleDayBreakdownPdf = `${apiUrl}/pfc-backend/download-single-day-breakdown`;
const getAllDaysBreakdownPdf = `${apiUrl}/pfc-backend/download-all-days-breakdown`;
const getSingleMealData = `${apiUrl}/pfc-backend/getExchangeMealInfoForAExchangeDay`;
const saveAllMealsGoals = `${apiUrl}/pfc-backend/saveAllMealsGoals`;
const getAllMealInfoApi = `${apiUrl}/pfc-backend/getAllMealInfo`;
const addUpdateExerciseData = `${apiUrl}/pfc-backend/addUpdateExerciseData`;
const getDataForWorkout = `${apiUrl}/pfc-backend/getDataForWorkout`;
const getMenuForWeekMeal = `${apiUrl}/pfc-backend/getMenuForWeekMeal`;
const addUpdateExerciseTemplate = `${apiUrl}/pfc-backend/addUpdateExerciseTemplate`;
const getWorkoutTemplate = `${apiUrl}/pfc-backend/workout-templates`;
const getWorkoutTemplateForFranchise = `${apiUrl}/pfc-backend/workout-templates/findForFranchise`;
const getTemplateDataForWorkout = `${apiUrl}/pfc-backend/getTemplateDataForWorkout`;
const replicateMeal = `${apiUrl}/pfc-backend/replicateMeal`;
const replicateMealTemplate = `${apiUrl}/pfc-backend/replicateMealTemplate`;
const getClientScheduleData = `${apiUrl}/pfc-backend/schedules`;
const createExerciseReport = `${apiUrl}/pfc-backend/createExerciseReport`;
const generateShoppingList = `${apiUrl}/pfc-backend/exchange-days/shoppingList`;
const useTemplate = `${apiUrl}/pfc-backend/useTemplate`;
const saveFavoriteExercise = `${apiUrl}/pfc-backend/saveFavoriteExercise`;
const deleteWorkoutTemplateData = `${apiUrl}/pfc-backend/deleteWorkoutTemplateData`;
const getExchangesAndMenuTemplate = `${apiUrl}/pfc-backend/meal-templates/findForFranchise`;
const getAllPreviousSchedules = `${apiUrl}/pfc-backend/getAllPreviousSchedules`;
const getMealTemplate = `${apiUrl}/pfc-backend/meal-templates`;
const getClientGender = `${apiUrl}/pfc-backend/contacts/getGender`;
const duplicateScheduleData = `${apiUrl}/pfc-backend/duplicateScheduleData`;
const ctAllData = `${apiUrl}/pfc-backend/ctAllData`;
const getAllMealInfoForTemplate = `${apiUrl}/pfc-backend/meal-templates/getExchangeInfoForTemplate`;
const saveAllMealsGoalsForTemplate = `${apiUrl}/pfc-backend/meal-templates/saveAllMealsGoals`;
const getExchangeMealInfoForAExchangeDay = `${apiUrl}/pfc-backend/meal-templates/getExchangeMealInfoForAExchangeDay`;
const savemealInfoForTemplate = `${apiUrl}/pfc-backend/meal-templates/savemealInfo`;
const getAge = `${apiUrl}/pfc-backend/contacts/getAge`;
const loadFavoritesForMeals = `${apiUrl}/pfc-backend/meal-templates/loadFavoritesForMeals`;
const saveFavoritesForMeal = `${apiUrl}/pfc-backend/meal-templates/saveFavoritesForMeals`;
const deleteClientData = `${apiUrl}/pfc-backend/deleteClientData`;
const deleteExchangeMealTemplateData = `${apiUrl}/exchange-meal-templates/deleteExchangeMealTemplateData`;
const downloadGiveAllDocumentGeneric = `${apiUrl}/upload/files`;
const getShoppingListForSchedule = `${apiUrl}/pfc-backend/exchange-days/getShoppingListForSchedule`;
const getWorkoutDayInfo = `${apiUrl}/pfc-backend/workout-days`;
const nutritionistNotes = `${apiUrl}/nutritionist-notes`;
const nutritionistNotesIcon = `${apiUrl}/nutritionist-notes-icons`;
const getNutrititionistNotes = `${apiUrl}/get-nutritionist-notes`;
const addUpdateClientNotes = `${apiUrl}/client-notes-updateNotes`;
const addUpdateNutrititionistNotes = `${apiUrl}/nutritionist-notes-updateNotes`;
const clientAddNewNotesTitleLogo = `${apiUrl}/client-notes/addNewNotesTitleLogo`;
const nutritionistAddNewNotesTitleLogo = `${apiUrl}/nutritionist-notes/addNewNotesTitleLogo`;
const getClientScheduleDates = `${apiUrl}/pfc-backend/getClientScheduleDates`;
/**
 * 2FA URLs
 */
const getQRCode = `${apiUrl}/pfc-backend/getQRCode`;
const Check2FaCode = `${apiUrl}/pfc-backend/checkCode`;
const verifyPasswordUrl = `${apiUrl}/pfc-backend/verifyPassword`;
const Verify2FaCode = `${apiUrl}/pfc-backend/verifyCode`;

/**
 * API for User detail
 */
const userMe = `${apiUrl}/users/me`;
const deleteUserContact = `${apiUrl}/pfc-backend/delete-user-contact`;

/**
 *API for manage user
 */
const getUsersDataUrl = `${apiUrl}/pfc-backend/user/getUsersData`;

/**
 * client level API
 */
const getScheduleDateForClient = `${apiUrl}/pfc-backend/getScheduleDateForClient`;
const getExchangeDayClientNotes = `${apiUrl}/pfc-backend/getExchangeDayClientNotes`;
const updateTrackedStatus = `${apiUrl}/pfc-backend/updateTrackingStatus`;
const clientNotes = `${apiUrl}/client-notes`;
const clientNotesIcons = `${apiUrl}/client-notes-icons`;
const getClientNotes = `${apiUrl}/get-client-notes`;
const notes = `${apiUrl}/notes`;
const food_diary = `${apiUrl}/food-diaries`;
const saveFoodDiary = `${apiUrl}/food-diaries/save-data`;
const policyacceptstatus = `${apiUrl}/pfc-backend/policyStatusAccept`;

/**
 * GraphColor API
 */

const getCustomizationUrl = `${apiUrl}/customizations`;

export {
  loginURL,
  requestOtpForgotPassURL,
  requestOtpTwoFAURL,
  verifyOtpForgotPassURL,
  verifyOtpForTwoFAURL,
  forgotPasswordURL,
  changePasswordURL,
  updateContact,
  apiBackendUrl,
  contacts,
  findIfDataAvailableInContacts,
  auditlogs,
  verifyPassForLogs,
  getDocumentListUrl,
  updateDocumentListUrl,
  franchise,
  franchiseSites,
  checkIfFranchiseSiteIsUsed,
  getFranchiseSitesForAdmin,
  franchiseListForAdmin,
  schoolingForFranchise,
  schoolingForAdmin,
  userTagForAdmin,
  userTagForFranchise,
  getRelation,
  getGender,
  getGenderForFranchise,
  getMaritalStatus,
  getCountry,
  franchiseAdmin,
  supplement,
  supplementForFranchise,
  user,
  findIfDataAvailableUser,
  getUsers,
  getRole,
  exercise,
  exerciseForFranchise,
  food,
  checkIfDataAailableForFood,
  checkIfDataAailableForFoodCatAndSubCat,
  checkIfSupplementDataAvailable,
  checkIfExerciseDataAvailable,
  checkIfMuscleGroupDataAvailable,
  foodForFranchise,
  foodCategory,
  foodSubCategory,
  foodSubCategoryForFranchise,
  sizeWeight,
  sizeWeightForFranchise,
  muscleGroup,
  muscleGroupForFranchise,
  addClient,
  addAdmin,
  addNutritionist,
  updateNutritionist,
  getClient,
  getOneClient,
  updateClient,
  getSingleClientData,
  getQRCode,
  Check2FaCode,
  userMe,
  deleteUserContact,
  getUsersDataUrl,
  verifyPasswordUrl,
  Verify2FaCode,
  userLogOut,
  getSuperAdminFranchiseId,
  getCurrentScheduleOfClient,
  printPdf,
  viewPdf,
  downloadSupplementsPdf,
  downloadSpecificMealsForTodayPdf,
  downloadActualFoodForAllMealsBreakdownPdf,
  getGraphDataUrl,
  getCholestrolGraphData,
  getPreviousMeasurementDataUrl,
  getSingleDayBreakdownPdf,
  getAllDaysBreakdownPdf,
  getPreviousBmiDataUrl,
  checkClientValidityForAFranchise,
  createScheduleApi,
  getBMofSchedule,
  bodyMassTable,
  getCholestrolOfSchedule,
  cholesterols_url,
  addUpdateSupplementData,
  getSupplementDataOfASchedule,
  checkEmailAvailable,
  getSupplementTemplate,
  updateProfile,
  getSingleMealData,
  savemealInfo,
  addUpdateSupplementTemplateData,
  getSupplementTemplateData,
  deleteSupplementTemplateData,
  saveAllMealsGoals,
  getAllMealInfoApi,
  addUpdateExerciseData,
  getDataForWorkout,
  getMenuForTodaysMeals,
  getMenuForWeekMeal,
  replicateMeal,
  getClientScheduleData,
  createExerciseReport,
  addUpdateExerciseTemplate,
  getWorkoutTemplate,
  getTemplateDataForWorkout,
  getWorkoutTemplateForFranchise,
  generateShoppingList,
  getSupplementTemplateForFranchise,
  useTemplate,
  saveFavoriteExercise,
  deleteWorkoutTemplateData,
  getAllPreviousSchedules,
  getExchangesAndMenuTemplate,
  createMealTemplate,
  getMealTemplate,
  getClientGender,
  duplicateScheduleData,
  ctAllData,
  getAllMealInfoForTemplate,
  saveAllMealsGoalsForTemplate,
  getExchangeMealInfoForAExchangeDay,
  savemealInfoForTemplate,
  getAge,
  loadFavoritesForMeals,
  saveFavoritesForMeal,
  replicateMealTemplate,
  deleteClientData,
  getAllCountries,
  deleteExchangeMealTemplateData,
  getAllReports,
  downloadGiveAllDocumentGeneric,
  getGenericDocument,
  generateGiveAllWithInserts,
  getScheduleDateForClient,
  getSupplementTemplateDataForTemplate,
  getShoppingListForSchedule,
  getExchangeDayClientNotes,
  getWorkoutDayInfo,
  updateTrackedStatus,
  clientNotes,
  nutritionistNotes,
  clientNotesIcons,
  getCustomizationUrl,
  nutritionistNotesIcon,
  getNutrititionistNotes,
  getClientNotes,
  franchiseAdminWithPaging,
  addUpdateClientNotes,
  addUpdateNutrititionistNotes,
  clientAddNewNotesTitleLogo,
  nutritionistAddNewNotesTitleLogo,
  findMuscleGroupForAdmin,
  notes,
  food_diary,
  saveFoodDiary,
  policyacceptstatus,
  getClientScheduleDates
};
