// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClientBodyMassNotes_notesSection__1EGQx {\n  margin-top: 1.25rem !important;\n}\n.ClientBodyMassNotes_cancelSaveButton__h2M83 {\n  margin-top: 1.25rem !important;\n  justify-content: \"flex-end\";\n  margin-left: \"auto\";\n  display: \"flex\";\n}\n\n@media only screen and (min-width: 1280px) {\n  .ClientBodyMassNotes_backIcon__9LGG5 {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/containers/ClientBodyMassNotes/ClientBodyMassNotes.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;AACA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE;IACE,6BAA6B;IAC7B,6BAA6B;EAC/B;AACF","sourcesContent":[".notesSection {\n  margin-top: 1.25rem !important;\n}\n.cancelSaveButton {\n  margin-top: 1.25rem !important;\n  justify-content: \"flex-end\";\n  margin-left: \"auto\";\n  display: \"flex\";\n}\n\n@media only screen and (min-width: 1280px) {\n  .backIcon {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notesSection": "ClientBodyMassNotes_notesSection__1EGQx",
	"cancelSaveButton": "ClientBodyMassNotes_cancelSaveButton__h2M83",
	"backIcon": "ClientBodyMassNotes_backIcon__9LGG5"
};
export default ___CSS_LOADER_EXPORT___;
