import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { NameComponent, NotFoundPage } from "../../components";
import { Button, Divider } from "@material-ui/core";
import { getInitials, useStyles } from "../../utils";
import { useHistory } from "react-router-dom";
import { BODYMASS } from "../../paths";

const localStyles = makeStyles(theme => ({
  addNewScheduleButton: {
    display: "flex",
    marginTop: "2%",
    float: "right"
  },
  middleDivider: {
    marginTop: "2%"
  }
}));

const ManageSchedule = props => {
  const classes = useStyles();
  const localClasses = localStyles();
  const history = useHistory();

  const [clientData, setClientData] = useState({
    id: null,
    name: null,
    schedule: null
  });
  const [initials, setInitials] = useState(null);
  const [isDataPresent, setIsDataPresent] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get("c");
    const schedule = urlParams.get("s");
    const clientName = urlParams.get("n");
    if (clientName) {
      setInitials(getInitials(clientName));
    }

    setClientData(clientData => ({
      ...clientData,
      id: clientId,
      name: clientName,
      schedule: schedule
    }));
    if (schedule) {
      setIsDataPresent(true);
    } else {
      setIsDataPresent(false);
    }
  }, []);

  const handleAddSchedule = () => {
    history.push({
      pathname: BODYMASS,
      search: `?c=${clientData.id}`
    });
  };

  return (
    <div className={classes.root}>
      {clientData.id && clientData.name && initials ? (
        <>
          <Grid container spacing={3}>
            <NameComponent
              initials={initials}
              full_name={clientData.name}
              id={clientData.id}
            />
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                className={localClasses.addNewScheduleButton}
                onClick={() => {
                  handleAddSchedule();
                }}
              >
                Add Schedule
              </Button>
            </Grid>
          </Grid>
          <Grid item className={localClasses.middleDivider}>
            <Divider variant="middle" />
          </Grid>
          <Grid container className={classes.centerAligned}>
            {isDataPresent ? (
              <div>No Existing Schedule, Please create one</div>
            ) : null}
          </Grid>
        </>
      ) : (
        <NotFoundPage />
      )}
    </div>
  );
};

export default ManageSchedule;
