const MaritalStatusSchema = {
  maritalStatusName: {
    label: "Name",
    id: "maritalStatusName",
    autoComplete: "Name",
    required: true,
    placeholder: "Name",
    autoFocus: true,
    type: "text",
    validations: {
      required: {
        value: "true",
        message: "Name is required"
      }
    }
  }
};
export default MaritalStatusSchema;
