import React from "react";
import { TextField } from "@material-ui/core";
import styles from "./Input.module.css";
import clsx from "clsx";

const Input = props => {
  return (
    <TextField
      required={props.required ? props.required : false}
      autoFocus={props.autoFocus ? props.autoFocus : false}
      fullWidth
      variant={props.variant ? props.variant : "outlined"}
      error={props.error ? props.error : false}
      {...props}
      className={
        props.className
          ? clsx(styles.ReactInput, props.className)
          : styles.ReactInput
      }
    />
  );
};

export default Input;
