// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClientMeasurementNotes_notesSection__3FMwS {\n  margin-top: 1.25rem !important;\n}\n.ClientMeasurementNotes_cancelSaveButton__1YhIo {\n  margin-top: 1.25rem !important;\n  justify-content: \"flex-end\";\n  margin-left: \"auto\";\n  display: \"flex\";\n}\n@media only screen and (min-width: 1280px) {\n  .ClientMeasurementNotes_backIcon__ECcEp {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/containers/ClientMeasurementNotes/ClientMeasurementNotes.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;AACA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE;IACE,6BAA6B;IAC7B,6BAA6B;EAC/B;AACF","sourcesContent":[".notesSection {\n  margin-top: 1.25rem !important;\n}\n.cancelSaveButton {\n  margin-top: 1.25rem !important;\n  justify-content: \"flex-end\";\n  margin-left: \"auto\";\n  display: \"flex\";\n}\n@media only screen and (min-width: 1280px) {\n  .backIcon {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notesSection": "ClientMeasurementNotes_notesSection__3FMwS",
	"cancelSaveButton": "ClientMeasurementNotes_cancelSaveButton__1YhIo",
	"backIcon": "ClientMeasurementNotes_backIcon__ECcEp"
};
export default ___CSS_LOADER_EXPORT___;
