import React, { useEffect, useState } from "react";
import styles from "./AddEditFood.module.css";
import addFoodForm from "../FoodSchema";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  LinearProgress
} from "@material-ui/core";
import {
  Auth,
  AutoCompleteDropDown,
  Input,
  SnackBar,
  DarkBlueButton,
  BorderLessButton
} from "../../../components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { get } from "lodash";
import {
  checkAllKeysPresent,
  checkEmpty,
  getListOfKeysNotPresent,
  setErrors
} from "../../../utils";
import SaveIcon from "@material-ui/icons/Save";
import { FOOD } from "../../../paths";
import { providerForGet, providerForPost, providerForPut } from "../../../api";
import {
  food,
  foodCategory,
  foodSubCategory,
  franchise,
  sizeWeight
} from "../../../constants";
import {
  ERROR_MESSAGE,
  SAVE_MESSAGE,
  UPDATE_MESSAGE
} from "../../../constants/genericConstants";
import useStyles from "../../../utils/Styles/ManagePageStyles";
import DialogBox from "../../../components/DialogBox/DialogBox";

const AddEditFood = props => {
  const history = useHistory();
  const auth = Auth.getToken();
  const userInfo = Auth.getUserInfo();
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  let propData = props["location"]["state"];
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    franchiseList: [],
    foodCategoryList: [],
    foodSubCategoryList: [],
    sizeWeightList: [],
    alert: false,
    severity: "success",
    errorMessage: "",
    /** This is when we return from edit page */
    rowData: propData ? propData : [],
    editData: propData ? (propData["id"] ? true : false) : false,
    editDataId: propData ? propData["id"] : null
  });
  const [isAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_ADMIN_ROLE_NAME ? true : false
  );
  const [isSuperAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
      ? true
      : false
  );
  const foodName = "foodName";
  const unitNumber = "unitNumber";
  const caloriesNumber = "caloriesNumber";
  const proteinsNumber = "proteinsNumber";
  const fatsNumber = "fatsNumber";
  const carbsNumber = "carbsNumber";
  const franchiseName = "franchiseName";
  const foodCategoryName = "foodCategoryName";
  const foodSubCategoryName = "foodSubCategoryName";
  const sizeWeightName = "sizeWeightName";

  /**Api call for getting autocomplete list itmes */
  const getListData = async () => {
    setLoader(true);
    let body = {};
    await providerForGet(franchise, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          franchiseList: res.data.data
        }));
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });

    if (isAdmin || isSuperAdmin) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [franchiseName]: Auth.getUserInfo().franchise
            ? Auth.getUserInfo().franchise.id
            : null
        }
      }));
    }

    await providerForGet(foodCategory, { _sort: "name:asc" }, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          foodCategoryList: res.data.data
        }));
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });

    await providerForGet(sizeWeight, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          sizeWeightList: res.data.data
        }));
        setLoader(false);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });

    if (formState.editData === true) {
      let editId = {
        id: formState.editDataId,
        desc: `${userInfo.full_name} accessed a food item for editing`
      };
      await providerForGet(food, editId, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              foodName: res.data.data[0].name,
              unitNumber: res.data.data[0].unit,
              caloriesNumber: res.data.data[0].calories,
              proteinsNumber: res.data.data[0].proteins,
              fatsNumber: res.data.data[0].fats,
              carbsNumber: res.data.data[0].carbs,
              franchiseName: res.data.data[0].franchise
                ? res.data.data[0].franchise.id
                : null,
              foodCategoryName: res.data.data[0].food_category
                ? res.data.data[0].food_category.id
                : null,
              foodSubCategoryName: res.data.data[0].food_sub_category
                ? res.data.data[0].food_sub_category.id
                : null,
              sizeWeightName: res.data.data[0].portion_unit
                ? res.data.data[0].portion_unit.id
                : null
            }
          }));
          setLoader(false);
        })
        .catch(err => {
          console.log("err", err);
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    getListData();
  }, []);

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: ""
    }));
  };

  const handleChange = e => {
    e.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));
    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const fetchFoodSubCategoryData = async () => {
    if (
      formState.values.hasOwnProperty(foodCategoryName) &&
      formState.values[foodCategoryName] !== null &&
      formState.values[foodCategoryName] !== undefined &&
      formState.values[foodCategoryName] !== ""
    ) {
      let body = {
        food_category: formState.values[foodCategoryName]
      };
      await providerForGet(foodSubCategory, body, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            foodSubCategoryList: res.data.data
          }));
          setLoader(false);
        })
        .catch(err => {
          console.log("err", err);
          setLoader(false);
        });
    }
  };

  /** This gets data into food sub category, when we change the food category */
  useEffect(() => {
    if (
      formState.values.hasOwnProperty(foodCategoryName) &&
      formState.values[foodCategoryName] !== null &&
      formState.values[foodCategoryName] !== undefined
    ) {
      fetchFoodSubCategoryData();
    }
  }, [formState.values[foodCategoryName]]);

  const clearFoodSubCategoryData = () => {
    setFormState(formState => ({
      ...formState,
      foodSubCategoryList: []
    }));
    delete formState.values[foodSubCategory];
  };

  const handleChangeAutoComplete = (eventName, event, value) => {
    if (value !== null) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: value.id
        },
        touched: {
          ...formState.touched,
          [eventName]: true
        }
      }));
      if (formState.errors.hasOwnProperty(eventName)) {
        delete formState.errors[eventName];
      }
      if (eventName === foodCategoryName) {
        fetchFoodSubCategoryData();
      }
    } else {
      if (eventName === franchiseName) {
        if (isAdmin) {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              [franchiseName]: Auth.getUserInfo().franchise.id
            }
          }));
        } else {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              [franchiseName]: null
            }
          }));
        }
      } else {
        if (eventName === foodCategoryName) {
          clearFoodSubCategoryData();
        }
        delete formState.values[eventName];
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [eventName]: null
          }
        }));
      }
    }
  };

  const hasError = field => (formState.errors[field] ? true : false);

  const handleSubmit = () => {
    setLoader(true);
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      addFoodForm
    );
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, addFoodForm);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(formState.values, addFoodForm);
      formState.errors = setErrors(formState.values, addFoodForm);
    }
    if (isValid) {
      let isGlobal = false;
      if (isSuperAdmin) {
        if (!formState.values.franchiseName) {
          isGlobal = true;
        }
      }

      /**api call from here */
      let postData = {
        name: formState.values.foodName,
        unit: formState.values.unitNumber,
        calories: formState.values.caloriesNumber,
        proteins: formState.values.proteinsNumber,
        fats: formState.values.fatsNumber,
        carbs: formState.values.carbsNumber,
        franchise: formState.values.franchiseName,
        food_category: formState.values.foodCategoryName,
        food_sub_category: formState.values.foodSubCategoryName,
        portion_unit: formState.values.sizeWeightName,
        isGlobal: isGlobal
      };
      if (formState.editData === true) {
        /**Put api call (Update method)*/
        providerForPut(food, formState.editDataId, postData, auth, {
          desc: `${userInfo.full_name} updated the food item ${formState.values.foodName}`
        })
          .then(res => {
            let successMessage = {
              successMessage: "Food " + res.data.name + UPDATE_MESSAGE
            };
            setLoader(false);
            history.push(FOOD, successMessage);
          })
          .catch(err => {
            setFormState(formState => ({
              ...formState,
              alert: true,
              severity: "error",
              errorMessage: ERROR_MESSAGE + err
            }));
            setOpenDialog(false);
            setLoader(false);
          });
      } else {
        /**Post api call (Save method)*/
        providerForPost(food, postData, auth, {
          desc: `${userInfo.full_name} added a new food item ${formState.values.foodName}`
        })
          .then(res => {
            let successMessage = {
              successMessage: "Food " + res.data.name + SAVE_MESSAGE
            };
            history.push(FOOD, successMessage);
            setLoader(false);
          })
          .catch(err => {
            console.log("error", err);
            setFormState(formState => ({
              ...formState,
              alert: true,
              severity: "error",
              errorMessage: ERROR_MESSAGE + err
            }));
            setOpenDialog(false);
            setLoader(false);
          });
      }
    } else {
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
      setLoader(false);
    }
  };

  const checkIfFranchiseSelected = () => {
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      addFoodForm
    );
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, addFoodForm);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(formState.values, addFoodForm);
      formState.errors = setErrors(formState.values, addFoodForm);
    }
    if (isValid) {
      if (formState.values.franchiseName) {
        handleSubmit();
      } else {
        setOpenDialog(true);
      }
    } else {
      setFormState(formState => ({
        ...formState
      }));
    }
  };

  const handleCancel = () => {
    history.push(FOOD);
  };
  return (
    <div>
      {loader ? (
        <LinearProgress />
      ) : (
        <>
          <Grid item xs={12} className={styles.title}>
            {formState.editData === true ? (
              <Typography variant="h4" className={classes.titleText}>
                Edit Food
              </Typography>
            ) : (
              <Typography variant="h4" className={classes.titleText}>
                Add Food
              </Typography>
            )}
          </Grid>
          <Grid>
            <SnackBar
              open={formState.alert}
              severity={formState.severity}
              onClose={handleSnackbarClose}
            >
              {formState.errorMessage}
            </SnackBar>
          </Grid>
          <Grid item xs={12} className={classes.formgrid}>
            <Card className={classes.editCard}>
              <CardContent className={classes.editCardContent}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFoodForm[foodName], "label")}
                      name={foodName}
                      value={formState.values[foodName] || ""}
                      error={hasError(foodName)}
                      placeholder={get(addFoodForm[foodName], "placeholder")}
                      variant="outlined"
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(foodName)
                          ? formState.errors[foodName].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFoodForm[unitNumber], "label")}
                      name={unitNumber}
                      value={formState.values[unitNumber] || ""}
                      error={hasError(unitNumber)}
                      placeholder={get(addFoodForm[unitNumber], "placeholder")}
                      variant="outlined"
                      type={get(addFoodForm[unitNumber], "type")}
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(unitNumber)
                          ? formState.errors[unitNumber].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFoodForm[caloriesNumber], "label")}
                      name={caloriesNumber}
                      value={formState.values[caloriesNumber] || ""}
                      error={hasError(caloriesNumber)}
                      placeholder={get(
                        addFoodForm[caloriesNumber],
                        "placeholder"
                      )}
                      variant="outlined"
                      type={get(addFoodForm[caloriesNumber], "type")}
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(caloriesNumber)
                          ? formState.errors[caloriesNumber].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFoodForm[proteinsNumber], "label")}
                      name={proteinsNumber}
                      value={formState.values[proteinsNumber] || ""}
                      error={hasError(proteinsNumber)}
                      placeholder={get(
                        addFoodForm[proteinsNumber],
                        "placeholder"
                      )}
                      variant="outlined"
                      type={get(addFoodForm[proteinsNumber], "type")}
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(proteinsNumber)
                          ? formState.errors[proteinsNumber].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFoodForm[fatsNumber], "label")}
                      name={fatsNumber}
                      value={formState.values[fatsNumber] || ""}
                      error={hasError(fatsNumber)}
                      placeholder={get(addFoodForm[fatsNumber], "placeholder")}
                      variant="outlined"
                      type={get(addFoodForm[fatsNumber], "type")}
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(fatsNumber)
                          ? formState.errors[fatsNumber].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFoodForm[carbsNumber], "label")}
                      name={carbsNumber}
                      value={formState.values[carbsNumber] || ""}
                      error={hasError(carbsNumber)}
                      placeholder={get(addFoodForm[carbsNumber], "placeholder")}
                      variant="outlined"
                      type={get(addFoodForm[carbsNumber], "type")}
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(carbsNumber)
                          ? formState.errors[carbsNumber].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AutoCompleteDropDown
                      options={formState.franchiseList}
                      getOptionLabel={option => option.franchise_name}
                      id={get(addFoodForm[franchiseName], "id")}
                      value={
                        formState.franchiseList[
                          formState.franchiseList.findIndex(function (item, i) {
                            return item.id === formState.values[franchiseName];
                          })
                        ] || null
                      }
                      disabled={isAdmin}
                      onChange={(event, value) => {
                        handleChangeAutoComplete(franchiseName, event, value);
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          disabled={isAdmin}
                          id={get(addFoodForm[franchiseName], "id")}
                          label={get(addFoodForm[franchiseName], "label")}
                          variant="outlined"
                          error={hasError(franchiseName)}
                          helperText={
                            hasError(franchiseName)
                              ? formState.errors[franchiseName].map(error => {
                                  return error + " ";
                                })
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AutoCompleteDropDown
                      options={formState.foodCategoryList}
                      getOptionLabel={option => option.name}
                      id={get(addFoodForm[foodCategoryName], "id")}
                      value={
                        formState.foodCategoryList[
                          formState.foodCategoryList.findIndex(function (
                            item,
                            i
                          ) {
                            return (
                              item.id === formState.values[foodCategoryName]
                            );
                          })
                        ] || null
                      }
                      onChange={(event, value) => {
                        handleChangeAutoComplete(
                          foodCategoryName,
                          event,
                          value
                        );
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          required
                          id={get(addFoodForm[foodCategoryName], "id")}
                          label={get(addFoodForm[foodCategoryName], "label")}
                          variant="outlined"
                          error={hasError(foodCategoryName)}
                          helperText={
                            hasError(foodCategoryName)
                              ? formState.errors[foodCategoryName].map(
                                  error => {
                                    return error + " ";
                                  }
                                )
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AutoCompleteDropDown
                      options={formState.foodSubCategoryList}
                      getOptionLabel={option => option.name}
                      id={get(addFoodForm[foodSubCategoryName], "id")}
                      value={
                        formState.foodSubCategoryList[
                          formState.foodSubCategoryList.findIndex(function (
                            item,
                            i
                          ) {
                            return (
                              item.id === formState.values[foodSubCategoryName]
                            );
                          })
                        ] || null
                      }
                      onChange={(event, value) => {
                        handleChangeAutoComplete(
                          foodSubCategoryName,
                          event,
                          value
                        );
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          required
                          id={get(addFoodForm[foodSubCategoryName], "id")}
                          label={get(addFoodForm[foodSubCategoryName], "label")}
                          variant="outlined"
                          error={hasError(foodSubCategoryName)}
                          helperText={
                            hasError(foodSubCategoryName)
                              ? formState.errors[foodSubCategoryName].map(
                                  error => {
                                    return error + " ";
                                  }
                                )
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AutoCompleteDropDown
                      options={formState.sizeWeightList}
                      getOptionLabel={option => option.name}
                      id={get(addFoodForm[sizeWeightName], "id")}
                      value={
                        formState.sizeWeightList[
                          formState.sizeWeightList.findIndex(function (
                            item,
                            i
                          ) {
                            return item.id === formState.values[sizeWeightName];
                          })
                        ] || null
                      }
                      onChange={(event, value) => {
                        handleChangeAutoComplete(sizeWeightName, event, value);
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          required
                          id={get(addFoodForm[sizeWeightName], "id")}
                          label={get(addFoodForm[sizeWeightName], "label")}
                          variant="outlined"
                          error={hasError(sizeWeightName)}
                          helperText={
                            hasError(sizeWeightName)
                              ? formState.errors[sizeWeightName].map(error => {
                                  return error + " ";
                                })
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} className={classes.actionButtonGrid}>
            <DarkBlueButton
              type="submit"
              data-testid="ok-button"
              onClick={checkIfFranchiseSelected}
            >
              <SaveIcon />
              &nbsp; Save
            </DarkBlueButton>

            <BorderLessButton onClick={handleCancel}>cancel</BorderLessButton>
          </Grid>

          <DialogBox
            open={openDialog}
            title="Save Food"
            buttonCancel="Cancel"
            handleCancel={() => setOpenDialog(false)}
            skipOk={false}
            buttonOk={"Ok"}
            handleOk={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Typography gutterBottom>
                  Since you haven't chosen a franchise, this entry will be seen
                  by all the franchises. Are you sure you want to go on?
                </Typography>
              </Grid>
            </Grid>
          </DialogBox>
        </>
      )}
    </div>
  );
};

AddEditFood.propTypes = {};

AddEditFood.defaultProps = {};

export default AddEditFood;
