import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Grid,
  makeStyles,
  useMediaQuery
} from "@material-ui/core";
import {
  Input,
  SnackBar,
  SupplementGrid,
  DarkBlueButton,
  BorderLessButton,
  HeadingOne
} from "../../components";
import { useTheme } from "@material-ui/core/styles";
import { providerForGet, providerForPost } from "../../api";
import {
  supplementForFranchise,
  addUpdateSupplementTemplateData,
  getSupplementTemplateForFranchise,
  getSupplementTemplateDataForTemplate
} from "../../constants";
import auth from "../../components/Auth";
import addSupplementForm from "./SupplementTemplateSchema";
import { get } from "lodash";
import {
  checkAllKeysPresent,
  checkEmpty,
  getListOfKeysNotPresent,
  setErrors
} from "../../utils";
import {
  ERROR_MESSAGE,
  SAVE_MESSAGE,
  UPDATE_MESSAGE
} from "../../constants/genericConstants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SUPPLEMENTTEMPLATE } from "../../paths";
import { Prompt } from "react-router-dom";

const AddEditSupplementScheduleTemplate = props => {
  let propData = props["location"]["state"];
  const history = useHistory();
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1
    },
    summaryButtonBox: {
      display: "flex",
      marginBottom: "3%"
    },
    loadTemplate: {
      marginLeft: "auto"
    },
    duplicateReplicateStyle: {
      display: "flex",
      justifyContent: "flex-end",
      marginLeft: "auto",
      marginBottom: "1.25rem"
    },
    margin: {
      margin: theme.spacing(1)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    addSupplementStyle: {
      padding: "15px 0px 52px 0px"
    }
  }));

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  const suppDummyData = {
    id: null,
    morn: 0,
    pre: 0,
    post: 0,
    eve: 0,
    total: 0,
    PersonalNotes: ""
  };

  const [suppForm, setSuppForm] = useState({
    suppData: {
      1: suppDummyData /** This is the initial data */
    },
    scheduleId: null,
    clientId: null,
    errors: {},
    checked: {}
  });

  const suppTimeArr = ["morn", "pre", "post", "eve"];

  const [statusVariable, setStatusVariable] = useState({
    isError: false,
    isOpenSnackBar: false,
    successErrorMessage: "",
    saveText: "",
    dummyTime: new Date(),
    savedTime: null,
    isSaving: false
  });
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    franchiseList: [],
    alert: false,
    severity: "success",
    errorMessage: "",
    /** This is when we return from edit page */
    rowData: propData ? propData : [],
    editData: propData ? (propData["id"] ? true : false) : false,
    editDataId: propData ? propData["id"] : null
  });
  const user = auth.getUserInfo();
  const supplementName = "supplementName";

  const [noOfSupplement] = useState(1);
  const [loader, setLoader] = useState(false);

  const [supplementData, setSupplementData] = useState([]);

  const getInitialSupplementData = async () => {
    if (formState.editData === true) {
      let editId = {
        id: formState.editDataId,
        desc: "Get supplement favorite data for editing"
      };
      await providerForGet(
        getSupplementTemplateDataForTemplate,
        editId,
        auth.getToken()
      )
        .then(res => {
          let obj = {};
          let resObj = res.data;
          if (resObj.length) {
            resObj.map((data, i) => {
              obj[i + 1] = {
                id: data.supplement_id ? data.supplement_id.id : null,
                morn: data.Morn ? data.Morn : null,
                pre: data.Pre ? data.Pre : null,
                post: data.Post ? data.Post : null,
                eve: data.Eve ? data.Eve : null,
                total: data.Total ? data.Total : null,
                supp_notes: data.supplement_id
                  ? data.supplement_id.description
                  : null,
                PersonalNotes: data.personal_notes ? data.personal_notes : null
              };
              return null;
            });
          } else {
            obj = {
              1: suppDummyData /** This is the initial data */
            };
          }
          setSuppForm(suppForm => ({
            ...suppForm,
            suppData: obj
          }));
          /**
           *
           */
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              supplementName: res.data[0]
                ? res.data[0].supplement_template_id
                  ? res.data[0].supplement_template_id.template_name
                  : null
                : null
            }
          }));
          setLoader(false);
        })
        .catch(err => {
          console.log("err", err);
          setLoader(false);
        });

      await providerForGet(
        getSupplementTemplateForFranchise,
        editId,
        auth.getToken()
      )
        .then(res => {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              supplementName: res.data.data[0]
                ? res.data.data[0].template_name
                : null
            }
          }));
          setLoader(false);
        })
        .catch(err => {
          console.log("error", err);
        });
    }

    setLoader(true);

    await providerForGet(
      supplementForFranchise,
      {
        pageSize: -1,
        _sort: "name:asc"
      },
      auth.getToken()
    )
      .then(res => {
        setSupplementData(res.data);
        setLoader(false);
      })
      .catch(error => {});
  };

  useEffect(() => {
    getInitialSupplementData();
  }, []);

  const localClasses = useStyles();

  const hasError = field => (formState.errors[field] ? true : false);

  const handleChange = e => {
    e.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));
    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const handleSupplementChange = (
    isAutoComplete,
    isTextBox,
    numbers,
    eventName,
    event,
    value,
    suppNumber
  ) => {
    if (isAutoComplete) {
      if (value) {
        if (suppForm.suppData[suppNumber]) {
          setSuppForm(suppForm => ({
            ...suppForm,
            suppData: {
              ...suppForm.suppData,
              [suppNumber]: {
                ...suppForm.suppData[suppNumber],
                id: value.id,
                supp_notes: value.description
              }
            }
          }));
        } else {
          setSuppForm(suppForm => ({
            ...suppForm,
            suppData: {
              ...suppForm.suppData,
              [suppNumber]: {
                ...suppForm.suppData[suppNumber],
                id: value.id,
                morn: 0,
                pre: 0,
                post: 0,
                eve: 0,
                total: 0,
                supp_notes: value.description,
                PersonalNotes: ""
              }
            }
          }));
        }
      } else {
        setSuppForm(suppForm => ({
          ...suppForm,
          suppData: {
            ...suppForm.suppData,
            [suppNumber]: {
              ...suppForm.suppData[suppNumber],
              id: null,
              morn: 0,
              pre: 0,
              post: 0,
              eve: 0,
              total: 0,
              supp_notes: "",
              PersonalNotes: ""
            }
          }
        }));
      }
    } else if (isTextBox) {
      setSuppForm(suppForm => ({
        ...suppForm,
        suppData: {
          ...suppForm.suppData,
          [suppNumber]: {
            ...suppForm.suppData[suppNumber],
            PersonalNotes: value
          }
        }
      }));
    } else if (numbers) {
      let total = 0;
      suppTimeArr.map(st => {
        if (st !== eventName) {
          total = total + suppForm.suppData[suppNumber][st];
        } else {
          if (parseInt(value)) {
            total = total + parseInt(value);
          } else {
            total = total + 0;
          }
        }
        return null;
      });
      if (parseInt(value)) {
        setSuppForm(suppForm => ({
          ...suppForm,
          suppData: {
            ...suppForm.suppData,
            [suppNumber]: {
              ...suppForm.suppData[suppNumber],
              [eventName]: parseInt(value),
              total: total
            }
          }
        }));
      } else {
        setSuppForm(suppForm => ({
          ...suppForm,
          suppData: {
            ...suppForm.suppData,
            [suppNumber]: {
              ...suppForm.suppData[suppNumber],
              [eventName]: 0
            }
          }
        }));
      }
    }
  };

  const checkIfDataProper = (isForDelete = false) => {
    let isValid = true;
    if (isForDelete) {
      let arr = Object.keys(suppForm.suppData);
      if (arr.length === 1 && !suppForm.suppData[arr[0]].id) {
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      let arr = Object.keys(suppForm.suppData);
      arr.map(i => {
        if (!suppForm.suppData[i].id) {
          isValid = false;
        }
        return null;
      });
    }
    return isValid;
  };

  const handleAddSupplement = () => {
    if (checkIfDataProper()) {
      let highest = Object.keys(suppForm.suppData)
        .sort(function (a, b) {
          return a - b;
        })
        .pop();
      setSuppForm(suppForm => ({
        ...suppForm,
        suppData: {
          ...suppForm.suppData,
          [parseInt(highest) + 1]: suppDummyData
        }
      }));
    }
  };

  const handleDeleteSupplement = event => {
    let arr = Object.keys(suppForm.suppData);
    if (arr.length === 1) {
      setSuppForm(suppForm => ({
        ...suppForm,
        suppData: {
          ...suppForm.suppData,
          [event]: {
            ...suppForm.suppData[event],
            id: null,
            morn: 0,
            pre: 0,
            post: 0,
            eve: 0,
            total: 0,
            supp_notes: "",
            PersonalNotes: ""
          }
        }
      }));
    } else {
      delete suppForm.suppData[event];

      setSuppForm(suppForm => ({
        ...suppForm
      }));
    }
  };
  const savedata = async (flag = false) => {
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      addSupplementForm
    );
    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, addSupplementForm);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        addSupplementForm
      );
      formState.errors = setErrors(formState.values, addSupplementForm);
    }
    let suppFavDataPresent = true;

    // if only one element present, then check if its Id is null
    if (
      Object.keys(suppForm.suppData).length === 1 &&
      Object.keys(suppForm.suppData)[0] === "1" &&
      !suppForm.suppData[1].id
    ) {
      suppFavDataPresent = false;
    }

    if (isValid) {
      if (suppFavDataPresent) {
        /**
         * *Checking if supplement data is present
         */
        setLoader(true);

        if (formState.editData === true) {
          /**Put api call (Update method)*/

          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isSaving: true
          }));
          setLoader(true);
          await providerForPost(
            addUpdateSupplementTemplateData,
            {
              data: suppForm.suppData,
              st: formState.editDataId,
              templateName: formState.values.supplementName,
              franchiseId: user.franchise ? user.franchise.id : null
            },
            auth.getToken(),
            {
              desc: "Update Supplement Template"
            }
          )
            .then(res => {
              setStatusVariable(statusVariable => ({
                ...statusVariable,
                isSaving: false,
                savedTime: new Date()
              }));
              if (flag) {
                setFormState(formState => ({
                  ...formState,
                  alert: true,
                  severity: "success",
                  errorMessage:
                    "Supplement Favorite " +
                    res.data.template_name +
                    UPDATE_MESSAGE
                }));
              }
              setLoader(false);
            })
            .catch(error => {
              if (
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                setStatusVariable(statusVariable => ({
                  ...statusVariable,
                  isSaving: false,
                  isOpenSnackBar: true,
                  isError: "error",
                  successErrorMessage:
                    ERROR_MESSAGE + error.response.data.message
                }));
              } else {
                setStatusVariable(statusVariable => ({
                  ...statusVariable,
                  isSaving: false
                }));
              }
              setLoader(false);
            });
        } else {
          /**Post api call (Save method)*/

          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isSaving: true
          }));
          setLoader(true);
          await providerForPost(
            addUpdateSupplementTemplateData,
            {
              data: suppForm.suppData,
              st: null,
              templateName: formState.values.supplementName,
              franchiseId: user.franchise ? user.franchise.id : null
            },
            auth.getToken(),
            {
              desc: "Add Supplement Template"
            }
          )
            .then(res => {
              setStatusVariable(statusVariable => ({
                ...statusVariable,
                isSaving: false,
                savedTime: new Date()
              }));
              if (flag) {
                setFormState(formState => ({
                  ...formState,
                  alert: true,
                  severity: "success",
                  errorMessage:
                    "Supplement Favorite " +
                    res.data.template_name +
                    SAVE_MESSAGE
                }));
              }
              setFormState(formState => ({
                ...formState,
                editData: true,
                editDataId: res.data.id
              }));
              setLoader(false);
            })
            .catch(error => {
              if (
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                setStatusVariable(statusVariable => ({
                  ...statusVariable,
                  isSaving: false,
                  isOpenSnackBar: true,
                  isError: "error",
                  successErrorMessage:
                    ERROR_MESSAGE + error.response.data.message
                }));
              } else {
                setStatusVariable(statusVariable => ({
                  ...statusVariable,
                  isSaving: false
                }));
              }
              setLoader(false);
            });
        }
      } else {
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          isOpenSnackBar: true,
          isError: "error",
          successErrorMessage: "No Supplement Selected "
        }));
      }
    } else {
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
      setLoader(false);
    }
  };

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: "",
      severity: "success"
    }));
    setStatusVariable(statusVariable => ({
      ...statusVariable,
      isSaving: false,
      isOpenSnackBar: false,
      isError: "",
      successErrorMessage: ""
    }));
  };

  const handleCancel = () => {
    history.push(SUPPLEMENTTEMPLATE);
  };

  return (
    <>
      <Prompt
        message={(location, action) => {
          savedata();
        }}
      />

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {formState.editData ? (
            <HeadingOne>Edit Supplement Favorite</HeadingOne>
          ) : (
            <HeadingOne>Add Supplement Favorite</HeadingOne>
          )}
        </Grid>
      </Grid>
      <Grid container className={localClasses.root} spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Grid>
            <SnackBar
              open={formState.alert}
              severity={formState.severity}
              onClose={handleSnackbarClose}
            >
              {formState.errorMessage}
            </SnackBar>
          </Grid>
          <Grid
            item
            className={localClasses.duplicateReplicateStyle}
            xs={12}
            md={12}
            lg={12}
          >
            <BorderLessButton
              variant="contained"
              style={{
                marginRight: 15
              }}
              onClick={handleCancel}
              cgut
            >
              Cancel
            </BorderLessButton>
            <DarkBlueButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() => savedata(true)}
            >
              Save
            </DarkBlueButton>{" "}
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Input
                label={get(addSupplementForm[supplementName], "label")}
                name={supplementName}
                value={formState.values[supplementName] || ""}
                error={hasError(supplementName)}
                placeholder={get(
                  addSupplementForm[supplementName],
                  "placeholder"
                )}
                variant="outlined"
                required
                fullWidth
                onChange={handleChange}
                helperText={
                  hasError(supplementName)
                    ? formState.errors[supplementName].map(error => {
                        return error + " ";
                      })
                    : null
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container>
              <Grid item xs="12">
                <SupplementGrid
                  isDesktop={isDesktop}
                  noOfSupplement={noOfSupplement}
                  supplementData={supplementData}
                  handleSupplementChange={handleSupplementChange}
                  handleAddSupplement={handleAddSupplement}
                  handleDeleteSupplement={handleDeleteSupplement}
                  checkIfDataProper={checkIfDataProper}
                  suppData={suppForm.suppData}
                />
              </Grid>
            </Grid>
          </Grid>
          <SnackBar
            open={statusVariable.isOpenSnackBar}
            severity={statusVariable.isError}
            onClose={handleSnackbarClose}
          >
            {statusVariable.successErrorMessage}
          </SnackBar>
        </Grid>
        <Backdrop className={localClasses.backdrop} open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </>
  );
};

export default AddEditSupplementScheduleTemplate;
