import React, { useState } from "react";
import DialogBox from "../../components/DialogBox/DialogBox";
import { getAllPreviousSchedules } from "../../constants";
import auth from "../../components/Auth";
import { Table, LightGrayButton } from "../../components";
import { BODYMASS } from "../../paths";
import { useHistory } from "react-router-dom";
import { getFormattedDate } from "../../utils";

const PreviousSchedule = props => {
  const tableRef = React.createRef();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);

  const columns = [{ title: "Schedule Date", field: "start_date" }];
  const [filter, setFilter] = useState({
    _sort: "Schedule_no:desc",
    "createdForUser.id": urlParams.get("c")
  });

  const closeDialogBox = () => {
    props.closeDialogue(false);
  };

  const convertTableData = res => {
    let arr = [];
    if (res && res.length > 0) {
      for (let i in res) {
        let tempArr = {};
        tempArr["id"] = res[i].id;
        tempArr["client_id"] = res[i].createdForUser
          ? res[i].createdForUser.id
          : "";
        tempArr["start_date"] = getFormattedDate(res[i].schedule_start_date);
        tempArr["end_date"] = getFormattedDate(res[i].schedule_end_date);
        arr.push(tempArr);
      }
      return arr;
    } else {
      return [];
    }
  };

  const getPreviousScheduleData = async (page, pageSize) => {
    let apiUrl = getAllPreviousSchedules;
    const urlParams = new URLSearchParams(window.location.search);

    setFilter(filter => ({
      ...filter,
      page: page,
      pageSize: pageSize
    }));
    let params = {
      page: page,
      pageSize: pageSize,
      s: urlParams.get("s"),
      c: urlParams.get("c")
    };
    Object.keys(filter).map(res => {
      if (!params.hasOwnProperty(res)) {
        params[res] = filter[res];
      }
      return null;
    });

    return new Promise((resolve, reject) => {
      fetch(apiUrl + "?" + new URLSearchParams(params), {
        desc: "Get previous schedule details of a client",
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + auth.getToken()
        }
      })
        .then(response => response.json())
        .then(result => {
          resolve({
            data: convertTableData(result.data),
            page: result.page - 1,
            totalCount: result.totalCount
          });
        });
    });
  };

  const handlePreviousSchedule = data => {
    history.push({
      pathname: BODYMASS,
      search: `?c=${data.client_id}&s=${data.id}`
    });
    closeDialogBox();
    window.location.reload();
  };

  return (
    <DialogBox
      open={props.OpenDialogueBox}
      title="All Schedules"
      buttonCancel="Cancel"
      handleCancel={closeDialogBox}
      skipOk={true}
    >
      <Table
        tableRef={tableRef}
        columns={columns}
        data={async query => {
          return await getPreviousScheduleData(query.page + 1, query.pageSize);
        }}
        actions={[
          {
            icon: () => <LightGrayButton>Use</LightGrayButton>,
            tooltip: "Edit",
            onClick: (event, rowData) => {
              handlePreviousSchedule(rowData);
            }
          }
        ]}
        options={{
          actionsColumnIndex: -1,
          search: false,
          sorting: false,
          pageSize: 5,
          thirdSortClick: false,
          headerStyle: {
            fontFamily: "Montserrat",
            fontWeight: 500,
            color: "#8A8A97",
            borderBottom: "solid #E0E0E0 2px",
            fontSize: "1rem"
          },
          cellStyle: {
            fontFamily: "Montserrat",
            fontWeight: 500,
            color: "#000000",
            fontSize: "1rem"
          },
          paginationStyle: {
            justifyContent: "center"
          }
        }}
      />
    </DialogBox>
  );
};

export default PreviousSchedule;
