import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { Layout } from "../hoc";
import { Auth as auth } from "../components";
import { LOGIN } from "../paths";

const PrivateRoute = ({
  component: Component,
  computedMatch: ComputedMatch,
  ...otherProps
}) => {
  if (auth.getToken() !== null) {
    return (
      <>
        <Route
          render={otherProps => (
            <>
              <Layout>
                <Component {...otherProps} urlParams={ComputedMatch} />
              </Layout>
            </>
          )}
        />
      </>
    );
  } else {
    return (
      <React.Fragment>
        {auth.clearAppStorage()}
        <Redirect
          to={{
            pathname: LOGIN
          }}
        />
      </React.Fragment>
    );
  }
};
export default PrivateRoute;
