const ExerciseTemplateSchema = {
  exerciseName: {
    label: "Favorite Name",
    id: "ExerciseName",
    autoComplete: "Name",
    required: true,
    placeholder: "Favorite Name",
    autoFocus: true,
    type: "text",
    validations: {
      noEmptySpaces: {
        value: "true",
        message: "Favorite Name is required, No blank spaces"
      }
    }
  },
  exerciseDescription: {
    label: "Description",
    id: "exerciseDescription",
    autoComplete: "Description",
    required: false,
    placeholder: "Description",
    autoFocus: true,
    type: "text",
    validations: {}
  }
};
export default ExerciseTemplateSchema;
