import React, { forwardRef, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Input,
  Auth,
  SnackBar,
  useWindowDimensions,
  DarkBlueButton,
  AutoCompleteDropDown,
  HeadingOne,
  DateRange
} from "../../components";
import clsx from "clsx";
import {
  Grid,
  InputAdornment,
  Backdrop,
  CircularProgress,
  Container,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Box,
  useMediaQuery,
  IconButton,
  TextField,
  Tooltip as TooltipForButton
} from "@material-ui/core";
import {
  providerForGet,
  providerForPost,
  providerForPut,
  providerForDownloadWithGraph
} from "../../api";
import {
  bodyMassTable,
  getBMofSchedule,
  viewPdf,
  getGraphDataUrl,
  getPreviousBmiDataUrl,
  getClientGender,
  getScheduleDateForClient,
  getClientScheduleData,
  bodyMassTitle
} from "../../constants";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import Styles from "./BodyMass.module.css";
import GetAppIcon from "@material-ui/icons/GetApp";
import auth from "../../components/Auth";
import { Prompt, Redirect } from "react-router-dom";
import { convertDate, convertNumber } from "../../../src/utils";
import {
  ERROR_MESSAGE,
  SAVE_MESSAGE,
  UPDATE_MESSAGE
} from "../../constants/genericConstants";
import moment from "moment";
import BodyMassIcon from "../../assets/small_icons/Body Mass.png";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { CLIENTMYSCHEDULE, LOGIN } from "../../paths";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import DialogBox from "../../components/DialogBox/DialogBox";
import BarChartIcon from "@material-ui/icons/BarChart";
import TimelineIcon from "@material-ui/icons/Timeline";
import noSchedule from "../../assets/images/No_schedule.jpg";
import PrintIcon from "@material-ui/icons/Print";

const currentWeight = "currentWeight";
const currentBodyFat = "currentBodyFat";
const leanWeight = "leanWeight";
const fatWeight = "fatWeight";
const currCalorieIntake = "currCalorieIntake";
const newCalorieIntake = "newCalorieIntake";
const currRestingBMR = "currRestingBMR";
const currActiveBMR = "currActiveBMR";
const bmrFuncPercent = "bmrFuncPercent";
const bmrSuppPercent = "bmrSuppPercent";

const BodyMass = forwardRef((props, ref) => {
  const { height } = useWindowDimensions();
  const themes = useTheme();

  const isDesktop = useMediaQuery(themes.breakpoints.up("lg"), {
    defaultMatches: true
  });

  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },
    formControl: {
      margin: theme.spacing(3)
    },
    valuePadding: {
      border: "none",
      padding: "0px",
      "font-size": "10px",
      outline: "none"
    },
    input: {
      "&:invalid": {
        border: "red solid 2px"
      }
    },
    label: {
      fontSize: "small",
      paddingLeft: 20
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 5,
      color: "#fff"
    },
    buttons: {
      margin: "1%"
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative"
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    },
    graphContainer: {},
    graphInnerContainer: {
      height: height / 2,
      textAlign: "center",
      backgroundColor: "#FFFFFF",
      width: "100%"
    },
    listRoot: {
      width: "100%",
      maxWidth: 360
    },
    cardTitleStyle: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "1rem",
      color: "#8A8A97",
      padding: "1rem !important"
    },
    tableRowStyle: {
      background: "#FAF8FD"
    },
    tableCellStyle: {
      fontFamily: "Montserrat",
      fontWeight: 700,
      fontSize: "0.9375rem",
      color: "#110F48",
      border: "none",
      padding: 0,
      backgroundColor: "#f4f8ff"
    },
    borderNone: {
      border: "none",
      padding: 0
    },
    trRoot: {
      "& .MuiTable-root": {
        borderSpacing: " 0 1.0625rem !important",
        borderCollapse: "separate  !important"
      },
      "& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover": {
        background: "#f4f8ff"
      },
      "& .MuiTableRow-root": {
        "&:hover": {
          background: "#f4f8ff !important"
        }
      }
    },
    inputRoot: {
      "& .MuiOutlinedInput-input": {
        textAlign: "center",
        padding: "0.875rem !important"
      },
      "& .MuiOutlinedInput-root": {
        margin: "8px 0px",
        width: "6.125rem"
      }
    },
    checkBoxStyle: {
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#1C4979"
      },
      "& > label > span": {
        paddingLeft: "0px",
        paddingRight: "0px"
      }
    },
    cardHeaderStyle: {
      fontFamily: "Montserrat",
      fontWeight: 800,
      fontSize: "1rem",
      color: "#110F48",
      textAlign: "center"
    },
    paddingStyle: {
      padding: "0 0 1.875rem 0"
    },
    radioButtonStyle: {
      "& .MuiFormControlLabel-label": {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "0.9375rem",
        color: "#000000",
        textAlign: "center"
      },
      "& > div .MuiFormControlLabel-root:last-child": {
        marginRight: "0 !important"
      },
      "& .MuiRadio-colorSecondary.Mui-checked": {
        color: "#1C4979"
      },
      "& .MuiSvgIcon-root": {
        fontSize: "1.2rem"
      }
    },
    responsiveContainerWrap: {
      overflow: "auto",
      height: "420px",
      "& > div": {
        margin: "0 auto"
      }
    },

    dialogBoxDesign: {
      "& MuiPaper-root": {
        width: 900
      }
    },
    notesSection: {
      marginTop: "1.25rem",
      width: "500px"
    },
    centerImage: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: "30%"
    }
  }));

  const renderColorfulLegendText = (value, entry) => {
    return (
      <span
        style={{
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: "0.875rem",
          color: "#8A8A97"
        }}
      >
        {value}
      </span>
    );
  };

  const classes = useStyles();
  const history = useHistory();
  let propData = props["location"] && props["location"]["state"];
  const noScheduleDataMessageFromBackend = "No schedule data";
  const [scheduleDateNotPresent, setScheduleDateNotPresent] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isPreviousPresent, setIsPreviousPresent] = useState(true);
  const [clientView] = useState(
    Auth.getUserInfo().role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
      ? true
      : false
  );
  const [imageSize] = useState({
    width: 800,
    height: 400
  });
  const [loader, setLoader] = useState(false);
  const [dateList, setDateList] = useState([]);
  const [graphColor, setGraphColor] = useState({
    graphAreaColor: [],
    graphFillColor: []
  });

  useEffect(() => {
    if (!Auth.getUserInfo()) {
      history.push(LOGIN);
    }
  }, []);

  const [formState, setFormState] = useState({
    values: {
      currentWeight: 0,
      currentBodyFat: 0,
      leanWeight: 0,
      fatWeight: 0,
      currCalorieIntake: 0,
      newCalorieIntake: 0,
      currRestingBMR: 0,
      currActiveBMR: 0,
      bmrFuncPercent: 0,
      bmrSuppPercent: "100"
    },
    clientFilter: {},
    scheduleDate: propData ? propData : null,
    fromData: propData ? propData.from : null,
    isActive: props ? props.isActive : null,
    previousValues: {
      currentWeight: 0,
      currentBodyFat: 0,
      leanWeight: 0,
      fatWeight: 0,
      currCalorieIntake: 0,
      newCalorieIntake: 0,
      currRestingBMR: 0,
      currActiveBMR: 0,
      bmrFuncPercent: 0,
      bmrSuppPercent: "100"
    },
    bodyMassTableId: null,
    scheduleId: null,
    clientId: null,
    errors: {},
    checked: {},
    graphData: {
      showGraph: false,
      graphDataArray: [],
      graphDataXaxis: [],
      graphDataYaxis: []
    },
    gender: null
  });

  const [notes] = useState({
    clientNotes: "",
    clientNotesTitle: bodyMassTitle,
    clientNotesId: null,
    isDataPresent: false
  });

  const [graphData, setGraphData] = useState({
    showGraph: false,
    graphDataArray: [],
    graphDataXaxis: ["start_date"],

    graphDataYaxis: [],
    graphDataYaxisForCalorie: [
      {
        paramData: "new_calorific_intake",
        paramColor: "#E63956 ",
        areaColor: "#FFEBEE "
      },
      {
        paramData: "current_resting_bmr",
        paramColor: "green",
        areaColor: "green"
      },
      {
        paramData: "current_active_bmr",
        paramColor: "#87DEFB ",
        areaColor: "#E6F6FB"
      }
    ]
  });

  const getClientCreds = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    let editId = {
      id: urlParams.get("c")
    };
    await providerForGet(getClientGender, editId, auth.getToken())
      .then(res => {
        setFormState(formState => ({
          ...formState,
          gender: res.data
        }));
        setLoader(false);
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });
  };

  const [xAxis] = useState(["start_date"]);
  const [yAxis, setYAxis] = useState([]);

  const [graphDataYaxisForCalorie, setGraphDataYaxisForCalorie] = useState([]);
  const [statusVariable, setStatusVariable] = useState({
    isAdd: false,
    isError: false,
    isOpenSnackBar: false,
    successErrorMessage: "",
    saveText: "",
    dummyTime: new Date(),
    savedTime: null,
    isSaving: false
  });

  const [radioValueGraphOne, setRadioValueGraphOne] = useState("Bar Graph");
  const [radioValueGraphTwo, setRadioValueGraphTwo] = useState("Bar Graph");
  const hasError = field => (formState.errors[field] ? true : false);
  const fiterText = "Recent 6 Visits";
  /**const for popover */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    recentVisits: true,
    selectDateRange: false,
    noOfVisits: false,
    filterButtonName: fiterText
  });
  const [dateFilter, setDateFilter] = useState({
    filterBy: null,
    fromDate: null,
    toDate: "",
    dateFromError: false,
    dateToError: false,
    dateNoOfVisitsError: false,
    dateFromErrorText: "",
    dateToErrorText: "",
    dateNoOfVisitsErrorText: "",
    filterSelectError: false,
    filterSelectErrorText: "",
    gte_key: "",
    lte_key: "",
    noOfVisits: null
  });

  const getScheduleDates = () => {
    providerForGet(
      getScheduleDateForClient,
      {
        c: Auth.getUserInfo().id
      },
      Auth.getToken()
    )
      .then(async res => {
        let convertDateData = await convertDate(res.data);
        setDateList(convertDateData);
        if (formState.scheduleDate === null) {
          setFormState(formState => ({
            ...formState,
            clientFilter: {
              ...formState.clientFilter,
              scheduleDate: convertDateData[0].id,
              scheduleObj: convertDateData[0],
              defaultScheduleObj: convertDateData[0]
            }
          }));
        }
        let resObj = convertDateData;

        resObj.map(r => {
          if (formState.scheduleDate !== null) {
            if (r.id === formState.scheduleDate.id) {
              setFormState(formState => ({
                ...formState,
                clientFilter: {
                  ...formState.clientFilter,
                  scheduleDate: r.id,
                  scheduleObj: r
                }
              }));
            }
          }
          return null;
        });
      })
      .catch(error => {
        console.log("error-->>", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.statusCode === 400 &&
          error.response.data.message === noScheduleDataMessageFromBackend
        ) {
          setScheduleDateNotPresent(true);
        }
        setLoader(false);
      });
  };

  const getInitialData = async clientScheduleDate => {
    setLoader(true);
    const urlParams = new URLSearchParams(window.location.search);
    setFormState(formState => ({
      ...formState,
      scheduleId: clientScheduleDate
        ? clientScheduleDate.id
        : urlParams.get("s"),
      clientId: clientScheduleDate ? Auth.getUserInfo().id : urlParams.get("c")
    }));
    await providerForGet(
      getBMofSchedule,
      {
        s: clientScheduleDate ? clientScheduleDate.id : urlParams.get("s"),
        c: clientScheduleDate ? Auth.getUserInfo().id : urlParams.get("c"),
        desc: clientScheduleDate
          ? `Client ${Auth.getUserInfo().full_name} viewed Body Mass details`
          : `Nutritionist ${
              Auth.getUserInfo().full_name
            } viewed Body Mass details of a client`,
        audit_log_clientid: clientScheduleDate
          ? Auth.getUserInfo().id
          : urlParams.get("c")
      },
      Auth.getToken()
    )
      .then(res => {
        if (Object.keys(res.data).length !== 0) {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              currentWeight: res.data["current_weight"]
                ? res.data["current_weight"]
                : 0,
              currentBodyFat: res.data["current_percent_body_fat"]
                ? res.data["current_percent_body_fat"]
                : 0,
              leanWeight: res.data["lean_weight"] ? res.data["lean_weight"] : 0,
              fatWeight: res.data["fat_weight"] ? res.data["fat_weight"] : 0,
              currCalorieIntake: res.data["current_calorific_intake"]
                ? res.data["current_calorific_intake"]
                : 0,
              newCalorieIntake: res.data["new_calorific_intake"]
                ? res.data["new_calorific_intake"]
                : 0,
              currRestingBMR: res.data["current_resting_bmr"]
                ? res.data["current_resting_bmr"]
                : 0,
              currActiveBMR: res.data["current_active_bmr"]
                ? res.data["current_active_bmr"]
                : 0,
              bmrFuncPercent: res.data["bmr_functional_percent"]
                ? res.data["bmr_functional_percent"]
                : 0,
              bmrSuppPercent: isNaN(
                parseFloat(res.data["bmr_suppresed_percent"])
              )
                ? 100
                : parseFloat(res.data["bmr_suppresed_percent"])
            },
            bodyMassTableId: res.data.id
          }));
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isAdd: false
          }));
        } else {
          setStatusVariable(statusVariable => ({
            ...statusVariable,
            isAdd: true
          }));
        }
        setLoader(false);
      })
      .catch(error => {
        console.log("errorInitialBmidata", error);
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          isAdd: true,
          isError: true,
          successErrorMessage: "Error getting body mass data"
        }));
        setLoader(false);
      });
  };

  const getPreviousData = async clientScheduleDate => {
    //call api for pewvious data of bmi
    const urlParams = new URLSearchParams(window.location.search);

    await providerForGet(
      getPreviousBmiDataUrl,
      {
        s: clientScheduleDate ? clientScheduleDate.id : urlParams.get("s"),
        c: clientScheduleDate ? Auth.getUserInfo().id : urlParams.get("c")
      },
      Auth.getToken()
    )
      .then(res => {
        if (
          res.data.previousBmiData &&
          Object.keys(res.data.previousBmiData).length
        ) {
          setIsPreviousPresent(true);
          setFormState(formState => ({
            ...formState,
            previousValues: {
              ...formState.previousValues,
              currentWeight: res.data.previousBmiData["current_weight"]
                ? res.data.previousBmiData["current_weight"]
                : 0,
              currentBodyFat: res.data.previousBmiData[
                "current_percent_body_fat"
              ]
                ? res.data.previousBmiData["current_percent_body_fat"]
                : 0,
              leanWeight: res.data.previousBmiData["lean_weight"]
                ? res.data.previousBmiData["lean_weight"]
                : 0,
              fatWeight: res.data.previousBmiData["fat_weight"]
                ? res.data.previousBmiData["fat_weight"]
                : 0,
              currCalorieIntake: res.data.previousBmiData[
                "current_calorific_intake"
              ]
                ? res.data.previousBmiData["current_calorific_intake"]
                : 0,
              newCalorieIntake: res.data.previousBmiData["new_calorific_intake"]
                ? res.data.previousBmiData["new_calorific_intake"]
                : 0,
              currRestingBMR: res.data.previousBmiData["current_resting_bmr"]
                ? res.data.previousBmiData["current_resting_bmr"]
                : 0,
              currActiveBMR: res.data.previousBmiData["current_active_bmr"]
                ? res.data.previousBmiData["current_active_bmr"]
                : 0,
              bmrFuncPercent: res.data.previousBmiData["bmr_functional_percent"]
                ? res.data.previousBmiData["bmr_functional_percent"]
                : 0,
              bmrSuppPercent: isNaN(
                parseFloat(res.data.previousBmiData["bmr_suppresed_percent"])
              )
                ? 100
                : parseFloat(res.data.previousBmiData["bmr_suppresed_percent"])
            }
          }));
        } else if (!Object.keys(res.data.previousBmiData).length) {
          setIsPreviousPresent(false);
        }
      })
      .catch(error => {
        console.log("error", error);
      });
  };

  const getGraphData = async clientScheduleDate => {
    if (!Auth.getUserInfo()) {
      return (
        <React.Fragment>
          <Redirect
            to={{
              pathname: LOGIN
            }}
          />
        </React.Fragment>
      );
    }

    setGraphData(graphData => ({
      ...graphData,
      showGraph: true
    }));

    const urlParams = new URLSearchParams(window.location.search);
    let scheduleDateTemp;
    let clientDateTemp;
    if (
      Auth.getUserInfo().role.name === process.env.REACT_APP_CLIENT_ROLE_NAME
    ) {
      setFormState(formState => ({
        ...formState,
        scheduleId: clientScheduleDate ? clientScheduleDate.id : null,
        clientId: Auth.getUserInfo().id
      }));
      scheduleDateTemp = clientScheduleDate ? clientScheduleDate.id : null;
      clientDateTemp = Auth.getUserInfo().id;
    } else if (
      Auth.getUserInfo().role.name === process.env.REACT_APP_NUTRITIONIST_ROLE
    ) {
      setFormState(formState => ({
        ...formState,
        scheduleId: urlParams.get("s"),
        clientId: urlParams.get("c")
      }));
      scheduleDateTemp = urlParams.get("s");
      clientDateTemp = urlParams.get("c");
    }

    let array = [];
    await providerForGet(
      getGraphDataUrl,
      {
        s: scheduleDateTemp,
        c: clientDateTemp,
        dateFilter: dateFilter.fromDate ? true : false,
        noOfVisits: dateFilter.noOfVisits
          ? parseInt(dateFilter.noOfVisits)
          : null,
        fromDate: dateFilter.fromDate ? dateFilter.fromDate : null,
        toDate: dateFilter.toDate ? dateFilter.toDate : null,

        desc: clientScheduleDate
          ? "View  Data for Body Mass Graph"
          : "Get Data for Body Mass Graph",
        audit_log_clientid: clientScheduleDate
          ? Auth.getUserInfo().id
          : urlParams.get("c")
      },
      Auth.getToken()
    )
      .then(res => {
        if (res.data.bmiData) {
          array.push(res.data.bmiData);
        }
        setGraphColor(graphColor => ({
          ...graphColor,
          graphAreaColor: res.data.color.areaColor,
          graphFillColor: res.data.color.fillColor
        }));
        setFormState(formState => ({
          ...formState,
          checked: {
            ...formState.checked,
            lean_weight: true,
            fat_weight: true,
            current_calorific_intake: true,
            current_resting_bmr: true,
            current_active_bmr: true,
            new_calorific_intake: true
          }
        }));
        setGraphData(graphData => ({
          ...graphData,
          graphDataArray: array,
          showGraph: false
        }));
      })
      .catch(error => {
        console.log("errorGraph", error);
        setGraphData(graphData => ({
          ...graphData,
          showGraph: false
        }));
      });
  };

  useEffect(() => {
    setLoader(true);
    if (clientView === true) {
      getScheduleDates();
    } else {
      getClientCreds();
      getInitialData();
      getPreviousData();
      getGraphData();
    }
  }, []);

  useEffect(() => {
    if (!isNaN(formState.values.currentWeight)) {
      let restingBMR = (formState.values.currentWeight / 2.26) * 24;
      let val = 0;
      if (formState.gender === "Male") {
        val = 1.2;
      } else {
        val = 1.1;
      }
      let activeBMR = (formState.values.currentWeight / 2.26) * 24 * val;
      restingBMR =
        Math.round(restingBMR * 100) / 100
          ? (Math.round(restingBMR * 100) / 100).toFixed(1)
          : 0;
      activeBMR =
        Math.round(activeBMR * 100) / 100
          ? (Math.round(activeBMR * 100) / 100).toFixed(1)
          : 0;
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          currRestingBMR: restingBMR,
          currActiveBMR: activeBMR
        }
      }));
    } else {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          currRestingBMR: 0,
          currActiveBMR: 0
        }
      }));
    }
  }, [formState.values.currentWeight]);

  useEffect(() => {
    if (
      !isNaN(formState.values.currentWeight) &&
      !isNaN(formState.values.currentBodyFat)
    ) {
      let fatWeightCalc =
        (formState.values.currentBodyFat / 100) *
        formState.values.currentWeight;
      let leanWeightCalc = formState.values.currentWeight - fatWeightCalc;
      fatWeightCalc =
        Math.round(fatWeightCalc * 100) / 100
          ? (Math.round(fatWeightCalc * 100) / 100).toFixed(1)
          : 0;
      leanWeightCalc =
        Math.round(leanWeightCalc * 100) / 100
          ? (Math.round(leanWeightCalc * 100) / 100).toFixed(1)
          : 0;
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          fatWeight: fatWeightCalc,
          leanWeight: leanWeightCalc
        }
      }));
    } else {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          fatWeight: 0,
          leanWeight: 0
        }
      }));
    }
  }, [formState.values.currentWeight, formState.values.currentBodyFat]);

  useEffect(() => {
    if (!isNaN(formState.values.currCalorieIntake)) {
      let value = parseFloat(formState.values.currCalorieIntake);
      let weight = parseFloat(formState.values.currentWeight);
      let bmrFunct = 0;
      let bmrSupp = 100;
      if (weight) {
        bmrFunct = (value / (12.2 * weight)) * 100;
        bmrSupp = 0;
        if (bmrFunct < 100) {
          bmrSupp = 100 - bmrFunct;
        }
      }

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [bmrFuncPercent]: bmrFunct ? bmrFunct.toFixed(1) : 0,
          [bmrSuppPercent]: bmrSupp ? bmrSupp.toFixed(1) : 0
        }
      }));
    }
  }, [formState.values.currCalorieIntake, formState.values.currentWeight]);

  /**functions for popover */
  const open = Boolean(anchorEl);
  const popOverId = open ? "simple-popover" : undefined;

  const handleClickPopUpOpen = event => {
    /**
     * for opening the popover button
     */
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    /**
     * for closing popover button
     */
    setAnchorEl(null);
  };

  const handlePopUpCheckBox = event => {
    if (event.target.name === "recentVisits" && event.target.checked === true) {
      setState({
        ...state,
        recentVisits: true,
        selectDateRange: false,
        noOfVisits: false
      });
      setDateFilter(dateFilter => ({
        ...dateFilter,
        filterBy: null,
        fromDate: null,
        toDate: "",
        dateFromError: false,
        dateToError: false,
        dateNoOfVisitsError: false,
        dateFromErrorText: "",
        dateToErrorText: "",
        dateNoOfVisitsErrorText: "",
        filterSelectError: false,
        filterSelectErrorText: "",
        gte_key: "",
        lte_key: "",
        noOfVisits: null
      }));
    }
    if (
      event.target.name === "selectDateRange" &&
      event.target.checked === true
    ) {
      setState({
        ...state,
        recentVisits: false,
        selectDateRange: true,
        noOfVisits: false
      });
      setDateFilter(dateFilter => ({
        ...dateFilter,
        filterBy: null,
        fromDate: null,
        toDate: "",
        dateFromError: false,
        dateToError: false,
        dateNoOfVisitsError: false,
        dateFromErrorText: "",
        dateToErrorText: "",
        dateNoOfVisitsErrorText: "",
        filterSelectError: false,
        filterSelectErrorText: "",
        gte_key: "",
        lte_key: "",
        noOfVisits: null
      }));
    }
    if (event.target.name === "noOfVisits" && event.target.checked === true) {
      setState({
        ...state,
        recentVisits: false,
        selectDateRange: false,
        noOfVisits: true
      });
      setDateFilter(dateFilter => ({
        ...dateFilter,
        filterBy: null,
        fromDate: null,
        toDate: "",
        dateFromError: false,
        dateToError: false,
        dateNoOfVisitsError: false,
        dateFromErrorText: "",
        dateToErrorText: "",
        dateNoOfVisitsErrorText: "",
        filterSelectError: false,
        filterSelectErrorText: "",
        gte_key: "",
        lte_key: "",
        noOfVisits: null
      }));
    }
    if (!event.target.checked) {
      setState({
        ...state,
        recentVisits: true,
        selectDateRange: false,
        noOfVisits: false
      });
      setDateFilter(dateFilter => ({
        ...dateFilter,
        filterBy: null,
        fromDate: null,
        toDate: "",
        dateFromError: false,
        dateToError: false,
        dateNoOfVisitsError: false,
        dateFromErrorText: "",
        dateToErrorText: "",
        dateNoOfVisitsErrorText: "",
        filterSelectError: false,
        filterSelectErrorText: "",
        gte_key: "",
        lte_key: "",
        noOfVisits: null
      }));
    }
  };

  const handleInputChange = event => {
    let error = false;
    let errorText = "";

    setDateFilter(dateFilter => ({
      ...dateFilter,
      noOfVisits: event.target.value,
      dateNoOfVisitsError: error,
      dateNoOfVisitsErrorText: errorText
    }));
  };

  /** Handle Start Date filter change */
  const handleStartDateChange = event => {
    let startDate = moment(event).format("YYYY-MM-DDT00:00:00.000Z");
    let fromDate = null;
    let dateFromError = false;
    let dateFromErrorText = "";
    if (startDate !== "Invalid date") {
      fromDate = new Date(startDate).toISOString();
      if (
        dateFilter.toDate &&
        dateFilter.toDate !== "" &&
        moment(fromDate).isAfter(moment(dateFilter.toDate))
      ) {
        dateFromError = true;
        dateFromErrorText = ["From date cannot be greater than to date"];
      }
    }

    setDateFilter(dateFilter => ({
      ...dateFilter,
      fromDate: fromDate,
      dateFromError: dateFromError,
      dateFromErrorText: dateFromErrorText
    }));
  };

  /** Handle End Date filter change */
  const handleEndDateChange = event => {
    let endDate = moment(event).endOf("day").format("YYYY-MM-DDT23:59:59.999Z");
    let toDate = "";
    let dateToError = false;
    let dateToErrorText = "";
    if (endDate !== "Invalid date") {
      toDate = new Date(endDate).toISOString();
      if (
        dateFilter.fromDate &&
        dateFilter.fromDate !== "" &&
        moment(endDate).isBefore(moment(dateFilter.fromDate))
      ) {
        dateToError = true;
        dateToErrorText = ["To date cannot be less than from date"];
      }
    }

    setDateFilter(dateFilter => ({
      ...dateFilter,
      toDate: toDate,
      dateToError: dateToError,
      dateToErrorText: dateToErrorText
    }));
  };

  const handleClickFilterDoneButton = () => {
    /**
     * validation for no of visits
     */
    if (state.noOfVisits) {
      if (!dateFilter.noOfVisits) {
        setDateFilter(dateFilter => ({
          ...dateFilter,
          dateNoOfVisitsError: true,
          dateNoOfVisitsErrorText: ["Please enter no. of visits"]
        }));
      }
      if (dateFilter.noOfVisits === 0) {
        setDateFilter(dateFilter => ({
          ...dateFilter,
          dateNoOfVisitsError: true,
          dateNoOfVisitsErrorText: ["No. of visits must be greater than 0"]
        }));
      }
      if (dateFilter.noOfVisits < 0) {
        setDateFilter(dateFilter => ({
          ...dateFilter,
          dateNoOfVisitsError: true,
          dateNoOfVisitsErrorText: ["No. of visits must be positive value"]
        }));
      }
      if (
        dateFilter.noOfVisits &&
        dateFilter.noOfVisits !== 0 &&
        dateFilter.noOfVisits > 0
      ) {
        setState({
          ...state,

          filterButtonName: "No. Of Visits"
        });
        handleClose();
        getGraphData();
      }
    }
    /**
     * for daterange and recentvisits
     */
    if (state.recentVisits) {
      setState({
        ...state,

        filterButtonName: fiterText
      });
      handleClose();
      getGraphData();
    }
    if (state.selectDateRange) {
      setState({
        ...state,

        filterButtonName: "Selected Date Range"
      });
      handleClose();
      getGraphData();
    }
  };

  const handleClickFilterResetButton = () => {
    handleClose();
    setDateFilter(dateFilter => ({
      ...dateFilter,
      filterBy: null,
      fromDate: null,
      toDate: "",
      dateFromError: false,
      dateToError: false,
      dateNoOfVisitsError: false,
      dateFromErrorText: "",
      dateToErrorText: "",
      dateNoOfVisitsErrorText: "",
      filterSelectError: false,
      filterSelectErrorText: "",
      gte_key: "",
      lte_key: "",
      noOfVisits: null
    }));

    setState({
      ...state,

      recentVisits: true,
      selectDateRange: false,
      noOfVisits: false,
      filterButtonName: fiterText
    });
    getGraphData();
  };

  const handleChange = event => {
    setFormState(formState => ({
      ...formState,
      checked: {
        ...formState.checked,
        [event.target.name]: event.target.checked
      }
    }));
  };

  const handleTextChange = event => {
    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const createFormData = body => {
    const data = new FormData();
    data.append("data", JSON.stringify(body));

    return data;
  };

  const handleChangeInput = async event => {
    const floatRegExp = new RegExp(
      process.env.REACT_APP_REG_EX_FOR_EMPTY_STRING
    );
    let value = event.target.value;
    if (value !== "" && floatRegExp.test(value)) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: value
        }
      }));
    } else if (event.target.value === "") {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]: 0
        }
      }));
    }
  };

  const saveChanges = async isShowMessage => {
    const urlParams = new URLSearchParams(window.location.search);
    let editId = urlParams.get("c");
    let dataToPost = {
      current_weight: parseFloat(formState.values.currentWeight),
      current_percent_body_fat: parseFloat(formState.values.currentBodyFat),
      lean_weight: parseFloat(formState.values.leanWeight),
      fat_weight: parseFloat(formState.values.fatWeight),
      current_calorific_intake: parseFloat(formState.values.currCalorieIntake),
      new_calorific_intake: parseFloat(formState.values.newCalorieIntake),
      current_resting_bmr: parseFloat(formState.values.currRestingBMR),
      current_active_bmr: parseFloat(formState.values.currActiveBMR),
      bmr_functional_percent: parseFloat(formState.values.bmrFuncPercent),
      bmr_suppresed_percent: parseFloat(formState.values.bmrSuppPercent),
      schedule_id: formState.scheduleId,
      clientId: editId
    };
    if (statusVariable.isAdd === true) {
      await providerForPost(bodyMassTable, dataToPost, Auth.getToken(), {
        desc: "Add body mass Info",
        audit_log_clientid: editId
      })
        .then(res => {
          if (isShowMessage) {
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              isAdd: false,
              isSaving: false,
              savedTime: new Date(),
              isOpenSnackBar: true,
              isError: "success",
              successErrorMessage: "Bodymass data " + SAVE_MESSAGE
            }));
          }

          setFormState(formState => ({
            ...formState,
            bodyMassTableId: res.data.id
          }));
          setLoader(false);
        })
        .catch(error => {
          if (isShowMessage) {
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              isAdd: true,
              isSaving: false,
              savedTime: null,
              isOpenSnackBar: true,
              isError: "error",
              successErrorMessage: ERROR_MESSAGE + error
            }));
          }

          setLoader(false);
        });
    } else if (statusVariable.isAdd === false) {
      let desc = {};
      /** This is done so that audit log doesn't get overfilled
       * initially the saved time is null so at that time when the data updates the audit log entry will be made
       * later the entry will not be made so as to prevent the audit log table to get overfilled by update entries
       */
      if (!statusVariable.savedTime) {
        desc = {
          desc: "Update body mass Info",
          audit_log_clientid: editId
        };
      }
      await providerForPut(
        bodyMassTable,
        formState.bodyMassTableId,
        dataToPost,
        Auth.getToken(),
        desc
      )
        .then(res => {
          if (isShowMessage) {
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              isSaving: false,
              savedTime: new Date(),
              isOpenSnackBar: true,
              isError: "success",
              successErrorMessage: "Bodymass data " + UPDATE_MESSAGE
            }));
          }
          setLoader(false);
        })
        .catch(error => {
          if (isShowMessage) {
            setStatusVariable(statusVariable => ({
              ...statusVariable,
              isSaving: false,
              savedTime: null,
              isOpenSnackBar: true,
              isError: "error",
              successErrorMessage: ERROR_MESSAGE + error
            }));
          }
          setLoader(false);
        });
    }
  };

  const handleSaveUpdateGraph = async () => {
    await saveChanges();
    await getGraphData();
  };

  const handleSave = async (isShowMessage = true) => {
    setLoader(true);
    //call graph function
    await saveChanges(isShowMessage);
    await getGraphData();
  };

  const handleSnackbarClose = () => {
    setStatusVariable(statusVariable => ({
      ...statusVariable,
      isOpenSnackBar: false,
      isError: "",
      successErrorMessage: ""
    }));
  };

  const getKeyName = key => {
    let name = "";
    switch (key) {
      case "current_weight":
        name = "Current Weight";
        break;
      case "current_percent_body_fat":
        name = "Current % Body Fat";
        break;
      case "lean_weight":
        name = "Lean Weight";
        break;
      case "fat_weight":
        name = "Fat Weight";
        break;
      case "new_calorific_intake":
        name = "New Caloric Intake";
        break;
      case "current_calorific_intake":
        name = "Current Caloric Intake";
        break;
      case "current_resting_bmr":
        name = "Current Resting BMR";
        break;
      case "current_active_bmr":
        name = "Current Active BMR";
        break;
      case "bmr_functional_percent":
        name = "BMR % Functional";
        break;
      case "bmr_suppresed_percent":
        name = "BMR % Suppressed";
        break;
    }
    return name;
  };

  useEffect(() => {
    //This is used to add keys in Y-Axis
    let array = [];
    let array2 = [];
    Object.keys(formState.checked).map((key, i) => {
      if (formState.checked[key] === true) {
        let gData = {};
        let g2Data = {};
        if (
          key === "current_weight" ||
          key === "current_percent_body_fat" ||
          key === "lean_weight" ||
          key === "fat_weight"
        ) {
          let name = getKeyName(key);
          gData = {
            name: key,
            keyName: name,
            paramColor: graphColor.graphAreaColor[i],
            areaColor: graphColor.graphFillColor[i]
          };
          array.push(gData);
        } else {
          let name = getKeyName(key);
          g2Data = {
            name: key,
            keyName: name,
            paramColor: graphColor.graphAreaColor[i],
            areaColor: graphColor.graphFillColor[i]
          };
          array2.push(g2Data);
        }
      }
      return null;
    });
    setGraphDataYaxisForCalorie(array2);
    setYAxis(array);
  }, [formState.checked]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      return (
        <div
          className="subscribers-by-channel-tooltip"
          style={{
            backgroundColor: "white"
          }}
        >
          <p
            className="subscribers-by-channel-tooltip-label"
            style={{
              paddingTop: "5px",
              paddingLeft: "10px"
            }}
          >
            {"Schedule Data : "}
            {label}
          </p>
          {payload.map(data => (
            <p
              className="subscribers-by-channel-tooltip-value"
              style={{
                color: `${data.stroke}`,
                paddingTop: "5px",
                paddingLeft: "10px"
              }}
            >
              {` ${data.name}`} : {` ${data.value}`}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };

  const handleChangeRadioGraphOne = event => {
    setRadioValueGraphOne(event);
  };

  const handleChangeRadioGraphTwo = event => {
    setRadioValueGraphTwo(event);
  };

  const downloadGraph = async (caloricAndBmr, weightHistory, data, isPrint) => {
    setLoader(true);
    const urlParams = new URLSearchParams(window.location.search);
    let finalData = createFormData({
      s: clientView
        ? formState.clientFilter["scheduleDate"]
        : urlParams.get("s"),
      c: clientView ? Auth.getUserInfo().id : urlParams.get("c"),
      caloricKeys: caloricAndBmr,
      weightHistoryKeys: weightHistory,
      bodyMassData: data
    });

    if (clientView !== true) {
      await saveChanges();
    }
    providerForDownloadWithGraph(viewPdf, finalData, Auth.getToken(), {
      desc: "Download BMI pdf",
      audit_log_clientid: clientView
        ? Auth.getUserInfo().id
        : urlParams.get("c")
    })
      .then(res => {
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          window.open(url,'_blank');
          // const pdfNewWindow = window.open();
          // pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "BMI Report.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }

        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };

  const handleDownload = async isPrint => {
    let caloricAndBmr = graphDataYaxisForCalorie;
    let weightHistory = yAxis;
    let data = graphData.graphDataArray[0];
    downloadGraph(caloricAndBmr, weightHistory, data, isPrint);
  };

  const handleChangeAutoComplete = (eventName, event, value) => {
    if (eventName === "scheduleDate") {
      if (value !== null) {
        if (formState.scheduleDate !== null) {
          formState.scheduleDate = null;
        }
        setFormState(formState => ({
          ...formState,
          clientFilter: {
            ...formState.clientFilter,
            [eventName]: value.id,
            scheduleObj: value
          }
        }));
      } else {
        setFormState(formState => ({
          ...formState,
          clientFilter: {
            ...formState.clientFilter,
            [eventName]: formState.clientFilter.scheduleObj.id
          }
        }));
      }
    }
  };

  const handleBackArrow = () => {
    let data = {};

    if (formState.fromData) {
      data["id"] = formState.clientFilter.scheduleObj.id;
      data["scheduleObj"] = formState.clientFilter.scheduleObj;
      history.push(formState.fromData, data);
    } else {
      history.push(CLIENTMYSCHEDULE, data);
    }
  };

  const handleDialogCancel = () => {
    setPopUpOpen(false);
  };

  useEffect(() => {
    for (var i = 0; i < dateList.length; i++) {
      if (
        formState.clientFilter["scheduleDate"] === dateList[i].id &&
        formState.scheduleDate === null
      ) {
        getInitialData(dateList[i]);
        getPreviousData(dateList[i]);
        getGraphData(dateList[i]);
      }
    }
  }, [formState.clientFilter["scheduleDate"]]);

  useEffect(() => {
    if (formState.scheduleDate !== null) {
      getInitialData(formState.scheduleDate);
      getPreviousData(formState.scheduleDate);
      getGraphData(formState.scheduleDate);
    }
  }, [formState.scheduleDate]);

  const handleDialogOk = async () => {
    /**
     * save notes data
     */
    setLoader(true);
    let dataToPost = {
      body_mass_client_notes: formState.notes ? formState.notes : null
    };
    await providerForPut(
      getClientScheduleData,
      formState.clientFilter.scheduleDate,
      dataToPost,
      Auth.getToken()
    )
      .then(res => {
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          isOpenSnackBar: true,
          isError: "success",
          successErrorMessage: "Body Mass notes saved successfully!"
        }));
        setLoader(false);
        handleDialogCancel();
      })
      .catch(error => {
        setLoader(false);
        handleDialogCancel();
        setStatusVariable(statusVariable => ({
          ...statusVariable,
          isOpenSnackBar: true,
          isError: "error",
          successErrorMessage: ERROR_MESSAGE + error
        }));
      });
  };

  return (
    <>
      {Auth.getUserInfo().role.name !==
        process.env.REACT_APP_CLIENT_ROLE_NAME && (
        <Prompt
          message={(location, action) => {
            handleSave(false);
          }}
        />
      )}
      <>
        {Auth.getUserInfo().role.name ===
          process.env.REACT_APP_CLIENT_ROLE_NAME && (
          <>
            <IconButton
              className={Styles.backIcon}
              onClick={() => handleBackArrow()}
            >
              <ChevronLeftIcon
                style={{
                  padding: "5px",
                  border: "solid #DADADA 1px",
                  borderRadius: "25px",
                  margin: "0px 1.875rem 0px 10px",
                  background: "#fff",
                  marginTop: 0
                }}
              />
            </IconButton>
            <HeadingOne>
              <img
                width="auto"
                height="30px"
                src={BodyMassIcon}
                alt={"BodyMassIcon"}
              />

              <span style={{ position: "absolute", marginLeft: "10px" }}>
                BODY MASS
              </span>
            </HeadingOne>
          </>
        )}
      </>
      {scheduleDateNotPresent === true ? (
        <Grid>
          <img
            src={noSchedule}
            className={classes.centerImage}
            alt="No schedule/data"
          />
        </Grid>
      ) : (
        <>
          {Auth.getUserInfo().role.name ===
            process.env.REACT_APP_CLIENT_ROLE_NAME && (
            <Grid container spacing={1}>
              {/* Add schedule auto complete */}
              <Grid item md={4} xs={12}>
                <AutoCompleteDropDown
                  options={dateList}
                  getOptionLabel={option => option.schedule_start_date}
                  id="schedule_date"
                  value={
                    dateList[
                      dateList.findIndex(function (item, i) {
                        return (
                          item.id === formState.clientFilter["scheduleDate"]
                        );
                      })
                    ] || null
                  }
                  onChange={(event, value) => {
                    handleChangeAutoComplete("scheduleDate", event, value);
                  }}
                  renderInput={params => (
                    <Input
                      {...params}
                      id="schedule_date"
                      label="Schedule Date"
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
          <Container maxWidth={false}>
            <Grid container>
              <Grid
                container
                item
                xs={12}
                justify="flex-end"
                alignItems="center"
              >
                <IconButton
                  onClick={() => handleDownload(true)}
                  style={{ marginRight: 15, padding: 0, background: "none" }}
                >
                  <TooltipForButton title="Print Report">
                    <PrintIcon style={{ color: "#1C4979", fontSize: 30 }} />
                  </TooltipForButton>
                </IconButton>

                <IconButton
                  onClick={() => handleDownload(false)}
                  style={{ marginRight: 15, padding: 0, background: "none" }}
                >
                  <TooltipForButton title="Download Report">
                    <GetAppIcon style={{ color: "#1C4979", fontSize: 30 }} />
                  </TooltipForButton>
                </IconButton>

                <DialogBox
                  skipCancel={false}
                  skipOk={false}
                  open={popUpOpen}
                  title={"Add/Edit Body Mass Notes"}
                  className={classes.dialogBoxDesign}
                  children={
                    <div>
                      <p style={{ marginTop: "1.25rem" }}>
                        Schedule date :{" "}
                        {formState.clientFilter &&
                          formState.clientFilter.scheduleObj &&
                          formState.clientFilter.scheduleObj[
                            "schedule_start_date"
                          ]}
                      </p>
                      <Grid
                        container
                        spacing={1}
                        className={classes.notesSection}
                      >
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            multiline
                            id="notes"
                            name="notes"
                            onChange={handleTextChange}
                            value={notes.clientNotes}
                            rows={15}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  }
                  handleOk={handleDialogOk}
                  handleCancel={handleDialogCancel}
                  buttonCancel={"Cancel"}
                  buttonOk={"SAVE"}
                />

                {Auth.getUserInfo().role.name !==
                  process.env.REACT_APP_CLIENT_ROLE_NAME && (
                  <DarkBlueButton
                    type="button"
                    variant="contained"
                    onClick={handleSave}
                    disabled={formState.isActive === false}
                  >
                    Save
                  </DarkBlueButton>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <div className={classes.trRoot}>
                  <Table className={Styles.boddyMassTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.borderNone}></TableCell>
                        <TableCell className={classes.borderNone}>
                          <Typography className={classes.cardTitleStyle}>
                            Category
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={classes.borderNone}
                          align="center"
                        >
                          <Typography className={classes.cardTitleStyle}>
                            Actual
                          </Typography>
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.borderNone}
                            align="center"
                          >
                            <Typography className={classes.cardTitleStyle}>
                              Previous
                            </Typography>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked["current_weight"]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.checked["current_weight"]
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  name={"current_weight"}
                                />
                              }
                              className={classes.label}
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          Current Weight
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          <div className={classes.inputRoot}>
                            <Input
                              name={currentWeight}
                              placeholder="0"
                              value={formState.values[currentWeight] || ""}
                              error={hasError(currentWeight)}
                              variant="outlined"
                              required
                              onChange={handleChangeInput}
                              onBlur={handleSaveUpdateGraph}
                              disabled={formState.isActive === false}
                              helperText={
                                hasError(currentWeight)
                                  ? formState.errors[currentWeight].map(
                                      error => {
                                        return error + " ";
                                      }
                                    )
                                  : null
                              }
                              InputProps={{
                                readOnly: clientView === true ? true : false
                              }}
                            />
                          </div>
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(
                              formState.previousValues[currentWeight]
                            )}
                          </TableCell>
                        ) : null}
                      </TableRow>

                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked["current_percent_body_fat"]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.checked[
                                      "current_percent_body_fat"
                                    ]
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  name={"current_percent_body_fat"}
                                />
                              }
                              className={classes.label}
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          Current % Body Fat
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          <div className={classes.inputRoot}>
                            <Input
                              placeholder="0"
                              value={formState.values[currentBodyFat] || ""}
                              name={currentBodyFat}
                              onChange={handleChangeInput}
                              onBlur={handleSaveUpdateGraph}
                              disabled={formState.isActive === false}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    %
                                  </InputAdornment>
                                ),
                                readOnly: clientView === true ? true : false
                              }}
                            />
                          </div>
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(
                              formState.previousValues[currentBodyFat]
                            )}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked["lean_weight"]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.checked["lean_weight"]
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  name={"lean_weight"}
                                />
                              }
                              className={classes.label}
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          Lean Weight
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          <div className={classes.inputRoot}>
                            <Input
                              name={leanWeight}
                              placeholder="0"
                              value={formState.values.leanWeight || ""}
                              error={hasError(leanWeight)}
                              variant="outlined"
                              required
                              disabled={formState.isActive === false}
                              InputProps={{
                                readOnly: true
                              }}
                            />
                            <FormControl
                              className={clsx(
                                classes.margin,
                                classes.withoutLabel,
                                classes.textField
                              )}
                            ></FormControl>
                          </div>
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(
                              formState.previousValues[leanWeight]
                            )}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked["fat_weight"]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.checked["fat_weight"]
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  name={"fat_weight"}
                                />
                              }
                              className={classes.label}
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          Fat Weight
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          <div className={classes.inputRoot}>
                            <Input
                              name={fatWeight}
                              placeholder="0"
                              value={formState.values[fatWeight] || ""}
                              error={hasError(fatWeight)}
                              disabled={formState.isActive === false}
                              variant="outlined"
                              required
                              InputProps={{
                                readOnly: true
                              }}
                            />
                          </div>
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(formState.previousValues[fatWeight])}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked["current_calorific_intake"]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.checked[
                                      "current_calorific_intake"
                                    ]
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  name={"current_calorific_intake"}
                                />
                              }
                              className={classes.label}
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          Current Caloric Intake
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          <div className={classes.inputRoot}>
                            <Input
                              name={currCalorieIntake}
                              placeholder="0"
                              value={formState.values[currCalorieIntake] || ""}
                              error={hasError(currCalorieIntake)}
                              variant="outlined"
                              required
                              disabled={formState.isActive === false}
                              onChange={handleChangeInput}
                              onBlur={handleSaveUpdateGraph}
                              helperText={
                                hasError(currCalorieIntake)
                                  ? formState.errors[currCalorieIntake].map(
                                      error => {
                                        return error + " ";
                                      }
                                    )
                                  : null
                              }
                              inputProps={{
                                readOnly: clientView === true ? true : false
                              }}
                            />
                          </div>
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(
                              formState.previousValues[currCalorieIntake]
                            )}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked["new_calorific_intake"]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.checked["new_calorific_intake"]
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  name={"new_calorific_intake"}
                                />
                              }
                              className={classes.label}
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          New Caloric Intake
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          <div className={classes.inputRoot}>
                            <Input
                              name={newCalorieIntake}
                              placeholder="0"
                              value={formState.values[newCalorieIntake] || ""}
                              error={hasError(newCalorieIntake)}
                              variant="outlined"
                              disabled={formState.isActive === false}
                              required
                              onChange={handleChangeInput}
                              onBlur={handleSaveUpdateGraph}
                              helperText={
                                hasError(newCalorieIntake)
                                  ? formState.errors[newCalorieIntake].map(
                                      error => {
                                        return error + " ";
                                      }
                                    )
                                  : null
                              }
                              inputProps={{
                                readOnly: clientView === true ? true : false
                              }}
                            />
                          </div>
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(
                              formState.previousValues[newCalorieIntake]
                            )}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked["current_resting_bmr"]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.checked["current_resting_bmr"]
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  name={"current_resting_bmr"}
                                />
                              }
                              className={classes.label}
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          Current Resting BMR
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          <div className={classes.inputRoot}>
                            <Input
                              name={currRestingBMR}
                              placeholder="0"
                              value={formState.values[currRestingBMR] || ""}
                              error={hasError(currRestingBMR)}
                              variant="outlined"
                              disabled={formState.isActive === false}
                              required
                              InputProps={{
                                readOnly: true
                              }}
                            />
                          </div>
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(
                              formState.previousValues[currRestingBMR]
                            )}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked["current_active_bmr"]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.checked["current_active_bmr"]
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  name={"current_active_bmr"}
                                />
                              }
                              className={classes.label}
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          Current Active BMR
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          <div className={classes.inputRoot}>
                            <Input
                              name={currActiveBMR}
                              placeholder="0"
                              value={formState.values[currActiveBMR] || ""}
                              error={hasError(currActiveBMR)}
                              variant="outlined"
                              disabled={formState.isActive === false}
                              required
                              InputProps={{
                                readOnly: true
                              }}
                            />
                          </div>
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(
                              formState.previousValues[currActiveBMR]
                            )}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked["bmr_functional_percent"]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.checked["bmr_functional_percent"]
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  name={"bmr_functional_percent"}
                                />
                              }
                              className={classes.label}
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          BMR % Functional
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          <div className={classes.inputRoot}>
                            <Input
                              name={bmrFuncPercent}
                              placeholder="0"
                              value={
                                convertNumber(
                                  formState.values[bmrFuncPercent]
                                ) || ""
                              }
                              error={hasError(bmrFuncPercent)}
                              variant="outlined"
                              disabled={formState.isActive === false}
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="start">
                                    %
                                  </InputAdornment>
                                )
                              }}
                              required
                            />
                          </div>
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(
                              formState.previousValues[bmrFuncPercent]
                            )}
                          </TableCell>
                        ) : null}
                      </TableRow>
                      <TableRow
                        className={classes.tableRowStyle}
                        selected={formState.checked["bmr_suppresed_percent"]}
                        hover={true}
                      >
                        <TableCell className={classes.tableCellStyle}>
                          <div className={classes.checkBoxStyle}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    formState.checked["bmr_suppresed_percent"]
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  name={"bmr_suppresed_percent"}
                                />
                              }
                              className={classes.label}
                            />
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCellStyle}>
                          BMR % Suppressed
                        </TableCell>
                        <TableCell
                          className={classes.tableCellStyle}
                          align="center"
                        >
                          <div className={classes.inputRoot}>
                            <Input
                              name={bmrSuppPercent}
                              placeholder="0"
                              value={
                                convertNumber(
                                  formState.values[bmrSuppPercent]
                                ) || ""
                              }
                              disabled={formState.isActive === false}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    %
                                  </InputAdornment>
                                ),
                                readOnly: true
                              }}
                              variant="outlined"
                              required
                            />
                          </div>
                        </TableCell>
                        {isPreviousPresent ? (
                          <TableCell
                            className={classes.tableCellStyle}
                            align="center"
                          >
                            {convertNumber(
                              formState.previousValues[bmrSuppPercent]
                            )}
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    marginLeft: "7rem"
                  }}
                >
                  <DateRange
                    id={popOverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClick={handleClickPopUpOpen}
                    handlePopUpCheckBox={handlePopUpCheckBox}
                    onClose={handleClose}
                    handleInputChange={handleInputChange}
                    recentVisits={state.recentVisits}
                    selectDateRange={state.selectDateRange}
                    noOfVisits={state.noOfVisits}
                    valueNoOfVisitis={dateFilter.noOfVisits}
                    valueFromDate={dateFilter.fromDate}
                    valueToDate={dateFilter.toDate}
                    dateFromError={dateFilter.dateFromError}
                    dateToError={dateFilter.dateToError}
                    dateFromErrorText={dateFilter.dateFromErrorText}
                    dateToErrorText={dateFilter.dateToErrorText}
                    dateNoOfVisitsError={dateFilter.dateNoOfVisitsError}
                    dateNoOfVisitsErrorText={dateFilter.dateNoOfVisitsErrorText}
                    handleStartDateChange={event =>
                      handleStartDateChange(event)
                    }
                    handleEndDateChange={event => handleEndDateChange(event)}
                    handleClickFilterResetButton={handleClickFilterResetButton}
                    handleClickFilterDoneButton={handleClickFilterDoneButton}
                    filterButtonName={state.filterButtonName}
                  ></DateRange>
                </Grid>
                <Card
                  elevation={0}
                  className={isDesktop ? classes.paddingStyle : null}
                >
                  <CardHeader
                    title={
                      <div style={{}}>
                        <Typography className={classes.cardHeaderStyle}>
                          Weight History
                        </Typography>
                      </div>
                    }
                    action={
                      <Grid container>
                        <Grid item>
                          <div
                            style={{ textAlign: "right", padding: "1.875rem" }}
                          >
                            <BarChartIcon
                              variant="outlined"
                              style={{
                                height: "35px",
                                width: "35px",
                                marginRight: "15px",
                                color:
                                  radioValueGraphOne === "Bar Graph"
                                    ? "#1C4979"
                                    : "#808080"
                              }}
                              onClick={() =>
                                handleChangeRadioGraphOne("Bar Graph")
                              }
                            />

                            <TimelineIcon
                              variant="contained"
                              style={{
                                height: "35px",
                                width: "35px",
                                marginRight: "15px",
                                color:
                                  radioValueGraphOne === "Line Graph"
                                    ? "#1C4979"
                                    : "#808080"
                              }}
                              onClick={() =>
                                handleChangeRadioGraphOne("Line Graph")
                              }
                            />
                          </div>
                        </Grid>
                      </Grid>
                    }
                  ></CardHeader>

                  <CardContent>
                    <Box className={classes.responsiveContainerWrap}>
                      {graphData.showGraph ? (
                        <div style={{ marginTop: "150px" }}>
                          <CircularProgress color="secondary" />
                        </div>
                      ) : radioValueGraphOne === "Line Graph" ? (
                        <ResponsiveContainer
                          id="responsiveContainer"
                          width={imageSize.width}
                          height={imageSize.height}
                        >
                          <LineChart
                            data={graphData.graphDataArray[0]}
                            height={imageSize.height}
                            margin={{
                              top: 5,
                              bottom: 5
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey={xAxis[0]}
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                color: "#8A8A97"
                              }}
                            />
                            <YAxis
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                color: "#8A8A97"
                              }}
                            />
                            <Tooltip
                              content={<CustomTooltip />}
                              cursor={false}
                              position="top"
                            />
                            <Legend
                              iconType="square"
                              iconSize={18}
                              formatter={renderColorfulLegendText}
                              wrapperStyle={{ bottom: "-20px" }}
                            />
                            {yAxis.map((number, index) => (
                              <Line
                                connectNulls
                                id={index}
                                type="monotone"
                                dataKey={number.name}
                                stroke={number.paramColor}
                                fill={number.areaColor}
                                strokeWidth={2.5}
                              />
                            ))}
                          </LineChart>
                        </ResponsiveContainer>
                      ) : (
                        <ResponsiveContainer
                          id="responsiveContainer"
                          width={imageSize.width}
                          height={imageSize.height}
                        >
                          <BarChart
                            data={graphData.graphDataArray[0]}
                            height={imageSize.height}
                            margin={{
                              top: 5,
                              bottom: 5
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey={xAxis[0]}
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                color: "#8A8A97"
                              }}
                            />
                            <YAxis
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                color: "#8A8A97"
                              }}
                            />{" "}
                            <Tooltip
                              content={<CustomTooltip />}
                              cursor={false}
                              position="top"
                            />
                            <Legend
                              iconType="square"
                              iconSize={18}
                              formatter={renderColorfulLegendText}
                              wrapperStyle={{ bottom: "-20px" }}
                            />
                            {yAxis.map((number, index) => (
                              <Bar
                                id={index}
                                type="monotone"
                                dataKey={number.name}
                                stroke={number.paramColor}
                                fill={number.areaColor}
                                strokeWidth={2.5}
                              />
                            ))}
                          </BarChart>
                        </ResponsiveContainer>
                      )}
                    </Box>
                  </CardContent>
                </Card>
                <Card
                  elevation={0}
                  className={isDesktop ? classes.paddingStyle : null}
                >
                  <CardHeader
                    title={
                      <Typography className={classes.cardHeaderStyle}>
                        Caloric and BMR
                      </Typography>
                    }
                    action={
                      <div
                        style={{ textAlign: "right", marginRight: "1.875rem" }}
                      >
                        <BarChartIcon
                          variant="outlined"
                          style={{
                            height: "35px",
                            width: "35px",
                            marginRight: "15px",
                            color:
                              radioValueGraphTwo === "Bar Graph"
                                ? "#1C4979"
                                : "#808080"
                          }}
                          onClick={() => handleChangeRadioGraphTwo("Bar Graph")}
                        />

                        <TimelineIcon
                          variant="contained"
                          style={{
                            height: "35px",
                            width: "35px",
                            marginRight: "15px",
                            color:
                              radioValueGraphTwo === "Line Graph"
                                ? "#1C4979"
                                : "#808080"
                          }}
                          onClick={() =>
                            handleChangeRadioGraphTwo("Line Graph")
                          }
                        />
                      </div>
                    }
                  />

                  <CardContent>
                    <Box className={classes.responsiveContainerWrap}>
                      {graphData.showGraph ? (
                        <div style={{ marginTop: "150px" }}>
                          <CircularProgress color="secondary" />
                        </div>
                      ) : radioValueGraphTwo === "Line Graph" ? (
                        <ResponsiveContainer
                          id="responsiveContainer"
                          width={imageSize.width}
                          height={imageSize.height}
                        >
                          <LineChart
                            data={graphData.graphDataArray[0]}
                            height={imageSize.height}
                            margin={{
                              top: 5,
                              bottom: 5
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey={xAxis[0]}
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                color: "#8A8A97"
                              }}
                            />
                            <YAxis
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                color: "#8A8A97"
                              }}
                            />{" "}
                            <Tooltip
                              content={<CustomTooltip />}
                              cursor={false}
                              position="top"
                            />
                            <Legend
                              iconType="square"
                              iconSize={18}
                              formatter={renderColorfulLegendText}
                              wrapperStyle={{ bottom: "-20px" }}
                            />
                            {graphDataYaxisForCalorie.map((number, index) => (
                              <Line
                                connectNulls
                                id={index}
                                type="monotone"
                                dataKey={number.name}
                                stroke={number.paramColor}
                                strokeWidth={2.5}
                              />
                            ))}
                          </LineChart>
                        </ResponsiveContainer>
                      ) : (
                        <ResponsiveContainer
                          id="responsiveContainer"
                          width={imageSize.width}
                          height={imageSize.height}
                        >
                          <BarChart
                            data={graphData.graphDataArray[0]}
                            height={imageSize.height}
                            margin={{
                              top: 5,
                              bottom: 5
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey={xAxis[0]}
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                color: "#8A8A97"
                              }}
                            />
                            <YAxis
                              style={{
                                fontSize: "0.875rem",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                color: "#8A8A97"
                              }}
                            />{" "}
                            <Tooltip
                              content={<CustomTooltip />}
                              cursor={false}
                              position="top"
                            />
                            <Legend
                              iconType="square"
                              iconSize={18}
                              formatter={renderColorfulLegendText}
                              wrapperStyle={{ bottom: "-20px" }}
                            />
                            {graphDataYaxisForCalorie.map((number, index) => (
                              <Bar
                                id={index}
                                type="monotone"
                                dataKey={number.name}
                                stroke={number.paramColor}
                                fill={number.areaColor}
                                strokeWidth={2.5}
                              />
                            ))}
                          </BarChart>
                        </ResponsiveContainer>
                      )}
                    </Box>
                  </CardContent>
                </Card>
                <Backdrop className={classes.backdrop} open={loader}>
                  <CircularProgress color="inherit" />
                </Backdrop>

                <Grid>
                  <SnackBar
                    open={statusVariable.isOpenSnackBar}
                    severity={statusVariable.isError}
                    onClose={handleSnackbarClose}
                  >
                    {statusVariable.successErrorMessage}
                  </SnackBar>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
});

export default BodyMass;
