import { React, useState } from "react";
import {
  Typography,
  Backdrop,
  CircularProgress,
  Grid,
  AppBar,
  Toolbar,
  Card,
  CardContent,
  Box,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { commonStyles } from "../../../common";
import { Input, BorderLessButton, DarkBlueButton } from "../../../components";
import { useHistory } from "react-router-dom";
import { VERIFYOTP, LOGIN } from "../../../paths/Paths";
import {
  checkAllKeysPresent,
  getListOfKeysNotPresent,
  setErrors,
  checkEmpty
} from "../../../utils";
import { get } from "lodash";
import form from "./form.json";
import { providerForPublicPost } from "../../../api";
import { requestOtpForgotPassURL } from "../../../constants";
import Logo from "../../../components/Logo/Logo";

import Styles from "./ForgotPassword.module.css";

const useStyles = makeStyles(theme => ({
  container: {
    margin: "117px auto 50px auto"
  },
  loginTextDesktop: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1.25rem",
    color: "#110F48"
  },
  loginTextMobile: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#110F48",
    fontSize: "1.25rem"
  },
  form: {
    width: "100%"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#0152CC",
    fontFamily: "Montserrat",
    fontWeight: 400,
    color: "#fff",
    fontSize: "0.875rem"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    position: "relative"
  },
  welcomeText: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000000",
    fontSize: "0.9375rem"
  },
  inputText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1rem"
  },
  label: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.875rem"
  }
}));

const ForgotPassword = () => {
  const identifier = "identifier";
  const classes = useStyles();
  const commonClass = commonStyles();
  const history = useHistory();
  const themes = useTheme();

  const isDesktop = useMediaQuery(themes.breakpoints.up("lg"), {
    defaultMatches: true
  });

  /** Use state for form */
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const [isErrorWhileSendingOTP, setIsErrorWhileSendingOTP] = useState(false);
  const [otpStatusMessage, setOtpStatusMessage] = useState("");

  const handleChange = ({ target }) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [target.name]: target.value
      },
      touched: {
        ...formState.touched,
        [target.name]: true
      }
    }));
    delete formState.errors[target.name];
    setIsErrorWhileSendingOTP(false);
  };

  const onCancel = () => {
    history.push({
      pathname: LOGIN
    });
  };

  const hasError = field => {
    return formState.errors[field] ? true : false;
  };

  const sendOtpToIdentifier = () => {
    let payload = {
      [identifier]: formState.values[identifier],
      reason: "forgot_pass"
    };
    providerForPublicPost(requestOtpForgotPassURL, payload, {
      desc: "Request OTP for Forgot password"
    })
      .then(res => {
        history.push({
          pathname: VERIFYOTP,
          identifier: formState.values.identifier,
          type: form[identifier].label
        });
      })
      .catch(error => {
        console.log("Error", error);
        setIsErrorWhileSendingOTP(true);
        setOtpStatusMessage(error.response.data.message);
      });
  };

  /** checkOtp
   * If form is valid, function sendOtp is called
   * @param myParam Identifier is required which can be registered username/email
   */
  const checkOtp = () => {
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(formState.values, form);

    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, form);
      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(formState.values, form);
      formState.errors = setErrors(formState.values, form);
    }
    if (isValid) {
      setFormState(formState => ({
        ...formState,
        isValid: true
      }));
      sendOtpToIdentifier();
    } else {
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
    }
  };

  return (
    <>
      <AppBar position="fixed" className={Styles.loginAppBar} elevation={0}>
        <Toolbar className={Styles.loginToolbar}>
          <Logo className={Styles.loginLogo} />
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" className={classes.container}>
        <Card variant="elevation" elevation={2}>
          <CardContent className={Styles.loginCardContent}>
            <Box>
              <Typography
                className={
                  isDesktop ? classes.loginTextDesktop : classes.loginTextMobile
                }
                align="center"
              >
                Account Recovery
              </Typography>
            </Box>
            <Box>
              <Typography align="center" className={classes.welcomeText}>
                Please enter your registered Email/Username
              </Typography>
            </Box>

            <form className={classes.form} noValidate>
              <Input
                id={get(form[identifier], "id")}
                variant="outlined"
                margin="normal"
                error={hasError("identifier") || isErrorWhileSendingOTP}
                fullWidth
                autoFocus={get(form[identifier], "autoFocus")}
                helperText={
                  hasError(identifier)
                    ? formState.errors[identifier].map(error => {
                        return error + " ";
                      })
                    : isErrorWhileSendingOTP
                    ? otpStatusMessage
                    : null
                }
                label={get(form[identifier], "label")}
                name={identifier}
                onChange={handleChange}
                type={get(form[identifier], "type")}
                value={formState.values[identifier] || ""}
                className={classes.inputText}
              />
              <Grid
                container
                spacing={2}
                style={{ justifyContent: "flex-end" }}
              >
                <Grid item>
                  <BorderLessButton
                    type="button"
                    variant="contained"
                    onClick={onCancel}
                    style={{
                      marginRight: 15,
                      marginTop: 20
                    }}
                  >
                    Back
                  </BorderLessButton>

                  <DarkBlueButton
                    type="button"
                    variant="contained"
                    onClick={checkOtp}
                    style={{
                      marginTop: 20
                    }}
                  >
                    Send OTP
                  </DarkBlueButton>
                </Grid>
              </Grid>
            </form>

            <Backdrop className={commonClass.backdrop}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default ForgotPassword;
