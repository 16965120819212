import { COLORS } from "./PfcTheme";
import { ADD_TEXT, EDIT_CLIENT_TEXT, EDIT_TEXT } from "./genericConstants";
import {
  loginURL,
  updateContact,
  apiBackendUrl,
  contacts,
  findIfDataAvailableInContacts,
  franchise,
  franchiseSites,
  checkIfFranchiseSiteIsUsed,
  schoolingForFranchise,
  schoolingForAdmin,
  userTagForAdmin,
  userTagForFranchise,
  getRelation,
  getGender,
  getGenderForFranchise,
  getMaritalStatus,
  getCountry,
  requestOtpForgotPassURL,
  requestOtpTwoFAURL,
  verifyOtpForgotPassURL,
  verifyOtpForTwoFAURL,
  changePasswordURL,
  forgotPasswordURL,
  franchiseAdmin,
  supplement,
  supplementForFranchise,
  user,
  findIfDataAvailableUser,
  getUsers,
  getRole,
  exercise,
  exerciseForFranchise,
  food,
  checkIfDataAailableForFood,
  checkIfDataAailableForFoodCatAndSubCat,
  checkIfSupplementDataAvailable,
  checkIfExerciseDataAvailable,
  checkIfMuscleGroupDataAvailable,
  foodForFranchise,
  foodCategory,
  foodSubCategory,
  foodSubCategoryForFranchise,
  sizeWeight,
  sizeWeightForFranchise,
  muscleGroup,
  muscleGroupForFranchise,
  addClient,
  addAdmin,
  addNutritionist,
  updateNutritionist,
  getClient,
  getOneClient,
  updateClient,
  getSingleClientData,
  getQRCode,
  Check2FaCode,
  printPdf,
  viewPdf,
  downloadSupplementsPdf,
  downloadSpecificMealsForTodayPdf,
  downloadActualFoodForAllMealsBreakdownPdf,
  getGraphDataUrl,
  getCholestrolGraphData,
  getPreviousMeasurementDataUrl,
  getSingleDayBreakdownPdf,
  getAllDaysBreakdownPdf,
  getPreviousBmiDataUrl,
  userMe,
  deleteUserContact,
  verifyPasswordUrl,
  Verify2FaCode,
  userLogOut,
  getSuperAdminFranchiseId,
  getCurrentScheduleOfClient,
  checkClientValidityForAFranchise,
  createScheduleApi,
  getBMofSchedule,
  bodyMassTable,
  getCholestrolOfSchedule,
  cholesterols_url,
  getUsersDataUrl,
  verifyPassForLogs,
  addUpdateSupplementData,
  getSupplementDataOfASchedule,
  checkEmailAvailable,
  getSupplementTemplate,
  updateProfile,
  getSingleMealData,
  savemealInfo,
  addUpdateSupplementTemplateData,
  getSupplementTemplateData,
  deleteSupplementTemplateData,
  saveAllMealsGoals,
  getAllMealInfoApi,
  addUpdateExerciseData,
  getDataForWorkout,
  getMenuForTodaysMeals,
  getMenuForWeekMeal,
  replicateMeal,
  getClientScheduleData,
  addUpdateExerciseTemplate,
  getWorkoutTemplate,
  getTemplateDataForWorkout,
  createExerciseReport,
  getWorkoutTemplateForFranchise,
  generateShoppingList,
  getSupplementTemplateForFranchise,
  useTemplate,
  saveFavoriteExercise,
  deleteWorkoutTemplateData,
  getAllPreviousSchedules,
  getExchangesAndMenuTemplate,
  createMealTemplate,
  getMealTemplate,
  getClientGender,
  duplicateScheduleData,
  ctAllData,
  getAllMealInfoForTemplate,
  saveAllMealsGoalsForTemplate,
  getExchangeMealInfoForAExchangeDay,
  savemealInfoForTemplate,
  getAge,
  loadFavoritesForMeals,
  saveFavoritesForMeal,
  replicateMealTemplate,
  deleteClientData,
  getAllCountries,
  deleteExchangeMealTemplateData,
  getAllReports,
  downloadGiveAllDocumentGeneric,
  getGenericDocument,
  generateGiveAllWithInserts,
  getScheduleDateForClient,
  getSupplementTemplateDataForTemplate,
  getShoppingListForSchedule,
  getExchangeDayClientNotes,
  getWorkoutDayInfo,
  updateTrackedStatus,
  clientNotes,
  nutritionistNotes,
  clientNotesIcons,
  getCustomizationUrl,
  nutritionistNotesIcon,
  getNutrititionistNotes,
  getClientNotes,
  franchiseAdminWithPaging,
  getFranchiseSitesForAdmin,
  getDocumentListUrl,
  addUpdateClientNotes,
  addUpdateNutrititionistNotes,
  clientAddNewNotesTitleLogo,
  nutritionistAddNewNotesTitleLogo,
  updateDocumentListUrl,
  findMuscleGroupForAdmin,
  notes,
  food_diary,
  saveFoodDiary,
  policyacceptstatus,
  getClientScheduleDates
} from "./urlConstants";

export {
  COLORS,
  ADD_TEXT,
  EDIT_CLIENT_TEXT,
  EDIT_TEXT,
  loginURL,
  requestOtpForgotPassURL,
  requestOtpTwoFAURL,
  verifyOtpForgotPassURL,
  verifyOtpForTwoFAURL,
  changePasswordURL,
  forgotPasswordURL,
  updateContact,
  getDocumentListUrl,
  updateDocumentListUrl,
  apiBackendUrl,
  contacts,
  findIfDataAvailableInContacts,
  franchise,
  franchiseSites,
  checkIfFranchiseSiteIsUsed,
  schoolingForFranchise,
  schoolingForAdmin,
  userTagForAdmin,
  userTagForFranchise,
  getRelation,
  getGender,
  getGenderForFranchise,
  getMaritalStatus,
  getCountry,
  franchiseAdmin,
  supplement,
  supplementForFranchise,
  user,
  findIfDataAvailableUser,
  getUsers,
  getRole,
  exercise,
  exerciseForFranchise,
  food,
  checkIfDataAailableForFood,
  checkIfDataAailableForFoodCatAndSubCat,
  checkIfSupplementDataAvailable,
  checkIfExerciseDataAvailable,
  checkIfMuscleGroupDataAvailable,
  foodForFranchise,
  foodCategory,
  foodSubCategory,
  foodSubCategoryForFranchise,
  sizeWeight,
  sizeWeightForFranchise,
  muscleGroup,
  muscleGroupForFranchise,
  addClient,
  addAdmin,
  addNutritionist,
  updateNutritionist,
  getClient,
  getOneClient,
  updateClient,
  getSingleClientData,
  getQRCode,
  Check2FaCode,
  printPdf,
  viewPdf,
  downloadSupplementsPdf,
  downloadSpecificMealsForTodayPdf,
  downloadActualFoodForAllMealsBreakdownPdf,
  getGraphDataUrl,
  getCholestrolGraphData,
  getPreviousMeasurementDataUrl,
  getSingleDayBreakdownPdf,
  getAllDaysBreakdownPdf,
  getPreviousBmiDataUrl,
  userMe,
  deleteUserContact,
  verifyPasswordUrl,
  Verify2FaCode,
  userLogOut,
  getSuperAdminFranchiseId,
  getCurrentScheduleOfClient,
  checkClientValidityForAFranchise,
  createScheduleApi,
  getBMofSchedule,
  bodyMassTable,
  getCholestrolOfSchedule,
  cholesterols_url,
  getUsersDataUrl,
  verifyPassForLogs,
  addUpdateSupplementData,
  getSupplementDataOfASchedule,
  checkEmailAvailable,
  getSupplementTemplate,
  updateProfile,
  getSingleMealData,
  savemealInfo,
  addUpdateSupplementTemplateData,
  getSupplementTemplateData,
  deleteSupplementTemplateData,
  saveAllMealsGoals,
  getAllMealInfoApi,
  addUpdateExerciseData,
  getDataForWorkout,
  getMenuForTodaysMeals,
  getMenuForWeekMeal,
  replicateMeal,
  getClientScheduleData,
  addUpdateExerciseTemplate,
  getWorkoutTemplate,
  getTemplateDataForWorkout,
  createExerciseReport,
  getWorkoutTemplateForFranchise,
  generateShoppingList,
  getSupplementTemplateForFranchise,
  useTemplate,
  saveFavoriteExercise,
  deleteWorkoutTemplateData,
  getAllPreviousSchedules,
  getExchangesAndMenuTemplate,
  createMealTemplate,
  getMealTemplate,
  getClientGender,
  duplicateScheduleData,
  ctAllData,
  getAllMealInfoForTemplate,
  saveAllMealsGoalsForTemplate,
  getExchangeMealInfoForAExchangeDay,
  savemealInfoForTemplate,
  getAge,
  loadFavoritesForMeals,
  saveFavoritesForMeal,
  replicateMealTemplate,
  deleteClientData,
  getAllCountries,
  deleteExchangeMealTemplateData,
  getAllReports,
  downloadGiveAllDocumentGeneric,
  getGenericDocument,
  generateGiveAllWithInserts,
  getScheduleDateForClient,
  getSupplementTemplateDataForTemplate,
  getShoppingListForSchedule,
  getExchangeDayClientNotes,
  getWorkoutDayInfo,
  updateTrackedStatus,
  clientNotes,
  nutritionistNotes,
  clientNotesIcons,
  getCustomizationUrl,
  nutritionistNotesIcon,
  getNutrititionistNotes,
  getClientNotes,
  franchiseAdminWithPaging,
  getFranchiseSitesForAdmin,
  addUpdateClientNotes,
  addUpdateNutrititionistNotes,
  clientAddNewNotesTitleLogo,
  nutritionistAddNewNotesTitleLogo,
  findMuscleGroupForAdmin,
  notes,
  food_diary,
  saveFoodDiary,
  policyacceptstatus,
  getClientScheduleDates
};

export {
  countryCodeList,
  countryCode,
  usaState,
  bodyMassTitle,
  measurementsTitle,
  exchangeMenuTitle,
  supplementTitle,
  exerciseTitle,
  foodDiaryTitle,
  defaultIconsModel,
  calculateAspectRatioFit
} from "./utils";
