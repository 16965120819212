import React from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router";
import {
  LogIn,
  Users,
  Schooling,
  ChangePassword,
  Gender,
  EmergencyContacts,
  MaritalStatus,
  Country,
  Client,
  Nutritionist,
  AddEditClient,
  AuditLogs,
  AllDocumentListPermission,
  Franchise,
  ManageClient,
  ForgotPassword,
  VerifyOtp,
  ResetPassword,
  PasswordChanged,
  TwoFactorAuth,
  VerifyCode,
  Supplement,
  Exercise,
  Food,
  FoodCategory,
  FoodSubCategory,
  SizeWeight,
  MuscleGroup,
  ManageSchedule,
  AddEditUsers,
  AddEditFoodSubCategory,
  AddEditSupplement,
  AddEditExercise,
  AddEditMuscleGroup,
  AddEditFranchise,
  AddEditSchooling,
  AddEditGender,
  AddEditEmergencyContact,
  AddEditMaritalStatus,
  AddEditCountry,
  UserTag,
  AddEditUserTag,
  BodyMass,
  Measurements,
  Exchange,
  SupplementSchedule,
  CreateMeals,
  SupplementTemplate,
  AddEditSupplementScheduleTemplate,
  Profile,
  WorkoutSchedule,
  ExerciseTemplate,
  AddEditExerciseTemplate,
  ExchangeTemplate,
  CreateMealTemplate,
  ManageExchangeTemplate,
  ClientDashboard,
  ClientMyReports,
  ClientMySchedule,
  ClientExercise,
  ClientExchange,
  ClientMyShoppingList,
  ClientMeasurementNotes,
  ClientBodyMassNotes,
  ClientExchangeNotes,
  CustomColors,
  ViewDocument,
  ManageNotes,
  FoodDiary,
  NewAccount
} from "../containers";
import { Logout } from "../components";
import {
  LOGIN,
  NUTRITIONIST,
  USERS,
  SCHOOLING,
  GENDER,
  EMERGENCYCONTACTS,
  MARITALSTATUS,
  COUNTRIES,
  CLIENT,
  CHANGEPASSWORD,
  LOGOUT,
  ADDCLIENT,
  EDITCLIENT,
  AUDITLOGS,
  DOCUMENTLIST,
  FRANCHISE,
  MANAGECLIENTS,
  EDITFRANCHISE,
  FORGOTPASSWORD,
  VERIFYOTP,
  RESETPASSWORD,
  PASSWORDCHANGED,
  TWOFACTORAUTH,
  VERIFYCODE,
  SUPPLEMENT,
  EXERCISE,
  FOOD,
  FOODCATEGORY,
  FOODSUBCATEGORY,
  SIZEWEIGHT,
  MUSCLEGROUP,
  SCHEDULE,
  AUTHENTICATOR,
  ADDFOODSUBCATEGORY,
  EDITFOODSUBCATEGORY,
  ADDUSERS,
  EDITUSERS,
  ADDSIZEWEIGHT,
  EDITSIZEWEIGHT,
  ADDSUPPLEMENT,
  EDITSUPPLEMENT,
  ADDEXERCISE,
  EDITEXERCISE,
  ADDMUSCLEGROUP,
  EDITMUSCLEGROUP,
  ADDFOODCATEGORY,
  EDITFOODCATEGORY,
  ADDFOOD,
  EDITFOOD,
  ADDFRANCHISE,
  ADDSCHOOLING,
  EDITSCHOOLING,
  ADDGENDER,
  EDITGENDER,
  ADDEMERGENCYCONTACTS,
  EDITEMERGENCYCONTACTS,
  ADDMARITALSTATUS,
  EDITMARITALSTATUS,
  ADDCOUNTRIES,
  EDITCOUNTRIES,
  USERTAG,
  ADDUSERTAG,
  EDITUSERTAG,
  BODYMASS,
  MEASUREMENTS,
  EXCHANGEMENU,
  SUPPLEMENTS,
  CREATEMEALS,
  SUPPLEMENTTEMPLATE,
  ADDSUPPLEMENTTEMPLATE,
  EDITSUPPLEMENTTEMPLATE,
  GIVEALL,
  VIEWPROFILE,
  VIEWFILE,
  WORKOUT,
  EXERCISETEMPLATE,
  ADDEXERCISETEMPLATE,
  EDITEXERCISETEMPLATE,
  EX_TEMPLATE,
  CREATEMEALTEMPLATE,
  MANAGEEXCHANGEMEALTEMPLATE,
  CLIENTDASHBAORD,
  CLIENTMYNOTES,
  CLIENTMYPROFILE,
  CLIENTMYREPORTS,
  CLIENTMYSCHEDULE,
  CLIENTSUPPLEMENT,
  CLIENTEXERCISE,
  FRANCHISESITES,
  ADDFRANCHISESITES,
  EDITFRANCHISESITES,
  CLIENTEXCHANGE,
  CLIENTMEASUREMENT,
  CLIENTBODYMASS,
  CLIENTSHOPPINGLIST,
  CLIENTBODYMASSNOTES,
  CLIENTMEALS,
  CLIENTMEASUREMENTSNOTES,
  CLIENTEXCHANGENOTES,
  CUSTOMCOLOR,
  ADDEDITNOTES,
  CLIENTFOODDIARY,
  NEWACCOUNT
} from "../paths";
import PrivateRoute from "./PrivateRoute";
import DefaultRoute from "./DefaultRoute";
import PublicRoute from "./PublicRoute";
import RouteWithTabLayOut from "./RouteWithTabLayOut";
import {
  AddEditFood,
  AddEditFoodCategory,
  AddEditSizeWeight
} from "../containers/Food";
import { Authenticator } from "../containers/Authenticator";
import NutritionistClientRoute from "./NutritionistClientRoute";

import GiveAllDoc from "../containers/GiveAllDocument/GiveAllDocument";
import ExchangesMenusFavourites from "./ExchangesMenusFavourites";
import ClientRoute from "./ClientRoute";
import { ClientSupplement } from "../containers/ClientSupplement";
import { AddEditFranchiseSites } from "../containers/FranchiseSites/AddEditFranchiseSites";
import { FranchiseSites } from "../containers/FranchiseSites";
import AddEditNotes from "../containers/Notes/AddEditNotes";

const Routes = () => {
  return (
    <div>
      <Switch>
        {/**
         * Schedule route for nutritionist and client
         */}
        <NutritionistClientRoute
          path={SCHEDULE}
          exact
          component={ManageSchedule}
        />
        {/**
         * Dashboard route for client
         */}
        <ClientRoute
          path={CLIENTDASHBAORD}
          exact
          component={ClientDashboard}
        ></ClientRoute>
        <ClientRoute
          path={CLIENTMEALS}
          exact
          component={CreateMeals}
        ></ClientRoute>
        <NutritionistClientRoute
          path={CLIENTMYNOTES}
          exact
          component={ManageNotes}
        ></NutritionistClientRoute>
        <NutritionistClientRoute
          path={CLIENTFOODDIARY}
          exact
          component={FoodDiary}
        ></NutritionistClientRoute>
        <NutritionistClientRoute
          path={ADDEDITNOTES}
          exact
          component={AddEditNotes}
        ></NutritionistClientRoute>
        <ClientRoute
          path={CLIENTEXCHANGENOTES}
          exact
          component={ClientExchangeNotes}
        ></ClientRoute>
        <ClientRoute
          path={CLIENTMYPROFILE}
          exact
          component={AddEditClient}
        ></ClientRoute>
        <ClientRoute
          path={CLIENTMYREPORTS}
          exact
          component={ClientMyReports}
        ></ClientRoute>
        <ClientRoute
          path={CLIENTMYSCHEDULE}
          exact
          component={ClientMySchedule}
        ></ClientRoute>
        <ClientRoute
          path={CLIENTSUPPLEMENT}
          exact
          component={ClientSupplement}
        ></ClientRoute>
        <ClientRoute
          path={CLIENTEXERCISE}
          exact
          component={ClientExercise}
        ></ClientRoute>
        <ClientRoute
          path={CLIENTEXCHANGE}
          exact
          component={ClientExchange}
        ></ClientRoute>
        <ClientRoute
          path={CLIENTMEASUREMENT}
          exact
          component={Measurements}
        ></ClientRoute>
        <ClientRoute
          path={CLIENTBODYMASS}
          exact
          component={BodyMass}
        ></ClientRoute>
        <ClientRoute
          path={VIEWFILE}
          exact
          component={ViewDocument}
        ></ClientRoute>
        <ClientRoute
          path={CLIENTSHOPPINGLIST}
          exact
          component={ClientMyShoppingList}
        ></ClientRoute>
        <ClientRoute
          path={CLIENTBODYMASSNOTES}
          exact
          component={ClientBodyMassNotes}
        ></ClientRoute>
        <ClientRoute
          path={CLIENTMEASUREMENTSNOTES}
          exact
          component={ClientMeasurementNotes}
        ></ClientRoute>
        {/**
         * For view/edit
         */}
        <RouteWithTabLayOut path={BODYMASS} exact component={BodyMass} />
        <RouteWithTabLayOut
          path={MEASUREMENTS}
          exact
          component={Measurements}
        />
        <RouteWithTabLayOut path={WORKOUT} exact component={WorkoutSchedule} />
        <RouteWithTabLayOut path={EXCHANGEMENU} exact component={Exchange} />
        <RouteWithTabLayOut path={CREATEMEALS} exact component={CreateMeals} />
        <RouteWithTabLayOut
          path={SUPPLEMENTS}
          exact
          component={SupplementSchedule}
        />
        {/**
         *  Other routes
         */}
        {/**
         * Exchanges and meal template
         */}
        <ExchangesMenusFavourites
          path={EX_TEMPLATE}
          exact
          component={ExchangeTemplate}
        />
        <ExchangesMenusFavourites
          path={CREATEMEALTEMPLATE}
          exact
          component={CreateMealTemplate}
        />
        <PrivateRoute
          path={MANAGEEXCHANGEMEALTEMPLATE}
          exact
          component={ManageExchangeTemplate}
        />
        {/**
         * ---------------------
         */}
        <DefaultRoute path={LOGIN} exact component={LogIn} />
        <PublicRoute path={TWOFACTORAUTH} exact component={TwoFactorAuth} />
        <PublicRoute path={VERIFYCODE} exact component={VerifyCode} />
        <PublicRoute path={FORGOTPASSWORD} exact component={ForgotPassword} />
        <PublicRoute path={NEWACCOUNT} exact component={NewAccount} />
        <PublicRoute path={VERIFYOTP} exact component={VerifyOtp} />
        <PublicRoute path={RESETPASSWORD} exact component={ResetPassword} />
        <PublicRoute path={PASSWORDCHANGED} exact component={PasswordChanged} />
        <PrivateRoute path={CLIENT} exact component={Client} />
        <PrivateRoute path={ADDCLIENT} exact component={AddEditClient} />
        <PrivateRoute path={EDITCLIENT} exact component={AddEditClient} />
        <PrivateRoute path={USERS} exact component={Users} />
        <PrivateRoute path={ADDUSERS} exact component={AddEditUsers} />
        <PrivateRoute path={EDITUSERS} exact component={AddEditUsers} />
        <PrivateRoute path={SCHOOLING} exact component={Schooling} />
        <PrivateRoute path={ADDSCHOOLING} exact component={AddEditSchooling} />
        <PrivateRoute path={EDITSCHOOLING} exact component={AddEditSchooling} />
        <PrivateRoute path={GENDER} exact component={Gender} />
        <PrivateRoute path={ADDGENDER} exact component={AddEditGender} />
        <PrivateRoute path={EDITGENDER} exact component={AddEditGender} />
        <PrivateRoute
          path={EMERGENCYCONTACTS}
          exact
          component={EmergencyContacts}
        />
        <PrivateRoute
          path={ADDEMERGENCYCONTACTS}
          exact
          component={AddEditEmergencyContact}
        />
        <PrivateRoute
          path={EDITEMERGENCYCONTACTS}
          exact
          component={AddEditEmergencyContact}
        />
        <PrivateRoute path={MARITALSTATUS} exact component={MaritalStatus} />
        <PrivateRoute
          path={ADDMARITALSTATUS}
          exact
          component={AddEditMaritalStatus}
        />
        <PrivateRoute
          path={EDITMARITALSTATUS}
          exact
          component={AddEditMaritalStatus}
        />
        <PrivateRoute path={COUNTRIES} exact component={Country} />
        <PrivateRoute path={ADDCOUNTRIES} exact component={AddEditCountry} />
        <PrivateRoute path={EDITCOUNTRIES} exact component={AddEditCountry} />
        <PrivateRoute path={USERTAG} exact component={UserTag} />
        <PrivateRoute path={ADDUSERTAG} exact component={AddEditUserTag} />
        <PrivateRoute path={EDITUSERTAG} exact component={AddEditUserTag} />
        <PrivateRoute path={NUTRITIONIST} exact component={Nutritionist} />
        <PrivateRoute path={CHANGEPASSWORD} exact component={ChangePassword} />
        <PrivateRoute path={FRANCHISE} exact component={Franchise} />
        <PrivateRoute path={ADDFRANCHISE} exact component={AddEditFranchise} />
        <PrivateRoute path={EDITFRANCHISE} exact component={AddEditFranchise} />
        <PrivateRoute path={FRANCHISESITES} exact component={FranchiseSites} />
        <PrivateRoute
          path={ADDFRANCHISESITES}
          exact
          component={AddEditFranchiseSites}
        />
        <PrivateRoute
          path={EDITFRANCHISESITES}
          exact
          component={AddEditFranchiseSites}
        />

        <PrivateRoute path={AUDITLOGS} exact component={AuditLogs} />
        <PrivateRoute
          path={DOCUMENTLIST}
          exact
          component={AllDocumentListPermission}
        />
        <PrivateRoute path={MANAGECLIENTS} exact component={ManageClient} />
        <PrivateRoute path={SUPPLEMENT} exact component={Supplement} />
        <PrivateRoute
          path={ADDSUPPLEMENT}
          exact
          component={AddEditSupplement}
        />
        <PrivateRoute
          path={EDITSUPPLEMENT}
          exact
          component={AddEditSupplement}
        />
        <PrivateRoute path={EXERCISE} exact component={Exercise} />
        <PrivateRoute path={CUSTOMCOLOR} exact component={CustomColors} />
        <PrivateRoute path={ADDEXERCISE} exact component={AddEditExercise} />
        <PrivateRoute path={EDITEXERCISE} exact component={AddEditExercise} />
        <PrivateRoute path={FOOD} exact component={Food} />
        <PrivateRoute path={ADDFOOD} exact component={AddEditFood} />
        <PrivateRoute path={EDITFOOD} exact component={AddEditFood} />
        <PrivateRoute path={FOODCATEGORY} exact component={FoodCategory} />
        <PrivateRoute
          path={ADDFOODCATEGORY}
          exact
          component={AddEditFoodCategory}
        />
        <PrivateRoute
          path={EDITFOODCATEGORY}
          exact
          component={AddEditFoodCategory}
        />
        <PrivateRoute
          path={FOODSUBCATEGORY}
          exact
          component={FoodSubCategory}
        />
        <PrivateRoute
          path={ADDFOODSUBCATEGORY}
          exact
          component={AddEditFoodSubCategory}
        />
        <PrivateRoute
          path={EDITFOODSUBCATEGORY}
          exact
          component={AddEditFoodSubCategory}
        />
        <PrivateRoute path={SIZEWEIGHT} exact component={SizeWeight} />
        <PrivateRoute
          path={ADDSIZEWEIGHT}
          exact
          component={AddEditSizeWeight}
        />
        <PrivateRoute
          path={EDITSIZEWEIGHT}
          exact
          component={AddEditSizeWeight}
        />
        <PrivateRoute path={MUSCLEGROUP} exact component={MuscleGroup} />
        <PrivateRoute
          path={ADDMUSCLEGROUP}
          exact
          component={AddEditMuscleGroup}
        />
        <PrivateRoute
          path={EDITMUSCLEGROUP}
          exact
          component={AddEditMuscleGroup}
        />
        <PrivateRoute
          path={SUPPLEMENTTEMPLATE}
          exact
          component={SupplementTemplate}
        />
        <PrivateRoute
          path={ADDSUPPLEMENTTEMPLATE}
          exact
          component={AddEditSupplementScheduleTemplate}
        />
        <PrivateRoute
          path={EDITSUPPLEMENTTEMPLATE}
          exact
          component={AddEditSupplementScheduleTemplate}
        />
        <PrivateRoute
          path={EXERCISETEMPLATE}
          exact
          component={ExerciseTemplate}
        />
        <PrivateRoute
          path={ADDEXERCISETEMPLATE}
          exact
          component={AddEditExerciseTemplate}
        />
        <PrivateRoute
          path={EDITEXERCISETEMPLATE}
          exact
          component={AddEditExerciseTemplate}
        />
        <PrivateRoute path={AUTHENTICATOR} exact component={Authenticator} />
        <PrivateRoute path={GIVEALL} exact component={GiveAllDoc} />
        <PrivateRoute path={VIEWPROFILE} exact component={Profile} />
        <Route path={LOGOUT} exact component={Logout} />
        <DefaultRoute path="*" exact />
      </Switch>
    </div>
  );
};

export default Routes;
