import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./routes";
import { AuthContext, InitContext } from "./context";
import auth from "./components/Auth";
import { apiBackendUrl } from "./constants";

function App() {
  const localStorageUserInfo = auth.getUserInfo();
  const localStorageLogoUrl = auth.getLogoUrl();
  const [isInitial, setInitial] = useState(true);
  const [userInfo, setUserInfo] = useState({
    user: {
      logo: localStorageLogoUrl ? apiBackendUrl + localStorageLogoUrl : null,
      name: localStorageUserInfo ? localStorageUserInfo.full_name : null
    }
  });

  return (
    <InitContext.Provider value={{ isInitial, setInitial }}>
      <AuthContext.Provider value={{ userInfo, setUserInfo }}>
        <Router>
          <Routes />
        </Router>
      </AuthContext.Provider>
    </InitContext.Provider>
  );
}

export default App;
