// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddEditNotes_editor-class__1qxvv figure {\n  margin: 0;\n}\n.AddEditNotes_editor-class__1qxvv .AddEditNotes_rdw-image-left__2nCRm {\n  display: unset;\n  float: left;\n}\n\n.AddEditNotes_editor-class__1qxvv .AddEditNotes_rdw-image-right__3qU60 {\n  display: unset;\n  justify-content: unset;\n  float: right;\n}\n@media only screen and (min-width: 1280px) {\n  .AddEditNotes_backIcon__2aVmv {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/containers/Notes/AddEditNotes.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;AACA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,cAAc;EACd,sBAAsB;EACtB,YAAY;AACd;AACA;EACE;IACE,6BAA6B;IAC7B,6BAA6B;EAC/B;AACF","sourcesContent":[".editor-class figure {\n  margin: 0;\n}\n.editor-class .rdw-image-left {\n  display: unset;\n  float: left;\n}\n\n.editor-class .rdw-image-right {\n  display: unset;\n  justify-content: unset;\n  float: right;\n}\n@media only screen and (min-width: 1280px) {\n  .backIcon {\n    position: relative !important;\n    margin-left: -60px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor-class": "AddEditNotes_editor-class__1qxvv",
	"rdw-image-left": "AddEditNotes_rdw-image-left__2nCRm",
	"rdw-image-right": "AddEditNotes_rdw-image-right__3qU60",
	"backIcon": "AddEditNotes_backIcon__2aVmv"
};
export default ___CSS_LOADER_EXPORT___;
