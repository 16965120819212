import React, { useEffect, useState } from "react";
import styles from "./AddEditFranchiseSites.module.css";
import addFranchiseSiteForm from "../FranchiseSitesSchema";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  LinearProgress,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  withStyles,
  Tooltip
} from "@material-ui/core";
import {
  Auth,
  AutoCompleteDropDown,
  Input,
  SnackBar,
  DarkBlueButton,
  BorderLessButton,
  Spinner
} from "../../../components";
import Switch from "@material-ui/core/Switch";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { get } from "lodash";
import {
  checkAllKeysPresent,
  checkEmpty,
  getListOfKeysNotPresent,
  setErrors
} from "../../../utils";
import SaveIcon from "@material-ui/icons/Save";
import { FRANCHISESITES } from "../../../paths";
import { providerForGet, providerForPost, providerForPut } from "../../../api";
import { franchise, apiBackendUrl, franchiseSites } from "../../../constants";
import {
  ERROR_MESSAGE,
  SAVE_MESSAGE,
  UPDATE_MESSAGE
} from "../../../constants/genericConstants";
import useStyles from "../../../utils/Styles/ManagePageStyles";
import upload_primary_logo from "../../../assets/images/upload_primary_logo.png";
import cover_page_logo from "../../../assets/images/cover_page_logo.png";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import PhoneInput from "react-phone-input-2";

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#F4F9FF",
    "&$checked": {
      color: "#1C4979"
    },
    "&$checked + $track": {
      backgroundColor: "#1C4979"
    }
  },
  checked: {
    color: "#F4F9FF"
  },
  track: {
    backgroundColor: "#1C4979"
  }
})(Switch);
const AddEditFranchise = props => {
  const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
  const history = useHistory();
  const auth = Auth.getToken();
  const userInfo = Auth.getUserInfo();
  const [loader, setLoader] = useState(false);
  const classes = useStyles();
  let propData = props["location"]["state"];
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    files: {},
    giveAllLogo: {},
    viewFile: "",
    deleteImage: false,
    previewFile: {},
    showPreviewImage: false,
    showPreviewEditImage: false,
    showPreviewNoImage: false,
    showAddPreviewNoImage: true,
    viewGiveAllFile: "",
    deleteGiveAllImage: false,
    previewGiveAllFile: {},
    showGiveAllPreviewImage: false,
    showGiveAllPreviewEditImage: false,
    showGiveAllPreviewNoImage: false,
    showAddGiveAllPreviewNoImage: true,
    franchiseList: [],
    alert: false,
    severity: "success",
    errorMessage: "",
    /** This is when we return from edit page */
    rowData: propData ? propData : [],
    editData: propData ? (propData["id"] ? true : false) : false,
    editDataId: propData ? propData["id"] : null
  });
  const [isAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_ADMIN_ROLE_NAME ? true : false
  );
  const [isSuperAdmin] = useState(() =>
    userInfo.role.name === process.env.REACT_APP_SUPER_ADMIN_ROLE_NAME
      ? true
      : false
  );
  const franchiseSiteName = "franchiseSiteName";
  const email = "email";
  const mask_all_users = "mask_all_users";
  const franchiseSiteDescription = "franchiseSiteDescription";
  const franchiseName = "franchiseName";
  const address_line_1 = "address_line_1";
  const address_line_2 = "address_line_2";
  const files = "files";
  const giveAllLogo = "giveAllLogo";
  const phone = "phone";
  const site_website = "site_website";
  useEffect(() => {
    getListData();
  }, []);

  /**Api call for getting autocomplete list itmes */
  const getListData = async () => {
    let body = {};
    await providerForGet(franchise, body, auth)
      .then(res => {
        setFormState(formState => ({
          ...formState,
          franchiseList: res.data.data
        }));
      })
      .catch(err => {
        console.log("err", err);
        setLoader(false);
      });

    if (isAdmin || isSuperAdmin) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [franchiseName]: Auth.getUserInfo().franchise
            ? Auth.getUserInfo().franchise.id
            : null
        }
      }));
    }
    if (formState.editData === true) {
      setLoader(true);
      let editId = {
        id: formState.editDataId,
        desc: `${userInfo.full_name} accessed a franchise site for editing`
      };
      await providerForGet(franchiseSites, editId, auth)
        .then(res => {
          setFormState(formState => ({
            ...formState,
            values: {
              ...formState.values,
              franchiseSiteName: res.data.data[0].name,
              franchiseName: res.data.data[0].franchise
                ? res.data.data[0].franchise.id
                : null,
              address_line_1: res.data.data[0].address_line_1,
              address_line_2: res.data.data[0].address_line_2,
              email: res.data.data[0].email,
              phone: res.data.data[0].phone ? res.data.data[0].phone : null,
              site_website: res.data.data[0].site_website,
              franchiseSiteDescription: res.data.data[0].description,
              mask_all_users: res.data.data[0].mask_all_users
            },
            viewFile:
              res.data.data[0] &&
              res.data.data[0].logo &&
              res.data.data[0].logo.url &&
              res.data.data[0].logo.url,
            showPreviewEditImage:
              res.data.data[0] &&
              res.data.data[0].logo &&
              res.data.data[0].logo.url
                ? true
                : false,
            showAddPreviewNoImage:
              res.data.data[0] &&
              res.data.data[0].logo &&
              res.data.data[0].logo.url
                ? false
                : true,
            viewGiveAllFile:
              res.data.data[0] &&
              res.data.data[0].give_all_site_logo &&
              res.data.data[0].give_all_site_logo.url &&
              res.data.data[0].give_all_site_logo.url,
            showGiveAllPreviewEditImage:
              res.data.data[0] &&
              res.data.data[0].give_all_site_logo &&
              res.data.data[0].give_all_site_logo.url
                ? true
                : false,
            showAddGiveAllPreviewNoImage:
              res.data.data[0] &&
              res.data.data[0].give_all_site_logo &&
              res.data.data[0].give_all_site_logo.url
                ? false
                : true
          }));

          setLoader(false);
        })
        .catch(err => {
          console.log("err", err);
          setLoader(false);
        });
    }
  };

  const handleChangeSwitch = event => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.checked
      },
      [event.target.name]: event.target.checked
    }));
  };

  const handleSnackbarClose = () => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: ""
    }));
  };

  const handleChange = e => {
    e.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));
    if (formState.errors.hasOwnProperty(e.target.name)) {
      delete formState.errors[e.target.name];
    }
  };

  const handlePhoneNumberChange = (value, name) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [name]: value
      },
      touched: {
        ...formState.touched,
        [name]: true
      }
    }));
    delete formState.errors[name];
  };

  async function validate(val, name) {
    let newVal = "+1" + val;
    try {
      const number = phoneUtil.parseAndKeepRawInput(newVal, "+1");
      let isValid = phoneUtil.isValidNumber(number);
      if (isValid) {
        if (val.startsWith("+")) {
          val = val.substring(1);
        }
        formState.values[name] = val;
      } else {
        formState.errors[name] = ["Invalid mobile number"];
      }
    } catch {}
  }

  const handleChangeAutoComplete = (eventName, event, value) => {
    if (value !== null) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [eventName]: value.id
        },
        touched: {
          ...formState.touched,
          [eventName]: true
        }
      }));
      if (formState.errors.hasOwnProperty(eventName)) {
        delete formState.errors[eventName];
      }
    } else {
      if (isAdmin) {
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [franchiseName]: Auth.getUserInfo().franchise.id
          }
        }));
      } else {
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [franchiseName]: null
          }
        }));
      }
    }
  };
  const hasError = field => (formState.errors[field] ? true : false);

  const createFormData = body => {
    const data = new FormData();
    data.append("files.logo", formState.files);
    data.append("files.give_all_site_logo", formState.giveAllLogo);
    data.append("data", JSON.stringify(body));
    return data;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoader(true);
    let isValid = false;
    let checkAllFieldsValid = checkAllKeysPresent(
      formState.values,
      addFranchiseSiteForm
    );

    if (checkAllFieldsValid) {
      formState.errors = setErrors(formState.values, addFranchiseSiteForm);
      validate(formState.values[phone], phone);

      if (checkEmpty(formState.errors)) {
        isValid = true;
      }
    } else {
      formState.values = getListOfKeysNotPresent(
        formState.values,
        addFranchiseSiteForm
      );
      formState.errors = setErrors(formState.values, addFranchiseSiteForm);
    }

    if (
      formState.giveAllLogo &&
      formState.giveAllLogo.name !== null &&
      formState.giveAllLogo.name
    ) {
      if (
        formState.giveAllLogo.type !== "image/png" &&
        formState.giveAllLogo.type !== "image/jpeg" &&
        formState.giveAllLogo.type !== "image/jpg"
      ) {
        isValid = false;
        setFormState(formState => ({
          ...formState,
          alert: true,
          severity: "error",
          errorMessage: "Image should be in PNG, JPG, JPEG format"
        }));
      }
    }

    if (
      isValid &&
      formState.files &&
      formState.files.name !== null &&
      formState.files.name
    ) {
      if (
        formState.files.type !== "image/png" &&
        formState.files.type !== "image/jpeg" &&
        formState.files.type !== "image/jpg"
      ) {
        isValid = false;
        setFormState(formState => ({
          ...formState,
          alert: true,
          severity: "error",
          errorMessage: "Image should be in PNG, JPG, JPEG format"
        }));
      }
    }
    if (isValid) {
      /**api call from here */
      let body = { franchise: formState.values.franchiseName };
      let noOfFranchiseSites = 0;
      let actualNoOfFranchiseSites = 0;
      await providerForGet(franchiseSites, body, auth)
        .then(res => {
          let obj = res.data.data;
          actualNoOfFranchiseSites = obj ? obj.length : 0;
          noOfFranchiseSites = obj[0]
            ? obj[0].franchise.no_of_franchise_sites
            : 0;
        })
        .catch(err => {
          console.log("err", err);
        });
      /**checking no of franchise sites given to that franchise and validating  the same */
      if (noOfFranchiseSites > actualNoOfFranchiseSites) {
        let postData = {
          name: formState.values.franchiseSiteName,
          franchise: formState.values.franchiseName,
          address_line_1: formState.values.address_line_1
            ? formState.values.address_line_1
            : "",
          address_line_2: formState.values.address_line_2
            ? formState.values.address_line_2
            : "",
          email: formState.values.email,
          phone: formState.values.phone ? formState.values.phone : null,
          site_website: formState.values.site_website,
          description: formState.values.franchiseSiteDescription,
          mask_all_users: formState.values.mask_all_users
        };
        if (formState.editData === true) {
          /**Put api call (Update method)*/
          let finalDataEdit = postData;
          if (
            (formState.files &&
              formState.files.name !== null &&
              formState.files.name) ||
            (formState.giveAllLogo &&
              formState.giveAllLogo.name !== null &&
              formState.giveAllLogo.name)
          ) {
            finalDataEdit = createFormData(postData);
          }

          /**
           * Below if is used when user removes the  logo and clicks on save
           */
          if (formState.showAddPreviewNoImage) {
            finalDataEdit["showAddPreviewNoImage"] = true;
          }
          if (formState.showAddGiveAllPreviewNoImage) {
            finalDataEdit["showAddGiveAllPreviewNoImage"] = true;
          }
          providerForPut(
            franchiseSites,
            formState.editDataId,
            finalDataEdit,
            auth,
            {
              desc: `${userInfo.full_name} updated the franchise site ${formState.values.franchiseSiteName}`
            }
          )
            .then(res => {
              let successMessage = {
                successMessage:
                  "Franchise site " + res.data.name + UPDATE_MESSAGE
              };

              history.push(FRANCHISESITES, successMessage);
              setLoader(false);
            })
            .catch(err => {
              console.log("err", err);
              if (
                err.response &&
                err.response.data &&
                err.response.data.message
              ) {
                setFormState(formState => ({
                  ...formState,
                  alert: true,
                  severity: "error",
                  errorMessage: err.response.data.message
                }));
              } else {
                setFormState(formState => ({
                  ...formState,
                  alert: true,
                  severity: "error",
                  errorMessage: ERROR_MESSAGE + err
                }));
              }
              setLoader(false);
            });
        } else {
          /**Post api call (Save method)*/

          let finalData = postData;
          if (
            (formState.files && formState.files.name) ||
            formState.giveAllLogo.name
          ) {
            finalData = createFormData(postData);
          }

          providerForPost(franchiseSites, finalData, auth, {
            desc: `${userInfo.full_name} added a new franchise site ${formState.values.franchiseSiteName}`
          })
            .then(res => {
              let successMessage = {
                successMessage: "Franchise site " + res.data.name + SAVE_MESSAGE
              };

              setLoader(false);
              history.push(FRANCHISESITES, successMessage);
            })
            .catch(err => {
              if (
                err.response &&
                err.response.data &&
                err.response.data.message
              ) {
                setFormState(formState => ({
                  ...formState,
                  alert: true,
                  severity: "error",
                  errorMessage: err.response.data.message
                }));
              } else {
                setFormState(formState => ({
                  ...formState,
                  alert: true,
                  severity: "error",
                  errorMessage: ERROR_MESSAGE + err
                }));
              }
              setLoader(false);
            });
          setLoader(false);
        }
      } else {
        setLoader(false);
        setFormState(formState => ({
          ...formState,
          alert: true,
          severity: "error",
          errorMessage:
            "Cannot have more than " +
            noOfFranchiseSites +
            " franchise sites for this franchise."
        }));
      }
    } else {
      setFormState(formState => ({
        ...formState,
        isValid: false
      }));
      setLoader(false);
    }
  };

  const handleCancel = () => {
    history.push(FRANCHISESITES);
  };

  const handleFileChange = event => {
    setFormState(formState => ({
      ...formState,
      alert: false,
      errorMessage: ""
    }));
    event.persist();

    if (event.target.files[0].size <= 1000000) {
      if (event.target.name === giveAllLogo) {
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.value
          },
          touched: {
            ...formState.touched,
            [event.target.name]: true
          },
          giveAllLogo: event.target.files[0],
          previewGiveAllFile: URL.createObjectURL(event.target.files[0]),
          showGiveAllPreviewEditImage: false,
          showGiveAllPreviewNoImage: false,
          showGiveAllPreviewImage: true,
          showAddGiveAllPreviewNoImage: false
        }));
      } else {
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [event.target.name]: event.target.value
          },
          touched: {
            ...formState.touched,
            [event.target.name]: true
          },
          files: event.target.files[0],
          previewFile: URL.createObjectURL(event.target.files[0]),
          showPreviewEditImage: false,
          showPreviewNoImage: false,
          showPreviewImage: true,
          showAddPreviewNoImage: false
        }));
      }
    } else {
      setFormState(formState => ({
        ...formState,
        alert: true,
        severity: "error",
        errorMessage: "File size must be less than or equal to 1mb"
      }));
    }

    /** This is used to remove any existing errors if present in text field */
    if (formState.errors.hasOwnProperty(event.target.name)) {
      delete formState.errors[event.target.name];
    }
  };

  const handleRemoveFile = () => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [files]: null
      },
      touched: {
        ...formState.touched,
        [files]: false
      },
      files: null,
      previewFile: null,
      showPreviewEditImage: false,
      showPreviewNoImage: false,
      showPreviewImage: false,
      showAddPreviewNoImage: true
    }));
  };
  const handleRemoveGiveAllLogo = () => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [giveAllLogo]: null
      },
      touched: {
        ...formState.touched,
        [giveAllLogo]: false
      },
      giveAllLogo: null,
      previewGiveAllFile: null,
      showGiveAllPreviewEditImage: false,
      showGiveAllPreviewNoImage: false,
      showGiveAllPreviewImage: false,
      showAddGiveAllPreviewNoImage: true
    }));
  };
  return (
    <div>
      {loader ? (
        <LinearProgress />
      ) : (
        <>
          <Grid item xs={12} className={styles.title}>
            {formState.editData === true ? (
              <Typography variant="h4" className={styles.titleText}>
                Edit Franchise Site
              </Typography>
            ) : (
              <Typography variant="h4" className={styles.titleText}>
                Add Franchise Site
              </Typography>
            )}
          </Grid>
          <Grid>
            <SnackBar
              open={formState.alert}
              severity={formState.severity}
              onClose={handleSnackbarClose}
            >
              {formState.errorMessage}
            </SnackBar>
          </Grid>
          <Grid item xs={12} className={styles.formgrid}>
            <Card className={classes.editCard}>
              <CardContent className={classes.editCardContent}>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(
                        addFranchiseSiteForm[franchiseSiteName],
                        "label"
                      )}
                      name={franchiseSiteName}
                      value={formState.values[franchiseSiteName] || ""}
                      error={hasError(franchiseSiteName)}
                      placeholder={get(
                        addFranchiseSiteForm[franchiseSiteName],
                        "placeholder"
                      )}
                      variant="outlined"
                      required
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(franchiseSiteName)
                          ? formState.errors[franchiseSiteName].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AutoCompleteDropDown
                      options={formState.franchiseList}
                      getOptionLabel={option => option.franchise_name}
                      id={get(addFranchiseSiteForm[franchiseName], "id")}
                      // value={formState.values[franchiseName]}
                      value={
                        formState.franchiseList[
                          formState.franchiseList.findIndex(function (item, i) {
                            return item.id === formState.values[franchiseName];
                          })
                        ] || null
                      }
                      disabled={isAdmin}
                      onChange={(event, value) => {
                        handleChangeAutoComplete(franchiseName, event, value);
                      }}
                      renderInput={params => (
                        <Input
                          {...params}
                          required
                          id={get(addFranchiseSiteForm[franchiseName], "id")}
                          label={get(
                            addFranchiseSiteForm[franchiseName],
                            "label"
                          )}
                          variant="outlined"
                          error={hasError(franchiseName)}
                          helperText={
                            hasError(franchiseName)
                              ? formState.errors[franchiseName].map(error => {
                                  return error + " ";
                                })
                              : null
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFranchiseSiteForm[email], "label")}
                      name={email}
                      value={formState.values[email] || ""}
                      error={hasError(email)}
                      placeholder={get(
                        addFranchiseSiteForm[email],
                        "placeholder"
                      )}
                      onChange={handleChange}
                      helperText={
                        hasError(email)
                          ? formState.errors[email].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <PhoneInput
                      country={"us"}
                      onlyCountries={["us"]}
                      disableDropdown={true}
                      disableCountryGuess={true}
                      disableCountryCode={true}
                      value={formState.values[phone] || ""}
                      onChange={phone =>
                        handlePhoneNumberChange(phone, "phone")
                      }
                      specialLabel="Phone"
                      placeholder={get(
                        addFranchiseSiteForm[phone],
                        "placeholder"
                      )}
                      inputProps={{
                        name: "phone",
                        style: {
                          width: "100%",
                          border: hasError(phone) ? "1px solid red" : null
                        }
                      }}
                    />
                    <FormHelperText
                      type="error"
                      visible={hasError(phone)}
                      style={{
                        "background-color": "White",
                        color: "red"
                      }}
                    >
                      {formState.errors[phone]}
                    </FormHelperText>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFranchiseSiteForm[address_line_1], "label")}
                      name={address_line_1}
                      value={formState.values[address_line_1] || ""}
                      error={hasError(address_line_1)}
                      placeholder={get(
                        addFranchiseSiteForm[address_line_1],
                        "placeholder"
                      )}
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(address_line_1)
                          ? formState.errors[address_line_1].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFranchiseSiteForm[address_line_2], "label")}
                      name={address_line_2}
                      value={formState.values[address_line_2] || ""}
                      error={hasError(address_line_2)}
                      placeholder={get(
                        addFranchiseSiteForm[address_line_2],
                        "placeholder"
                      )}
                      fullWidth
                      onChange={handleChange}
                      helperText={
                        hasError(address_line_2)
                          ? formState.errors[address_line_2].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(
                        addFranchiseSiteForm[franchiseSiteDescription],
                        "label"
                      )}
                      name={franchiseSiteDescription}
                      value={formState.values[franchiseSiteDescription] || ""}
                      placeholder={get(
                        addFranchiseSiteForm[franchiseSiteDescription],
                        "placeholder"
                      )}
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                      className={styles.elementroot}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Input
                      label={get(addFranchiseSiteForm[site_website], "label")}
                      name={site_website}
                      value={formState.values[site_website] || ""}
                      error={hasError(site_website)}
                      placeholder={get(
                        addFranchiseSiteForm[site_website],
                        "placeholder"
                      )}
                      onChange={handleChange}
                      helperText={
                        hasError(site_website)
                          ? formState.errors[site_website].map(error => {
                              return error + " ";
                            })
                          : null
                      }
                      className={styles.elementroot}
                    />
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      style={{
                        position: "relative",
                        top: "20px",
                        fontSize: "large"
                      }}
                      control={
                        <Tooltip
                          title={
                            formState.values.mask_all_users
                              ? "Masks all clients"
                              : "Masks only VIP and VVIP clients"
                          }
                          aria-label="add"
                        >
                          <PurpleSwitch
                            checked={
                              formState.values.mask_all_users
                                ? formState.values.mask_all_users
                                : false
                            }
                            onChange={handleChangeSwitch}
                            name={mask_all_users}
                          />
                        </Tooltip>
                      }
                      label={"Mask All Clients"}
                    />
                  </FormGroup>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      style={{
                        textAlign: "center"
                      }}
                    >
                      {" "}
                      <div className={classes.imageDiv}>
                        <Grid item md={6} xs={12}>
                          {" "}
                          {formState.showPreviewImage ? (
                            <img
                              src={formState.previewFile}
                              style={{
                                height: "200px",
                                width: "530px"
                              }}
                              alt="abc"
                              loader={<Spinner />}
                              className={classes.UploadImage}
                            />
                          ) : null}
                          {formState.showPreviewEditImage &&
                          formState.values.viewFile !== "" ? (
                            <>
                              <img
                                src={apiBackendUrl + formState.viewFile}
                                style={{
                                  height: "200px",
                                  width: "530px"
                                }}
                                alt="abc"
                                loader={<Spinner />}
                                className={classes.UploadImage}
                              />
                            </>
                          ) : null}
                          {formState.showAddPreviewNoImage ? (
                            <img
                              src={upload_primary_logo}
                              alt="abc"
                              style={{
                                height: "200px",
                                width: "530px"
                              }}
                              loader={<Spinner />}
                              className={classes.DefaultNoImage}
                            />
                          ) : null}
                        </Grid>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      style={{
                        display: "flex"
                      }}
                    >
                      <Input
                        fullWidth
                        id={get(addFranchiseSiteForm[files], "id")}
                        name={files}
                        onChange={handleFileChange}
                        required
                        onClick={event => {
                          event.target.value = null;
                        }}
                        type={get(addFranchiseSiteForm[files], "type")}
                        error={hasError(files)}
                        inputProps={{ accept: "image/*" }}
                        helperText={
                          hasError(files)
                            ? formState.errors[files].map(error => {
                                return error + " ";
                              })
                            : null
                        }
                        variant="outlined"
                        className={classes.inputFile}
                      />
                      <Grid item md={6} xs={6}>
                        <label htmlFor={get(addFranchiseSiteForm[files], "id")}>
                          <DarkBlueButton
                            variant="contained"
                            color="primary"
                            component="span"
                            fullWidth
                            startIcon={<AddOutlinedIcon />}
                          >
                            ADD PRIMARY LOGO{" "}
                          </DarkBlueButton>
                        </label>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <BorderLessButton
                          variant="contained"
                          color="primary"
                          component="span"
                          fullWidth
                          onClick={handleRemoveFile}
                        >
                          Remove Logo
                        </BorderLessButton>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      style={{
                        textAlign: "center"
                      }}
                    >
                      <div className={classes.imageDiv}>
                        <Grid item md={6} xs={12}>
                          {" "}
                          {formState.showGiveAllPreviewImage ? (
                            <img
                              src={formState.previewGiveAllFile}
                              alt="abc"
                              style={{
                                height: "200px",
                                width: "530px"
                              }}
                              loader={<Spinner />}
                              className={classes.UploadImage}
                            />
                          ) : null}
                          {formState.showGiveAllPreviewEditImage &&
                          formState.values.viewGiveAllFile !== "" ? (
                            <>
                              <img
                                src={apiBackendUrl + formState.viewGiveAllFile}
                                style={{
                                  height: "200px",
                                  width: "530px"
                                }}
                                alt="abc"
                                loader={<Spinner />}
                                className={classes.UploadImage}
                              />
                            </>
                          ) : null}
                          {formState.showAddGiveAllPreviewNoImage ? (
                            <img
                              src={cover_page_logo}
                              style={{
                                height: "200px",
                                width: "530px"
                              }}
                              alt="abc"
                              loader={<Spinner />}
                              className={classes.DefaultNoImage}
                            />
                          ) : null}
                        </Grid>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      style={{
                        display: "flex"
                      }}
                    >
                      <Input
                        fullWidth
                        id={get(addFranchiseSiteForm[giveAllLogo], "id")}
                        name={giveAllLogo}
                        onChange={handleFileChange}
                        required
                        onClick={event => {
                          event.target.value = null;
                        }}
                        type={get(addFranchiseSiteForm[giveAllLogo], "type")}
                        error={hasError(giveAllLogo)}
                        inputProps={{ accept: "image/*" }}
                        helperText={
                          hasError(giveAllLogo)
                            ? formState.errors[giveAllLogo].map(error => {
                                return error + " ";
                              })
                            : null
                        }
                        variant="outlined"
                        className={classes.inputFile}
                      />

                      <Grid item md={6} xs={6}>
                        <label
                          htmlFor={get(addFranchiseSiteForm[giveAllLogo], "id")}
                        >
                          <DarkBlueButton
                            variant="contained"
                            color="primary"
                            component="span"
                            fullWidth
                            startIcon={<AddOutlinedIcon />}
                          >
                            ADD COVER PAGE IMAGE
                          </DarkBlueButton>
                        </label>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <BorderLessButton
                          variant="contained"
                          color="primary"
                          component="span"
                          fullWidth
                          onClick={handleRemoveGiveAllLogo}
                        >
                          Remove Logo
                        </BorderLessButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} className={classes.actionButtonGrid}>
            <DarkBlueButton
              type="submit"
              data-testid="ok-button"
              onClick={handleSubmit}
            >
              <SaveIcon />
              &nbsp; Save
            </DarkBlueButton>

            <BorderLessButton onClick={handleCancel}>cancel</BorderLessButton>
          </Grid>
        </>
      )}
    </div>
  );
};

AddEditFranchise.propTypes = {};

AddEditFranchise.defaultProps = {};

export default AddEditFranchise;
