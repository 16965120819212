import { React } from "react";
import {
  Typography,
  AppBar,
  Toolbar,
  Card,
  CardContent,
  Box,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { DarkBlueButton } from "../../../components";
import { useHistory } from "react-router-dom";
import { FORGOTPASSWORD, LOGIN } from "../../../paths/Paths";
import Logo from "../../../components/Logo/Logo";

function Copyright() {
  return (
    <Typography
      align="center"
      style={{
        color: "#8A8A97",
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "0.875rem"
      }}
    >
      Copyright © {new Date().getFullYear()} Performance Fitness Concepts, All
      Rights Reserved.
    </Typography>
  );
}
const useStyles = makeStyles(theme => ({
  loginAppBar: {
    background: "rgb(244, 248, 255) !important",
    "box-shadow": "0px 3px 5px #e8eef7 !important"
  },
  loginToolbar: {
    padding: "0px 25px"
  },
  loginLogo: {
    "max-width": "178px",
    margin: "0px 5px",
    "max-height": "50px"
  },
  container: {
    margin: "117px auto 50px auto"
  },
  cardContent: {
    padding: " 60px !important",
    paddingBottom: "45px !important",
    "background-color": " rgb(244 248 255) !important",
    border: "1px solid #1C4979 !important",
    "border-radius": "4px !important"
  },
  loginTextDesktop: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1.25rem",
    color: "#110F48",
    paddingTop: 40
  },
  loginTextMobile: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#110F48",
    fontSize: "1.25rem"
  },
  form: {
    width: "100%"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#0152CC",
    fontFamily: "Montserrat",
    fontWeight: 400,
    color: "#fff",
    fontSize: "0.875rem"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    position: "relative"
  },
  welcomeText: {
    marginBottom: theme.spacing(2),
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000000",
    fontSize: "1rem"
  },
  inputText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1rem"
  },
  label: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.875rem"
  },
  Heading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000000",
    fontSize: "1rem"
  }
}));

const PasswordChanged = props => {
  const classes = useStyles();
  const history = useHistory();
  const identifierPresent = props.location.identifier;
  const themes = useTheme();

  const isDesktop = useMediaQuery(themes.breakpoints.up("lg"), {
    defaultMatches: true
  });
  if (!identifierPresent) {
    history.push({
      pathname: FORGOTPASSWORD
    });
  }

  /** onPassChange
   * * Page displays password is reset, then redirects to home page
   */
  const onPassChange = () => {
    //change to login
    history.push({
      pathname: LOGIN
    });
  };

  return (
    <>
      <AppBar position="fixed" className={classes.loginAppBar} elevation={0}>
        <Toolbar className={classes.loginToolbar}>
          <Logo className={classes.loginLogo} />
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" className={classes.container}>
        <Card variant="elevation" elevation={2}>
          <CardContent className={classes.cardContent}>
            <Box>
              <Typography
                className={
                  isDesktop ? classes.loginTextDesktop : classes.loginTextMobile
                }
                align="center"
              >
                Passsword Changed
              </Typography>
            </Box>
            <Box>
              <Typography
                align="center"
                className={classes.welcomeText}
                style={{ marginBottom: 0 }}
              >
                Your Password has been successfully changed for
              </Typography>
              <Typography align="center" className={classes.welcomeText}>
                your registered email <br /> <b>{identifierPresent}</b>
              </Typography>
            </Box>
            <DarkBlueButton
              type="button"
              fullWidth
              variant="contained"
              onClick={onPassChange}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              LOGIN PAGE
            </DarkBlueButton>
          </CardContent>
        </Card>
        <Box mt={2}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
};

export default PasswordChanged;
