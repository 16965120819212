import React, { useEffect, useState } from "react";
import styles from "./ClientSupplement.module.css";
import {
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import {
  AutoCompleteDropDown,
  HeadingOne,
  Input,
  SupplementGrid
} from "../../components";
import { convertDate, useStyles } from "../../utils";
import {
  providerForDownload,
  providerForGet,
  providerForPost
} from "../../api";
import {
  downloadSupplementsPdf,
  getScheduleDateForClient,
  getSupplementDataOfASchedule,
  supplementForFranchise
} from "../../constants";
import auth from "../../components/Auth";
import GetAppIcon from "@material-ui/icons/GetApp";
import SupplementIcon from "../../assets/small_icons/Supplements.png";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { CLIENTMYSCHEDULE } from "../../paths";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PrintIcon from "@material-ui/icons/Print";
import noSchedule from "../../assets/images/No_schedule.jpg";

const ClientSupplement = props => {
  const localClasses = useStyles();

  const userInfo = auth.getUserInfo();
  let propData = props["location"]["state"];
  const noScheduleDataMessageFromBackend = "No schedule data";
  const [scheduleDateNotPresent, setScheduleDateNotPresent] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });
  const history = useHistory();
  const [noOfSupplement] = useState(1);
  const [supplementData, setSupplementData] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [openAction] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    scheduleId: propData ? propData.id : null,
    scheduleObj: propData ? propData.scheduleObj : null
  });

  const suppDummyData = {
    id: null,
    morn: 0,
    pre: 0,
    post: 0,
    eve: 0,
    total: 0,
    supp_notes: "",
    PersonalNotes: ""
  };
  const [suppForm, setSuppForm] = useState({
    suppData: {
      1: suppDummyData /** This is the initial data */
    },
    scheduleId: null,
    clientId: null,
    errors: {},
    checked: {}
  });

  const getInitialSupplementData = async () => {
    setLoader(true);
    let sId = null;
    await providerForGet(
      getScheduleDateForClient,
      {
        c: userInfo.id
      },
      auth.getToken()
    )
      .then(async res => {
        let convertDateData = await convertDate(res.data);
        setDateList(convertDateData);

        if (!formState.scheduleId || !formState.scheduleObj) {
          setFormState(formState => ({
            ...formState,
            scheduleId: convertDateData[0].id,
            scheduleObj: convertDateData[0]
          }));
          sId = convertDateData[0].id;
        } else {
          sId = formState.scheduleId;
        }
      })
      .catch(error => {
        console.log("error", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.statusCode === 400 &&
          error.response.data.message === noScheduleDataMessageFromBackend
        ) {
          setScheduleDateNotPresent(true);
        }
        setLoader(false);
      });

    setSuppForm(suppForm => ({
      ...suppForm,
      scheduleId: sId,
      clientId: userInfo.id
    }));
    await providerForGet(
      supplementForFranchise,
      {
        pageSize: -1,
        _sort: "name:asc"
      },
      auth.getToken()
    )
      .then(res => {
        setSupplementData(res.data);
      })
      .catch(error => {});

    if (sId) {
      await providerForPost(
        getSupplementDataOfASchedule,
        {
          s: sId,
          c: userInfo.id
        },
        auth.getToken(),
        {
          desc: "Access supplement data of a schedule by client"
        }
      )
        .then(res => {
          setLoader(false);
          let obj = {};
          let resObj = res.data;
          if (resObj.length) {
            resObj.map((data, i) => {
              obj[i + 1] = {
                suppScheduleId: data.id,
                id: data.supplement_id ? data.supplement_id.id : 0,
                morn: data.Morn ? data.Morn : 0,
                pre: data.Pre ? data.Pre : 0,
                post: data.Post ? data.Post : 0,
                eve: data.Eve ? data.Eve : 0,
                total: data.Total ? data.Total : 0,
                supp_notes: data.supplement_id
                  ? data.supplement_id.description
                  : "",
                PersonalNotes: data.personal_notes ? data.personal_notes : "",
                trackedStatus: data.isTracked ? data.isTracked : false
              };
              return null;
            });
          } else {
            obj = {
              1: suppDummyData /** This is the initial data */
            };
          }
          setSuppForm(suppForm => ({
            ...suppForm,
            suppData: obj
          }));
        })
        .catch(error => {
          setLoader(false);
          console.log("errr*****", error);
        });
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    getInitialSupplementData();
  }, []);

  const checkIfDataProper = (isForDelete = false) => {
    let isValid = true;
    if (isForDelete) {
      let arr = Object.keys(suppForm.suppData);
      if (arr.length === 1 && !suppForm.suppData[arr[0]].id) {
        isValid = false;
      } else {
        isValid = true;
      }
    } else {
      let arr = Object.keys(suppForm.suppData);
      arr.map(i => {
        if (!suppForm.suppData[i].id) {
          isValid = false;
        }
        return null;
      });
    }
    return isValid;
  };

  const handleAddSupplement = () => {
    if (checkIfDataProper()) {
      let highest = Object.keys(suppForm.suppData)
        .sort(function (a, b) {
          return a - b;
        })
        .pop();
      setSuppForm(suppForm => ({
        ...suppForm,
        suppData: {
          ...suppForm.suppData,
          [parseInt(highest) + 1]: suppDummyData
        }
      }));
    }
  };

  const handleChangeAutoComplete = async (eventName, event, value) => {
    if (eventName === "scheduleDate") {
      setLoader(true);
      if (value !== null) {
        setFormState(formState => ({
          ...formState,
          scheduleId: value.id,
          scheduleObj: value
        }));

        await providerForPost(
          getSupplementDataOfASchedule,
          {
            s: value.id,
            c: userInfo.id
          },
          auth.getToken(),
          {
            desc: "Access supplement data of a schedule by client"
          }
        )
          .then(res => {
            setLoader(false);
            let obj = {};
            let resObj = res.data;
            if (resObj.length) {
              resObj.map((data, i) => {
                obj[i + 1] = {
                  suppScheduleId: data.id,
                  id: data.supplement_id ? data.supplement_id.id : null,
                  morn: data.Morn ? data.Morn : 0,
                  pre: data.Pre ? data.Pre : 0,
                  post: data.Post ? data.Post : 0,
                  eve: data.Eve ? data.Eve : 0,
                  total: data.Total ? data.Total : 0,
                  supp_notes: data.supplement_id
                    ? data.supplement_id.description
                    : "",
                  PersonalNotes: data.personal_notes ? data.personal_notes : "",
                  trackedStatus: data.isTracked ? data.isTracked : false
                };
                return null;
              });
            } else {
              obj = {
                1: suppDummyData /** This is the initial data */
              };
            }
            setSuppForm(suppForm => ({
              ...suppForm,
              suppData: obj
            }));
          })
          .catch(error => {
            setLoader(false);
            console.log(error);
          });
      } else {
        setFormState(formState => ({
          ...formState,
          scheduleId: formState.scheduleId,
          scheduleObj: formState.scheduleObj
        }));
      }
    }
  };

  const downloadPdf = async isPrint => {
    setLoader(true);
    let finalData = {
      s: formState.scheduleId,
      c: userInfo.id
    };
    await providerForDownload(
      downloadSupplementsPdf,
      finalData,
      auth.getToken(),
      {
        desc: "Download Supplements Schedule by client"
      }
    )
      .then(res => {
        if (isPrint) {
          const url = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          window.open(url,'_blank');
          // const pdfNewWindow = window.open();
          // pdfNewWindow.location.href = url;
        } else {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Supplements.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
        console.log(error);
      });
  };


  const handleBackArrow = () => {
    history.push(CLIENTMYSCHEDULE);
  };

  return (
    <div>
      {auth.getUserInfo().role.name ===
        process.env.REACT_APP_CLIENT_ROLE_NAME && (
        <IconButton
          className={styles.backIcon}
          onClick={() => handleBackArrow()}
        >
          <ChevronLeftIcon
            style={{
              padding: "5px",
              border: "solid #DADADA 1px",
              borderRadius: "25px",
              margin: "0px 1.875rem 0px 10px",
              background: "#fff",
              marginTop: 0
            }}
          />
        </IconButton>
      )}
      <HeadingOne>
        {" "}
        <img
          width="auto"
          height="1.875rem"
          src={SupplementIcon}
          alt={"SupplementIcon"}
        />
        <span style={{ position: "absolute", marginLeft: "10px" }}>
          SUPPLEMENTS
        </span>
      </HeadingOne>

      {scheduleDateNotPresent ? (
        <Grid>
          <img
            src={noSchedule}
            alt="No schedule/data"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "50%"
            }}
          />
        </Grid>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12}>
              <AutoCompleteDropDown
                options={dateList}
                getOptionLabel={option => option.schedule_start_date}
                id="schedule_date"
                value={
                  dateList[
                    dateList.findIndex(function (item, i) {
                      return item.id === formState.scheduleId;
                    })
                  ] || null
                }
                onChange={(event, value) => {
                  handleChangeAutoComplete("scheduleDate", event, value);
                }}
                renderInput={params => (
                  <Input {...params} id="schedule_date" label="Schedule Date" />
                )}
              />
            </Grid>
          </Grid>
          <div className={localClasses.cancelSaveButton}>
            <IconButton
              variant="contained"
              size="small"
              onClick={() => downloadPdf(false)}
              style={{ padding: 12 }}
            >
              <Tooltip title="Download Report">
                <GetAppIcon style={{ color: "#1C4979", fontSize: 30 }} />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={() => downloadPdf(true)}
              style={{ marginRight: 15, padding: 0, background: "none" }}
            >
              <Tooltip title="Print Report">
                <PrintIcon style={{ color: "#1C4979", fontSize: 30 }} />
              </Tooltip>
            </IconButton>
          </div>
          <Grid item xs={12} md={12} lg={12} style={{ marginTop: "0px" }}>
            <Grid container spacing={2}>
              <Grid item xs="12">
                {!loader &&
                Object.keys(suppForm.suppData).length === 1 &&
                suppForm.suppData[Object.keys(suppForm.suppData)[0]].id ===
                  null ? (
                  <Grid>
                    <img
                      src={noSchedule}
                      alt="No schedule/data"
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "30%"
                      }}
                    />
                  </Grid>
                ) : (
                  <SupplementGrid
                    viewOnly={true}
                    isDesktop={isDesktop}
                    openAction={openAction}
                    noOfSupplement={noOfSupplement}
                    supplementData={supplementData}
                    handleAddSupplement={handleAddSupplement}
                    checkIfDataProper={checkIfDataProper}
                    suppData={suppForm.suppData}
                  />
                )}

                <Backdrop className={localClasses.backdrop} open={loader}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

ClientSupplement.propTypes = {};

ClientSupplement.defaultProps = {};

export default ClientSupplement;
